import { AccountReducer } from "./reducer";
import sagas from "./saga";
import { AccountModuleState } from "./types";

export { AccountModuleState };

export const accountModule = {
  id: "account",
  reducerMap: {
    account: AccountReducer,
  },
  sagas: [sagas],
};

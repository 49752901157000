import React from "react";
import { connect } from "react-redux";
import { GRAPHQL_TYPE_ENTITY } from "@radivision/graphql";
import { AppState } from "../../../redux";
import { FormFieldContainer, FormFieldInput } from "../../../react-components/modals/modal-form-elements";
import { SetProfileStateAction, SetProfileStatePayload, ProfileModuleState } from "../redux/profile/types";
import { setProfileState } from "../redux/profile/actions";
import { CompanyTypes } from "../../../constants/company-info";

interface MapStateProps {
  activities: string[];
  isCompany: boolean;
}

interface MapDispatchProps {
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { activities, setProfileState, isCompany } = props;

  if (!isCompany) return null;

  return (
    <FormFieldContainer>
      <label htmlFor="activities">
        <span>Company Type: *</span>
      </label>
      <FormFieldInput>
        <select
          value={activities[0] ? activities[0] : ""}
          onChange={(e) => {
            setProfileState({ activities: [e.target.value] });
          }}
        >
          <option disabled value={""}>
            Select company type
          </option>
          {CompanyTypes.map(({ kind, title }) => {
            return (
              <option key={`company-type-${kind}`} value={kind}>
                {title}
              </option>
            );
          })}
        </select>
      </FormFieldInput>
    </FormFieldContainer>
  );
};

const mapState = (state: AppState & ProfileModuleState) => {
  return {
    activities: state.profile.activities,
    isCompany: state.profile.type === GRAPHQL_TYPE_ENTITY,
  };
};

const mapDispatch = {
  setProfileState: setProfileState,
};

export const CompanyType = connect(mapState, mapDispatch)(Component);

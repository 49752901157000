/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CreateUpdateChangeEmailRequestInput = {
    readonly clientMutationId: string;
    readonly description?: string | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly email: string;
    readonly isDeleted?: boolean | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type CreatePreviewInput = {
    readonly kind?: ReadonlyArray<string> | null;
    readonly link?: string | null;
    readonly mediaAsset?: string | null;
};
export type createChangeEmailRequestMutationVariables = {
    readonly input: CreateUpdateChangeEmailRequestInput;
};
export type createChangeEmailRequestMutationResponse = {
    readonly ChangeEmailRequest: {
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly hasErrors: boolean | null;
        readonly userRequest: ({
            readonly id?: string;
            readonly status?: string | null;
        } & ({
            readonly id: string;
            readonly status: string | null;
        } | {
            /*This will never be '% other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        })) | null;
    } | null;
};
export type createChangeEmailRequestMutation = {
    readonly response: createChangeEmailRequestMutationResponse;
    readonly variables: createChangeEmailRequestMutationVariables;
};



/*
mutation createChangeEmailRequestMutation(
  $input: CreateUpdateChangeEmailRequestInput!
) {
  ChangeEmailRequest(input: $input) {
    errors {
      id
      location
    }
    hasErrors
    userRequest {
      __typename
      ... on DeactivateRequest {
        id
        status
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateUpdateChangeEmailRequestInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "createChangeEmailRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "ChangeEmailRequest",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUpdateUserRequestPayload",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userRequest",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "type": "DeactivateRequest",
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createChangeEmailRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "ChangeEmailRequest",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUpdateUserRequestPayload",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userRequest",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "DeactivateRequest",
                "selections": [
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "createChangeEmailRequestMutation",
    "id": null,
    "text": "mutation createChangeEmailRequestMutation(\n  $input: CreateUpdateChangeEmailRequestInput!\n) {\n  ChangeEmailRequest(input: $input) {\n    errors {\n      id\n      location\n    }\n    hasErrors\n    userRequest {\n      __typename\n      ... on DeactivateRequest {\n        id\n        status\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '2ac6c7543f7f9b5c88d23178bb0f2263';
export default node;

/**
 * @author Ahmed Samer
 * @date   2020-01-08
 * @description Declaration of the UrlRoute interface.
 * @filename ugc-request.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { UserRequest } from "./user-request";
import { UGCMetaDataKind } from "./ugc-meta-type";
import { MediaAsset } from "./media-asset";
/**
 * the GraphQl Type.
 */
export const GRAPHQL_TYPE_UGC_REQUEST = "UGCRequest";

/**
 *
 *
 * @export
 * @interface UGCRequest
 * @extends {Node}
 */
export interface UGCRequest<D extends Date | string = string>
  extends UserRequest<D> {
  /**
   * the media asset that holding audio file assets
   *
   * @type {string}
   * @memberof UGCRequest
   */
  audioMediaAsset?: MediaAsset;

  /**
   *
   *
   * @type {({ [key in UGCMetaDataKind]?: string | boolean })}
   * @memberof UGCRequest
   */
  metaData?: { [key in UGCMetaDataKind]?: string | boolean };

  /**
   * A description of the actions associated with the poster.
   *
   * @type {string}
   * @memberof UGCRequest
   */
  personId?: string;

  /**
   * A title for the actions associated with the poster.
   *
   * @type {string}
   * @memberof UGCRequest
   */
  personEmail?: string;

  /**
   * the  media asset that holding sample video assets
   *
   * @type {MediaAsset}
   * @memberof UGCRequest
   */
  sample?: MediaAsset;
}

import { CrawlerReducer } from "./reducer";
import { CrawlerModuleState } from "./types";
import sagas from "./saga";

export { CrawlerModuleState };

export const crawlerModule = {
  id: "crawler",
  reducerMap: {
    crawler: CrawlerReducer,
  },
  sagas: [sagas],
};

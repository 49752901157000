/**
 * @Author: Ahmed Samer
 * @Date:   2020-06-23
 * @Project: Radivision
 * @Filename: event.ts
 * @Copyright: Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import { Node } from "./node";
import { Preview } from "./preview";
import { Connection } from "./connection";

/**
 * The __typeID of the Event GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_EVENT: string = "Event";

/**
 *
 *
 * @export
 * @interface Event
 * @extends {Node<D>}
 * @template D
 */
export interface Event<D extends Date | string = string> extends Node<D> {
  /**
   *Event name.
   *
   * @type {string}
   * @memberof Event
   */
  name: string;
  /**
   *string describing the event and its purpose.
   *
   * @type {string}
   * @memberof Event
   */
  description?: string;

  /**
   *Event summary. Short summary describing the event and its purpose.
   *
   * @type {string}
   * @memberof Event
   */
  summary?: string;

  /**
   * A collection of media assets used as previews for the entity. Only one media asset can act as a given
   * type of preview
   *
   * @template D
   * @type {Connection<D, Preview>}
   */
  previews?: Connection<D, Preview>;

  /**
   * URL of the Event's Listing page on event-brite.com.
   *
   * @type {string}
   * @memberof Event
   */
  url: string;
  /**
   * Event start date and time.
   *
   * @type {string}
   * @memberof Event
   */
  start: string;
  /**
   * Event end date and time.
   *
   * @type {string}
   * @memberof Event
   */
  end?: string;
  /**
   *
   *
   * @type {("draft" | "live" | "started" | "ended" | "completed" | "canceled")}
   * @memberof Event
   */
  status: "draft" | "live" | "started" | "ended" | "completed" | "canceled";
  /**
   *
   *
   * @type {string}
   * @memberof Event
   */
  currency?: string;

  /**
   *
   *
   * @type {string}
   * @memberof Event
   */
  externalId?:string;
}

import { MONTHS_NAMES } from "../../../constants/months-names";
import { esc } from "../../../utilities/esc";
import { EVENT_LIVE } from "../redux/events/constants";

const blankInfo = { url: null, isLiveEvent: false, dateLine: "" };

interface EventInfo {
  isLiveEvent: boolean;
  dateLine: string;
  url: string | null;
}
export function getEventInfo(event: any): EventInfo {
  if (!event) return blankInfo;
  const url = `/event/${esc(event.name)}/${event.id}`;
  const startDate = event.start ? new Date(event.start) : null;
  const endDate = event.end ? new Date(event.end) : null;
  const dateLine = getDateStr(startDate, endDate);
  const isLiveEvent = event?.status === EVENT_LIVE;
  return { ...blankInfo, dateLine, url, isLiveEvent };
}

function getDateStr(startDate: any, endDate: any) {
  const start = getDateParts(startDate);
  const end = getDateParts(endDate);
  const startStr = start
    ? `${start.monthName} ${start.day}${start.nth}, ${start.hours}:${String(start.minutes).padStart(2, "0")}${
        start.hours > 12 ? "pm" : "am"
      }`
    : "";
  const endStr =
    end.hours !== undefined && end.minutes !== undefined
      ? ` - ${end.hours}:${String(end.minutes).padStart(2, "0")}${end.hours > 12 ? "pm" : "am"}  `
      : "";
  const DateStr = startStr + endStr + ` ${start.year} ET`;
  return DateStr;
}

function getDateParts(date) {
  const day = date?.getDate();
  const month = date?.getMonth();
  const year = date?.getFullYear();
  const monthName = date ? MONTHS_NAMES[month] : null;
  const hours = date?.getHours();
  const minutes = date?.getMinutes();
  const nth = [1, 21].includes(day) ? "st" : [2, 22].includes(day) ? "nd" : [3, 23].includes(day) ? "rd" : "th";
  return { day, month, year, monthName, hours, minutes, nth };
}

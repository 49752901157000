import { debounce, put } from "redux-saga/effects";
import { setAppState } from "../actions";
import { SetAppStateAction } from "../types";
import { SET_DEVICE } from "../constants";

function* setDeviceWorker(action: SetAppStateAction) {
  try {
    yield put(setAppState(action.payload));
  } catch (errors) {}
}

export function* watchSetDevice() {
  yield debounce(500, SET_DEVICE, setDeviceWorker);
}

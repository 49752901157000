/**
 * @author W. Alex Livesley
 * @copyright Copyright 2018 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2018-02-04
 * @description Implementation of the TypesUtilities class.
 * @filename types-utilities.ts
 */
import { Types } from "./types";

/**
 * A collection of utility functions concerning type based operations.
 */
export class TypesUtilities {
  /**
   * Returns a date, specified in the form of a Date object or a string, as the number of milliseconds since 1 January
   * 1970 00:00:00 UTC.
   *
   * @static
   * @param {(Date|string)} date The date value to be transformed.
   *
   * @return {number} The date as the number of milliseconds since 1 January 1970 00:00:00 UTC.
   */
  public static getDateInMilliseconds(date: Date | string): number {
    return Types.isString(date) === true
      ? new Date(date as string).valueOf()
      : (date as Date).valueOf();
  }

  /**
   * Returns an object without a given set of direct properties contained in a source object.
   *
   * @static
   * @param {Object} value The source object.
   *
   * @param {Array<string>} keys The name of the properties of the source object to be removed.
   *
   * @return {Object} A copy of the source object without a given set of properties.
   */
  public static omitFromOwnProperties(value: {}, ...keys: string[]): {} {
    return Object.keys(value).reduce((result: {}, key: string): {} => {
      if (keys.indexOf(key) === -1) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (result as { [index: string]: any })[key] = (value as {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          [index: string]: any;
        })[key];
      }
      return result;
    }, {});
  }

  /**
   * Returns an object containing only a given set of direct properties contained in a source object.
   *
   * @static
   * @param {Object} value The source object.
   *
   * @param {Array<string>} keys The name of the properties of the source object to be retained.
   *
   * @return {Object} A copy of the source object containing a given set of properties.
   */
  public static pickFromOwnProperties(value: {}, ...keys: string[]): {} {
    return keys.reduce((result: {}, key: string): {} => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (result as { [index: string]: any })[key] = (value as {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [index: string]: any;
      })[key];
      return result;
    }, {});
  }

  /**
   * Returns a given source array filtered to remove duplicates.
   *
   * @static
   * @param {Array<*>} values The source array.
   *
   * @return {Array<*>} The source array filtered to remove duplicates.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static removeDuplicatesFromArray(values: any[]): any[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return values.filter((vl: any, idx: number, arr: any[]): boolean => {
      return arr.indexOf(vl) === idx;
    });
  }

  /**
   * Returns a flag which is true if a given value exists (is not undefined and is not null).
   *
   * @static
   * @param {*} value The value.
   *
   * @returns {boolean} A flag which is true if the given value exists.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static exist(value: any): boolean {
    return value !== undefined && value !== null;
  }
}

/**
 * @author Ahmed Serag
 * @date   2018-07-31
 * @description Declaration of the ListCollection interface.
 * @filename list-collection.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Connection } from "./connection";
import { ListType, ListItemType, ListItemKind } from "./list";
import { Node } from "./node";
import { Preview } from "./preview";
import { Questionnaire } from "./questionnaire";

/**
 * The __typeID of the List Collection type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_LIST_COLLECTION: string = "ListCollection";

/**
 *  A set of lists selected manually. This may be ordered or unordered
 *
 * @interface
 * @template D, L
 */
export interface ListCollection<
  D extends Date | string = string,
  L extends ListItemType<D> = ListItemType<D>
> extends Node<D> {
  /**
   * A description of the list collection. Metadata
   *
   * @type {string}
   * @memberof ListCollection
   */
  description?: string;

  /**
   * The kinds of items in the list collection.
   *
   * @type {ListItemKind[]}
   * @memberof List
   */
  itemsTypes?: ListItemKind[];

  /**
   * A label for the list collection. Metadata
   *
   * @type {string}
   * @memberof ListCollection
   */
  label?: string;

  /**
   * A label for the list collection. Metadata
   *
   * @type {string}
   * @memberof ListCollection
   */
  landingPageUrl?: string;

  /**
   * The collection of lists in the list collection
   *
   * @template D, L
   * @type {List<N, L>}
   * @memberof ListCollection
   */
  lists?: ListType<D, L>[];

  /**
   *  The name of the list collection
   *
   * @type {string}
   * @memberof ListCollection
   */
  name?: string;

  /**
   * A collection of media assets used as previews for the list collection.
   *
   * @template D
   * @type {Connection<D, Preview>}
   */
  previews?: Connection<D, Preview>;

  /**
   * The date when the list collection was published
   *
   * @template D
   * @type {D}
   * @memberof ListCollection
   */
  publishedDate?: D;

  /**
   * The questionnaire associated with the list collection.
   *
   * @type {Questionnaire}
   * @memberof ListCollection
   */
  questionnaire?: Questionnaire;

  /**
   * The sub-title of the list collection
   *
   * @type {string}
   * @memberof ListCollection
   */
  subTitle?: string;

  /**
   * The title of the list collection
   *
   * @type {string}
   * @memberof ListCollection
   */
  title?: string;
}

import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { ProfileModuleState, SetProfilePhotoPayload, SetProfilePhotoAction, SetProfileStateAction, SetProfileStatePayload } from "../redux/profile/types";
import { Person, PreviewKind } from "@radivision/graphql";
import { setProfilePhoto, setProfileState } from "../redux/profile/actions";
import { EDIT_PROFILE_PAGE_INFO } from "../redux/profile/constants";
import "./upload-image-button.scss";

interface ComponentProps {
  isRequired?: boolean;
  previewKind: typeof PreviewKind.SQUARE | typeof PreviewKind.BACKGROUND;
}

interface MapStateProps {
  user: Person;
}

interface MapDispatchProps {
  setProfilePhoto: (payload: SetProfilePhotoPayload) => SetProfilePhotoAction;
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}

export type Props = ComponentProps & MapStateProps & MapDispatchProps;

export const Component = (props: Props) => {
  const { setProfilePhoto, setProfileState, previewKind } = props;

  const onFileSelect = (type: PreviewKind.BACKGROUND | PreviewKind.SQUARE) => (event: any) => {
    const file = event.target?.files && event.target.files[0];
    if (file) {
      setProfilePhoto({ type, file });
      setProfileState({ screen: EDIT_PROFILE_PAGE_INFO });
    }
  };

  return (
    <div className="upload-profile-image-button">
      <input
        type="file"
        name="backgroundFile"
        onChange={onFileSelect(previewKind)}
        accept="image/png,image/gif,image/jpeg"
      />
      <button>Upload banner image</button>
    </div>
  );
};

const mapState = (state: AppState & ProfileModuleState) => ({
  user: state.account.user,
});

const mapDispatch = {
  setProfilePhoto: setProfilePhoto,
  setProfileState: setProfileState,
};

export const UploadImageButton = connect(mapState, mapDispatch)(Component);

/**
 * @author Ahmed Samer
 * @date   2020-01-08
 * @description Declaration of the Story interface.
 * @filename user-request.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Connection } from "./connection";
import { Node } from "./node";
import { Preview } from "./preview";

/**
 * A user request.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface UserRequest<D extends Date | string = string> extends Node<D> {
  /**
   * A description of the request.
   *
   * @type {Array<string>}
   */
  description?: string;

  /**
   * The date when the request was published as a ISO-8601 formatted UTC date string
   *
   * @type {string}
   */
  expired?: number;

  /**
   * Predefined value by content management.
   *
   * @type {boolean}
   */
  isNew?: boolean;

  /**
   * A label for the request.
   *
   * @type {string}
   */
  label?: string;

  /**
   * The media assets used as previews for the request.
   *
   * @template D
   * @type {Connection<D, Preview>}
   */
  previews?: Connection<D, Preview>;

  /**
   * The status of the request .
   *
   * @template D
   * @type {UserRequestStatusKind<D>}
   */
  status?: UserRequestStatusKind;
}

/**
 * An enumeration of the kinds of the request  statuses.
 *
 * @enum {string}
 */
export enum UserRequestStatusKind {
  /**
   * The request  is available.
   */
  APPROVED = "APPROVED",

  /**
   * The request  is created but not yet available.
   */
  CREATED = "CREATED",

  /**
   * The request  is declined.
   */
  DECLINED = "DECLINED",

  /**
   * The request  is deleted.
   */
  DELETED = "DELETED",

  /**
   * The request  is uploaded but the attempt made to process the request  has failed.
   */
  ERROR = "ERROR",

  /**
   * The request  is uploaded and is being expired.
   */
  EXPIRED = "EXPIRED",

  /**
   * The request  is uploaded and is being processed.
   */
  PROCESSING = "PROCESSING"
}

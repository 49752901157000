/**
 * @author William Alexander Livesley
 * @date    2018-01-30
 * @filename  index.tsx
 * @LastModifiedBy Ahmed Samer
 * @LastModified 2019-02-26
 * @Copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import "regenerator-runtime/runtime";
import * as React from "react";
import ReactDOM from "react-dom";
import "../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { Loader } from "./react-components/page/loader";
import { connect, Provider } from "react-redux";
import { ERROR_MESSAGES } from "./constants/errors-constants";
import { ACTION_MODAL_ID } from "./constants/general-constants";
import { ACTION_MODAL_CONTEXT } from "./contexts/action-modal-context";
import { CognitoIdpAuthenticator } from "./react-components/authentication/cognito-idp-authenticator";
import { LinkedInAuthenticator } from "./react-components/authentication/linked-in-authenticator";
import { TwitterAuthenticator } from "./react-components/authentication/twitter-authenticator";
import { ActionModal, ActionModalProps } from "./react-components/modals/action-modal";
import { Modal } from "./react-components/modals/modal";
import HtmlPage from "./react-components/page/html-page";
import { Router } from "./utilities/router";
import { ErrorBoundary } from "./error-boundary";
import ReactGA from "react-ga";
import $ from "jquery";
import { LocalStorageKeys } from "./utilities/local-storage-keys";
import DetailsPanel from "./react-components/details-panel";
import { UserModals } from "./react-components/modals/user-modals";
import { AuthenticationModal } from "./react-components/modals/authentication";
import FullScreenVideo from "./react-components/full-screen-video";
import { ToastMessage } from "./react-components/page-framework/toast-message";
import { storageFactory } from "./utilities/local-storage-factory";
import { setProfileActiveTab, closeDetailsPanel, setAppState } from "./redux/actions";
import { ProfileTabsNames } from "./constants/profile-tabs-names";
import { store } from "./redux";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import * as Actions from "./redux/actions";
import { AppState as StoreState } from "./redux";
import { FetchHtmlPageAction, FetchHtmlPagePayload } from "./redux/cache/types.js";
import { SetAppStatePayload, SetAppStateAction } from "./redux/app/types.js";
import { HtmlPageLoading } from "./react-components/page/html-page-loading";
import { aliasCache } from "./redux/cache/alias-cache";
const localStore = storageFactory(() => localStorage);
import TagManager from "react-gtm-module";
import debounce from "lodash.debounce";
import { Helmet } from "react-helmet";
import logoImage from "../static/rv-logo-li.png";
import { MuteProvider } from "./react-components/page-framework/add-to-saved-button/contexts/mute-context";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

/**
 *
 *
 * @interface AppState
 */
interface AppState {
  /**
   *
   *
   * @type {ActionModalProps}
   * @memberof AppState
   */
  actionModalProps: ActionModalProps;

  /**
   *
   *
   * @type {React.ReactNode}
   * @memberof AppState
   */
  view: React.ReactNode;
}

interface MapStateProps {
  cache: { [s: string]: any };
  inProgress: { [s: string]: boolean };
  device: string;
}

interface MapDispatchProps {
  fetchHtmlPage: (payload: FetchHtmlPagePayload) => FetchHtmlPageAction;
  setAppState: (payload: SetAppStatePayload) => SetAppStateAction;
}

export type Props = MapDispatchProps & MapStateProps;

class App extends React.Component<Props, AppState> {
  constructor(props: any) {
    super(props);

    this.state = {
      actionModalProps: {
        allowCancelButton: false,
        buttonAction: null,
        buttonText: null,
        modalMessageBody: ERROR_MESSAGES.RE_SIGN_IN_MESSAGE,
        modalTitle: "Please sign in again",
      },

      view: <div />,
    };

    this.showModal = this.showModal.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    const currentHash = window.location.hash.replace("#", "");
    if (!isEmpty(currentHash)) {
      store.dispatch(setAppState({ currentHash }));
    }
    if (!this.props.device) {
      this.onResize();
    }
    if (process.env.ENABLE_ANALYTICS !== undefined) {
      // setup google analytics
      ReactGA.initialize(process.env.GA_ID, {
        debug: false,
        alwaysSendToDefaultTracker: false,
        standardImplementation: false,
      });
      ReactGA.set({ page: window.location.pathname });
      ReactGA.pageview(window.location.pathname + window.location.search);
      const tagManagerArgs = {
        gtmId: process.env.GTM_ID,
      };
      TagManager.initialize(tagManagerArgs);

      setTimeout(() => {
        // setup hot jar
        // Prevent errors during CI tests by dynamically loading the libraries
        const FACEBOOK_PIXEL = require("react-facebook-pixel").default;
        const advancedMatching = { em: "support@radivision.com" };
        const options = {
          autoConfig: true, // set pixel's autoConfig
          debug: true, // enable logs
        };
        // setup facebook pixel
        FACEBOOK_PIXEL.init(process.env.FB_PIXEL_ID, advancedMatching, options);
        FACEBOOK_PIXEL.pageView();

        const HOTJAR = require("react-hotjar");
        HOTJAR.hotjar.initialize(process.env.HOTJAR_ID, process.env.HOTJAR_VERSION);

        //  setup snowplow tracker
        // require("./utilities/analytics-snowplow")
        //   .analyticsSnowplow.init()
        //   .page({
        //     path: window.location.pathname + window.location.search + window.location.hash,
        //     search: window.location.search,
        //     url: window.location.href,
        //   });
      }, 7 * 1000);
    }

    window.addEventListener("rv-enter", this.onUrlChange, true);
    window.addEventListener("scroll", debounce(this.onScroll, 300), true);
    window.addEventListener("resize", debounce(this.onResize, 300), true);

    window.onhashchange = (event) => {
      this.onUrlChange(event);
    };
    window.onpopstate = (event) => {
      this.onUrlChange(event);
    };

    this.getElementToRender().then((node: React.ReactNode): void => {
      this.setState({ view: node });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("rv-enter", this.onUrlChange);
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  }

  onScroll() {
    const pageYOffset = window.pageYOffset;
    store.dispatch(Actions.setPageScroll({ pageYOffset }));
  }

  onUrlChange() {
    const currentHash = window.location.hash.replace("#", "");
    const currentCtx = Router.getParameterByName("ctx");

    const state = store.getState();
    if (isEmpty(currentHash) && isEmpty(currentCtx)) {
      store.dispatch(closeDetailsPanel());
    }
    if (!isEmpty(currentHash)) {
      store.dispatch(setAppState({ currentHash }));
    }
    const profileActiveTab = state.account.profileActiveTab;
    if (ProfileTabsNames.includes(currentHash) && profileActiveTab !== currentHash) {
      store.dispatch(setProfileActiveTab({ activeTab: currentHash }));
    }
    this.getElementToRender().then((node: React.ReactNode): void => {
      this.setState({ view: node });
    });
  }

  onResize() {
    const device =
      window.innerWidth > 1919
        ? "large"
        : window.innerWidth > 992
        ? "desktop"
        : window.innerWidth > 768
        ? "tablet"
        : "mobile";
    store.dispatch(Actions.setDevice({ device }));
  }

  componentDidUpdate(nextProps: Props) {
    if (nextProps.cache !== this.props.cache) {
      this.onUrlChange();
    }
  }

  showModal(modalProps: ActionModalProps) {
    if (!isEqual(modalProps.modalMessageBody, this.state.actionModalProps.modalMessageBody)) {
      this.setState({ actionModalProps: modalProps }, () => {
        $(`#${ACTION_MODAL_ID}`).modal({
          keyboard: false,
          backdrop: "static",
          show: true,
        });
      });
    }
  }

  /**
   *
   *
   * @returns {({
   *     userIdentityId: string;
   *     provider: "linkedin" | "twitter";
   *   })}
   * @memberof App
   */
  getUserIdentity(): {
    userIdentityId: string;
    provider: "linkedin" | "twitter";
  } {
    const userIdentity: {
      userIdentityId: string;
      provider: "linkedin" | "twitter";
    } = {
      userIdentityId: "",
      provider: "twitter",
    };

    userIdentity.userIdentityId = localStore.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_TWITTER_ID_CREDENTIALS);

    if (userIdentity.userIdentityId === undefined || userIdentity.userIdentityId === null) {
      userIdentity.userIdentityId = localStore.getItem(
        LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_LINKEDIN_ID_CREDENTIALS,
      );
      userIdentity.provider = "linkedin";
    }
    return userIdentity;
  }

  /**
   *Function responsible to handle url query parameters
   *
   * @returns {React.ReactNode}
   * @memberof App
   */
  handleQueryStringUrl(): Promise<React.ReactNode> {
    let query: URLSearchParams;
    let twitterToken: string;
    let twitterVerifier: string;
    const userIdentity: {
      userIdentityId: string;
      provider: "linkedin" | "twitter";
    } = this.getUserIdentity();
    const userIdentityId: string = userIdentity.userIdentityId;
    let linkedInCode: string;
    let linkedInState: string;
    let queryParameters: string[];
    let pageAlias: string;
    let result: Promise<React.ReactNode>;

    // initialize query string handler
    query = new URLSearchParams(window.location.search);
    // attempt to extract query parameters to identify linked in sign in
    linkedInCode = query.get("code");
    linkedInState = query.get("state");

    // attempt to extract query parameters to identify twitter sign in
    twitterToken = query.get("oauth_token");
    twitterVerifier = query.get("oauth_verifier");

    // validate each sign in case
    if (linkedInCode !== null && linkedInCode !== undefined && linkedInState !== null && linkedInState !== undefined) {
      result = Promise.resolve(<LinkedInAuthenticator code={linkedInCode} />);
    } else if (
      twitterToken !== null &&
      twitterToken !== undefined &&
      twitterVerifier !== null &&
      twitterVerifier !== undefined
    ) {
      result = Promise.resolve(<TwitterAuthenticator oauthToken={twitterToken} oauthVerifier={twitterVerifier} />);
    }
    // other the two third party sign in we handle query parameter as router parameter
    else {
      // attempt to extract query parameters from
      result = Promise.all([Router.getQueryParameters(), Router.getAlias()]).then((res) => {
        queryParameters = res[0];
        pageAlias = res[1];

        const cacheKey = `${pageAlias}-${queryParameters.join()}`;
        const cache = this.props.cache;
        const cachedAlias = aliasCache[cacheKey];

        const inProgress = this.props.inProgress[cacheKey];

        const variables = {
          userIdentityId,
          queryParameters,
          alias: pageAlias,
        };
        const htmlDocument = cache[cacheKey] || cachedAlias;
        if (isEmpty(htmlDocument) && !inProgress) {
          this.props.fetchHtmlPage({ variables, cacheKey });
        }

        let node;
        node = htmlDocument ? (
          <HtmlPage htmlDocument={htmlDocument} cacheKey={cacheKey} />
        ) : (
          <Loader isActive={true} splash={true} />
        );
        return Promise.resolve(node);
      });
    }
    return result;
  }

  /**
   * Function responsible to handle which element should be rendered
   *
   * @memberof App
   */
  getElementToRender(): Promise<React.ReactNode> {
    const idpTokens: string = window.location.hash ? window.location.hash : "";
    let idpParameters: any;
    let isAccessToken: boolean;
    let result: Promise<React.ReactNode>;

    // apply regular expression to determine if access token matches
    isAccessToken = /access_token/i.test(idpTokens);
    if (isAccessToken) {
      idpParameters = {};
      idpTokens
        .replace("#", "")
        .split("&")
        .forEach((value: string): any => {
          const vl = value.split("=");
          idpParameters[vl[0]] = vl[1];
        });
      result = Promise.resolve(<CognitoIdpAuthenticator tokens={idpParameters} />);
    } else {
      result = this.handleQueryStringUrl();
    }
    return result;
  }

  render(): React.ReactNode {
    return (
      <div>
        <Helmet>
          <meta
            name="keywords"
            content="radivision, entrepreneur, network, entrepreneurship, award-winning, mentor, digital TV, Radivision, Entrepreneur, Entrepreneurship, Network, Award-Winning, Mentor, Digital TV"
          />
          <meta
            name="description"
            content="Radivision® is the Startup World streaming + community platform, connecting viewers to entertaining and informative content,  resources, investment opportunities, new product discovery, and more. Radivision democratizes access to the Entrepreneurial Revolution while helping more startups and fund managers get funded, and more investors grow wealth in the 70% higher performing private markets!"
          />
          <meta
            property="og:description"
            data-react-helmet="true"
            content="Radivision® is the Startup World streaming + community platform, connecting viewers to entertaining and informative content,  resources, investment opportunities, new product discovery, and more. Radivision democratizes access to the Entrepreneurial Revolution while helping more startups and fund managers get funded, and more investors grow wealth in the 70% higher performing private markets!"
          />
          <meta name="image" property="og:image" data-react-helmet="true" content={logoImage} />
          <meta property="og:image:type" data-react-helmet="true" content="image/jpg" />
          <meta property="og:url" data-react-helmet="true" content="https://www.radivision.com/" />
          <meta property="og:type" data-react-helmet="true" content="website" />
          <meta property="og:title" data-react-helmet="true" content="Radivision®: The Entrepreneurship Network" />
          <meta property="og:site_name" data-react-helmet="true" content="Radivision" />
          <title data-react-helmet="true">Radivision®: The Entrepreneurship Network</title>
        </Helmet>
        <HtmlPageLoading />
        <DetailsPanel />
        <UserModals />
        <MuteProvider>
          {this.state.view}
          <ACTION_MODAL_CONTEXT.Provider
            value={{
              showModal: this.showModal,
            }}
          >
            <Modal
              id={ACTION_MODAL_ID}
              className="modal-dialog-centered modal-sm"
              title={this.state.actionModalProps.modalTitle}
            >
              <ActionModal {...this.state.actionModalProps} />
            </Modal>
          </ACTION_MODAL_CONTEXT.Provider>
        </MuteProvider>

        <FullScreenVideo />
        <ToastMessage />
      </div>
    );
  }
}

const mapState = (state: StoreState) => ({
  cache: state.cache.store,
  device: state.app.device,
  inProgress: state.cache.inProgress,
});

const mapDispatch = {
  fetchHtmlPage: Actions.fetchHtmlPage,
  setAppState: Actions.setAppState,
};

const ConnectedApp = connect(mapState, mapDispatch)(App);
// ReactDOM.render(
//  <ErrorBoundary>
//    <Provider store={store}>
//      <ConnectedApp />
//    </Provider>
//  </ErrorBoundary>,
//  document.getElementById("root"),
// );

const RootApp = (
  <ErrorBoundary>
    <Provider store={store}>
      <AuthenticationModal />
      <ConnectedApp />
    </Provider>
  </ErrorBoundary>
);

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(RootApp, rootElement);
} else {
  ReactDOM.render(RootApp, rootElement);
}

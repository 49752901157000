/**
 * @author William Alexander Livesley
 * @date   2018-03-20
 * @description Declaration of the StoryRating interface.
 * @filename story-rating.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

/**
 * The __typeID of the GraphQL Story Rating object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_STORY_RATING: string = "StoryRating";

/**
 * The rating information for a story
 *
 * @interface
 */
export interface StoryRating {
  /**
   * The total number of four star ratings
   *
   * @type {number}
   */
  fourStarCount?: number;

  /**
   * The total number of five star ratings
   *
   * @type {number}
   */
  fiveStarCount?: number;

  /**
   * The total number of one star ratings
   *
   * @type {number}
   */
  oneStarCount?: number;

  /**
   * The total number of three star ratings
   *
   * @type {number}
   */
  threeStarCount?: number;

  /**
   * The total number of two star ratings
   *
   * @type {number}
   */
  twoStarCount?: number;

  /**
   * The typename of the Story Rating.
   *
   * @type {string}
   */
  __typename?: string;
}

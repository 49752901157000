import { getClientMutationId } from "../../utilities/general";
import { commitMutation } from "../../utilities/relay";
import { ENVIRONMENT } from "../../relay/relay-environment";
import { CREATE_RADIVISION_NOTIFICATION_MUTATION } from "../../relay/mutations/create-radivision-notification";
import {NotificationMetaDataInput} from "./types";

export interface CreateNotificationInput {
  clientMutationId: string;
  notificationKind: string;
  email: string;
  meta?: NotificationMetaDataInput;
}
export const createRadivisionNotification = (
  notificationKind: string,
  email: string,
  meta: NotificationMetaDataInput | undefined,
) => {
  const createNotificationInput: CreateNotificationInput = {
    clientMutationId: getClientMutationId(),
    notificationKind,
    email,
    meta,
  };

  return commitMutation(ENVIRONMENT, {
    mutation: CREATE_RADIVISION_NOTIFICATION_MUTATION,
    variables: {
      input: createNotificationInput,
    },
  }).then((response) => {
    return response.createRadivisionNotification.hasErrors === true
      ? Promise.reject(response.createRadivisionNotification.errors)
      : Promise.resolve(response.createRadivisionNotification.notification);
  });
};

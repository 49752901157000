import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../redux";
import { CompanyDetails } from "./company-details";
import { Entity, Person, GRAPHQL_TYPE_PERSON } from "@radivision/graphql";

import { PeoplePortfolioPanel, CompaniesPortfolioPanel } from "../portfolio";

interface ComponentProps {
  subject: Person | Entity;
}

interface MapDispatchProps {}
interface MapStateProps {}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const AboutProfileComponent = (props: Props) => {
  const { subject } = props;
  const isPerson = subject?.__typename === GRAPHQL_TYPE_PERSON;
  return (
    <Container>
      <h3 className="section-title">About</h3>
      <div className="about-description">{subject?.description}</div>

      {isPerson && (
        <div className="location-address">
          {Array.isArray(subject?.locations) && subject?.locations.map(({ label }) => <div key={label}>{label}</div>)}
        </div>
      )}
      <CompanyDetails entity={subject} />
      {!isPerson && (
        <div>
          {/* <CompaniesPortfolioPanel entity={subject} /> */}
          <PeoplePortfolioPanel entity={subject} />
        </div>
      )}
    </Container>
  );
};

const Container = ({ children }: React.PropsWithChildren<any>) => <div className="about-profile">{children}</div>;
const mapState = (state: AppState) => ({
  user: state.account.user,
});

const mapDispatch = {};

export const AboutProfile = connect(mapState, mapDispatch)(AboutProfileComponent);

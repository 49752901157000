/**
 * @author Ahmed Serag
 * @date   2018-08-19
 * @description Implementation of the defaults.
 * @filename badges.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

export const DEFAULTS: {
  [index: string]: any;
} = {
  DEFAULT_RadivisionPost_CAROUSEL: "c7/cw/c7cw8RXeRt2zBRhPY8AvlQ.png",
  DEFAULT_RadivisionPost_HEADSHOT: "c7/cw/c7cw8RXeRt2zBRhPY8AvlQ.png",
  DEFAULT_RadivisionPost_HERO: "c7/cw/c7cw8RXeRt2zBRhPY8AvlQ.png",
  DEFAULT_RadivisionPost_LOGO: "c7/cw/c7cw8RXeRt2zBRhPY8AvlQ.png",  
  DEFAULT_RadivisionPost_SQUARE: "c7/cw/c7cw8RXeRt2zBRhPY8AvlQ.png",
  DEFAULT_ArticleStory_CAROUSEL: "u9/dj/u9dJtuXWTgmrxcxmkDAxsA.png",
  DEFAULT_ArticleStory_HEADSHOT: "u9/dj/u9dJtuXWTgmrxcxmkDAxsA.png",
  DEFAULT_ArticleStory_HERO: "u9/dj/u9dJtuXWTgmrxcxmkDAxsA.png",
  DEFAULT_ArticleStory_LOGO: "u9/dj/u9dJtuXWTgmrxcxmkDAxsA.png",
  DEFAULT_ArticleStory_SQUARE: "u9/dj/u9dJtuXWTgmrxcxmkDAxsA.png",
  DEFAULT_BookStory_CAROUSEL: "qe/lp/QElPouixSWuiHhIKChOmJg.png",
  DEFAULT_BookStory_HEADSHOT: "qe/lp/QElPouixSWuiHhIKChOmJg.png",
  DEFAULT_BookStory_HERO: "qe/lp/QElPouixSWuiHhIKChOmJg.png",
  DEFAULT_BookStory_LOGO: "qe/lp/QElPouixSWuiHhIKChOmJg.png",
  DEFAULT_BookStory_SQUARE: "qe/lp/QElPouixSWuiHhIKChOmJg.png",
  DEFAULT_Entity_CAROUSEL: "yj/sv/YjSVmJYPQkSWqtHprDdkdw.png",
  DEFAULT_Entity_HEADSHOT: "yj/sv/YjSVmJYPQkSWqtHprDdkdw.png",
  DEFAULT_Entity_HERO: "yj/sv/YjSVmJYPQkSWqtHprDdkdw.png",
  DEFAULT_Entity_LOGO: "yj/sv/YjSVmJYPQkSWqtHprDdkdw.png",
  DEFAULT_Entity_SQUARE: "yj/sv/YjSVmJYPQkSWqtHprDdkdw.png",
  DEFAULT_Entity_BACKGROUND: "yj/sv/YjSVmJYPQkSWqtHprDdkdw.png",
  DEFAULT_NewsletterStory_CAROUSEL: "7t/il/7tiL3WBPTuuOq2fKAd8LLg.png",
  DEFAULT_NewsletterStory_HEADSHOT: "7t/il/7tiL3WBPTuuOq2fKAd8LLg.png",
  DEFAULT_NewsletterStory_HERO: "7t/il/7tiL3WBPTuuOq2fKAd8LLg.png",
  DEFAULT_NewsletterStory_LOGO: "7t/il/7tiL3WBPTuuOq2fKAd8LLg.png",
  DEFAULT_NewsletterStory_SQUARE: "7t/il/7tiL3WBPTuuOq2fKAd8LLg.png",
  DEFAULT_OnlineCourseStory_CAROUSEL: "ft/4s/FT4SCCViROeYRWrmNJZtHw.png",
  DEFAULT_OnlineCourseStory_HEADSHOT: "ft/4s/FT4SCCViROeYRWrmNJZtHw.png",
  DEFAULT_OnlineCourseStory_HERO: "ft/4s/FT4SCCViROeYRWrmNJZtHw.png",
  DEFAULT_OnlineCourseStory_LOGO: "ft/4s/FT4SCCViROeYRWrmNJZtHw.png",
  DEFAULT_OnlineCourseStory_SQUARE: "ft/4s/FT4SCCViROeYRWrmNJZtHw.png",
  DEFAULT_Person_CAROUSEL: "vd/5n/vD5nrZCFQbaZ7in5bfTkiw.png",
  DEFAULT_Person_HEADSHOT: "vd/5n/vD5nrZCFQbaZ7in5bfTkiw.png",
  DEFAULT_Person_HERO: "vd/5n/vD5nrZCFQbaZ7in5bfTkiw.png",
  DEFAULT_Person_LOGO: "vd/5n/vD5nrZCFQbaZ7in5bfTkiw.png",
  DEFAULT_Person_SQUARE: "vd/5n/vD5nrZCFQbaZ7in5bfTkiw.png",
  DEFAULT_Person_BACKGROUND: "rs/li/rSlIHwOhRdmFtPRoPrt87w.jpg",
  DEFAULT_PodcastStory_CAROUSEL: "kj/5m/kJ5MzJU4QtKn7A8IquM5ug.png",
  DEFAULT_PodcastStory_HEADSHOT: "kj/5m/kJ5MzJU4QtKn7A8IquM5ug.png",
  DEFAULT_PodcastStory_HERO: "kj/5m/kJ5MzJU4QtKn7A8IquM5ug.png",
  DEFAULT_PodcastStory_LOGO: "kj/5m/kJ5MzJU4QtKn7A8IquM5ug.png",
  DEFAULT_PodcastStory_SQUARE: "kj/5m/kJ5MzJU4QtKn7A8IquM5ug.png",
  DEFAULT_OriginalContentStory_CAROUSEL: "kj/5m/kJ5MzJU4QtKn7A8IquM5ug.png",
  DEFAULT_OriginalContentStory_HEADSHOT: "kj/5m/kJ5MzJU4QtKn7A8IquM5ug.png",
  DEFAULT_OriginalContentStory_HERO: "kj/5m/kJ5MzJU4QtKn7A8IquM5ug.png",
  DEFAULT_OriginalContentStory_LOGO: "kj/5m/kJ5MzJU4QtKn7A8IquM5ug.png",
  DEFAULT_OriginalContentStory_SQUARE: "kj/5m/kJ5MzJU4QtKn7A8IquM5ug.png",
  DEFAULT_DynamicList_SQUARE: "00/11/0011kzi5VETx7ku0.png",
  DEFAULT_DynamicList_CAROUSEL: "00/11/0011kzi5VETx7ku0.png",
  DEFAULT_FixedList_SQUARE: "00/11/0011kzi5VETx7ku0.png",
  DEFAULT_FixedList_CAROUSEL: "00/11/0011kzi5VETx7ku0.png"
};

/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2019-02-11
 * @filename: content-moderation-state.ts
 * @description: Declaration of content moderation state interface
 */

import { ContentModerationStateKind } from "./content-moderation-state-kind";
import { Node } from "./node";

/**
 * The __typeID of the Moderation State type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_CONTENT_MODERATION_STATE: string =
  "ContentModerationState";

/**
 *The content moderation state of a story
 *
 * @export
 * @interface ContentModerationState
 * @extends {Node<D>}
 * @template D
 */
export interface ContentModerationState<D extends Date | string = string>
  extends Node<D> {
  
  /**
   * A description of the moderation state
   *
   * @type {string}
   * @memberof ContentModerationState
   */
  description?: string;

  /**
   *  The kind of the moderation state
   *
   * @type {ContentModerationStateKind}
   * @memberof ContentModerationState
   */
  kind: ContentModerationStateKind;

  /**
   *  The label of the moderation state
   *
   * @type {string}
   * @memberof ContentModerationState
   */
  label?: string;
}

import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import {
  FormFieldContainer,
  FormFieldInput,
  FormSectionTitle,
  CharsCount,
  FormSection,
} from "../../../react-components/modals/modal-form-elements";
import { ProfileModuleState, SetProfileOpportunityPayload, SetProfileOpportunityAction } from "../redux/profile/types";
import { setProfileOpportunity } from "../redux/profile/actions";
import { GRAPHQL_TYPE_ENTITY, Opportunities as OpportunitiesType } from "@radivision/graphql";

import isEmpty from "lodash.isempty";

interface MapStateProps {
  opportunities: OpportunitiesType;
  isCompany: boolean;
}

interface MapDispatchProps {
  setProfileOpportunity: (payload: SetProfileOpportunityPayload) => SetProfileOpportunityAction;
}

const DESCRIPTION_MAX_LENGTH = 250;
const URL_MAX_LENGTH = 127;

export type Props = MapDispatchProps & MapStateProps;

export const OpportunitiesFields = [
  {
    title: "Career",
    name: "careers",
  },
  {
    title: "Other",
    name: "collaboration",
  },
];

const textareaId = (id: number) => `profile-opportunities-${id}`;

export const Component = (props: Props) => {
  const { opportunities, setProfileOpportunity, isCompany } = props;

  const [charsCount, setCharsCount] = React.useState<{ [s: string]: number }>({});

  React.useEffect(() => {
    Object.keys(opportunities).forEach((k) => {
      const opportunity = opportunities[k];
      const description = !isEmpty(opportunity?.description) ? opportunity?.description : "";
      setCharsCount((prev) => ({ ...prev, [k]: description ? description.length : 0 }));
    });
  }, [opportunities]);

  React.useEffect(function resizeTextarea() {
    [0, 1].forEach((index) => {
      const textarea = document.getElementById(textareaId(index));
      if (!textarea) {
        return;
      }
      try {
        textarea.setAttribute(
          "style",
          `min-height:100px;max-height:200px;height:${textarea.scrollHeight}px;overflow-y:auto;`,
        );
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      } catch (error) {}
    });
  }, []);

  if (!isCompany) return null;

  return (
    <>
      {[0, 1].map((index) => {
        const field = OpportunitiesFields[index];
        const opportunity = opportunities && opportunities[field.name];
        const value = !isEmpty(opportunity?.description) ? opportunity?.description : "";
        const urlValue = !isEmpty(opportunity?.link) ? opportunity?.link : "";
        return (
          <FormSection key={`profile-opportunities-${index}`}>
            <FormSectionTitle>{field.title}</FormSectionTitle>
            <FormFieldContainer>
              <label htmlFor={`${field.name}-description`}>
                <span>Description:</span>
              </label>
              <FormFieldInput>
                <textarea
                  rows={4}
                  name={`${field.name}-description`}
                  id={textareaId(index)}
                  maxLength={DESCRIPTION_MAX_LENGTH}
                  value={value ? value : null}
                  onChange={(e) =>
                    setProfileOpportunity({ name: field.name, key: "description", value: e.target.value })
                  }
                />
                <CharsCount count={charsCount[field.name]} limit={DESCRIPTION_MAX_LENGTH} />
              </FormFieldInput>
            </FormFieldContainer>
            <FormFieldContainer>
              <label htmlFor={`${field.name}-url`}>
                <span>Link URL or an email address: </span>
              </label>
              <FormFieldInput>
                <input
                  type="text"
                  name={`${field.name}-url`}
                  placeholder={`${field.title} URL`}
                  maxLength={URL_MAX_LENGTH}
                  value={urlValue ? urlValue : ""}
                  onChange={(e) => setProfileOpportunity({ name: field.name, key: "link", value: e.target.value })}
                />
              </FormFieldInput>
            </FormFieldContainer>
          </FormSection>
        );
      })}
    </>
  );
};

const mapState = (state: AppState & ProfileModuleState) => {
  return {
    opportunities: state.profile.opportunities,
    isCompany: state.profile.type === GRAPHQL_TYPE_ENTITY,
  };
};

const mapDispatch = {
  setProfileOpportunity: setProfileOpportunity,
};

export const Opportunities = connect(mapState, mapDispatch)(Component);

/**
 * @author Ahmed Samer
 * @date   2021-03-16
 * @description Declaration of the MarketStory interface.
 * @filename market-story.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Story } from "./story";
import { DATE_TIME } from "./date-time";

/**
 * The __typeID of the API market entry Story GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_MARKET_STORY: string = "MarketEntryStory";

/**
 * A story concerning an market entry.
 *
 * @extends {Story}
 * @template D
 * @interface
 */
export interface MarketStory<D extends Date | string = string>
  extends Story<D> {
  /**
   * The URL of the market entry.
   *
   * @type {string}
   */
  link?: string;

  /**
   *
   *
   * @type {DATE_TIME}
   * @memberof LinkedInUser
   */
  expires?: DATE_TIME;

  /**
   *
   *
   * @type {MarketEntryStoryStatusKind}
   */
  state?: MarketEntryStoryStatusKind;

  /**
   * promotions status of the story.
   *
   * @type {boolean}
   */
  isPromoted?: boolean;

  /**
   * The promoCode of the market entry.
   *
   * @type {string}
   */
  promoCode?: string;

   /**
   * 
   *
   * @type {MarketEntryKind}
   */
  kind?: MarketEntryKind;


  offerType?: string;
}


/**
 * An enumeration of the kinds of.
 *
 * @enum {string}
 */
export enum MarketEntryStoryStatusKind {
  /**
   * The market entry is available.
   */
  AVAILABLE = "AVAILABLE",

  /**
   * The market entry is created but not yet available.
   */
  CREATED = "CREATED",

  /**
   * The market entry is deleted.
   */
  ACCEPTED = "ACCEPTED",

  /**
   * The market entry is uploaded but the attempt made to process the market entry has failed.
   */
  ERROR = "ERROR",

  /**
   *
   */
  REJECTED = "REJECTED",
}

export enum MarketEntryKind {
  /**
   *
   *
   */
  FEATURED_ENTRY = "FEATURED_ENTRY",

  /**
   *
   *
   */
  PROMOTED_ENTRY = "PROMOTED_ENTRY",

  /**
   *
   *
   */
  ENTRY = "ENTRY",
}

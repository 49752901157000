export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_SAVED_ITEMS = "UPDATE_SAVED_ITEMS";
export const FETCH_SAVED_ITEMS = "FETCH_SAVED_ITEMS";
export const SET_LOCAL_STORAGE = "SET_LOCAL_STORAGE";
export const FOLLOW_RESOURCE = "FOLLOW_RESOURCE";
export const UNFOLLOW_RESOURCE = "UNFOLLOW_RESOURCE";
export const SAVE_RESOURCE = "SAVE_RESOURCE";
export const UNSAVE_RESOURCE = "UNSAVE_RESOURCE";
export const ADD_SAVE_ITEM_LOADING = "ADD_SAVE_ITEM_LOADING";
export const REMOVE_SAVE_ITEM_LOADING = "REMOVE_SAVE_ITEM_LOADING";
export const UPDATE_USER_MY_LIST = "UPDATE_USER_MY_LIST";
export const UPDATE_USER_MY_LIST_SUCCESS = "UPDATE_USER_MY_LIST_SUCCESS";
export const REMOVE_FROM_SAVED_ITEMS = "REMOVE_FROM_SAVED_ITEMS";
export const FETCH_CURRENT_USER = "FETCH_CURRENT_USER";
export const FETCHING_CURRENT_USER = "FETCHING_CURRENT_USER";
export const REMOVE_FROM_USER_REQUESTS = "REMOVE_FROM_USER_REQUESTS";
export const UPDATE_USER_REQUESTS = "UPDATE_USER_REQUESTS";
export const SET_SETTINGS_MENU = "SET_SETTINGS_MENU";
export const DEACTIVATE_ACCOUNT_REQUEST = "DEACTIVATE_ACCOUNT_REQUEST";
export const DEACTIVATE_ACCOUNT_FULFILLED = "DEACTIVATE_ACCOUNT_FULFILLED";
export const SET_ACCOUNT_IS_LOADING = "SET_ACCOUNT_IS_LOADING";
export const CHANGE_EMAIL_REQUEST = "CHANGE_EMAIL_REQUEST";
export const CHANGE_EMAIL_FULFILLED = "CHANGE_EMAIL_FULFILLED";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_FULFILLED = "CANCEL_SUBSCRIPTION_FULFILLED";
export const SET_PROFILE_ACTIVE_TAB = "SET_PROFILE_ACTIVE_TAB";
export const FETCH_CURRENT_USER_REVISION = "FETCH_CURRENT_USER_REVISION";
export const UPDATE_USER_REVISION = "UPDATE_USER_REVISION";
export const SET_PITCH_PLANET_FILTER = "SET_PITCH_PLANET_FILTER";
export const VERIFY_CHANGE_EMAIL = "VERIFY_CHANGE_EMAIL";

export const SETTING_SIGN_IN_AND_SECURITY = "Sign In and Security";
export const SETTING_BILLING_AND_PAYMENTS = "Billing and Payments";
export const PITCH_PLANET_FILTERS = ["stage","industry","activity"];

import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { FormFieldContainer, FormFieldInput } from "../../../react-components/modals/modal-form-elements";
import { SetProfileStateAction, SetProfileStatePayload, ProfileModuleState } from "../redux/profile/types";
import { setProfileState } from "../redux/profile/actions";
import {GRAPHQL_TYPE_PERSON} from "@radivision/graphql";

interface MapStateProps {
  fullName: string;
  isPerson: boolean;
}

interface MapDispatchProps {
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { fullName, setProfileState, isPerson } = props;

  if (!isPerson) return null;

  return (
    <FormFieldContainer>
      <label htmlFor="fullName">
        <span>Full Name: *</span>
      </label>
      <FormFieldInput>
        <input
          type="text"
          name="fullName"
          maxLength={70}
          value={fullName ? fullName : ""}
          onChange={(e) => setProfileState({ fullName: e.target.value })}
        />
      </FormFieldInput>
    </FormFieldContainer>
  );
};

const mapState = (state: AppState & ProfileModuleState) => {
  return {
    fullName: state.profile.fullName,
    isPerson: state.profile.type === GRAPHQL_TYPE_PERSON,
  };
};

const mapDispatch = {
  setProfileState: setProfileState,
};

export const FullName = connect(mapState, mapDispatch)(Component);

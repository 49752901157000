/**
 * @author: Adham El Banhawy
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2018-08-05
 * @filename: linked-in-authentication-query.ts
 * @description: implementation of GraphQL query to retrieve a linkedin user OpenId Token.
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL query to retrieve the linkedin user OpenId Token.
 *
 * @type {GraphQLTaggedNode}
 */
export const LINKED_USER_GRAPHQL_QUERY: GraphQLTaggedNode = graphql`
  query linkedInAuthenticationQuery($linkedInCode: String!) {
    linkedInUser(id: $linkedInCode) {
      errors {
        created
        id
        location
        type
      }
      hasErrors
      linkedInUser {
        id
        linkedInUserId
        openIdToken
      }
    }
  }
`;

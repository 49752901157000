import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { FormFieldContainer, FormFieldInput } from "../../../react-components/modals/modal-form-elements";
import { SetProfileStateAction, SetProfileStatePayload, ProfileModuleState } from "../redux/profile/types";
import { setProfileState } from "../redux/profile/actions";
import { LabelToolTip } from "../../../react-components/page-framework/label-tool-tip";
import { getTodayDate } from "../../../utilities/general";
import isEmpty from "lodash.isempty";
import { GRAPHQL_TYPE_ENTITY } from "@radivision/graphql";

interface MapStateProps {
  founded: string;
  isCompany: boolean;
}

interface MapDispatchProps {
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { founded, setProfileState, isCompany } = props;
  const defaultDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}`;
  const [placeholder, setPlaceholder] = React.useState(defaultDate);
  const tooltipText = "The date the Company/Organization was established.";

  if (!isCompany) return null;

  return (
    <FormFieldContainer>
      <label htmlFor="founded">
        <span>Founded: *</span>
        <LabelToolTip text={tooltipText} />
      </label>
      <p className="field-help-text">{tooltipText}</p>
      <FormFieldInput>
        <input
          type="month"
          placeholder="YYYY-MM"
          max={getTodayDate()}
          value={isEmpty(founded) ? (placeholder ? placeholder : "") : founded}
          className={isEmpty(founded) ? "is-grey" : ""}
          onBlur={() => {
            setPlaceholder(defaultDate);
          }}
          onFocus={() => {
            setPlaceholder(null);
          }}
          id="founded"
          disabled={false}
          onChange={(e) => {
            setProfileState({ founded: e.target.value });
          }}
        />
      </FormFieldInput>
    </FormFieldContainer>
  );
};

const mapState = (state: AppState & ProfileModuleState) => {
  return {
    founded: state.profile.founded,
    isCompany: state.profile.type === GRAPHQL_TYPE_ENTITY,
  };
};

const mapDispatch = {
  setProfileState: setProfileState,
};

export const Founded = connect(mapState, mapDispatch)(Component);

import * as React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import YouTube from "react-youtube";
import Truncate from "react-truncate";
import { Loader } from "../../page/loader";
import { useOnScreen } from "../../../utilities/use-on-screen";
import { ProfilePictureName } from "../../page-framework/profile-picture-name";
import { getProfileInfo } from "../../../utilities/get-profile-info";
import {
  PreviewKind,
  TopLevelMediaKind,
  GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
  PitchVideoStory,
  OriginalContentStory,
} from "@radivision/graphql";
import { Router } from "../../../utilities/router";
import { AppState } from "../../../redux";
import * as Actions from "../../../redux/actions";
import { OptimizedImageUrls } from "../../../component-configuration/optimized-image";
import { ImageHelper } from "../../../utilities/image-helper";
import { gaEvent } from "../../../utilities/general";
import isEmpty from "lodash.isempty";
import { SetAppStatePayload, SetAppStateAction } from "../../../redux/app/types";
import { SetProfileActiveTabPayload, SetProfileActiveTabAction } from "../../../redux/account/types";
import { SelectPitchVersion } from "../../inner-pages/profile-details-tab/investment/pitch-video-panel";
import { SVG } from "../../../component-configuration/svgs";
import { extractYoutubeId } from "../../../utilities/extract-youtube-id";

interface ComponentProps {
  slideIndex: number;
  currentSlideIndex: number;
  slickNext: () => void;
  showBtn?: boolean;
  item: OriginalContentStory & PitchVideoStory;
}

interface MapDispatchProps {
  setAppState: (payload: SetAppStatePayload) => SetAppStateAction;
  setProfileActiveTab: (payload: SetProfileActiveTabPayload) => SetProfileActiveTabAction;
}

interface MapStateProps {
  videoId: string | null;
  isPlayingFullScreen: boolean;
}

type Props = ComponentProps & MapStateProps & MapDispatchProps;

export const Component = (props: Props) => {
  const {
    currentSlideIndex,
    slideIndex,
    item,
    slickNext,
    videoId,
    isPlayingFullScreen,
    showBtn,
    setAppState,
    setProfileActiveTab,
  } = props;

  const [videoReady, setVideoReady] = React.useState(false);
  const thisRef = React.useRef(null);
  const isOnScreen = useOnScreen(thisRef);

  const eitherPitch = item?.trailer || item?.originalClip ;
  const DEFAULT_VIDEO_ID: string =
    item.__typename === GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY ? item?.externalId : eitherPitch?.externalId;

  const [youtubeId, setYoutubeId] = React.useState(DEFAULT_VIDEO_ID || extractYoutubeId(item?.link));
  const [readMore, setReadMore] = React.useState(false);

  const notPlayingFullScreenVideo = isEmpty(videoId) && !isPlayingFullScreen;
  const isCurrent = currentSlideIndex === slideIndex;
  const shouldPlayVideo = isCurrent && notPlayingFullScreenVideo && youtubeId;
  const hasMissingVideo = isEmpty(item?.originalClip?.externalId) || isEmpty(item?.trailer?.externalId);

  React.useEffect(() => {
    if (!isCurrent) {
      setVideoReady(false);
      setReadMore(false);
    }
  }, [isCurrent]);

  const imageUrls: OptimizedImageUrls = item?.previews
    ? ImageHelper.fetchOptimizedImageUrl({
        imageType: "PREVIEW",
        preview: {
          content: item.previews,
          previewKind: PreviewKind.CAROUSEL,
          topLevelMedia: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: { containerWidthRatio: 3 / 4, numberOfItems: 1 },
        isSquareImage: false,
        revision: item.revision,
      })
    : null;

  const backgroundImageUrl =
    imageUrls?.requestedResolutionUrl ||
    imageUrls?.screenResolutionUrl ||
    `https://i.ytimg.com/vi/${item?.externalId}/sddefault.jpg`;
  const backgroundImage = backgroundImageUrl && `url('${backgroundImageUrl}')`;

  const company = item && item.peopleEntities && item.peopleEntities[0] && item.peopleEntities[0].entity;
  const referral = item && item.peopleEntities && item.peopleEntities[1] && item.peopleEntities[1].entity;
  const { profileUrl: referralUrl } = getProfileInfo(referral);

  const isPrerender = /HeadlessChrome/i.test(navigator.userAgent);
  const { profileUrl } = getProfileInfo(company);
  const currentVersion = youtubeId === item?.trailer?.externalId ? "99sec" : "5min";
  const investmentsBy = Array.isArray(company?.investmentsBy) && company?.investmentsBy[0];
  return (
    <div ref={thisRef}>
      <Container>
        <div className="referral">
          {referral && (
            <>
              <div className="referral-name">
                <p className="nominated-by">Nominated By</p>
                <a
                  href={referralUrl}
                  className="nominated-by"
                  onClick={(e: any) => {
                    e.preventDefault();
                    Router.to(referralUrl);
                  }}
                >
                  {referral?.name}
                </a>
              </div>
              <ProfilePictureName subject={referral} />
            </>
          )}
        </div>
        <VideoContainer>
          <Loader isActive={!videoReady} />
          <div
            style={{
              pointerEvents: "none",
              backgroundImage,
              opacity: !isOnScreen ? 1 : 0,
            }}
            className="pitch-poster"
          />
          <Video style={{ backgroundImage: isCurrent ? "none" : backgroundImage }}>
            {shouldPlayVideo && (
              <YouTube
                videoId={youtubeId}
                opts={{
                  height: "100%",
                  width: "100%",
                  playerVars: {
                    rel: 0,
                    autoplay: 1,
                    mute: 1,
                    loop: 0,
                    playsinline: 1,
                    start: 0,
                  },
                }}
                id={youtubeId}
                onPlay={() => {}}
                onReady={(event) => {
                  setTimeout(() => {
                    setVideoReady(true);
                    event?.target?.playVideo();
                  }, 100);
                }}
                onEnd={() => {
                  slickNext();
                }}
                className="iframe"
                width="100%"
                height="100%"
              />
            )}
          </Video>
        </VideoContainer>
        {!hasMissingVideo &&
          (item.__typename === "PitchVideoStory" ? (
            <SelectPitchVersion
              selected={currentVersion}
              className={`highlighted ${item?.trailer?.externalId ? "has-quick" : ""}  ${
                item?.originalClip?.externalId ? "has-full" : ""
              }`}
              onClick={(version: string) => {
                const video = version === "99sec" ? item?.trailer : item?.originalClip;
                if (video) {
                  setYoutubeId(video?.externalId);
                }
              }}
            />
          ) : null)}
        <CardTitle>
          <ProfilePictureName subject={company} />
        </CardTitle>
        <CardTitle>
          <CardText>
            {!readMore && !isPrerender && item?.description?.length > 100 ? (
              <>
                <Truncate lines={1} ellipsis={<span className="ellipsis">...</span>}>
                  {item?.description}
                </Truncate>
                <div className="read-more" onClick={() => setReadMore(true)}>
                  Read more
                </div>
              </>
            ) : (
              item?.description
            )}
          </CardText>
        </CardTitle>
        <CardTitle>
        </CardTitle>
      </Container>
    </div>
  );
};

const mapState = (state: AppState) => ({
  videoId: state.fullScreenVideo.videoId,
  isPlayingFullScreen: state.fullScreenVideo.isPlayingFullScreen,
});

const mapDispatch = {
  setAppState: Actions.setAppState,
  setProfileActiveTab: Actions.setProfileActiveTab,
};
export const Card = connect(mapState, mapDispatch)(Component);

export const CardText = ({ children }) => {
  return (
    <div className={`pitch-card-text sm-scroller`}>
      <span>{children}</span>
    </div>
  );
};

export const Button = ({
  isOutline,
  text,
  onClick,
  isDisabled,
  target,
}: {
  isOutline: boolean;
  isDisabled?: boolean;
  text: any;
  target?: string;
  onClick?: () => void;
}) => {
  return (
    <a
      className={`pitch-card-button ${isOutline ? "outline-button" : ""} ${isDisabled ? "disabled" : ""}`}
      onClick={onClick}
      target={target}
    >
      {text}
    </a>
  );
};

export const VideoContainer = ({ children }) => {
  return <div className="pitch-video-container">{children}</div>;
};

export const CardTitle = ({ children, style }: React.PropsWithChildren<{ style?: any }>) => {
  return (
    <div className="pitch-card-title" style={style}>
      {children}
    </div>
  );
};
export const Buttons = ({ children }) => {
  return <div className="pitch-card-actions">{children}</div>;
};

export const Container = ({ children }) => {
  return <div className="pitch-card">{children}</div>;
};

export const Video = ({ children, style }) => {
  return (
    <div className="pitch-video" style={style}>
      {children}
    </div>
  );
};

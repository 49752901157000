/**
 * @author W. Alex Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2018-06-22
 * @description A class acting as the error returned from a request to a GraphQL server where the HTTP request is
 *  invalid (an HTTP 400 status).
 * @filename invalid-http-request-error.ts
 */
import { Error } from "./error";

/**
 * The error returned from a request to a GraphQL server where the HTTP request is invalid (an HTTP 400 status).
 *
 * @extends {Error}
 */
export class InvalidHttpRequestError extends Error {
  /**
   * The type ID of the error.
   *
   * @type {string}
   */
  public static readonly TYPE_ID: string = "hicEnMkjLE2aa1qAWoVwDg";

  /**
   * A parametric constructor.
   *
   * @param {string} location The location where the error was thrown.
   *
   * @param {Object<string, (string|Array<string>)} _invalid A collection of invalid query parameters.
   *
   * @param {Array<string>} _missing The names of missing, but required, query parameters.
   *
   * @param {string} id The ID of the error.
   */
  public constructor(
    location: string,
    private _invalid: { [index: string]: string | string[] },
    private _missing: string[] = null,
    id: string = null
  ) {
    super(location, id, 400, "Bad Request");
  }

  /**
   * The collection of invalid query parameters.
   *
   * @type {Object<string, (string|Array<string>)>}
   */
  public get invalidQueryParameters(): { [index: string]: string | string[] } {
    return this._invalid === undefined || this._invalid === null
      ? {}
      : this._invalid;
  }

  /**
   * The collection of missing, but required, query parameters.
   *
   * @type {Array<string>}
   */
  public get missingQueryParameters(): string[] {
    return this._missing === undefined || this._missing === null
      ? []
      : this._missing;
  }

  /**
   * The type of the error - a general classification of the error.
   *
   * @type {string}
   */
  public get type(): string {
    return InvalidHttpRequestError.TYPE_ID;
  }
}

/**
 * @author Ahmed Serag
 * @date   2018-09-13
 * @description Declaration of the Range interface.
 * @filename range.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

/**
 * The __typeID of the Range GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_RANGE: string = "Range";

/**
 * An entrepreneurial range performed by an organization.
 *
 * @interface
 * @template D
 */
export interface Range {
  
  /**
   * The typename of the Range.
   *
   * @type {"Range"}
   */
  __typename: "Range";
  
  /**
   * The maximum value.
   *
   * @type {number}
   * @memberof Range
   */
  maximum?: number;

  /**
   * The minimum value.
   *
   * @type {number}
   * @memberof Range
   */
  minimum?: number;

  /**
   * A flag which is true if the range is greater than the minimum.
   *
   * @type {boolean}
   * @memberof Range
   */
  isGreaterThan?: boolean;

  /**
   * A flag which is true if the range is less than the maximum.
   *
   * @type {boolean}
   * @memberof Range
   */
  isLessThan?: boolean;

  /**
   * The range has a single value and its difference is, therefore, 0.
   *
   * @type {number}
   * @memberof Range
   */
  singleValue?: number;
}

/**
 * @author W. Alex Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2018-03-30
 * @description A class acting as the error returned when by a request to a GraphQL server where the operation to be 
 * performed does include matching revision information.
 * @filename conflict-error.ts
 */
import { Error } from "./error";

/**
 * The error returned from a request to a GraphQL server where the operation to be performed does include matching 
 * revision information.
 *
 * @extends {Error}
 */
export class ConflictError extends Error {
  /**
   * The type ID of the error.
   *
   * @type {string}
   */
  public static readonly TYPE_ID: string = "LD3Ez7tD6USq7hUwuO6aew";

  /**
   * A parametric constructor.
   *
   * @param {string} location The location where the error was thrown.
   *
   * @param {string} _requestRevision The revision included in the request.
   *
   * @param {string} _revision The revision of the node.
   *
   * @param {string} id The ID of the error.
   */
  public constructor(
    location: string,
    private _requestRevision: string,
    private _revision: string,
    id: string = null
  ) {
    super(location, id, 409, "Conflict");
  }

  /**
   * Returns the revision included in the request.
   *
   * @type {string}
   */
  public requestRevision(): string {
    return this._requestRevision;
  }

  /**
   * Returns the revision of the node.
   *
   * @type {string}
   */
  public revision(): string {
    return this._revision;
  }

  /**
   * The type of the error - a general classification of the error.
   *
   * @type {string}
   */
  public get type(): string {
    return ConflictError.TYPE_ID;
  }
}

/**
 * @author William Alexander Livesley
 * @date   2018-04-13
 * @description Declaration of the Preview interface.
 * @filename preview-type.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { MediaAsset } from "./media-asset";
import { PreviewKind } from "./preview-type";
import { ConnectionArgs } from "./connection";

/**
 * The __typeID of the GraphQL Preview type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_PREVIEW: string = "Preview";

/**
 * arguments used to filter the PreviewsConnection result
 *
 * @export
 * @interface PreviewsConnectionArgs
 * @extends {ConnectionArgs}
 */
export interface PreviewsConnectionArgs extends ConnectionArgs {
  kinds?: PreviewKind[];
}

/**
 * The properties of a preview for a person, entity, story list or list collection.
 *
 * @extends {MediaAsset}
 * @template D
 * @interface
 */
export interface Preview {
  /**
   * The kind (use-case) of the preview type.
   *
   * @type {PreviewKind}
   */
  kind?: PreviewKind;

  /**
   * The URL of a remote image used as a preview.
   *
   * @type {string}
   * @memberof Preview
   */
  link?: string;

  /**
   * The ID of the preview.
   *
   * @type {string}
   * @memberof Preview
   */
  id?: string;

  /**
   * The media asset associated with the preview. Either this or the link attribute is specified.
   *
   * @type {MediaAsset}
   * @memberof Preview
   */
  mediaAsset?: MediaAsset;

  /**
   * string;
   *
   * @type {string}
   * @memberof Preview
   */
  __typename?: string;
}

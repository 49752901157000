/**
 * @author Ahmed Serag
 * @date   2019-04-08
 * @description Declaration of the ComponentProperty interface.
 * @filename component-property.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

/**
 * the GraphQl Type.
 */
export const GRAPHQL_TYPE_COMPONENT_PROPERTY = "ComponentProperty";


/**
 * A public, named, property of a component. 
 *
 * @export
 * @interface ComponentProperty
 */
export interface ComponentProperty {
  
  /**
   * A description of the property. Metadata.
   *
   * @type {string}
   * @memberof ComponentProperty
   */
  description?: string;

  /**
   * The ID of the component property.
   *
   * @type {string}
   * @memberof ComponentProperty
   */
  id?: string;

  /**
   * A flag which, if true, means that the property must be configured for the component.
   *
   * @type {string}
   * @memberof ComponentProperty
   */
  isRequired?: boolean;

  /**
   * A flag which, if true, means that the value of the property 
   * is the value of the query parameter in the request with the 
   * name identified by the component property value. 
   * Either this flag, the isUserRelated flag or the 
   * kind attribute must be specified.
   *
   * @type {boolean}
   * @memberof ComponentProperty
   */
  isQueryParameterName?: boolean;

  /**
   * The kind of data type of the component property.
   *
   * @type {ComponentPropertyKind}
   * @memberof ComponentProperty
   */
  kind?: ComponentPropertyKind;

  /**
   * The name of the property. Corresponds to the name of the props attribute.
   *
   * @type {string}
   * @memberof ComponentProperty
   */
  name?: string;

  /**
   * graphQlType
   */
  __typename?: string;
}

/**
 * The kind of data type of the property
 *
 * @export
 * @enum {number}
 */
export enum ComponentPropertyKind {
  ARTICLE_STORY = "ARTICLE_STORY",
  BOOK_STORY = "BOOK_STORY",
  BOOLEAN = "BOOLEAN",
  DYNAMIC_LIST = "DYNAMIC_LIST",
  ENTITY = "ENTITY",
  FIXED_LIST = "FIXED_LIST",
  LIST = "LIST",
  NEWSLETTER_STORY = "NEWSLETTER_STORY",
  NUMBER = "NUMBER",
  ONLINE_COURSE = "ONLINE_COURSE",
  ORIGINAL_CONTENT = "ORIGINAL_CONTENT",
  PERSON = "PERSON",
  PODCAST = "PODCAST",
  QUERY_PARAMETER = "QUERY_PARAMETER",
  STORY = "STORY",
  STRING = "STRING",
  USER = "USER"
}
/**
 * @author William Alexander Livesley
 * @date   2018-04-08
 * @description Declaration of the TopLevelMediaKind enum and the TopLevelMediaType interface declaring the properties
 * of a top level media type.
 * @filename top-level-media-type.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the Top Level Media Type GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_TOP_LEVEL_MEDIA_TYPE: string = "TopLevelMediaType";

/**
 * An enumeration of the different kinds of top level media types.
 */
export enum TopLevelMediaKind {
  /**
   * Any kind of binary data.
   */
  APPLICATION = "APPLICATION",

  /**
   * Audio files.
   */
  AUDIO = "AUDIO",

  /**
   * Image files.
   */
  IMAGE = "IMAGE",

  /**
   * Text files.
   */
  TEXT = "TEXT",

  /**
   * Video files.
   */
  VIDEO = "VIDEO",

  /**
   * Video files.
   */
  DOCUMENT = "DOCUMENT"
}

/**
 * The properties of a top level media type.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface TopLevelMediaType<D extends Date | string = string>
  extends Node<D> {
  /**
   * A description of the top level media type.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The kind of the top level media type.
   *
   * @type {TopLevelMediaKind}
   */
  kind?: TopLevelMediaKind;

  /**
   * A label of the top level media type.
   *
   * @type {string}
   */
  label?: string;
}

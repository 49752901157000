import * as React from "react";
import {SVG} from "../../../component-configuration/svgs";

interface ComponentProps {
  text: string;
}

export type Props = ComponentProps;

export const Component = (props: Props) => {
  const { text } = props;
  return (
    <div className={`has-tooltip label-tooltip`}>
      <span className="tooltip-icon">{SVG.info}</span>
      <div className="tooltip">{text}</div>
    </div>
  );
};

export const LabelToolTip = Component;

import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import { SetAuthStatePayload, SetAuthStateAction } from "../../../redux/authentication/types";
import { AUTH_RESET_PASSWORD } from "../../../redux/authentication/constants";

interface ComponentProps {}

interface MapDispatchProps {
  setAuthState: (paylaod: SetAuthStatePayload) => SetAuthStateAction;
}

interface MapStateProps {}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { setAuthState } = props;

  return (
    <Container>
      <button onClick={() => setAuthState({ step: AUTH_RESET_PASSWORD })}>Forgot password?</button>
    </Container>
  );
};

const Container = ({ children }) => {
  return <div className="forgot-password-link">{children}</div>;
};

const mapState = (state: AppState) => ({});

const mapDispatch = {
  setAuthState: Actions.setAuthState,
};

export const ForgotPasswordLink = connect(mapState, mapDispatch)(Component);

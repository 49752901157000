import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { fetchListLoadMore } from "../../../redux/actions";
import { FetchListLoadMorePayload, FetchListLoadMoreAction } from "../../../redux/cache/types";
import { SVG } from "../../../component-configuration/svgs";
import { useOnScreen } from "../../../utilities/use-on-screen";

interface ComponentProps {
  listId: string;
}

interface MapDispatchProps {
  fetchListLoadMore: (payload: FetchListLoadMorePayload) => FetchListLoadMoreAction;
}

interface MapStateProps {
  inProgress: boolean;
  list: any;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const LoadMoreComponent = (props: Props) => {
  const { listId, list, inProgress, fetchListLoadMore } = props;
  const hasNextPage = list?.list?.list?.items?.pageInfo?.hasNextPage;
  const thisRef = React.useRef(null);
  const isOnScreen = useOnScreen(thisRef);

  const loadMoreItems = React.useCallback(() => {
    fetchListLoadMore({ listId });
  }, [listId, fetchListLoadMore]);

  React.useEffect(() => {
    if (isOnScreen && !inProgress && hasNextPage) {
      fetchListLoadMore({ listId });
    }
  }, [isOnScreen, inProgress, fetchListLoadMore, hasNextPage, listId]);

  const MoreBreakLine = React.useCallback(() => {
    if (!hasNextPage) return null;
    return (
      <div className="more-line-break" onClick={loadMoreItems}>
        <div className="br" />
        <span>
          <em>{inProgress ? SVG.spinner : SVG.chevronDown}</em>
        </span>
        <div className="br" />
      </div>
    );
  }, [hasNextPage, loadMoreItems, inProgress]);

  if (!hasNextPage) return null;

  return (
    <div ref={thisRef} style={{ margin: "0px 70px" }}>
      <MoreBreakLine />
    </div>
  );
};

const mapState = (state: AppState, props: ComponentProps) => ({
  inProgress: state.cache.inProgress[props.listId],
  list: state.cache.store[props.listId],
});

const mapDispatch = {
  fetchListLoadMore: fetchListLoadMore,
};

export const LoadMore = connect(mapState, mapDispatch)(LoadMoreComponent);

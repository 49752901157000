import * as React from "react";

export const Disclaimer = () => {
  return (
    <div className="row">
      <div className="disclaimer">
        <p>
          Radivision is an entertainment and media company. Radivision is not a registered broker-dealer or investment
          adviser. Any investment opportunities identified on the Radivision website cannot be consummated on or through
          Radivision or with any involvement of Radivision whatsoever. Potential transactions would necessarily occur
          off of this platform directly between you, the issuer, and, where applicable, any third-party facilitating the
          investment opportunity – without any direct or indirect involvement by Radivision. The information contained
          herein regarding available investments is obtained directly from the source of the information without any
          substantive involvement by Radivision. Radivision does not represent that any information contained on any
          video or page is accurate or complete, and Radivision has not undertaken any independent review of such
          information.
        </p>
        <p>
          {" "}
          The material contained on this platform is for entertainment and informational purposes only and is qualified
          in its entirety by the information, disclosures and terms of use on this platform, any information provided to
          you separately by any company featured on this platform and on any other site or platform that may be
          facilitating the investment. This material shall not constitute an offer to sell or the solicitation to buy
          any securities or adopt any investment strategy, or will be appropriately disclaimed by the parties making any
          such offer to sell or solicitation to buy any securities. The information presented on this website has been
          obtained directly from the sources of such information and is believed to be reliable; however, Radivision
          does not guarantee the accuracy, adequacy or completeness of such information, and such information is subject
          to change. Nothing contained herein constitutes investment, legal, tax or other advice nor is it to be relied
          on in making an investment or other decision.
        </p>
        <p>
          This website features materials protected by the Fair Use guidelines of Section 107 of the Copyright Act. All rights reserved to the respective copyright owners.
        </p>
      </div>
    </div>
  );
};

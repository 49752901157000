import { ImageHelper } from "../../../utilities/image-helper";
import { PreviewKind, TopLevelMediaKind, PitchVideoStory } from "@radivision/graphql";

export const getPreviewImageUrl = (item: PitchVideoStory) => {
  if (!item?.previews) {
    return null;
  }
  const requestedImageDimensions = {
    containerWidthRatio: 0.5,
    numberOfItems: 1,
  };
  const previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
    imageType: "PREVIEW",
    preview: {
      content: item.previews,
      previewKind: PreviewKind.CAROUSEL,
      topLevelMedia: TopLevelMediaKind.IMAGE,
    },
    desiredDimensions: requestedImageDimensions,
    isSquareImage: false,
    revision: item.revision,
  });
  return previewImageUrl;
};

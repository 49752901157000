import { graphql, GraphQLTaggedNode } from "react-relay";

export const CREATE_DEACTIVATE_ACCOUNT_REQUEST_MUTATION: GraphQLTaggedNode = graphql`
  mutation createDeactivateAccountRequestMutation($input: CreateUpdateDeactivateRequestInput!) {
    DeactivateRequest(input: $input) {
      errors {
        id
        location
      }
      hasErrors
      userRequest {
        ... on DeactivateRequest {
          id
          description
          status
          reasons
        }
      }
    }
  }
`;

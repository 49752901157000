/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-1-02
 * @description Implementation of the GraphQL mutation to refresh twitter users open id token.
 * @filename update-twitter-user
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to refresh linked in user open id Token.
 *
 * @type {GraphQLTaggedNode}
 */
export const REFRESH_TWITTER_USER_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation refreshTwitterCredentialsMutation(
    $input: RefreshTwitterCredentialsInput!
  ) {
    refreshTwitterCredentials(input: $input) {
      clientMutationId
      credentials {
        id
        twitterUserId
        openIdToken
      }
      hasErrors
      errors {
        created
        id
        location
        type
      }
    }
  }
`;

/**
 * @author William Alexander Livesley
 * @date   2018-04-13
 * @description Declaration of the PreviewType interface and PreviewKind enumeration.
 * @filename preview-type.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the Preview Type type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_PREVIEW_TYPE: string = "PreviewType";

/**
 * The properties of a use case for a preview for a person, entity, story list or list collection.
 *
 * @enum
 */
export enum PreviewKind {
  /**
   * The header background of the personal page for a person or entity.
   */
  BACKGROUND = "BACKGROUND",

  /**
   * A 16:9 media asset to be displayed in a carousel.
   */
  CAROUSEL = "CAROUSEL",

  /**
   * A short sequence from the original media asset.
   */
  EXCERPT = "EXCERPT",

  /**
   * A headshot preview.
   */
  HEADSHOT = "HEADSHOT",

  /**
   * A 21:9 media asset to be displayed in a hero.
   */
  HERO = "HERO",

  /**
   * A logo to be displayed in a hero.
   */
  HERO_LOGO = "HERO_LOGO",

  /**
   * A logo preview.
   */
  LOGO = "LOGO",

  /**
   * A portrait preview.
   */
  PORTRAIT = "PORTRAIT",

  /**
   * A square media asset.
   */
  SQUARE = "SQUARE",

  /**
   * A square media asset.
   */
  A_ROLE = "A_ROLE",

  /**
   * A square media asset.
   */
  B_ROLE = "B_ROLE",

  /**
   * A badges media asset.
   */
  BADGE_DARK = "BADGE_DARK",

  /**
   * A badge media asset.
   */
  BADGE_LIGHT = "BADGE_LIGHT",

    /**
   * A badge media asset.
   */
  CIRCLE = "CIRCLE"
}

/**
 * The properties of a use case for a preview for a person, entity, story list or list collection.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface PreviewType<D extends Date | string = string> extends Node<D> {
  /**
   * A description of the preview type.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The kind of the preview type.
   *
   * @type {PreviewKind}
   */
  kind?: PreviewKind;

  /**
   * A label of the preview type.
   *
   * @type {string}
   */
  label?: string;
}

import { Reducer } from "redux";
import produce from "immer";
import { CrawlerState, CrawlerAction } from "./types";
import * as constants from "./constants";

const initialState: CrawlerState = {
  cache: {},
  isFetching: false,
};

export const CrawlerReducer: Reducer<CrawlerState, CrawlerAction> = (
  state: CrawlerState = initialState,
  action: CrawlerAction,
) => {
  switch (action.type) {
    case constants.CRAWL_FULFILLED: {
      return produce(state, (draft) => {
        draft.cache[action.payload.url] = action.payload;
      })
    }
    case constants.CRAWL_FETCHING: {
      return produce(state, (draft) => {
        draft.isFetching = action.payload.status;
      })
    }
    default:
      return state;
  }
};

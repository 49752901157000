import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import $ from "jquery";
import { AppState } from "../../../redux";
import {
  SetAuthStatePayload,
  SetAuthStateAction,
  SignInWithEmailAndPasswordPayload,
  SignInWithEmailAndPasswordAction,
} from "../../../redux/authentication/types";
import { AUTHENTICATION_MODAL_ID, AUTH_SIGNIN } from "../../../redux/authentication/constants";
import { FormFieldContainer, FormFieldInput, Submit } from "../modal-form-elements";
import { ForgotPasswordLink } from "./forgot-password-link";
import { ProvidersSignin } from "./providers-signin";
import { DontHaveAnAccount } from "./dont-have-an-account";
import { isValidEmail } from "../../../utilities/is-valid-email";
import { TOAST_ALERT } from "../../../redux/toast/constants";
import { SetToastMessagePayload, SetToastMessageAction } from "../../../redux/toast/types";
import { eyeClosedIcon, eyeOpenedIcon } from "../icons";
import isEmpty from "lodash.isempty";

interface MapDispatchProps {
  setAuthState: (paylaod: SetAuthStatePayload) => SetAuthStateAction;
  signInWithEmailAndPassword: (paylaod: SignInWithEmailAndPasswordPayload) => SignInWithEmailAndPasswordAction;
  setToastMessage: (payload: SetToastMessagePayload) => SetToastMessageAction;
}

interface MapStateProps {
  step: number;
  password: string;
  email: string;
  inProgress: boolean;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { step, email, password, inProgress, setAuthState, signInWithEmailAndPassword, setToastMessage } = props;
  const shouldDisplay = step === AUTH_SIGNIN;

  const [hiddenPassword, setHiddenPassword] = React.useState(true);

  const onSubmit = React.useCallback(() => {
    if (!isValidEmail(email)) {
      setToastMessage({ msg: "Please enter your email address", type: TOAST_ALERT });
      return;
    }
    if (isEmpty(password)) {
      setToastMessage({ msg: "Please enter your password", type: TOAST_ALERT });
      return;
    }
    signInWithEmailAndPassword({ email, password });
  }, [email, password, signInWithEmailAndPassword, setToastMessage]);

  React.useEffect(() => {
    const keyDownHandler = (event) => {
      if (shouldDisplay && $(`#${AUTHENTICATION_MODAL_ID}`).data("bs.modal")?._isShown && event.key === "Enter") {
        event.preventDefault();
        onSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [onSubmit]);

  const hidePassword = React.useCallback(() => {
    setHiddenPassword(!hiddenPassword);
  }, [hiddenPassword]);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Container>
      <div className="subtitle">Welcome to Radivision</div>
      <FormFieldContainer>
        <FormFieldInput>
          <input
            type="email"
            className="form-control"
            placeholder="Email Address"
            required={true}
            disabled={false}
            value={email}
            onChange={(e) => setAuthState({ email: e.target.value })}
          />
        </FormFieldInput>
      </FormFieldContainer>
      <FormFieldContainer>
        <FormFieldInput withIcon rightAligned>
          <span onClick={hidePassword}>{hiddenPassword ? eyeClosedIcon : eyeOpenedIcon}</span>
          <input
            type={hiddenPassword ? "password" : "text"}
            className="form-control"
            placeholder="Password"
            required={true}
            disabled={false}
            value={password}
            onChange={(e) => setAuthState({ password: e.target.value })}
          />
        </FormFieldInput>
      </FormFieldContainer>
      <ForgotPasswordLink />
      <Submit
        onClick={onSubmit}
        disabled={isEmpty(email) || isEmpty(password)}
        isSubmitting={inProgress}
        title={"Log in"}
      />
      <Or />
      <ProvidersSignin step={step} />
      <DontHaveAnAccount />
    </Container>
  );
};

export const Or = () => (
  <div className="or-line-break">
    <div className="br" />
    <span>
      <em>OR</em>
    </span>
    <div className="br" />
  </div>
);

const Container = ({ children }) => {
  return <div className="signin-form">{children}</div>;
};

const mapState = (state: AppState) => ({
  step: state.authentication.step,
  email: state.authentication.email,
  password: state.authentication.password,
  inProgress: state.cache.inProgress["signin"],
});

const mapDispatch = {
  setAuthState: Actions.setAuthState,
  signInWithEmailAndPassword: Actions.signInWithEmailAndPassword,
  setToastMessage: Actions.setToastMessage,
};

export const SignIn = connect(mapState, mapDispatch)(Component);

import * as React from "react";
import { useCarouselContext } from "./context";
import ProgressiveImage from "react-progressive-image";

export const ShowLogo = () => {
  const { show, displayBadge } = useCarouselContext();

  //if (!displayBadge) return null;

  return (
    <Container>
      {show.badges && show.badges.map((badge, index) => {
        return(<a href={badge.link} key={index} target="_blank">
          <ProgressiveImage
            src={
              badge.imgUrl.requestedResolutionUrl !== undefined &&
              badge.imgUrl.requestedResolutionUrl !== null
                ? badge.imgUrl.requestedResolutionUrl
                : badge.imgUrl.screenResolutionUrl
            }
            placeholder={badge.imgUrl.placeHolderUrl}
            key={`badge-${index.toString()}`}
          >
            {(src: any, _LOADING: any, _srcSetData: any) => (
              <img
                loading="lazy"
                alt={`Badge ${index}`}
                className="award"
                key={index}
                src={src}
              />
            )}
          </ProgressiveImage>
        </a>);
      })}
    </Container>
  );
};

const Container = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <div
      className="badges"
    >
      {children}
    </div>
  );
};

export default ShowLogo;

/**
 * @Author: Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-05-28
 * @description Implementation of the TagsClause class.
 * @filename tags-clause.ts
 */
import { ClauseOperator } from "./clause-operator";
import { Tag } from "./tag";

/**
 * The __typeID of the GraphQL TagsClause object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_TAGS_CLAUSE: string = "TagsClause";

/**
 * A collection of tags and or clauses and'd or or'd together.
 *
 * @export
 * @template D
 */
export interface TagsClause<D extends Date | string = string> {
  /**
   * A collection of tags clauses.
   *
   * @type {Array<TagsClause>}
   */
  clauses?: TagsClause[];

  /**
   *  The clause operator.
   *
   * @type {ClauseOperator}
   */
  operator?: ClauseOperator;

  /**
   * The collection of tags
   *
   * @type {Array<Tag>}
   */
  tags?: Tag[];

  /**
   * The typename of the tags clause.
   *
   * @type {string}
   */
  __typename?: string;
}

/**
 * @author William Alexander Livesley
 * @date   2018-04-08
 * @description Declaration of the NetworkLocationZone interface.
 * @filename network-zone.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the GraphQL Network Zone object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_NETWORK_ZONE: string = "NetworkZone";

/**
 * A network zone from which network requests are made.
 *
 * @template D
 * @interface
 */
export interface NetworkZone<D extends Date | string = string> extends Node<D> {
  /**
   * A description of the network location zone.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The network zone label.
   *
   * @type {string}
   */
  label?: string;
}

/**
 * @author William Alexander Livesley
 * @date   2018-04-02
 * @description Declaration of the OrderInput interface.
 * @filename order-input.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

/**
 * The properties used to order the results of a query.
 *
 * @interface
 */
export interface OrderInput {
  /**
   * The name of the attribute which is the order key.
   *
   * @type {string}
   */
  attribute: string;

  /**
   * A flag which is true if the attribute values are in descending order.
   *
   * @type {boolean}
   */
  descending: boolean;

  /**
   * The populate kind of the attribute which is the order key.
   *
   * @type {*}
   * @memberof OrderInput
   */
  populateKind?: PopulateKind;
}

/**
 *
 */
export enum PopulateKind {
  /**
   *
   *
   */
  FUZZY = "FUZZY",

  /**
   *
   *
   */
  HOURLY = "HOURLY",

  /**
   *
   *
   */
  DAILY = "DAILY",
  /**
   *
   *
   */
  MONTHLY = "MONTHLY",
  /**
   *
   *
   */
  YEAR = "YEAR"
}

/**
 * @author W. Alex Livesley
 * @copyright Copyright 2018 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2018-02-04
 * @description Implementation of the unique-id module.
 * @filename unique-id.ts
 */
import * as UUID_V4 from "uuid/v4";

/**
 * Returns a base-64 string constructed from a buffer containing the UUID.
 *
 * @param {Array<number>} bytes A buffer containing the UUID.
 *
 * @return {string} The base-64 string.
 */

function getBytesFromBase64(bytes: number[]): string {
  let base64String: string;

  if (typeof Buffer === "undefined") {
    base64String = String.fromCharCode.apply(null, bytes);
    base64String = btoa(base64String).replace(/.{76}(?=.)/g, "$&\n");
  } else {
    base64String = new Buffer(bytes).toString("base64");
  }
  return base64String;
}

/**
 * Returns a 22 character UUID from which unsafe characters have been substituted.
 *
 * Must be declared before use.
 *
 * @param {string} uuid The original UUID.
 *
 * @return {string} The bowdlerized UUID.
 */
function getSafeUuidFromString(uuid: string): string {
  return uuid
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

/**
 * Returns a new unique ID - a UUID encoded using base-64 into a 22-character string.
 *
 * @returns {string} A new ID.
 */
export function getNewUniqueId(): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return getSafeUuidFromString(getBytesFromBase64(UUID_V4(null, [] as any)));
}

import * as React from "react";
import ProgressiveImage from "react-progressive-image";
import {
  Person,
  Entity,
  PreviewKind,
  TopLevelMediaKind,
  GRAPHQL_TYPE_ENTITY,
  GRAPHQL_TYPE_PERSON,
} from "@radivision/graphql";
import { ImageHelper } from "../../../utilities/image-helper";
import DEFAULT_IMAGE from "../../../../static/no-image-icon.png";
import { Router } from "../../../utilities/router";
import { getProfileInfo } from "../../../utilities/get-profile-info";

interface ComponentProps {
  subject: Person | Entity;
  numberOfItems?: number;
  containerWidthRatio?: number;
}

export type Props = ComponentProps;

export const ProfilePicture = (props: Props) => {
  const { subject, numberOfItems, containerWidthRatio } = props;

  const isPerson = subject?.__typename === "Person";

  const { profileUrl } = getProfileInfo(subject);

  return subject && subject.previews ? (
    <ProgressiveImage
      src={
        ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: subject.previews,
            previewKind: isPerson ? PreviewKind.HEADSHOT : PreviewKind.LOGO,
            topLevelMedia: TopLevelMediaKind.IMAGE,
            isDefaultPreview: true,
            type: isPerson ? GRAPHQL_TYPE_PERSON : GRAPHQL_TYPE_ENTITY,
          },
          desiredDimensions: {
            containerWidthRatio: containerWidthRatio || 3 / 12,
            numberOfItems: numberOfItems || 4,
          },
          revision: subject.revision,
        })?.requestedResolutionUrl
      }
      placeholder={
        ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: subject.previews,
            previewKind: isPerson ? PreviewKind.HEADSHOT : PreviewKind.LOGO,
            topLevelMedia: TopLevelMediaKind.IMAGE,
            isDefaultPreview: true,
            type: isPerson ? GRAPHQL_TYPE_PERSON : GRAPHQL_TYPE_ENTITY,
          },
          desiredDimensions: {
            containerWidthRatio: containerWidthRatio || 3 / 12,
            numberOfItems: numberOfItems || 4,
          },
          revision: subject.revision,
        })?.placeHolderUrl
      }
    >
      {(src: any, LOADING: any, srcSetData: any) => (
        <a
          href={profileUrl}
          onClick={(e) => {
            e.preventDefault();
            Router.navigateTo(profileUrl);
          }}
          className="profile-picture"
          style={{ backgroundImage: `url(${src})` }}
        ></a>
      )}
    </ProgressiveImage>
  ) : (
    <ProgressiveImage src={DEFAULT_IMAGE} placeholder={DEFAULT_IMAGE}>
      {(src: any, LOADING: any, srcSetData: any) => (
        <span className="profile-picture" style={{ backgroundImage: `url(${src})` }}></span>
      )}
    </ProgressiveImage>
  );
};

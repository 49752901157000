/**
 * @author William Alexander Livesley
 * @date   2018-03-16
 * @description Declaration of the ListPayload interface.
 * @filename list-payload.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Connection } from "./connection";
import { Error } from "./error";
import { Node } from "./node";

/**
 * The __typeID of the List Payload type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_LIST_PAYLOAD: string = "ListPayload";

/**
 * The response to a GraphQL query to return collection of items.
 *
 * @interface
 * @template D, T
 */
export interface ListPayload<
  T extends Node<D>,
  D extends Date | string = string
> {
  /**
   * The connection returned in the response.
   *
   * @template D, T
   * @type {N}
   */
  connection?: Connection<D, T>;

  /**
   * One or more errors returned in the response.
   *
   * @template D
   * @type {Array<Error<D>>}
   */
  errors?: Error<D>[];

  /**
   * A flag which is true if the response to the query contains errors.
   *
   * @template D
   * @type {D}
   */
  hasErrors: boolean;
}

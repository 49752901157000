import { AuthenticationReducer } from "./reducer";
import { AuthenticationModuleState } from "./types";
import sagas from "./saga";

export { AuthenticationModuleState };

export const authenticationModule = {
  id: "authentication",
  reducerMap: {
    authentication: AuthenticationReducer,
  },
  sagas: [sagas],
};

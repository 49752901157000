/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type linkedInAuthenticationQueryVariables = {
    readonly linkedInCode: string;
};
export type linkedInAuthenticationQueryResponse = {
    readonly linkedInUser: {
        readonly errors: ReadonlyArray<{
            readonly created: unknown | null;
            readonly id: string;
            readonly location: string | null;
            readonly type: string | null;
        } | null> | null;
        readonly hasErrors: boolean | null;
        readonly linkedInUser: {
            readonly id: string;
            readonly linkedInUserId: string | null;
            readonly openIdToken: string | null;
        } | null;
    } | null;
};
export type linkedInAuthenticationQuery = {
    readonly response: linkedInAuthenticationQueryResponse;
    readonly variables: linkedInAuthenticationQueryVariables;
};



/*
query linkedInAuthenticationQuery(
  $linkedInCode: String!
) {
  linkedInUser(id: $linkedInCode) {
    errors {
      created
      id
      location
      type
    }
    hasErrors
    linkedInUser {
      id
      linkedInUserId
      openIdToken
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "linkedInCode",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "linkedInUser",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "linkedInCode"
      }
    ],
    "concreteType": "LinkedInUserPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "linkedInUser",
        "storageKey": null,
        "args": null,
        "concreteType": "LinkedInUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "linkedInUserId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "openIdToken",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "linkedInAuthenticationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "linkedInAuthenticationQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "linkedInAuthenticationQuery",
    "id": null,
    "text": "query linkedInAuthenticationQuery(\n  $linkedInCode: String!\n) {\n  linkedInUser(id: $linkedInCode) {\n    errors {\n      created\n      id\n      location\n      type\n    }\n    hasErrors\n    linkedInUser {\n      id\n      linkedInUserId\n      openIdToken\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '37b45e014daa94e6c2753f76fdb8f113';
export default node;

import {
  GetNotificationSubscriptionStatusPayload,
  GetNotificationSubscriptionStatusAction,
  CreateNotificationRequestPayload,
  CreateNotificationRequestAction,
  CreatingNotificationPayload,
  CreatingNotificationAction,
} from "./types";
import { GET_NOTIFICATION_SUBSCRIPTION_STATUS, CREATE_NOTIFICATION_REQUEST, CREATING_NOTIFICATION } from "./constants";

export const getNotificationSubscriptionStatus = (
  payload: GetNotificationSubscriptionStatusPayload,
): GetNotificationSubscriptionStatusAction => ({
  type: GET_NOTIFICATION_SUBSCRIPTION_STATUS,
  payload,
});

export const createNotificationRequest = (
  payload: CreateNotificationRequestPayload,
): CreateNotificationRequestAction => ({
  type: CREATE_NOTIFICATION_REQUEST,
  payload,
});

export const creatingNotification = (payload: CreatingNotificationPayload): CreatingNotificationAction => ({
  type: CREATING_NOTIFICATION,
  payload,
});

import * as React from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { NextArrow, PrevArrow } from "./elements";
import { Card } from "./card";
import isEmpty from "lodash.isempty";
import { Router } from "../../../utilities/router";
import { AppState } from "../../../redux";

interface ComponentProps {
  items: any[];
  setCurrentSlideIndex: (n: number) => void;
  currentSlideIndex: number;
  showBtn: boolean;
  playMode: "YOUTUBE" | "HLS";
}

interface MapStateProps {
  lastSeenPitchId: string | null;
}
export type Props = ComponentProps & MapStateProps;

const Component = (props: Props) => {
  const { items, setCurrentSlideIndex, currentSlideIndex, lastSeenPitchId, showBtn, playMode } = props;
  const sliderRef = React.useRef(null);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    lazyLoad: true,
    centerPadding: "30%",
    focusOnSelect: false,
    beforeChange: (_i: number, index: number) => {
      setCurrentSlideIndex(index);
    },
    slidesToShow: 1,
    speed: 250,
    touchThreshold: 20,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          centerPadding: "30%",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "25%",
        },
      },
      {
        breakpoint: 960,
        settings: {
          centerPadding: "22%",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "10%",
        },
      },
    ],
  };

  const ACTIVE_SLIDE = Router.getParameterByName("slide") || lastSeenPitchId;

  React.useEffect(() => {
    if (items && !isEmpty(ACTIVE_SLIDE)) {
      const index = items.findIndex((item: any) => {
        return ACTIVE_SLIDE === item?.listItem?.item?.id;
      });
      if (index >= 0 && sliderRef?.current) {
        setCurrentSlideIndex(index);
        sliderRef.current?.slickGoTo(index);
      }
    }
    setCurrentSlideIndex(currentSlideIndex);
    sliderRef.current?.slickGoTo(currentSlideIndex);
  }, [ACTIVE_SLIDE, items, setCurrentSlideIndex, currentSlideIndex]);

  const slickNext = () => {
    if (typeof sliderRef.current?.slickNext === "function") {
      sliderRef.current?.slickNext();
    }
  };

  return (
    <Slider {...settings} ref={sliderRef}>
      {items &&
        items.map((edge: any, index) => (
          <Card
            key={`pictch-card-${edge?.listItem?.item?.id}`}
            slickNext={slickNext}
            item={edge?.listItem?.item}
            currentSlideIndex={currentSlideIndex}
            slideIndex={index}
            showBtn={showBtn}
            playMode={playMode}
          />
        ))}
    </Slider>
  );
};

const mapState = (state: AppState) => ({
  lastSeenPitchId: state.app.lastSeenPitchId,
});

export const LargeCarousel = connect(mapState)(Component);

import {
  SetEventStateAction,
  CreateRSVPRequestAction,
  CreateRSVPRequestPayload,
  SetEventStatePayload,
  FetchEventPayload,
  FetchEventAction,
  CreateInvestAmountRequestPayload,
  CreateInvestAmountRequestAction,
} from "./types";
import { SET_EVENTS_STATE, CREATE_RSVP_REQUEST, FETCH_EVENT, CREATE_INVEST_AMOUNT_REQUEST } from "./constants";

export const setEventState = (payload: SetEventStatePayload): SetEventStateAction => ({
  type: SET_EVENTS_STATE,
  payload,
});

export const createRSVPRequest = (payload: CreateRSVPRequestPayload): CreateRSVPRequestAction => ({
  type: CREATE_RSVP_REQUEST,
  payload,
});

export const fetchEvent = (payload: FetchEventPayload): FetchEventAction => ({
  type: FETCH_EVENT,
  payload,
});

export const createInvestAmountRequest = (
  payload: CreateInvestAmountRequestPayload,
): CreateInvestAmountRequestAction => ({
  type: CREATE_INVEST_AMOUNT_REQUEST,
  payload,
});

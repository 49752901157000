import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { ProfileModuleState } from "../redux/profile";
import { ModalFooter, Submit, Cancel } from "../../../react-components/modals/modal-form-elements";
import { EDIT_PROFILE_ABOUT } from "../redux/profile/constants";
import { submitProfile } from "../redux/profile/actions";
import { SubmitProfileAction } from "../redux/profile/types";
import { Description } from "../profile/description";
import { CompanySize } from "../profile/company-size";
import { Founded } from "../profile/founded";
import { CompanyIndustry } from "../profile/company-industry";
import { CompanyType } from "../profile/company-type";
import { Location } from "../profile/location";

interface MapDispatchProps {
  submitProfile: () => SubmitProfileAction;
}

interface MapStateProps {
  screen: string;
  inProgress: boolean;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { screen, submitProfile, inProgress } = props;
  const shouldDisplay = screen === EDIT_PROFILE_ABOUT;

  const onSubmit = React.useCallback(() => {
    submitProfile();
  }, [submitProfile]);

  const isValid = true;

  if (!shouldDisplay) return null;

  return (
    <Container>
      <div className="form-body">
        <Description />
        <Location />
        <Founded />
        <CompanyIndustry />
        <CompanySize />
        <CompanyType />
      </div>
      <ModalFooter>
        <Cancel />
        <Submit
          onClick={onSubmit}
          disabled={false}
          className={isValid ? `` : "disabled"}
          isSubmitting={inProgress}
          title="Save"
        />
      </ModalFooter>
    </Container>
  );
};

export const Container = ({ children }: React.PropsWithChildren<any>) => {
  return <div className="edit-profile-page-info edit-profile-form">{children}</div>;
};

const mapState = (state: AppState & ProfileModuleState) => ({
  screen: state.profile.screen,
  inProgress: state.cache.inProgress["submit-profile"],
});

const mapDispatch = {
  submitProfile: submitProfile,
};

export const About = connect(mapState, mapDispatch)(Component);

/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2019-02-05
 * @filename image-format.ts
 * @description Declaration of ImageFormat enum
 */

/**
 * The __typeID of the GraphQL image format object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_INSTAGRAM_IMAGE_FORMAT: string = "ImageFormat";

/**
 * An enumeration of the formats supported by an optimize image request
 *
 * @enum {number}
 */
export enum ImageFormat {
  /**
   * An image/jpeg
   */
  JPEG = "JPEG",
  /**
   *# An image/png
   */
  PNG = "PNG",
  /**
   * An image/webp
   */
  WEBP = "WEBP"
}

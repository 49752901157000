/**
 * @author Maria Osama
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-08-29 11:00:00
 * @description Implementation of the FAQS questions and answers react component
 * @filename faqs-question-answer.tsx
 */
import React from "react";
import { SVG } from "../../../component-configuration/svgs";

/**
 * The static properties of the FaqsQuestionAndAnswer class
 *
 * @interface
 */
interface FaqsQuestionAndAnswerProps {
  question: string;
  answer: React.ReactNode;
}

/**
 * State of the FaqsQuestionAndAnswer class.
 *
 * @interface
 */
interface FaqsQuestionAndAnswerState {
  status: string;
}

/**
 *
 * A React component that renders the FaqsQuestionAndAnswer tab content .
 *
 * @extends {React.Component}
 */
export class FaqsQuestionAndAnswer extends React.Component<FaqsQuestionAndAnswerProps, FaqsQuestionAndAnswerState> {
  /**
   * Constructor.
   *
   * @param {FaqsQuestionAndAnswerProps} props The props of the component.
   */
  constructor(props: FaqsQuestionAndAnswerProps) {
    super(props);
    this.showAnswer = this.showAnswer.bind(this);
    this.hideAnswer = this.hideAnswer.bind(this);
    this.statusToggle = this.statusToggle.bind(this);
    this.state = {
      status: "hide",
    };
  }

  /**
   *Function that changes the type of password input field from password
     to text and vice versa
   *
   * @type {function}
   */
  showAnswer() {
    this.setState({
      status: "show",
    });
  }
  hideAnswer() {
    this.setState({
      status: "hide",
    });
  }
  statusToggle() {
    this.state.status === "hide" ? this.setState({ status: "show" }) : this.setState({ status: "hide" });
  }
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    const substractIcon: React.ReactNode = <i onClick={this.hideAnswer}>{SVG.dash}</i>;
    const showAnswerIcon: React.ReactNode = <i onClick={this.showAnswer}>{SVG.plus}</i>;
    return (
      <div>
        <h3 className="font-weight-bold" onClick={this.statusToggle}>
          <b>Q: </b>
          {this.props.question}
        </h3>
        {this.state.status === "hide" ? showAnswerIcon : substractIcon}
        <div className={this.state.status}>
          <span>A: </span>
          {this.props.answer}
        </div>
      </div>
    );
  }
}

export const DrivenShowId = "rarcGxVvREunnf9Wl72t2g";
export const VisionariesShowId = "ULxQnNudTGKWbf9TPgvGlA";
export const RadivisionInterviewsShowId = "vbN3NPC_R3i7mAwtkFXHrQ";
export const FeaturedCarouselId = "sfGMhClZToqlWUazxiN8Lw";
export const defaultMoreLikeThis = "qQXPd4GmRlGeKbNxpioVpA";
export const pitchPlanetListId = "mFrJCxiHQESBl-vkUjMH2g";
export const BlackIsTheNewGreenListId = "cax_GMmsRPerPQI8YGhDmQ";
export const SheconomicsListId = "bKZ9jXKJQSqILgVktGrMbw";

export const originalContentMoreLikeThisIDs = {
  [DrivenShowId]: "Vy1Jh4A9TZGADLVbpKIzTA",
  [VisionariesShowId]: "qQXPd4GmRlGeKbNxpioVpA",
  [RadivisionInterviewsShowId]: "fFicMtCNSBG1mumHmJ-kVQ",
  [SheconomicsListId]: "fFicMtCNSBG1mumHmJ-kVQ",
  [BlackIsTheNewGreenListId]: "fFicMtCNSBG1mumHmJ-kVQ",
};

export const CONTENT_REQUIRING_PAYWALL = [];
export const CROWNFUNDING_COMPANIES = [
  { name: "FynCom", id: "eGQJ5j1DT5ObRuBSUHfwtQ" },
  { name: "Modgarden", id: "J-1VD7NmRXuqPA99KN8Z8g" },
  { name: "TTC-Oncology", id: "Q8Lyb6yUT3awC8R56Y1Sdg" },
  { name: "TFI", id: "6itgzchkS-W3vsEsHidfNw" },
  { name: "Play-Out-Apparel", id: "ZVvKpZc6SIqI6t8HgKYv1A" },
  { name: "FoodsPass", id: "vWUlYm2nRUGk3pJeE3yjQQ" },
  { name: "CabinetM", id: "ip7ocEF2Rx-aCYUr9xwVWQ" },
  { name: "Paybby", id: "k-GDAcA6Qsam_B4H-i2yHA" },
  { name: "Enosi", id: "Pf0eFC5rTkCuQQ0VV3hE0w" },
  { name: "EDA-Inc", id: "lPLQAnA0QMiOd0_ojqIyxw" },
];

/**
 * @author William Alexander Livesley
 * @date   2018-03-20
 * @description Declaration of the ApiObjectKind interface and the types of API object types.
 * @filename api-object-kind.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the API Object Kind GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_API_OBJECT_KIND: string = "ApiObjectKind";

/**
 * The type literals of the kinds of API objects.
 */
export enum ApiObjectKindType {
  /**
   * A story with concerning an article
   */
  ARTICLE_STORY = "ARTICLE_STORY",

  /**
   * A story with concerning a book
   */
  BOOK_STORY = "BOOK_STORY",

  /**
   * An entity
   */
  ENTITY = "ENTITY",

  /**
   * An HTML document
   */
  HTML_DOCUMENT = "HTML_DOCUMENT",

  /**
   * An HTML document alias route
   */
  HTML_DOCUMENT_ALIAS_ROUTE = "HTML_DOCUMENT_ALIAS_ROUTE",

  /**
   * An HTML document template
   */
  HTML_DOCUMENT_TEMPLATE = "HTML_DOCUMENT_TEMPLATE",

  /**
   * A list of stories, people, and entities
   */
  LIST = "LIST",

  /**
   * A collection of lists
   */
  LIST_COLLECTION = "LIST_COLLECTION",

  /**
   * A media asset
   */
  MEDIA_ASSET = "MEDIA_ASSET",

  /**
   * A media asset file
   */
  MEDIA_ASSET_FILE = "MEDIA_ASSET_FILE",

  /**
   * A media asset file status
   */
  MEDIA_ASSET_FILE_STATUS = "MEDIA_ASSET_FILE_STATUS",

  /**
   * A media asset state
   */
  MEDIA_ASSET_STATE = "MEDIA_ASSET_STATE",

  /**
   * A mutation
   */
  MUTATION = "MUTATION",

  /**
   * A network location
   */
  NETWORK_LOCATION = "NETWORK_LOCATION",

  /**
   * A network location zone
   */
  NETWORK_ZONE = "NETWORK_ZONE",

  /**
   * A story with concerning a newsletter
   */
  NEWSLETTER_STORY = "NEWSLETTER_STORY",

  /**
   * A note
   */
  NOTE = "NOTE",

  /**
   * An online course story
   */
  ONLINE_COURSE_STORY = "ONLINE_COURSE_STORY",

  /**
   * An original content story
   */
  ORIGINAL_CONTENT_STORY = "ORIGINAL_CONTENT_STORY",

  /**
   * A person
   */
  PERSON = "PERSON",

  /**
   * A tag
   */
  TAG = "TAG",

  /**
   * A user
   */
  USER = "USER",

  /**
   * A PODCAST story
   */
  PODCAST = "PODCAST"
}

/**
 * Metadata describing a kind of API object
 *
 * @extends {Node}
 * @interface
 * @template D
 */
export interface ApiObjectKind<D extends Date | string = string>
  extends Node<D> {
  /**
   * A description of the API object kind
   *
   * @type {string}
   */
  description?: string;

  /**
   * A label for the API object kind. Metadata
   *
   * @type {string}
   */
  label?: string;

  /**
   * A title for the API object kind. Metadata
   *
   * @type {string}
   */
  title?: string;

  /**
   * Identifies the type of the object kind
   *
   * @type {ApiObjectKindType}
   */
  type?: ApiObjectKindType;
}

import * as React from "react";

export const Container = ({ id, children }: React.PropsWithChildren<{ id: string }>) => {
  return (
    <div id={id} className="scrolling-carousel">
      {children}
    </div>
  );
};
export const Slider = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <div className="scroller">
      <div className="items-container">{children}</div>
    </div>
  );
};

export const Item = ({ children, width, onClick }: React.PropsWithChildren<{ width: number; onClick: () => void }>) => {
  return (
    <div className="slide-item" onClick={onClick} style={{ width: `${width}px`, maxWidth: `${width}px` }}>
      {children}
    </div>
  );
};

export const Thumbnail = ({
  children,
  width,
  height,
  className,
  backgroundImage,
}: React.PropsWithChildren<{
  width: number;
  height: number;
  className: string;
  backgroundImage: string;
}>) => {
  return (
    <div
      className={`thumbnail ${className}`}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        backgroundImage: `url('${backgroundImage}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >
      {children}
    </div>
  );
};

export const ItemTitle = ({ children }: React.PropsWithChildren<{ width: number }>) => {
  return <div className="item-title">{children}</div>;
};
export const Title = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className="title">{children}</div>;
};

export const Header = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className="header">{children}</div>;
};

export const PreviewImage = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className="preview-image">{children}</div>;
};

export const ImagePlaceholder = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className="image-placeholder">{children}</div>;
};

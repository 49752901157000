import { Reducer } from "redux";
import { DetailsPanelState, DetailsPanelAction } from "./types";
import * as constants from "./constants";

const initialState: DetailsPanelState = {
  item: null,
  scrollAnchorId: null,
  moreLikeThis: null,
  checkPass: false,
};

export const DetailsPanelReducer: Reducer<DetailsPanelState, DetailsPanelAction> = (
  state: DetailsPanelState = initialState,
  action: DetailsPanelAction,
) => {
  switch (action.type) {
    case constants.OPEN_DETAILS_PANEL:
      return {
        ...state,
        ...action.payload,
      };
    case constants.SET_DETAILS_PANEL:
      return {
        ...state,
        ...action.payload,
      };
    case constants.CLOSE_DETAILS_PANEL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { FormFieldContainer, FormFieldInput } from "../../../react-components/modals/modal-form-elements";
import { SetProfileStateAction, SetProfileStatePayload, ProfileModuleState } from "../redux/profile/types";
import { setProfileState } from "../redux/profile/actions";
import { GRAPHQL_TYPE_ENTITY } from "@radivision/graphql";

interface MapStateProps {
  name: string;
  isCompany: boolean;
}

interface MapDispatchProps {
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { name, setProfileState, isCompany } = props;

  if (!isCompany) return null;

  return (
    <FormFieldContainer>
      <label htmlFor="name">
        <span>Name: *</span>
      </label>
      <FormFieldInput>
        <input
          type="text"
          name="name"
          maxLength={70}
          value={name ? name : ""}
          onChange={(e) => setProfileState({ name: e.target.value })}
        />
      </FormFieldInput>
    </FormFieldContainer>
  );
};

const mapState = (state: AppState & ProfileModuleState) => {
  return {
    name: state.profile.name,
    isCompany: state.profile.type === GRAPHQL_TYPE_ENTITY,
  };
};

const mapDispatch = {
  setProfileState: setProfileState,
};

export const Name = connect(mapState, mapDispatch)(Component);

import { GRAPHQL_TYPE_ENTITY, GRAPHQL_TYPE_PERSON, Entity, Person } from "@radivision/graphql";

export function isOwner(subject: Person | Entity | null | undefined, user: Person | undefined | null) {
  if (!subject || !user) return false;
  if (subject?.isEditable) return true;

  const isPerson = subject["__typename"] === GRAPHQL_TYPE_PERSON || subject["type"] === GRAPHQL_TYPE_PERSON;
  const isEntity = subject["__typename"] === GRAPHQL_TYPE_ENTITY || subject["type"] === GRAPHQL_TYPE_ENTITY;

  if (isPerson) return subject.id === user.id;
  if (isEntity) {
    const _entity = user?.entities?.find((entity: Entity) => entity.id === subject.id);
    if (_entity) return true;
  }
  return false;
}

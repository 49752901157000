import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import {
  SetAuthStatePayload,
  SetAuthStateAction,
  ResendVerificationCodePayload,
  ResendVerificationCodeAction,
} from "../../../redux/authentication/types";
import { SVG } from "../../../component-configuration/svgs";
import { SetToastMessagePayload, SetToastMessageAction } from "../../../redux/toast/types";
import { TOAST_ALERT } from "../../../redux/toast/constants";

interface ComponentProps {}

interface MapDispatchProps {
  resendAccountVerificationCode: (payload: ResendVerificationCodePayload) => ResendVerificationCodeAction;
  resendUserAttributesVerificationCode: (payload: ResendVerificationCodePayload) => ResendVerificationCodeAction;
  setAuthState: (payload: SetAuthStatePayload) => SetAuthStateAction;
  setToastMessage: (payload: SetToastMessagePayload) => SetToastMessageAction;
}

interface MapStateProps {
  email: string;
  inProgress: boolean;
  resendAt: null | number;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const {
    resendAccountVerificationCode,
    resendUserAttributesVerificationCode,
    email,
    inProgress,
    resendAt,
    verifyAttributes,
    setAuthState,
    setToastMessage,
  } = props;

  const [seconds, setSeconds] = React.useState<number>(0);

  const refreshSeconds = React.useCallback(() => {
    if (!resendAt) {
      setSeconds(0);
      return;
    }
    const currentTime = new Date().getTime();
    const secondsLeftInMilliSeconds = currentTime - resendAt;
    const secondsLeft = 120 - Math.floor(secondsLeftInMilliSeconds / 1000);
    if (secondsLeft <= 0) {
      setAuthState({ resendAt: null });
      return;
    }
    setSeconds(secondsLeft);
  }, [setSeconds, resendAt, setAuthState]);

  React.useEffect(() => {
    const refreshTimer = setInterval(refreshSeconds, 1000);
    return () => clearInterval(refreshTimer);
  }, [resendAt, setSeconds, refreshSeconds]);

  const canResend = seconds === null || seconds === 0;
  return (
    <Container>
      <a
        onClick={(e) => {
          e.preventDefault();
          if (canResend && email) {
            verifyAttributes !== null && verifyAttributes
              ? resendUserAttributesVerificationCode(undefined)
              : resendAccountVerificationCode({ email });
           
          } else {
            setToastMessage({
              msg: `Verification code has been sent to your email. You will be able to resend again in a few minutes.`,
              type: TOAST_ALERT,
            });
          }
        }}
      >
        {inProgress ? <span className="icon">{SVG.spinner}</span> : null}
        <span>Resend Code</span>
      </a>
    </Container>
  );
};

const Container = ({ children }) => {
  return <div className="resend-code-link">{children}</div>;
};

const mapState = (state: AppState) => ({
  email: state.authentication.email,
  verifyAttributes: state.authentication.verifyAttributes,
  resendAt: state.authentication.resendAt,
  inProgress: state.cache.inProgress["resend-verification-code"],
});

const mapDispatch = {
  resendAccountVerificationCode: Actions.resendAccountVerificationCode,
  resendUserAttributesVerificationCode: Actions.resendUserAttributesVerificationCode,
  setToastMessage: Actions.setToastMessage,
  setAuthState: Actions.setAuthState,
};

export const ResendCodeLink = connect(mapState, mapDispatch)(Component);

import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import { INVESTOR, COMPANY, ENTHUSIAST, FOUNDER } from "../../../redux/authentication/account-types";
import { AUTH_WELCOME_TO_RADIVISION } from "../../../redux/authentication/constants";
import { CreateCompany } from "./create-company";
import { OnBoardingInvestor } from "./onboarding-investor";
import { OnBoardingFounder } from "./onboarding-founder";
import { OnBoardingEnthusiast } from "./onboarding-enthusiast";
import { AccountType } from "../../../redux/types/account-type";

interface MapStateProps {
  step: number;
  accountType: AccountType;
}

export type Props = MapStateProps;

export const Component = (props: Props) => {
  const { step, accountType } = props;

  const shouldDisplay = step === AUTH_WELCOME_TO_RADIVISION;

  if (!shouldDisplay) {
    return null;
  }

  if (accountType?.kind === COMPANY.kind) {
    return <CreateCompany />;
  }
  if (accountType?.kind === INVESTOR.kind) {
    return <OnBoardingInvestor />;
  }
  if (accountType?.kind === FOUNDER.kind) {
    return <OnBoardingFounder />;
  }
  if (accountType?.kind === ENTHUSIAST.kind) {
    return <OnBoardingEnthusiast />;
  }
  return null;
};

const mapState = (state: AppState) => ({
  step: state.authentication.step,
  accountType: state.authentication.accountType,
});

const mapDispatch = {
  setToastMessage: Actions.setToastMessage,
};

export const WelcomeToRadivision = connect(mapState, mapDispatch)(Component);

import { Reducer } from "redux";
import {
  PLAY_FULL_SCREEN_VIDEO,
  SET_PLAYING_FULL_SCREEN,
  CLOSE_FULL_SCREEN_VIDEO,
  PLAY_IN_MINI_PLAYER,
  CLOSE_MINI_PLAYER,
  VIDEO_MODE_MINIPLAYER,
  VIDEO_MODE_INLINE,
} from "./constants";
import { FullScreenVideoState, FullScreenVideoAction } from "./types";

const initialState: FullScreenVideoState = {
  videoId: null,
  videoMode: VIDEO_MODE_INLINE,
  scrollAnchorId: null,
  isPlayingFullScreen: false,
  recentlyPlayed: [],
};

export const FullScreenVideoReducer: Reducer<FullScreenVideoState, FullScreenVideoAction> = (
  state: FullScreenVideoState = initialState,
  action: FullScreenVideoAction,
) => {
  switch (action.type) {
    case PLAY_FULL_SCREEN_VIDEO: {
      const { videoId, scrollAnchorId } = action.payload;
      return {
        ...state,
        videoId,
        isPlayingFullScreen: true,
        scrollAnchorId,
      };
    }
    case CLOSE_FULL_SCREEN_VIDEO:
      return {
        ...state,
        videoId: null,
        isPlayingFullScreen: false,
        scrollAnchorId: null,
      };
    case SET_PLAYING_FULL_SCREEN:
      return {
        ...state,
        isPlayingFullScreen: action.payload.status,
        ...action.payload,
      };
    case PLAY_IN_MINI_PLAYER: {
      const { videoId } = action.payload;
      return {
        ...state,
        videoId,
        videoMode: VIDEO_MODE_MINIPLAYER,
        recentlyPlayed: [videoId, ...state.recentlyPlayed.filter((v) => v !== videoId)],
      };
    }
    case CLOSE_MINI_PLAYER: {
      const { videoId } = action.payload;
      return {
        ...state,
        videoId: videoId === state.videoId ? null : videoId,
        videoMode: VIDEO_MODE_INLINE,
      };
    }
    default:
      return state;
  }
};

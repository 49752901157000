/**
* @author: Adham El Banhawy
* @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
* @date: 2018-08-15
* @filename: twitter.ts
* @description: Declaration of the twitter type interface
*/

/**
 * The __typeID of the GraphQL twitter user object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_TWITTER: string = "Twitter";

/**
 *
 *
 * @export
 * @interface Twitter
 */
export interface Twitter {
    /**
     * A Twitter oauth token.
     */
    oauthToken: string;

    /**
     * A Twitter oauth token secret.
     */
    oauthTokenSecret: string;

    /**
     * A flag which confirms that the oauth callback has been accepted.
     */
    oauthCallbackConfirmed: boolean;
}
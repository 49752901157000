import { call, put, takeEvery, select } from "redux-saga/effects";
import { addToPerson } from "../api";
import { AddToPersonInput } from "@radivision/graphql/lib/ts/graphql/mutations/add-to-person-input";
import { getClientMutationId } from "../../../../../utilities/general";
import { setToastMessage, fetchInProgress, updateUser, cacheData, fetchListItem } from "../../../../../redux/actions";
import { TOAST_FAIL, TOAST_ALERT, TOAST_SUCCESS } from "../../../../../redux/toast/constants";
import { AddCompanyAction } from "../types";
import { ADD_COMPANY } from "../constants";
import { AppState } from "../../../../../redux";
import { fetchListItemWorker } from "../../../../../redux/cache/saga";

const EXPERIENCE = "experience";
function* addCompanyWorker(action: AddCompanyAction) {
  const { entityId, collection } = action.payload;
  try {
    const collections = [EXPERIENCE];
    if (!collections.includes(collection)) {
      yield put(setToastMessage({ msg: "Error: cannot add company!", type: TOAST_FAIL }));
      return false;
    }

    yield put(fetchInProgress({ key: `add-company-${collection}`, status: true }));
    const user = yield select((state: AppState) => state.account.user);
    const experienceExists =
      collection === EXPERIENCE &&
      user?.experience?.edges?.find((edge: any) => edge?.position?.entity?.id === entityId);
    if (experienceExists) {
      yield put(setToastMessage({ msg: "Company has already beed added.", type: TOAST_ALERT }));
      return false;
    }

    const action = yield put(fetchListItem({ type: "entity", itemId: entityId }));
    const entity = yield call(fetchListItemWorker, action);
    if (!entity) {
      yield put(
        setToastMessage({ msg: "Cannot add experience. The company has been deleted/removed.", type: TOAST_ALERT }),
      );
      return;
    }

    const experience =
      collection === EXPERIENCE
        ? {
            experience: [
              {
                entity: entityId,
                tags: [],
                isCurrent: null,
              },
            ],
          }
        : {};

    const personInput: AddToPersonInput = {
      id: user.id,
      clientMutationId: getClientMutationId(),
      ...experience,
      revision: user.revision,
    };
    const updatedUser = yield call(addToPerson, personInput);
    yield put(updateUser({ user: updatedUser }));
    yield put(cacheData({ key: updatedUser.id, data: updatedUser }));
    yield put(setToastMessage({ msg: "Company has been added!", type: TOAST_SUCCESS }));
  } catch (e) {
    yield put(
      setToastMessage({ msg: "Cannot add experience. The company has been deleted/removed.", type: TOAST_ALERT }),
    );
  } finally {
    yield put(fetchInProgress({ key: `add-company-${collection}`, status: false }));
  }
}

export function* watchAddCompany() {
  yield takeEvery(ADD_COMPANY, addCompanyWorker);
}

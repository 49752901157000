/**
 * @author William Alexander Livesley
 * @date   2018-05-22
 * @description Declaration of the User interface declaring the properties of a User GraphQL type.
 * @filename user.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Connection } from "./connection";
import { Follows } from "./follows";
import { Person } from "./person";
import { Preview } from "./preview";
import { Tag } from "./tag";

/**
 * The __typeID of the GraphQL User object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_USER: string = "User";

/**
 * The current user.
 */
export interface User<D extends Date | string = string> {
  /**
   * The timestamp when the user's person record was created as a Date object or an ISO-8601 formatted UTC date string.
   *
   * @template D
   * @type {string}
   */
  created?: D;

  /**
   * The full name of the user (Peter James Smith).
   *
   * @type {string}
   */
  fullName?: string;

  /**
   * The collection of people and entities that a person follows.
   *
   * @type {Array<Follows>}
   */
  follows?: Follows[];

  /**
   * The nickname or name usually used for a user (Pete).
   *
   * @type {string}
   */
  greeting?: string;

  /**
   * A flag which is true if the user has a public page on the site.
   *
   * @type {boolean}
   */
  hasPublicPage?: boolean;

  /**
   * A flag which is true if the user is registered with site.
   *
   * @type {boolean}
   */
  isRegistered?: boolean;

  /**
   * The timestamp when the user's person record was last modified as a Date object or an ISO-8601 formatted UTC date
   * string.
   *
   * @template D
   * @type {D}
   */
  lastModified?: D;

  /**
   * The underlying person..
   *
   * @template D
   * @type {Person<D>}
   */
  person?: Person<D>;

  /**
   * The collection of media assets used as previews.
   *
   * @template D
   * @type {Connection<D, Preview>}
   */
  previews?: Connection<D, Preview>;

  /**
   * The current revision of the user's person record.
   *
   * @type {string}
   */
  revision?: string;

  /**
   * The collection of tags associated with the user.
   *
   * @type {Array<Tag>}
   */
  tags?: Tag[];

  /**
   * The typename of the node.
   *
   * @type {string}
   */
  __typename?: string;
}

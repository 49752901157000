/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ComponentPropertyKind = "ARTICLE_STORY" | "BOOK_STORY" | "BOOLEAN" | "DYNAMIC_LIST" | "ENTITY" | "FIXED_LIST" | "LIST" | "NEWSLETTER_STORY" | "NUMBER" | "ONLINE_COURSE" | "ORIGINAL_CONTENT" | "PERSON" | "PODCAST" | "QUERY_PARAMETER" | "STORY" | "STRING" | "USER" | "%future added value";
export type htmlPageQueryVariables = {
    readonly alias: string;
    readonly userIdentityId?: string | null;
    readonly queryParameters?: ReadonlyArray<string> | null;
};
export type htmlPageQueryResponse = {
    readonly urlRouteByAlias: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly created: unknown | null;
            readonly id: string;
            readonly location: string | null;
            readonly type: string | null;
        } | null> | null;
        readonly node: {
            readonly id: string;
            readonly panels: ReadonlyArray<{
                readonly component: {
                    readonly id: string;
                    readonly key: string | null;
                    readonly properties: ReadonlyArray<{
                        readonly kind: ComponentPropertyKind | null;
                        readonly isRequired: boolean | null;
                        readonly isQueryParameterName: boolean | null;
                        readonly name: string | null;
                    } | null> | null;
                } | null;
                readonly properties: ReadonlyArray<{
                    readonly name: string | null;
                    readonly article: {
                        readonly id: string;
                    } | null;
                    readonly book: {
                        readonly id: string;
                    } | null;
                    readonly boolean: boolean | null;
                    readonly dynamicList: {
                        readonly id: string;
                    } | null;
                    readonly entity: {
                        readonly id: string;
                    } | null;
                    readonly fixedList: {
                        readonly id: string;
                    } | null;
                    readonly id: string | null;
                    readonly int: number | null;
                    readonly json: string | null;
                    readonly listCollection: {
                        readonly id: string;
                    } | null;
                    readonly newsletter: {
                        readonly id: string;
                    } | null;
                    readonly number: number | null;
                    readonly onlineCourse: {
                        readonly id: string;
                    } | null;
                    readonly originalContent: {
                        readonly id: string;
                    } | null;
                    readonly person: {
                        readonly id: string;
                    } | null;
                    readonly podcast: {
                        readonly id: string;
                    } | null;
                    readonly poster: {
                        readonly id: string;
                    } | null;
                    readonly string: string | null;
                } | null> | null;
            }> | null;
        } | null;
    } | null;
};
export type htmlPageQuery = {
    readonly response: htmlPageQueryResponse;
    readonly variables: htmlPageQueryVariables;
};



/*
query htmlPageQuery(
  $alias: String!
  $userIdentityId: ID
  $queryParameters: [String!]
) {
  urlRouteByAlias(alias: $alias, userIdentityId: $userIdentityId, queryParameters: $queryParameters) {
    hasErrors
    errors {
      created
      id
      location
      type
    }
    node {
      id
      panels {
        component {
          id
          key
          properties {
            kind
            isRequired
            isQueryParameterName
            name
            id
          }
        }
        properties {
          name
          article {
            id
          }
          book {
            id
          }
          boolean
          dynamicList {
            id
          }
          entity {
            id
          }
          fixedList {
            id
          }
          id
          int
          json
          listCollection {
            id
          }
          newsletter {
            id
          }
          number
          onlineCourse {
            id
          }
          originalContent {
            id
          }
          person {
            id
          }
          podcast {
            id
          }
          poster {
            id
          }
          string
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "alias",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userIdentityId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "queryParameters",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "alias",
    "variableName": "alias"
  },
  {
    "kind": "Variable",
    "name": "queryParameters",
    "variableName": "queryParameters"
  },
  {
    "kind": "Variable",
    "name": "userIdentityId",
    "variableName": "userIdentityId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "created",
      "args": null,
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "key",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isRequired",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isQueryParameterName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v10 = [
  (v3/*: any*/)
],
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "properties",
  "storageKey": null,
  "args": null,
  "concreteType": "ComponentPropertyValue",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "article",
      "storageKey": null,
      "args": null,
      "concreteType": "ArticleStory",
      "plural": false,
      "selections": (v10/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "book",
      "storageKey": null,
      "args": null,
      "concreteType": "BookStory",
      "plural": false,
      "selections": (v10/*: any*/)
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "boolean",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "dynamicList",
      "storageKey": null,
      "args": null,
      "concreteType": "DynamicList",
      "plural": false,
      "selections": (v10/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "entity",
      "storageKey": null,
      "args": null,
      "concreteType": "Entity",
      "plural": false,
      "selections": (v10/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "fixedList",
      "storageKey": null,
      "args": null,
      "concreteType": "FixedList",
      "plural": false,
      "selections": (v10/*: any*/)
    },
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "int",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "json",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "listCollection",
      "storageKey": null,
      "args": null,
      "concreteType": "ListCollection",
      "plural": false,
      "selections": (v10/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "newsletter",
      "storageKey": null,
      "args": null,
      "concreteType": "NewsletterStory",
      "plural": false,
      "selections": (v10/*: any*/)
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "onlineCourse",
      "storageKey": null,
      "args": null,
      "concreteType": "OnlineCourseStory",
      "plural": false,
      "selections": (v10/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "originalContent",
      "storageKey": null,
      "args": null,
      "concreteType": "OriginalContentStory",
      "plural": false,
      "selections": (v10/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "person",
      "storageKey": null,
      "args": null,
      "concreteType": "Person",
      "plural": false,
      "selections": (v10/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "podcast",
      "storageKey": null,
      "args": null,
      "concreteType": "PodcastStory",
      "plural": false,
      "selections": (v10/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "poster",
      "storageKey": null,
      "args": null,
      "concreteType": "Poster",
      "plural": false,
      "selections": (v10/*: any*/)
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "string",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "htmlPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "urlRouteByAlias",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HtmlDocumentPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "HtmlDocument",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "panels",
                "storageKey": null,
                "args": null,
                "concreteType": "HtmlDocumentPanel",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "component",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Component",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "properties",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ComponentProperty",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v11/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "htmlPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "urlRouteByAlias",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HtmlDocumentPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "HtmlDocument",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "panels",
                "storageKey": null,
                "args": null,
                "concreteType": "HtmlDocumentPanel",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "component",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Component",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "properties",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ComponentProperty",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v11/*: any*/),
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "htmlPageQuery",
    "id": null,
    "text": "query htmlPageQuery(\n  $alias: String!\n  $userIdentityId: ID\n  $queryParameters: [String!]\n) {\n  urlRouteByAlias(alias: $alias, userIdentityId: $userIdentityId, queryParameters: $queryParameters) {\n    hasErrors\n    errors {\n      created\n      id\n      location\n      type\n    }\n    node {\n      id\n      panels {\n        component {\n          id\n          key\n          properties {\n            kind\n            isRequired\n            isQueryParameterName\n            name\n            id\n          }\n        }\n        properties {\n          name\n          article {\n            id\n          }\n          book {\n            id\n          }\n          boolean\n          dynamicList {\n            id\n          }\n          entity {\n            id\n          }\n          fixedList {\n            id\n          }\n          id\n          int\n          json\n          listCollection {\n            id\n          }\n          newsletter {\n            id\n          }\n          number\n          onlineCourse {\n            id\n          }\n          originalContent {\n            id\n          }\n          person {\n            id\n          }\n          podcast {\n            id\n          }\n          poster {\n            id\n          }\n          string\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'd98f50654ce4572d1f9178838f8f3bf3';
export default node;

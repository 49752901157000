/**
 * @author Ahmed serag
 * @date   2018-09-24
 * @description Declaration of the TextQuestion interface.
 * @filename text-question.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Connection } from "./connection";
import { Preview } from "./preview";
import { QuestionResponse } from "./questionnaire";
import { TextQuestionResponse } from "./text-question-response";

/**
 * The __typeID of the text Question GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_TEXT_QUESTION: string = "TextQuestion";

/**
 * A text question.
 *
 * @export
 * @interface TextQuestion
 */
export interface TextQuestion {

  /**
   * The content of the question.
   *
   * @type {string}
   * @memberof TextQuestion
   */
  content?: string;

  /**
   * The ID of the question.
   *
   * @type {string}
   * @memberof TextQuestion
   */
  id?: string;

  /**
   * A collection of media assets used as previews for the question.
   *
   * @type {Connection<string, Preview>}
   * @memberof TextQuestion
   */
  previews?: Connection<string, Preview>;

    /**
   * The response to the question by the person or entity if returned within the context of a person or entity.
   *
   * @type {TextQuestionResponse}
   * @memberof TextQuestion
   */
  response?: TextQuestionResponse;

  /**
   * A collection of responses to the question.
   *
   * @type {Connection<string,QuestionResponse>}
   * @memberof TextQuestion
   */
  responses?: Connection<string,QuestionResponse>;

  /**
   * The sub-title of the question.
   *
   * @type {string}
   * @memberof TextQuestion
   */
  subTitle?: string;

  /**
   * The title of the question.
   *
   * @type {string}
   * @memberof TextQuestion
   */
  title?: string;

  /**
   * The typename of the node.
   *
   * @type {string}
   */
  __typename?: string;
}
import { put, select, call } from "redux-saga/effects";
import {
  UpdatePersonInput,
  UpdateEntityInput,
  GRAPHQL_TYPE_PERSON,
  GRAPHQL_TYPE_ENTITY,
  PreviewKind,
  Person,
  Entity,
} from "@radivision/graphql";
import isEmpty from "lodash.isempty";
import { getClientMutationId } from "../../../../../utilities/general";
import { getRangeFromNumber } from "../../../../../utilities/get-number-from-range";
import { cacheData, updateUser } from "../../../../../redux/actions";
import {
  prepareUploadMediaPayload,
  ImageUpload,
  prepareMediaAssetPayload,
} from "../../../../../utilities/image-uploader";
import { getSocialMediaProfilesToBeAdded } from "../utils";
import { ProfileModuleState } from "../types";
import { AppState } from "../../../../../redux";
import { User } from "../../../../../utilities/user";
import { getSocialMediaProfilesMap } from "../selectors";
import { setProfileState } from "../actions";
import { updatePerson, updateEntity } from "../api";
import isEqual from "lodash.isequal";

export function* updateProfileWorker() {
  const state = yield select((state: ProfileModuleState) => state.profile);
  const subjectId = state.id;
  const subject = yield select((state: AppState) => state.cache.store[subjectId]);

  const isCompany = subject.__typename === GRAPHQL_TYPE_ENTITY;
  const isPerson = subject.__typename === GRAPHQL_TYPE_PERSON;
  const mediaAssetId = yield call(uploadProfilePhotoFile, PreviewKind.SQUARE);

  const backgroundAssetId = state.backgroundAssetId
    ? state.backgroundAssetId
    : yield call(uploadProfilePhotoFile, PreviewKind.BACKGROUND);

  let previews;
  if (mediaAssetId || backgroundAssetId) {
    if (isPerson) {
      previews = User.handleEditPersonPreview(mediaAssetId, backgroundAssetId, subject.previews);
    }
    if (isCompany) {
      previews = User.handleEditPersonPreview(
        mediaAssetId ? mediaAssetId : null,
        backgroundAssetId ? backgroundAssetId : null,
        subject.previews,
        PreviewKind.LOGO,
      );
    }
  }

  const hasChanged = (field: string) => (!isEmpty(state[field]) && state[field] !== subject[field] ? true : false);

  const _socialMediaProfiles = getSocialMediaProfilesToBeAdded(
    state.socialMediaProfiles,
    getSocialMediaProfilesMap(subject),
  );
  const hasSocialMediaToBeAdded = !isEmpty(_socialMediaProfiles);
  const socialMediaProfiles = hasSocialMediaToBeAdded ? { socialMediaProfiles: _socialMediaProfiles } : {};

  const fullName = hasChanged("fullName") ? { fullName: state.fullName } : {};
  const name = hasChanged("name") ? { name: state.name } : {};
  const description = hasChanged("description") ? { description: state.description } : {};
  const responsibility = isPerson && hasChanged("responsibility") ? { responsibility: state.responsibility } : {};
  const websiteUrl = hasChanged("websiteUrl") ? { websiteUrl: state.websiteUrl } : {};
  const tagLine = hasChanged("tagLine") ? { tagLine: state.tagLine } : {};
  const accountType =
    isPerson && state.accountType?.kind !== subject.accountType?.kind ? { accountType: state.accountType } : {};

  const personLocationDidNotChange =
    subject.locations?.length && subject.locations[0] ? subject.locations[0].label !== state.location : true;
  const personLocation =
    !isEmpty(state.location) && personLocationDidNotChange ? { locations: [{ address: state.location }] } : {};

  const companyLocationDidNotChange =
    subject.locations?.length && subject.locations[0] ? subject.locations[0].label !== state.location : true;
  const companyLocation =
    !isEmpty(state.location) && companyLocationDidNotChange
      ? { locations: [{ location: { address: state.location, label: state.location } }] }
      : {};

  const currentFounded = new Date(subject.founded).getTime();
  const newFounded = new Date(state.founded).getTime();
  const founded =
    isCompany && !isEmpty(state.founded) && newFounded !== currentFounded ? { founded: state.founded } : {};
  const _noOfEmployees = getRangeFromNumber(state.noOfEmployees);
  const noOfEmployees =
    !isEmpty(state.noOfEmployees) && subject.noOfEmployees?.maximum !== _noOfEmployees?.maximum
      ? { noOfEmployees: getRangeFromNumber(state.noOfEmployees) }
      : {};
  const industry = hasChanged("industry") ? { industry: state.industry } : {};
  const activities =
    state.activities?.length &&
    (subject.activities?.length && subject.activities[0] ? state.activities[0] !== subject.activities[0].kind : true)
      ? { activities: state.activities }
      : {};

  const opportunitiesDidNotChange = !isEqual(subject.opportunities, state.opportunities);
  const opportunities = opportunitiesDidNotChange ? { opportunities: state.opportunities } : {};
  if (isPerson) {
    const personInput: UpdatePersonInput = {
      clientMutationId: getClientMutationId(),
      id: subject.id,
      revision: subject.revision,
      ...fullName,
      ...responsibility,
      ...websiteUrl,
      ...personLocation,
      ...description,
      ...accountType,
      ...(mediaAssetId || backgroundAssetId ? { previews } : {}),
      ...socialMediaProfiles,
    };

    const noChanges = Object.keys(personInput).length === 3;
    if (noChanges) return false;
    const updatedUser: Person = yield call(updatePerson, personInput);
    if (updatedUser) {
      yield put(setProfileState({ revision: updatedUser.revision }));
      yield put(cacheData({ key: updatedUser.id, data: updatedUser }));
      return true;
    }
  }
  if (isCompany) {
    const entityInput: UpdateEntityInput = {
      clientMutationId: getClientMutationId(),
      id: subject.id,
      revision: subject.revision,
      ...name,
      ...description,
      ...websiteUrl,
      ...tagLine,
      ...companyLocation,
      ...founded,
      ...noOfEmployees,
      ...industry,
      ...activities,
      ...opportunities,
      ...accountType,
      ...(mediaAssetId || backgroundAssetId ? { previews } : {}),
      ...socialMediaProfiles,
    };

    const noChanges = Object.keys(entityInput).length === 3;
    if (noChanges) return false;
    const updatedEntity: Entity = yield call(updateEntity, entityInput);
    if (updatedEntity) {
      yield put(setProfileState({ revision: updatedEntity.revision }));
      yield put(cacheData({ key: updatedEntity.id, data: updatedEntity }));
      return true;
    }
  }
}

function* uploadProfilePhotoFile(type: PreviewKind.SQUARE | PreviewKind.BACKGROUND) {
  const state = yield select((state: ProfileModuleState) => state.profile);
  const profileImage = type === PreviewKind.SQUARE ? state.profileImage : state.profileBackground;
  let mediaAssetId: string | null = null;
  if (profileImage) {
    yield call(ImageUpload.uploadImageToS3, prepareUploadMediaPayload(profileImage));
    mediaAssetId = yield call(ImageUpload.createMediaAssetFile, prepareMediaAssetPayload(profileImage));
    return mediaAssetId;
  }
  return null;
}

/**
 * @author Adham EL Banaway
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-05
 * @description Implementation of S3 utility class.
 * @filename s3.ts
 */

/**
 * A collection of utilities for AWS S3.
 */
export class S3Helper {
  /**
   * Returns a promise to upload a file with a given URI to an S3 bucket defined by the UPLOAD_S3_BUCKET env variable.
   *
   * @param {string} fileUri The URI of the file to be uploaded to S3.
   *
   * @param {string} fileName The name of the file after being uploaded.
   *
   * @returns {Promise<S3.ManagedUpload.SendData>} The promise to upload the file.
   */
  public static uploadToS3(
    fileUri: string | Blob,
    fileName: string,
    fileOptions?: { [index: string]: any },
    progressCallback?: Function,
  ): Promise<S3.ManagedUpload.SendData> {
    return import("../authentication/cognito-client").then((module) => {
      return module.CognitoClient.getCognitoIdentityCredentials().then(
        (): Promise<any> => {
          return import("../authentication/aws-sdk").then((AWS_MODULES) => {
            return new Promise((resolve: (value: any) => void, reject: (err: Error) => void): void => {
              const UPLOADER = new AWS_MODULES.AWS_CLIENTS.S3();
              if (fileOptions.ContentType === undefined) {
                throw new Error("[RY09dbw9X0OXwgNCtN5dvA] invalid contentType");
              }

              const uploadOptions = {
                Bucket: process.env.AWS_S3_UPLOADS_BUCKET_NAME,
                Key: fileName,
                Body: fileUri,
                ...fileOptions,
              };
              let request = UPLOADER.upload(
                uploadOptions,
                (err: Error = null, data: S3.ManagedUpload.SendData = null): void => {
                  if (err === null) {
                    resolve(data);
                  } else {
                    reject(err);
                  }
                },
              );

              if (progressCallback) {
                request = request.on("httpUploadProgress", (progress) => {
                  // Here you can use `this.body` to determine which file this particular
                  // event is related to and use that info to calculate overall progress.
                  progressCallback(progress);
                });
              }
            });
          });
        },
      );
    });
  }

  /**
   *
   *
   * @static
   * @param {string} sourceBucket
   * @param {string} fileKey
   * @returns {Promise<S3.GetObjectAclOutput>}
   * @memberof S3
   */
  public static getObject(sourceBucket: string, fileKey: string): Promise<any> {
    return import("../authentication/cognito-client").then((module) => {
      return module.CognitoClient.getCognitoIdentityCredentials().then(
        (): Promise<any> => {
          return import("../authentication/aws-sdk").then((AWS_MODULES) => {
            return new Promise((resolve: (value: any) => void, reject: (err: Error) => void): void => {
              const DOWNLOADER = new AWS_MODULES.AWS_CLIENTS.S3();

              const objectParams = {
                Bucket: sourceBucket,
                Key: fileKey,
              };

              DOWNLOADER.getObject(
                objectParams,
                (
                  err: {
                    code: string;
                    message: string;
                  },
                  data: S3.GetObjectAclOutput,
                ): void => {
                  if (err === null) {
                    resolve(data);
                  } else {
                    reject(err);
                  }
                },
              );
            });
          });
        },
      );
    });
  }
}

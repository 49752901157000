@import "../../../../../scss/common.scss";

.offer-card {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-color: rgb(40, 40, 40);
  border-radius: 6px;
  padding-bottom: 10px;
  color: rgb(223, 217, 217);
  position: relative;
  &:hover {
    .admin-buttons {
      display: flex;
      width: auto;
      position: absolute;
      top: 1px;
      right: 0px;
      margin-bottom: 2px;
      border-radius: 50%;
      border: 2px;
      &.delete {
        top: 2.25rem;
      }
    }
  }
  .profile-picture-name {
    top: 20px;
    left: 20px;
    margin: 10px 0px;
    position: relative;
    z-index: 1;
    text-shadow: 1px 1px 4px rgb(0 0 0 / 100%);
    align-self: flex-start;
    & > a {
      box-shadow: 1px 1px 4px rgb(0 0 0 / 100%);
    }
  }
  .profile-picture-placeholder {
    height: 56px;
  }
  .card-image {
    align-self: flex-start;
    width: 100%;
    height: 0px;
    margin: 0px auto 0px auto;
    padding-top: 50%;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    overflow: hidden;
    position: relative;
    &::before {
      position: absolute;
      top: 0px;
      right: 0px;
      left: 0px;
      bottom: 0px;
      content: " ";
      background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
    }
  }
  .card-content {
    padding: 10px 20px 20px 20px;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .description {
      margin: 10px 0px;
      width: 100%;
      text-align: left;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-flow: column;
      flex: 1;
      span {
        font-size: 16px;
        line-height: 28px;
        color: white;
        font-family: Muli;
      }
    }
    .code-btn {
      margin-top: 10px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .code {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        width: 100%;
        text-align: center;
        margin-right: 15px;
      }
      .copy {
        margin-left: 6px;
        svg {
          fill: #888;
          width: 12px;
          height: 12px;
        }
      }
    }
    .action-btn {
      background-color: $radivision-blue;
      color: white;
      font-family: MuliBold;
      font-size: 16px;
      cursor: pointer;
      border-radius: 20px;
      border: none;
      padding: 7px 40px;
      margin: 10px 0px 0px 0px;
      text-decoration: none;
      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .profile-picture-name {
      margin: 0px 0px 15px 0px;
      .profile-name {
        a {
          font-size: 14px;
          white-space: wrap;
        }
      }
      .profile-picture {
        width: 32px;
        height: 32px;
      }
    }
    .card-content {
      .description {
        margin: 10px 0px 0px 0px;
        span {
          font-size: 15px;
          line-height: 24px;
        }
      }
      .code-btn {
        .action-btn {
          padding: 4px 26px;
        }
      }
    }
  }
  @media screen and (min-width: 1600px) {
    .profile-picture-name {
      margin: 10px 0px;
      .profile-name {
        a {
          font-size: 20px;
        }
      }
      .profile-picture {
        width: 36px;
        height: 36px;
      }
    }
    .card-content {
      .code-btn {
        .action-btn {
          margin: 10px 0px 0px 0px;
          font-size: 18px;
          padding: 5px 30px;
        }
      }
      .description {
        span {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }
}

/**
 * @author Ahmed serag
 * @date   2018-03-28
 * @description Declaration of the Entity interface.
 * @filename entity.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Activity } from "./activity";
import { Connection } from "./connection";
import { DynamicList } from "./dynamic-list";
import { EntityProduct } from "./entity-product";
import { GeographicalLocation } from "./geographical-location";
import { InvestmentByEntity } from "./investment-by-entity";
import { InvestmentInEntity } from "./investment-in-entity";
import { ListType } from "./list";
import { Node } from "./node";
import { Person } from "./person";
import { Preview } from "./preview";
import { Range } from "./range";
import { SocialMediaProfile } from "./social-media-profile";
import { SocialMediaPost } from "./social-media-post";
import { StoryType } from "./story-type";
import { Questionnaire } from "./questionnaire";
import { Badge } from "./badge";
import { SocialMediaCredential } from "./social-media-credential";
import { Investment } from "./investment";
import { Opportunities } from "./opportunities";
import { Feed } from "./feed";
import { OriginalContentStory } from "./original-content-story";
import { FundingRoundKind } from "./funding-round-kind";

/**
 * The __typeID of the Entity  GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_ENTITY: string = "Entity";

/**
 * A company or other formal entity such as a partnership.
 *
 * @extends {Node}
 * @interface
 * @template D
 */
export interface Entity<D extends Date | string = string> extends Node<D> {
  /**
   * The entrepreneurial activities of the entity
   *
   * @type {Array<Activity>}
   * @memberof Entity
   */
  activities?: Activity[];

  /**
   * The collection of badges associated with the entity.
   *
   * @type {Badge[]}
   * @memberof Entity
   */
  badges?: Badge[];

  /**
   * Doing business as
   *
   * @type {string}
   * @memberof Entity
   */
  dba?: string;

  /**
   * The default geographical location of the entity.
   *
   * @type {GeographicalLocation}
   * @memberof Entity
   */
  defaultLocation?: GeographicalLocation;

  /**
   * a description of the entity.
   *
   * @type {string}
   * @memberof Entity
   */
  description?: string;

  /**
   * The type of the current funding round
   *
   * @type {FundingRoundType}
   * @memberof Entity
   */
  fundingRound?: FundingRoundKind;

  /**
   * The date and time when the entity was established
   *
   * @type {string}
   * @memberof Entity
   */
  founded?: D;

  /**
   * A fragment to access the entity page. This, or details, are populated when the person
   * is included as part of a list
   *
   * @type {string}
   * @memberof Entity
   */
  fragment?: string;

  /**
   * The collection of feed entity
   *
   * @type {Array<Follows>}
   * @memberof Person
   */
  feed?: Feed;

  /**
   * A flag which is true if the entity has a public page on the site
   *
   * @type {boolean}
   * @memberof Entity
   */
  hasPublicPage?: boolean;

  /**
   * A flag which is true if the entity has a portfolio of companies;
   * typically an entity such as an incubator, accelerator or institutional investor.
   *
   * @type {boolean}
   * @memberof Entity
   */
  hasPortfolio?: boolean;

  /**
   * The industry with which the entity is associated
   *
   * @type {string}
   * @memberof Entity
   */
  industry?: string;

  /**
   * The collection of investments made by an entity
   *
   * @type {Array<InvestmentByEntity>}
   * @memberof Entity
   */
  investmentsBy?: (InvestmentByEntity<D> | Investment<D>)[];

  /**
   * The collection of investments made in an entity
   *
   * @type {Array<InvestmentInEntity>}
   * @memberof Entity
   */
  investmentsIn?: (InvestmentInEntity | Investment<D>)[];

  /**
   * Set to true if the details of the entity may be claimed.
   */
  isClaimable?: boolean;

  /**
   *
   *
   * @type {boolean}
   * @memberof Entity
   */
  isFeedBaseEntity?: boolean;

  /**
   * Set to true if the details of the entity may be edited.
   */
  isEditable?: boolean;

  /**
   * A flag which is true if the entity is fundraising
   *
   * @type {boolean}
   * @memberof Entity
   */
  isFundraising?: boolean;

  /**
   * The list with which the person is associated. This is only populated when the person
   * is included as part of a list with this information configured.
   *
   * @memberof Entity
   * @template D
   * @type {ListType<D, *>}
   */
  list?: ListType<D, any>;

  /**
   * The current geographical locations of the entity
   *
   * @type {Array<GeographicalLocation>}
   * @memberof Entity
   */
  locations?: GeographicalLocation[];

  /**
   * A page in the collection of media items about the entity
   *
   * @template D
   * @type {Connection<D,StoryType<D>>}
   * @memberof Entity
   */
  mediaAbout?: Connection<D, StoryType<D>>;

  /**
   * A page in the collection of marketplace entries belongs the entity
   *
   * @template D
   * @type {Connection<D,StoryType<D>>}
   * @memberof Entity
   */
  marketplace?: Connection<D, StoryType<D>>;

  /**
   * People and entities which are related to the person as a list of people and a list of
   * entities
   *
   * @template D
   * @type {Array<DynamicList<D>>}
   * @memberof Entity
   */
  moreLikeThis?: DynamicList<D>[];

  /**
   * The name of the entity.
   *
   * @type {string}
   * @memberof Entity
   */
  name?: string;

  /**
   * A statement of belief - what makes the entity tick.
   *
   * @type {Questionnaire}
   * @memberof Entity
   */
  northStar?: Questionnaire;

  /**
   * The current number of employees.
   *
   * @type {Range}
   * @memberof Entity
   */
  noOfEmployees?: Range;

  /**
   * The number of portfolio entities of the entity.
   *
   * @type {(number | RangeModel)}
   * @memberof EntityModel
   */
  noOfPortfolioCompanies?: Range;

  /**
   * A page in the collection of people associated with the entity.
   *
   * @template D
   * @type {Connection<D, Person<D>>}
   * @memberof Entity
   */
  people?: Connection<D, Person<D>>;

  /**
   * A list of entities forming the portfolio of this entity.
   *
   * @type {Connection<D, Entity<D>>}
   * @memberof Entity
   */
  portfolio?: Connection<D, Entity<D>>;

  /**
   * The ID of the list collection containing the entities in the portfolio of the entity organized by category.
   *
   * @type {string}
   * @memberof EntityModel
   */
  portfolioEntities?: string;

  /**
   * Posts associated with the entity
   *
   * @type {Connection<SocialMediaPost>}
   * @memberof Entity
   */
  posts?: Connection<D, SocialMediaPost<D>>;

  /**
   * The ID of the list collection containing the people in the portfolio of the entity organized by category.
   *
   * @type {string}
   * @memberof Entity
   */
  portfolioPeople?: string;

  /**
   * A non-normative description of the market cap of the portfolio companies.
   *
   * @type {string}
   * @memberof Entity
   */
  portfolioMarketCap?: string;

  /**
   * A collection of media assets used as previews for the entity. Only one media asset can act as a given
   * type of preview
   *
   * @template D
   * @type {Connection<D, Preview>}
   */
  previews?: Connection<D, Preview>;

  /**
   * A collection of products offered by the entity
   *
   * @type {Array<EntityProduct>}
   * @memberof Entity
   */
  products?: EntityProduct<D>[];

  /**
   * The collection of questionnaires to which the person may have responded
   *
   * @type {Connection<D, Questionnaire<D>>}
   * @memberof Entity
   */
  questionnaires?: Connection<D, Questionnaire<D>>;

  /**
   * A page in the collection of social media posts made by the entity
   *
   * @type {Array<SocialMediaProfile>}
   * @memberof Entity
   */
  socialMediaProfiles?: SocialMediaProfile[];

  /**
   * The social media credentials of the entity
   *
   * @type {[SocialMediaCredential]}
   * @memberof Entity
   */
  socialMediaCredentials?: SocialMediaCredential[];

  /**
   * The tagline for the entity
   *
   * @type {string}
   * @memberof Entity
   */
  tagLine?: string;

  /**
   * The URL of the entity's website
   *
   * @type {string}
   * @memberof Entity
   */
  websiteUrl?: string;

  /**
   * The landing page url of the entity.
   *
   * @type {string}
   * @memberof Story
   */
  landingPageUrl?: string;

  /**
   *
   *
   * @type {Person[]}
   * @memberof Entity
   */
  verifiedOwners?: Person[];

  /**
   *
   *
   * @type {OriginalContentStory}
   * @memberof Entity
   */
  pitchOriginalVideo?: OriginalContentStory;

  /**
   *
   *
   * @type {Opportunities<D>}
   * @memberof Entity
   */
  opportunities?: Opportunities<D>;
}

/**
 * @author Ahmed Samer
 * @copyright Copyright 2019 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-07
 * @description Implementation of the GraphQL query to retrieve the social media feed data.
 * @filename entity-social-media-feed-query.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL query to retrieve the current entity posts.
 *
 * @type {GraphQLTaggedNode}
 */
export const ENTITY_QUERY_TICKER_FEED: GraphQLTaggedNode = graphql`
  query entityQuery($id: ID!) {
    entity(id: $id) {
      hasErrors
      entity {
        __typename
        landingPageUrl
        feed(kind: TICKER) {
          keys
          feed {
            key
            stories {
              __typename
              ... on RadivisionPost {
                id
                link
                title
              }
              ... on ArticleStory {
                id
                link
                title
              }
            }
          }
        }
      }
    }
  }
`;

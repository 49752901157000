/**
 * @author William Alexander Livesley
 * @date   2018-03-22
 * @description Declaration of the Change interface.
 * @filename change.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

/**
 * The __typeID of the API Change GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_CHANGE: string = "Change";

/**
 * An enumeration of the different kinds of changes which can be described by a change record.
 *
 * @export
 * @enum {string}
 */
export enum ChangeType {
  // The node is created
  CREATE = "CREATE",

  // The node is deleted
  DELETE = "DELETE",

  // The node is updated
  UPDATE = "UPDATE"
}

/**
 *  A change made to a node.
 *
 * @export
 * @interface Change
 */
export interface Change {
  /**
   * The details of the change made to the node
   *
   * @type {any}
   * @memberof Change
   */
  change?: any;

  /**
   * The type of the change
   *
   * @type {ChangeType}
   * @memberof Change
   */
  type?: ChangeType;
}

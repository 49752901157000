import { ToastReducer } from "./reducer";
import { ToastModuleState } from "./types";

export { ToastModuleState };

export const toastModule = {
  id: "toast",
  reducerMap: {
    toast: ToastReducer,
  },
};

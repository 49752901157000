/**
 * @author William Alexander Livesley
 * @date   2018-05-28
 * @description Declaration of the HtmlDocumentMetaHeaderEquivTypeKind interface.
 * @filename html-document-meta-header-http-equiv-type.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the HTML document meta header http-equiv attribute GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_HTML_DOCUMENT_META_HEADER_HTTP_EQIV_TYPE: string =
  "HtmlDocumentMetaHeaderHttpEquivType";

/**
 * An enumeration of the legal values for the HTML document meta header http-equiv attribute.
 */
export enum HtmlDocumentMetaHeaderHttpEquivTypeKind {
  /**
   * The character encoding for the document.
   */
  CONTENT_TYPE = "CONTENT_TYPE",

  /**
   * The preferred style sheet to use.
   */
  DEFAULT_STYLE = "DEFAULT_STYLE",

  /**
   * The time interval, in seconds, for the document to refresh itself.
   */
  REFRESH = "REFRESH",

  /**
   * The document mode used to identify version of Internet Explorer the page should be rendered as.
   */
  X_UA_COMPATIBLE = "X_UA_COMPATIBLE"
}

/**
 * A legal value of the HTML document meta header http-equiv attribute.
 *
 * @extends {Node}
 * @interface
 * @template D
 */
export interface HtmlDocumentMetaHeaderHttpEquivType<D extends Date | string = string>
  extends Node<D> {
  /**
   * A description of the HTML document meta header http-equiv attribute value. Metadata.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The kind of the HTML document meta header http-equiv attribute value. Metadata.
   *
   * @type {HtmlDocumentMetaHeaderHttpEquivTypeKind}
   */
  kind?: HtmlDocumentMetaHeaderHttpEquivTypeKind;

  /**
   * A label of the HTML document meta header http-equiv attribute value. Metadata.
   *
   * @type {string}
   */
  label?: string;
}

import * as React from "react";
import { Modal } from "../../modals/modal";
import { connect } from "react-redux";
import $ from "jquery";
import { RdvButton } from "../../page-framework/rdv-button";
import { Checkbox } from "../../page-framework/checkbox";
import { AppState } from "../../../redux";
import { Toaster } from "../../page-framework/toaster";
import * as Actions from "../../../redux/actions";
import { CreateNotificationRequestPayload, CreateNotificationRequestAction } from "../../../redux/notification/types";
import isEmpty from "lodash.isempty";
import { SetToastMessageAction, SetToastMessagePayload } from "../../../redux/toast/types";
import { TOAST_ALERT } from "../../../redux/toast/constants";

interface MapDispatchProps {
  createNotificationRequest: (payload: CreateNotificationRequestPayload) => CreateNotificationRequestAction;
  setToastMessage: (payload: SetToastMessagePayload) => SetToastMessageAction;
}

interface MapStateProps {
  isLoading: boolean;
  subscriptionStatus: string;
}

export type Props = MapDispatchProps & MapStateProps;

export const notifyMeModalId = "pitch-notify-me-modal";

export const Component = (props: Props) => {
  const { createNotificationRequest, subscriptionStatus, isLoading, setToastMessage } = props;
  const [checked, onChange] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const isValidEmail = /.+@.+\..+/g.test(email);
  React.useEffect(() => {
    if (!isEmpty(subscriptionStatus)) {
      setEmail(subscriptionStatus);
    }
  }, [subscriptionStatus]);

  const onSubmit = React.useCallback(() => {
    if (!isValidEmail) {
      setToastMessage({ msg: `Please enter a valid email address`, type: TOAST_ALERT });
      return;
    }
    createNotificationRequest({ notificationKind: "PITCH_PLANET", email });
    setEmail("");
  }, [createNotificationRequest, email, isValidEmail, setToastMessage]);

  return (
    <Modal id={`pitch-notify-me-modal`} title={"Subscribe To Pitch Planet Notification"} className="notify-me-modal">
      <div className="content" style={{ position: "relative" }}>
        <p>Please enter you email address to be notified when Pitch Planet will be going live!</p>
        <div className="notify-me-form">
          <input
            placeholder="Your Email Address"
            className={`notify-me-email-address`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Checkbox
            checked={checked}
            onChange={() => onChange(!checked)}
            label="Sign me up for Radivision's newsletter"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="row">
          <RdvButton text="Notify Me" onClick={onSubmit} isLoading={isLoading} />
        </div>
      </div>
    </Modal>
  );
};
const mapState = (state: AppState) => ({
  subscriptionStatus: state.notification.status["PITCH_PLANET"],
  isLoading: state.notification.isLoading,
});

const mapDispatch = {
  createNotificationRequest: Actions.createNotificationRequest,
  setToastMessage: Actions.setToastMessage,
};

export const NotifyMeModal = connect(mapState, mapDispatch)(Component);

/**
 * @author Ahmed Samer
 * @date   2019-11-19
 * @description Declaration of the PreviewType interface and PreviewKind enumeration.
 * @filename funding-round-kind.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the Preview Type type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_FUNDING_ROUND_KIND: string = "FundingRoundKind";

/**
 * The properties of a use case for a preview for a person, entity, story list or list collection.
 *
 * @enum
 */
export enum FundingRoundKind {
  /**
   * Investment from Angel investors.
   */
  ANGEL = "Angel",

  /**
   * Founder resources.
   */
  FOUNDERS = "Founders",

  /**
   * A short sequence from the original media asset.
   */
  FRIENDS_FAMILY = "Friends and Family",

  /**
   * A headshot preview.
   */
  PRE_SEED = "Pre-seed",

  /**
   * A 21:9 media asset to be displayed in a hero.
   */
  SEED = "Seed",

  /**
   * A logo to be displayed in a hero.
   */
  SERIES_A = "Series A",

  /**
   * A logo to be displayed in a hero.
   */
  SERIES_B = "Series B",
  /**
   * A logo to be displayed in a hero.
   */
  SERIES_C = "Series C",
  /**
   * A logo to be displayed in a hero.
   */
  SERIES_D = "Series D"
}

/**
 * The properties of a use case for a preview for a person, entity, story list or list collection.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface FundingRoundType<D extends Date | string = string>
  extends Node<D> {
  /**
   * A description of the preview type.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The kind of the preview type.
   *
   * @type {PreviewKind}
   */
  kind?: FundingRoundKind;

  /**
   * A label of the preview type.
   *
   * @type {string}
   */
  label?: string;
}

import { take, call, put, all, fork } from "redux-saga/effects";
import * as Actions from "./actions";
import * as Api from "./api";
import { CrawlFetchAction } from "./types";
import { CRAWL_FETCH } from "./constants";

export function* crawlFetchWorker(action: CrawlFetchAction) {
  try {
    const data = yield call(Api.crawlFetch, action.payload.url);
    yield put(Actions.crawlFetching({ status: true }));
    const payload = {
      url: String(action.payload.url),
      title: String(data?.title),
      subTitle: String(data?.subTitle),
      durationInSeconds: data?.durationInSeconds,
      link: String(data?.link),
      description: String(data?.description),
      previewUrl:
        data && data.previews && data.previews.edges[0] && String((data.previews.edges[0] as any).preview?.link),
    };
    yield put(Actions.crawlFulfilled(payload));
    yield put(Actions.crawlFetching({ status: false }));
  } catch (error) {
    const payload = {
      url: String(action.payload.url),
      message: error,
    };
    yield put(Actions.crawlFulfilled(payload));
    yield put(Actions.crawlFetching({ status: false }));
  }
}
export function* watchCrawlFetch() {
  while (true) {
    const action = yield take(CRAWL_FETCH);
    yield call(crawlFetchWorker, action);
  }
}

const sagas = [watchCrawlFetch];
export default function* root() {
  yield all(sagas.map((saga) => fork(saga)));
}

/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2019-02-11
 * @filename: content-moderation-state-by-role.ts
 * @description: Declaration of content moderation state  interface
 */

import { ContentModerationRole } from "./content-moderation-role";
import { ContentModerationStateKind } from "./content-moderation-state-kind";
import { Node } from "./node";

/**
 * The __typeID of the Moderation State by role type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_CONTENT_MODERATION_STATE_BY_ROLE: string =
  "ContentModerationStateByRole";

/**
 *The content moderation state of a story
 *
 * @export
 * @interface ContentModerationState
 * @extends {Node<D>}
 * @template D
 */
export interface ContentModerationStateByRole<D extends Date | string = string>
  extends Node<D> {
  
  /**
   * A description of the moderation state
   *
   * @type {string}
   * @memberof ContentModerationState
   */
  description?: string;
  
  /**
   *  The kind of the moderation state
   *
   * @type {ContentModerationStateKind!}
   * @memberof ContentModerationState
   */
  kind: ContentModerationStateKind;

  /**
   *  The label of the moderation state
   *
   * @type {string}
   * @memberof ContentModerationState
   */
  label?: string;

  /**
   * The role of the content moderator setting the content moderation state
   *
   * @type {ContentModerationRole}
   * @memberof ContentModerationStateByRole
   */
  role?: ContentModerationRole;
}

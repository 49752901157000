/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CreateMediaAssetFileInput = {
    readonly clientMutationId: string;
    readonly copies?: ReadonlyArray<string> | null;
    readonly description?: string | null;
    readonly format: string;
    readonly heightInPixels?: number | null;
    readonly mediaAsset?: string | null;
    readonly name: string;
    readonly path?: string | null;
    readonly tags?: ReadonlyArray<string> | null;
    readonly widthInPixels?: number | null;
    readonly sizeInBytes?: number | null;
};
export type createMediaAssetFileMutationVariables = {
    readonly input: CreateMediaAssetFileInput;
};
export type createMediaAssetFileMutationResponse = {
    readonly createMediaAssetFile: {
        readonly clientMutationId: string;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly mediaAssetFile: {
            readonly id: string;
            readonly name: string | null;
        } | null;
    } | null;
};
export type createMediaAssetFileMutation = {
    readonly response: createMediaAssetFileMutationResponse;
    readonly variables: createMediaAssetFileMutationVariables;
};



/*
mutation createMediaAssetFileMutation(
  $input: CreateMediaAssetFileInput!
) {
  createMediaAssetFile(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    mediaAssetFile {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateMediaAssetFileInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createMediaAssetFile",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteUpdateMediaAssetFilePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mediaAssetFile",
        "storageKey": null,
        "args": null,
        "concreteType": "MediaAssetFile",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "createMediaAssetFileMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "createMediaAssetFileMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "createMediaAssetFileMutation",
    "id": null,
    "text": "mutation createMediaAssetFileMutation(\n  $input: CreateMediaAssetFileInput!\n) {\n  createMediaAssetFile(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    mediaAssetFile {\n      id\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '55c8d4ad32558a2402291966f23443cc';
export default node;

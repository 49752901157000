/**
 * @Author: Adham El Banhawy
 * @Date:   2018-07-10
 * @Filename: linkedin-authenticator.ts
 * @Copyright: Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Description: Linkedin authentication methods
 */


import { getNewUniqueId } from "@radivision/common";

/**
 * The attributes returned from LinkedIn authentication.
 *
 * @interface
 */
export interface LinkedinResponseData {
  /**
   * The access token returned from LinkedIn after successful authentication.
   *
   * @type {string}
   */
  access_token: string;

  /**
   * The email address of the user authenticated by Linkedin.
   *
   * @type {string}
   */
  emailAddress: string;

  /**
   * First Name of the user authenticated by LinkedIn.
   *
   * @type {string}
   */
  firstName: string;

  /**
   * The Linkedin id of the user authenticated by Linkedin.
   *
   * @type {string}
   */
  id: string;

  /**
   * Last Name of the user authenticated by Linkedin.
   *
   * @type {string}
   */
  lastName: string;

  /**
   * The OpenId token returned from AppSync after successful registration with cognito.
   *
   * @type {string}
   */
  openId_token: string;
}

/**
 * The response from the Linkedin Lambda function.
 *
 * @interface
 */
export interface OpenIdResponse {
  /**
   * A unique identifier generated by AWS.
   *
   * @type {string}
   */
  IdentityId: string;

  /**
   * An OpenId token.
   *
   * @type {string}
   */
  OpenIdToken: string;
}

/**
 *
 */
export class LinkedInAuthenticator {
  /**
   * A function that constructs and redirects to Linkedin authentication page.
   *
   * @return {Window} A window redirect to the Linkedin Login page.
   */
  static requestLinkedInAuthorizationCode(): any {
    const initialState: string = getNewUniqueId();
    // URL to request an Oauth 2.0 Authorization code from LinkedIn
    const url: string =
      `https://www.linkedin.com/oauth/v2/authorization?` +
      `response_type=code&` +
      `client_id=${process.env.LINKEDIN_CLIENT_ID}&` +
      `redirect_uri=${encodeURIComponent(process.env.HOME_URI)}&` +
      `state=${initialState}&` +
      `scope=r_liteprofile%20r_emailaddress%20w_member_social`;

    LinkedInAuthenticator.openPopup(url, "_self");
  }
 

  /**
   * A function that opens up a popup authentication window
   *
   * @returns {Window} A new window that holds an authentication page
   */
  static openPopup(url: string, target: string): Window {
    return window.open(url, target);
  }
}

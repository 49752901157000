import React, { createContext, useState, useContext } from "react";

const MuteContext = createContext({});

export const MuteProvider = ({ children }) => {
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = (value: boolean) => {
    setIsMuted(value);
  };

  return <MuteContext.Provider value={{ isMuted, toggleMute }}>{children}</MuteContext.Provider>;
};

export const useMute = () => {
  const context = useContext(MuteContext);
  if (!context) {
    throw new Error("useMute must be used within a MuteProvider");
  }
  return context;
};

import { graphql, GraphQLTaggedNode } from "react-relay";

export const LIST_COLLECTION_QUERY: GraphQLTaggedNode = graphql`
  query listCollectionQuery($listCollectionId: ID!, $count: Int) {
    listCollection(id: $listCollectionId) {
      errors {
        created
        id
        location
        type
      }
      hasErrors
      listCollection {
        description
        id
        label
        badges {
          imageLink
          link
          previews {
            ...previewsConnection_edges @relay(mask: false)
          }
        }
        lists {
          __typename
          ... on FixedList {
            description
            id
            items(first: $count) {
              pageInfo {
                hasNextPage
              }
              edges {
                listItem {
                  item {
                    __typename
                    ... on Event {
                      ...eventItem @relay(mask: false)
                    }
                    ... on PitchVideoStory {
                      ...pitchVideoStoryItem @relay(mask: false)
                    }
                    ... on OriginalContentStory {
                      ...originalContentStoryItem @relay(mask: false)
                    }
                    ... on PodcastStory {
                      ...podcastStoryItem @relay(mask: false)
                    }
                    ... on Poster {
                      ...poster_item @relay(mask: false)
                    }
                    ... on Entity {
                      ...entityItem_item @relay(mask: false)
                    }
                    ... on Person {
                      ...personItem_item @relay(mask: false)
                    }
                    ... on ArticleStory {
                      ...articleStory_item @relay(mask: false)
                    }
                    ... on BookStory {
                      ...bookStory_item @relay(mask: false)
                    }
                    ... on NewsletterStory {
                      ...newsletterStory_item @relay(mask: false)
                    }
                    ... on OnlineCourseStory {
                      ...onlineCourseStory_item @relay(mask: false)
                    }
                  }
                }
              }
            }
            label
            name
            previews {
              ...previewsConnection_edges @relay(mask: false)
            }
            subTitle
            title
            revision
          }
          ... on DynamicList {
            description
            id
            label
            name
            items(first: $count) {
              edges {
                listItem {
                  item {
                    __typename
                    ... on SearchHit {
                      ...searchHit_item @relay(mask: false)
                    }
                  }
                }
              }
            }
            previews {
              ...previewsConnection_edges @relay(mask: false)
            }
            subTitle
            title
            revision
          }
        }
        previews {
          ...previewsConnection_edges @relay(mask: false)
        }
        name
        subTitle
        title
        revision
      }
    }
  }
`;

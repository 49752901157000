/**
 * @author Ahmed serag
 * @date   2018-04-19
 * @description Declaration of the Investment interface.
 * @filename investment.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";
import { Entity } from "./entity";
import { Person } from "./person";

/**
 * The __typeID of the Investment type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_INVESTMENT: string = "Investment";

/**
 *  An investment.
 *
 * @export
 * @interface Investment
 * @extends {Node<D>}
 * @template D
 */
export interface Investment<D extends Date | string = string> extends Node<D> {
  /**
   *
   *
   * @type {string}
   * @memberof Investment
   */
  id?: string;

  /**
   * The amount of the investment
   *
   * @type {number}
   * @memberof Investment
   */
  amount?: string;

  /**
   * The currency unit of the investment as an ISO 4217 alphabetic code
   *
   * @type {string}
   * @memberof Investment
   */
  currency?: string;

  /**
   * The entity who made the investment
   *
   * @template D
   * @type {Entity}
   * @memberof Investment
   */
  entity?: Entity<D>;

  /**
   * The entity in whom the investment was made
   *
   * @template D
   * @type {Entity<D>}
   * @memberof Investment
   */
  investee?: Entity<D>;

  /**
   * The timestamp when the investment was made
   *
   * @template D
   * @type {D}
   * @memberof Investment
   */
  investmentDate?: D;

  /**
   * The timestamp when the investment was made
   *
   * @template D
   * @type {D}
   * @memberof Investment
   */
  investmentEndDate?: D;

  /**
   * The person or collection of people who made the investment on behalf of the entity
   *
   * @template D
   * @type {Array<Person<D>>}
   * @memberof Investment
   */
  people?: Person<D>[];

  /**
   *
   *
   * @type {string}
   * @memberof Investment
   */
  link?: string;

  /**
   *
   *
   * @type {string}
   * @memberof Investment
   */
  deckUrl?: string;
}

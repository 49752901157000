import { isValidEmail } from "./is-valid-email";

export const addHttpToLink = (link: string) => {
  const isNotEmail = !isValidEmail(link);
  const isMissingMailTo = !link.startsWith("mailto");
  const isMissingHttp = !link.startsWith("http");
  if (isNotEmail && isMissingHttp) {
    return `https://${link}`;
  }
  if (isValidEmail(link) && isMissingMailTo) {
    return `mailto:${link}`;
  }
  return link;
};

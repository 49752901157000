/**
 * @author William Alexander Livesley
 * @date   2018-04-08
 * @description Declaration of the MediaType interface.
 * @filename media-types.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { FileExtension } from "./file-extension";
import { Node } from "./node";
import { TopLevelMediaType } from "./top-level-media-type";

/**
 * The __typeID of the GraphQL Media Asset Type object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_MEDIA_TYPE: string = "MediaType";

/**
 * The properties of a media type.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface MediaType<D extends Date | string = string> extends Node<D> {
  /**
   * A description of the media type.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The default file extensions for files of the media type.
   *
   * @type {FileExtension}
   */
  defaultFileExtension?: FileExtension;

  /**
   * The media type identifier.
   *
   * @type {string}
   */
  identifier?: string;

  /**
   * The file extensions associated with the media type.
   *
   * @type {Array<FileExtension>}
   */
  fileExtensions?: FileExtension[];

  /**
   * The sub-type of the media type.
   *
   * @type {string}
   */
  subType?: string;

  /**
   * The top level media type for the media type.
   *
   * @type {TopLevelMediaType}
   */
  topLevelMediaType?: TopLevelMediaType;
}

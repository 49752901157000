/**
 * @author Muhammad Omran
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-11-17
 * @description Implementation of Confirm dismiss Modal if there is changes made to a form.
 * @filename confirm-dismiss-modal.tsx
 */
import $ from "jquery";
import React from "react";
import { RdvButton } from "../page-framework/rdv-button";

/**
 * The props of the ConfirmationModal class.
 *
 * @interface
 */
interface ConfirmationModalProps {
  /**
   * the item callback with the confirm handler
   * 
   * @type {string}
   * @memberof ConfirmationModalProps
   */
  callbackItem?: string;

  /**
   * custom text for the cancel button
   * 
   * @type {string}
   * @memberof ConfirmationModalProps
   */
  cancelBtnText?: string;

  /**
   * The name of the css class for the ConfirmationModal - overrides the default.
   *
   * @type {string}
   */
  className?: string;

  /**
   * the function to trigger When click on Discard and lose changes
   *
   * @type {Function}
   */
  confirm: Function;

  /**
   * The ID of the parent div element.
   *
   * @type {string}
   */
  id: string;

  /**
   * confirm message
   * 
   * @type {string}
   * @memberof ConfirmationModalProps
   */
  message: string;

  /**
   * custom text for the yes button
   * 
   * @type {string}
   * @memberof ConfirmationModalProps
   */
  yesBtnText?: string;
}

interface ConfirmationModalState {
  cleared: boolean;
}
/**
 * A React component that renders ConfirmationModals.
 *
 * @extends {React.Component<ConfirmationModalProps>}
 */
export class ConfirmationModal extends React.Component<
  ConfirmationModalProps,
  ConfirmationModalState
> {
  /**
   * Constructor.
   *
   * @param {ConfirmationModalProps} props The props of the component.
   */
  constructor(props: ConfirmationModalProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      cleared: false
    };
  }

  /**
   *
   * @memberof ConfirmationModalProps
   */
  closeModal(e) {
    $(`#${this.props.id}`).modal("hide");
    $(`.modal`).css({ overflowX: "hidden", overflowY: "auto" });
    this.setState({
      cleared: !this.state.cleared
    });
  }
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let modalDialogClassName = "modal-dialog modal-dialog-centered modal-lg";

    if (this.props.className) {
      modalDialogClassName = `${modalDialogClassName} ${this.props.className}`;
    }
    return (
      <div
        className="modal fade confirmation-modal"
        id={this.props.id}
        role="dialog"
        aria-hidden="true"
      >
        <div className={modalDialogClassName} role="document">
          <div className="modal-content">
            <div className="modal-body">
              <span>{this.props.message}</span>
              <div className="modal-actions">
                <RdvButton
                  isOutline={true}
                  extraClassName="outline-light cancel-button"
                  text={this.props.cancelBtnText ? this.props.cancelBtnText : "Cancel"}
                  onClick={this.closeModal}
                />
                <RdvButton
                  extraClassName="add-media-btn confirm-button"
                  text={this.props.yesBtnText ? this.props.yesBtnText : "Confirm"}
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    $(`#${this.props.id}`).modal("hide");
                    this.props.confirm(this.props.callbackItem ? this.props.callbackItem : undefined);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * @author W. Alex Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2018-04-01
 * @description Implements the MediaAssetStateKind enum containing an enumeration of the kinds of the states of media
 * assets and the MediaAssetState interface declaring the properties of a media asset state.
 * @filename media-asset-state.ts
 */
import { Node } from "./node";

/**
 * The __typeID of the GraphQL Media Asset State object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_MEDIA_ASSET_STATE: string = "MediaAssetState";

/**
 * An enumeration of the kinds of the states of media assets.
 */
export enum MediaAssetStateKind {
  /**
   * The media asset is available.
   */
  AVAILABLE = "AVAILABLE",

  /**
   * The media asset is created but not yet available.
   */
  CREATED = "CREATED",

  /**
   * The media asset is deleted.
   */
  DELETED = "DELETED",

  /**
   * An attempt made to process one or more media asset files associated with the media asset has failed.
   */
  ERROR = "ERROR",

  /**
   * One or more media asset files associated with the media asset have been uploaded and are being processed.
   */
  PROCESSING = "PROCESSING"
}

/**
 * The properties of a media asset state.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface MediaAssetState<D extends Date | string = string> extends Node<D> {
  /**
   * A description of the media asset state.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The kind of a media asset state.
   *
   * @type {MediaAssetStateKind}
   */
  kind?: MediaAssetStateKind;

  /**
   * A label of the media asset state.
   *
   * @type {string}
   */
  label?: string;
}


export const esc = (name: string) => {
  const _name = encodeURIComponent(
    name
      ?.replace(/'/g, "")
      .replace(/\W+/g, "-")
      .replace(/-+$/, "")
      .replace(/^-+/, ""),
  );
  return _name;
};

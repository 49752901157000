import { ImageHelper } from "./image-helper";
import { PreviewKind, Entity, TopLevelMediaKind, Person } from "@radivision/graphql";

export function getProfilePhoto(
  subject: Entity | Person,
  kind: PreviewKind.BACKGROUND | PreviewKind.SQUARE = PreviewKind.SQUARE,
) {
  return subject?.previews
    ? ImageHelper.fetchOptimizedImageUrl({
        imageType: "PREVIEW",
        preview: {
          content: subject?.previews,
          previewKind: kind,
          topLevelMedia: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: { containerWidthRatio: 1, numberOfItems: 1 },
        isSquareImage: kind === PreviewKind.SQUARE ? true : false,
        revision: subject.revision,
      })
    : null;
}

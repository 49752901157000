import * as React from "react";
import $ from "jquery";

export const Checkmark = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      version="1.1"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.5 2l-7.5 7.5-3.5-3.5-2.5 2.5 6 6 10-10z"></path>
    </svg>
  );
};

export const Checkbox = ({
  checked,
  onChange,
  label,
}: {
  checked: boolean;
  onChange?: () => void;
  label?: string | JSX.Element;
}) => {
  return (
    <div className="checkbox-control" onClick={typeof onChange === "function" ? onChange : $.noop}>
      <div className="checkbox-sign">{checked && <Checkmark />}</div>
      {label && <label>{label}</label>}
    </div>
  );
};

/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-11
 * @description Implementation of Article utilities to be used in the app.
 * @filename entity.ts
 */

import {
  AddToEntityInput,
  CreateEntityInput,
  CreateUpdatePreviewInput,
  SocialMediaPlatformKind,
  UpdateEntityInput,
} from "@radivision/graphql";
import { CreateUpdateSocialMediaCredentialInput } from "@radivision/graphql/lib/ts/graphql/mutations/create-update-social-media-credential-input";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { EntityPageFormInput } from "../../component-configuration/cms-pages";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";
import { RemoveFromEntityInput } from "@radivision/graphql/lib/ts/graphql/mutations/remove-from-entity-input";

/**
 *
 *
 * @export
 * @class Entity
 */
export class Entity {
  /**
   *
   *
   * @static
   * @param {*} entityInput
   * @param {Environment} [environment=RELAY_ENVIRONMENT]
   * @returns {Promise<any>}
   * @memberof Entity
   */
  static addEntity(entityInput: EntityPageFormInput, environment: Environment = RELAY_ENVIRONMENT): Promise<any> {
    // ... convert article input to  CreateArticleStoryInput
    let createEntityInput: CreateEntityInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };
    let previewsInput: CreateUpdatePreviewInput[];
    const SOCIAL_MEDIA_CREDENTIALS: CreateUpdateSocialMediaCredentialInput[] = Entity.handleSocialMediaCredentialsInput(
      entityInput,
    );

    if (entityInput.socialMediaCredentials === undefined || entityInput.socialMediaCredentials === null) {
      entityInput.socialMediaCredentials = SOCIAL_MEDIA_CREDENTIALS;
    }

    if (entityInput.previews !== undefined && entityInput.previews !== null && entityInput.previews.length > 0) {
      // handle preview inputs
      // handle preview inputs
      previewsInput = [];
      for (const PREVIEW of entityInput.previews) {
        previewsInput.push({
          kind: PREVIEW.kind,
          link: PREVIEW.link,
          mediaAsset: PREVIEW.mediaAsset,
        });
      }
    }

    createEntityInput = {
      clientMutationId: getClientMutationId(),
      name: entityInput.name && entityInput.name.length > 0 ? entityInput.name.trim() : undefined,
      description:
        entityInput.description && entityInput.description.length > 0 ? entityInput.description.trim() : undefined,
      socialMediaCredentials:
        entityInput.socialMediaCredentials && entityInput.socialMediaCredentials.length > 0
          ? entityInput.socialMediaCredentials
          : undefined,
      tags: entityInput.tags && entityInput.tags.length > 0 ? entityInput.tags : undefined,
      mediaAbout: entityInput.mediaAbout && entityInput.mediaAbout.length ? entityInput.mediaAbout : undefined,
      previews: previewsInput,
      websiteUrl: entityInput.website && entityInput.website.length > 0 ? entityInput.website.trim() : undefined,
      portfolioEntities:
        entityInput.portfolioEntities && entityInput.portfolioEntities.length > 0
          ? entityInput.portfolioEntities.trim()
          : undefined,
      portfolioPeople:
        entityInput.portfolioPeople && entityInput.portfolioPeople.length > 0
          ? entityInput.portfolioPeople.trim()
          : undefined,
    };

    //
    promise = import("../../relay/mutations-entity").then((mutations) => {
      config = {
        mutation: mutations.CREATE_ENTITY_GRAPHQL_MUTATION,
        variables: {
          input: createEntityInput,
        },
      };
      return commitMutation(environment, config);
    });

    promise = promise.then((mutationResults: any) => {
      return mutationResults.createEntity.hasErrors === false
        ? Promise.resolve(mutationResults.createEntity.entity)
        : Promise.reject(mutationResults.createEntity.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Entity
   */
  static updateEntity(entityInput: EntityPageFormInput, environment: Environment = RELAY_ENVIRONMENT): Promise<any> {
    let promise: Promise<any>;
    try {
      const updateEntityInput: UpdateEntityInput = Entity.generateAddToUpdateEntityInput(entityInput);
      let config: { mutation: GraphQLTaggedNode; variables: {} };

      if (updateEntityInput === undefined || updateEntityInput === null) {
        promise = Promise.reject(new Error("qRLjjKOjOkyULFZjSRvLnA"));
      }

      promise = import("../../relay/mutations-entity").then((mutations) => {
        config = {
          mutation: mutations.UPDATE_ENTITY_GRAPHQL_MUTATION,
          variables: {
            input: updateEntityInput,
          },
        };
        return commitMutation(environment, config);
      });

      promise = promise.then((mutationResults: any) => {
        return mutationResults.updateEntity.hasErrors === false
          ? Promise.resolve(mutationResults.updateEntity.entity)
          : Promise.reject(mutationResults.updateEntity.errors);
      });
      return promise;
    } catch (e) {
      promise = Promise.reject({ message: e.message });
      return promise;
    }
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Entity
   */
  static removeFromEntity(
    entityInput: RemoveFromEntityInput,
    environment: Environment = RELAY_ENVIRONMENT,
  ): Promise<any> {
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    if (entityInput === undefined || entityInput === null) {
      promise = Promise.reject(new Error("qRLjjKOjOkyULFZjSRvLnA"));
    }

    promise = import("../../relay/mutations-entity").then((mutations) => {
      config = {
        mutation: mutations.REMOVE_FROM_ENTITY_GRAPHQL_MUTATION,
        variables: {
          input: entityInput,
        },
      };
      return commitMutation(environment, config);
    });

    promise = promise.then((mutationResults: any) => {
      return mutationResults.removeFromEntity.hasErrors === false
        ? Promise.resolve(mutationResults.removeFromEntity.entity)
        : Promise.reject(mutationResults.removeFromEntity.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Entity
   */
  static addToEntity(entityInput: EntityPageFormInput, environment: Environment = RELAY_ENVIRONMENT): Promise<any> {
    let promise: Promise<any>;
    try {
      const addToEntityInput: UpdateEntityInput = Entity.generateAddToUpdateEntityInput(entityInput);
      let config: { mutation: GraphQLTaggedNode; variables: {} };

      if (addToEntityInput === undefined || addToEntityInput === null) {
        promise = Promise.reject(new Error("rXjGf87P4UiAjfiE8LFDCQ"));
      }

      promise = import("../../relay/mutations-entity").then((mutations) => {
        config = {
          mutation: mutations.ADD_TO_ENTITY_GRAPHQL_MUTATION,
          variables: {
            input: addToEntityInput,
          },
        };
        return commitMutation(environment, config);
      });

      promise = promise.then((mutationResults: any) => {
        return mutationResults.addToEntity.hasErrors === false
          ? Promise.resolve(mutationResults.addToEntity.entity)
          : Promise.reject(mutationResults.addToEntity.errors);
      });
    } catch (e) {
      promise = Promise.reject({ message: e.message });
    }

    return promise;
  }

  /**
   *
   *
   * @static
   * @returns {Promise<any>}
   * @memberof Entity
   */
  static deleteEntity(entityInput: EntityPageFormInput, environment: Environment = RELAY_ENVIRONMENT): Promise<any> {
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    if (entityInput === undefined || entityInput === null || entityInput.id === undefined) {
      promise = Promise.reject(new Error("rT5GYiG4RECcoCERNRyNgw"));
    }

    const input = {
      id: entityInput.id,
      revision: entityInput.revision,
      clientMutationId: getClientMutationId(),
    };

    //
    promise = import("../../relay/mutations-entity").then((mutations) => {
      config = {
        mutation: mutations.DELETE_ENTITY_GRAPHQL_MUTATION,
        variables: {
          input,
        },
      };
      return commitMutation(environment, config);
    });

    promise = promise.then((mutationResults: any) => {
      return mutationResults.deleteEntity.hasErrors === false
        ? Promise.resolve(mutationResults.deleteEntity.entity)
        : Promise.reject(mutationResults.deleteEntity.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @param {PersonPageFormInput} personInput
   * @returns {CreateUpdateSocialMediaCredentialInput[]}
   * @memberof Person
   */
  static handleSocialMediaCredentialsInput(personInput: EntityPageFormInput): CreateUpdateSocialMediaCredentialInput[] {
    let socialMediaProfileInput: CreateUpdateSocialMediaCredentialInput[];

    if (personInput.socialMediaProfiles !== undefined && personInput.socialMediaProfiles !== null) {
      socialMediaProfileInput = [];

      if (
        personInput.socialMediaProfiles.FacebookID !== undefined &&
        personInput.socialMediaProfiles.FacebookID !== null &&
        personInput.socialMediaProfiles.FacebookID.length > 1
      ) {
        socialMediaProfileInput.push({
          platform: SocialMediaPlatformKind.FACEBOOK,
          credentials: personInput.socialMediaProfiles.FacebookID.trim(),
        });
      }

      if (
        personInput.socialMediaProfiles.InstagramID !== undefined &&
        personInput.socialMediaProfiles.InstagramID !== null &&
        personInput.socialMediaProfiles.InstagramID.length > 1
      ) {
        socialMediaProfileInput.push({
          platform: SocialMediaPlatformKind.INSTAGRAM,
          credentials: personInput.socialMediaProfiles.InstagramID.trim(),
        });
      }

      if (
        personInput.socialMediaProfiles.LinkedInID !== undefined &&
        personInput.socialMediaProfiles.LinkedInID !== null &&
        personInput.socialMediaProfiles.LinkedInID.length > 1
      ) {
        socialMediaProfileInput.push({
          platform: SocialMediaPlatformKind.LINKED_IN,
          credentials: personInput.socialMediaProfiles.LinkedInID.trim(),
        });
      }

      if (
        personInput.socialMediaProfiles.TwitterID !== undefined &&
        personInput.socialMediaProfiles.TwitterID !== null &&
        personInput.socialMediaProfiles.TwitterID.length > 1
      ) {
        socialMediaProfileInput.push({
          platform: SocialMediaPlatformKind.TWITTER,
          credentials: personInput.socialMediaProfiles.TwitterID.trim(),
        });
      }
    }

    return socialMediaProfileInput;
  }

  /**
   *
   *
   * @static
   * @param {EntityPageFormInput} entityInput
   * @returns {(UpdateEntityInput | AddToEntityInput)}
   * @memberof Entity
   */
  static generateAddToUpdateEntityInput(entityInput: EntityPageFormInput): UpdateEntityInput | AddToEntityInput {
    let updateEntityInput: UpdateEntityInput | AddToEntityInput;

    let previewsInput: CreateUpdatePreviewInput[];
    const SOCIAL_MEDIA_CREDENTIALS: CreateUpdateSocialMediaCredentialInput[] = Entity.handleSocialMediaCredentialsInput(
      entityInput,
    );

    // validate required parameters in schema and then
    // add only provided optional parameters.

    if (entityInput.socialMediaCredentials === undefined || entityInput.socialMediaCredentials === null) {
      entityInput.socialMediaCredentials = SOCIAL_MEDIA_CREDENTIALS;
    }

    if (entityInput.id !== undefined || entityInput.id !== null || entityInput.id.length > 0) {
      if (entityInput.previews !== undefined && entityInput.previews !== null && entityInput.previews.length > 0) {
        previewsInput = [];
        for (const PREVIEW of entityInput.previews) {
          previewsInput.push({
            kind: PREVIEW.kind,
            link: PREVIEW.link,
            mediaAsset: PREVIEW.mediaAsset,
          });
        }
      }

      // initialize create article graphQl input
      updateEntityInput = {
        clientMutationId: getClientMutationId(),
        id: entityInput.id,
        revision: entityInput.revision && entityInput.revision.length > 0 ? entityInput.revision.trim() : undefined,
        name: entityInput.name && entityInput.name.length > 0 ? entityInput.name.trim() : undefined,
        description:
          entityInput.description && entityInput.description.length > 0 ? entityInput.description.trim() : undefined,
        tagLine: entityInput.tagLine && entityInput.tagLine.length > 0 ? entityInput.tagLine.trim() : undefined,
        industry:
          entityInput.industry !== undefined && entityInput.industry !== null ? entityInput.industry : undefined,
        founded: entityInput.founded !== undefined && entityInput.founded !== null ? entityInput.founded : undefined,
        fundingRound:
          entityInput.fundingRound && entityInput.fundingRound.length > 0 ? entityInput.fundingRound.trim() : undefined,
        investmentsBy: entityInput.investmentsBy,
        activities: entityInput.activities,
        opportunities: entityInput.opportunities,
        noOfEmployees: entityInput.noOfEmployees ? entityInput.noOfEmployees : undefined,
        portfolioMarketCap: entityInput.portfolioMarketCap ? entityInput.portfolioMarketCap : undefined,
        noOfPortfolioCompanies: entityInput.noOfPortfolioCompanies ? entityInput.noOfPortfolioCompanies : undefined,
        isFundraising: entityInput.isFundraising,
        socialMediaCredentials:
          entityInput.socialMediaCredentials && entityInput.socialMediaCredentials.length > 0
            ? entityInput.socialMediaCredentials
            : undefined,
        socialMediaProfiles:
          entityInput.socialMediaProfilesUrl && entityInput.socialMediaProfilesUrl.length > 0
            ? entityInput.socialMediaProfilesUrl
            : undefined,
        tags: entityInput.tags && entityInput.tags.length > 0 ? entityInput.tags : undefined,

        northStar: entityInput.northStar && entityInput.northStar.length > 0 ? entityInput.northStar : undefined,
        mediaAbout: entityInput.mediaAbout && entityInput.mediaAbout.length ? entityInput.mediaAbout : undefined,
        websiteUrl:
          entityInput.websiteUrl && entityInput.websiteUrl.length > 0 ? entityInput.websiteUrl.trim() : undefined,
        force: entityInput.force,
        locations: entityInput.locations && entityInput.locations.length > 0 ? entityInput.locations : undefined,
        people: entityInput.people && entityInput.people.length > 0 ? entityInput.people : undefined,
        portfolioEntities:
          entityInput.portfolioEntities && entityInput.portfolioEntities.length > 0
            ? entityInput.portfolioEntities.trim()
            : undefined,
        portfolioPeople:
          entityInput.portfolioPeople && entityInput.portfolioPeople.length > 0
            ? entityInput.portfolioPeople.trim()
            : undefined,
        previews: previewsInput,
      };
    }

    if ((updateEntityInput as UpdateEntityInput).founded) {
      // validate provided publish date
      const currentTime: number = new Date().getTime();
      const founded = new Date((updateEntityInput as UpdateEntityInput).founded).getTime();

      if (currentTime < founded) {
        throw new Error("Please enter valid founded date");
      }
    }
    return updateEntityInput;
  }
}

/**
 * @author Ahmed Serag
 * @date   2018-11-19
 * @description Declaration of the SuggestionItem interface.
 * @filename suggestion-item.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

/**
 * The __typeID of the GraphQL Suggestion Item object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_SUGGESTION_ITEM: string = "SuggestionItem";

/**
 * A suggestion item, with optional response
 *
 * @export
 * @interface SuggestionItem
 */
export interface SuggestionItem {

  /**
   * The ID of the suggestion item.
   *
   * @type {string}
   * @memberof SuggestionItem
   */
  id?: string;

  /**
   * The content the suggestion.
   *
   * @type {string}
   * @memberof SuggestionItem
   */
  suggestion?: string;

  /**
   * The response to the suggestion.
   *
   * @type {string}
   * @memberof SuggestionItem
   */
  title?: string;

  /**
   * The typename of the node.
   *
   * @type {string}
   * @memberof SuggestionItem
   */
  __typename?: string;
}
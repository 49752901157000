/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2019-02-11
 * @filename: content-moderation-role.ts
 * @description: Declaration of content moderation state  interface
 */

import { Node } from "./node";

/**
 * The __typeID of the Moderation State Role type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_CONTENT_MODERATION_ROLE: string =
  "ContentModerationRole";

/**
 * A role applied to the content moderation process
 *
 * @export
 * @interface ContentModerationRole
 * @extends {Node<D>}
 * @template D
 */
export interface ContentModerationRole<D extends Date | string = string>
  extends Node<D> {

  /**
   * The name of the AWS Cognito user pool group associated with the role
   *
   * @type {string}
   * @memberof ContentModerationRole
   */
  cognitoUserPoolGroup?: string;

  /**
   * A description of the content moderation role. Metadata
   *
   * @type {string}
   * @memberof ContentModerationRole
   */
  description?: string;

  /**
   * The label of the content moderation role
   *
   * @type {string}
   * @memberof ContentModerationRole
   */
  label?: string;

  /**
   * The priority of the role. Higher priority roles override lower priority roles.
   *
   * @type {number}
   * @memberof ContentModerationRole
   */
  priority?: number;
}

import * as React from "react";
import { connect } from "react-redux";
import { Header } from "./header";
import { FetchListAction, FetchListPayload, CacheDataAction, CacheDataPayload } from "../../../redux/cache/types";
import { useWindowSize } from "../../../utilities/use-window-size";
import { PreviewKind, TopLevelMediaKind } from "@radivision/graphql";
import { AppState } from "../../../redux";
import * as Actions from "../../../redux/actions";
import { SmallCarousel } from "./small-carousel";
import { LargeCarousel } from "./large-carousel";
import { Loader } from "../../page/loader";
import isEmpty from "lodash.isempty";
import { Card as LargeCard } from "./card";
import { Card as SmallCard } from "./small-card";
import { ImageHelper } from "../../../utilities/image-helper";

interface ComponentProps {
  json: { sourceId: string; showBtn?: boolean; viewMode?: "GRID" | "CAROUSEL" };
}

interface MapStateProps {
  data: any;
  listId: string;
  showBtn?: boolean;
  isFetching: boolean;
  viewMode?: "GRID" | "CAROUSEL";
}

interface MapDispatchProps {
  fetchList: (payload: FetchListPayload) => FetchListAction;
  cacheData: (payload: CacheDataPayload) => CacheDataAction;
}

export type Props = ComponentProps & MapStateProps & MapDispatchProps;

export const Component = (props: Props) => {
  const { data, isFetching, fetchList, listId, showBtn, viewMode, cacheData } = props;
  const list = data?.list?.list;
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState<number>(0);
  const [retries, setRetries] = React.useState<number>(0);
  const [items, setItems] = React.useState(null);
  const windowSize = useWindowSize();
  const screenWidth = windowSize?.width;
  const isSmallScreen = !isNaN(screenWidth) && screenWidth <= 520;
  const GRID_VIEW = viewMode === "GRID";

  const isPrerender = /HeadlessChrome/i.test(navigator.userAgent);
  const hasHydratedData = !isEmpty(window.pitchplanet);

  const [hydrationData, setHydrationData] = React.useState(null);

  React.useEffect(() => {
    const shouldUseHydratedData = hasHydratedData && !list;
    if (shouldUseHydratedData) {
      const key = listId;
      const hydratedList = window.pitchplanet;
      cacheData({ key, data: hydratedList });
    }
  }, [hydrationData, cacheData, listId, hasHydratedData, list]);

  React.useEffect(() => {
    if (isPrerender && !hasHydratedData && !isEmpty(data)) {
      const listJson = JSON.stringify(data);
      setHydrationData(listJson);
    }
  }, [hasHydratedData, data, isPrerender]);

  React.useEffect(() => {
    const shouldFetchData = !hasHydratedData && !isEmpty(listId) && isEmpty(list) && !isFetching && retries < 3;
    if (shouldFetchData) {
      setRetries(retries + 1);
      fetchList({ variables: { listId } });
    }
  }, [listId, isFetching, fetchList, list, retries, setRetries, hasHydratedData]);

  React.useEffect(() => {
    if (list?.items?.edges && items === null) {
      const _items = [...list?.items?.edges];
      setItems(_items);
    }
  }, [list, setItems, items]);

  if (!screenWidth) {
    return null;
  }

  return (
    <>
      <Container isSmallScreen={isSmallScreen} isPitchPlanet={GRID_VIEW}>
        <Header title={list?.title} />
        {items && !isPrerender ? (
          isSmallScreen ? (
            <SmallCarousel
              items={items}
              showBtn={showBtn}
              setCurrentSlideIndex={setCurrentSlideIndex}
              currentSlideIndex={currentSlideIndex}
              playMode={"YOUTUBE"}
            />
          ) : (
            <LargeCarousel
              items={items}
              showBtn={showBtn}
              setCurrentSlideIndex={setCurrentSlideIndex}
              currentSlideIndex={currentSlideIndex}
              playMode={"YOUTUBE"}
            />
          )
        ) : (
          <SkeletonWithImages items={items} isPrerender={isPrerender} isSmallScreen={isSmallScreen} />
        )}
      </Container>
    </>
  );
};

export const xSkeleton = (props) => {
  const { items, isSmallScreen, isPrerender } = props;
  const slides = items ? [items[items.length - 1], items[0], items[1]] : [];
  const Card = isSmallScreen ? SmallCard : LargeCard;
  return (
    <div className={`pitch-carousel-skeleton ${isPrerender ? "isPrerender" : ""}`}>
      {slides &&
        slides.map((edge: any, index) => {
          if (index > 2) return null;
          return (
            <div key={`pictch-card-${edge?.listItem?.item?.id}`}>
              <Card
                slickNext={() => {}}
                item={edge?.listItem?.item}
                currentSlideIndex={3}
                slideIndex={index}
                showBtn={false}
              />
            </div>
          );
        })}
    </div>
  );
};

export const SkeletonWithImages = (props) => {
  const { items, isPrerender, isSmallScreen } = props;
  const imageUrls = React.useCallback(
    (index) => {
      const item = items && items[index] ? items[index].listItem.item : null;
      return item?.previews
        ? ImageHelper.fetchOptimizedImageUrl({
            imageType: "PREVIEW",
            preview: {
              content: item.previews,
              previewKind: PreviewKind.CAROUSEL,
              topLevelMedia: TopLevelMediaKind.IMAGE,
            },
            desiredDimensions: { containerWidthRatio: 3 / 4, numberOfItems: 1 },
            isSquareImage: false,
            revision: item.revision,
          })
        : {};
    },
    [items],
  );

  return (
    <div className="pitch-carousel-skeleton">
      <div>{isPrerender && <img src={imageUrls(items?.length - 1).requestedResolutionUrl} />}</div>
      <div>
        <Loader isActive={true} />
        {isPrerender && <img src={imageUrls(0).requestedResolutionUrl} />}
      </div>
      <div>{isPrerender && <img src={imageUrls(1).requestedResolutionUrl} />}</div>
    </div>
  );
};

export const Skeleton = (props) => {
  return (
    <div className="pitch-carousel-skeleton">
      <div></div>
      <div>
        <Loader isActive={true} />
      </div>
      <div></div>
    </div>
  );
};
export const Container = ({ children, isSmallScreen, isPitchPlanet }) => {
  return (
    <>
      <div
        id="pitch_planet"
        className={`${isSmallScreen ? "small-pitch-carousel" : "pitch-carousel"} ${
          isPitchPlanet ? "is-pitch-planet" : "is-standalone"
        }`}
      >
        <div className="top-shadow" />
        <div className="bottom-shadow" />
        {children}
      </div>
    </>
  );
};

const mapState = (state: AppState, props: ComponentProps) => {
  const listId = props.json.sourceId;
  const data = state.cache.store[listId];
  const showBtn = props?.json?.showBtn;
  const viewMode = props?.json?.viewMode;

  return {
    data,
    showBtn,
    viewMode,
    listId: props.json.sourceId,
    isFetching: state.cache.inProgress[listId],
  };
};

const mapDispatch = {
  fetchList: Actions.fetchList,
  cacheData: Actions.cacheData,
};

export const VerticalVideoPanel = connect(mapState, mapDispatch)(Component);

/**
 * @author Muhammad Omran
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2020-12-01
 * @description Implementation of the Marketing overlay panel component.
 * @filename marketing-overlay-panel.tsx
 */
import React from "react";
import { RdvButton } from "../page-framework/rdv-button";
import ProgressiveImage from "react-progressive-image";
import { storageFactory } from "../../utilities/local-storage-factory";

const LOGO = require("../../../static/logo-big.png");
const BACKGROUND = require("../../../static/radivision_bg.jpg");
const localStore = storageFactory(() => localStorage);


/**
 * The state of the MarketingOverlayPanel component
 *
 * @interface MarketingOverlayPanelState
 */
export interface MarketingOverlayPanelState {
  /**
   * A boolean used to hide the consent if the user visited the website before.
   *
   * @type {boolean}
   * @memberof MarketingOverlayPanelState
   */
  hidePanel: boolean;
}

/**
 * A React component that renders a one-time Marketing Overlay Panel
 *
 * @export
 * @class MarketingOverlayPanel
 * @extends {React.Component<MarketingOverlayPanelState>}
 */
export class MarketingOverlayPanel extends React.Component<{}, MarketingOverlayPanelState> {
  /**
   * Constructor
   *
   * @param {MarketingOverlayPanelProps} props The props.
   */
  constructor(props: {}) {
    super(props);
    this.state = {
      hidePanel: false,
    };

    try {
      if (localStore.getItem("MarketingOverlayDismissed") === null) {
        document.body.classList.remove("allow-scroll");
        localStore.setItem("MarketingOverlayDismissed", "false");
      }
    } catch (e) {
      console.error(e);
    }

    // ...binding
    this.dismissPanel = this.dismissPanel.bind(this);
  }

  /**
   * A method invoked when the 'Get Started' button is clicked.
   */
  dismissPanel() {
    localStore.setItem("MarketingOverlayDismissed", "true");
    document.body.classList.add("allow-scroll");
    // update state to reflect changes
    this.setState({
      hidePanel: true,
    });
  }

  /**
   * Invoked when the component first rendered to the screen.
   */
  componentDidMount(): void {
    // if the user has opened  the website before and agreed to use of cookies -> hide consent
    const currentPath = window.location.pathname;
    const isHome = currentPath === "/" || currentPath === "/home";
    if (!isHome) {
      document.body.classList.add("allow-scroll");
      localStore.setItem("MarketingOverlayDismissed", "true");
      this.setState({ hidePanel: true });
      return;
    }

    try {
    if (localStore.getItem("MarketingOverlayDismissed") === "true") {
      document.body.classList.add("allow-scroll");
      // update state to reflect changes
      this.setState({
        hidePanel: true,
      });
    } else {
      setTimeout(() => {
        if (!this.state.hidePanel) {
          this.dismissPanel();
        }
      }, 60 * 1000);
    }
  }catch(e){
    console.error(e);
  }
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    return !this.state.hidePanel ? (
      <div className="marketing-overlay">
        <div className="marketing-panel-bg">
          <ProgressiveImage src={BACKGROUND} placeholder={BACKGROUND}>
            {(src: any, LOADING: any, srcSetData: any) => <img loading="lazy" src={src} />}
          </ProgressiveImage>
        </div>
        <div className="start-adventure">
          <ProgressiveImage src={LOGO} placeholder={LOGO}>
            {(src: any, LOADING: any, srcSetData: any) => <img className="radivision" loading="lazy" src={src} />}
          </ProgressiveImage>
          <h3>Watch. Learn. Connect…and Be Inspired!</h3>
          <p>
            See Radical Visionaries in Original Shows, LIVE interviews, and curated videos. Check out their person and
            company profiles and sample the many resources curated to help you navigate the complex startup world.
          </p>
          <p>Join the Entrepreneurial Revolution</p>
          <RdvButton
            text="Let’s Go!"
            onClick={() => {
              this.dismissPanel();
            }}
          />
        </div>
      </div>
    ) : null;
  }
}

import {
  AddSaveItemLoadingAction,
  AddSaveItemLoadingPayload,
  FetchSavedItemsAction,
  FetchSavedItemsPayload,
  FollowResourceAction,
  FollowResourcePayload,
  RemoveFromSavedItemsAction,
  RemoveFromSavedItemsPayload,
  RemoveFromUserRequestsAction,
  RemoveFromUserRequestsPayload,
  RemoveSaveItemLoadingAction,
  RemoveSaveItemLoadingPayload,
  SaveResourceAction,
  SaveResourcePayload,
  UnfollowResourceAction,
  UnfollowResourcePayload,
  UnsaveResourceAction,
  UnsaveResourcePayload,
  UpdateSavedItemsAction,
  UpdateSavedItemsPayload,
  UpdateUserAction,
  UpdateUserMyListPayload,
  UpdateUserMyListSuccessPayload,
  UpdateUserPayload,
  UpdateUserRequestsAction,
  UpdateUserRequestsPayload,
  SetSettingsMenuPayload,
  SetSettingsMenuAction,
  DeactivateAccountRequestAction,
  DeactivateAccountFulfilledPayload,
  DeactivateAccountFulfilledAction,
  DeactivateAccountRequestPayload,
  SetAccountIsLoadingPayload,
  SetAccountIsLoadingAction,
  ChangeEmailRequestPayload,
  ChangeEmailRequestAction,
  ChangeEmailFulfilledPayload,
  ChangeEmailFulfilledAction,
  SetProfileActiveTabPayload,
  SetProfileActiveTabAction,
  UpdateUserRevisionPayload,
  UpdateUserRevisionAction,
  FetchCurrentUserRevisionAction,
  FetchCurrentUserRevisionPayload,
  SetPitchPlanetFilterPayload,
  SetPitchPlanetFilterAction,
  VerifyChangeEmailPayload,
  VerifyChangeEmailAction,
} from "./types";
import * as constants from "./constants";

export const updateUser = (payload: UpdateUserPayload): UpdateUserAction => ({
  type: constants.UPDATE_USER,
  payload,
});

export const updateUserRevision = (payload: UpdateUserRevisionPayload): UpdateUserRevisionAction => ({
  type: constants.UPDATE_USER_REVISION,
  payload,
});

export const updateSavedItems = (payload: UpdateSavedItemsPayload): UpdateSavedItemsAction => ({
  type: constants.UPDATE_SAVED_ITEMS,
  payload,
});

export const fetchSavedItems = (payload: FetchSavedItemsPayload): FetchSavedItemsAction => ({
  type: constants.FETCH_SAVED_ITEMS,
  payload,
});

export const saveResource = (payload: SaveResourcePayload): SaveResourceAction => ({
  type: constants.SAVE_RESOURCE,
  payload,
});

export const unsaveResource = (payload: UnsaveResourcePayload): UnsaveResourceAction => ({
  type: constants.UNSAVE_RESOURCE,
  payload,
});

export const followResource = (payload: FollowResourcePayload): FollowResourceAction => ({
  type: constants.FOLLOW_RESOURCE,
  payload,
});

export const unfollowResource = (payload: UnfollowResourcePayload): UnfollowResourceAction => ({
  type: constants.UNFOLLOW_RESOURCE,
  payload,
});

export const addSaveItemLoading = (payload: AddSaveItemLoadingPayload): AddSaveItemLoadingAction => ({
  type: constants.ADD_SAVE_ITEM_LOADING,
  payload,
});

export const removeSaveItemLoading = (payload: RemoveSaveItemLoadingPayload): RemoveSaveItemLoadingAction => ({
  type: constants.REMOVE_SAVE_ITEM_LOADING,
  payload,
});

export const updateUserMyList = (payload: UpdateUserMyListPayload) => ({
  type: constants.UPDATE_USER_MY_LIST,
  payload,
});

export const updateUserMyListSuccess = (payload: UpdateUserMyListSuccessPayload) => ({
  type: constants.UPDATE_USER_MY_LIST_SUCCESS,
  payload,
});

export const removeFromSavedItems = (payload: RemoveFromSavedItemsPayload): RemoveFromSavedItemsAction => ({
  type: constants.REMOVE_FROM_SAVED_ITEMS,
  payload,
});

export const removeFromUserRequests = (payload: RemoveFromUserRequestsPayload): RemoveFromUserRequestsAction => ({
  type: constants.REMOVE_FROM_USER_REQUESTS,
  payload,
});

export const updateUserRequests = (payload: UpdateUserRequestsPayload): UpdateUserRequestsAction => ({
  type: constants.UPDATE_USER_REQUESTS,
  payload,
});

export const fetchCurrentUser = () => ({
  type: constants.FETCH_CURRENT_USER,
});

export const fetchCurrentUserRevision = (payload: FetchCurrentUserRevisionPayload): FetchCurrentUserRevisionAction => ({
  type: constants.FETCH_CURRENT_USER_REVISION,
  payload,
});


export const setSettingsMenu = (payload: SetSettingsMenuPayload): SetSettingsMenuAction => ({
  type: constants.SET_SETTINGS_MENU,
  payload,
});

export const deactivateAccountRequest = (payload: DeactivateAccountRequestPayload): DeactivateAccountRequestAction => ({
  type: constants.DEACTIVATE_ACCOUNT_REQUEST,
  payload,
});

export const deactivateAccountFulfilled = (
  payload: DeactivateAccountFulfilledPayload,
): DeactivateAccountFulfilledAction => ({
  type: constants.DEACTIVATE_ACCOUNT_FULFILLED,
  payload,
});

export const changeEmailRequest = (payload: ChangeEmailRequestPayload): ChangeEmailRequestAction => ({
  type: constants.CHANGE_EMAIL_REQUEST,
  payload,
});

export const changeEmailFulfilled = (payload: ChangeEmailFulfilledPayload): ChangeEmailFulfilledAction => ({
  type: constants.CHANGE_EMAIL_FULFILLED,
  payload,
});

export const setAccountIsLoading = (payload: SetAccountIsLoadingPayload): SetAccountIsLoadingAction => ({
  type: constants.SET_ACCOUNT_IS_LOADING,
  payload,
});

export const setProfileActiveTab = (payload: SetProfileActiveTabPayload): SetProfileActiveTabAction => ({
  type: constants.SET_PROFILE_ACTIVE_TAB,
  payload,
});

export const setPitchPlanetFilter = (payload: SetPitchPlanetFilterPayload): SetPitchPlanetFilterAction => ({
  type: constants.SET_PITCH_PLANET_FILTER,
  payload,
});

export const verifyChangeEmail = (payload: VerifyChangeEmailPayload): VerifyChangeEmailAction => ({
  type: constants.VERIFY_CHANGE_EMAIL,
  payload,
});

import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../redux";
import { Entity, GRAPHQL_TYPE_ENTITY } from "@radivision/graphql";
import { getNumberFromRange } from "../../../../utilities/get-number-from-range";
import { getLocation } from "../../../../utilities/get-location";
import {MONTHS_NAMES} from "../../../../constants/months-names";

interface ComponentProps {
  entity: Entity | any;
}

interface MapDispatchProps {}
interface MapStateProps {}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const CompanyDetailsComponent = (props: Props) => {
  const { entity } = props;

  if (!entity || (entity && entity.__typename !== GRAPHQL_TYPE_ENTITY)) return null;

  const companyType =
    Array.isArray(entity?.activities) && entity?.activities.length > 0 && entity?.activities[0].kind
      ? entity?.activities[0].kind === "NON_PROFIT"
        ? entity?.activities[0].kind.toLowerCase().replace("_", "")
        : entity?.activities[0].kind.toLowerCase().replace("_", " ")
      : null;

  const companyTypeLabel = companyType
    ? `${companyType?.length > 0 ? companyType[0].toUpperCase() : ""}${companyType?.slice(1)}`
    : null;


  const year = new Date(entity?.founded).getFullYear();
  const month = new Date(entity?.founded).getUTCMonth();
  const foundedDate = `${!isNaN(month) ? MONTHS_NAMES[month] : ""} ${year}`;
  // const foundedDateSplited = entity?.founded?.slice(0, 9).split("-");
  // const updatedFoundedDate = new Date(foundedDateSplited[0], foundedDateSplited[1] - 1, foundedDateSplited[2] + 2);

  // const year = new Date(updatedFoundedDate).getFullYear();
  // const month = new Date(updatedFoundedDate).getMonth();
  // const foundedDate = `${!isNaN(month) ? MONTHS_NAMES[month] : ""} ${year}`;

  return (
    <Container>
      {entity && entity.locations ? (
        <div className="company-info">
          <h4>Location:</h4>
          <p>{getLocation(entity)}</p>
        </div>
      ) : null}

      {entity && entity.founded ? (
        <div className="company-info">
          <h4>Founded:</h4>
          <p>{foundedDate}</p>
        </div>
      ) : null}

      {entity && entity.noOfEmployees ? (
        <div className="company-info">
          <h4>Company Size:</h4>
          <p>
            {entity.noOfEmployees.maximum ? "" : ">"} {getNumberFromRange(entity.noOfEmployees)} Employees
          </p>
        </div>
      ) : null}

      {entity && entity.industry ? (
        <div className="company-info">
          <h4>Industry:</h4>
          <p>{entity.industry}</p>
        </div>
      ) : null}

      {companyTypeLabel ? (
        <div className="company-info">
          <h4>Company Type:</h4>
          <p>{companyTypeLabel}</p>
        </div>
      ) : null}

      {entity && entity.headquarters && entity.hasPortfolio ? (
        <div className="company-info">
          <h4>Headquarters:</h4>
          <p>{entity.headquarters.location.label}</p>
        </div>
      ) : null}

      {entity && entity.noOfPortfolioCompanies && entity.hasPortfolio ? (
        <div className="company-info">
          <h4>Companies:</h4>
          <p>{getNumberFromRange(entity.noOfPortfolioCompanies)}</p>
        </div>
      ) : null}

      {entity && entity.portfolioMarketCap && entity.hasPortfolio ? (
        <div className="company-info">
          <h4>Market Cap of Co's:</h4>
          <p>{entity.portfolioMarketCap}</p>
        </div>
      ) : null}
    </Container>
  );
};

const Container = ({ children }: React.PropsWithChildren<any>) => <div className="company-infos">{children}</div>;
const mapState = (state: AppState) => ({
  user: state.account.user,
});

const mapDispatch = {};

export const CompanyDetails = connect(mapState, mapDispatch)(CompanyDetailsComponent);

import * as constants from "./constants";
import { StartVideoPlayerAction, StopVideoPlayerAction, PipPlayerPayload } from "./types";

export const startVideoPlayer = (payload: PipPlayerPayload): StartVideoPlayerAction => ({
  payload,
  type: constants.START_VIDEO_PLAYER,
});

export const stopVideoPlayer = (): StopVideoPlayerAction => ({
  type: constants.STOP_VIDEO_PLAYER,
});

// export function startVideoPlayer(video) {
//   return (dispatch) => {
//     return dispatch({ type: constants.START_VIDEO_PLAYER, video });
//   };
// }

// export function stopVideoPlayer() {
//   return (dispatch) => {
//     return dispatch({ type: constants.STOP_VIDEO_PLAYER });
//   };
// }

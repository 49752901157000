/**
 * @author Ahmed serag
 * @date   2018-04-19
 * @description Declaration of the InvestmentByPerson interface.
 * @filename investment-by-person.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Currency } from "./currency";
import { Entity } from "./entity";
import { FundingRoundType } from "./funding-round-type";
import { Node } from "./node";

/**
 * The __typeID of the Investment by Person type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_INVESTMENT_BY_PERSON: string = "InvestmentByPerson";

/**
 * An investment made by a person in an entity
 *
 * @export
 * @interface InvestmentByPerson
 * @extends {Node<D>}
 * @template D
 */
export interface InvestmentByPerson<D extends Date | string = string>
  extends Node<D> {
  /**
   * The amount of the investment
   *
   * @type {number}
   * @memberof InvestmentByPerson
   */
  amount?: string;

  /**
   * The currency unit of the investment,
   *
   * @template D
   * @type {string}
   * @memberof InvestmentByPerson
   */
  currency?: Currency<D>;

  /**
   * The entity by which the investment was made.
   *
   * @template D
   * @type {Entity<D>}
   * @memberof InvestmentByPerson
   */
  entity?: Entity<D>;

  /**
   * The funding round when the investment was made
   *
   * @template D
   * @type {FundingRoundType<D>}
   * @memberof InvestmentByPerson
   */
  fundingRound?: FundingRoundType<D>;

  /**
   * The entity in whom the investment was made.
   *
   * @template D
   * @type {Entity<D>}
   * @memberof InvestmentByPerson
   */
  investee?: Entity<D>;

  /**
   * The timestamp when the investment was made
   *
   * @template D
   * @type {D}
   * @memberof InvestmentByPerson
   */
  investmentDate?: D;
}

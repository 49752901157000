import { takeLatest, select, put, all, fork } from "redux-saga/effects";
import $ from "jquery";
import { OpenDetailsPanelAction } from "./types";
import { OPEN_DETAILS_PANEL, CLOSE_DETAILS_PANEL, SET_DETAILS_PANEL } from "./constants";
import { AppState } from "..";
import { setAppState } from "../app/actions";

export function* openDetailsPanelWorker(action: OpenDetailsPanelAction) {
  try {
    const { item, list } = action.payload;
    const currentHash = yield select((state: AppState) => state.app.currentHash);
    const params = currentHash ? String(currentHash).split("/") : [];
    const title = list?.title || params[0] || item?.title;
    const hash = `${title?.replace(/:/g, "").replace(/[\W]+/g, "-")}/${item.id}/${list?.id || params[2] || ""}`;
    window.location.hash = hash;
    $("body").removeClass("allow-scroll");
    setTimeout(() => {
      document.querySelector(".visible-panel.details-panel").scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 300);
  } catch (error) {
    $("body").addClass("allow-scroll");
  }
}

export function* closeDetailsPanelWorker() {
  try {
    $("body").addClass("allow-scroll");
    const currentHash = yield select((state: AppState) => state.app.currentHash);
    const { scrollAnchorId } = yield select((state: AppState) => state.detailsPanel);
    const [_title, _itemId, listId] = currentHash ? String(currentHash).split("/") : [];
    const elemId = scrollAnchorId || listId;
    const [cleanUrl, hash] = window.location.href.split("#");
    if (hash?.length) {
      window.history.pushState("", "", cleanUrl);
    }
    yield put(setAppState({ currentHash: null }));
    const elem = $(`#${elemId}`);
    if (elemId && elem && elem.offset()) {
      setTimeout(() => window.scrollTo({ top: elem.offset().top - 100 }), 500);
    }
  } catch (error) {}
}
export function* setDetailsPanelWorker(action: OpenDetailsPanelAction) {
  const { item, list } = action.payload;
  if (item || list) {
    $("body").removeClass("allow-scroll");
  }
}

export function* watchCloseDetailsPanel() {
  yield takeLatest(CLOSE_DETAILS_PANEL, closeDetailsPanelWorker);
}

export function* watchOpenDetailsPanel() {
  yield takeLatest(OPEN_DETAILS_PANEL, openDetailsPanelWorker);
}
export function* watchSetDetailsPanel() {
  yield takeLatest(SET_DETAILS_PANEL, setDetailsPanelWorker);
}

export const sagas = [watchOpenDetailsPanel, watchCloseDetailsPanel, watchSetDetailsPanel];

export default function* root() {
  yield all(sagas.map((saga) => fork(saga)));
}

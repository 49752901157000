/**
* @author: Ahmed Samer
* @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
* @date: 2019-01-18
* @filename: linked-in-share-media-category.ts
* @description: Declaration of Twitter tweet media kind payload enum
*/

/**
 *
 * The details of a Twitter tweet media kind
 * @export
 * @enum TweetMediaKind
 */
export enum LinkedInShareMediaCategory {
    /**
     * Represents shared content that only contains articles
     */
    ARTICLE,
    /**
     * Represents shared content that should be rendered in carousel format
     */
    CAROUSEL,
    /**
     *  Represents shared content that only contains images
     */
    IMAGE ,
    /**
     *  Represents shared content that does not contain any media
     */
    NONE,
    /**
     *  Represents shared content that only contains rich media
     */
    RICH,
    /**
     * Represents shared content that only contains videos
     */
    VIDEO
}
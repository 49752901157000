/**
 * @author Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-09-04
 * @description Implementation of Routing utilities to be used in the app.
 * @filename router.ts
 */

import URIJS from "urijs";
import { store } from "../redux";
import { navigateTo } from "../redux/actions";

const querystring = require("querystring");

/**
 * The regular expression used to identify the page part of a hash.
 *
 * @type {RegExp}
 */
const REGEX_FRAGMENT_PAGE: RegExp = /^([^?:]*)(:([^?]*))?([?](.*))?$/i;

/**
 * an interface that declares the url and it's parts
 *
 * @export
 * @interface Url
 */
export interface Url {
  /**
   * the name alias of the page in the html document rule.
   *
   * @type {string}
   * @memberof Url
   */
  alias?: string;

  /**
   * the location of the Element in the Document.
   *
   * @type {string}
   * @memberof Url
   */
  location?: string;

  /**
   * the Query parameters for the html document rule
   *
   * @type {string[]}
   * @memberof Url
   */
  queryParameters?: string[];
}

/**
 * The class responsible for routing and building the Urls in the website.
 *
 * AND HOW DOES IT DO THIS - REALLY IMPORTANT TO DESCRIBE
 *
 * @export
 * @class Router
 */
export class Router {
  /**
   * Updates the current URL based on given parameters. HOW?
   *
   * @static
   * @param {string} alias The alias of the html-document.
   *
   * @param {Array<string>} queryParameters The query parameters for the destination.
   *
   * @param {string} location The location to scroll for in the page.
   */
  public static to(
    alias: string = "home",
    queryParameters: string[] = [],
    location: string = undefined,
    hash: string = undefined,
  ): void {
    let change: boolean = false;
    let newAlias: string;
    let newFragment: string = "";
    let newQP: string[];
    let qpString: string = "";

    return Promise.all([Router.getAlias(), Router.getQueryParameters(), Router.getLocation()]).then((res) => {
      const currentAlias: string = res[0];
      const currentQP = res[1];
      const currentLocation = res[2];
      if (alias !== null) {
        if (currentAlias !== alias) {
          newAlias = alias;
          change = true;
        }
      } else {
        newAlias = currentAlias;
      }
      if (queryParameters !== undefined && queryParameters !== null) {
        if (queryParameters.length !== currentQP.length) {
          newQP = queryParameters;
          change = true;
        } else {
          for (const QP of queryParameters) {
            if (currentQP.indexOf(QP) < 0) {
              newQP = queryParameters;
              change = true;
              break;
            }
          }
        }
      }
      if (change) {
        if (newAlias !== null && newAlias !== undefined) {
          newFragment = `${newFragment}${newAlias}`;
        } else {
          newFragment = `${newFragment}${currentAlias}`;
        }

        if (newQP !== null && newQP !== undefined) {
          for (const QP of newQP) {
            if (QP.length > 1) {
              qpString = `${qpString}&${QP}`;
            }
          }
          if (qpString.length > 1) {
            qpString = qpString
              .split("")
              .slice(1)
              .join("");
            qpString = `?${qpString}`;
          }
        }
        //  window.location.href = "/";

        window.history.pushState(
          "",
          "",
          `${URIJS(window.location)
            .path(newFragment)
            .query(qpString.length ? qpString : "")
            .hash(location ? `:${location}` : hash ? `${hash}` : "")}`,
        );

        // window.location.assign(
        // `${URIJS(window.location)
        //   .path(newFragment)
        //   .query(qpString.length ? qpString : "")
        //   .hash(location ? `:${location}` : "")}`
        // );
      } else if (location) {
        if (currentLocation !== null && currentLocation !== undefined) {
          newFragment = `${URIJS(window.location)
            .fragment()
            .replace(`:${currentLocation}`, `:${location}`)}`;
        } else {
          newFragment = `${URIJS(window.location).hash()}:${location}`;
        }

        // window.history.pushState(
        //   "",
        //   "",
        //   `${URIJS(window.location).fragment(newFragment)}`
        // );

        window.location.assign(`${URIJS(window.location).fragment(newFragment)}`);
        // window.location.replace(
        //   `${URIJS(window.location).fragment(newFragment)}`
        // );

        Router.scrollTo();
      }

      window.dispatchEvent(new Event("rv-enter"));
      return Promise.resolve();
    });
  }

  public static navigateTo(
    alias = "home",
    queryParameters: string[] = [],
    location: string = undefined,
    hash: string = undefined,
  ) {
    store.dispatch(navigateTo({ alias, queryParameters, location, hash }));
  }

  /**
   * Scrolls the page to a location (the in-page location) identified by the hash value (page:location).
   *
   * @param {string} pageLocation The page location to which to scroll. This is null if there is no target.
   */
  public static scrollTo(pageLocation: string = null): void {
    let element: HTMLElement;
    let nbElement: HTMLElement;
    let promise: Promise<any> = Promise.all([Router.getLocation()]);
    promise = promise.then((results: [string, any]) => {
      const LOCATION = results[0];

      if (pageLocation === null || pageLocation === undefined) {
        if (LOCATION !== undefined && LOCATION !== null && LOCATION.length > 0) {
          element = document.getElementById(decodeURIComponent(LOCATION));
          nbElement = document.getElementById("top-nav");
          if (element !== undefined && element !== null) {
            if (element) {
              setTimeout(() => window.scrollTo({ top: element.getBoundingClientRect().top - 50, behavior: "smooth" }), 100);
            }
            // COMMON_MODULE.COMMON.scroller.scrollTo(decodeURIComponent(LOCATION), {
            //   offset: -1 * (nbElement.getBoundingClientRect().height + 50),
            //   smooth: true,
            // });
          }
        }
      } else {
        element = document.getElementById(decodeURIComponent(pageLocation));
        if (element !== undefined && element !== null) {
          setTimeout(() => window.scrollTo({ top: element.getBoundingClientRect().top - 50, behavior: "smooth" }), 100);
        }
      }
    });
  }

  /**
   * Scrolls the page to a location to top
   *
   * @static
   * @returns {*}
   * @memberof Router
   */
  public static scrollToTop(): void {
    return;
    // import("../common").then((module) => {
    //   module.COMMON.scroller.scrollTo("top-nav", {
    //     smooth: true,
    //   });
    // });
  }

  /**
   * Returns the alias from the page URL.
   *
   * @static
   * @returns {string} The alias from the page URL.
   * @memberof Router
   */

  public static getAlias(): Promise<string> {
    let alias: string = "home";

    const path: string = URIJS(window.location)
      .path()
      .split("/")[1];

    if (path) {
      const endCursor: number = path.indexOf("#");
      alias = path.substr(0, endCursor !== -1 ? endCursor : undefined);
    }
    return Promise.resolve(alias);
  }

  /**
   *
   *
   * @static
   * @returns {Promise<string>}
   * @memberof Router
   */
  public static getPathName(): Promise<string> {
    const path: string = URIJS(window.location).path();
    return Promise.resolve(path.replace(/\/$/, ""));
  }

  /**
   * Returns the query parameters from the page URL.
   *
   * @static
   * @returns {Array<string>} The query parameters from the page URL.
   * @memberof Router
   */
  public static getQueryParameters(): Promise<string[]> {
    let queryParameters: string[] = undefined;
    const QUERY: string = URIJS(encodeURI(window.location.href)).query();
    const path: string[] = URIJS(encodeURI(window.location.href))
      .path()
      .split("/");

    const PASSED_QP = querystring.parse(QUERY);

    if (PASSED_QP) {
      queryParameters = [];
      Object.keys(PASSED_QP).forEach((key: string) => {
        queryParameters.push(`${key}=${PASSED_QP[key]}`);
      });
    }
    if (path.length > 2) {
      queryParameters.push(`item_id=${path.pop()}`);
    }
    return Promise.resolve(queryParameters);
  }

  /**
   *
   *
   * @param {*} url
   * @memberof Router
   */
  static generateRouterData(url): Promise<any> {
    let queryParameters: string[] = undefined;
    let alias: string = undefined;
    const QUERY: string = URIJS(encodeURI(url)).query();
    const path: string[] = URIJS(encodeURI(url))
      .path()
      .split("/");

    const PASSED_QP = querystring.parse(QUERY);

    if (PASSED_QP) {
      queryParameters = [];
      Object.keys(PASSED_QP).forEach((key: string) => {
        queryParameters.push(`${key}=${PASSED_QP[key]}`);
      });
    }
    if (path.length > 2) {
      queryParameters.push(`item_id=${path.pop()}`);
    }

    const LOCATION: string = URIJS(url)
      .path()
      .split("/")[1];

    if (LOCATION) {
      const endCursor: number = LOCATION.indexOf("#");
      alias = LOCATION.substr(0, endCursor !== -1 ? endCursor : undefined);
    }

    return Promise.resolve({ alias, qp: queryParameters });
  }

  /**
   *
   *
   * @static
   * @returns {{ [index: string]: any }}
   * @memberof Router
   */
  public static getQueryParametersObject(): { [index: string]: any } {
    const queryParameters: { [index: string]: any } = {};

    return queryParameters;
  }

  /**
   * Returns the in-page location (page:location) from the page URL.
   *
   * @static
   * @returns {string} The in-page location from the page URL.
   * @memberof Router
   */
  public static getLocation(): Promise<string> {
    let location: string = null;
    const fragment: string = URIJS(window.location).fragment();
    let regFragment: RegExpExecArray;

    if (fragment !== undefined && fragment !== null && fragment.length > 0) {
      regFragment = REGEX_FRAGMENT_PAGE.exec(fragment);
      if (regFragment.length > 2) {
        location = regFragment[3];
      }
    }
    return Promise.resolve(location);
  }

  /**
   *
   *
   * @static
   * @param {*} name
   * @returns
   * @memberof Router
   */
  public static getParameterByName(name: string) {
    const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  }
}

import * as React from "react";
import { SVG } from "../../../component-configuration/svgs";
import { LinkedInAuthenticator } from "../../../authentication/linkedin-authenticator";
import { TwitterAuthenticator } from "../../../authentication/twitter-authenticator";
import { googleIcon, twitterIcon } from "./icons";
import { AUTH_SIGNIN, AUTH_SIGNUP } from "../../../redux/authentication/constants";

export const ProvidersSignin = ({ step }) => {
  if (![AUTH_SIGNUP, AUTH_SIGNIN].includes(step)) {
    return null;
  }
  const action = "Continue";
  return (
    <div className="providers-signin">
      <div
        className="social-login-btn googleIcon"
        onClick={() => {
          window.location.href = process.env.GOOGLE_URI;
        }}
      >
        <a href="#" onClick={(e) => e.preventDefault()}>
          <div className="icon">{googleIcon}</div>
          <span>{action} with Google</span>
        </a>
      </div>
      <div
        className="social-login-btn twitterIcon"
        onClick={() => {
          TwitterAuthenticator.authenticateTwitterUser();
        }}
      >
        <a href="#" onClick={(e) => e.preventDefault()}>
          <div className="icon">{twitterIcon}</div>
          <span>{action} with Twitter</span>
        </a>
      </div>
      <div
        className="social-login-btn linkedinIcon"
        onClick={() => {
          LinkedInAuthenticator.requestLinkedInAuthorizationCode();
        }}
      >
        <a href="#" onClick={(e) => e.preventDefault()}>
          <div className="icon">{SVG.linkedinModal}</div>
          <span>{action} with LinkedIn</span>
        </a>
      </div>
    </div>
  );
};

/**
 * @author William A. Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-25
 * @description Implementation of the CookieConsent component.
 * @filename cookie-consent.tsx
 */
import React from "react";
import { connect } from "react-redux";
import { Router } from "../../utilities/router";
import { RdvButton } from "./rdv-button";
import { SVG } from "../../component-configuration/svgs";
import { storageFactory } from "../../utilities/local-storage-factory";
import { AppState } from "../../redux";
const localStore = storageFactory(() => localStorage);

/**
 * The state of the CookieConsent component
 *
 * @interface CookieConsentState
 */
export interface CookieConsentState {
  /**
   * The scroll offset to close the cookie consent form.
   *
   * @type {number}
   */
  autoCloseScrollOffset?: number;

  /**
   * The height of the consent div.
   *
   * @type {number}
   */
  consentHeight: number;

  /**
   * A boolean used to hide the consent if the user visited the website before.
   *
   * @type {boolean}
   */
  hideConsent: boolean;

  /**
   * The scroll position
   *
   * @type {number}
   */
  initialScrollPosition?: number;
}

/**
 * A React component that renders a cookie consent notice
 *
 * @export
 * @class Divider
 * @extends {React.Component<CookieConsentState>}
 */
interface MapStateProps {
  pageYOffset: number;
}
type Props = MapStateProps;
export class Component extends React.Component<Props, CookieConsentState> {
  /**
   * A reference to the cookie consent form.
   *
   * @type {React.RefObject<HTMLDivElement>}
   */
  cookieRef: React.RefObject<HTMLDivElement>;

  /**
   * Constructor
   *
   * @param {CookieConsentProps} props The props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      consentHeight: 0,
      hideConsent: false,
    };
    this.cookieRef = React.createRef();
    this.onScroll = this.onScroll.bind(this);
    const COOKIES_OPEN = localStore.getItem("previouslyOpened");

    if (!COOKIES_OPEN) {
      localStore.setItem("previouslyOpened", "false");
    }
  }

  /**
   * A method invoked when the close consent button is selected.
   */
  closeConsent = () => {
    localStore.setItem("previouslyOpened", "true");
    // update state to reflect changes
    this.setState({
      hideConsent: true,
    });
  };

  /**
   * Invoked by the React framework immediately after a component is mounted.
   */
  componentDidMount(): void {
    // if the user has opened  the website before and agreed to use of cookies -> hide consent
    if (localStore.getItem("previouslyOpened") === "true") {
      // update state to reflect changes
      this.setState({
        hideConsent: true,
        initialScrollPosition: this.props.pageYOffset,
      });
    } else {
      this.setState({
        autoCloseScrollOffset: screen.height * 0.3,
        initialScrollPosition: this.props.pageYOffset,
      });
    }
    // update state to get height of consent container to correctly position it at the bottom
    this.setState({
      consentHeight: this.cookieRef.current.clientHeight,
    });
  }

  componentDidUpdate(prevProps: Props) {
    if(prevProps.pageYOffset !== this.props.pageYOffset) {
      this.onScroll();
    }
  }
  /**
   * Invoked when the window scrolls.
   */
  onScroll(): void {
    if (Math.abs(this.props.pageYOffset - this.state.initialScrollPosition) > this.state.autoCloseScrollOffset) {
      this.closeConsent();
    }
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    return !this.state.hideConsent ? (
      <div
        className="cookie-consent-container"
        style={{ top: `calc(100% -${this.state.consentHeight}px)` }}
        ref={this.cookieRef}
      >
        <div className="position-relative">
          <p>
            We use cookies to ensure that you get the best experience on our website. If you continue browsing we'll
            assume you're happy with it!
          </p>
          <a
            href="#"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
              event.preventDefault();
              Router.to("legal", ["section=cookies-section"], "privacy-policy");
            }}
          >
            Find out more!
          </a>
          <RdvButton
            text="got it!"
            onClick={() => {
              this.closeConsent();
            }}
          />
          <i
            onClick={() => {
              this.closeConsent();
            }}
          >
            {SVG.crosshairs}
          </i>
        </div>
      </div>
    ) : null;
  }
}

const mapState = (state: AppState) => ({
  pageYOffset: state.app.pageYOffset,
});

export const CookieConsent = connect(mapState)(Component);

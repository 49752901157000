import { Person } from "@radivision/graphql/lib/ts/graphql/person";
import { Entity } from "@radivision/graphql/lib/ts/graphql/entity";
import isEmpty from "lodash.isempty";
import { CarouselItem } from "../component-configuration/carousel-item";
import { esc } from "./esc";

interface GetProfileInfo {
  name?: string;
  profileUrl?: string;
}

const nullInfo: GetProfileInfo = {
  name: null,
  profileUrl: null,
}

export function getProfileInfo(subject: Person | Entity | undefined | CarouselItem | null): GetProfileInfo {
  if (isEmpty(subject)) {
    return nullInfo;
  }
  const isCarouselItem = subject["type"];
  const isPerson = subject["__typename"] === "Person" || subject["type"] === "Person";
  const name = isCarouselItem
    ? (subject as CarouselItem).title
    : isPerson
    ? (subject as Person).fullName
    : (subject as Entity)?.name;
  const profileUrl = isPerson ? `/person/${esc(name)}/${subject.id}` : `/company/${esc(name)}/${subject.id}`;
  return { ...nullInfo, name, profileUrl };
}


/**
* @author: Ahmed Samer
* @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
* @date: 2019-01-18
* @filename: tweet-media-kind.ts
* @description: Declaration of Twitter tweet media kind payload enum
*/

/**
 *
 * The details of a Twitter tweet media kind
 * @export
 * @enum TweetMediaKind
 */
export enum TweetMediaKind {
    /**
     * The tweet content contains an animated GIF
     */
    ANIMATED_GIF,
    /**
     * The tweet content contains an image
     */
    PHOTO,
    /**
     * The tweet content contains a video
     */
    VIDEO
}
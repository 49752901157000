/**
 * @author William Alexander Livesley
 * @date   2018-08-20
 * @description Declaration of the Comment interface.
 * @filename comment.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";
import { Person } from "./person";

/**
 * The __typeID of the GraphQL Comment type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_COMMENT: string = "Comment";

/**
 * A poster - an poster displayed on the site, often to advertise site features or shows.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface Comment<D extends Date | string = string> extends Node<D> {
  /**
   * The originator of the comment.
   *
   * @template D
   * @type {Person<D>}
   * @memberof StoryReview
   */
  attribution?: Person<D>;

  /**
   * The content of the comment.
   *
   * @type {string}
   * @memberof StoryReview
   */
  content?: string;

  /**
   *  The number of people who thought that the comment was helpful.
   *
   * @type {number}
   * @memberof StoryReview
   */
  helpful?: number;

  /**
   * The number of people who thought that the comment was not helpful.
   *
   * @type {number}
   * @memberof StoryReview
   */
  notHelpful?: number;

  /**
   * The rating that the commenter gave to the poster.
   *
   * @type {number}
   * @memberof StoryReview
   */
  rating?: number;
}

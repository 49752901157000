import { takeEvery, put, call, select } from "redux-saga/effects";
import $ from "jquery";
import { CREATE_RSVP_REQUEST } from "../constants";
import { CreateRSVPRequestAction } from "../types";
import { getClientMutationId } from "../../../../../utilities/general";
import { fetchInProgress, setToastMessage, updateUser } from "../../../../../redux/actions";
import { createRSVPRequest } from "../api";
import { OOPS_SOMETHING_WENT_WRONG } from "../../../../../constants/error-codes";
import { TOAST_FAIL } from "../../../../../redux/toast/constants";
import { fetchCurrentUserWorker } from "../../../../../redux/account/saga";

function* createRSVPRequestWorker(action: CreateRSVPRequestAction) {
  const { eventId } = action.payload;
  try {
    yield put(fetchInProgress({ key: `create-rsvp-request-${eventId}`, status: true }));

    const input: any = {
      clientMutationId: getClientMutationId(),
      item: eventId,
    };

    const request = yield call(createRSVPRequest, input);
    yield put(fetchInProgress({ key: `create-rsvp-request-${eventId}`, status: false }));
    const user = yield select((state) => state.account.user);
    const updatedUser = { ...user, requests: [request, ...(Array.isArray(user.requests) ? user.requests : [])] };
    yield put(updateUser({ user: updatedUser }));
    $(`#rsvp-success-modal`).modal("show");
    yield call(fetchCurrentUserWorker);
  } catch (error) {
    yield put(setToastMessage({ msg: OOPS_SOMETHING_WENT_WRONG, type: TOAST_FAIL }));
  }
}

export function* watchCreateRSVPRequest() {
  yield takeEvery(CREATE_RSVP_REQUEST, createRSVPRequestWorker);
}

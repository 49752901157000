/**
 * @author Ahmed Samer
 * @date   2019-09-30
 * @description Declaration of the ArticleStory interface.
 * @filename radivision-post.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Story } from "./story";

/**
 * The __typeID of the API Post GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_RADIVISION_POST: string = "RadivisionPost";

/**
 * A story concerning a post.
 *
 * @extends {Story}
 * @template D
 * @interface
 */
export interface RadivisionPost<D extends Date | string = string>
  extends Story<D> {
  /**
   * The URL of the post.
   *
   * @type {string}
   */
  link?: string;

  /**
   *
   *
   * @type {string}
   * @memberof RadivisionPost
   */
  content?: string;
}

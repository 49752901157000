/**
 * @author Ahmed Serag
 * @date   2018-04-09
 * @description Declaration of the UrlRoute interface.
 * @filename url-route-rule.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { HtmlDocument } from "./html-document"
import { Tag } from "./tag";


/**
 * the GraphQl Type.
 */
export const GRAPHQL_TYPE_URL_ROUTE_RULE = "UrlRouteRule";

/**
 * the GraphQl Type.
 */
export const GRAPHQL_TYPE_URL_QUERY_PARAMETER_RULE = "QueryParameterRule";


/**
 * A rule which maps the properties of an HTTP request to a given HTML document.
 *
 * @export
 * @interface UrlRouteRule
 */
export interface UrlRouteRule {
  
  /**
   * The ID of the AWS Cognito identity pool 
   * to which the user must belong for the rule 
   * to be evaluated as true. If this is not 
   * specified then the rule may be true irrespective 
   * of the AWS Cognito identity pool to which the user belongs.
   *
   * @type {string}
   * @memberof UrlRouteRule
   */
  cognitoIdentityPool?: string;

  /**
   * The names of the AWS Cognito user pool 
   * groups to which the user must belong for 
   * the rule to be evaluated as true. 
   * If this is not specified then the rule may be true 
   * irrespective of the AWS Cognito user pool groups 
   * to which the user belongs.
   *
   * @type {string[]}
   * @memberof UrlRouteRule
   */
  cognitoUserPoolGroups?: string[];

  /**
   * A description of the URL route rule.
   *
   * @type {string}
   * @memberof UrlRouteRule
   */
  description?: string;

  /**
   * The HTML document structure for the HTML document delivered for the URL route.
   *
   * @type {HtmlDocument}
   * @memberof UrlRouteRule
   */
  htmlDocument?: HtmlDocument;

  /**
   * The ID of the URL route rule.
   *
   * @type {string}
   * @memberof UrlRouteRule
   */
  id?: string;

  /**
   * A label for the URL route rule.
   *
   * @type {string}
   * @memberof UrlRouteRule
   */
  label?: string;

  /**
   * An ordered set of rules each of which associate the URL route with a given HTML document.
   *
   * @type {[]}
   * @memberof UrlRouteRule
   */
  queryParameters?: QueryParameterRule[];

  /**
   * The tags associated with the document.
   *
   * @type {Tag[]}
   * @memberof UrlRouteRule
   */
  tags?: Tag[];

  __typename?: string;
}

/**
 * A rule applied to a query parameter in a URL route 
 * as part of a URL route rule. The rule matches if the 
 * route includes the query parameter with the given name 
 * and with one of a given set of values. If the values 
 * are not specified then the rule matches if the query 
 * parameter is present, irrespective of its value..
 *
 * @export
 * @interface QueryParameterRule
 */
export interface QueryParameterRule {

  /**
   * The name of the query parameter.
   *
   * @type {string}
   * @memberof QueryParameterRule
   */
  name?: string;

  /**
   * The values. If specified then the match must be made to any specified value.
   *
   * @type {string[]}
   * @memberof QueryParameterRule
   */
  values?: string[];

  /**
   * 
   *
   * @type {string}
   * @memberof QueryParameterRule
   */
  __typename?: string;
}
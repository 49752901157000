import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import { AppState } from "../../redux";
import { Loader } from "../page/loader";
import { Preview } from "../../utilities/preview";
import {
  PreviewKind,
  GRAPHQL_TYPE_EVENT,
  GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
  TopLevelMediaKind,
  GRAPHQL_TYPE_FIXED_LIST,
  GRAPHQL_TYPE_POSTER,
} from "@radivision/graphql";
import { getVideoLinksFromMediaItem } from "../../utilities/general";
import { ImageHelper } from "../../utilities/image-helper";
import ProgressiveImage from "react-progressive-image";
import { extractYoutubeId } from "../../utilities/extract-youtube-id";
import { CONTENT_REQUIRING_PAYWALL } from "../../constants/content-ids";
import { PaywallOverlay } from "./paywall-overlay";
import {
  SetDetailsPanelAction,
  SetDetailsPanelPayload,
  CloseDetailsPanelAction,
} from "../../redux/details-panel/types";
import isEmpty from "lodash.isempty";

import { useWindowSize } from "../../utilities/use-window-size";

const VideoPanel = React.lazy(() => import("../page-framework/video-panel"));

interface ComponentProps {
  startMuted: boolean;
}
interface MapDispatchProps {
  setDetailsPanel: (payload: SetDetailsPanelPayload) => SetDetailsPanelAction;
  closeDetailsPanel: () => CloseDetailsPanelAction;
}

interface MapStateProps {
  item: any;
  device: string;
  checkPass: boolean;
  list: any;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { item, device, startMuted, closeDetailsPanel, list } = props;

  const windowSize = useWindowSize();
  const smallerScreen = windowSize?.width < 1600;

  const isStoryOrEvent =
    item?.__typename === GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY || item?.__typename === GRAPHQL_TYPE_EVENT;
  const isOriginalShow = item?.__typename === GRAPHQL_TYPE_FIXED_LIST;
  const canStreamVideo = isStoryOrEvent && item && item?.mediaItem;
  const whichPreviewKind = isStoryOrEvent || isOriginalShow ? PreviewKind.BACKGROUND : PreviewKind.CAROUSEL;
  const placeholder = item?.previews && Preview.getPlaceholderUrlFromPreview(item?.previews, whichPreviewKind);

  const isVideoType = item?.__typename !== GRAPHQL_TYPE_POSTER;
  const videoTrailerSrc =
    isOriginalShow && item?.previews ? Preview.getUrlFromPreview(item.previews, PreviewKind.EXCERPT) : null;
  const videoStreams = canStreamVideo ? getVideoLinksFromMediaItem(item.mediaItem) : null;
  const videoStreamSrc = videoStreams?.length ? videoStreams[0].src : null;
  const videoSrc = videoTrailerSrc || videoStreamSrc;

  const episodesIds = list?.items?.edges?.map((edge) => edge?.listItem?.item?.id);
  const itemIsNotAnEpisode =
    !isEmpty(item?.sourceList) &&
    list?.id &&
    list?.id === item?.sourceList &&
    episodesIds &&
    !episodesIds?.includes(item?.id);
  const youtubeIdFromFirstEpisode =
    itemIsNotAnEpisode && isEmpty(videoTrailerSrc) && list?.items?.edges && list?.items?.edges[0]
      ? list?.items?.edges[0].listItem?.item?.externalId
      : null;

  const youtubeIdFromLink = !isStoryOrEvent && item?.link ? extractYoutubeId(item.link) : null;
  const youtubeExternalId = item?.externalId || item?.originalClip?.externalId || item?.trailer?.externalId;
  const youtubeId = youtubeExternalId || youtubeIdFromLink || youtubeIdFromFirstEpisode;

  const hasVideo = isVideoType ? youtubeId || videoSrc : false;

  const [isReady, setIsReady] = React.useState(false);
  const videoPanelRef = React.useRef(null);

  const backgroundImage =
    item?.previews &&
    ImageHelper.fetchOptimizedImageUrl({
      imageType: "PREVIEW",
      preview: {
        content: item.previews,
        previewKind: whichPreviewKind,
        topLevelMedia: TopLevelMediaKind.IMAGE,
      },
      desiredDimensions: {
        containerWidthRatio: device === "large" ? 1.8 : 1,
        numberOfItems: 2,
      },
      revision: item.revision,
    });

  const ShowBackground = React.useCallback(() => {
    return (
      <ProgressiveImage
        src={backgroundImage?.requestedResolutionUrl || backgroundImage?.screenResolutionUrl}
        placeholder={placeholder}
      >
        {(src: any, LOADING: any, srcSetData: any) => (
          <img
            loading="lazy"
            alt={`${item?.title} Cover`}
            style={{
              filter: LOADING ? "blur(50px)" : "",
              pointerEvents: "none",
              opacity: isReady ? 0 : 1,
              transition: "opacity 5s ease-out",
            }}
            className="details-panel-cover"
            src={src}
          />
        )}
      </ProgressiveImage>
    );
  }, [item, placeholder, backgroundImage, isReady]);

  const shouldShowPaywall = CONTENT_REQUIRING_PAYWALL.includes(item?.sourceList);
  const isFirstEpisode =
    list?.items?.edges && list?.items?.edges[0] ? list?.items?.edges[0].listItem?.item?.id === item.id : false;
  const paywallDelayInSeconds = isFirstEpisode || itemIsNotAnEpisode ? 90 : 45;

  return (
    <Container>
      <ShowBackground />
      {shouldShowPaywall && (
        <PaywallOverlay videoPanelRef={videoPanelRef} delayInSeconds={paywallDelayInSeconds} skip={closeDetailsPanel} />
      )}
      {!smallerScreen && <Loader isActive={hasVideo && !isReady} />}
      {hasVideo ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            // opacity: isReady ? 1 : 0,
            transition: "opacity 2s ease-out",
          }}
        >
          <React.Suspense fallback={<Loader isActive={true} />}>
            <VideoPanel
              ref={videoPanelRef}
              videoPosition="FIT"
              videoSrc={videoSrc}
              youTube={youtubeId}
              mute={startMuted}
              disableSeek={false}
              onReady={() => setIsReady(true)}
              showControls={true}
              onVideoEnded={() => {}}
            />
          </React.Suspense>
        </div>
      ) : null}
    </Container>
  );
};

const Container = ({ children }: React.PropsWithChildren<any>) => {
  return <div className="details-panel-video">{children}</div>;
};
const mapState = (state: AppState) => {
  const { item: _item, checkPass } = state.detailsPanel;
  const { currentHash } = state.app;
  const [_title, itemId, listId] = currentHash ? String(currentHash).split("/") : [];
  const isOriginalShow = itemId && itemId === listId;
  const list = listId ? state.cache.store[listId] : null;
  const item = isOriginalShow ? list?.list?.list : _item;
  const device = state.app.device;
  return {
    device,
    item,
    list: list?.list?.list,
    checkPass,
  };
};
const mapDispatch = {
  setDetailsPanel: Actions.setDetailsPanel,
  closeDetailsPanel: Actions.closeDetailsPanel,
};

export const ItemVideo = connect(mapState, mapDispatch)(Component);

import * as React from "react";
import { connect } from "react-redux";
import { Modal } from "../../modals/modal";
import { Checkbox } from "../../page-framework/checkbox";
import { RdvButton } from "../../page-framework/rdv-button";
import { Person } from "@radivision/graphql/lib/ts/graphql/person";
import { DeactivateAccountRequestPayload, DeactivateAccountRequestAction } from "../../../redux/account/types";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";

export const DEACTIVATE_ACCOUNT_MODAL_ID = `deactivate-account-modal`;

interface MapStateProps {
  user: Person;
  isLoading: boolean;
}

interface MapDispatchProps {
  deactivateAccountRequest: (payload: DeactivateAccountRequestPayload) => DeactivateAccountRequestAction;
}

interface State {
  reasons: string[];
  description: string;
  errorMsg: string | null;
}

type Props = MapStateProps & MapDispatchProps;

const theReasons = [
  "I have a duplicate account",
  "I am not getting any value from my account",
  "I have privacy concerns",
  "Other",
];

class Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      reasons: [],
      description: "",
      errorMsg: null,
    };
    this.toggleReason = this.toggleReason.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { reasons, description } = this.state;
    this.props.deactivateAccountRequest({ reasons, description });
  }

  toggleReason(reason: string) {
    const { reasons } = this.state;
    this.setState({
      reasons: reasons.indexOf(reason) >= 0 ? reasons.filter((r) => r !== reason) : [...reasons, reason],
    });
  }

  render() {
    const { isLoading } = this.props;
    return (
      <Modal id={DEACTIVATE_ACCOUNT_MODAL_ID} title={"We are sorry to see you go"}>
        <div className="content" style={{ position: "relative" }}>
          <p>Tell us why you are closing your account</p>
          <div className="deactivate-account-modal">
            {theReasons.map((reason: string) => {
              return (
                <Checkbox
                  key={`reason-${reason}`}
                  checked={this.state.reasons.includes(reason)}
                  onChange={() => this.toggleReason(reason)}
                  label={reason}
                />
              );
            })}
            <textarea
              rows={4}
              value={this.state.description}
              onChange={(e) => {
                this.setState({ description: e.target.value });
              }}
            />
          </div>
          <div className="modal-footer">
            <div className="is-highlight">
              <RdvButton form="deactivate-account-request" text="Don't Deactivate" disabled={false} isCancel={true} />
            </div>
            <div className="is-grayed">
              <RdvButton
                form="deactivate-account-request-confirmed"
                text={isLoading ? "Sending request..." : `Deactivate my account`}
                isLoading={isLoading}
                disabled={false}
                onClick={this.onSubmit}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapState = (state: AppState) => ({
  user: state.account.user,
  isLoading: state.account.isLoading,
});

const mapDispatch = {
  deactivateAccountRequest: Actions.deactivateAccountRequest,
};
export const DeactivateAccountModal = connect(mapState, mapDispatch)(Component);

export const SET_PROFILE_STATE = "SET_PROFILE_STATE";
export const ONBOARDING_INVESTOR = "ONBOARDING_INVESTOR";
export const ONBOARDING_FOUNDER = "ONBOARDING_FOUNDER";
export const ONBOARDING_ENTHUSIAST = "ONBOARDING_ENTHUSIAST";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_ACCOUNT_TYPE = "UPDATE_ACCOUNT_TYPE";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const SET_PROFILE_PHOTO = "SET_PROFILE_PHOTO";
export const SET_PROFILE_SOCIAL_LINK = "SET_PROFILE_SOCIAL_LINK";
export const SET_PROFILE_OPPORTUNITY = "SET_PROFILE_OPPORTUNITY";
export const CHECK_EXISTING_COMAPNY_URL = "CHECK_EXISTING_WEBSITE_URL";
export const CHECK_EXISTING_COMAPNY_URL_FULFILLED = "CHECK_EXISTING_COMAPNY_URL_FULFILLED";
export const SUBMIT_PROFILE = "SUBMIT_PROFILE";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const ADD_COMPANY = "ADD_COMPANY";

export const EDIT_PROFILE_PAGE_INFO = "EDIT_PROFILE_PAGE_INFO";
export const EDIT_PROFILE_ABOUT = "EDIT_PROFILE_ABOUT";
export const EDIT_PROFILE_SOCIAL_LINKS = "EDIT_PROFILE_SOCIAL_LINKS";
export const EDIT_PROFILE_OPPORTUNITIES = "EDIT_PROFILE_OPPORTUNITIES";
export const EDIT_PROFILE_SELECT_BANNER = "EDIT_PROFILE_SELECT_BANNER";

export const INSTITUTIONAL = "INSTITUTIONAL";
export const ACCREDITED = "ACCREDITED";
export const NONACCREDITED = "NONACCREDITED";

export const InvestorTypes = [
  { title: "VC" },
  { title: "Angel Investor" },
  { title: "Syndicate Lead" },
  { title: "Founder" },
  { title: "Press" },
];

export const AccreditedInvestorTypes = [
  { title: "Yes, finra-licensed individual in good standing with Series 7, 82 or 65", kind: "" },
  { title: "Yes, entity or trust not formed for the specific purpose of investing with $5M+ in assets", kind: "" },
  { title: "Yes, entity beneficially owned by all accredited investors by the standards above", kind: "" },

  { title: "Yes, individual with net-worth of $1M+ (with or without spouse)", kind: "" },
  { title: "Yes, individual with annual income in last two years if $200K+ ($300K+ with spouse)", kind: "" },
];

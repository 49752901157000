import { Reducer } from "redux";
import produce from "immer";
import { CacheState, CacheAction, CacheDataPayload, CacheDataAction, FetchInProgressAction, FetchErrorAction, SetLoadingStatusAction } from "./types";
import * as constants from "./constants";

const initialState: CacheState = {
  store: {},
  inProgress: {},
  isLoading: false,
  updatedAt: {},
  errors: {},
};

export const CacheReducer: Reducer<CacheState, CacheAction> = (
  state: CacheState = initialState,
  action: CacheAction,
) => {
  switch (action.type) {
    case constants.CACHE_DATA: {
      const { key, data } = (action as CacheDataAction).payload;
      return produce(state, (draft) => {
        draft.store[key] = data;
        draft.updatedAt[key] = new Date().getTime();
        draft.errors[key] = null;
      });
    }
    case constants.FETCH_IN_PROGRESS: {
      const { key, status } = (action as FetchInProgressAction).payload;
      return produce(state, (draft) => {
        draft.inProgress[key] = status;
      });
    }
    case constants.SET_LOADING_STATUS: {
      const { status } = (action as SetLoadingStatusAction).payload;
      return produce(state, (draft) => {
        draft.isLoading = status;
      });
    }
    case constants.FETCH_ERROR: {
      const { key, error } = (action as FetchErrorAction).payload;
      return produce(state, (draft) => {
        draft.errors[key] = error;
      });
    }
    default:
      return state;
  }
};

/**
 * @author Ahmed Samer
 * @date   2021-04-24
 * @description Declaration of the UrlRoute interface.
 * @filename deactivate-request.ts
 * @copyright Copyright 2021 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { UserRequest } from "./user-request";

/**
 * the GraphQl Type.
 */
export const GRAPHQL_TYPE_DEACTIVATE_REQUEST = "DeactivateRequest";

/**
 *
 *
 * @export
 * @interface DeactivateRequest
 * @extends {Node}
 */
export interface DeactivateRequest<D extends Date | string = string>
  extends UserRequest<D> {
  /**
   *
   *
   * @type {string[]}
   * @memberof DeactivateRequest
   */
  reasons?: string[];

  /**
   * 
   *
   * @type {string}
   * @memberof DeactivateRequest
   */
  description?: string;
}

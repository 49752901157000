/**
 * @Author: Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-05-28
 * @description Implementation of the ClauseOperator class and declaration of the ClauseOperatorKind enum.
 * @filename clause-operator.ts
 */
import { Node } from "./node";

/**
 * The __typeID of the Clause Operator with Data GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_CLAUSE_OPERATOR: string = "ClauseOperator";

/**
 * The collection of clause operators
 */
export enum ClauseOperatorKind {
  /**
   * An AND operator
   */
  AND = "AND",

  /**
   * An OR operator
   */
  OR = "OR"
}

/**
 * An operator used in a tag filter clause.
 *
 * @export
 * @extends {Node<D>}
 * @template D
 */
export interface ClauseOperator<D extends Date | string = string>
  extends Node<D> {
  /**
   * A description of the clause operator.
   *
   * @type {string}
   */
  description?: string;

  /**
   *  The category key
   *
   * @type {ClauseOperatorKind}
   */
  kind?: ClauseOperatorKind;

  /**
   * The clause operator label
   */
  label?: string;
}

import { fork, all } from "redux-saga/effects";
import { watchOnBoardingInvestor } from "./workers/onboarding-investor";
import { watchOnBoardingFounder } from "./workers/onboarding-founder";
import { watchOnBoardingEnthusiast } from "./workers/onboarding-enthusiast";
import { watchEditProfile } from "./workers/edit-profile";
import { watchSetProfilePhoto } from "./workers/set-profile-photo";
import { watchSubmitProfile } from "./workers/submit-profile";
import { watchDeleteCompany } from "./workers/delete-company";
import { watchAddCompany } from "./workers/add-company";

const sagas = [
  watchOnBoardingInvestor,
  watchOnBoardingFounder,
  watchOnBoardingEnthusiast,
  watchSetProfilePhoto,
  watchEditProfile,
  watchSubmitProfile,
  watchDeleteCompany,
  watchAddCompany,
];

export default function* root() {
  yield all(sagas.map((saga) => fork(saga)));
}

import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import {
  ProfileImage,
  Subject,
  ProfileModuleState,
  SetProfilePhotoPayload,
  SetProfilePhotoAction,
  SetProfileStatePayload,
  SetProfileStateAction,
} from "../redux/profile/types";
import { Person, PreviewKind, GRAPHQL_TYPE_ENTITY } from "@radivision/graphql";
import { companyIcon, personIcon, cameraIcon } from "../icons";
import { setProfilePhoto, setProfileState } from "../redux/profile/actions";
import { OptimizedImageUrls } from "../../../component-configuration/optimized-image";
import { DEFAULTS } from "../../../utilities/defaults";
import "./profile-images.scss";
import { EDIT_PROFILE_SELECT_BANNER } from "../redux/profile/constants";

interface ComponentProps {
  isRequired?: boolean;
}

interface MapStateProps {
  profileBackground: ProfileImage;
  profileBackgroundUrl: OptimizedImageUrls | null;
  profileImage: ProfileImage;
  profileImageUrl: OptimizedImageUrls | null;
  user: Person;
  type: Subject | null;
}

interface MapDispatchProps {
  setProfilePhoto: (payload: SetProfilePhotoPayload) => SetProfilePhotoAction;
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}

export type Props = ComponentProps & MapStateProps & MapDispatchProps;

export const Component = (props: Props) => {
  const {
    profileBackground,
    profileBackgroundUrl,
    setProfileState,
    profileImage,
    profileImageUrl,
    setProfilePhoto,
    type,
  } = props;

  const onFileSelect = (type: PreviewKind.BACKGROUND | PreviewKind.SQUARE) => (event: any) => {
    const file = event.target?.files && event.target.files[0];
    if (file) setProfilePhoto({ type, file });
  };

  const openSelectBannerScreen = React.useCallback(() => {
    setProfileState({ screen: EDIT_PROFILE_SELECT_BANNER });
  }, [setProfileState]);

  const uploadedProfileImage = profileImage?.dataUrl;
  const existingProfileImage = profileImageUrl?.requestedResolutionUrl;
  // const defaultImage = DEFAULTS[`DEFAULT_${type}_SQUARE`];
  const image = uploadedProfileImage || existingProfileImage;
  const imageUrl = image ? { backgroundImage: `url('${image}')` } : {};

  const uploadedProfileBackground = profileBackground?.dataUrl;
  const existingProfileBackground = profileBackgroundUrl?.requestedResolutionUrl;
  const defaultBackground = DEFAULTS[`DEFAULT_${type}_BACKGROUND`];
  const background = uploadedProfileBackground || existingProfileBackground || defaultBackground;
  const backgroundUrl = background ? { backgroundImage: `url('${background}')` } : {};
  const imageIcon = type === GRAPHQL_TYPE_ENTITY ? companyIcon : personIcon;
  return (
    <div className="upload-profile-images">
      <label htmlFor="backgroundFile" className="upload-background" style={{ ...backgroundUrl }}>
        <button onClick={openSelectBannerScreen}>
          <span>Change banner</span>
        </button>
      </label>
      <label htmlFor="imageFile" className="upload-image" style={{ ...imageUrl }}>
        {!image ? <div className="icon">{imageIcon}</div> : null}
        <input
          type="file"
          name="backgroundFile"
          onChange={onFileSelect(PreviewKind.SQUARE)}
          accept="image/png,image/gif,image/jpeg"
        />
        <div className="camera">{profileImage || profileImageUrl ? cameraIcon : null}</div>
        <button>Upload picture</button>
      </label>
    </div>
  );
};

const mapState = (state: AppState & ProfileModuleState) => ({
  user: state.account.user,
  profileBackground: state.profile.profileBackground,
  profileBackgroundUrl: state.profile.profileBackgroundUrl,
  profileImage: state.profile.profileImage,
  profileImageUrl: state.profile.profileImageUrl,
  type: state.profile.type,
});

const mapDispatch = {
  setProfilePhoto: setProfilePhoto,
  setProfileState: setProfileState,
};

export const ProfileImages = connect(mapState, mapDispatch)(Component);

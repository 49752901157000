import * as React from "react";
import { Props } from ".";

export const CarouselContext: React.Context<Props> = React.createContext({} as Props);

export const useCarouselContext = () => {
  const ctx = React.useContext(CarouselContext);
  if (!ctx) {
    throw new Error("useCarouselContext must be inside a Provider with a value");
  }
  return ctx;
};

/**
 * @author Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-12-25
 * @description Implementation of crawling utilities to be used in the app.
 * @filename crawler.ts
 */
import { ApiObjectType } from "@radivision/graphql/lib/ts/graphql/api-object";
import { fetchQuery } from "react-relay";
import { CRAWL_QUERY } from "../relay/queries/crawl-query";
import { ENVIRONMENT } from "../relay/relay-environment";
import { ApiObjectKindType } from "@radivision/graphql/lib/ts/graphql/api-object-kind";
import { ERROR_MESSAGES } from "../constants/errors-constants";
import { isYoutube } from "./general";

/**
 * Contains functions manages requests to the crawl GraphQL query.
 */
export class Crawler {
  /**
   * Returns a promise containing the results of crawling a given API object.
   *
   * @param {string} url The URL of the remote endpoint.
   *
   * @param {} apiObjectKind The API object.
   *
   * @returns {Promise<ApiObjectType>} The promise containing the results of crawling a given API object.
   */
  static fetch(url: string, apiObjectKind: ApiObjectKindType): Promise<ApiObjectType> {
    apiObjectKind = isYoutube(url) ? ApiObjectKindType.PODCAST : apiObjectKind;
    return fetchQuery(ENVIRONMENT, CRAWL_QUERY, {
      url,
      apiObjectKind,
    }).then((result: any) => {
      let promise: Promise<ApiObjectType>;

      if (
        result !== undefined &&
        result !== null &&
        result.crawl !== undefined &&
        result.crawl !== null &&
        result.crawl.node !== undefined &&
        result.crawl.node !== null
      ) {
        promise = Promise.resolve(result.crawl.node);
      } else {
        promise = Promise.reject(Error(ERROR_MESSAGES.INVALID_CRAWLING_QUERY));
      }

      return promise;
    });
  }
}

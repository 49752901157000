import { graphql, GraphQLTaggedNode } from "react-relay";

export const USER_REVISION_QUERY: GraphQLTaggedNode = graphql`
  query userRevisionQuery($id: ID!) {
    person(id: $id) {
      hasErrors
      person {
        __typename
        id
        revision
      }
    }
  }
`;

/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-03-14
 * @description Implementation of dynamicList utilities to be used in the app.
 * @filename fixed-list.ts
 */

import { CreateFixedListInput, UpdateFixedListInput } from "@radivision/graphql";
import { Environment, GraphQLTaggedNode } from "relay-runtime";
import { ListPageFromInput } from "../../component-configuration/cms-pages";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../../relay/relay-environment";
import { getClientMutationId } from "../general";
import { commitMutation } from "../relay";

/**
 *
 *
 * @export
 * @class FixedList
 */
export class FixedList {
  /**
   * Returns a promise to add new dynamicList to database.
   *
   * @param {ListPageFromInput} fixedListInput create new fixedList input.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add new dynamicList mutation .
   */
  static addFixedList(fixedListInput: ListPageFromInput, environment: Environment = RELAY_ENVIRONMENT): Promise<any> {
    // ... convert dynamicList input to  CreateFixedListInput
    let createFixedListInput: CreateFixedListInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    // initialize create dynamicList graphQl input
    createFixedListInput = {
      clientMutationId: getClientMutationId(),
      previews: fixedListInput.previews,
      description:
        fixedListInput.summary !== undefined && fixedListInput.summary !== null && fixedListInput.summary.length > 0
          ? fixedListInput.summary.trim()
          : undefined,
      tags: fixedListInput.tags && fixedListInput.tags.length > 0 ? fixedListInput.tags : undefined,
      label:
        fixedListInput.label !== undefined && fixedListInput.label !== null && fixedListInput.label.length > 0
          ? fixedListInput.label.trim()
          : undefined,
      title:
        fixedListInput.title !== undefined && fixedListInput.title !== null && fixedListInput.title.length > 0
          ? fixedListInput.title.trim()
          : undefined,
      name:
        fixedListInput.name !== undefined && fixedListInput.name !== null && fixedListInput.name.length > 0
          ? fixedListInput.name.trim()
          : undefined,
      // badges: fixedListInput.badges,
      subTitle:
        fixedListInput.subTitle !== undefined && fixedListInput.subTitle !== null && fixedListInput.subTitle.length > 0
          ? fixedListInput.subTitle.trim()
          : undefined,
      items: fixedListInput.items && fixedListInput.items.length > 0 ? fixedListInput.items : undefined,
    };

    promise = import("../../relay/mutations-fixedlist").then((mutations) => {
      config = {
        mutation: mutations.CREATE_FIXED_LIST_GRAPHQL_MUTATION,
        variables: {
          input: createFixedListInput,
        },
      };
      return commitMutation(environment, config);
    });

    promise = promise.then((mutationResults: any) => {
      return mutationResults.createFixedList.hasErrors === false
        ? Promise.resolve(mutationResults.createFixedList.fixedList)
        : Promise.reject(mutationResults.createFixedList.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @param {ListPageFromInput} fixedListInput
   * @param {Environment} [environment=RELAY_ENVIRONMENT]
   * @returns {Promise<any>}
   * @memberof FixedList
   */
  static updateFixedList(
    fixedListInput: ListPageFromInput,
    environment: Environment = RELAY_ENVIRONMENT,
  ): Promise<any> {
    // ... convert dynamicList input to  CreateFixedListInput
    let updateFixedListInput: UpdateFixedListInput;
    let promise: Promise<any>;
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    // validate required parameters in schema and then
    // add only provided optional parameters.

    // initialize create dynamicList graphQl input
    updateFixedListInput = {
      id: fixedListInput.id,
      revision: fixedListInput.revision,
      clientMutationId: getClientMutationId(),
      previews: fixedListInput.previews,
      description:
        fixedListInput.summary !== undefined && fixedListInput.summary !== null && fixedListInput.summary.length > 0
          ? fixedListInput.summary.trim()
          : undefined,
      tags: fixedListInput.tags && fixedListInput.tags.length > 0 ? fixedListInput.tags : undefined,
      label:
        fixedListInput.label !== undefined && fixedListInput.label !== null && fixedListInput.label.length > 0
          ? fixedListInput.label.trim()
          : undefined,
      title:
        fixedListInput.title !== undefined && fixedListInput.title !== null && fixedListInput.title.length > 0
          ? fixedListInput.title.trim()
          : undefined,
      name:
        fixedListInput.name !== undefined && fixedListInput.name !== null && fixedListInput.name.length > 0
          ? fixedListInput.name.trim()
          : undefined,
      // badges: fixedListInput.badges,
      subTitle:
        fixedListInput.subTitle !== undefined && fixedListInput.subTitle !== null && fixedListInput.subTitle.length > 0
          ? fixedListInput.subTitle.trim()
          : undefined,
      items: fixedListInput.items && fixedListInput.items.length > 0 ? fixedListInput.items : undefined,
    };

    promise = import("../../relay/mutations-fixedlist").then((mutations) => {
      config = {
        mutation: mutations.UPDATE_FIXED_LIST_GRAPHQL_MUTATION,
        variables: {
          input: updateFixedListInput,
        },
      };
      return commitMutation(environment, config);
    });

    promise = promise.then((mutationResults: any) => {
      return mutationResults.updateFixedList.hasErrors === false
        ? Promise.resolve(mutationResults.updateFixedList.fixedList)
        : Promise.reject(mutationResults.updateFixedList.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @static
   * @param {ListPageFromInput} fixedListInput
   * @param {Environment} [environment=RELAY_ENVIRONMENT]
   * @returns {Promise<any>}
   * @memberof FixedList
   */
  static deleteFixedList(
    fixedListInput: ListPageFromInput,
    environment: Environment = RELAY_ENVIRONMENT,
  ): Promise<any> {
    return;
  }
}

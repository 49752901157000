/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2019-09-24
 * @filename: invitation.ts
 * @description: Declaration of optimized image interface
 */

import { Node } from "./node";

/**
 * The __typeID of the GraphQL image object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_INVITATION: string = "Invitation";

/**
 * The properties of a use case for a preview for a person, entity, story list or list collection.
 *
 * @enum
 */
export enum InvitationKind {
  /**
   * The header background of the personal page for a person or entity.
   */
  SIGN_UP = "SIGN_UP",

  /**
   * A square media asset.
   */
  OTHER = "OTHER"
}

/**
 * The details of the optimized image
 *
 * @export
 * @interface Invitation
 */
export interface Invitation<D extends Date | string = string> extends Node<D> {
  /**
   *
   *
   * @type {string}
   * @memberof Invitation
   */
  description?: string;

  /**
   *
   *
   * @type {string}
   * @memberof Invitation
   */
  entity?: string;

  /**
   *
   *
   * @type {string}
   * @memberof Invitation
   */
  person?: string;

  /**
   *
   *
   * @type {string}
   * @memberof Invitation
   */
  expiryDate?: string;

  /**
   *
   *
   * @type {string}
   * @memberof Invitation
   */
  invitationType?: InvitationKind;
}

/**
 * @author Ahmed serag
 * @date   2018-09-24
 * @description Declaration of the MultipleChoiceQuestionChoice interface.
 * @filename multiple-choice-question-choice.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Connection } from "./connection";
import { Entity } from "./entity";
import { Preview } from "./preview";
import { Person } from "./person";
import { StoryType } from "./story-type";
import { Poster } from "./poster";

/**
 * The __typeID of the Multiple Choice Question Choice GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_MULTIPLE_CHOICE_QUESTION_CHOICE: string = "MultipleChoiceQuestionChoice";

/**
 * A choice for a multiple-choice question for a questionnaire.
 *
 * @export
 * @interface MultipleChoiceQuestionChoice
 */
export interface MultipleChoiceQuestionChoice {

  /**
   * A flag which indicates that a multiple-choice question choice can also accept text.
   *
   * @type {boolean}
   * @memberof MultipleChoiceQuestionChoice
   */
  acceptsText?: boolean;

  /**
   * The number of times that the choice has been selected.
   *
   * @type {number}
   * @memberof MultipleChoiceQuestionChoice
   */
  count?: number;
  
  /**
   * The entity associated with the multiple-choice question choice.
   *
   * @type {Entity}
   * @memberof MultipleChoiceQuestionChoice
   */
  entity?: Entity;

  /**
   * The ID of the question.
   *
   * @type {string}
   * @memberof MultipleChoiceQuestionChoice
   */
  id: string;

  /**
   * A collection of media assets used as previews for the question.
   *
   * @type {Connection<string, Preview>}
   * @memberof MultipleChoiceQuestionChoice
   */
  previews?: Connection<string, Preview>;

  /**
   * The person associated with the multiple-choice question choice.
   *
   * @type {Person}
   * @memberof MultipleChoiceQuestionChoice
   */
  person?: Person;

  /**
   * The poster associated with the multiple-choice question choice. 
   *
   * @type {Poster}
   * @memberof MultipleChoiceQuestionChoice
   */
  poster?: Poster;

  /**
   * The story associated with the multiple-choice question choice.
   *
   * @type {StoryType}
   * @memberof MultipleChoiceQuestionChoice
   */
  story?: StoryType;

  /**
   * The sub-title of the multiple-choice question choice.
   *
   * @type {string}
   * @memberof MultipleChoiceQuestionChoice
   */
  subTitle?: string;

  /**
   * The title of the multiple-choice question choice.
   *
   * @type {string}
   * @memberof MultipleChoiceQuestionChoice
   */
  title?: string;

  /**
   * The typename of the node.
   *
   * @type {string}
   */
  __typename?: string;
}
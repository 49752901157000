/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2019-01-22
 * @filename: instagram-post-content-item-kind.ts
 * @description: Declaration of instagram post item kind interface
 */


 /**
 * The __typeID of the GraphQL instagram post content items kind object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_INSTAGRAM_POST_CONTENT_KIND: string = "InstagramPostContentItemKind";


/**
 * The kind of Instagram post item
 *
 * @export
 * @enum {number}
 */
export enum InstagramPostContentItemKind {
  /**
   *  The post item contains links to other post items
   */
  CAROUSEL = "CAROUSEL",

  /**
   * The post item is an image
   */
  IMAGE = "IMAGE",

  /**
   * The post item a video
   */
  VIDEO = "VIDEO"
}

/**
 * @author Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-07-08 03:10
 * @description Implementation of general utilities to be used in the app.
 * @filename general.ts
 */
import { getNewUniqueId as _getNewUniqueId, Types } from "@radivision/common";
import { Badge as GraphQlBadge } from "@radivision/graphql/lib/ts/graphql/badge";
import $ from "jquery";
import ReactGA from "react-ga";
import { CarouselItem, CarouselItemDetails } from "../component-configuration/carousel-item";
import { OptimizedImageUrls } from "../component-configuration/optimized-image";
import { ProfileHeaderDetails } from "../component-configuration/profile-header-details";
import { GridViewItem } from "../component-configuration/grid-view-item";
import { Episode } from "../component-configuration/episode";
import { ACTION_MODAL_ID } from "../constants/general-constants";
// import { UPCOMING_SHOWS_QUESTIONNAIRE } from "./questionnaire";
import { ImageHelper } from "./image-helper";
import { List } from "@radivision/graphql/lib/ts/graphql/list";
import { PreviewKind } from "@radivision/graphql/lib/ts/graphql/preview-type";
import {
  GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
  OriginalContentStory,
} from "@radivision/graphql/lib/ts/graphql/original-content-story";
import { GRAPHQL_TYPE_SEARCH_HIT, SearchHit, HitItemKind } from "@radivision/graphql/lib/ts/graphql/search-hit";
import { GRAPHQL_TYPE_ENTITY, Entity } from "@radivision/graphql/lib/ts/graphql/entity";
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import { GRAPHQL_TYPE_ARTICLE_STORY } from "@radivision/graphql/lib/ts/graphql/article-story";
import { GRAPHQL_TYPE_BOOK_STORY } from "@radivision/graphql/lib/ts/graphql/book-story";
import { GRAPHQL_TYPE_NEWSLETTER_STORY } from "@radivision/graphql/lib/ts/graphql/newsletter-story";
import { GRAPHQL_TYPE_ONLINE_COURSE_STORY } from "@radivision/graphql/lib/ts/graphql/online-course-story";
import { GRAPHQL_TYPE_PERSON, Person } from "@radivision/graphql/lib/ts/graphql/person";
import { GRAPHQL_TYPE_PODCAST_STORY } from "@radivision/graphql/lib/ts/graphql/podcast-story";
import { GRAPHQL_TYPE_FIXED_LIST } from "@radivision/graphql/lib/ts/graphql/fixed-list";
import { GRAPHQL_TYPE_DYNAMIC_LIST } from "@radivision/graphql/lib/ts/graphql/dynamic-list";
import { GRAPHQL_TYPE_LIST_COLLECTION } from "@radivision/graphql/lib/ts/graphql/list-collection";
import { Questionnaire } from "@radivision/graphql/lib/ts/graphql/questionnaire";
import { MultipleChoiceQuestion } from "@radivision/graphql/lib/ts/graphql/multiple-choice-question";
import { Follows } from "@radivision/graphql/lib/ts/graphql/follows";
import { MediaAsset } from "@radivision/graphql/lib/ts/graphql/media-asset";
import { InvestmentByPerson } from "@radivision/graphql/lib/ts/graphql/investment-by-person";
import { Investment } from "@radivision/graphql/lib/ts/graphql/investment";
import { Inspiration } from "@radivision/graphql/lib/ts/graphql/inspiration";
import { PersonEntity } from "@radivision/graphql/lib/ts/graphql/person-entity";
import { GRAPHQL_TYPE_EVENT } from "@radivision/graphql/lib/ts/graphql/event";
import { NominateTabDetails } from "../component-configuration/nominate-tab-details";
import { GeneralItemDetails } from "../component-configuration/general-item-details";

import { Show } from "../component-configuration/show";
import { analyticsSnowplow } from "./analytics-snowplow";
import { VALID_URL_PATTERN } from "../constants/valid-url-pattern";
import isEmpty from "lodash.isempty";
import { storageFactory } from "./local-storage-factory";
import { isValidEmail } from "./is-valid-email";
import { GRAPHQL_TYPE_PITCH_VIDEO_STORY } from "@radivision/graphql";
import { getLocation } from "./get-location";
export * from "./get-number-from-range";
export * from "./get-location";

const localStore = storageFactory(() => localStorage);

/**
 * An array of alphanumeric characters.
 *
 * @const
 * @type {string}
 */
const ALPHANUMERIC: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";

/**
 * An array of alpha characters.
 *
 * @const
 * @type {string}
 */
const ALPHA: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

/**
 * Formats a number to a string with given length padded by zeroes.
 *
 * @param {number} value The number.
 *
 * @param {number} length The required length.
 *
 * @returns {string} The number formatted as a string.
 */
function formatInteger(value: number, length: number): string {
  return (value / Math.pow(10, length)).toFixed(length).substr(2);
}

/**
 *
 */
export function getTodayDate(): string {
  return new Date().toISOString().split("T")[0];
}

/**
 * Convert milliseconds to minutes:sec format
 *
 * @param {number} milliseconds The number of milliseconds.
 *
 * @returns {string} A string of format minutes:sec
 */
export function mapMillisecondsToMinutes(milliseconds: number): string {
  let minutes: number = 0;
  let seconds: number = 0;

  if (milliseconds > 1000) {
    seconds = Math.floor(milliseconds / 1000);
    if (seconds >= 60) {
      minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;
    }
  }
  return `${formatInteger(minutes, 2)}:${formatInteger(seconds, 2)}`;
}

/**
 * Print time to minutes:sec format
 *
 * @param {number} seconds The number of milliseconds.
 *
 * @returns {string} A string of format minutes:sec
 */
export function printFormattedTime(seconds: number): string {
  let minutes: number = 0;
  let second: number = seconds;
  if (second >= 60) {
    minutes = Math.floor(second / 60);
    second = seconds % 60;
  }
  return `${formatInteger(minutes, 2)}:${formatInteger(second, 2)}`;
}

/**
 * to print the duration in format of how many hours and minutes
 *
 * @param time the duration time in format mm:ss
 *
 * @returns {string} the formatted duration
 */
export function printHoursAndMinutesOfDuration(time: string): string {
  const t = time.split(":");
  let minutes: number = Number(t[0]);
  let hours: number = 0;
  if (minutes > 60) {
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
  }
  return `${hours > 0 ? hours + "h" : ""}${minutes}m`;
}

/**
 * detect if user uses a mobile device
 *
 * @returns {boolean}
 */
export function detectMobile(): boolean {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

/**
 * Returns a carousel item populated from an underlying GraphQL item.
 *
 * @param {*} item The GraphQL item.
 * @param {List} list The parent list that the item belongs to.
 * @param {number} index The index of the item in the list.
 * @param {{ containerWidthRatio: number, numberOfItems: number}} requestedImageDimensions The preview image dimensions.
 * @param {PreviewKind} itemPreviewType The Preview Kind of item preview
 * @param {Person} user The current logged in user value
 * @param {boolean} oneTabDetails The for Original Content Story episode details to gather all info in one tab
 * @param {boolean} getDetails return details to the list items or not
 *
 * @returns {CarouselItem} The populated carousel item.
 */
export function getCarouselItem(
  edge: any,
  list: List,
  index: number,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
  itemPreviewType: PreviewKind,
  getDetails?: boolean,
): CarouselItem {
  let authors: string[];
  let cost: string;
  let description: string;
  let episodeInfo: string[];
  let id: string;
  let link: string;
  let location: string;
  let previewImageUrl: OptimizedImageUrls = {};
  let publishDate: string;
  let publisher: string;
  let summary: string;
  let subtitle: string;
  let timeLabel: number;
  let timeRequiredInSeconds: number;
  let title: string;
  let type: string;
  let video;
  let youTubeVideo;
  let content: string;
  const tags: string[] = [];
  let itemKind: any;
  let permalink: any;
  let websiteUrl: string;
  let sourceList: string;
  let originalClip: any;
  let trailer: any;

  const item: any =
    edge && edge.listItem
      ? edge.listItem.item
      : edge.story
      ? edge.story
      : edge.edges
      ? edge.edges
      : edge.node
      ? edge.node
      : edge;

  let desiredItemPreviewType: PreviewKind = itemPreviewType;

  switch (itemPreviewType) {
    case PreviewKind.CIRCLE:
      desiredItemPreviewType = PreviewKind.SQUARE;
      break;
    default:
  }

  if (Types.isObject(item)) {
    if (Types.toString(item.__typename)) {
      type = item.__typename;
      if (item.__typename === GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY || item.__typename === GRAPHQL_TYPE_EVENT) {
        video = getVideoLinksFromMediaItem(item.mediaItem);
        youTubeVideo = getVideoLinksFromExternalId(item.externalId);
        episodeInfo = [list ? list.name : "", `Episode ${index + 1}`, mapMillisecondsToMinutes(item.durationInSeconds)];
      }
      if (item.__typename === GRAPHQL_TYPE_SEARCH_HIT) {
        return getCarouselItemFromSearchHit(item, requestedImageDimensions, desiredItemPreviewType);
      }
      if (item.__typename === GRAPHQL_TYPE_ENTITY) {
        location = getLocation(item);
        websiteUrl = item.websiteUrl;
      } else if (item.link !== null && item.link !== undefined) {
        link = item.link;
      }
      if (item.__typename === GRAPHQL_TYPE_PITCH_VIDEO_STORY) {
        trailer = item.trailer;
        originalClip = item.originalClip;
      }
    }
    if (item.previews !== null && item.previews !== undefined) {
      previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
        imageType: "PREVIEW",
        preview: {
          content: item.previews,
          previewKind: desiredItemPreviewType,
          topLevelMedia: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: requestedImageDimensions,
        isSquareImage: desiredItemPreviewType === PreviewKind.SQUARE ? true : false,
        revision: item.revision,
      });
    } else {
      previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
        imageType: "DEFAULT",
        default: {
          previewType: desiredItemPreviewType,
          mediaType: TopLevelMediaKind.IMAGE,
          type: item.__typename,
        },
        desiredDimensions: requestedImageDimensions,
        isSquareImage: desiredItemPreviewType === PreviewKind.SQUARE ? true : false,
        revision: item.revision,
      });
    }

    if (item.title !== null && item.title !== undefined) {
      title = item.title;
    } else if (item["fullName"] !== null && item["fullName"] !== undefined) {
      title = item["fullName"];
    } else if (item["name"] !== null && item["name"] !== undefined) {
      title = item["name"];
    } else if (item.label !== null && item.label !== undefined) {
      title = item.label;
    }
    if (item.id !== null && item.id !== undefined) {
      id = item.id;
    }

    if (item["subTitle"] !== null && item["subTitle"] !== undefined) {
      description = item["description"];
      subtitle = item["description"];
    } else if (item["description"] !== null && item["description"] !== undefined) {
      description = item["description"];
      summary = item["description"];
    }
    if (item["publisher"] !== null && item["publisher"] !== undefined) {
      publisher = item["publisher"];
    }
    if (item["publishedDate"] !== null && item["publishedDate"] !== undefined) {
      publishDate = item["publishedDate"];
    }

    if (item["authors"] !== null && item["authors"] !== undefined) {
      authors = item["authors"];
    }

    if (item["cost"] !== null && item["cost"] !== undefined) {
      cost = item["cost"];
    }

    if (item["responsibility"] !== null && item["responsibility"] !== undefined) {
      description = item["responsibility"];
    }

    if (item["timeRequiredInSeconds"] !== null && item["timeRequiredInSeconds"] !== undefined) {
      timeRequiredInSeconds = item["timeRequiredInSeconds"];
      timeLabel = item["timeRequiredInSeconds"];
    }

    if (item["timeToReadInSeconds"] !== null && item["timeToReadInSeconds"] !== undefined) {
      timeLabel = item["timeToReadInSeconds"];
    }
    if (item["durationInSeconds"] !== null && item["durationInSeconds"] !== undefined) {
      timeRequiredInSeconds = item["durationInSeconds"];
      timeLabel = item["durationInSeconds"];
    }

    if (item["content"] !== null && item["content"] !== undefined) {
      content = item["content"];
      description = item["content"];
    }
    if (item["tags"] !== null && item["tags"] !== undefined) {
      for (const TAG of item.tags) {
        tags.push(TAG.label);
      }
    }
    if (item.kind !== null && item.kind !== undefined) {
      itemKind = item.kind;
    }
    if (item.permalink !== null && item.permalink !== undefined) {
      permalink = item.permalink;
    }

    if (item.sourceList !== null && item.sourceList !== undefined) {
      sourceList = item.sourceList;
    }
  }

  const details: CarouselItemDetails = getDetails
    ? getCarouselItemDetails(edge, list, index, requestedImageDimensions)
    : null;

  return {
    previewImageUrl,
    title,
    description,
    tagLine: item.tagLine,
    details,
    link,
    type,
    location,
    timeLabel,
    publisher,
    publishDate,
    id,
    authors,
    cost,
    timeRequiredInSeconds,
    summary,
    video,
    youTubeVideo,
    episodeInfo,
    subtitle,
    content,
    tags,
    itemKind,
    permalink,
    websiteUrl,
    landingPageUrl: item.landingPageUrl,
    sourceList,
    trailer,
    originalClip,
  };
}

/**
 *
 *
 * @export
 * @param {SearchHit} hit
 * @param {{
 *     requestedImageDimensions: {
 *       containerWidthRatio: number;
 *       numberOfItems: number;
 *     };
 *     isSquareImage: boolean;
 *   }} imageOptions
 * @returns {CarouselItem}
 */
export function getCarouselItemFromSearchHit(
  hit: SearchHit,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
  itemPreviewType: PreviewKind,
): CarouselItem {
  const item: CarouselItem = {
    title: hit.title ? hit.title : hit.name,
    link: hit.linkUrl,
    id: hit.id,
    previewImageUrl: {},
    landingPageUrl: hit.landingPageUrl,
    tags: hit.tags,
    responsibility: hit.responsibility,
  };

  if (hit.kind !== undefined && hit.kind !== null) {
    switch (hit.kind) {
      case HitItemKind.ARTICLE:
        item.type = GRAPHQL_TYPE_ARTICLE_STORY;
        item.subtitle = hit.subTitle;
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_ARTICLE_STORY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });

        break;
      case HitItemKind.BOOK:
        item.type = GRAPHQL_TYPE_BOOK_STORY;
        item.subtitle = hit.subTitle;
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_BOOK_STORY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });

        break;
      case HitItemKind.ENTITY:
        item.type = GRAPHQL_TYPE_ENTITY;
        item.subtitle = hit.tagLine ? hit.tagLine : hit.subTitle;
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_ENTITY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });

        break;
      case HitItemKind.NEWSLETTER:
        item.subtitle = hit.subTitle ? hit.subTitle : hit.publisher;
        item.type = GRAPHQL_TYPE_NEWSLETTER_STORY;
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_NEWSLETTER_STORY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });

        break;
      case HitItemKind.ONLINE_COURSE:
        item.subtitle = hit.subTitle ? hit.subTitle : hit.published;
        item.timeLabel = `${(Number(hit.durationInSeconds) / 60).toFixed()} minutes duration`;
        item.type = GRAPHQL_TYPE_ONLINE_COURSE_STORY;
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_ONLINE_COURSE_STORY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });

        break;
      case HitItemKind.ORIGINAL_CONTENT:
        item.type = GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY;
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });

        break;
      case HitItemKind.PERSON:
        item.type = GRAPHQL_TYPE_PERSON;
        (item.responsibility = hit.responsibility ? hit.responsibility : null),
          (item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
            imageType: "DEFAULT",
            default: {
              previewType: PreviewKind.SQUARE,
              mediaType: TopLevelMediaKind.IMAGE,
              type: GRAPHQL_TYPE_PERSON,
            },
            desiredDimensions: requestedImageDimensions,
            isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
            revision: undefined,
          }));

        break;
      case HitItemKind.PODCAST:
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_PODCAST_STORY,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });
        item.timeLabel = `${(Number(hit.durationInSeconds) / 60).toFixed()} minutes duration`;
        item.type = GRAPHQL_TYPE_PODCAST_STORY;
        break;
      case HitItemKind.FIXED_LIST:
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_FIXED_LIST,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });
        item.type = GRAPHQL_TYPE_FIXED_LIST;
        break;
      case HitItemKind.DYNAMIC_LIST:
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_DYNAMIC_LIST,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });
        item.type = GRAPHQL_TYPE_DYNAMIC_LIST;
        break;
      case HitItemKind.LIST_COLLECTION:
        item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_DYNAMIC_LIST,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
          revision: undefined,
        });
        item.type = GRAPHQL_TYPE_LIST_COLLECTION;
        break;
      default:
    }
  }

  if (hit.previewUrl !== undefined && hit.previewUrl !== null) {
    if (hit.previewUrl.indexOf("http") < 0) {
      item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
        imageType: "MEDIA_ASSET",
        mediaAsset: {
          mediaAssetPath: hit.previewUrl,
          type: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: requestedImageDimensions,
        isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
        revision: undefined,
      });
    } else {
      item.previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
        imageType: "REMOTE",
        remote: {
          url: hit.previewUrl,
        },
        desiredDimensions: requestedImageDimensions,
        isSquareImage: itemPreviewType === PreviewKind.SQUARE ? true : false,
        revision: undefined,
      });
    }
  }
  return item;
}

function getCarouselItemDetails(
  edge: any,
  list: List,
  index: number,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
): CarouselItemDetails {
  const item: any = edge.listItem.item;
  if (item.__typename === GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY || item.__typename === GRAPHQL_TYPE_EVENT) {
    return getEpisodeDetails(edge, list, index + 1);
  }
  return getGenericDetails(item, requestedImageDimensions);
}

/**
 * Returns a a UUID cleaned of special characters.
 *
 * @return {string} The cleaned UUID.
 */
export function getCleanUuid(): string {
  return _getNewUniqueId()
    .replace(/\+/g, (): string => {
      return getRandomCharacter();
    })
    .replace(/\//g, (): string => {
      return getRandomCharacter();
    })
    .replace(/-/g, (): string => {
      return getRandomCharacter();
    })
    .replace(/_/g, (): string => {
      return getRandomCharacter();
    });
}

/**
 * Returns a random character.
 *
 * @return {string} The random character.
 */
function getRandomCharacter(): string {
  return ALPHANUMERIC[Math.floor(Math.random() * ALPHANUMERIC.length)];
}

/**
 * Returns a valid html id
 *
 * @return {string} The cleaned id.
 */
export function getCleanHtmlId(): string {
  return _getNewUniqueId()
    .replace(/\d/g, (): string => {
      return getRandomAlphaCharacter();
    })
    .replace(/\+/g, (): string => {
      return getRandomAlphaCharacter();
    })
    .replace(/\//g, (): string => {
      return getRandomAlphaCharacter();
    })
    .replace(/-/g, (): string => {
      return getRandomAlphaCharacter();
    })
    .replace(/_/g, (): string => {
      return getRandomAlphaCharacter();
    });
}

/**
 * get current window location
 *
 * @returns {string} the clean window location
 */
export function getCleanWindowLocation(): string {
  let windowLocation = window.location.href;
  windowLocation = windowLocation.substring(
    0,
    windowLocation.indexOf("#") !== -1 ? windowLocation.indexOf("#") : undefined,
  );
  windowLocation = windowLocation.slice(-1) === "/" ? windowLocation : `${windowLocation}/`;
  return windowLocation;
}

/**
 * Returns a random character.
 *
 * @return {string} The random character.
 */
function getRandomAlphaCharacter(): string {
  return ALPHA[Math.floor(Math.random() * ALPHA.length)];
}

/**
 *
 * @param rawData
 * @param fileName
 */
export function blobToFile(rawData: Blob, fileName: string): File {
  const BLOB: any = rawData;
  // A Blob() is almost a File()
  // it's just missing the two properties below which we will add
  BLOB.lastModifiedDate = new Date();
  BLOB.name = fileName;
  // cast to a File() type
  return BLOB;
}

/**
 * Check if there is a stored mutationId and generate new one if not.
 */
export function getClientMutationId(): string {
  let clientMutationId: string = localStore.getItem("clientMutationId");

  if (!clientMutationId) {
    clientMutationId = _getNewUniqueId();
    localStore.setItem("clientMutationId", clientMutationId);
  }
  return clientMutationId;
}

/**
 * Returns the carousel item from a questionnaire.
 *
 * @param {Questionnaire} questionnaire The questionnaire.
 *
 * @returns {CarouselItem} The carousel item populated from the questionnaire.
 */
export function getDrivenNominateItem(
  questionnaire: Questionnaire,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
): CarouselItem {
  let details: CarouselItemDetails;
  let item: CarouselItem;
  const QUESTION: MultipleChoiceQuestion = questionnaire.questions[0];

  const choices = QUESTION.choices.map((choice) => {
    let c: any;

    if (choice !== null && choice !== undefined && choice.person !== undefined && choice.person !== null) {
      c = {
        id: choice.id,
        title: choice.person.fullName,
        image: ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: choice.person.previews,
            previewKind: PreviewKind.SQUARE,
            topLevelMedia: TopLevelMediaKind.IMAGE,
            isDefaultPreview: true,
            type: GRAPHQL_TYPE_PERSON,
          },
          desiredDimensions: requestedImageDimensions,
          revision: undefined,
        }),
      };
    }
    return c;
  });

  details = {
    choices,
    question: QUESTION.id,
    questionnaire: questionnaire.id,
  } as NominateTabDetails;

  item = {
    details,
    type: "Nominate",
    title: "Nominate the Next Celebrity Advisors on Driven!",
    previewImageUrl: {},
    landingPageUrl: undefined,
  };

  return item;
}

/**
 * Returns the details of an episode in a series as a carousel item.
 *
 * @param {OriginalContentStory} episode The episode.
 *
 * @param {List} show The show for which this is an episode.
 *
 * @param {number} episodeNumber The number of the episode.
 *
 * @returns {Episode} The carousel item created from the episode.
 */
export function getEpisodeDetails(edge: any, show: List, episodeNumber: number): Episode {
  const episode: any = edge.listItem.item;
  const video = episode.mediaItem ? getVideoLinksFromMediaItem(episode.mediaItem) : null;
  const youTubeVideo = episode.externalId ? getVideoLinksFromExternalId(episode.externalId) : null;
  let episodeShow: Show | null = null;
  if (edge && edge.listItem && edge.listItem.list) {
    const parentList = edge.listItem.list;
    const items = parentList.items
      ? parentList.items.edges.map((edge: any, index: number) => {
          return getCarouselItem(
            edge,
            parentList,
            index,
            {
              containerWidthRatio: 1.4,
              numberOfItems: 4,
            },
            PreviewKind.CAROUSEL,
            true,
          );
        })
      : [];

    const BADGES = parentList.badges
      ? parentList.badges.map((award: GraphQlBadge) => {
          const MEDIA_ASSERT = extractBadge(award, PreviewKind.BADGE_LIGHT);

          if (Types.isObject(MEDIA_ASSERT)) {
            return {
              link: award.link,
              imgUrl: ImageHelper.fetchOptimizedImageUrl({
                imageType: "MEDIA_ASSET",
                mediaAsset: {
                  files: MEDIA_ASSERT.files,
                  type: TopLevelMediaKind.IMAGE,
                },
                desiredDimensions: {
                  containerWidthRatio: 3 / 12,
                  numberOfItems: 4,
                },
                revision: parentList.revision,
              }),
            };
          }
        })
      : undefined;

    episodeShow = {
      items,
      id: parentList.id,
      title: parentList.name ? parentList.name : parentList.title,
      description: parentList.description,
      revision: parentList.revision,
      landingPageUrl: parentList.landingPageUrl,
      badges: BADGES,
      logo: ImageHelper.fetchOptimizedImageUrl({
        imageType: "PREVIEW",
        preview: {
          content: parentList.previews,
          previewKind: PreviewKind.LOGO,
          topLevelMedia: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: {
          containerWidthRatio: 1,
          numberOfItems: 2.2,
        },
        revision: undefined,
      }),
      backgroundImage: ImageHelper.fetchOptimizedImageUrl({
        imageType: "PREVIEW",
        preview: {
          content: parentList.previews,
          previewKind: PreviewKind.BACKGROUND,
          topLevelMedia: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: {
          containerWidthRatio: 1,
          numberOfItems: 1,
        },
        revision: parentList.revision,
      }),
    };
  }

  const episodeDetails: Episode = {
    video,
    youTubeVideo,
    id: episode.id,
    videoId: episode.id,
    episodeType:
      episode.origContentTags && episode.origContentTags.length && episode.origContentTags[0].label === "live-interview"
        ? "live-interview"
        : episode.__typename
        ? episode.__typename
        : null,
    episodeTitle: episode.title ? episode.title : episode.name,
    episodeSubtitle: episode.subTitle ? episode.subTitle : null,
    backgroundImage: episode.previews
      ? ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: episode.previews,
            previewKind: PreviewKind.BACKGROUND,
            topLevelMedia: TopLevelMediaKind.IMAGE,
          },
          desiredDimensions: { containerWidthRatio: 0.75, numberOfItems: 1 },
          revision: show.revision,
        })
      : null,
    episodeDescription: episode.description
      ? episode.description
      : episode.peopleEntities
      ? episode.peopleEntities.map((person) => {
          return person.person ? person.person.description : null;
        })
      : null,
    episodeDetails: [show.name, `Episode ${episodeNumber}`, mapMillisecondsToMinutes(episode.durationInSeconds)],
    show: episodeShow,
    landingPageUrl: episode.landingPageUrl ? episode.landingPageUrl : null,
    people: episode.peopleEntities
      ? episode.peopleEntities.map((personEntity: PersonEntity): {
          name: string;
          personId?: string;
          companyId: string;
          companyName?: string;
          companyUrl?: string;
          description?: string;
          preview: OptimizedImageUrls;
          responsibility: string;
          landingPageUrl: string;
        } => {
          return {
            name: personEntity.person ? personEntity.person.fullName : null,
            companyId: personEntity.entity ? personEntity.entity.id : null,
            personId: personEntity.person ? personEntity.person.id : null,
            description: personEntity.person ? personEntity.person.description : null,
            companyName: personEntity.entity ? personEntity.entity.name : null,
            companyUrl: personEntity.entity ? personEntity.entity.websiteUrl : null,

            preview: personEntity.person
              ? ImageHelper.fetchOptimizedImageUrl({
                  imageType: "PREVIEW",
                  preview: {
                    content: personEntity.person.previews,
                    previewKind: PreviewKind.SQUARE,
                    topLevelMedia: TopLevelMediaKind.IMAGE,
                  },
                  desiredDimensions: {
                    containerWidthRatio: 0.75,
                    numberOfItems: 1,
                  },
                  revision: show.revision ? show.revision : null,
                })
              : ImageHelper.fetchOptimizedImageUrl({
                  imageType: "DEFAULT",
                  default: {
                    previewType: PreviewKind.SQUARE,
                    mediaType: TopLevelMediaKind.IMAGE,
                    type: GRAPHQL_TYPE_PERSON,
                  },
                  desiredDimensions: {
                    containerWidthRatio: 1,
                    numberOfItems: 5,
                  },
                  isSquareImage: true,
                  revision: undefined,
                }),
            responsibility: personEntity.person ? personEntity.person.responsibility : null,
            landingPageUrl: personEntity.person ? personEntity.person.landingPageUrl : null,
          };
        })
      : [],
    companies: episode.peopleEntities
      ? episode.peopleEntities.map((personEntity: PersonEntity): {
          name: string;
          companyName?: string;
          companyUrl?: string;
          description?: string;
          companyId: string;
          preview: OptimizedImageUrls;
          landingPageUrl: string;
        } => {
          return {
            name: null,
            companyId: personEntity.entity ? personEntity.entity.id : null,
            description: personEntity.entity ? personEntity.entity.description : null,
            companyName: personEntity.entity ? personEntity.entity.name : null,
            companyUrl: personEntity.entity ? personEntity.entity.websiteUrl : null,
            preview: ImageHelper.fetchOptimizedImageUrl({
              imageType: "PREVIEW",
              preview: {
                content: personEntity.entity ? personEntity.entity.previews : null,
                previewKind: PreviewKind.SQUARE,
                topLevelMedia: TopLevelMediaKind.IMAGE,
              },
              desiredDimensions: {
                containerWidthRatio: 0.75,
                numberOfItems: 1,
              },
              revision: show.revision,
            }),
            landingPageUrl: personEntity.entity ? personEntity.entity.landingPageUrl : "",
          };
        })
      : [],
  };

  let details: Episode = episodeDetails;

  return details;
}

/**
 * get lists of followed entities/people in for Carousel.
 *
 * @export
 * @param {Follows} follows
 * @returns {{
 *   people?: CarouselItem[],
 *   entities?: CarouselItem[],
 * }}
 */
export function getFollowingItems(
  follows: Follows[],
  imageOptions: {
    requestedImageDimensions: {
      containerWidthRatio: number;
      numberOfItems: number;
    };
    isSquareImage: boolean;
  },
): {
  people?: CarouselItem[];
  entities?: CarouselItem[];
} {
  const followedItems: {
    people?: CarouselItem[];
    entities?: CarouselItem[];
  } = {
    people: [],
    entities: [],
  };
  if (follows !== undefined && follows !== null) {
    for (const f of follows) {
      if (f.__typename === GRAPHQL_TYPE_PERSON || "fullName" in f) {
        followedItems.people.push(
          getCarouselItem(f, null, 0, imageOptions.requestedImageDimensions, PreviewKind.SQUARE),
        );
      } else {
        followedItems.entities.push(
          getCarouselItem(f, null, 0, imageOptions.requestedImageDimensions, PreviewKind.SQUARE),
        );
      }
    }
  }
  return followedItems;
}

/**
 * Returns the header details of an Entity profile.
 *
 * @export
 * @param {Entity} item The Entity GraphQl Object.
 *
 * @returns {ProfileHeaderDetails} The profile header details.
 */
export function getEntityDetails(
  item: any,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
): ProfileHeaderDetails {
  // the reason why we have item as any is that the relay compiler fails when we use type assertion
  let preview: OptimizedImageUrls;
  preview =
    item.previews !== null || item.previews !== undefined
      ? ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: item.previews,
            previewKind: PreviewKind.LOGO,
            topLevelMedia: TopLevelMediaKind.IMAGE,
            isDefaultPreview: true,
            type: item.__typename,
          },
          desiredDimensions: requestedImageDimensions,
          isSquareImage: true,
          revision: item.revision,
        })
      : ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_ENTITY,
          },
          desiredDimensions: { containerWidthRatio: 1, numberOfItems: 5 },
          isSquareImage: true,
          revision: undefined,
        });

  if (!preview) {
    preview = ImageHelper.fetchOptimizedImageUrl({
      imageType: "PREVIEW",
      preview: {
        content: item.previews,
        previewKind: PreviewKind.SQUARE,
        topLevelMedia: TopLevelMediaKind.IMAGE,
        isDefaultPreview: true,
        type: item.__typename,
      },
      desiredDimensions: requestedImageDimensions,
      isSquareImage: true,
      revision: item.revision,
    });
  }
  const socialMediaProfiles =
    item.socialMediaProfiles &&
    item.socialMediaProfiles.reduce((acc, i) => {
      if (!i.platform) {
        return acc;
      }
      acc[i?.platform?.kind] = i.link;
      return acc;
    }, {});
  let avatarMediaAssetId: string = ImageHelper.getPreviewMediaAssetId(item.previews, PreviewKind.LOGO);
  if (!avatarMediaAssetId) {
    avatarMediaAssetId = ImageHelper.getPreviewMediaAssetId(item.previews, PreviewKind.SQUARE);
  }

  let bgMediaAsset: MediaAsset;
  item.previews.edges.map((media) => {
    if (media.preview.kind === PreviewKind.BACKGROUND) {
      bgMediaAsset = media.preview.mediaAsset;
    }
  });

  let investmentsBy = Array.isArray(item.investmentsBy) && item.investmentsBy[0];
  if (isEmpty(investmentsBy)) {
    investmentsBy = {
      fundingRound: null,
      amount: null,
      deckUrl: null,
      link: null,
    };
  }
  let profileHeader: ProfileHeaderDetails;
  profileHeader = {
    investmentsBy,
    activities: Array.isArray(item.activities) ? item.activities?.map((a: any) => a.kind) : [],
    opportunities: item.opportunities,
    socialMediaProfiles,
    type: item.__typename,
    location: getLocation(item),
    websiteUrl: item.websiteUrl,
    name: item.name,
    follows: item.follows,
    title: item.dba,
    description: item.description,
    tagLine: item.tagLine,
    landingPageUrl: item.landingPageUrl,
    profileImgUrl: preview,
    profileMediaAssetId: avatarMediaAssetId,
    industryId: item.industry ? item.industry.id : undefined,
    industry: item.industry ? item.industry : undefined,
    founded: item.founded ? new Date(item.founded).toISOString().split("T")[0] : "",
    stage: item.fundingRound,
    fundraisingStatus: item.isFundraising,
    noOfEmployees: item.noOfEmployees ? item.noOfEmployees : undefined,
    hasPortfolio: item.hasPortfolio,
    portfolioMarketCap: item.portfolioMarketCap,
    headquarters: item.headquarters && item.headquarters.location ? item.headquarters.location.label : undefined,
    noOfPortfolioCompanies: item.noOfPortfolioCompanies ? item.noOfPortfolioCompanies : undefined,

    backgroundImgUrl: ImageHelper.fetchOptimizedImageUrl({
      imageType: "PREVIEW",
      preview: {
        content: item.previews,
        previewKind: PreviewKind.BACKGROUND,
        topLevelMedia: TopLevelMediaKind.IMAGE,
        isDefaultPreview: true,
        type: item.__typename,
      },
      desiredDimensions: {
        containerWidthRatio: 1,
        numberOfItems: 1,
      },
      revision: item.revision,
    }),
    backgroundMediaAsset: bgMediaAsset,
    isFeedBaseEntity: item.isFeedBaseEntity,
  };

  return profileHeader;
}
export const getEntityHeaderDetails = getEntityDetails;
/**
 * Returns the name of an inspirer from a GraphQL inspirer item.
 *
 * @param {*} inspirer The original inspirer.
 *
 * @returns {string} The name of the inspirer.
 */
function getInspirerName(inspirer: any): string {
  return inspirer.__typename === GRAPHQL_TYPE_ENTITY ? inspirer.name : inspirer.fullName;
}

/**
 * Return a collection of grid items created from a collection of investments.
 *
 * @export
 * @param {Array<InvestmentByPerson>} investments The collection of investments.
 *
 * @returns {Array<GridViewItem>} The collection of grid items created from the investments.
 */
export function getItemsFromInvestments(
  investments: (InvestmentByPerson | Investment)[],
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
): GridViewItem[] {
  let items: GridViewItem[];

  items = investments.map(
    (investment): GridViewItem => {
      if (investment.investee !== undefined && investment.investee !== null) {
        return {
          type: investment.investee.__typename,
          id: investment.investee.id,
          description: investment.investee.description,
          previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
            imageType: "PREVIEW",
            preview: {
              content: investment.investee.previews,
              previewKind: PreviewKind.SQUARE,
              topLevelMedia: TopLevelMediaKind.IMAGE,
              isDefaultPreview: true,
              type: investment.__typename,
            },
            desiredDimensions: requestedImageDimensions,
            revision: investment.revision,
          }),
          title: investment.investee.name,
          landingPageUrl: investment.investee.landingPageUrl,
          websiteUrl: investment.investee.websiteUrl,
        };
      }
      if (investment.entity !== undefined && investment.entity !== null) {
        return {
          type: investment.entity.__typename,
          id: investment.entity.id,
          description: investment.investee.description,
          previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
            imageType: "PREVIEW",
            preview: {
              content: investment.entity.previews,
              previewKind: PreviewKind.SQUARE,
              topLevelMedia: TopLevelMediaKind.IMAGE,
              isDefaultPreview: true,
              type: investment.__typename,
            },
            desiredDimensions: requestedImageDimensions,
            revision: investment.revision,
          }),
          title: investment.entity.name,
          landingPageUrl: investment.entity.landingPageUrl,
          websiteUrl: investment.entity.websiteUrl,
        };
      }
    },
  );
  return items;
}

/**
 * Returns a collection of grid items from an experience GraphQL connection.
 *
 * @param {Connection<string, Position>} experience A person's experience.
 *
 * @returns {Array<GridViewItem>} The collection of grid items created from the experiences.
 */
export function getItemsFromExperiences(
  experience: any,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
): GridViewItem[] {
  let items: GridViewItem[] = [];

  if (experience.edges !== undefined && experience.edges !== null && experience.edges.length > 0) {
    for (const EDGE of experience.edges) {
      if (EDGE !== undefined && EDGE !== null && EDGE.position) {
        items.push({
          type: EDGE.position.__typename,
          description: EDGE.position.entity ? EDGE.position.entity.description : undefined,

          previewImageUrl: EDGE.position.entity
            ? ImageHelper.fetchOptimizedImageUrl({
                imageType: "PREVIEW",
                preview: {
                  content: EDGE.position.entity.previews,
                  previewKind: PreviewKind.SQUARE,
                  topLevelMedia: TopLevelMediaKind.IMAGE,
                  isDefaultPreview: true,
                  type: EDGE.position.entity.__typename,
                },
                desiredDimensions: requestedImageDimensions,
                revision: undefined,
              })
            : undefined,
          id: EDGE.position.id,
          title: EDGE.position.entity ? EDGE.position.entity.name : undefined,
          landingPageUrl: EDGE.position.entity ? EDGE.position.entity.landingPageUrl : undefined,
          websiteUrl: EDGE.position.entity ? EDGE.position.entity.websiteUrl : undefined,
        });
      }
    }
  } else {
    items = [];
  }
  return items;
}

/**
 * Returns the collection of grid items from a collection of inspirations.
 *
 * @param {Array<Inspiration>} inspirations The collection of inspirations.
 *
 * @returns {Array<GridViewItem>} The collection of grid items.
 */
export function getItemFromInspirations(
  inspirations: Inspiration[],
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
): GridViewItem[] {
  let items: GridViewItem[];

  items = inspirations.map(
    (inspiration: Inspiration): GridViewItem => {
      return {
        type: inspiration.inspirer.__typename,
        description: inspiration.inspirer.description,

        previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: inspiration.inspirer.previews,
            previewKind: PreviewKind.SQUARE,
            topLevelMedia: TopLevelMediaKind.IMAGE,
            isDefaultPreview: true,
            type: inspiration.__typename,
          },
          desiredDimensions: requestedImageDimensions,
          revision: undefined,
        }),
        id: inspiration.inspirer.id,
        title: getInspirerName(inspiration.inspirer),
        landingPageUrl: inspiration.inspirer.landingPageUrl,
        websiteUrl: inspiration.inspirer.websiteUrl,
      };
    },
  );
  return items;
}



/**
 * Return Person profile header details.
 *
 * @export
 * @param {Person} item The graphQl Person Object.
 *
 * @returns {ProfileHeaderDetails} The profile header details.
 */
export function getPersonDetails(
  item: any,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
): ProfileHeaderDetails {
  // the reason why we have item as any is that the relay compiler fails when we use type assertion
  let profileHeader: ProfileHeaderDetails;
  const avatarMediaAssetId: string = ImageHelper.getPreviewMediaAssetId(item.previews, PreviewKind.HEADSHOT);
  let profileImgUrl: OptimizedImageUrls;
  profileImgUrl =
    item.preview !== null || item.previews !== undefined
      ? ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: item.previews,
            previewKind: PreviewKind.HEADSHOT,
            topLevelMedia: TopLevelMediaKind.IMAGE,
            isDefaultPreview: true,
            type: GRAPHQL_TYPE_PERSON,
          },
          isSquareImage: true,
          desiredDimensions: requestedImageDimensions,
          revision: item.revision,
        })
      : ImageHelper.fetchOptimizedImageUrl({
          imageType: "DEFAULT",
          default: {
            previewType: PreviewKind.SQUARE,
            mediaType: TopLevelMediaKind.IMAGE,
            type: GRAPHQL_TYPE_PERSON,
          },
          desiredDimensions: { containerWidthRatio: 1, numberOfItems: 5 },
          isSquareImage: true,
          revision: undefined,
        });
  let bgMediaAsset: MediaAsset;

  if (item && item.previews) {
    item.previews.edges.map((media) => {
      if (media.preview.kind === PreviewKind.BACKGROUND) {
        bgMediaAsset = media.preview.mediaAsset;
      }
    });
  }
  const socialMediaProfiles =
    item?.socialMediaProfiles &&
    item?.socialMediaProfiles.reduce((acc, i) => {
      if (!i.platform) {
        return acc;
      }
      acc[i?.platform?.kind] = i.link;
      return acc;
    }, {});

  profileHeader = {
    profileImgUrl,
    socialMediaProfiles,
    location: getLocation(item),
    name: item.fullName,
    title: item.responsibility,
    type: item.__typename,
    follows: item.follows,
    description: item.description,
    landingPageUrl: item.landingPageUrl,
    websiteUrl: item.websiteUrl,
    profileMediaAssetId: avatarMediaAssetId,
    backgroundImgUrl: ImageHelper.fetchOptimizedImageUrl({
      imageType: "PREVIEW",
      preview: {
        content: item.previews,
        previewKind: PreviewKind.BACKGROUND,
        topLevelMedia: TopLevelMediaKind.IMAGE,
        isDefaultPreview: true,
        type: GRAPHQL_TYPE_PERSON,
      },
      desiredDimensions: {
        containerWidthRatio: 1,
        numberOfItems: 1,
      },
      revision: item.revision,
    }),
    backgroundMediaAsset: bgMediaAsset,
  };
  return profileHeader;
}

export const getPersonHeaderDetails = getPersonDetails;

/**
 * Returns the poster details as a carousel item.
 *
 * @export
 * @param {*} poster The poster.
 *
 * @returns {GeneralItemDetails} The poster details as a carousel item.
 */
export function getGenericDetails(
  item: any,
  requestedImageDimensions: {
    containerWidthRatio: number;
    numberOfItems: number;
  },
): GeneralItemDetails {
  let video: string = "";
  // if item link is to outside youtube video >> cut of the video ID
  if (item.link && item.link.includes("youtube.com")) {
    video = item.link.substring(item.link.indexOf("v=") + 2);
  } else if (item.link && item.link.includes("youtu.be")) {
    video = item.link.substring(item.link.indexOf("youtu.be") + 9);
  }
  const details: GeneralItemDetails = {
    id: item.id,
    landingPageUrl: item.permalink ? item.permalink : undefined,
    people: item.peopleEntities
      ? item.peopleEntities.map((personEntity: PersonEntity): {
          name: string;
          personId?: string;
          companyId: string;
          companyName?: string;
          companyUrl?: string;
          description?: string;
          preview: OptimizedImageUrls;
          responsibility: string;
          landingPageUrl: string;
        } => {
          return {
            name: personEntity.person ? personEntity.person.fullName : null,
            companyId: personEntity.entity ? personEntity.entity.id : null,
            personId: personEntity.person ? personEntity.person.id : null,
            description: personEntity.person ? personEntity.person.description : null,
            companyName: personEntity.entity ? personEntity.entity.name : null,
            companyUrl: personEntity.entity ? personEntity.entity.websiteUrl : null,

            preview: personEntity.person
              ? ImageHelper.fetchOptimizedImageUrl({
                  imageType: "PREVIEW",
                  preview: {
                    content: personEntity.person.previews,
                    previewKind: PreviewKind.SQUARE,
                    topLevelMedia: TopLevelMediaKind.IMAGE,
                  },
                  desiredDimensions: {
                    containerWidthRatio: 0.75,
                    numberOfItems: 1,
                  },
                  revision: undefined,
                })
              : ImageHelper.fetchOptimizedImageUrl({
                  imageType: "DEFAULT",
                  default: {
                    previewType: PreviewKind.SQUARE,
                    mediaType: TopLevelMediaKind.IMAGE,
                    type: GRAPHQL_TYPE_PERSON,
                  },
                  desiredDimensions: {
                    containerWidthRatio: 1,
                    numberOfItems: 5,
                  },
                  isSquareImage: true,
                  revision: undefined,
                }),
            responsibility: personEntity.person ? personEntity.person.responsibility : null,
            landingPageUrl: personEntity.person ? personEntity.person.landingPageUrl : null,
          };
        })
      : undefined,
    companies: item.peopleEntities
      ? item.peopleEntities.map((personEntity: PersonEntity): {
          name: string;
          companyName?: string;
          companyUrl?: string;
          description?: string;
          companyId: string;
          preview: OptimizedImageUrls;
          landingPageUrl: string;
        } => {
          return {
            name: null,
            companyId: personEntity.entity ? personEntity.entity.id : null,
            description: personEntity.entity ? personEntity.entity.description : null,
            companyName: personEntity.entity ? personEntity.entity.name : null,
            companyUrl: personEntity.entity ? personEntity.entity.websiteUrl : null,
            preview: ImageHelper.fetchOptimizedImageUrl({
              imageType: "PREVIEW",
              preview: {
                content: personEntity.entity ? personEntity.entity.previews : null,
                previewKind: PreviewKind.SQUARE,
                topLevelMedia: TopLevelMediaKind.IMAGE,
              },
              desiredDimensions: {
                containerWidthRatio: 0.75,
                numberOfItems: 1,
              },
              revision: undefined,
            }),
            landingPageUrl: personEntity.entity ? personEntity.entity.landingPageUrl : "",
          };
        })
      : undefined,
    backgroundImage: item.previews
      ? ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: item.previews,
            previewKind: PreviewKind.BACKGROUND,
            topLevelMedia: TopLevelMediaKind.IMAGE,
          },
          desiredDimensions: { containerWidthRatio: 0.75, numberOfItems: 1 },
          revision: item.revision,
        })
      : null,
    logo:
      item.previews !== undefined && item.previews !== null
        ? ImageHelper.fetchOptimizedImageUrl({
            imageType: "PREVIEW",
            preview: {
              content: item.previews,
              previewKind: PreviewKind.LOGO,
              topLevelMedia: TopLevelMediaKind.IMAGE,
            },
            desiredDimensions: requestedImageDimensions,
            revision: item.revision,
          })
        : null,
    title: item.title ? item.title : item.label,
    description: item.description,
    youTubeVideo: video ? video : null,
  };
  return details;
}

/**
 * open the actionModal from the jQuery.
 *
 * @export
 */
export function showActionModal() {
  const actionModal = document.getElementById(ACTION_MODAL_ID);
  if (actionModal !== undefined && actionModal !== null) {
    import("../authentication/cognito-client").then((module) => {
      $(`#${ACTION_MODAL_ID}`).modal({
        keyboard: false,
        backdrop: "static",
        show: true,
      });
      return module.CognitoClient.signOut(true);
    });
  }
}

/**
 *
 * @param ExternalId
 */
export function getVideoLinksFromExternalId(externalId: string) {
  let videoUrls: string;
  if (externalId !== null && externalId !== undefined) {
    videoUrls = externalId;
  } else {
    null;
  }
  return videoUrls;
}

/**
 * Exports videos URLs from a given media item
 *
 * @param {MediaAsset} mediaItem The media item.
 *
 * @returns {Array<string>} The video links in a media ite,.
 */
export function getVideoLinksFromMediaItem(
  mediaItem: MediaAsset,
): {
  src: string;
  name: string;
}[] {
  let videoUrls: {
    src: string;
    name: string;
  }[] = [];

  if (mediaItem !== null && mediaItem !== undefined) {
    videoUrls = mediaItem.files.map((file) => {
      if (file !== null && file !== undefined) {
        return {
          src: ImageHelper.fetchOptimizedImageUrl({
            imageType: "MEDIA_ASSET",
            mediaAsset: { files: [file], type: TopLevelMediaKind.VIDEO },
            desiredDimensions: { containerWidthRatio: 1, numberOfItems: 1 },
            revision: file.revision,
          }).originalUrl,
          name: `${file.widthInPixels}`,
        };
      }
      return null;
    });
  }
  videoUrls.reverse();
  return videoUrls;
}

/**
 *
 *
 * @export
 * @param {Badge} badges
 * @param {PreviewKind} [target=PreviewKind.A_ROLE]
 * @returns {MediaAsset}
 */
export function extractBadge(badges: GraphQlBadge, target: PreviewKind = PreviewKind.BADGE_DARK): MediaAsset {
  let targetBadge: MediaAsset;

  if (badges && badges.previews) {
    const TARGET_PREVIEW = badges.previews.edges.filter((preview: any) => {
      return preview.preview.kind === target;
    });

    if (TARGET_PREVIEW && TARGET_PREVIEW[0]) {
      targetBadge = (TARGET_PREVIEW as any)[0].preview.mediaAsset;
    }
  }

  return targetBadge;
}

export function facebookPixelTraceEvent(event: any, context: any) {
  if (process.env.ENABLE_ANALYTICS !== undefined) {
    const FACEBOOK_PIXEL = require("react-facebook-pixel").default;

    FACEBOOK_PIXEL.track(event, context);
  }
}

export function snowplowAnalytics(): Promise<typeof analyticsSnowplow> {
  if (process.env.ENABLE_ANALYTICS !== undefined) {
    const SNOWPLOW = analyticsSnowplow;
    return Promise.resolve(SNOWPLOW);
  }
  return Promise.reject();
}

/**
 * Function used to validate if provided url is valid url
 *
 * @export
 * @param {string} targetUrl
 * @returns {Promise<any>}
 */
export function isValidUrl(targetUrl: string): Promise<boolean> {
  let promise: Promise<any> = Promise.resolve(false);

  promise = promise.then(
    (): Promise<Boolean> => {
      if (targetUrl === undefined || targetUrl === null) {
        throw new Error("No url provided");
      }

      return Promise.resolve(!!VALID_URL_PATTERN.test(targetUrl));
    },
  );

  return promise;
}

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const gaEvent = (category: any, action: string, label: any, nonInteraction: boolean, value?: number) => {
  ReactGA.event({
    category,
    action,
    label,
    nonInteraction,
    value,
  });
};

/**
 *
 *
 * @export
 * @returns {boolean}
 */
export function isIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function validateEmail(email: any) {
  return isValidEmail(email);
}

/**
 *
 *
 * @export
 * @returns {boolean}
 */
export function isYoutube(url: string): boolean {
  const YOUTUBE_REG = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;

  try {
    return YOUTUBE_REG.test(url);
  } catch (e) {
    return false;
  }
}

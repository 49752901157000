/**
 * @author W. Alex Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2018-03-30
 * @description The base class for an error returned by the GraphQl server.
 * @filename error.ts
 */
import { Error as BaseError } from "../graphql/error";
import { getNewUniqueId as _getNewUniqueId } from "@radivision/common";

/**
 * The base class for an error returned by the GraphQl server.
 *
 * @template D
 * @implements {BaseError<D>}
 */
export abstract class Error<D extends Date | string = string>
  implements BaseError<D> {
  /**
   * The date and time when the error was created.
   *
   * @template D
   * @type {D}
   */
  private readonly _created: D;

  /**
   * The HTTP status value of the error.
   *
   * @type {number}
   */
  private readonly _httpStatus: number;

  /**
   * The HTTP status description of the error.
   *
   * @type {number}
   */
  private readonly _httpStatusDescription: string;

  /**
   * The ID of the error.
   *
   * @type {string}
   */
  private readonly _id: string;

  /**
   * The location where the error occurred.
   *
   * @type {string}
   */
  private readonly _location: string;

  /**
   * A parametric constructor.
   *
   * @param {string} location The location where the error occured.
   *
   * @param {string} id The ID of the error.
   *
   * @param {number} httpStatus The HTTP status code value of the error.
   *
   * @param {string} httpStatusDescription The HTTP status description of the error.
   */
  protected constructor(
    location: string,
    id: string = null,
    httpStatus: number = null,
    httpStatusDescription: string = null
  ) {
    this._id = id === null ? _getNewUniqueId() : id;
    this._created = <D>new Date().toISOString();
    this._location = location;
    this._httpStatus = httpStatus === null ? 500 : httpStatus;
    this._httpStatusDescription =
      httpStatusDescription === null
        ? "Internal Server Error"
        : httpStatusDescription;
  }

  /**
   * The date and time when the error was created.
   *
   * @template D
   * @type {D}
   */
  public get created(): D {
    return this._created;
  }

  /**
   * The HTTP status value of the error.
   *
   * @type {number}
   */
  public get httpStatus(): number {
    return this._httpStatus;
  }

  /**
   * The HTTP status description of the error.
   *
   * @type {string}
   */
  public get httpStatusDescription(): string {
    return this._httpStatusDescription;
  }

  /**
   * The ID of the error.
   *
   * @type {string}
   */
  public get id(): string {
    return this._id;
  }

  /**
   * The location where the error occurred.
   *
   * @type {string}
   */
  public get location(): string {
    return this._location;
  }

  /**
   * A flag which is true if the error is an API error.
   *
   * @type {string}
   */
  public get isRadivisionApiError(): boolean {
    return true;
  }

  /**
   * The type of the error - a general classification of the error.
   *
   * @type {string}
   */
  abstract get type(): string;
}

import { select, put } from "redux-saga/effects";
import { ProfileModuleState } from "../types";
import { GRAPHQL_TYPE_ENTITY, GRAPHQL_TYPE_PERSON } from "@radivision/graphql";
import isEmpty from "lodash.isempty";
import { isValidUrl } from "../../../../../utilities/is-valid-url";
import { TOAST_ALERT } from "../../../../../redux/toast/constants";
import { setToastMessage } from "../../../../../redux/actions";
import { socialPlatforms } from "../../../../../constants/social-platforms";
import { AppState } from "../../../../../redux";
import { isValidEmail } from "../../../../../utilities/is-valid-email";

export function* validateProfileForm() {
  const state = yield select((state: ProfileModuleState) => state.profile);
  const subjectId = state.id;
  const subject = yield select((state: AppState) => state.cache.store[subjectId]);

  const isCompany = subject.__typename === GRAPHQL_TYPE_ENTITY;
  const isPerson = subject.__typename === GRAPHQL_TYPE_PERSON;

  const isInvalidWebsiteUrl = isCompany && (isEmpty(state.websiteUrl) || !isValidUrl(state.websiteUrl));
  if (isInvalidWebsiteUrl) {
    yield put(setToastMessage({ msg: "Please enter a valid website URL", type: TOAST_ALERT }));
    return false;
  }

  const invalidSocialLink =
    state.socialMediaProfiles &&
    Object.keys(state.socialMediaProfiles).find((k) => {
      const platform = socialPlatforms[k];
      const isValid = state.socialMediaProfiles[k] ? platform.regexp[0].test(state.socialMediaProfiles[k]) : true;
      if (!isValid) {
        return true;
      }
      return false;
    });
  if (invalidSocialLink) {
    yield put(
      setToastMessage({
        msg: `Please enter a valid ${socialPlatforms[invalidSocialLink].name} link`,
        type: TOAST_ALERT,
      }),
    );
    return;
  }

  if (isCompany && isEmpty(state.name)) {
    yield put(setToastMessage({ msg: "Please enter company name", type: TOAST_ALERT }));
    return false;
  }

  const hasAtLeastTwoNames = /\w +\w/.test(state.fullName);
  if (isPerson && (isEmpty(state.fullName) || !hasAtLeastTwoNames)) {
    yield put(setToastMessage({ msg: "Please enter your full name", type: TOAST_ALERT }));
    return false;
  }

  if (isCompany && isEmpty(state.industry)) {
    yield put(setToastMessage({ msg: "Please select company industry", type: TOAST_ALERT }));
    return false;
  }

  if (isCompany && isEmpty(state.founded)) {
    yield put(setToastMessage({ msg: "Please select company founded date", type: TOAST_ALERT }));
    return false;
  }

  const isFutureDate = new Date(state.founded) > new Date();
  if (isCompany && isFutureDate) {
    yield put(setToastMessage({ msg: "Company founded date cannot be in the future", type: TOAST_ALERT }));
    return false;
  }

  const isOldDate = new Date(state.founded) < new Date("1800-01-01");
  if (isCompany && isOldDate) {
    yield put(setToastMessage({ msg: "Please enter a valid company founded date ", type: TOAST_ALERT }));
    return false;
  }

  if (isCompany && isEmpty(state.noOfEmployees)) {
    yield put(setToastMessage({ msg: "Please select company size", type: TOAST_ALERT }));
    return false;
  }

  if (isCompany && !(Array.isArray(state.activities) && state.activities.length > 0)) {
    yield put(setToastMessage({ msg: "Please select company type", type: TOAST_ALERT }));
    return false;
  }

  if (isCompany && isEmpty(state.profileImage) && isEmpty(state.profileImageUrl)) {
    yield put(setToastMessage({ msg: "Please upload your company logo", type: TOAST_ALERT }));
    return false;
  }

  if (isCompany && state.tagLine && state.tagLine.length > 120) {
    yield put(setToastMessage({ msg: "company's tagline should not exceed 120 characters", type: TOAST_ALERT }));
    return false;
  }

  if (isCompany) {
    const productLink = state.opportunities?.productAndServices?.link;
    const isValidProductLink = isEmpty(productLink) || isValidEmail(productLink) || isValidUrl(productLink);
    if (!isValidProductLink) {
      yield put(
        setToastMessage({
          msg: "'Product and Services' link should be valid Url or an email address",
          type: TOAST_ALERT,
        }),
      );
      return false;
    }
    const careersLink = state.opportunities?.careers?.link;
    const isValidCareersLink = isEmpty(careersLink) || isValidEmail(careersLink) || isValidUrl(careersLink);
    if (!isValidCareersLink) {
      yield put(
        setToastMessage({
          msg: "Careers link should be valid Url or an email address",
          type: TOAST_ALERT,
        }),
      );
      return false;
    }
    const collaborationLink = state.opportunities?.collaboration?.link;
    const isValidCollaborationLink =
      isEmpty(collaborationLink) || isValidEmail(collaborationLink) || isValidUrl(collaborationLink);
    if (!isValidCollaborationLink) {
      yield put(
        setToastMessage({
          msg: "Collaboration link should be valid Url or an email address",
          type: TOAST_ALERT,
        }),
      );
      return false;
    }
  }
  return true;
}

/**
 * @author Muhammad Omran
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-04-11
 * @description Implementation of the Reusable Radivision Button Component
 * @filename rdv-button.tsx
 */
import React from "react";
import { Spinner } from "../user-widgets/spinner";

/**
 * The props of Radivision Button component
 *
 * @interface RdvButton
 */
export interface RdvButtonProps {
  /**
   * Disabled state of the button
   *
   * @type {boolean}
   */
  disabled?: boolean;

  /**
   * extra classes if needed
   *
   * @type {string}
   */
  extraClassName?: string;

  /**
   * the form this button belongs to
   *
   * @type {string}
   */
  form?: string;

  /**
   * is Modal dismiss kinda button
   *
   * @type {boolean}
   */
  isCancel?: boolean;

  /**
   * flag to check loading state
   *
   * @type {boolean}
   */
  isLoading?: boolean;

  /**
   * to configure Button style, if false: normal state Blueish, true: white with radivision blue border
   *
   * @type {boolean}
   */
  isOutline?: boolean;

  /**
   * the id of the modal which to toggle
   *
   * @type {string}
   */
  modalId?: string;

  /**
   * The on click function handler
   *
   * @type {any}
   */
  onClick?: any;

  /**
   * Embeded styles if needed
   *
   * @type {any}
   */
  style?: any;

  /**
   * the text that appears on the button,if no text display the children
   *
   * @type {string}
   */
  text?: string;

  /**
   * if the button toggles a modal instead of onClick function handler
   *
   * @type {boolean}
   */
  toggle?: boolean;

  /**
   * the type of the button if it submits a form
   *
   * @type {string}
   */
  type?: string;
}

/**
 * A React component that renders the Radivision Standard Button Component
 *
 * @extends {React.Component<RdvButtonProps, {}>}
 */
export class RdvButton extends React.Component<RdvButtonProps, {}> {
  /**
   * Constructor
   */
  constructor(props: any) {
    super(props);
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    return (
      <button
        form={this.props.form ? this.props.form : undefined}
        type={this.props.type ? "submit" : "button"}
        className={`btn btn-radivision text-center${
          this.props.isOutline ? " outline" : ""
        }${this.props.extraClassName ? ` ${this.props.extraClassName}` : ""}${this.props.isCancel ? " cancel-btn" : ""}`}
        onClick={this.props.onClick}
        data-toggle={this.props.toggle ? "modal" : undefined}
        data-target={this.props.toggle ? this.props.modalId : undefined}
        data-dismiss={this.props.isCancel ? "modal" : undefined}
        disabled={this.props.disabled}
        style={this.props.style}
      >
        {this.props.isLoading ? (
          <Spinner />
        ) : this.props.text ? (
          this.props.text
        ) : (
          this.props.children
        )}
        <div className="hoverOverlay" />
      </button>
    );
  }
}

import * as React from "react";
import { connect } from "react-redux";
import ProgressiveImage from "react-progressive-image";
import * as Actions from "../../redux/actions";
import { AppState } from "../../redux";
import { GRAPHQL_TYPE_FIXED_LIST, GRAPHQL_TYPE_POSTER, PreviewKind, TopLevelMediaKind } from "@radivision/graphql";
import AddToSavedButton from "../page-framework/add-to-saved-button";
import { ItemCast } from "./item-cast";
import { Preview } from "../../utilities/preview";
import { ImageHelper } from "../../utilities/image-helper";

interface MapStateProps {
  itemId: string;
  item: any;
  list: any;
}

export type Props = MapStateProps;

export const Component = (props: Props) => {
  const { item, itemId, list, listId } = props;
  const isSaveable = item && item.__typename !== GRAPHQL_TYPE_FIXED_LIST && item.__typename !== GRAPHQL_TYPE_POSTER;
  const isOriginalShow = itemId === listId;
  const title = isOriginalShow ? list?.list?.title : item?.title;
  const description = isOriginalShow ? list?.list?.description : item?.description;
  // const logo = list?.previews ? Preview.getUrlFromPreview(list.previews, PreviewKind.LOGO) : null;
  const logo = list?.list?.previews
    ? ImageHelper.fetchOptimizedImageUrl({
        imageType: "PREVIEW",
        preview: {
          content: list?.list?.previews,
          previewKind: PreviewKind.LOGO,
          topLevelMedia: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: { containerWidthRatio: 1, numberOfItems: 2.2 },
        revision: undefined,
      })
    : null;

  if (item && item?.id !== itemId) return null;
  return (
    <div className="details-panel-info">
      <div className="details-panel-section details-panel-meta">
        <div className="details-panel-description">
          {isSaveable && (
            <div className="item-actions">
              <AddToSavedButton item={item} />
            </div>
          )}
          <h1 className="video-title">{title}</h1>
          <p className="video-description">{description}</p>
        </div>
        <div className="details-panel-show-logo">
          {logo ? (
            <ProgressiveImage src={logo.requestedResolutionUrl} placeholder={logo.requestedResolutionUrl}>
              {(src: any, LOADING: any, srcSetData: any) => (
                <img loading="lazy" alt={`${title} logo`} className="show-logo-img" src={src} />
              )}
            </ProgressiveImage>
          ) : null}
        </div>
      </div>
      <ItemCast />
    </div>
  );
};

const mapState = (state: AppState) => {
  const { item } = state.detailsPanel;
  const { currentHash } = state.app;
  const [_title, itemId, listId] = currentHash ? String(currentHash).split("/") : [];
  const list = listId ? state.cache.store[listId] : false;
  const storyItem = itemId ? state.cache.store[itemId] : false;
  const device = state.app.device;
  return {
    device,
    itemId,
    listId,
    list: list?.list,
    item: item ? item : storyItem?.node,
  };
};

const mapDispatch = {
  createPostSet: Actions.createPostSet,
};

export const ItemInfo = connect(mapState, mapDispatch)(Component);

/**
 * @author William Alexander Livesley
 * @date   2018-05-28
 * @description Declaration of the Currency interface.
 * @filename currency.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the GraphQL Currency type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_CURRENCY: string = "Currency";

/**
 * A currency type.
 *
 * @export
 * @extends {Node<D>}
 * @interface Currency
 * @template D
 */
export interface Currency<D extends Date | string = string> extends Node<D> {
  /**
   * The ISO 4217 alphabetic code.
   *
   * @type {string}
   */
  code?: string;

  /**
   * A description of the currency
   *
   * @type {string}
   */
  description?: string;
}

/**
 * @author W. Alex Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2018-03-30
 * @description A class containing the error returned from a request to a GraphQL server where the request results in 
 * an unexpected error.
 * @filename validation-error.ts
 */
import { Error as BaseError} from "./error";

/**
 * The error returned from a request to a GraphQL server where the request results in an unexpected error.
 *
 * @extends {BaseError}
 */
export class UnexpectedError extends BaseError {
  /**
   * The type ID of the error.
   *
   * @type {string}
   */
  public static readonly TYPE_ID: string = "pJWfvoIiOUeySODEtGU45A";

  /**
   * A parametric constructor.
   *
   * @param {string} location The location where the error was thrown.
   *
   * @param {Error} _error The details of the error.
   *
   * @param {string} id The ID of the error.
   */
  public constructor(
    location:string,
    private _error: Error,
    id: string = null
  ) {
    super(location, id, 500, "Internal Server Error");
  }

  /**
   * Returns the details of the error.
   *
   * @type {Error}
   */
  public error(): Error {
    return this._error;
  }

  /**
   * The type of the error - a general classification of the error.
   *
   * @type {string}
   */
  public get type(): string {
    return UnexpectedError.TYPE_ID;
  }
}

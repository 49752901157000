/**
 * @author Ahmed Serag
 * @date   2019-01-21
 * @description Declaration of the Badge interface.
 * @filename badge.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";
import { MediaAsset } from "./media-asset";
import { Preview } from "./preview";
import { Connection } from "./connection";

export const GRAPHQL_TYPE_BADGE: string = "Badge";

/**
 * A badge with a(n optional) clickable link.
 *
 * @export
 * @interface Badge
 * @extends {Node<D>}
 * @template D
 */
export interface Badge <D extends Date | string = string> extends Node<D> {

  /**
   * The URL link for the image used for the badge.
   *
   * @type {string}
   * @memberof Badge
   */
  imageLink?: string;

  /**
   * A label for the badge.
   *
   * @type {string}
   * @memberof Badge
   */
  label?: string;
  
  /**
   * The URL link for the badge.
   *
   * @type {string}
   * @memberof Badge
   */
  link?: string;

  /**
   * The media asset used as the badge.
   *
   * @type {MediaAsset}
   * @memberof Badge
   */
  mediaAsset?: MediaAsset;

  /**
   * A collection of media assets used as previews for the Badge. 
   * Only one media asset can act as a given type of preview
   *
   * @template D
   * @type {Connection<D, Preview>}
   * @memberof Badge
   */
  previews?: Connection<D, Preview>;
}
/**
 * @author Ahmed Khalil
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-05-14
 * @description Implementation of reusable toaster react component
 * @filename toaster.tsx
 */
import React from "react";
import { SVG } from "../../component-configuration/svgs";
const isEqual = require("lodash.isequal");

/**
 *
 *
 * @interface ToasterProps
 */
interface ToasterProps {
  /**
   * flag to set the toaster behaviour to be auto hidden or not
   *
   * @type {boolean}
   */
  autoHide?: boolean;

  /**
   * The header text of the toaster
   *
   * @type {string}
   * @memberof ToasterProps
   */
  header?: string;

  /**
   * The text message of the toaster
   *
   * @type {string}
   * @memberof ToasterProps
   */
  message: string;

  /**
   * to do extra something when toaster is closed
   *
   * @type {Function}
   */
  onClose?: Function;

  /**
   *
   *
   * @type {"success" | "danger"}
   * @memberof ToasterProps
   */
  type?: "success" | "danger" | "alert";
}
/**
 *
 *
 * @interface ToasterState
 */
interface ToasterState {
  /**
   * A flag to trigger the close button
   *
   * @type {boolean}
   * @memberof ToasterState
   */
  close: boolean;
}

/**
 *
 *
 * @export
 * @class Toaster
 * @extends {React.Component<ToasterProps, ToasterState>}
 */
export class Toaster extends React.Component<ToasterProps, ToasterState> {
  /**
   * the timer
   */
  timer: ReturnType<typeof setTimeout>;

  constructor(props: ToasterProps) {
    super(props);
    // .. BINDING Function to the class
    this.handleCloseToaster = this.handleCloseToaster.bind(this);

    this.state = {
      close: false,
    };
  }

  /**
   * Invoked when the component finishes rendering
   */
  componentDidMount() {
    if (this.props.autoHide) {
      this.timer = setTimeout(this.handleCloseToaster, 5000);
    }
  }

  /**
   * Invoked when the component updated with new state or props
   *
   * @param prevProps the prev set of props
   */
  componentDidUpdate(prevProps: ToasterProps) {
    if (!isEqual(prevProps, this.props)) {
      if (this.timer) {
        this.setState({ close: false });
        // restart the timer if the toaster has new props
        clearTimeout(this.timer);
        this.timer = setTimeout(this.handleCloseToaster, 5000);
      }
    }
  }

  /**
   * Invoked when the component is about to destroy
   */
  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  /**
   * Close the toaster function handler
   */
  handleCloseToaster() {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({
      close: true,
    });
  }

  render(): React.ReactNode {
    return (
      <div
        className={`toast ${this.state.close ? "hide" : "show"} ${
          this.props.type === "success" ? "success" : this.props.type === "alert" ? "alert" : "danger"
        }`}
      >
        {false && this.props.header ? (
          <div className="toast-header">
            <span className="toaster-icon">
              {this.props.type === "success" ? (
                <i className="success">{SVG.checkmark}</i>
              ) : this.props.type === "alert" ? (
                <i className="alert">{SVG.info}</i>
              ) : (
                <i className="danger">{SVG.info}</i>
              )}
            </span>
            <strong className="header-message">{this.props.header}</strong>
            {this.props.autoHide ? null : (
              <button
                type="button"
                className="close"
                data-dismiss="toast"
                aria-label="Close"
                onClick={() => this.handleCloseToaster()}
              >
                <span aria-hidden="true">{SVG.crosshairsThinAlt}</span>
              </button>
            )}
          </div>
        ) : null}
        <div className="toast-body">
          {false && !this.props.header ? (
            <span className="toaster-icon">
              {this.props.type === "success" ? (
                <i className="success">{SVG.checkmark}</i>
              ) : this.props.type === "alert" ? (
                <i className="alert">{SVG.info}</i>
              ) : (
                <i className="danger">{SVG.info}</i>
              )}
            </span>
          ) : null}
          <span className="message">{this.props.message}</span>
          {!this.props.header && !this.props.autoHide ? (
            <button
              type="button"
              className="close"
              data-dismiss="toast"
              aria-label="Close"
              onClick={() => this.handleCloseToaster()}
            >
              <span aria-hidden="true">{SVG.crosshairsThinAlt}</span>
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

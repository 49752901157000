import * as React from "react";
import isEmpty from "lodash.isempty";

import { useCarouselContext } from "./context";

export const Campaigns = () => {
  const { campaignPreview } = useCarouselContext();
  if (isEmpty(campaignPreview)) return null;
  const preview =
    campaignPreview && !isEmpty(campaignPreview.requestedResolutionUrl)
      ? campaignPreview.requestedResolutionUrl
      : campaignPreview.screenResolutionUrl;

  return (
    <Container>
      <img src={ preview } />
    </Container>
  );
};

const Container = ({ children }: React.PropsWithChildren<any>) => {
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      {children}
    </div>
  );
};

export default Campaigns;

/**
 * @author Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-12-19
 * @description Implementation of the GraphQL query to retrieve the search.
 * @filename search-query.tsx
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL query to retrieve the current user.
 *
 * @type {GraphQLTaggedNode}
 */
export const SEARCH_QUERY: GraphQLTaggedNode = graphql`
  query searchQuery(
    $query: String!
    $cursor: String
    $facets: [SearchFacetInput!]
    $filterQuery: String
    $order: String
  ) {
    search(
      query: $query
      cursor: $cursor
      facets: $facets
      filterQuery: $filterQuery
      order: $order
    ) {
      hasErrors
      errors {
        id
        location
      }
      connection {
        count
        facets {
          name
          buckets {
            count
            value
          }
        }
        edges {
          cursor
          node {
            __typename
            activities
            area
            authors
            categories
            country
            countryCode
            durationInSeconds
            fundingRound
            id
            investments
            isPublic
            kind
            label
            linkUrl
            location
            name
            previewUrl
            landingPageUrl
            published
            publisher
            responsibility
            settlement
            subTitle
            tagLine
            tags
            title
          }
        }
      }
    }
  }
`;

/**
 * @author Muhammad Omran
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2021-01-31
 * @description Implementation of the Reusable Spinner Component
 * @filename spinner.tsx
 */
import React from "react";

/**
 * The props of Radivision spinner component
 *
 * @interface SpinnerProps
 */
interface SpinnerProps {
  /**
   * [optional] the color of the spinner, default white
   *
   * @type {string}
   * @memberof SpinnerProps
   */
  color?: string;

  /**
   * [optional] the size of the spinner
   *
   * @type {string}
   * @memberof SpinnerProps
   */
  size?: number;

  /**
   * [optional] the stroke width of the spinner
   *
   * @type {string}
   * @memberof SpinnerProps
   */
  stroke?: number;
}

/**
 * A React component that renders the Radivision Standard spinner Component
 */
export const Spinner = (props: SpinnerProps) => {
  const stroke: number = props.stroke ? props.stroke : 4;
  const spinnerContainerStyles: React.CSSProperties = props.size
    ? {
        width: props.size,
        height: props.size,
      }
    : {};

  let spinnerStyles: React.CSSProperties = props.size
    ? {
        width: props.size - 2 * stroke,
        height: props.size - 2 * stroke,
        borderWidth: stroke,
      }
    : {};
  spinnerStyles = props.color
    ? {
        ...spinnerStyles,
        borderTopColor: props.color,
      }
    : { ...spinnerStyles };

  return (
    <div className="ring-loader" style={spinnerContainerStyles}>
      <div style={spinnerStyles} />
      <div style={spinnerStyles} />
      <div style={spinnerStyles} />
      <div style={spinnerStyles} />
    </div>
  );
};

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type entityQueryVariables = {
    readonly id: string;
};
export type entityQueryResponse = {
    readonly entity: {
        readonly hasErrors: boolean | null;
        readonly entity: {
            readonly __typename: string;
            readonly landingPageUrl: string | null;
            readonly feed: {
                readonly keys: ReadonlyArray<string> | null;
                readonly feed: ReadonlyArray<{
                    readonly key: string;
                    readonly stories: ReadonlyArray<{
                        readonly __typename: string;
                        readonly id?: string;
                        readonly link?: string | null;
                        readonly title?: string | null;
                    } & ({
                        readonly __typename: "RadivisionPost";
                        readonly id: string;
                        readonly link: string | null;
                        readonly title: string | null;
                    } | {
                        /*This will never be '% other', but we need some
                        value in case none of the concrete values match.*/
                        readonly __typename: "%other";
                    })> | null;
                }> | null;
            } | null;
        } | null;
    } | null;
};
export type entityQuery = {
    readonly response: entityQueryResponse;
    readonly variables: entityQueryVariables;
};



/*
query entityQuery(
  $id: ID!
) {
  entity(id: $id) {
    hasErrors
    entity {
      __typename
      landingPageUrl
      feed(kind: TICKER) {
        keys
        feed {
          key
          stories {
            __typename
            ... on RadivisionPost {
              id
              link
              title
            }
            ... on ArticleStory {
              id
              link
              title
            }
            ... on Node {
              id
            }
          }
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "landingPageUrl",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "kind",
    "value": "TICKER"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "keys",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "key",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v11 = [
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
],
v12 = [
  (v9/*: any*/),
  (v10/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "entityQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "entity",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "EntityPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "entity",
            "storageKey": null,
            "args": null,
            "concreteType": "Entity",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed",
                "storageKey": "feed(kind:\"TICKER\")",
                "args": (v5/*: any*/),
                "concreteType": "FeedConnection",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "feed",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedItem",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "stories",
                        "storageKey": null,
                        "args": null,
                        "concreteType": null,
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "type": "RadivisionPost",
                            "selections": (v11/*: any*/)
                          },
                          {
                            "kind": "InlineFragment",
                            "type": "ArticleStory",
                            "selections": (v11/*: any*/)
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "entityQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "entity",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "EntityPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "entity",
            "storageKey": null,
            "args": null,
            "concreteType": "Entity",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed",
                "storageKey": "feed(kind:\"TICKER\")",
                "args": (v5/*: any*/),
                "concreteType": "FeedConnection",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "feed",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedItem",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "stories",
                        "storageKey": null,
                        "args": null,
                        "concreteType": null,
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v8/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "type": "RadivisionPost",
                            "selections": (v12/*: any*/)
                          },
                          {
                            "kind": "InlineFragment",
                            "type": "ArticleStory",
                            "selections": (v12/*: any*/)
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v8/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "entityQuery",
    "id": null,
    "text": "query entityQuery(\n  $id: ID!\n) {\n  entity(id: $id) {\n    hasErrors\n    entity {\n      __typename\n      landingPageUrl\n      feed(kind: TICKER) {\n        keys\n        feed {\n          key\n          stories {\n            __typename\n            ... on RadivisionPost {\n              id\n              link\n              title\n            }\n            ... on ArticleStory {\n              id\n              link\n              title\n            }\n            ... on Node {\n              id\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'c5a3055a02d2c1c31862954e83dfe790';
export default node;

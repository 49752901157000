import {
  GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
  GRAPHQL_TYPE_EVENT,
  TopLevelMediaKind,
  PreviewKind,
  Person,
  Entity,
  Story,
} from "@radivision/graphql";
import { Episode } from "../../component-configuration/episode";
import { GeneralItemDetails } from "../../component-configuration/general-item-details";
import { AppState } from "../../redux";
import { Show } from "../../component-configuration/show";
import { ImageHelper } from "../../utilities/image-helper";
import { OptimizedImageUrls } from "../../component-configuration/optimized-image";

const isNumber = (n) => typeof n === "number";

export const getShow = (state: AppState): Show | null => {
  const { detailsPanel } = state.detailsPanel;
  if (!detailsPanel) return null;

  return detailsPanel.show ? (detailsPanel.show as Show) : null;
};

export const getEpisode = (state: AppState) => {
  const show = getShow(state);
  const currentEpisode = getCurrentEpisode(state);

  return isNumber(currentEpisode) && show && show.items?.length ? show.items[currentEpisode] : null;
};

export const getEpisodeType = (state: AppState): "Episode" | "GeneralItem" => {
  const episode = getEpisode(state);

  return episode && (episode.type === GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY || episode.type === GRAPHQL_TYPE_EVENT)
    ? "Episode"
    : "GeneralItem";
};

export const getEpisodeDetails = (state: AppState): Episode | GeneralItemDetails | null => {
  const episode = getEpisode(state);
  const episodeType = getEpisodeType(state);

  return episode
    ? episodeType === "Episode"
      ? (episode.details as Episode)
      : (episode.details as GeneralItemDetails)
    : null;
};

export const getEpisodeDetailsVideoSrc = (state: AppState) => {
  const episodeDetails = getEpisodeDetails(state);
  const episodeType = getEpisodeType(state);

  return (
    episodeDetails &&
    episodeType === "Episode" &&
    Array.isArray((episodeDetails as Episode).video) &&
    (episodeDetails as Episode).video[0].src
  );
};

export const getVideoSrc = (state: AppState) => {
  const episodeDetails = getEpisodeDetails(state);

  return episodeDetails && (episodeDetails as Episode).video;
};

export const getEpisodeYoutube = (state: AppState) => {
  const episodeDetails = getEpisodeDetails(state);

  return String(episodeDetails?.youTubeVideo).trim();
};

export const getEpisodeVideoSrc = (state: AppState) => {
  const episodeDetailsVideoSrc = getEpisodeDetailsVideoSrc(state);
  const videoSrc = getVideoSrc(state);

  return episodeDetailsVideoSrc || videoSrc;
};

export const getEpisodeTitle = (state: AppState) => {
  const episodeDetails = getEpisodeDetails(state);
  const episodeType = getEpisodeType(state);

  return (
    episodeDetails &&
    (episodeType === "Episode"
      ? (episodeDetails as Episode).episodeTitle
      : (episodeDetails as GeneralItemDetails).title)
  );
};

export const isShowTrailer = (state: AppState) => {
  const detailsPanel = state.detailsPanel?.detailsPanel;

  return detailsPanel && detailsPanel.type === "Show" && detailsPanel.episode === null;
};

export const getShowTrailer = (state: AppState) => {
  const show = getShow(state);

  return show?.trailer;
};

export const isEpisode = (state: AppState): boolean => {
  const detailsPanel = state.detailsPanel?.detailsPanel;
  const currentEpisode = getCurrentEpisode(state);

  return detailsPanel && detailsPanel.type === "Show" && isNumber(currentEpisode);
};

export const episodeHasVideo = (state: AppState) => {
  const episodeDetails = getEpisodeDetails(state);
  const episodeYoutube = getEpisodeYoutube(state);
  const episodeDetailsVideoSrc = getEpisodeDetailsVideoSrc(state);

  return episodeDetails && Boolean(episodeYoutube || episodeDetailsVideoSrc);
};

export const isPitchGrid = (state: AppState) => {
  const detailsPanel = state.detailsPanel?.detailsPanel;
  return detailsPanel.type === "PitchGrid";
};

export const isGeneralItem = (state: AppState) => {
  const detailsPanel = state.detailsPanel?.detailsPanel;
  const episodeType = getEpisodeType(state);

  return detailsPanel && detailsPanel.type === "General" && episodeType === "GeneralItem";
};


export const generalItemHasVideo = (state: AppState) => Boolean(getGeneralItemYoutube(state));

export const getCurrentEpisode = (state: AppState) => state.detailsPanel?.detailsPanel?.episode;

export const isThereNextEpisode = (state: AppState) => {
  const currentEpisode = getCurrentEpisode(state);
  const show = getShow(state);
  return isNumber(currentEpisode) && currentEpisode >= 0 && currentEpisode < show.items.length - 1;
};

export const getNextEpisode = (state: AppState) => {
  const currentEpisode = getCurrentEpisode(state);
  const show = getShow(state);
  const isLastEpisode = isNumber(currentEpisode) && show && currentEpisode >= show.items.length - 1;
  const stopAutoPlay = null;
  const startFromFirstEpisode = 0;

  const nextEpisode = isThereNextEpisode(state)
    ? currentEpisode + 1
    : isLastEpisode
    ? stopAutoPlay
    : startFromFirstEpisode;

  return nextEpisode;
};

export const isItemOriginalContent = (state: AppState) => {
  const item = getEpisode(state);
  return item && item.type === "OriginalContentStory";
};

export const getPreviewImageUrl = (item: Story | Entity | Person | null): OptimizedImageUrls | null => {
  if (!item) {
    return null;
  }
  const previewUrl = item.previews
    ? ImageHelper.fetchOptimizedImageUrl({
        imageType: "PREVIEW",
        preview: {
          content: item?.previews,
          previewKind: PreviewKind.SQUARE,
          topLevelMedia: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: { containerWidthRatio: 1, numberOfItems: 1 },
        isSquareImage: true,
        revision: item.revision,
      })
    : null;
  return previewUrl;
};

import Loadable from "react-loadable";
import OptionsWithoutRender = LoadableExport.OptionsWithoutRender;

interface ComponentProps {
  videoPanelRef: any;
  skip: () => void;
  delayInSeconds: number;
}

type Props = ComponentProps;

const loadableOptions: OptionsWithoutRender<Props> = {
  loader: () => import("../../modules/paywall").then((module) => module.PaywallOverlay) as Promise<any>,
  loading: () => null,
};

export const PaywallOverlay = Loadable(loadableOptions);

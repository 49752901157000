/**
 * @author William A Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-08-29
 * @description Implementation of the ScrollToTopButton component.
 * @filename scroll-to-top-button.tsx
 */
import React from "react";
import { connect } from "react-redux";
import { SVG } from "../../component-configuration/svgs";
import { AppState } from "../../redux";

interface MapStateProps {
  pageYOffset: number;
}

type Props = MapStateProps;

class Component extends React.Component<Props> {
  state: { visible: boolean };

  constructor(props: any) {
    super(props);
    this.state = { visible: false };
  }

  componentDidUpdate() {
    if (!isNaN(this.props.pageYOffset)) {
      if (this.props.pageYOffset > 20 && !this.state.visible) {
        this.setState({ visible: true });
        return;
      }
      if (this.props.pageYOffset < 20 && this.state.visible) {
        this.setState({ visible: false });
      }
    }
  }

  render(): React.ReactNode {
    return (
      <div
        className={this.state.visible ? "scroll-to-top-button visible" : "scroll-to-top-button"}
        onClick={this.scrollToTop}
      >
        {SVG.chevronDown}
      </div>
    );
  }

  scrollToTop(e: React.MouseEvent): void {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}

const mapState = (state: AppState) => ({
  pageYOffset: state.app.pageYOffset,
});

export const ScrollToTopButton = connect(mapState)(Component);

.banners-list {
  min-height: 200px;
  max-height: 340px;
  overflow-y: auto;
  .banner {
    height: 100px;
    background-size: cover;
    background-position: center center;
    margin: 5px 0px;
  }
  @media (max-width: 560px) {
    max-height: 550px;
  }
}

import Loadable from "react-loadable";
export const PORTFOLIO_ITEMS_COUNT = 100;

export const PeoplePortfolioTab = Loadable({
  loader: () =>
    import("../../../../modules/manage-portfolio").then((module) => module.PeoplePortfolioTab) as Promise<any>,
  loading: () => null,
});

export const CompaniesPortfolioPanel = Loadable({
  loader: () =>
    import("../../../../modules/manage-portfolio").then((module) => module.CompaniesPortfolioPanel) as Promise<any>,
  loading: () => null,
});

export const CompaniesPortfolioTab = Loadable({
  loader: () =>
    import("../../../../modules/manage-portfolio").then((module) => module.CompaniesPortfolioTab) as Promise<any>,
  loading: () => null,
});

export const PeoplePortfolioPanel = Loadable({
  loader: () =>
    import("../../../../modules/manage-portfolio").then((module) => module.PeoplePortfolioPanel) as Promise<any>,
  loading: () => null,
});

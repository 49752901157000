/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2019-01-22
 * @filename: instagram-post.ts
 * @description: Declaration of instagram post payload interface
 */

import { InstagramPostContentItem } from "./instagram-post-content-item";

/**
 * The __typeID of the GraphQL instagram post object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_INSTAGRAM_POST: string = "InstagramPost";

/**
 * The details of a Instagram post
 *
 * @export
 * @interface InstagramPost
 */
export interface InstagramPost {
  /**
   * The author of the Instagram post
   *
   * @type {string}
   * @memberof InstagramPost
   */
  author: string;

  /**
   *  The number of times that the Instagram post has been commented on
   *
   * @type {number}
   * @memberof InstagramPost
   */
  commented: number;

  /**
   * The content of the Instagram post
   *
   * @type {[InstagramPostContentItem]}
   * @memberof InstagramPost
   */
  content: [InstagramPostContentItem];

  /**
   * The number of times that the Instagram post has been liked
   *
   * @type {number}
   * @memberof InstagramPost
   */
  liked: number;

  /**
   * The display name of the author of the Instagram post
   *
   * @type {string}
   * @memberof InstagramPost
   */
  name: string;

  /**
   * The URL of the link to the author's Instagram profile
   *
   * @type {string}
   * @memberof InstagramPost
   */
  profileLink: string;

  /**
   * The collection of tags associated with the Instagram post
   *
   * @type {[string]}
   * @memberof InstagramPost
   */
  tags: [string];
  
  /**
   * The typename of the instagram post.
   *
   * @type {string}
   */
  __typename?: string;
}

/**
 * @author Muhammad Omran
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2020-04-19
 * @description Implementation of reusable Bootstrap alert message
 * @filename alert-message.tsx
 */
import React from "react";
import { SVG } from "../../component-configuration/svgs";
const isEqual = require("lodash.isequal");

/**
 *
 *
 * @interface AlertMessageProps
 */
interface AlertMessageProps {
  /**
   * flag to set the AlertMessage behaviour to be auto hidden or not
   *
   * @type {boolean}
   */
  autoHide?: boolean;

  /**
   * The text message of the AlertMessage
   *
   * @type {string}
   * @memberof AlertMessageProps
   */
  message: string;

  /**
   * to do extra something when AlertMessage is closed
   *
   * @type {Function}
   */
  onClose?: Function;

  /**
   *
   *
   * @type {"SUCCESS" | "ERROR"}
   * @memberof AlertMessageProps
   */
  type?: "SUCCESS" | "ERROR";
}
/**
 *
 *
 * @interface AlertMessageState
 */
interface AlertMessageState {
  /**
   * displayed status
   *
   * @type {boolean}
   * @memberof AlertMessageState
   */
  hide: boolean;
}

/**
 *
 *
 * @export
 * @class AlertMessage
 * @extends {React.Component<AlertMessageProps, AlertMessageState>}
 */
export class AlertMessage extends React.Component<
  AlertMessageProps,
  AlertMessageState
> {
  /**
   * the timer
   */
  timer: ReturnType<typeof setTimeout>;

  constructor(props: AlertMessageProps) {
    super(props);
    // .. BINDING Function to the class
    this.handleDismiss = this.handleDismiss.bind(this);

    this.state = {
      hide: false,
    };
  }

  /**
   * Invoked when the component finishes rendering
   */
  componentDidMount() {
    if (this.props.autoHide) {
      this.timer = setTimeout(this.handleDismiss, 7000);
    }
  }

  /**
   * Invoked when the component updated with new state or props
   *
   * @param prevProps the prev set of props
   */
  componentDidUpdate(prevProps: AlertMessageProps) {
    if (!isEqual(prevProps, this.props)) {
      if (this.timer) {
        this.setState({ hide: false });
        // restart the timer if the toaster has new props
        clearTimeout(this.timer);
        this.timer = setTimeout(this.handleDismiss, 7000);
      }
    }
  }

  /**
   * Close the Alert Message function handler
   */
  handleDismiss() {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({
      hide: true,
    });
  }

  render(): React.ReactNode {
    return (
      <div
        id="alert-message"
        className={`alert alert-${
          this.props.type === "SUCCESS" ? "success" : "danger"
        }${this.props.autoHide ? " floating" : ""} ${
          this.state.hide ? "hide" : "show"
        }`}
      >
        <span>{this.props.message}</span>
        <span className="dismiss-alert" onClick={this.handleDismiss}>
          {SVG.crosshairs}
        </span>
      </div>
    );
  }
}

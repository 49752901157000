/**
 * @author William Alexander Livesley
 * @date   2018-04-08
 * @description Declaration of the NetworkLocationAddress interface.
 * @filename network-location-address.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { NetworkZone } from "./network-zone";

/**
 * The __typeID of the GraphQL Network Location Address object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_NETWORK_LOCATION_ADDRESS: string =
  "NetworkLocationAddress";

/**
 * The address of the network location when accessed from a given network zone.
 *
 * @template D
 * @interface
 */
export interface NetworkLocationAddress {
  /**
   * The URI of the network location address.
   *
   * @type {string}
   */
  uri?: string;

  /**
   * The network zone for the network location address.
   *
   * @type {NetworkZone}
   */
  zone?: NetworkZone;

  /**
   * The typename of the node.
   *
   * @type {string}
   */
  __typename?: string;
}

import { SetAppStatePayload, SetAppStateAction } from "./types";
import { SET_APP_STATE, SET_DEVICE, SET_PAGE_SCROLL, SET_KEY_PRESS } from "./constants";

export const setAppState = (payload: SetAppStatePayload): SetAppStateAction => ({
  type: SET_APP_STATE,
  payload,
});

export const setDevice = (payload: SetAppStatePayload) => ({
  type: SET_DEVICE,
  payload,
});
export const setPageScroll = (payload: SetAppStatePayload) => ({
  type: SET_PAGE_SCROLL,
  payload,
});
export const setKeyPress = (payload: SetAppStatePayload) => ({
  type: SET_KEY_PRESS,
  payload,
});

import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import {
  SetAuthStatePayload,
  SetAuthStateAction,
  ConfirmResetPasswordPayload,
  ConfirmResetPasswordAction,
} from "../../../redux/authentication/types";
import { AUTH_CONFIRM_RESET_PASSWORD } from "../../../redux/authentication/constants";
import { FormFieldContainer, FormFieldInput, Submit, ModalFooter } from "../modal-form-elements";
import { SVG } from "../../../component-configuration/svgs";
import { SetToastMessageAction, SetToastMessagePayload } from "../../../redux/toast/types";
import { TOAST_ALERT } from "../../../redux/toast/constants";
import { Password } from "./password";

interface MapDispatchProps {
  setAuthState: (paylaod: SetAuthStatePayload) => SetAuthStateAction;
  confirmResetPassword: (payload: ConfirmResetPasswordPayload) => ConfirmResetPasswordAction;
  setToastMessage: (payload: SetToastMessagePayload) => SetToastMessageAction;
}

interface MapStateProps {
  step: number;
  email: string;
  password: string;
  verificationCode: string;
  inProgress: boolean;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { step, email, password, verificationCode, confirmResetPassword, setToastMessage, inProgress } = props;
  const shouldDisplay = step === AUTH_CONFIRM_RESET_PASSWORD;

  const [isValid, setIsValid] = React.useState(false);

  const onSubmit = React.useCallback(() => {
    if (!isValid) {
      setToastMessage({ msg: "Please enter valid and matching passwords.", type: TOAST_ALERT });
      return;
    }
    confirmResetPassword({ email, verificationCode, password });
  }, [email, password, verificationCode, confirmResetPassword, setToastMessage, isValid]);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Container>
      <div className="subtitle">Please enter the new password.</div>
      <Password setIsValid={setIsValid} />
      <ModalFooter>
        <Submit
          onClick={onSubmit}
          disabled={false}
          className={!isValid ? "disabled" : ""}
          isSubmitting={inProgress}
          title={"Reset password"}
        />
      </ModalFooter>
    </Container>
  );
};

const Container = ({ children }) => {
  return <div className="reset-password-form">{children}</div>;
};

const mapState = (state: AppState) => ({
  step: state.authentication.step,
  email: state.authentication.email,
  password: state.authentication.password,
  verificationCode: state.authentication.verificationCode,
  inProgress: state.cache.inProgress["reset-password"],
});

const mapDispatch = {
  setAuthState: Actions.setAuthState,
  confirmResetPassword: Actions.confirmResetPassword,
  setToastMessage: Actions.setToastMessage,
};

export const ConfirmResetPassword = connect(mapState, mapDispatch)(Component);

/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2019-02-11
 * @filename: content-moderation-state-kind.ts
 * @description: Declaration of moderation kind interface
 */


/**
 * An enumeration of the kinds of content moderation states
 *
 * @enum {number}
 */
export enum ContentModerationStateKind {
 /**
  * The story is approved
  */
  APPROVED = "APPROVED",
  /**
   *  The story is banned and cannot be viewed publicly
   */
  BANNED = "BANNED",
  /**
   *  The story is not yet approved
   */
  TO_BE_APPROVED = "TO_BE_APPROVED"
}





export function randomize(items: any[], generator: () => number) {
  return items
    .map((item: any) => {
      const sort = generator();
      //const sort = typeof generator === "function" ? generator() : Math.random();
      return { item, sort };
    })
    .sort((a: any, b: any) => a.sort - b.sort)
    .map(({ item }) => item);
}

/**
 * @author Ahmed Serag
 * @date   2018-11-19
 * @description Declaration of the Suggestion interface.
 * @filename suggestion.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from './node';
import { SuggestionItem } from './suggestion-item';

/**
 * The __typeID of the GraphQL Suggestion object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_SUGGESTION: string = "Suggestion";


/**
 * A suggestion
 *
 * @export
 * @interface Suggestion
 * @extends {Node}
 */
export interface Suggestion extends Node {

  /**
   * The email address of the person making the suggestion. This is populated for anonymous
	 * users who have provided a feedback email address
   *
   * @type {string}
   * @memberof Suggestion
   */
  feedbackTo?: string;

  /**
   * The collection of suggestion items.
   *
   * @type {SuggestionItem[]}
   * @memberof Suggestion
   */
  items: SuggestionItem[];

  /**
   * The person making the suggestion. This is not populated for anonymous users.
   *
   * @type {string}
   * @memberof Suggestion
   */
  person?: string;

  
}
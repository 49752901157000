import * as React from "react";
import { Modal } from "./modal";

export const CommunityGuidelines = () => {
  return (
    <Modal id={`community-guidelines`} title={"Community Guidelines"} className="community-guidlines-modal">
      <div className="content" style={{ position: "relative" }}>
        <div className="community-guidelines-modal">
          <div>
            Radivision&apos;s mission is to teach and inspire. We are building an entrepreneurial community where
            companies can create and share their pitch video to help them spread awareness and &ldquo;test the
            waters.&rdquo; As Radivision grows, we are working hard to maintain a professional environment for our
            community. In order to do so, we created a list of community guidelines that provide guidance on what is and
            is not acceptable in order to maintain a professional and safe environment for our users. By submitting a
            Pitch Video you agree to the community guidelines outlined below:
          </div>
          <br />
          <ul>
            <li>Only share video footage that you have created or have the right to share.</li>

            <li>
              Upload a Pitch video that is appropriate for diverse audiences. Racism, sexism, and bigotry will not be
              tolerated.
            </li>

            <li>Do not post a video that contains illegal activities including drug abuse and gun violence.</li>

            <li>We do not allow nudity or sexually explicit content on our platform.</li>

            <li>
              We do not allow content that can undermine the integrity of our platform or the authenticity of our users.
            </li>

            <li>No spam, fake and/or misleading investment information.</li>

            <li>We prohibit any activities that undermine the security and reliability of Radivision.</li>
          </ul>
        </div>
      </div>
    </Modal>
  );
};

import * as React from "react";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import {
  SetAuthStatePayload,
  SetAuthStateAction,
  VerifyAccountAction,
  VerifyAccountPayload,
} from "../../../redux/authentication/types";
import { AUTH_SIGNUP_VERIFICATION } from "../../../redux/authentication/constants";
import { FormFieldContainer, ModalFooter } from "../modal-form-elements";
import { TOAST_ALERT } from "../../../redux/toast/constants";
import { SetToastMessagePayload, SetToastMessageAction } from "../../../redux/toast/types";
import { ResendCodeLink } from "./resend-code-link";
import { Spinner } from "../../user-widgets/spinner";

interface ComponentProps {
  onSubmit: () => void;
}

interface MapDispatchProps {
  setAuthState: (paylaod: SetAuthStatePayload) => SetAuthStateAction;
  setToastMessage: (payload: SetToastMessagePayload) => SetToastMessageAction;
}

interface MapStateProps {
  verificationCode: string;
  inProgress: boolean;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { verificationCode, setAuthState, setToastMessage, inProgress, onSubmit } = props;
  const submitRef = React.useRef(null);

  const onChange = React.useCallback(
    (otp: string) => {
      setAuthState({ verificationCode: otp });
    },
    [setAuthState],
  );

  const submitBtn = submitRef.current;

  React.useEffect(() => {
    const finishedLastDigit = verificationCode?.length === 6;
    if (finishedLastDigit) {
      submitBtn?.focus();
    }
  }, [verificationCode, submitBtn]);

  const onSubmitFn = React.useCallback(() => {
    if (verificationCode.length !== 6) {
      setToastMessage({ msg: "Please enter the 6 characters verification code.", type: TOAST_ALERT });
      return;
    }
    onSubmit();
  }, [verificationCode, setToastMessage, onSubmit]);

  const isDisabled = verificationCode.length !== 6;
  return (
    <Container>
      <div className="subtitle">Please check your email for the code to verify your account.</div>
      <FormFieldContainer>
        <OtpInput
          value={verificationCode}
          onChange={onChange}
          numInputs={6}
          shouldAutoFocus={true}
          className="otp-input"
          isInputNum={true}
          isDisabled={false}
        />
      </FormFieldContainer>
      <ResendCodeLink />
      <ModalFooter>
        <button
          type="button"
          ref={submitRef}
          className={`btn btn-radivision text-center ${isDisabled ? "disabled" : ""}`}
          onClick={onSubmitFn}
          disabled={false}
        >
          {inProgress ? <Spinner /> : "Continue"}
          <div className="hoverOverlay" />
        </button>
      </ModalFooter>
    </Container>
  );
};

const Container = ({ children }) => {
  return <div className="reset-verification-code verification-code-form">{children}</div>;
};

const mapState = (state: AppState) => ({
  verificationCode: state.authentication.verificationCode,
  inProgress: state.cache.inProgress["verify-account"],
});

const mapDispatch = {
  setAuthState: Actions.setAuthState,
  setToastMessage: Actions.setToastMessage,
};

export const VerifyAccount = connect(mapState, mapDispatch)(Component);

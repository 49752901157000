import * as React from "react";
import { connect } from "react-redux";
import { Loader } from "../page/loader";
import { AppState } from "../../redux";
import isEmpty from "lodash.isempty";
import * as Actions from "../../redux/actions";
import { PitchVideoStory } from "@radivision/graphql/lib/ts/graphql/pitch-video-story";
import { SetProfileActiveTabPayload, SetProfileActiveTabAction } from "../../redux/account/types";
import { PitchPeopleEntity } from "./pitch-people-entity";
import { SelectPitchVersion } from "../inner-pages/profile-details-tab/investment/pitch-video-panel";
import { InvestNowButton } from "./invest-now-button";
import { CROWNFUNDING_COMPANIES } from "../../constants/content-ids";
import logo from "../../../static/pitch_planet_on_black.png";
import { gaEvent } from "../../utilities/general";
import { SetAppStateAction, SetAppStatePayload } from "../../redux/app/types";
import { SVG } from "../../component-configuration/svgs";
import { extractYoutubeId } from "../../utilities/extract-youtube-id";

const VideoPanel = React.lazy(() => import("../page-framework/video-panel"));

interface MapStateProps {
  item: PitchVideoStory;
}

interface MapDispatchProps {
  setProfileActiveTab: (payload: SetProfileActiveTabPayload) => SetProfileActiveTabAction;
  setAppState: (payload: SetAppStatePayload) => SetAppStateAction;
}

type Props = MapStateProps & MapDispatchProps;

const Component = (props: Props) => {
  const { item, setAppState } = props;
  const [videoVersion, setVideoVersion] = React.useState(null);
  const [videoReady, setVideoReady] = React.useState(false);
  const currentVersion = videoVersion || (item?.trailer ? "99sec" : "5min");

  const hasMissingVideo = isEmpty(item?.originalClip?.externalId) || isEmpty(item?.trailer?.externalId);
  const key = currentVersion === "99sec" ? "trailer" : "originalClip";
  const details = item && item[key];
  const youtubeId = details?.externalId || extractYoutubeId(item.link);
  React.useEffect(() => {
    setTimeout(() => setVideoReady(true), 5000);
  }, []);

  const entity = item?.peopleEntities && item.peopleEntities[0] && item?.peopleEntities[0].entity;
  const investmentsBy = Array.isArray(entity?.investmentsBy) && entity?.investmentsBy[0];
  const link = investmentsBy?.link;
  const isCrowdFunding = false; /*CROWNFUNDING_COMPANIES.find(({ id }) => id === entity?.id);*/
  return (
    <>
      <div className={`details-panel-video PitchGrid`}>
        <Loader isActive={!videoReady} />
        <React.Suspense fallback={<Loader isActive={true}></Loader>}>
          <VideoPanel
            videoPosition="FIT"
            youTube={youtubeId}
            videoSrc={undefined}
            mute={false}
            showControls={true}
            disableAutoPlay={false}
            onReady={() => setVideoReady(true)}
            onVideoEnded={() => {
              const nextVideo = currentVersion === "99sec" ? item?.originalClip?.externalId : item?.trailer?.externalId;
              const nextVideoVersion = currentVersion === "99sec" ? "5min" : "99sec";
              if (!nextVideo) {
                return;
              }
              setVideoVersion(nextVideoVersion);
            }}
          />
        </React.Suspense>
      </div>
      {!hasMissingVideo ? (
        <SelectPitchVersion
          selected={currentVersion}
          className={`highlighted ${item?.trailer?.externalId ? "has-quick" : ""}  ${
            item?.originalClip?.externalId ? "has-full" : ""
          }`}
          onClick={(version: string) => {
            const video = version === "99sec" ? item?.trailer : item?.originalClip;
            if (video) {
              setVideoVersion(version);
            }
          }}
        />
      ) : null}
      <div className="details-panel-info">
        <div className="details-panel-section details-panel-meta">
          <div className="details-panel-description">
            <h1 className="video-title">{item?.title}</h1>
            <p className="video-description">{details?.description}</p>
          </div>
          <div className="details-panel-show-logo">
            <img src={logo} className={"pitch-planet-logo"} />
          </div>
        </div>
        <Buttons>
          {!isCrowdFunding ? (
            <InvestNowButton entity={entity} link={link} />
          ) : (
            <Button
              text={
                <>
                  <span>Invest Now</span>
                  {SVG.externalLinkAlt}
                </>
              }
              isOutline={false}
              onClick={() => {
                gaEvent("PP-learn-more", "click", item?.peopleEntities[0]?.entity?.name, false);
                setAppState({ lastSeenPitchId: item.id });
                window.open(link);
              }}
            />
          )}
        </Buttons>
        <PitchPeopleEntity item={item} />
      </div>
    </>
  );
};

export const Buttons = ({ children }) => {
  return <div className="pitch-card-actions  details-panel-section">{children}</div>;
};

export const Button = ({
  isOutline,
  text,
  onClick,
  target,
  isDisabled,
}: {
  isOutline: boolean;
  isDisabled?: boolean;
  text: any;
  target?: string;
  onClick?: () => void;
}) => {
  return (
    <a
      className={`pitch-card-button ${isOutline && !isDisabled ? "outline-button" : ""} ${
        isDisabled ? "disabled" : ""
      }`}
      onClick={!isDisabled ? onClick : undefined}
      target={target}
    >
      <span>{text}</span>
    </a>
  );
};

const mapState = (state: AppState) => ({
  item: state.detailsPanel.item,
});

const mapDispatch = {
  closeDetailsPanel: Actions.closeDetailsPanel,
  setProfileActiveTab: Actions.setProfileActiveTab,
  setAppState: Actions.setAppState,
};
export const PitchVideo = connect(mapState, mapDispatch)(Component);


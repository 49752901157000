import { Reducer } from "redux";
import produce from "immer";
import { SET_TOAST_MESSAGE, DISMISS_TOAST_MESSAGE, TOAST_SUCCESS } from "./constants";
import { ToastAction, ToastState } from "./types";

export const initialState: ToastState = {
  msg: null,
  header: null,
  type: null,
  autoHide: true,
  recent: [],
};

export const ToastReducer: Reducer<ToastState, ToastAction> = (
  state: ToastState = initialState,
  action: ToastAction,
) => {
  switch (action.type) {
    case SET_TOAST_MESSAGE: {
      const { msg, autoHide, header, type } = action.payload;
      return {
        ...state,
        msg,
        autoHide: typeof autoHide === "undefined" ? true : autoHide,
        header: typeof header === "undefined" ? null : header,
        type: typeof type === "string" ? type : TOAST_SUCCESS,
      };
    }
    case DISMISS_TOAST_MESSAGE: {
      return {
        ...initialState,
        recent: [...state.recent],
      };
    }
    default:
      return state;
  }
};

/**
 * @author Sarah Nouh
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-07-08 03:10
 * @description Implementation of the hero carousel.
 * @filename hero-carousel.tsx
 */
import * as React from "react";
import { FixedList as GraphQlFixedList, FixedList } from "@radivision/graphql/lib/ts/graphql/fixed-list";
import { ListItem as GraphQlListItem } from "@radivision/graphql/lib/ts/graphql/list";
import { PreviewKind } from "@radivision/graphql/lib/ts/graphql/preview-type";
import $ from "jquery";
import { connect } from "react-redux";
import ProgressiveImage from "react-progressive-image";
import Truncate from "react-truncate";
import isEmpty from "lodash.isempty";
import { OptimizedImageUrls } from "../../component-configuration/optimized-image";
import {
  facebookPixelTraceEvent,
  snowplowAnalytics,
  gaEvent,
  getVideoLinksFromExternalId,
} from "../../utilities/general";
import { ImageHelper } from "../../utilities/image-helper";
import { MediaAsset } from "../../utilities/media-asset";
import { Preview } from "../../utilities/preview";
import { RdvButton } from "../page-framework/rdv-button";
import { SVG } from "../../component-configuration/svgs";
import { Show } from "../../component-configuration/show";
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import { GeneralItemDetails } from "../../component-configuration/general-item-details";
import { CarouselItem } from "../../component-configuration/carousel-item";
import * as Actions from "../../redux/actions";
import { GaEventAction, GaEventPayload } from "../../redux/trackers/types";
import { AppState } from "../../redux";
import { OpenDetailsPanelAction, OpenDetailsPanelPayload } from "../../redux/details-panel/types";
import { FetchListPayload, FetchListAction } from "../../redux/cache/types";
import AddToSavedButton from "../page-framework/add-to-saved-button";
import { SetPlayingFullScreenPayload, SetPlayingFullScreenAction } from "../../redux/full-screen-video/types";
import { Loader } from "../page/loader";
import { Helmet } from "react-helmet";
import SheconomicsLogo from "../../../static/sheconomics-logo.png";
import { SheconomicsListId } from "../../constants/content-ids";
// import { NewEditorials } from "../editorials";

const VideoPanel = React.lazy(() => import("../page-framework/video-panel"));
const { Suspense } = React;

interface HeroCarouselProps {
  list: GraphQlFixedList;
  isOnScreen: boolean;
}

interface HeroCarouselState {
  currentSlide: number;
  currentVidSrc: string;

  /**
   * trigger current slide excert video to play
   *
   * @type {boolean}
   * @memberof HeroCarouselState
   */
  playExcert: boolean;

  /**
   * playing state of the full length video
   *
   * @type {boolean}
   * @memberof HeroCarouselState
   */
  isFullVideoPlaying: boolean;
  isPlaying: boolean;

  /**
   * video slides mute state
   *
   * @type {boolean}
   */
  muted: boolean;

  /**
   * save call to action loading state array of loading item ids
   *
   * @type {string[]}
   * @memberof HeroCarouselState
   */
  saveItemLoading: string[];

  /**
   * The state of Hero video, event when video finsihed loading
   *
   * @type {boolean}
   */
  videoIsLoaded: boolean;

  /**
   * The collection of the URIs of the video preview slides.
   *
   * @type {Array<string>}
   */
  videoPreviewSlidesUri: string[];
}

interface MapStateProps {
  data: any;
  isDetailsPanelOpen: boolean;
  isPlayingFullScreen: boolean;
  isPlaying: boolean;
}

interface MapDispatchProps {
  gaEvent: (payload: GaEventPayload) => GaEventAction;
  openDetailsPanel: (payload: OpenDetailsPanelPayload) => OpenDetailsPanelAction;
  fetchList: (payload: FetchListPayload) => FetchListAction;
  setPlayingFullScreen: (payload: SetPlayingFullScreenPayload) => SetPlayingFullScreenAction;
  cacheData: (payload: CacheDataPayload) => CacheDataAction;
  pageYOffset: number;
}

type Props = HeroCarouselProps & MapDispatchProps & MapStateProps;

class HeroCarousel extends React.Component<Props, HeroCarouselState> {
  carousel: React.RefObject<HTMLDivElement>;
  heroExcertTimeout: ReturnType<typeof setTimeout>;

  /**
   * Constructor.
   *
   * @param {HeroCarouselProps} props The props of the component.
   */
  constructor(props: Props) {
    super(props);
    this.carousel = React.createRef();
    this.state = {
      currentSlide: 0,
      muted: true,
      videoIsLoaded: false,
      currentVidSrc: "",
      playExcert: false,
      isFullVideoPlaying: false,
      isPlaying: false,
      videoPreviewSlidesUri: this.props.list?.items?.edges?.map((edge: any): string => {
        let url: string;

        if (edge.listItem.item.previews !== undefined && edge.listItem.item.previews !== null) {
          url = Preview.getUrlFromPreview(edge.listItem.item.previews, PreviewKind.EXCERPT);
        }
        return url;
      }),
      saveItemLoading: [],
    };
    this.openDetailsPanel = this.openDetailsPanel.bind(this);
    this.exitFullScreen = this.exitFullScreen.bind(this);
    // this.closeHeroIntroduction = this.closeHeroIntroduction.bind(this);
    this.cycleCarousel = this.cycleCarousel.bind(this);
    this.pauseCarousel = this.pauseCarousel.bind(this);
  }

  componentWillUnmount() {
    window.clearTimeout(this.heroExcertTimeout);
    delete this.carousel;
  }
  /**
   * Invoked by the React framework immediately after a component is mounted initially.
   */
  componentDidMount() {
    if (this.carousel?.current) {
      const $carousel: JQuery<HTMLElement> = $(this.carousel.current);
      $carousel.carousel({
        pause: false,
        interval: 7000,
      });
      this.heroExcertTimeout = setTimeout(() => {
        this.setState({
          playExcert: true,
        });
      }, 3000);
      // setTimeout(() => {
      //   this.setState({ heroIntro: false });
      //   $(".main-navbar")
      //     .removeClass("darkBlue")
      //     .addClass("nigntyOpacity");
      // }, 10000);
      $carousel.on("slid.bs.carousel", (e: { to: number }) => {
        // on carousel cycle clear timeouts and cycle the carousel in case of carousel is paused
        window.clearTimeout(this.heroExcertTimeout);
        $carousel.carousel("cycle");
        this.heroExcertTimeout = setTimeout(() => {
          this.setState({
            playExcert: true,
          });
        }, 3000);
        this.setState({
          currentSlide: e.to,
          currentVidSrc: "",
          playExcert: false,
          isFullVideoPlaying: false,
        });
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const detailsPanelJustClosed = prevProps.isDetailsPanelOpen && !this.props.isDetailsPanelOpen;
    if (detailsPanelJustClosed) this.cycleCarousel();
  }

  exitFullScreen() {
    this.setState({ playExcert: false, isFullVideoPlaying: false, isPlaying: false });
    $(this.carousel.current).carousel("next");
  }
  cycleCarousel() {
    this.setState({ playExcert: false, isFullVideoPlaying: false, isPlaying: false });
    $(this.carousel.current).carousel("next");
    $(this.carousel.current).carousel("cycle");
  }
  pauseCarousel() {
    $(this.carousel.current).carousel("pause");
  }

  render(): React.ReactNode {
    return (
      <div id="heroCarousel" ref={this.carousel} className="carousel hero" data-ride="carousel">
        {this.props.list?.items?.edges && this.props.list?.items?.edges?.length <= 1 ? null : (
          <ol className="carousel-indicators">
            {this.props.list?.items?.edges?.map((slide, index) => {
              return (
                <li
                  key={index}
                  data-target="#heroCarousel"
                  data-slide-to={index}
                  className={index === 0 ? "active" : ""}
                />
              );
            })}
          </ol>
        )}
        <div
          className={`carousel-inner${
            this.props.list?.items?.edges && this.props.list?.items?.edges?.length <= 1 ? " featured-hero" : ""
          }`}
        >
          {this.props.list?.items?.edges?.map((edge: any, index: number) => {
            // // this condition is used to restore the current slide that played the video after the video ends
            if (index === this.state.currentSlide) {
              return this.renderSlide(edge.listItem, index, "active");
            }
            return this.renderSlide(edge.listItem, index);
          })}
          <div className="overlay" />
        </div>
        {this.props.list?.items?.edges && this.props.list?.items?.edges?.length <= 1 ? null : (
          <>
            <div className="carousel-control-prev carousel-navigation">
              <span
                className="carousel-control-prev-icon right"
                role="button"
                data-slide="prev"
                onClick={() => {
                  $(this.carousel.current).carousel("prev");
                  this.setState({ isPlaying: false });
                }}
              >
                {SVG.nextArrow}
              </span>
            </div>
            <div className="carousel-control-next carousel-navigation">
              <span
                className="carousel-control-next-icon"
                role="button"
                data-slide="next"
                onClick={() => {
                  $(this.carousel.current).carousel("next");
                  this.setState({ isPlaying: false });
                }}
              >
                {SVG.nextArrow}
              </span>
            </div>
          </>
        )}
        <div className="shadow" />
      </div>
    );
  }

  openDetailsPanel(item: any, list: FixedList) {
    this.setState({
      playExcert: false,
      isFullVideoPlaying: false,
    });

    this.props.openDetailsPanel({
      item,
      list,
      scrollAnchorId: "heroCarousel",
    });
  }

  renderSlide(listItem: GraphQlListItem, key: number, itemClass: string = ""): React.ReactNode {
    const item: any = listItem.item;
    const list: GraphQlFixedList = listItem.list;
    const isSheconomics = item?.sourceList === SheconomicsListId;

    const DESCRIPTION: string = item?.subTitle || item?.description || list?.description;
    const windowSize = window.innerWidth;
    const placeholder = Preview.getPlaceholderUrlFromPreview(list?.previews, PreviewKind.BACKGROUND);
    const itemLogo: OptimizedImageUrls =
      list && list.previews
        ? ImageHelper.fetchOptimizedImageUrl({
            imageType: "PREVIEW",
            preview: {
              content: list.previews,
              previewKind: PreviewKind.HERO_LOGO,
            },
            desiredDimensions: {
              containerWidthRatio: windowSize > 1920 ? 5 / 12 : 4 / 12,
              numberOfItems: 1,
            },
            revision: list.revision,
          })
        : undefined;

    const src = item && item.mediaItem ? MediaAsset.getVideoUrlFromMediaAsset(item.mediaItem) : null;
    const imageSource = Preview.getUrlFromPreview(item.previews, [
      PreviewKind.HERO_LOGO,
      PreviewKind.HERO,
      PreviewKind.BACKGROUND,
    ]);

    const excertSrc = this.state.videoPreviewSlidesUri[key];
    const EXTERNAL_ID = item?.externalId || item?.originalClip?.externalId || item?.trailer?.externalId;
    const youtubeVideo = EXTERNAL_ID ? getVideoLinksFromExternalId(EXTERNAL_ID) : null;
    if (
      ((excertSrc !== null && excertSrc !== undefined) || this.state.isFullVideoPlaying) &&
      itemClass &&
      this.carousel?.current
    ) {
      this.pauseCarousel();
    }

    const { isOnScreen } = this.props;
    const showImageSlide = !isOnScreen || (!this.state.playExcert && !this.state.isFullVideoPlaying) || !itemClass;
    const shouldPlayVideo = !this.props.isDetailsPanelOpen && !this.props.isPlayingFullScreen;
    const hasInfo = excertSrc || item.link || item.landingPageUrl || item.permalink;

    return (
      <div className={`carousel-item ${itemClass} ${item?.id}`} key={key}>
        {excertSrc !== null && excertSrc !== undefined ? (
          <div className="video-slide">
            {(!this.state.playExcert && !this.state.isFullVideoPlaying) || !itemClass || !isOnScreen ? (
              <div
                className="image-slide"
                style={{
                  backgroundColor: "#161616",
                  opacity: showImageSlide ? 1 : 0,
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  right: "0px",
                  bottom: "0px",
                  zIndex: 1,
                  transition: "opacity 0.8s cubic-bezier(.665,.235,.265,.8) 0s",
                }}
              >
                <ProgressiveImage src={isSheconomics ? null : imageSource} placeholder={placeholder}>
                  {(src: any, LOADING: any, srcSetData: any) => (
                    <img
                      loading="lazy"
                      alt={`${item && item.title ? `${item.title}` : "slide"}`}
                      className="video-logo"
                      src={src}
                      style={{
                        opacity: LOADING ? 0 : 1,
                        transition: "opacity 0.1s cubic-bezier(.665,.235,.265,.8) 0s",
                      }}
                    />
                  )}
                </ProgressiveImage>
              </div>
            ) : (
              <Suspense fallback={<div />}>
                <VideoPanel
                  videoSrc={
                    this.state.playExcert && !this.state.isFullVideoPlaying && shouldPlayVideo
                      ? this.state.currentVidSrc === ""
                        ? excertSrc
                        : this.state.currentVidSrc
                      : null
                  }
                  onPlay={() => this.setState({ isPlaying: true })}
                  onVideoEnded={() => {
                    this.cycleCarousel();
                    this.props.setPlayingFullScreen({ status: false });
                    this.setState({ isPlaying: false });
                  }}
                  mute={!this.state.isFullVideoPlaying}
                  // hasHeroIntro={this.state.heroIntro}
                  youTube={this.state.isFullVideoPlaying ? youtubeVideo : null}
                  videoPosition={this.state.isFullVideoPlaying ? "FULL_SCREEN" : "FIT"}
                  json={{}}
                />
              </Suspense>
            )}
          </div>
        ) : (
          <div className="image-slide">
            <ProgressiveImage src={imageSource} placeholder={placeholder}>
              {(src: any, LOADING: any, srcSetData: any) => (
                <img loading="lazy" alt={`${item && item.title ? item.title : "slide"}`} src={src} />
              )}
            </ProgressiveImage>
          </div>
        )}
        <div>
          <div className="text-block forExcerpt">
            <div className={`info  ${this.state.isPlaying && itemClass ? "is-playing" : ""}`}>
              {itemLogo !== undefined && itemLogo !== null ? (
                <h6
                  className={`list-logo ${isSheconomics ? "is-sheconomics" : "is-normal"}   ${
                    this.state.isPlaying && itemClass ? "is-playing" : ""
                  }`}
                >
                  <ProgressiveImage
                    src={
                      isSheconomics
                        ? SheconomicsLogo
                        : itemLogo.requestedResolutionUrl !== undefined && itemLogo.requestedResolutionUrl !== null
                        ? itemLogo.requestedResolutionUrl
                        : itemLogo.screenResolutionUrl
                    }
                    placeholder={isSheconomics && !this.state.isPlaying ? SheconomicsLogo : itemLogo.placeHolderUrl}
                  >
                    {(src: any, LOADING: any, srcSetData: any) => (
                      <img
                        loading="lazy"
                        alt={`${list && list.name ? `${list.name}` : "logo"}`}
                        className="hero-logo"
                        src={src}
                      />
                    )}
                  </ProgressiveImage>
                </h6>
              ) : item.title !== null && item.title !== undefined ? (
                <h2 className="subtitle">{item.title}</h2>
              ) : null}

              {DESCRIPTION !== null && DESCRIPTION !== undefined ? (
                <p className={`text-content  ${this.state.isPlaying && itemClass ? "is-playing" : ""}`}>
                  <span>{DESCRIPTION}</span>
                </p>
              ) : null}
              <div className="slide-actions">
                {!this.state.isFullVideoPlaying &&
                ((youtubeVideo !== undefined && youtubeVideo !== null) || (src !== undefined && src !== null)) ? (
                  <RdvButton
                    isOutline={true}
                    extraClassName="xmultiple playButton"
                    disabled={
                      (youtubeVideo !== undefined && youtubeVideo !== null) || (src !== undefined && src !== null)
                        ? this.state.videoIsLoaded
                        : !this.state.videoIsLoaded
                    }
                    onClick={() => {
                      this.pauseCarousel();
                      this.props.setPlayingFullScreen({ status: true });
                      this.setState({
                        currentVidSrc: src,
                        isFullVideoPlaying: true,
                      });

                      this.props.gaEvent({
                        category: "Hero Video",
                        action: "Click",
                        label: item.title ? item.title : "j1iBBlnfvku3C6NGxRav8A",
                        nonInteraction: true,
                      });

                      facebookPixelTraceEvent(event, {
                        category: item.id ? item.id : null,
                        title: `${item.title ? item.title : "Hero Video Played"}`,
                      });

                      snowplowAnalytics()
                        .then((r) =>
                          r.trackPlayVideo({
                            category: item.id ? item.id : null,
                            label: item.title ? item.title : "Hero Video Played",
                            property: item.landingPageUrl ? item.landingPageUrl : window.location.href,
                          }),
                        )
                        .catch((r) => console.debug("SnowPlow not initialized."));
                    }}
                  >
                    {SVG.play}
                    Play
                  </RdvButton>
                ) : null}
                {hasInfo ? (
                  <>
                    <div
                      className="more-info"
                      onClick={() => {
                        let url: string;
                        let type: string = "";

                        if (list) {
                          this.pauseCarousel();
                          this.openDetailsPanel(item, list);
                        } else {
                          if (item.landingPageUrl !== undefined && item.landingPageUrl !== null) {
                            if (item.landingPageUrl.includes("/person/") || item.landingPageUrl.includes("/company/")) {
                              url = item.landingPageUrl;
                              type = "_self";
                            } else {
                              this.pauseCarousel();
                              let video: string = "";
                              // if item link is to outside youtube video >> cut of the video ID
                              if (item.link && item.link.includes("youtube.com")) {
                                video = item.link.substring(item.link.indexOf("v=") + 2);
                              } else if (item.link && item.link.includes("youtu.be")) {
                                video = item.link.substring(item.link.indexOf("youtu.be") + 9);
                              } else if (item.externalId) {
                                video = item.externalId;
                              }

                              const itemAsShow: Show = {
                                id: item.id,
                                revision: item.revision,
                                description: item.description,
                                items: [
                                  {
                                    id: item.id,
                                    title: item.title,
                                    type: "Story",
                                    details: {
                                      id: item.id,
                                      backgroundImage: item.previews
                                        ? ImageHelper.fetchOptimizedImageUrl({
                                            imageType: "PREVIEW",
                                            preview: {
                                              content: item.previews,
                                              previewKind: PreviewKind.HERO,
                                              topLevelMedia: TopLevelMediaKind.IMAGE,
                                            },
                                            desiredDimensions: {
                                              containerWidthRatio: 0.75,
                                              numberOfItems: 1,
                                            },
                                            revision: item.revision,
                                          })
                                        : null,
                                      logo:
                                        item.previews !== undefined && item.previews !== null
                                          ? ImageHelper.fetchOptimizedImageUrl({
                                              imageType: "PREVIEW",
                                              preview: {
                                                content: item.previews,
                                                previewKind: PreviewKind.LOGO,
                                                topLevelMedia: TopLevelMediaKind.IMAGE,
                                              },
                                              desiredDimensions: {
                                                containerWidthRatio: 0.75,
                                                numberOfItems: 4,
                                              },
                                              revision: item.revision,
                                            })
                                          : null,
                                      title: item.title ? item.title : item.label,
                                      description: item.description,
                                      youTubeVideo: video ? video : null,
                                    } as GeneralItemDetails,
                                  },
                                ],
                              };
                              this.pauseCarousel();
                              this.openDetailsPanel(item);
                            }
                          } else if (item.permalink !== undefined && item.permalink !== null) {
                            url = item.permalink;
                            type = "_self";
                          } else if (item.link !== null && item.link !== undefined) {
                            url = item.link;
                            type = "_blank";
                          }
                          if (url) {
                            setTimeout(() => {
                              window.open(url, type);
                            }, 400);
                          }
                        }

                        gaEvent(
                          "Hero Slide More info ",
                          "Click",
                          item.title ? item.title : "j1iBBlnfvku3C6NGxRav8A",
                          true,
                        );

                        facebookPixelTraceEvent(event, {
                          category: item.id ? item.id : null,
                          title: `${item.title ? item.title : "Hero Slide More info"}`,
                        });
                      }}
                    >
                      {SVG.info}
                      <span>More Info</span>
                    </div>
                    <AddToSavedButton item={item as CarouselItem} />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="text-block upComming">
          <div className="info">
            {item.description !== null && item.description !== undefined ? (
              <p className="text-content">
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {item.description}
                </Truncate>
              </p>
            ) : null}
          </div>
        </div> */}
      </div>
    );
  }
}

export const HeroCarouselIndex = (props: Props) => {
  const {
    data,
    fetchList,
    openDetailsPanel,
    gaEvent,
    setPlayingFullScreen,
    pageYOffset,
    isDetailsPanelOpen,
    cacheData,
  } = props;
  const listId = props.list?.id;
  const list = data?.list?.list;

  const thisRef = React.useRef(null);
  const isOnScreen = pageYOffset < 300;

  const isPrerender = /HeadlessChrome/i.test(navigator.userAgent);
  const hasHydratedData = !isEmpty(window.homeHeroCarousel);

  const [hydrationData, setHydrationData] = React.useState(null);
  const isHome = /\/$|\/home$/.test(window.location.pathname);

  React.useEffect(() => {
    const shouldUseHydratedData = hasHydratedData && !list;
    if (shouldUseHydratedData) {
      const key = listId;
      const hydratedList = window.homeHeroCarousel;
      cacheData({ key, data: hydratedList });
    }
  }, [hydrationData, cacheData, listId, hasHydratedData, list]);

  React.useEffect(() => {
    if (isPrerender && !hasHydratedData && !isEmpty(data)) {
      const listJson = JSON.stringify(data);
      setHydrationData(listJson);
    }
  }, [hasHydratedData, data, isPrerender]);

  React.useEffect(() => {
    if (listId && !data && !hasHydratedData) {
      const variables = { listId };
      fetchList({ variables });
    }
  }, [listId, data, fetchList, hasHydratedData]);

  if (isEmpty(listId)) {
    return <div className="alert alert-danger about-danger">Error Loading HeroCarousel!</div>;
  }

  if (isEmpty(list)) {
    return (
      <div className="hero-container xxtra">
        <Loader isActive={true} />
      </div>
    );
  }

  const passedProps = { gaEvent, openDetailsPanel, isOnScreen, isDetailsPanelOpen, setPlayingFullScreen };
  return (
    <>
      <div ref={thisRef} className="hero-container hero-carousel">
        {!hasHydratedData && isPrerender && hydrationData ? (
          <Helmet>
            <script id={"HomeHeroCarouselData"}>{`window.homeHeroCarousel=${hydrationData}`}</script>
          </Helmet>
        ) : null}
        <HeroCarousel list={list} {...passedProps} isOnScreen={isOnScreen} />
      </div>
    </>
  );
};

const mapState = (state: AppState, props: HeroCarouselProps) => ({
  user: state.account.user,
  isDetailsPanelOpen: !isEmpty(state.detailsPanel.item),
  isPlayingFullScreen: state.fullScreenVideo.isPlayingFullScreen,
  data: props.list?.id && state.cache.store[props.list?.id],
  pageYOffset: state.app.pageYOffset,
});

const mapDispatch = {
  gaEvent: Actions.gaEvent,
  openDetailsPanel: Actions.openDetailsPanel,
  fetchList: Actions.fetchList,
  setPlayingFullScreen: Actions.setPlayingFullScreen,
  cacheData: Actions.cacheData,
};

export const HeroCarouselContainer = connect(mapState, mapDispatch)(HeroCarouselIndex);
export default HeroCarouselContainer;

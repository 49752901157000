import * as React from "react";
import { useCarouselContext } from "./context";
import isEmpty from "lodash.isempty";
import { SVG } from "../../../component-configuration/svgs";
import { Router } from "../../../utilities/router";

export const ShowDescription = () => {
  const { displayListDescriptionFlag, show } = useCarouselContext();
  const description = show && show.description && show.description !== "null" ? show.description : null;
  const viewAllOnClick = React.useCallback(() => {
    Router.navigateTo(`/browse/${show.id}`);
  }, [show]);
  if (!displayListDescriptionFlag || isEmpty(description)) return null;
  return (
    <div className="description-view-all">
      <h6 className="description">{description}</h6>
      <span className="view-all" onClick={viewAllOnClick}>
        View All {SVG.chevronRight}
      </span>
    </div>
  );
};

export default ShowDescription;

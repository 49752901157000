/**
 * @Author: Nassar Sara
 * @Date:   2018-03-27T10:49:05+02:00
 * @Email:  sara@radivision.com
 * @Project: Radivision
 * @Filename: fixed-list.ts
 * @Last modified by:   Nassar Sara
 * @Last modified time: 2018-04-22T10:12:33+02:00
 * @Copyright: Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { List, ListItemType } from "./list";

/**
 * The __typeID of the Fixed List GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_FIXED_LIST: string = "FixedList";

/**
 * A set of entities, people and stories selected manually. This may be ordered or unordered
 *
 * @export
 * @interface FixedList
 * @extends {List<D, L>}
 * @template D, L
 */
export interface FixedList<
  D extends Date | string = string,
  L extends ListItemType<D> = ListItemType<D>
> extends List<D> {}

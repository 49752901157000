import { NotificationReducer } from "./reducer";
import { NotificationModuleState } from "./types";
import sagas from "./saga";

export { NotificationModuleState };

export const notificationModule = {
  id: "notification",
  reducerMap: {
    notification: NotificationReducer,
  },
  sagas: [sagas],
};

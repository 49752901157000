/**
 * @author: Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-04-26
 * @description Declaration of the Position interface.
 * @filename position.ts
 */
import { Connection } from "./connection";
import { Node } from "./node";
import { Preview } from "./preview";

/**
 * The __typeID of the GraphQL Publication object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_PUBLICATION: string = "Publication";

/**
 * A Radivision publication.
 *
 * @export
 * @interface Publication
 * @extends {Node<D>}
 * @template D
 */
export interface Publication<D extends Date | string = string> extends Node<D> {
  /**
   * A description of the publication.
   *
   * @type {string}
   */
  description?: string;

  /**
   * A collection of media assets used as previews for the publication. Only one media asset can act as a given type of
   * preview
   *
   * @template D
   * @type Connection<D, Preview>
   */
  previews?: Connection<D, Preview>;
}

import { CacheReducer } from "./reducer";
import { CacheModuleState } from "./types";
export { CacheModuleState };
import sagas from "./saga";

export const cacheModule = {
  id: "cache",
  reducerMap: {
    cache: CacheReducer,
  },
  sagas: [sagas],
};

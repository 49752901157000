/**
 * @author: William Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-06-04
 * @description Implementation of StoryReview interface.
 * @filename well-known-position.ts
 */
import { Node } from "./node";

/**
 * The __typeID of the Well Known Position type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_WELL_KNOWN_POSITION: string = "WellKnownPosition";

/**
 * An enumeration of the different kinds of well known positions.
 */
export enum WellKnownPositionKind {
  /**
   * A board member.
   */
  BOARD_MEMBER = "BOARD_MEMBER",

  /**
   * A Chief Executive Officer.
   */
  CEO = "CEO",

  /**
   * A Chief Financial Officer.
   */
  CFO = "CFO",

  /**
   * A Chief Innovation Officer.
   */
  CINO = "CINO",

  /**
   * A Chief Information Officer.
   */
  CIO = "CIO",

  /**
   * A Chief Marketing Officer.
   */
  CMO = "CMO",

  /**
   * A Chief Operating Officer.
   */
  COO = "COO",

  /**
   * A Chief Technical Officer.
   */
  CTO = "CTO",

  /**
   * President.
   */
  PRESIDENT = "President"
}

/**
 * A well-known position.
 *
 * @extends {Node<D>}
 * @template D
 */
export interface WellKnownPosition<D extends Date | string = string>
  extends Node<D> {
  /**
   * A description of the well-known position.
   *
   * @type {string}
   */
  description?: string;

  /**
   *  The kind of the well-known position.
   *
   * @type {WellKnownPositionKind}
   */
  kind?: WellKnownPositionKind;

  /**
   * A label of the well-known position.
   *
   * @type {string}
   */
  label?: string;
}

/**
 * @author: Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-04-26
 * @description Declaration of the Position interface.
 * @filename position.ts
 */
import { Entity } from "./entity";
import { Tag } from "./tag";
import { WellKnownPosition } from "./well-known-position";

/**
 * The __typeID of the GraphQL Position object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_POSITION: string = "Position";

/**
 * The position of person in the entity
 *
 * @export
 * @interface Position
 * @template D
 */
export interface Position<D extends Date | string = string> {
  /**
   * The date and time when the inspiration was created as a Date object or an ISO-8601 formatted UTC date string.
   *
   * @template D
   * @type {D}
   */
  created?: D;

  /**
   * A description of the position
   *
   * @type {string}
   * @memberof Position
   */
  description?: string;

  /**
   * The date when the position was terminated
   *
   * @template D
   * @type {D}
   * @memberof Position
   */
  end?: D;

  /**
   * The entity hosting the position
   *
   * @type {Entity}
   * @memberof Position
   */
  entity?: Entity;

  /**
   * The ID of the position
   * 
   * @type {string}
   */
  id?: string;

  /**
   * A flag which is true if the position is current
   *
   * @type {boolean}
   * @memberof Position
   */
  isCurrent?: boolean;

  /**
   * A flag which is true if the person was a founder of the entity
   *
   * @type {boolean}
   * @memberof Position
   */
  isFounder?: boolean;

  /**
   * The date and time when the position was last modified as a Date object or an ISO-8601 formatted UTC date string.
   *
   * @template D
   * @type {D}
   */
  lastModified?: D;

  /**
   * The date when the position started
   *
   * @template D
   * @type {D}
   * @memberof Position
   */
  start?: D;

  /**
   * The tags associated with the position.
   *
   * @type {Array<Tag>}
   */
  tags?: Tag[];

  /**
   * The title of the position
   *
   * @type {string}
   * @memberof Position
   */
  title?: string;

  /**
   * The typename of the inspiration.
   *
   * @type {string}
   */
  __typename?: string;

  /**
   * The well known position
   *
   * @type {Array<WellKnownPosition>}
   * @memberof Position
   */
  wellKnownPositions?: WellKnownPosition[];
}

/**
 * @author Ahmed Serag
 * @date   2019-01-21
 * @description Declaration of the Article interface.
 * @filename article.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";
import { MediaAsset } from "./media-asset";
import { Preview } from "./preview";
import { Connection } from "./connection";

export const GRAPHQL_TYPE_ARTICLE: string = "Article";

/**
 * An article with a(n optional) clickable link.
 *
 * @export
 * @interface Article
 * @extends {Node<D>}
 * @template D
 */
export interface Article <D extends Date | string = string> extends Node<D> {

  /**
   * The URL link for the image used for the article.
   *
   * @type {string}
   * @memberof Article
   */
  name?: string;
}

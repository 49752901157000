/**
 * @author Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-04-15
 * @description Implementation of Structure related utilities.
 * @filename structure-utilities.ts
 */
import { Types } from "@radivision/common";
import { ComponentProps } from "react";
import { ComponentProperty } from "@radivision/graphql/lib/ts/graphql/component-property";
import { ComponentPropertyValue } from "@radivision/graphql/lib/ts/graphql/component-property-value";

/**
 * Handles operations on structure related components.
 *
 * @export
 * @class StructureUtilities
 */
export class StructureUtilities {
  /**
   * get the properties from graphql the Panel/Component.
   *
   * @static
   * @param {ComponentProperty[]} [componentProperties]
   * @param {ComponentPropertyValue[]} [panelProperties]
   * @returns {React.ComponentProps<any>}
   * @memberof StructureUtilities
   */
  static getComponentUtilities(
    componentProperties?: ComponentProperty[],
    panelProperties?: ComponentPropertyValue[]
  ): ComponentProps<any> {
    const props: ComponentProps<any> = {};
    // TODO Handle Component Props.
    if (!Types.isEmpty(panelProperties)) {
      for (const prop of panelProperties) {
        if (Types.isObject(prop) && Types.toString(prop.name)) {
          if (Types.isObject(prop.article)) {
            props[prop.name] = prop.article;
          } else if (Types.isObject(prop.book)) {
            props[prop.name] = prop.book;
          } else if (prop.boolean !== undefined && prop.boolean !== null) {
            props[prop.name] = Types.toBoolean(prop.boolean);
          } else if (Types.isObject(prop.dynamicList)) {
            props[prop.name] = prop.dynamicList;
          } else if (Types.isObject(prop.entity)) {
            props[prop.name] = prop.entity;
          } else if (Types.isObject(prop.fixedList)) {
            props[prop.name] = prop.fixedList;
          } else if (Types.toNumber(prop.int)) {
            props[prop.name] = Types.toNumber(prop.int);
          } else if (Types.toString(prop.json)) {
            props[prop.name] = JSON.parse(prop.json);
          } else if (Types.isObject(prop.listCollection)) {
            props[prop.name] = prop.listCollection;
          } else if (Types.isObject(prop.newsletter)) {
            props[prop.name] = prop.newsletter;
          } else if (Types.toNumber(prop.number)) {
            props[prop.name] = Types.toNumber(prop.number);
          } else if (Types.isObject(prop.onlineCourse)) {
            props[prop.name] = prop.onlineCourse;
          } else if (Types.isObject(prop.originalContent)) {
            props[prop.name] = prop.originalContent;
          } else if (Types.isObject(prop.person)) {
            props[prop.name] = prop.person;
          } else if (Types.isObject(prop.podcast)) {
            props[prop.name] = prop.podcast;
          } else if (Types.isObject(prop.poster)) {
            props[prop.name] = prop.poster;
          } else if (Types.toString(prop.string)) {
            props[prop.name] = prop.string;
          } else if (Types.toString(prop.id)) {
            props[prop.name] = prop.id;
          }
        }
      }
    }

    return props;
  }
}

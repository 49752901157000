import { call, put, select, takeLatest, all, fork } from "redux-saga/effects";
import $ from "jquery";
import {
  AUTH_RESET_VERIFICATION,
  AUTH_SIGNIN,
  AUTH_SIGNIN_VERIFY_ACCOUNT,
  AUTH_SIGNUP,
  AUTH_SIGNUP_PASSWORD,
  CHECK_ACCOUNT_EXISTS,
  CONFIRM_RESET_PASSWORD,
  RESEND_VERIFICATION_CODE,
  RESEND_ATTRIBUTES_VERIFICATION_CODE,
  RESET_PASSWORD,
  SIGNIN_VERIFY_ACCOUNT,
  SIGNIN_WITH_EMAIL_AND_PASSWORD,
  SIGNUP_VERIFY_ACCOUNT,
  SIGNUP_WITH_EMAIL_AND_PASSWORD,
  AUTH_SIGNUP_VERIFY_ACCOUNT,
  AUTHENTICATION_MODAL_ID,
  AUTH_CHOOSE_ACCOUNT_TYPE,
  SET_AUTH_STATE,
} from "./constants";
import { setToastMessage } from "../toast/actions";
import { TOAST_FAIL, TOAST_SUCCESS, TOAST_ALERT } from "../toast/constants";
import {
  signInWithEmailAndPassword,
  resetPassword,
  confirmResetPassword,
  signUpWithEmailAndPassword,
  verifyAccount,
  resendAccountVerificationCode,
  checkAccountExists,
  resendUserAttributesAccountVerificationCode,
} from "./api";
import {
  SignInWithEmailAndPasswordAction,
  ResetPasswordAction,
  ConfirmResetPasswordAction,
  SignUpWithEmailAndPasswordAction,
  VerifyAccountAction,
  ResendVerificationCodeAction,
  CheckAccountExistsAction,
  SetAuthStatePayload,
  SetAuthStateAction,
} from "./types";
import { fetchInProgress } from "../cache/actions";
import { fetchCurrentUser, updateUser } from "../account/actions";
import { setAuthState } from "./actions";
import { isValidEmail } from "../../utilities/is-valid-email";
import isEmpty from "lodash.isempty";
import { AppState } from "..";
import { initialState } from "./reducer";
import { fetchCurrentUserWorker } from "../account/saga";

export function* signUpWithEmailAndPasswordWorker(action: SignUpWithEmailAndPasswordAction) {
  try {
    yield put(fetchInProgress({ key: "signup", status: true }));
    yield call(signUpWithEmailAndPassword, action.payload);
    yield put(setAuthState({ step: AUTH_SIGNUP_VERIFY_ACCOUNT }));
    yield put(fetchInProgress({ key: "signup", status: false }));
  } catch (error) {
    switch (error.code) {
      case "UsernameExistsException": {
        yield put(setAuthState({ step: AUTH_SIGNUP, password: "" }));
        yield put(setToastMessage({ msg: error.message, type: TOAST_ALERT }));
        break;
      }
      case "UserNotConfirmedException": {
        yield put(setAuthState({ step: AUTH_SIGNUP_VERIFY_ACCOUNT }));
        const msg = "An unconfirmed account with the given email already exists.";
        yield put(setToastMessage({ msg, type: TOAST_ALERT }));
        break;
      }
      default: {
        yield put(setToastMessage({ msg: error.message, type: TOAST_FAIL }));
      }
    }
    yield put(fetchInProgress({ key: "signup", status: false }));
  }
}

export function* signInWithEmailAndPasswordWorker(action: SignInWithEmailAndPasswordAction) {
  try {
    yield put(fetchInProgress({ key: "signin", status: true }));
    yield call(signInWithEmailAndPassword, action.payload);
    const user = yield call(fetchCurrentUserWorker);
    const userAccountTypeisNotSetYet = user && user.accountType === null;
    if (userAccountTypeisNotSetYet) {
      yield put(setAuthState({ step: AUTH_CHOOSE_ACCOUNT_TYPE }));
      $(`#${AUTHENTICATION_MODAL_ID}`).modal("show");
      yield put(fetchInProgress({ key: "signin", status: false }));
      return;
    }
    yield put(fetchInProgress({ key: "signin", status: false }));
    yield put(setToastMessage({ msg: "Welcome to Radivision", type: TOAST_SUCCESS }));
    yield put(setAuthState(initialState));
    $(`#${AUTHENTICATION_MODAL_ID}`).modal("hide");
  } catch (error) {
    switch (error.code) {
      case "NotAuthorizedException": {
        yield put(setToastMessage({ msg: error.message, type: TOAST_ALERT }));
        break;
      }
      case "UserNotConfirmedException": {
        yield put(setAuthState({ step: AUTH_SIGNIN_VERIFY_ACCOUNT }));
        const msg = "Your account is not confirmed.";
        yield put(setToastMessage({ msg: msg, type: TOAST_ALERT }));
        break;
      }
      default: {
        yield put(setToastMessage({ msg: error.message, type: TOAST_FAIL }));
      }
    }
    yield put(fetchInProgress({ key: "signin", status: false }));
  }
}

export function* resetPasswordWorker(action: ResetPasswordAction) {
  const email = action.payload.email;
  try {
    if (isEmpty(email)) {
      yield put(setToastMessage({ msg: "Please enter the Email associated with your account.", type: TOAST_ALERT }));
      return;
    }
    if (!isValidEmail(email)) {
      yield put(setToastMessage({ msg: "Please enter a valid email address.", type: TOAST_ALERT }));
      return;
    }
    yield put(fetchInProgress({ key: "reset-password", status: true }));
    yield call(resetPassword, email);
    yield put(fetchInProgress({ key: "reset-password", status: false }));
    yield put(setAuthState({ step: AUTH_RESET_VERIFICATION }));
    yield;
  } catch (error) {
    switch (error.code) {
      case "NotAuthorizedException": {
        yield put(setToastMessage({ msg: error.message, type: TOAST_ALERT }));
        break;
      }
      case "UserNotFoundException": {
        yield put(setToastMessage({ msg: error.message, type: TOAST_ALERT }));
        break;
      }
      case "InvalidParameterException": {
        yield put(setAuthState({ step: AUTH_SIGNUP_VERIFY_ACCOUNT }));
        const msg = "The account associated with the given email is unconfirmed";
        yield put(setToastMessage({ msg, type: TOAST_ALERT }));
        break;
      }
      default: {
        yield put(setToastMessage({ msg: error.message, type: TOAST_FAIL }));
      }
    }
    yield put(fetchInProgress({ key: "reset-password", status: false }));
  }
}
export function* confirmResetPasswordWorker(action: ConfirmResetPasswordAction) {
  try {
    yield put(fetchInProgress({ key: "confirm-reset-password", status: true }));
    yield call(confirmResetPassword, action.payload);
    yield put(fetchInProgress({ key: "confirm-reset-password", status: false }));
    yield put(setAuthState({ step: AUTH_SIGNIN }));
    yield put(
      setToastMessage({ msg: "Password was reset successfully. Log in using your new password.", type: TOAST_SUCCESS }),
    );
  } catch (error) {
    switch (error.code) {
      case "NotAuthorizedException": {
        yield put(setToastMessage({ msg: error.message, type: TOAST_ALERT }));
        break;
      }
      case "CodeMismatchException": {
        yield put(setToastMessage({ msg: error.message, type: TOAST_FAIL }));
        yield put(setAuthState({ step: AUTH_RESET_VERIFICATION }));
        break;
      }
      default: {
        yield put(setToastMessage({ msg: error.message, type: TOAST_FAIL }));
      }
    }
    yield put(fetchInProgress({ key: "confirm-reset-password", status: false }));
  }
}
export function* signinVerifyAccountWorker(action: VerifyAccountAction) {
  const { email, password } = yield select((state: AppState) => state.authentication);
  try {
    yield put(fetchInProgress({ key: "verify-account", status: true }));
    yield call(verifyAccount, action.payload);
    yield call(signInWithEmailAndPassword, { email, password });
    yield put(fetchCurrentUser());
    yield put(setToastMessage({ msg: "Welcome to Radivision", type: TOAST_SUCCESS }));
    yield put(fetchInProgress({ key: "verify-account", status: false }));
    $(`#${AUTHENTICATION_MODAL_ID}`).modal("hide");
  } catch (error) {
    switch (error.code) {
      case "NotAuthorizedException": {
        yield put(setToastMessage({ msg: error.message, type: TOAST_ALERT }));
        break;
      }
      default: {
        yield put(setToastMessage({ msg: error.message, type: TOAST_FAIL }));
        break;
      }
    }
    yield put(fetchInProgress({ key: "verify-account", status: false }));
  }
}
export function* signupVerifyAccountWorker(action: VerifyAccountAction) {
  const { email, password } = yield select((state: AppState) => state.authentication);
  try {
    yield put(fetchInProgress({ key: "verify-account", status: true }));
    yield call(verifyAccount, action.payload);
    yield call(signInWithEmailAndPassword, { email, password });
    yield call(fetchCurrentUserWorker);
    yield put(fetchInProgress({ key: "verify-account", status: false }));
    yield put(setAuthState({ step: AUTH_CHOOSE_ACCOUNT_TYPE }));
    $(`#${AUTHENTICATION_MODAL_ID}`).modal("show");
  } catch (error) {
    switch (error.code) {
      case "NotAuthorizedException": {
        yield put(setToastMessage({ msg: error.message, type: TOAST_ALERT }));
        break;
      }
      default: {
        yield put(setToastMessage({ msg: error.message, type: TOAST_FAIL }));
        break;
      }
    }
    yield put(fetchInProgress({ key: "verify-account", status: false }));
  }
}
export function* resendVerificationCodeWorker(action: ResendVerificationCodeAction) {
  try {
    yield put(fetchInProgress({ key: "resend-verification-code", status: true }));
    yield call(resendAccountVerificationCode, action.payload);
    yield put(setAuthState({ resendAt: new Date().getTime() }));
    yield put(fetchInProgress({ key: "resend-verification-code", status: false }));
    yield put(
      setToastMessage({
        msg: "Verification code email was sent",
        type: TOAST_SUCCESS,
      }),
    );
  } catch (error) {
    switch (error.code) {
      case "NotAuthorizedException": {
        yield put(setToastMessage({ msg: error.message, type: TOAST_ALERT }));
        break;
      }
      default: {
        yield put(setToastMessage({ msg: error.message, type: TOAST_FAIL }));
        break;
      }
    }
    yield put(fetchInProgress({ key: "resend-verification-code", status: false }));
  }
}

export function* resendUserAttributesVerificationCodeWorker(action: ResendVerificationCodeAction) {
  try {
    yield put(fetchInProgress({ key: "resend-verification-code", status: true }));
    yield call(resendUserAttributesAccountVerificationCode);
    yield put(setAuthState({ resendAt: new Date().getTime() }));
    yield put(fetchInProgress({ key: "resend-verification-code", status: false }));
    yield put(
      setToastMessage({
        msg: "Verification code email was sent",
        type: TOAST_SUCCESS,
      }),
    );
  } catch (error) {
    switch (error.code) {
      case "NotAuthorizedException": {
        yield put(setToastMessage({ msg: error.message, type: TOAST_ALERT }));
        break;
      }
      default: {
        yield put(setToastMessage({ msg: error.message, type: TOAST_FAIL }));
        break;
      }
    }
    yield put(fetchInProgress({ key: "resend-verification-code", status: false }));
  }
}

export function* checkAccountExistsWorker(action: CheckAccountExistsAction) {
  try {
    yield put(fetchInProgress({ key: "check-account-exists", status: true }));
    yield call(checkAccountExists, action.payload.email);
    yield put(fetchInProgress({ key: "check-account-exists", status: false }));
    yield put(setAuthState({ step: AUTH_SIGNUP_PASSWORD }));
  } catch (error) {
    switch (error.code) {
      case "UsernameExistsException": {
        yield put(setToastMessage({ msg: error.message, type: TOAST_ALERT }));
        break;
      }
      case "UserNotConfirmedException": {
        yield put(setAuthState({ step: AUTH_SIGNIN_VERIFY_ACCOUNT }));
        const msg = "Your account is not confirmed.";
        yield put(setToastMessage({ msg: msg, type: TOAST_ALERT }));
        break;
      }
      default: {
        yield put(setToastMessage({ msg: error.message, type: TOAST_FAIL }));
        break;
      }
    }
    yield put(fetchInProgress({ key: "check-account-exists", status: false }));
  }
}
export function* setAuthStateWorker(action: SetAuthStateAction) {
  const { step } = action.payload;

  try {
    if (!isNaN(step)) {
      const elem = window.document.getElementById(AUTHENTICATION_MODAL_ID);
      if (elem) elem.scrollTo({ top: 0 });
    }
  } catch (error) {}
}

export function* watchSignInWithEmailAndPassword() {
  yield takeLatest(SIGNIN_WITH_EMAIL_AND_PASSWORD, signInWithEmailAndPasswordWorker);
}

export function* watchSignUpWithEmailAndPassword() {
  yield takeLatest(SIGNUP_WITH_EMAIL_AND_PASSWORD, signUpWithEmailAndPasswordWorker);
}

export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPasswordWorker);
}

export function* watchConfirmResetPassword() {
  yield takeLatest(CONFIRM_RESET_PASSWORD, confirmResetPasswordWorker);
}

export function* watchSignupVerifyAccount() {
  yield takeLatest(SIGNUP_VERIFY_ACCOUNT, signupVerifyAccountWorker);
}

export function* watchSigninVerifyAccount() {
  yield takeLatest(SIGNIN_VERIFY_ACCOUNT, signinVerifyAccountWorker);
}

export function* watchResendVerificationCode() {
  yield takeLatest(RESEND_VERIFICATION_CODE, resendVerificationCodeWorker);
}
export function* watchResendUserAttributesVerificationCode() {
  yield takeLatest(RESEND_ATTRIBUTES_VERIFICATION_CODE, resendUserAttributesVerificationCodeWorker);
}

export function* watchCheckAccountExists() {
  yield takeLatest(CHECK_ACCOUNT_EXISTS, checkAccountExistsWorker);
}
export function* watchSetAuthState() {
  yield takeLatest(SET_AUTH_STATE, setAuthStateWorker);
}

const sagas = [
  watchCheckAccountExists,
  watchConfirmResetPassword,
  watchResendVerificationCode,
  watchResendUserAttributesVerificationCode,
  watchResetPassword,
  watchSignInWithEmailAndPassword,
  watchSignUpWithEmailAndPassword,
  watchSignupVerifyAccount,
  watchSigninVerifyAccount,
  watchSetAuthState,
];

export default function* root() {
  yield all(sagas.map((saga) => fork(saga)));
}

/**
 * @author William Alexander Livesley
 * @date   2018-03-22
 * @description Declaration of the HtmlDocumentHeader interface.
 * @filename html-document-header.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { HtmlDocumentLinkHeaderRelType } from "./html-document-link-header-rel-type";
import { HtmlDocumentMetaHeaderHttpEquivType } from "./html-document-meta-header-http-equiv-type";
import { HtmlDocumentHeaderType } from "./html-document-header-type";

/**
 * The __typeID of the HTML Document Header GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_HTML_DOCUMENT_HEADER: string = "HtmlDocumentHeader";

/**
 * An HTML document header.
 *
 * @interface
 * @template D
 */
export interface HtmlDocumentHeader<D extends Date | string = string> {
  /**
   * The charset value of a meta HTML document header (META).
   *
   * @type {string}
   */
  charset?: string;

  /**
   * The content of a meta HTML document header (META).
   *
   * @type {string}
   */
  content?: string;

  /**
   * A description of the HTML document header - for metadata use.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The URL of the HTML document header link (LINK).
   *
   * @type {string}
   */
  href?: string;

  /**
   * The language associated with the HTML document header link. This is only required when an alternate relationship
   * is specified (LINK).
   *
   * @type {string}
   */
  hreflang?: string;

  /**
   * An HTTP header for the information/value of the content attribute (content-type, default-style, refresh) of a meta
   * HTML document header (META).
   *
   * @template D
   * @type {HtmlDocumentMetaHeaderHttpEquivType<D>}
   */
  httpEquiv?: HtmlDocumentMetaHeaderHttpEquivType<D>;

  /**
   * The ID of the HTML document header.
   *
   * @type {string}
   */
  id?: string;

  /**
   * A flag which indicates that the HTML document header is marked for deletion.
   *
   * @type {string}
   */
  isDeleted?: boolean;

  /**
   * The name of the meta HTML document header (META).
   *
   * @type {string}
   */
  name?: string;

  /**
   * The name of an open-graph property in a meta HTML document header (META).
   *
   * @type {string}
   */
  property?: string;

  /**
   * The relationship of the HTML document header link (LINK)
   *
   * @template D
   * @type {HtmlDocumentLinkHeaderRelType}
   */
  rel?: HtmlDocumentLinkHeaderRelType<D>;

  /**
   * The value of the title header (TITLE)
   *
   * @type {string}
   */
  title?: string;

  /**
   * The type of the header.
   *
   * @template D
   * @type {HtmlDocumentHeaderType<D>}
   */
  type?: HtmlDocumentHeaderType<D>;

  /**
   * The typename of the header.
   *
   * @type {string}
   */
  __typename?: string;
}

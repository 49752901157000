import { fetchQuery } from "react-relay";
import { ENVIRONMENT } from "../../relay/relay-environment";
import { htmlPageQuery } from "../../relay/queries/html-page";
import {
  FetchListPayload,
  FetchListCollectionPayload,
  FetchListItemPayload,
  FetchHtmlPagePayload,
  FetchOriginalShowsPayload,
} from "./types";
import { LIST_QUERY } from "../../relay/queries/list-query";
import { ORIGINAL_SHOWS_QUERY } from "../../relay/queries/original-shows";
import { STORY_QUERY } from "../../relay/queries/story-query";
import { FETCH_ENTITY_QUERY } from "../../relay/queries/fetch-entity";
import { LIST_COLLECTION_QUERY } from "../../relay/queries/list-collection-query";
import { LIST_ITEM_PERSON_QUERY } from "../../relay/queries/list-item-person-query";
import { LIST_ITEM_ENTITY_QUERY } from "../../relay/queries/list-item-entity-query";

import isEmpty from "lodash.isempty";
import { MEDIA_ASSETS_LIST_QUERY } from "../../relay/queries/media-assets-list-query";
import { PERSON_QUERY } from "../../relay/queries/person-query";

export const fetchHtmlPage = (payload: FetchHtmlPagePayload): any => {
  const { variables } = payload;
  let alias: string = variables?.alias;
  alias = isEmpty(variables.alias) ? "home" : alias.toLowerCase();
  return fetchQuery(ENVIRONMENT, htmlPageQuery, {
    ...variables,
    ...{ alias },
  });
};

export const fetchList = (payload: FetchListPayload): any => {
  const options = { force: true };
  const { variables } = payload;
  return fetchQuery(ENVIRONMENT, LIST_QUERY, variables, options);
};

export const fetchListItem = (payload: FetchListItemPayload): any => {
  return fetchQuery(ENVIRONMENT, payload.type === "person" ? LIST_ITEM_PERSON_QUERY : LIST_ITEM_ENTITY_QUERY, {
    id: payload.itemId,
  }).then((res) => {
    const KEY = payload.type === "person" ? "person" : "entity";
    return res[KEY].hasErrors ? Promise.reject(res[KEY].errors) : Promise.resolve(res[KEY][KEY]);
  });
};

export const fetchListCollection = (payload: FetchListCollectionPayload): any => {
  const options = { force: true };
  const { variables } = payload;
  return fetchQuery(ENVIRONMENT, LIST_COLLECTION_QUERY, variables, options);
};

export const fetchOriginalShows = (payload: FetchOriginalShowsPayload): any => {
  const { variables } = payload;
  return fetchQuery(ENVIRONMENT, ORIGINAL_SHOWS_QUERY, variables);
};

export const fetchStoryItem = (itemId: string) => {
  return fetchQuery(
    ENVIRONMENT,
    STORY_QUERY,
    {
      id: itemId,
    },
    {
      force: true,
    },
  ).then((result) =>
    result.story.hasErrors === false ? Promise.resolve(result.story.node) : Promise.reject(result.story.errors),
  );
};

export const fetchEntity = (id: string) => {
  return fetchQuery(
    ENVIRONMENT,
    FETCH_ENTITY_QUERY,
    {
      id,
    },
    {
      force: true,
    },
  ).then((result) =>
    result.entity.hasErrors === false ? Promise.resolve(result.entity.entity) : Promise.reject(result.entity.errors),
  );
};
export const fetchPerson = (id: string) => {
  return fetchQuery(
    ENVIRONMENT,
    PERSON_QUERY,
    {
      id,
    },
    {
      force: true,
    },
  ).then((result) =>
    result.person.hasErrors === false ? Promise.resolve(result.person.person) : Promise.reject(result.person.errors),
  );
};

export const fetchMediaAssetsList = async (id: string) => {
  return fetchQuery(ENVIRONMENT, MEDIA_ASSETS_LIST_QUERY, {
    id,
  }).then((result: any) =>
    result.list.hasErrors === false ? Promise.resolve(result.list.list) : Promise.reject(result.list.errors),
  );
};

export const fetchListLoadMore = async (listId: string, after: string) => {
  const input = {
    listId,
    first: 24,
    after,
  };
  return fetchQuery(ENVIRONMENT, LIST_QUERY, input).then((result: any) =>
    result.list.hasErrors === false ? Promise.resolve(result) : Promise.reject(result.list.errors),
  );
};

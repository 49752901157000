/**
 * @author William Alexander Livesley
 * @date   2018-04-14
 * @description Declaration of the ArticleStory interface.
 * @filename article-story.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Story } from "./story";

/**
 * The __typeID of the API Article Story GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_ARTICLE_STORY: string = "ArticleStory";

/**
 *
 *
 */
export enum ArticleStoryKind {
  /**
   *
   *
   */
  TECH_NEWS = "TECH_NEWS",

  /**
   *
   *
   */
  VENTURE_NEWS = "VENTURE NEWS"
}

/**
 * A story concerning an article.
 *
 * @extends {Story}
 * @template D
 * @interface
 */
export interface ArticleStory<D extends Date | string = string>
  extends Story<D> {
  /**
   * The URL of the article.
   *
   * @type {string}
   */
  link?: string;

  /**
   *
   *
   * @type {ArticleStoryKind}
   * @memberof ArticleStory
   */
  kind?: ArticleStoryKind;

  /**
   * The time to read the article in seconds.
   *
   * @type {number}
   */
  timeToReadInSeconds?: number;
}

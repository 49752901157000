/**
* @author: Ahmed Samer
* @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
* @date: 2019-01-22
* @filename: instagram-post-content-item.ts
* @description: Declaration of instagram post content interface
*/

import { InstagramPostContentItemKind } from "./instagram-post-content-item-kind";


/**
 * The __typeID of the GraphQL instagram post content object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_INSTAGRAM_POST_CONTENT: string = "InstagramPostContentItem";


/**
 * The content of an item in an Instagram post
 *
 * @export
 * @interface InstagramPostContentItem
 */
export interface InstagramPostContentItem {
  /**
   * The height of the item
   *
   * @type {string}
   * @memberof InstagramPostContentItem
   */
  height: string;

  /**
   * The kind of the content
   *
   * @type {InstagramPostContentItemKind}
   * @memberof InstagramPostContentItem
   */
  kind: InstagramPostContentItemKind;

  /**
   *The URL of the link to the item
   *
   * @type {string}
   * @memberof InstagramPostContentItem
   */
  link: string;

  /**
   * The width of the item
   *
   * @type {string}
   * @memberof InstagramPostContentItem
   */
  width: string;
} 
/**
 * @author W. Alex Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2018-04-01
 * @description Implements the MediaAssetFileStatusKind enum containing an enumeration of the kinds of asset file
 * statuses and the MediaAssetFileStatus interface used to declare properties of a media asset file status.
 * @filename media-asset-file-status.ts
 */
import { Node } from "./node";

/**
 * The __typeID of the GraphQL Media Asset File Status object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_MEDIA_ASSET_FILE_STATUS: string = "MediaAssetFileStatus";

/**
 * An enumeration of the kinds of the media asset file statuses.
 *
 * @enum {string}
 */
export enum MediaAssetFileStatusKind {
  /**
   * The media asset file is available.
   */
  AVAILABLE = "AVAILABLE",

  /**
   * The media asset file is created but not yet available.
   */
  CREATED = "CREATED",

  /**
   * The media asset file is deleted.
   */
  DELETED = "DELETED",

  /**
   * The media asset file is uploaded but the attempt made to process the media asset file has failed.
   */
  ERROR = "ERROR",

  /**
   * The media asset file is uploaded and is being processed.
   */
  PROCESSING = "PROCESSING"
}

/**
 * The properties of a media asset file status.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface MediaAssetFileStatus<D extends Date | string = string>
  extends Node<D> {
  /**
   * A description of the media asset file status.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The kind of the status of a media asset file.
   *
   * @type {MediaAssetFileStatusKind}
   */
  kind?: MediaAssetFileStatusKind;

  /**
   * A label of the media asset file status.
   *
   * @type {string}
   */
  label?: string;
}

import { graphql } from "react-relay";

export const LIST_ITEM_PERSON_QUERY = graphql`
  query listItemPersonQuery($id: ID!) {
    person(id: $id) {
      hasErrors
      person {
        __typename
        description
        fullName
        landingPageUrl
        id
        previews {
          count
          edges {
            cursor
            preview {
              link
              kind
              mediaAsset {
                id
                files {
                  id
                  name
                  path
                }
                topLevelMediaType {
                  kind
                  label
                }
              }
            }
          }
        }
        responsibility
      }
    }
  }
`;

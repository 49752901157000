import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import { getPreviewImageUrl } from "./selectors";
import { AppState } from "../../redux";
import { DEFAULTS } from "../../utilities/defaults";
import ProgressiveImage from "react-progressive-image";
import { Person } from "@radivision/graphql/lib/ts/graphql/person";
import { Entity } from "@radivision/graphql/lib/ts/graphql/entity";
import { Router } from "../../utilities/router";
import { CloseDetailsPanelAction } from "../../redux/details-panel/types";
import { getProfileInfo } from "../../utilities/get-profile-info";
import { SetProfileActiveTabAction, SetProfileActiveTabPayload } from "../../redux/account/types";

interface ComponentProps {}

interface MapDispatchProps {
  closeDetailsPanel: () => CloseDetailsPanelAction;
  setProfileActiveTab: (payload: SetProfileActiveTabPayload) => SetProfileActiveTabAction;
}
interface MapStateProps {
  item: any;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;
const DEFAULT_PERSON_PREVIEW = `${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_Person_SQUARE"]}?wip=300`;
const DEFAULT_COMPANY_PREVIEW = `${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_Entity_SQUARE"]}?wip=300`;

export const Component = (props: Props) => {
  const { item, closeDetailsPanel, setProfileActiveTab } = props;

  const hasCast =
    item &&
    item?.peopleEntities &&
    item?.peopleEntities?.some(({ person, entity }: { person: Person; entity: Entity }) => person || entity);

  const hasMultiCast = item?.peopleEntities?.length > 1;

  const goTo = React.useCallback(
    (url) => (e) => {
      e.preventDefault();
      if (url) {
        Router.navigateTo(url);
        closeDetailsPanel();
        setProfileActiveTab({ activeTab: "Posts" });
      }
    },
    [],
  );

  if (!hasCast) return null;
  return (
    <div className="details-panel-section detail-panel-cast">
      <h3 className="section-title">Featured</h3>
      <div className="episode-cast">
        {item?.peopleEntities?.map(({ person, entity }: { person: Person; entity: Entity }, index: number) => {
          const personPicture = getPreviewImageUrl(person);
          const companyPicture = getPreviewImageUrl(entity);
          const { profileUrl: entityUrl } = getProfileInfo(entity);
          const { profileUrl: personUrl } = getProfileInfo(person);
          return (
            <div key={`person-${index}-${person?.fullName}`} className={hasMultiCast ? "cast-item multi" : "cast-item"}>
              <div className="cast-multi">
                <div className={hasMultiCast ? "cast-item-avatars multi" : "cast-item-avatars"}>
                  <a href={person?.landingPageUrl} onClick={goTo(personUrl)} target="_self" className="cast-person">
                    {person && (
                      <ProgressiveImage src={personPicture?.requestedResolutionUrl || DEFAULT_PERSON_PREVIEW}>
                        {(src: any, LOADING: any, srcSetData: any) => {
                          return (
                            <img
                              loading="lazy"
                              style={{ opacity: LOADING ? 0 : 1, transition: "opacity 0.5s ease-in" }}
                              alt={`${item && item.name ? item.name : ""}`}
                              src={src}
                              className="cast-person-img"
                            />
                          );
                        }}
                      </ProgressiveImage>
                    )}
                  </a>
                </div>
                <div className={hasMultiCast ? "cast-item-info multi" : "cast-item-info"}>
                  <h4 className="cast-item-info-title">
                    <a href={personUrl} onClick={goTo(personUrl)}>
                      {person?.fullName}
                    </a>
                  </h4>
                  <p>{person?.responsibility}</p>
                </div>
              </div>
              <div className="cast-multi">
                <div className={hasMultiCast ? "cast-item-avatars multi" : "cast-item-avatars"}>
                  {entity ? (
                    <a
                      href={entity.landingPageUrl || "#"}
                      onClick={goTo(entityUrl)}
                      target="_self"
                      className="cast-company"
                    >
                      <ProgressiveImage src={companyPicture?.requestedResolutionUrl || DEFAULT_COMPANY_PREVIEW}>
                        {(src: any, LOADING: any, srcSetData: any) => {
                          return (
                            <img
                              style={{ opacity: LOADING ? 0 : 1, transition: "opacity 0.5s ease-in" }}
                              loading="lazy"
                              alt={entity.name}
                              src={src}
                              className="cast-company-img"
                            />
                          );
                        }}
                      </ProgressiveImage>
                    </a>
                  ) : null}
                </div>
                <div className={hasMultiCast ? "cast-item-info multi" : "cast-item-info"}>
                  {entity ? (
                    <h4 className="cast-item-info-title">
                      <a href={entityUrl} onClick={goTo(entityUrl)}>
                        {entity?.name}
                      </a>
                    </h4>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapState = (state: AppState) => {
  const { item } = state.detailsPanel;
  const { currentHash } = state.app;
  const [_title, itemId, listId] = currentHash ? String(currentHash).split("/") : [];
  const device = state.app.device;
  return {
    device,
    item,
  };
};

const mapDispatch = {
  closeDetailsPanel: Actions.closeDetailsPanel,
  setProfileActiveTab: Actions.setProfileActiveTab,
};

export const ItemCast = connect(mapState, mapDispatch)(Component);

/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-06-03
 * @description Implementation of the GraphQL query to retrieve the story.
 * @filename story-query.tsx
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL query to retrieve the current user.
 *
 * @type {GraphQLTaggedNode}
 */
export const STORY_QUERY: GraphQLTaggedNode = graphql`
  query storyQuery($id: ID!) {
    story(id: $id) {
      hasErrors
      errors {
        id
        location
      }
      node {
        ... on PitchVideoStory {
          ...pitchVideoStory @relay(mask: false)
        }
        ... on OriginalContentStory {
          ...originalContentStory @relay(mask: false)
        }
        ... on PodcastStory {
          ...podcastStory @relay(mask: false)
        }
        ... on ArticleStory {
          ...articleStory_item @relay(mask: false)
        }
        ... on BookStory {
          ...bookStory_item @relay(mask: false)
        }
        ... on NewsletterStory {
          ...newsletterStory_item @relay(mask: false)
        }
        ... on OnlineCourseStory {
          ...onlineCourseStory_item @relay(mask: false)
        }
        ... on RadivisionPost {
          ...radivisionPost_item @relay(mask: false)
        }
      }
    }
  }
`;

/**
 * @author Ahmed Samer
 * @date   2021-08-02
 * @description Declaration of the Notification interface.
 * @filename Notification.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the Notification type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_NOTIFICATION: string = "Notification";

/**
 *  An Notification.
 *
 * @export
 * @interface Notification
 * @extends {Node<D>}
 * @template D
 */
export interface Notification<D extends Date | string = string>
  extends Node<D> {
  /**
   * The amount of the Notification.
   *
   * @type {number}
   * @memberof NotificationModel
   */
  kind?: NotificationKind;
  /**
   * The currency in which the Notification was made.
   *
   * @type {KindModel}
   * @memberof NotificationModel
   */
  providers?: NotificationProviderKind;

  /**
   * The title in which the Notification was made.
   *
   * @type {EntitySummaryModel}
   * @memberof NotificationModel
   */
  title?: string;

  /**
   * The body when the Notification was made.
   *
   * @type {string}
   * @memberof NotificationModel
   */
  body?: string;

  /**
   * The date and time when the Notification was ended.
   *
   * @type {string}
   * @memberof NotificationModel
   */
  consumers?: string[];

  /**
   *
   *
   * @type {NotificationStatus}
   * @memberof Notification
   */
  status: NotificationStatus;
}

/**
 *
 *
 * @export
 * @enum {number}
 */
export enum NotificationKind {
  DIRECT = "DIRECT",
  GROUP = "GROUP",
}

/**
 *
 *
 * @export
 * @enum {number}
 */
export enum NotificationProviderKind {
  APN = "APN",
  EMAIL = "EMAIL",
  FCM = "FCM",
  SMS = "SMS",
}

/**
 *
 *
 * @export
 * @enum {number}
 */
export enum NotificationStatus {
  /**
   * The Notification  is available.
   */
  SEEN = "SEEN",

  /**
   * The Notification  is created but not yet available.
   */
  CREATED = "CREATED",

  /**
   * The Notification  is deleted.
   */
  DELETED = "DELETED",

  /**
   * The Notification  is uploaded but the attempt made to process the Notification  has failed.
   */
  ERROR = "ERROR",

  /**
   * The Notification  is uploaded and is being expired.
   */
  EXPIRED = "EXPIRED",

  /**
   * The Notification  is uploaded and is being processed.
   */
  PROCESSING = "PROCESSING",
}

import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import { VerifyAccountAction, VerifyAccountPayload } from "../../../redux/authentication/types";
import { AUTH_SIGNIN_VERIFY_ACCOUNT } from "../../../redux/authentication/constants";
import { VerifyAccount } from "./verify-account";

interface MapDispatchProps {
  signinVerifyAccount: (payload: VerifyAccountPayload) => VerifyAccountAction;
}

interface MapStateProps {
  step: number;
  email: string;
  verificationCode: string;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { step, email, verificationCode, signinVerifyAccount } = props;

  const shouldDisplay = step === AUTH_SIGNIN_VERIFY_ACCOUNT;

  const onSubmit = React.useCallback(() => {
    signinVerifyAccount({ email, verificationCode });
  }, [email, signinVerifyAccount, verificationCode]);

  if (!shouldDisplay) {
    return null;
  }

  return <VerifyAccount onSubmit={onSubmit} />;
};

const mapState = (state: AppState) => ({
  step: state.authentication.step,
  email: state.authentication.email,
  verificationCode: state.authentication.verificationCode,
});

const mapDispatch = {
  setToastMessage: Actions.setToastMessage,
  signinVerifyAccount: Actions.signinVerifyAccount,
};

export const SignInVerifyAccount = connect(mapState, mapDispatch)(Component);

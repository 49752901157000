/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type TopLevelMediaKind = "APPLICATION" | "AUDIO" | "DOCUMENT" | "IMAGE" | "TEXT" | "VIDEO" | "%future added value";
export type mediaAssetsListQueryVariables = {
    readonly id: string;
};
export type mediaAssetsListQueryResponse = {
    readonly list: {
        readonly hasErrors: boolean | null;
        readonly list: {
            readonly name?: string | null;
            readonly label?: string | null;
            readonly description?: string | null;
            readonly title?: string | null;
            readonly subTitle?: string | null;
            readonly itemsTypes?: ReadonlyArray<{
                readonly __typename: string;
            }> | null;
            readonly items?: {
                readonly count: number | null;
                readonly edges: ReadonlyArray<{
                    readonly listItem: {
                        readonly item: ({
                            readonly __typename: string;
                            readonly created?: unknown | null;
                            readonly description?: string | null;
                            readonly durationInMilliseconds?: number | null;
                            readonly files?: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string | null;
                                readonly path: string | null;
                            }> | null;
                            readonly id?: string;
                            readonly label?: string | null;
                            readonly revision?: string | null;
                            readonly topLevelMediaType?: {
                                readonly kind: TopLevelMediaKind | null;
                                readonly label: string | null;
                            } | null;
                        } & ({
                            readonly __typename: "MediaAsset";
                            readonly created: unknown | null;
                            readonly description: string | null;
                            readonly durationInMilliseconds: number | null;
                            readonly files: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string | null;
                                readonly path: string | null;
                            }> | null;
                            readonly id: string;
                            readonly label: string | null;
                            readonly revision: string | null;
                            readonly topLevelMediaType: {
                                readonly kind: TopLevelMediaKind | null;
                                readonly label: string | null;
                            } | null;
                        } | {
                            /*This will never be '% other', but we need some
                            value in case none of the concrete values match.*/
                            readonly __typename: "%other";
                        })) | null;
                    } | null;
                }> | null;
            } | null;
        } | null;
    } | null;
};
export type mediaAssetsListQuery = {
    readonly response: mediaAssetsListQueryResponse;
    readonly variables: mediaAssetsListQueryVariables;
};



/*
query mediaAssetsListQuery(
  $id: ID!
) {
  list(id: $id) {
    hasErrors
    list {
      __typename
      ... on List {
        name
        label
        description
        title
        subTitle
        itemsTypes {
          __typename
          ... on Node {
            id
          }
          ... on SearchHit {
            id
          }
          ... on Tag {
            id
          }
        }
        items {
          count
          edges {
            listItem {
              item {
                __typename
                ... on MediaAsset {
                  created
                  description
                  durationInMilliseconds
                  files {
                    id
                    name
                    path
                  }
                  id
                  label
                  revision
                  topLevelMediaType {
                    kind
                    label
                    id
                  }
                }
                ... on Node {
                  id
                }
                ... on SearchHit {
                  id
                }
                ... on Tag {
                  id
                }
              }
              id
            }
          }
        }
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subTitle",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "durationInMilliseconds",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "files",
  "storageKey": null,
  "args": null,
  "concreteType": "MediaAssetFile",
  "plural": true,
  "selections": [
    (v12/*: any*/),
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "path",
      "args": null,
      "storageKey": null
    }
  ]
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revision",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "mediaAssetsListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "list",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ListPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "list",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "itemsTypes",
                "storageKey": null,
                "args": null,
                "concreteType": null,
                "plural": true,
                "selections": [
                  (v8/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "items",
                "storageKey": null,
                "args": null,
                "concreteType": "ListItemsConnection",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ListItemEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "listItem",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ListItem",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "item",
                            "storageKey": null,
                            "args": null,
                            "concreteType": null,
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "type": "MediaAsset",
                                "selections": [
                                  (v10/*: any*/),
                                  (v5/*: any*/),
                                  (v11/*: any*/),
                                  (v13/*: any*/),
                                  (v12/*: any*/),
                                  (v4/*: any*/),
                                  (v14/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "topLevelMediaType",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "TopLevelMediaType",
                                    "plural": false,
                                    "selections": [
                                      (v15/*: any*/),
                                      (v4/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "mediaAssetsListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "list",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ListPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "list",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "itemsTypes",
                "storageKey": null,
                "args": null,
                "concreteType": null,
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v12/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "items",
                "storageKey": null,
                "args": null,
                "concreteType": "ListItemsConnection",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ListItemEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "listItem",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ListItem",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "item",
                            "storageKey": null,
                            "args": null,
                            "concreteType": null,
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v12/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "type": "MediaAsset",
                                "selections": [
                                  (v10/*: any*/),
                                  (v5/*: any*/),
                                  (v11/*: any*/),
                                  (v13/*: any*/),
                                  (v4/*: any*/),
                                  (v14/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "topLevelMediaType",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "TopLevelMediaType",
                                    "plural": false,
                                    "selections": [
                                      (v15/*: any*/),
                                      (v4/*: any*/),
                                      (v12/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          (v12/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v12/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "mediaAssetsListQuery",
    "id": null,
    "text": "query mediaAssetsListQuery(\n  $id: ID!\n) {\n  list(id: $id) {\n    hasErrors\n    list {\n      __typename\n      ... on List {\n        name\n        label\n        description\n        title\n        subTitle\n        itemsTypes {\n          __typename\n          ... on Node {\n            id\n          }\n          ... on SearchHit {\n            id\n          }\n          ... on Tag {\n            id\n          }\n        }\n        items {\n          count\n          edges {\n            listItem {\n              item {\n                __typename\n                ... on MediaAsset {\n                  created\n                  description\n                  durationInMilliseconds\n                  files {\n                    id\n                    name\n                    path\n                  }\n                  id\n                  label\n                  revision\n                  topLevelMediaType {\n                    kind\n                    label\n                    id\n                  }\n                }\n                ... on Node {\n                  id\n                }\n                ... on SearchHit {\n                  id\n                }\n                ... on Tag {\n                  id\n                }\n              }\n              id\n            }\n          }\n        }\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '4bdfbc49a9d25249c12c264b947f5bdf';
export default node;

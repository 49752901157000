/**
 * @author Ahmed Samer
 * @date   2021-08-17
 * @description Declaration of the Opportunities interface.
 * @filename Opportunities.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the Opportunities type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_OPPORTUNITIES: string = "Opportunities";

/**
 *  An Opportunities.
 *
 * @export
 * @interface Opportunities
 * @extends {Node<D>}
 * @template D
 */
export interface Opportunities<D extends Date | string = string>
  extends Node<D> {
  /**
   *
   *
   * @type {OpportunitiesItem}
   * @memberof Opportunities
   */
  productAndServices?: OpportunitiesItem;

  /**
   *
   *
   * @type {OpportunitiesItem}
   * @memberof Opportunities
   */
  collaboration?: OpportunitiesItem;

  /**
   *
   *
   * @type {OpportunitiesItem}
   * @memberof Opportunities
   */
  careers?: OpportunitiesItem;
}

/**
 *
 *
 * @export
 * @interface OpportunitiesItem
 */
export interface OpportunitiesItem {
  /**
   *
   *
   * @type {string}
   * @memberof OpportunitiesItem
   */
  description: string;

  /**
   *
   *
   * @type {string}
   * @memberof OpportunitiesItem
   */
  link: string;
}

/**
 * @author William Alexander Livesley
 * @date   2018-04-08
 * @description Declaration of the file extension interface.
 * @filename media-types.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the GraphQL FileExtension object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_FILE_EXTENSION: string = "FileExtension";

/**
 * The properties of a file extension.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface FileExtension<D extends Date | string = string> extends Node<D> {
  /**
   * A description of the file extension.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The file extension.
   *
   * @type {string}
   */
  extension?: string;
}

import * as constants from "./constants";
import {CrawlFetchPayload, CrawlFetchAction, CrawlFulfilledPayload, CrawlFulfilledAction, CrawlFetchingPayload, CrawlFetchingAction} from "./types";

export const crawlFetch = (payload: CrawlFetchPayload): CrawlFetchAction => ({
  type: constants.CRAWL_FETCH,
  payload
});

export const crawlFetching = (payload: CrawlFetchingPayload): CrawlFetchingAction => ({
  type: constants.CRAWL_FETCHING,
  payload
});

export const crawlFulfilled = (payload: CrawlFulfilledPayload): CrawlFulfilledAction => ({
  type: constants.CRAWL_FULFILLED,
  payload
});

/**
 * @author William Alexander Livesley
 * @date   2018-03-22
 * @description Declaration of the HtmlDocumentSlot interface.
 * @filename html-document.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { HtmlDocumentHeader } from "./html-document-header";
import { HtmlDocumentPanel } from "./html-document-panel";
import { Person } from './person';
import { Node } from "./node";

/**
 * The __typeID of the HTML Document type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_HTML_DOCUMENT: string = "HtmlDocument";

/**
 * An HTML document
 *
 * @extends {Node}
 * @interface
 * @template D
 */
export interface HtmlDocument<D extends Date | string = string>
  extends Node<D> {
  /**
   * A description of the HTML document. Metadata to manage the HTML document.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The collection of headers for the HTML document.
   *
   * @template D
   * @type {Array<(HtmlDocumentHeader<D>)>}
   */
  headers?: HtmlDocumentHeader<D>[];

  /**
   * A label for the HTML document. Metadata
   *
   * @type {string}
   */
  label?: string;

  /**
   * The panels forming the HTML document.
   *
   * @type {HtmlDocumentPanel[]}
   * @memberof HtmlDocument
   */
  panels?: HtmlDocumentPanel[];

  /**
   * current logged-in, user
   * 
   * @type {ComponentWithData}
   */
  user?: Person;
}

/**
 * @author William Alexander Livesley
 * @date   2018-03-16
 * @description Declaration of the Connection interface.
 * @filename connection.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Edge } from "./edge";
import { Node } from "./node";
import { PageInfo } from "./page-info";
import { FilterInput } from "./queries/filter-input";
import { OrderInput } from "./queries/order-input";

/**
 * The __typeID of the Connection GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_CONNECTION: string = "Connection";

/**
 * arguments used to filter the connection result.
 *
 * @export
 * @interface ConnectionArgs
 */
export interface ConnectionArgs {
  after?: string,
  before?: string,
  filter?: FilterInput,
  first?: number,
  last?: number,
  order?: OrderInput
}

/**
 * A connection in the response to a GraphQL request.
 *
 * @interface
 * @template D, N
 */
export interface Connection<
  D extends Date | string = string,
  N extends Node<D> = Node<D>
> {
  /**
   * The total number of edges in the connection.
   *
   * @type {number}
   */
  count?: number;

  /**
   * The edges returned in the response.
   *
   * @template D, N
   * @type {Array<Edge<D, N>>}
   */
  edges?: Edge<D, N>[];

  /**
   * Information pertaining to a page of edges in a connection.
   *
   * @type {PageInfo}
   */
  pageInfo?: PageInfo;

  /**
   * The typename of the connection.
   *
   * @type {string}
   */
  __typename?: string;
}

/**
 * @author Ahmed Samer
 * @date   2020-04-06
 * @description Declaration of the AddToPersonInput interface.
 * @filename add-to-person-input.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { AddToNodeInput } from "./add-to-node-input";

/**
 * The input to the mutation to add edge entities to an existing person.
 * Duplicates are ignored
 */
export interface DeletePersonAttributeInput extends AddToNodeInput {
  /**
   *
   *
   * @type {DeletePersonAttributeKind[]}
   * @memberof DeletePersonAttributeInput
   */
  attributes: DeletePersonAttributeKind[];
}

/**
 *
 *
 * @export
 * @enum {number}
 */
export enum DeletePersonAttributeKind {
  /**
   * .
   */
  DESCRIPTION = "DESCRIPTION",

  /**
   * .
   */
  ENTITIES = "ENTITIES",

  /**
   * .
   */
  LOCATIONS = "LOCATIONS",

/**
   * .
   */
  PREVIEWS = "PREVIEWS",
  
  /**
   * .
   */
  REQUESTS ="REQUESTS",

  /**
   * .
   */
  STORIES = "STORIES",

  /**
   * 
   */
  SOCIAL_MEDIA ="SOCIAL_MEDIA",

  /**
   * .
   */
  RESPONSIBILITY = "RESPONSIBILITY",

  /**
   * .
   */
  WEBSITE_URL = "WEBSITE_URL",
}

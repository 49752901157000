/**
 * @author Ahmed Serag
 * @date   2018-04-01
 * @description The input to the mutation to create and add a new response to a question in a questionnair
 * @filename create-question-response-input.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { CreatePreviewInput } from "./create-preview-input";
import { CreateNodeInput } from "./create-node-input";
import { CreateUpdatePreviewInput } from "./create-update-preview-input";


/**
 * The input to create and add a new questionnaire.
 *
 * @export
 * @interface CreateQuestionnaireInput
 */
export interface CreateQuestionnaireInput extends CreateNodeInput {
  /**
   * A description of the questionnaire.
   *
   * @type {string}
   * @memberof CreateQuestionnaireInput
   */
  description?: string;

  /**
   * The previews for the questionnaire.
   *
   * @type {CreatePreviewInput[]}
   * @memberof CreateQuestionnaireInput
   */
  previews?: CreatePreviewInput[];

  /**
   *
   *
   * @type {CreateQuestionInput[]}
   * @memberof CreateQuestionnaireInput
   */
  questions?: CreateQuestionInput[];

  /**
   * The sub-title of the questionnaire.
   *
   * @type {string}
   * @memberof CreateQuestionnaireInput
   */
  subTitle?: string;

  /**
   * The title of the questionnaire.
   *
   * @type {string}
   * @memberof CreateQuestionnaireInput
   */
  title?: string;
}

/**
 * The input to create and add a new question in a questionnaire.
 *
 * @export
 * @interface CreateQuestionInput
 */
export interface CreateQuestionInput {
  /**
   * The choices in the multiple-choice question.
   *
   * @type {CreateMutl}
   * @memberof CreateQuestionInput
   */
  choices?: CreateMultipleChoiceQuestionChoiceInput[];
  /**
   *The content of the text question.
   *
   * @type string
   * @memberof CreateQuestionInput
   */
  content?: string;

  /**
   * The maximum number of choices in the response to a multiple-choice question.
   *
   * @type {string}
   * @memberof CreateQuestionInput
   */
  maximumNumberOfChoices?: string;

  /**
   * The minimum number of choices in the response to a multiple-choice question.
   *
   * @type {string}
   * @memberof CreateQuestionInput
   */
  minimumNumberOfChoices?: string;

  /**
   *
   *
   * @type {CreatePreviewInput[]}
   * @memberof CreateQuestionInput
   */
  previews?: CreateUpdatePreviewInput[];

  /**
   *
   *
   * @type {QuestionKind}
   * @memberof CreateQuestionInput
   */
  questionType: QuestionKind;

  /**
   *
   *
   * @type {string}
   * @memberof CreateQuestionInput
   */
  subTitle?: string;

  /**
   *
   *
   * @type {string}
   * @memberof CreateQuestionInput
   */
  title?: string;
}


/**
 *
 *
 * @export
 * @interface CreateMultipleChoiceQuestionChoiceInput
 */
export interface CreateMultipleChoiceQuestionChoiceInput {
  /**
   *A flag which indicates that the multiple-choice question choice can also accept text.
   *
   * @type {boolean}
   * @memberof CreateMultipleChoiceQuestionChoiceInput
   */
  acceptsText?: boolean;

  /**
   * The ID of an entity associated with the multiple-choice question choice.
   *
   * @type {string}
   * @memberof CreateMultipleChoiceQuestionChoiceInput
   */
  entityId?: string;

  /**
   *
   *
   * @type {CreatePreviewInput []}
   * @memberof CreateMultipleChoiceQuestionChoiceInput
   */
  previews?: CreatePreviewInput[];

  /**
   * The ID of a person associated with the multiple-choice question choice
   *
   * @type {string}
   * @memberof CreateMultipleChoiceQuestionChoiceInput
   */
  personId?: string;

  /**
   *The ID of a story associated with the multiple-choice question choice.
   *
   * @type {string}
   * @memberof CreateMultipleChoiceQuestionChoiceInput
   */
  storyId?: string;

  /**
   * The sub-title of the multiple-choice question choice.
   *
   * @type {string}
   * @memberof CreateMultipleChoiceQuestionChoiceInput
   */
  subTitle?: string;

  /**
   * The title of the multiple-choice question choice.
   *
   * @type {string}
   * @memberof CreateMultipleChoiceQuestionChoiceInput
   */
  title?: string;
}

/**
 *
 *
 * @export
 * @enum {number}
 */
export enum QuestionKind {
  /**
   * A question which offers a choice from a collection of choices.
   */
  MUTLIPLE_CHOICE = "MUTLIPLE_CHOICE",

  /**
   * A question which is answered with text.
   */
  TEXT = "TEXT"
}

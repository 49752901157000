import * as React from "react";

export const INVESTOR = {
  kind: "INVESTOR",
  title: "Join as an Investor",
  description:
    "Discover new startups and click to connect directly for deal flow. Grow your brand and share your advice with the community. Welcome to Radivision!",
  colors: ["#00B09B", "#96C93D"],
};

export const FOUNDER = {
  kind: "FOUNDER",
  title: "Join as a Founder",
  description:
    "Founders, showcase your startup to investors, community members, and ecosystem vedors/partners. Benefit from Radivision's IR & marketing support on Pitch Planet, Bytes, our Newsletter, Social Media, Interviews & more. ",
  colors: ["#1A2980", "#26D0CE"],
};

export const COMPANY = {
  kind: "COMPANY",
  title: "Join as an Ecosystem Partner",
  description: (
    <>
      Startup Ecosystem Partners include corporate brands, vendors, accelerators, universities, and other valued B2B
      participants.
    </>
  ),
  colors: ["#6107BC", "#C210DA"],
};

export const ENTHUSIAST = {
  kind: "ENTHUSIAST",
  title: "Join as a Community Member",
  description:
    "For entrepreneurial enthusiasts eager to be entertained, learn more about this ecosystem, and discover cool startups, join as a community member.",
  colors: ["#FF5F6D", "#FFC371"],
};

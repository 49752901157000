/**
 * @author Sarah Nouh
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-07-18 03:33
 * @description Implementation of the six by one grid.
 * @filename grid-view.tsx
 */
import { getNewUniqueId as _getNewUniqueId } from "@radivision/common";
import $ from "jquery";
import { connect, DispatchProp } from "react-redux";
import isEqual from "lodash.isequal";
import React, { Suspense } from "react";
import ProgressiveImage from "react-progressive-image";
import Truncate from "react-truncate";
import { Environment } from "relay-runtime";
import { CarouselItem } from "../../component-configuration/carousel-item";
import { RadivisionPostInterface } from "../../component-configuration/radivision-post-interface";
import { GridViewItem } from "../../component-configuration/grid-view-item";
import { SVGS, SVG } from "../../component-configuration/svgs";
import { Analytics } from "../../utilities/analytics";
import { DEFAULTS } from "../../utilities/defaults";
import {
  facebookPixelTraceEvent,
  snowplowAnalytics,
  gaEvent,
  printFormattedTime,
  detectMobile,
} from "../../utilities/general";
import { ImageHelper } from "../../utilities/image-helper";
import { Router } from "../../utilities/router";
import { ConfirmationModal } from "../modals/confirmation-modal";
// import { RadivisionPostModal } from "../modals/radivision-post-modal";
import { Loader } from "../page/loader";
import { PreviewKind } from "@radivision/graphql/lib/ts/graphql/preview-type";
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import { GRAPHQL_TYPE_RADIVISION_POST } from "@radivision/graphql/lib/ts/graphql/radivision-post";
import YouTube from "react-youtube";
import { Person, Entity, GRAPHQL_TYPE_ENTITY, GRAPHQL_TYPE_POSTER } from "@radivision/graphql";
import { formatTime } from "../../utilities/format-time";
import { extractYoutubeId } from "../../utilities/extract-youtube-id";

const VideoPanel = React.lazy(() => import("../page-framework/video-panel"));

interface MapDispatchProps {}
/**
 * The props of the GridView component.
 *
 * @interface
 */
interface GridViewProps {
  /**
   * active modals to be rendered when adding new items.
   *
   * @type {("entity" | "stories")}
   * @memberof GridViewProps
   */
  activeModal?: "entity" | "stories";

  /**
   * to allow re-order items
   *
   * @type {boolean}
   * @memberof GridViewProps
   */
  allowReorder?: boolean;

  /**
   * to make item title aligned to center
   *
   * @type {boolean}
   */
  centerItemTitle?: boolean;

  /**
   * display item description
   *
   * @type {boolean}
   * @memberof GridViewProps
   */
  displayItemDescription?: boolean;

  /**
   * to only show one line of the description
   *
   * @type {boolean}
   * @memberof GridViewProps
   */
  displayItemDescriptionOneLine?: boolean;

  /**
   * to display view more items button
   *
   * @type {boolean}
   * @memberof GridViewProps
   */
  displayViewMoreBtn?: boolean;

  /**
   * the dna section name the grid is child of
   *
   * @type {string}
   * @memberof GridViewProps
   */
  dnaSection?: string;

  /**
   * relay environment.
   */
  environment?: Environment;

  /**
   * The content of the grid.
   *
   * @type {CarouselItem[]}
   */
  gridContent: CarouselItem[];

  /**
   * The grid description
   *
   * @type {boolean}
   */
  gridDescription?: string;

  /**
   * function to be executed when adding new media.
   */
  handleAddNewMedia?: Function;

  /**
   * function callback when add new media fails
   *
   * @type {Function}
   * @memberof GridViewProps
   */
  handleAddMediaError?: Function;

  /**
   * function to be executed when editing media.
   */
  handleUpdateMedia?: Function;

  /**
   * function to be executed when deleting media.
   */
  handleDeleteMedia?: Function;

  /**
   * custom onClick handler for item onClick
   *
   * @type {(index: number) => void}
   * @memberof GridViewProps
   */
  handleItemClick?: (index: number) => void;

  /**
   * handle items re-reorder event
   *
   * @type {Function}
   * @memberof GridViewProps
   */
  handleOrder?: Function;

  /**
   * to hide the "Add New" button
   *
   * @type {boolean}
   * @memberof GridViewProps
   */
  hideAddNewButton?: boolean;

  /**
   * to hide the icon that defines the item type next to title
   *
   * @type {boolean}
   * @memberof GridViewProps
   */
  hideItemTypeIcon?: boolean;

  /**
   * flag to detect if loading state exists to prevent new actions
   *
   * @type {boolean}
   */
  isLoading?: boolean;

  /**
   * A flag which is true if the component is the owner of the grid.
   *
   * @type {boolean}
   */
  isOwner?: boolean;

  /**
   * flag if the item is editable
   *
   * @type {boolean}
   * @memberof GridViewProps
   */
  isEditableItem?: boolean;

  /**
   * item preview kind
   *
   * @type {PreviewKind}
   * @memberof GridViewProps
   */
  itemPreviewKind?: PreviewKind;

  /**
   * the number of items to be shown per row
   *
   * @type {number}
   */
  itemsToShow?: number;

  /**
   * A boolean to indicate whether the grid item has a publisher and duraction info shown
   *
   * @type {boolean}
   * @memberof GridViewProps
   */
  isMedia?: boolean;

  /**
   * hover effect ?
   */
  hover?: boolean;

  /**
   * the title of the list itself (used to navigate to the list's equivalent content in the inner pages on click in mobile screens).
   *
   * @type {string}
   * @memberof GridViewProps
   */
  listTitle?: string;

  /**
   * the type of the list (person or entity..) (used to navigate to the list's equivalent content in the inner pages on click in mobile screens).
   *
   * @type {string}
   * @memberof GridViewProps
   */
  listType?: string;

  /**
   * if modal id is controlled via parent component
   *
   * @type {string}
   * @memberof GridViewProps
   */
  modalHtmlId?: string;

  /**
   * handler when click on view more button
   *
   * @type {() => void}
   * @memberof GridViewProps
   */
  onViewMoreHandler?: () => void;
  dispatch: DispatchProp;
  person: Person;
  entity: Entity;
}

/**
 *
 *
 * @interface GridViewState
 */
interface GridViewState {
  /**
   * the id of the item to delete when confirm modal shows up
   *
   * @type {string}
   * @memberof GridViewState
   */
  confirmDeleteItemId: string;

  /**
   * the title of the item to delete when confirm modal shows up
   *
   * @type {string}
   * @memberof GridViewState
   */
  confirmDeleteItemTitle: string;

  /**
   * the confirm Dismiss modal id
   *
   * @type {string}
   */
  confirmationModalId: string;

  /**
   * store current to open RadivisionPost if there is any
   *
   * @type {RadivisionPostInterface}
   */
  currentRadivisionPost: RadivisionPostInterface;
  editMedia: boolean;
  itemToEdit: GridViewItem;
  newOrderedArray: any;
  loading: boolean;
  errorPanel: boolean;

  /**
   *
   *
   * @type {string}
   * @memberof GridViewState
   */
  modalId: string;

  /**
   * this id of the Youtube Video upon which slide is clicked
   *
   * @type {string}
   */
  videoId: string;
  /**
   * state of the video playing or not
   *
   * @type {boolean}
   */
  playVideo: boolean;
  hoverPlay: number;
}
/**
 *  A React component that renders a six by one grid.
 *
 * @export
 * @class GridView
 * @extends {React.Component<GridViewProps>}
 */
export class GridViewComponent extends React.Component<GridViewProps & MapDispatchProps, GridViewState> {
  gridRef: any;
  isMounted: boolean;
  /**
   * the Dragged item
   *
   * @type {any}
   */
  draggedItem: any;
  youtubeHoverVideoOpt: any;

  /**
   * Constructor.
   *
   * @param {GridViewProps} props The props of the component.
   */
  constructor(props: GridViewProps & MapDispatchProps) {
    super(props);
    this.gridRef = React.createRef();

    const currentRadivisionPost: RadivisionPostInterface = null;
    // for(let item of props.gridContent) {
    //   if(item?.type === GRAPHQL_TYPE_RADIVISION_POST) {
    //     currentRadivisionPost = {
    //       title: item?.title,
    //       content: item?.content,
    //       image: item?.previewImageUrl
    //     }
    //     break;
    //   }
    // }

    const confirmationModalId: string = `l${_getNewUniqueId()}`;

    // on initial load > build sceleton of two rows in loading state
    let gridItems: CarouselItem[] = [];
    if (this.props.gridContent === null || this.props.gridContent === undefined) {
      for (let i = 0; i < props.itemsToShow * 2 - 1; i++) {
        gridItems.push({
          title: "",
        });
      }
    } else {
      gridItems = this.props.gridContent;
    }

    this.state = {
      confirmationModalId,
      currentRadivisionPost,
      confirmDeleteItemId: "",
      confirmDeleteItemTitle: "",
      editMedia: false,
      itemToEdit: null,
      newOrderedArray: gridItems,
      loading: false,
      errorPanel: false,
      playVideo: false,
      hoverPlay: -1,
      videoId: "",
      modalId: this.props.modalHtmlId ? this.props.modalHtmlId : `l${_getNewUniqueId()}`,
    };
    this.playVideo = this.playVideo.bind(this);
    this.exitFullScreen = this.exitFullScreen.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  /**
   *
   *
   * @memberof GridView
   */
  componentDidMount() {
    // disable the edit mode if the modal is closed
    $(document).on("click", ".modal .close", () => {
      if (this.state.editMedia) {
        this.setState({ editMedia: false });
      }
    });
    if (this.props && this.props.gridContent) {
      this.setState({ newOrderedArray: this.props.gridContent });
    }
  }

  /**
   *
   *
   * @param {GridViewProps} prevProps
   * @memberof GridView
   */
  componentDidUpdate(prevProps: GridViewProps) {
    if (!isEqual(prevProps, this.props)) {
      let gridItems: CarouselItem[] = [];
      if (this.props.gridContent === null || this.props.gridContent === undefined) {
        for (let i = 0; i < this.props.itemsToShow * 2 - 1; i++) {
          gridItems.push({
            title: "",
          });
        }
      } else {
        gridItems = this.props.gridContent;
      }
      this.setState({ newOrderedArray: gridItems });
    }
  }

  /**
   * A function called to handle starting animation only when hovered on a grid item
   *
   * @type {function()}
   */
  onItemHover = (item, index) => {
    // if (this.gridRef.current) {
    //   this.gridRef.current.classList.add("grid-hover");
    // }
    if (item?.link && item?.link.includes("youtube")) {
      setTimeout(() => {
        this.youtubeHoverVideoOpt = {
          playerVars: {
            autoplay: 1,
            controls: 0,
            mute: 1,
            rel: 0,
          },
        };
      }, 700);
      this.setState({ hoverPlay: index });
    }
  };

  /**
   * A function called after load to handle mouse leave for grid item
   *
   * @type {function()}
   */
  onMouseLeave = () => {
    // if (this.gridRef.current) {
    //   this.gridRef.current.classList.remove("grid-hover");
    // }
    this.setState({ hoverPlay: -1 });
  };
  /**
   * on youtube video has ended..
   */
  onYouTubeVideoEnds = () => {
    this.setState({ hoverPlay: -1 });
  };
  /**
   * opens the corresponding inner page on grid click in mobile screens.
   *
   * @type {function()}
   */
  handleMobileGridClick = () => {
    if (this.props.listTitle !== null && this.props.listTitle !== undefined) {
      // if its originiated from people or companies
      if (this.props.listTitle.toLowerCase() === "people" || this.props.listTitle.toLowerCase() === "companies") {
        Router.to(this.props.listTitle.toLowerCase());
      } else {
        // if it's from the curated list of people
        if (this.props.listType[0].toLowerCase() === "person") {
          Router.to("people", null, this.props.listTitle.toLowerCase().replace(/ /g, "_"));
        } else {
          // if it's from the curated list of companies
          if (this.props.listType[0].toLowerCase() === "entity") {
            Router.to("companies", null, this.props.listTitle.toLowerCase().replace(/ /g, "_"));
          } else {
            // if  it's a resource in the navigate section
            Router.to("navigate", null, this.props.listTitle.toLowerCase().replace(/ /g, "_"));
          }
        }
      }
    }
  };

  /**
   * A function triggered to handle images  that are not found
   *
   * @type {function()}
   */
  loadDefaultImage(targetImage: any, itemType: any) {
    targetImage.onerror = null;
    targetImage.src = ImageHelper.fetchOptimizedImageUrl({
      imageType: "DEFAULT",
      default: {
        previewType: this.props.itemPreviewKind || PreviewKind.CAROUSEL,
        mediaType: TopLevelMediaKind.IMAGE,
        type: itemType,
      },
      desiredDimensions: { containerWidthRatio: 1, numberOfItems: 6 },
      isSquareImage: true,
      revision: undefined,
    }).requestedResolutionUrl;
  }

  /**
   * play the video
   *
   * @type {function():void}
   */
  playVideo(videoSrc: any) {
    const vidSrc = extractYoutubeId(videoSrc);
    this.setState({ playVideo: true, videoId: vidSrc });
    // if (!document.fullscreenElement) {
    //   document.documentElement.requestFullscreen();
    // }
  }

  /**
   * exit playing the video.
   *
   * @type {function():void}
   */
  exitFullScreen() {
    this.setState({ playVideo: false });
    // if (document.exitFullscreen) {
    //   document.exitFullscreen();
    // }
    // $("body").removeClass("crossfade");
  }

  /**
   * Event listener when item starting to get dragged
   *
   * @param e the event action
   * @param index the index of the dragged item
   */
  onDragStart(e, index) {
    if (this.props.isOwner) {
      const dragedCard = e.target.parentNode.parentNode.parentNode;
      this.draggedItem = this.state.newOrderedArray[index];
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/html", dragedCard);
      e.dataTransfer.setDragImage(dragedCard, 20, 20);
    }
  }

  /**
   * when mouse is over an item while dragging, switch places
   *
   * @param index the of the item the mouse is dragged over
   */
  onDragOver(index) {
    if (this.props.isOwner) {
      const draggedOverItem = this.state.newOrderedArray[index];

      // if the item is dragged over itself, ignore
      if (this.draggedItem === draggedOverItem || !this.draggedItem) {
        return;
      }

      // filter out the currently dragged item
      const items = this.state.newOrderedArray.filter((item) => item !== this.draggedItem);

      // add the dragged item after the dragged over item
      items.splice(index, 0, this.draggedItem);

      this.setState({ newOrderedArray: items });
    }
  }

  /**
   * when Drag event is ended
   */
  onDragEnd() {
    if (this.props.isOwner) {
      this.setState({ loading: true, errorPanel: false });

      this.props
        .handleOrder(this.state.newOrderedArray)
        .then(() => {
          this.setState({ loading: false });
        })
        .catch((err: any) => {
          this.setState({ loading: false, errorPanel: true });
        });
    }
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    // when to show 5 items and there is no items to fill spaces to 5
    // fill them with empty cells to complete at least one row
    const emptyCells: React.ReactNode[] = [];
    let itemsLength: number = 0;
    if (this.state.newOrderedArray && this.state.newOrderedArray.length) {
      itemsLength = this.props.displayViewMoreBtn
        ? this.state.newOrderedArray.length + 1
        : this.state.newOrderedArray.length;
    }
    if (!this.props.isOwner && this.props.itemsToShow === 5 && itemsLength % 5 !== 0) {
      for (let i = 0; i < 5 - (itemsLength % 5); i++) {
        emptyCells.push(<div className="col" key={(itemsLength + i).toString()} />);
      }
    }

    const grid: React.ReactNode[] = this.state.newOrderedArray
      ? this.state.newOrderedArray.map((item: any, index) => {
          const duration = formatTime(item?.timeLabel || item?.timeRequiredInSeconds);
          return (
            <div
              id={item?.id ? item?.id : `item${index + 1}`}
              className={`${
                this.props.itemsToShow === 1
                  ? "col-sm-12"
                  : this.props.itemsToShow === 3
                  ? "col-6 col-sm-3"
                  : this.props.itemsToShow === 5 && $("body").prop("clientWidth") > 768
                  ? "col"
                  : this.props.itemsToShow === 6
                  ? "col-lg-2 col-6 col-sm-3 col-md-3"
                  : "col-md-3 col-6 col-sm-3 searchUx"
              }`}
              key={index.toString()}
              onDragOver={() => this.onDragOver(index)}
            >
              <div
                className="grid-item item-card"
                key={index.toString()}
                onMouseEnter={() => {
                  this.props.hover && item?.id ? this.onItemHover(item, index) : null;
                }}
                onMouseLeave={() => {
                  this.props.hover && item?.id ? this.onMouseLeave() : null;
                }}
              >
                {this.props.isOwner ? (
                  <div className={`grid-item-actions${detectMobile() ? " d-block" : ""}`}>
                    {this.props.allowReorder && this.state.newOrderedArray.length > 1 && !detectMobile() ? (
                      <div className="swap-item">
                        <button
                          type="button"
                          className="close close-lg"
                          data-toggle="confirmation"
                          data-title="Remove Item?"
                          draggable={this.props.isOwner}
                          onDragStart={(e) => this.onDragStart(e, index)}
                          onDragEnd={this.onDragEnd}
                          style={{ cursor: "move" }}
                        >
                          <span aria-hidden="true">{SVG.hamburger}</span>
                        </button>
                      </div>
                    ) : null}
                    <div className="delete-item">
                      <button
                        type="button"
                        className="close close-lg"
                        data-toggle={this.props.isLoading ? "" : "modal"}
                        data-target={`#${this.state.confirmationModalId}`}
                        disabled={this.props.isLoading}
                        onClick={(e) => {
                          if (this.props.isLoading) return;
                          e.preventDefault();
                          this.setState({
                            confirmDeleteItemId: item?.id,
                            confirmDeleteItemTitle: item?.title,
                          });
                        }}
                      >
                        <span aria-hidden="true">{SVG.trash}</span>
                      </button>
                    </div>
                  </div>
                ) : null}
                {this.props.isMedia ? (
                  <div
                    className={`linked-preview card-image${
                      this.props.itemPreviewKind === PreviewKind.CIRCLE
                        ? " card-image-circle"
                        : this.props.itemPreviewKind === PreviewKind.CAROUSEL
                        ? " card-carousel"
                        : this.props.itemPreviewKind === PreviewKind.PORTRAIT
                        ? " card-portrait"
                        : " "
                    }`}
                    data-toggle={item?.type === GRAPHQL_TYPE_RADIVISION_POST ? "modal" : ""}
                    data-target={
                      item?.type === GRAPHQL_TYPE_RADIVISION_POST ? `#${this.state.modalId}-radivision-post` : ""
                    }
                    onClick={() => {
                      if (!item?.id) return;
                      if (this.props.handleItemClick) {
                        this.props.handleItemClick(index);
                        return;
                      }
                      let type: string = "_blank";
                      Analytics.userHistory({
                        item: item?.id,
                        description: item?.description,
                      }).then((requestId: string): void => {});

                      if (item?.link !== undefined && item?.link !== null) {
                        if (item?.link.includes("youtube")) {
                          this.playVideo(item?.link);
                          type = "_self";
                        } else {
                          window.open(item?.link, "_blank");
                        }
                      } else if (item?.landingPageUrl !== undefined && item?.landingPageUrl !== null) {
                        window.open(item?.landingPageUrl, "_self");
                      } else if (item?.type === GRAPHQL_TYPE_RADIVISION_POST) {
                        this.setState({
                          currentRadivisionPost: {
                            title: item?.title,
                            content: item?.description,
                            image: item?.previewImageUrl,
                          },
                        });
                      }
                      gaEvent(item?.type, "Click", item?.title ? item?.title : "d8uLQVW02R6l4krDrXQQ", true);

                      facebookPixelTraceEvent(event, {
                        category: item?.id ? item?.id : null,
                        title: `${item?.title ? item?.title : "Video Played"}`,
                        url: item?.landingPageUrl ? item?.landingPageUrl : window.location.href,
                      });

                      snowplowAnalytics()
                        .then((r) => {
                          if (item?.link.includes("youtube")) {
                            r.trackPlayVideo({
                              category: item?.id ? item?.id : null,
                              label: item?.title ? item?.title : "",
                              property: item?.link
                                ? item?.link
                                : item?.landingPageUrl
                                ? item?.landingPageUrl
                                : window.location.href,
                            });
                          } else {
                            r.trackClick({
                              category: item?.id ? item?.id : null,
                              label: item?.title ? item?.title : "",
                              property: item?.link
                                ? item?.link
                                : item?.landingPageUrl
                                ? item?.landingPageUrl
                                : window.location.href,
                            });
                          }
                        })
                        .catch((r) => console.debug("SnowPlow not initialized."));
                    }}
                  >
                    {duration && (
                      <div className="overlay-item-meta">
                        <span className="duration">{duration}</span>
                      </div>
                    )}
                    {/* <LazyLoad once={true} overflow={true}> */}
                    {item?.id && item?.previewImageUrl ? (
                      <ProgressiveImage
                        src={
                          item?.previewImageUrl?.requestedResolutionUrl !== undefined &&
                          item?.previewImageUrl?.requestedResolutionUrl !== null
                            ? item?.previewImageUrl?.requestedResolutionUrl
                            : item?.previewImageUrl?.screenResolutionUrl
                        }
                        placeholder={item?.previewImageUrl?.placeHolderUrl}
                      >
                        {(src: any, LOADING: any, srcSetData: any) => (
                          <img
                            loading="lazy"
                            src={src}
                            onError={(event) => {
                              this.loadDefaultImage(event.target, item?.type);
                            }}
                          />
                        )}
                      </ProgressiveImage>
                    ) : (
                      <span className="img-placeholder loading-gradient" />
                    )}
                    {/* </LazyLoad> */}
                  </div>
                ) : (
                  <div
                    className={`card-image${
                      this.props.itemPreviewKind === PreviewKind.CIRCLE
                        ? " card-image-circle"
                        : this.props.itemPreviewKind === PreviewKind.CAROUSEL
                        ? " card-carousel"
                        : this.props.itemPreviewKind === PreviewKind.PORTRAIT
                        ? " card-portrait"
                        : ""
                    }`}
                    onClick={() => {
                      if (!item?.id) return;
                      if (this.props.handleItemClick) {
                        this.props.handleItemClick(index);
                        return;
                      }
                      let type: string = "_blank";
                      Analytics.userHistory({
                        item: item?.id,
                        description: item?.description,
                      }).then((requestId: string): void => {});

                      if (item?.link !== undefined && item?.link !== null) {
                        if (item?.link.includes("youtube")) {
                          this.playVideo(item?.link);
                          type = "_self";
                        } else {
                          window.open(item?.link, "_blank");
                        }
                      } else if (item?.landingPageUrl !== undefined && item?.landingPageUrl !== null) {
                        window.open(item?.landingPageUrl, "_self");
                      } else if (item?.type === GRAPHQL_TYPE_RADIVISION_POST) {
                        this.setState(
                          {
                            currentRadivisionPost: {
                              title: item?.title,
                              content: item?.content,
                              image: item?.previewImageUrl,
                            },
                          },
                          () => {
                            $(`#${this.state.modalId}-radivision-post`).modal("show");
                          },
                        );
                      }
                      gaEvent(item?.type, "Click", item?.title ? item?.title : "idztvdpWw0ebR1lhGx1KMA", true);

                      facebookPixelTraceEvent(event, {
                        category: item?.id ? item?.id : null,
                        title: `${item?.title ? item?.title : "Video Played"}`,
                        url: item?.landingPageUrl ? item?.landingPageUrl : window.location.href,
                      });

                      snowplowAnalytics()
                        .then((r) =>
                          r.trackPlayVideo({
                            category: item?.id ? item?.id : null,
                            label: item?.title ? item?.title : "Video Played",
                            url: item?.landingPageUrl ? item?.landingPageUrl : window.location.href,
                          }),
                        )
                        .catch((r) => console.debug("SnowPlow not initialized."));
                    }}
                  >
                    {duration && (
                      <div className="overlay-item-meta">
                        <span className="duration">{duration}</span>
                      </div>
                    )}
                    {!item?.id ? (
                      <span className="img-placeholder loading-gradient" />
                    ) : item?.previewImageUrl ? (
                      <ProgressiveImage
                        src={
                          item?.previewImageUrl.requestedResolutionUrl !== undefined &&
                          item?.previewImageUrl.requestedResolutionUrl !== null
                            ? item?.previewImageUrl.requestedResolutionUrl
                            : item?.previewImageUrl.screenResolutionUrl
                        }
                        placeholder={item?.previewImageUrl.placeHolderUrl}
                      >
                        {(src: any, LOADING: any, srcSetData: any) => (
                          <img
                            loading="lazy"
                            src={src}
                            onError={(event) => {
                              this.loadDefaultImage(event.target, item?.type);
                            }}
                          />
                        )}
                      </ProgressiveImage>
                    ) : item?.type === "Entity" ? (
                      <ProgressiveImage
                        src={`${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_Entity_SQUARE"]}?wip=300`}
                        placeholder={`${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_Entity_SQUARE"]}?wip=10`}
                      >
                        {(src: any, LOADING: any, srcSetData: any) => (
                          <img
                            loading="lazy"
                            src={src}
                            onError={(event) => {
                              this.loadDefaultImage(event.target, item?.type);
                            }}
                          />
                        )}
                      </ProgressiveImage>
                    ) : (
                      <ProgressiveImage
                        src={`${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_Person_SQUARE"]}?wip=300`}
                        placeholder={`${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_Person_SQUARE"]}?wip=10`}
                      >
                        {(src: any, LOADING: any, srcSetData: any) => (
                          <img
                            loading="lazy"
                            src={src}
                            onError={(event) => {
                              this.loadDefaultImage(event.target, item?.type);
                            }}
                          />
                        )}
                      </ProgressiveImage>
                    )}
                    {item?.link && item?.link.includes("youtube") && this.state.hoverPlay === index ? (
                      <div>
                        <div
                          className="youtubeOverlay"
                          onClick={() => {
                            let type: string = "_blank";
                            if (item?.link && item?.link.includes("youtube")) {
                              this.playVideo(item?.link);
                              type = "_self";
                            }
                          }}
                        />
                        <YouTube
                          videoId={extractYoutubeId(item?.link)}
                          opts={this.youtubeHoverVideoOpt}
                          className="youtube-on-hover"
                          onEnd={this.onYouTubeVideoEnds}
                        />
                      </div>
                    ) : null}
                  </div>
                )}
                <div
                  className="grid-details"
                  data-toggle={item?.type === GRAPHQL_TYPE_RADIVISION_POST ? "modal" : ""}
                  data-target={
                    item?.type === GRAPHQL_TYPE_RADIVISION_POST ? `#${this.state.modalId}-radivision-post` : ""
                  }
                  onClick={() => {
                    if (!item?.id) return;
                    if (this.props.handleItemClick) {
                      this.props.handleItemClick(index);
                      return;
                    }
                    let url: string;
                    let type: string = "_blank";

                    if (item?.link !== undefined && item?.link !== null) {
                      if (item?.link.includes("youtube")) {
                        this.playVideo(item?.link);
                        type = "_self";
                      } else {
                        url = item?.link;

                        // window.open(item?.link, "_blank");
                      }
                    } else if (item?.landingPageUrl !== undefined && item?.landingPageUrl !== null) {
                      url = item?.landingPageUrl;
                      type = "_self";

                      // window.open(item?.landingPageUrl, "_self");
                    } else if (item?.type === GRAPHQL_TYPE_RADIVISION_POST) {
                      this.setState({
                        currentRadivisionPost: {
                          title: item?.title,
                          content: item?.content,
                          image: item?.previewImageUrl,
                        },
                      });
                    }

                    Analytics.userHistory({
                      item: item?.id,
                      description: item?.description,
                    }).then((requestId: string): void => {});

                    gaEvent(item?.type, "Click", item?.title ? item?.title : "Gy5P3EX93UOKP3gXmY2OYQ", true);

                    facebookPixelTraceEvent(event, {
                      category: item?.id ? item?.id : null,
                      title: `${item?.title ? item?.title : "Video Played"}`,
                      url: item?.landingPageUrl ? item?.landingPageUrl : window.location.href,
                    });

                    snowplowAnalytics()
                      .then((r) =>
                        r.trackPlayVideo({
                          category: item?.id ? item?.id : null,
                          label: item?.title ? item?.title : "Video Played",
                          property: item?.landingPageUrl ? item?.landingPageUrl : window.location.href,
                        }),
                      )
                      .catch((r) => console.debug("SnowPlow not initialized."));

                    setTimeout(() => {
                      if (url) {
                        window.open(url, type);
                      }
                    }, 200);
                  }}
                >
                  <div className="item-details">
                    <h6
                      className={`card-title${this.props.centerItemTitle ? " text-center" : ""}${
                        SVGS[item?.type] && !this.props.hideItemTypeIcon && !this.props.isMedia ? " has-icon" : ""
                      }`}
                    >
                      {!this.props.isMedia || !item?.id ? null : SVGS[item?.type] ? (
                        this.props.isMedia ? (
                          <span className="story-icon">
                            {item?.type === "OriginalContentStory" ? null : SVGS[item?.type]}
                          </span>
                        ) : null
                      ) : null}

                      {item?.id ? (
                        <span className={`${this.props.centerItemTitle ? "text-center" : ""}`}>{item?.title}</span>
                      ) : (
                        <span className="title-placeholder loading-gradient" />
                      )}
                      {SVGS[item?.type] && !this.props.hideItemTypeIcon ? (
                        this.props.isMedia ? null : (
                          <div
                            className={
                              item?.link && item?.link.includes("youtube") ? "story-icon toHide" : "story-icon"
                            }
                          >
                            {SVGS[item?.type]}
                          </div>
                        )
                      ) : null}
                    </h6>
                    {this.props.displayItemDescription ? (
                      <p className={`card-description${this.props.displayItemDescriptionOneLine ? " truncate" : ""}`}>
                        {item.type === GRAPHQL_TYPE_ENTITY
                          ? item?.tagLine
                            ? item?.tagLine
                            : item?.description
                          : item?.description}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      : [];

    if (this.props.itemsToShow === 5 && $("body").prop("clientWidth") > 768) {
      let pos = 5;
      const interval = 6;

      while (pos < grid.length) {
        grid.splice(pos, 0, <div className="w-100" key={`wrap${pos}`} />);
        pos += interval;
      }
    }
    return (
      <div>
        {this.state.errorPanel ? (
          <div className="alert alert-danger" role="alert">
            Error !, please try again
          </div>
        ) : null}
        <div className={`row grid-view${this.props.itemsToShow === 1 ? " full-row" : ""}`}>
          {grid}
          {this.props.displayViewMoreBtn ? (
            <div
              className={`${
                this.props.itemsToShow === 3
                  ? "col-6 col-sm-3"
                  : this.props.itemsToShow === 5 && $("body").prop("clientWidth") > 768
                  ? "col"
                  : this.props.itemsToShow === 6
                  ? "col-lg-2 col-6 col-sm-3 col-md-3"
                  : "col-md-3 col-6 col-sm-3 searchUx"
              } view-more-btn-container`}
            >
              <div className="view-more-btn">
                <div
                  className="view-more"
                  onClick={() => {
                    if (this.props.onViewMoreHandler) {
                      this.props.onViewMoreHandler();
                    }
                  }}
                >
                  <i>
                    {SVG.chevronRightThin}
                    {SVG.chevronRightThin}
                  </i>
                  <span>View more</span>
                </div>
              </div>
            </div>
          ) : null}
          {emptyCells.map((cell) => cell)}
          {this.props.isOwner && !this.props.hideAddNewButton ? (
            <div
              className={`${
                this.props.itemsToShow === 3
                  ? "col-6 col-sm-3"
                  : this.props.itemsToShow === 5 && $("body").prop("clientWidth") > 768
                  ? "col"
                  : this.props.itemsToShow === 6
                  ? "col-lg-2 col-6 col-sm-3 col-md-3"
                  : "col-md-3 col-6 col-sm-3 searchUx"
              } optional-div`}
            >
              <div className="plusIconDiv">
                <a
                  data-toggle="modal"
                  data-target={`#create-post-modal`}
                  onClick={() => {
                    this.setState({ editMedia: false });
                  }}
                >
                  {SVG.plusAlt}
                  <span>
                    {this.props.isMedia
                      ? "Add Media"
                      : this.props.dnaSection === "companies"
                      ? "Add Company"
                      : this.props.dnaSection === "investments"
                      ? "Add Investments"
                      : this.props.dnaSection === "experiences"
                      ? "Add Experiences"
                      : this.props.dnaSection === "inspirations"
                      ? "Add Inspirations"
                      : ""}
                  </span>
                </a>
              </div>
            </div>
          ) : null}
        </div>

        {this.props.gridDescription ? <div className="grid-description"> {this.props.gridDescription}</div> : null}
        {this.props.isOwner ? (
          <ConfirmationModal
            className="fat-title"
            id={this.state.confirmationModalId}
            message={`Are you sure to delete: ${this.state.confirmDeleteItemTitle}`}
            confirm={(itemId: string) => {
              this.props.handleDeleteMedia(itemId);
            }}
            callbackItem={this.state.confirmDeleteItemId}
          />
        ) : null}
        {/* this.state.currentRadivisionPost ? (
          <RadivisionPostModal modalId={this.state.modalId} radivisionPost={this.state.currentRadivisionPost} />
        ) : null */}
        <Loader isActive={this.state.loading} />

        {this.state.playVideo && this.state.videoId ? (
          <Suspense fallback={<div>Loading...</div>}>
            <VideoPanel
              youTube={this.state.videoId}
              // videoSrc={this.state.videoId}
              videoPosition="FULL_SCREEN"
              onVideoEnded={this.exitFullScreen}
              hasBackBtn={true}
            />
          </Suspense>
        ) : null}
      </div>
    );
  }
}
const mapState = () => ({});
const mapDispatch = {};
export const GridView = connect(mapState, mapDispatch)(GridViewComponent);

import isEmpty from "lodash.isempty";
import * as React from "react";
import { SVG } from "../../../component-configuration/svgs";
import { Router } from "../../../utilities/router";

import { useCarouselContext } from "./context";

export const CarouselHeader = (): JSX.Element => {
  const {
    displayMode,
    viewAllOnClick,
    show,
    listDetailsView,
    hideTitle,
    pageInfo,
    campaignPreview,
  } = useCarouselContext();
  const showTitle = !listDetailsView && !hideTitle;
  const preview =
    campaignPreview && !isEmpty(campaignPreview?.requestedResolutionUrl)
      ? campaignPreview?.requestedResolutionUrl
      : campaignPreview?.screenResolutionUrl;

  if (!showTitle) return null;

  const isCarouselDisplayMode: boolean = !displayMode || displayMode === "Carousel";
  const VIEW_MORE: boolean = (pageInfo?.hasPreviousPage || pageInfo?.hasNextPage) && isCarouselDisplayMode;
  const SheResearch = window.location.pathname.includes("aEKCQOczRWGIeU6oRFuXkA");
  const TITLE_CLASS_NAME = VIEW_MORE ? "panel-title clickable" : "panel-title";
  const goToSheconomics = React.useCallback(
    () => (e) => {
      e.preventDefault();
      Router.navigateTo("sheconomics");
    },
    [],
  );

  return (
    <div className="carousel-header">
      {preview ? (
        <div
          style={{
            display: "flex",
          }}
        >
          <img src={preview} />
        </div>
      ) : null}
      <h3
        className={TITLE_CLASS_NAME}
        onClick={() => {
          if (VIEW_MORE) {
            viewAllOnClick();
          }
        }}
      >
        {show?.title?.length ? show.title : <span className="title-placeholder" />}
      </h3>
      {VIEW_MORE ? (
        <span className="view-all" onClick={viewAllOnClick}>
          View All {SVG.chevronRight}
        </span>
      ) : null}

      {SheResearch ? (
        <span className="view-all she" onClick={goToSheconomics()}>
          Go to SHEconomics {SVG.chevronRight}
        </span>
      ) : null}
    </div>
  );
};

/**
 * @author Ahmed Samer
 * @date   2021-04-07
 * @description Declaration of the UrlRoute interface.
 * @filename ugc-request.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { UserRequest } from "./user-request";
import { ListItemType } from "./list";
import { PersonEntity } from "./person-entity";
/**
 * the GraphQl Type.
 */
export const GRAPHQL_TYPE_PITCH_REQUEST = "PitchRequest";

/**
 *
 *
 * @export
 * @interface UGCRequest
 * @extends {Node}
 */
export interface PitchRequest<D extends Date | string = string>
  extends UserRequest<D> {
  /**
   * A description of the actions associated with the poster.
   *
   * @type {string}
   * @memberof PosterModel
   */
  item?: ListItemType;

  /**
   * The collection of persons and their associated entity associated with the request
   *
   * @type {PersonEntity[]}
   * @memberof Story
   */
  peopleEntities?: PersonEntity[];

  /**
   * the  media asset that holding sample video assets
   *
   * @type {MediaAsset}
   * @memberof UGCRequest
   */
  investmentUrl?: string;
}

import * as React from "react";
import { connect } from "react-redux";
import { Loader } from "../../page/loader";
import YouTube from "react-youtube";
import { useOnScreen } from "../../../utilities/use-on-screen";
import { ProfilePictureName } from "../../page-framework/profile-picture-name";
import { getProfileInfo } from "../../../utilities/get-profile-info";
import {
  PreviewKind,
  TopLevelMediaKind,
  OriginalContentStory,
  GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
} from "@radivision/graphql";
import { Router } from "../../../utilities/router";
import isEmpty from "lodash.isempty";
import { AppState } from "../../../redux";
import * as Actions from "../../../redux/actions";
import { OptimizedImageUrls } from "../../../component-configuration/optimized-image";
import { ImageHelper } from "../../../utilities/image-helper";
import { PlayInMiniPlayerPayload, PlayInMiniPlayerAction, VideoModes } from "../../../redux/full-screen-video/types";
import { VIDEO_MODE_MINIPLAYER } from "../../../redux/full-screen-video/constants";
import { SetProfileActiveTabPayload, SetProfileActiveTabAction } from "../../../redux/account/types";
import { gaEvent } from "../../../utilities/general";
import { SetAppStateAction, SetAppStatePayload } from "../../../redux/app/types";
import { SelectPitchVersion } from "../../inner-pages/profile-details-tab/investment/pitch-video-panel";
import { SVG } from "../../../component-configuration/svgs";
import { CloseDetailsPanelAction } from "../../../redux/details-panel/types";
import { PitchVideoStory } from "../../../redux/types/pitch-video-story";
import { extractYoutubeId } from "../../../utilities/extract-youtube-id";
import { Suspense } from "react";
import { Preview } from "../../../utilities/preview";
const VideoPanel = React.lazy(() => import("../../page-framework/video-panel"));

interface ComponentProps {
  slideIndex: number;
  currentSlideIndex: number;
  slickNext: () => void;
  showBtn?: boolean;
  item: OriginalContentStory & PitchVideoStory;
  playMode: "YOUTUBE" | "HLS";
}

interface MapStateProps {
  videoId: string | null;
  isPlayingFullScreen: boolean;
  videoMode: VideoModes;
  isDetailsPanelOpen: boolean;
  muted: boolean;
}

interface MapDispatchProps {
  playInMiniPlayer: (payload: PlayInMiniPlayerPayload) => PlayInMiniPlayerAction;
  setProfileActiveTab: (payload: SetProfileActiveTabPayload) => SetProfileActiveTabAction;
  setAppState: (payload: SetAppStatePayload) => SetAppStateAction;
  closeDetailsPanel: () => CloseDetailsPanelAction;
}

type Props = MapDispatchProps & ComponentProps & MapStateProps;

export const Component = (props: Props) => {
  const {
    currentSlideIndex,
    slideIndex,
    item,
    slickNext,
    videoId,
    videoMode,
    isPlayingFullScreen,
    isDetailsPanelOpen,
    setAppState,
    showBtn,
    setProfileActiveTab,
    muted,
    playMode,
  } = props;

  const [isVideoFullScreen, setIsVideoFullScreen] = React.useState(false);
  const EXCERPT = Preview.getUrlFromPreview(item?.previews, PreviewKind.EXCERPT);
  const [videoReady, setVideoReady] = React.useState(false);
  const [isCentered, setIsCentered] = React.useState(false);
  const videoRef = React.useRef(null);
  const eitherPitch = item?.trailer || item?.originalClip;
  const DEFAULT_VIDEO_ID: string =
    item.__typename === GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY ? item?.externalId : eitherPitch?.externalId;
  const [youtubeId, setYoutubeId] = React.useState(DEFAULT_VIDEO_ID || extractYoutubeId(item?.link));

  const thisRef = React.useRef(null);
  const isOnScreen = useOnScreen(thisRef);
  const notPlayingFullScreenVideo = isEmpty(videoId) && !isPlayingFullScreen;
  const notPlayingInMiniPlayer = isEmpty(videoId) && videoMode !== VIDEO_MODE_MINIPLAYER;
  const isCurrent = currentSlideIndex === slideIndex;
  const PLAYER = playMode === "HLS" ? OriginalVideoPanel : YOUTUBE;

  React.useEffect(() => {
    if (!isCurrent) {
      setVideoReady(false);
    }
  }, [isCurrent]);

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight === screen.height) {
        setIsVideoFullScreen(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const currentVersion =
    item?.trailer?.externalId === youtubeId ? "99sec" : item?.originalClip?.externalId === youtubeId ? "5min" : null;

  const imageUrls: OptimizedImageUrls = item?.previews
    ? ImageHelper.fetchOptimizedImageUrl({
        imageType: "PREVIEW",
        preview: {
          content: item.previews,
          previewKind: PreviewKind.CAROUSEL,
          topLevelMedia: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: { containerWidthRatio: 3 / 4, numberOfItems: 1 },
        isSquareImage: false,
        revision: item.revision,
      })
    : null;

  const backgroundImageUrl =
    imageUrls?.requestedResolutionUrl ||
    imageUrls?.screenResolutionUrl ||
    `https://i.ytimg.com/vi/${DEFAULT_VIDEO_ID}/sddefault.jpg`;
  const backgroundImage = backgroundImageUrl && `url('${backgroundImageUrl}')`;
  // debugger;
  React.useEffect(() => {
    try {
      if (isCurrent) {
        setIsCentered(true);
      } else {
        setIsCentered(false);
      }
    } catch (e) {}
  }, [isCurrent]);

  React.useEffect(() => {
    const checkMute = async () => {
      if (!isCentered) return null;
      const isMuted = await videoRef?.current?.internalPlayer?.isMuted();
      if (isMuted === false && muted) {
        setAppState({ muted: false });
      }
    };
    const checkMuteInterval = setInterval(checkMute, 1000);
    return () => clearInterval(checkMuteInterval);
  }, [muted, videoRef, setAppState, isCentered]);

  const company = item && item.peopleEntities && item.peopleEntities[0] && item.peopleEntities[0].entity;
  const referral = item && item.peopleEntities && item.peopleEntities[1] && item.peopleEntities[1].entity;
  const { profileUrl } = getProfileInfo(company);
  const { profileUrl: referralUrl } = getProfileInfo(referral);
  const shouldPlayVideo =
    (isOnScreen || isVideoFullScreen) &&
    isCentered &&
    !isDetailsPanelOpen &&
    notPlayingFullScreenVideo &&
    notPlayingInMiniPlayer;

  return (
    <div ref={thisRef}>
      <Container>
        {shouldPlayVideo ? (
          <div className="company-referral">
            <div className="company-name">
              <ProfilePictureName subject={company} />
            </div>
            <div className="referral">
              {referral && (
                <>
                  <div className="referral-name">
                    <p className="nominated-by">Nominated By</p>
                    <a
                      href={referralUrl}
                      className="nominated-by"
                      onClick={(e: any) => {
                        e.preventDefault();
                        Router.to(referralUrl);
                      }}
                    >
                      {referral?.name}
                    </a>
                  </div>
                  <ProfilePictureName subject={referral} />
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="mt-5"></div>
        )}
        <VideoContainer isCurrent={isCurrent}>
          <div
            style={{
              backgroundImage,
              opacity: !isOnScreen ? 1 : videoReady && isCentered ? 0 : 1,
              height: isCentered ? "100%" : "75%",
              margin: "auto 0",
              paddingTop: "177.78%",
              borderRadius: "12px"
            }}
            className="pitch-poster"
          />
          <Loader isActive={isCentered && !videoReady && notPlayingInMiniPlayer} />
          <Video
            style={{
              backgroundImage: isCentered ? "none" : backgroundImage,
              height: isCentered ? "100%" : "75%",
              margin: "auto 0",
              paddingTop: "177.78%",
              borderRadius: "12px"
            }}
          >
            {shouldPlayVideo && (
              <>
                <PLAYER
                  key={`${youtubeId}-${muted ? 1 : 0}-${item.id}`}
                  ref={videoRef}
                  onPlay={(event) => {
                    setTimeout(() => {
                      event?.target?.playVideo();
                      setVideoReady(true);
                    }, 100);
                  }}
                  onEnd={() => {
                    slickNext();
                  }}
                  videoPosition={"FIT"}
                  videoSource={playMode === "HLS" ? EXCERPT : youtubeId}
                  mute={muted}
                />
              </>
            )}
          </Video>
        </VideoContainer>
        <div className="pitch-card-buttons">
          <CardTitle>
            <Buttons>
              {item.__typename === "PitchVideoStory" ? (
                <SelectPitchVersion
                  selected={currentVersion}
                  className={`highlighted ${item?.trailer?.externalId ? "has-quick" : ""}  ${
                    item?.originalClip?.externalId ? "has-full" : ""
                  }`}
                  onClick={(version: string) => {
                    const video = version === "99sec" ? item?.trailer : item?.originalClip;
                    if (video) {
                      setYoutubeId(video?.externalId);
                    }
                  }}
                />
              ) : null}
            </Buttons>
          </CardTitle>
          <div className="btns">
            <CardTitle style={{}}>
              {showBtn ? (
                <Buttons>
                  <Button
                    text={
                      <>
                        <span>More Info</span>
                        {SVG.externalLinkAlt}
                      </>
                    }
                    isOutline={false}
                    onClick={() => {
                      gaEvent("PP-learn-more", "click", item?.peopleEntities[0]?.entity?.name, false);
                      // const URL =() `${window.location.href.split("?")[0]}?slide=${item.id}#:pitch_planet`;
                      // window.history.pushState("", "", URL);
                      setAppState({ lastSeenPitchId: item.id });
                      Router.to(profileUrl, undefined, undefined, "Opportunities");
                      setProfileActiveTab({ activeTab: "Opportunities" });

                      // window.open(link);
                    }}
                  />
                </Buttons>
              ) : null}
            </CardTitle>
          </div>
        </div>
        <CardText>{item?.description}</CardText>
      </Container>
    </div>
  );
};

const mapState = (state: AppState) => ({
  videoId: state.fullScreenVideo.videoId,
  isPlayingFullScreen: state.fullScreenVideo.isPlayingFullScreen,
  isDetailsPanelOpen: !isEmpty(state.detailsPanel.item),
  muted: state.app.muted,
});

const mapDispatch = {
  playInMiniPlayer: Actions.playInMiniPlayer,
  setProfileActiveTab: Actions.setProfileActiveTab,
  setAppState: Actions.setAppState,
  closeDetailsPanel: Actions.closeDetailsPanel,
};

export const Card = connect(mapState, mapDispatch)(Component);

export const CardText = ({ children }) => {
  return (
    <div className={`pitch-card-text sm-scroller`}>
      <span>{children}</span>
    </div>
  );
};

const OriginalVideoPanel = ({
  refId,
  key,
  videoSource,
  mute,
  videoPosition,
  onEnd,
  onPlay,
}: {
  refId: any;
  key: string;
  videoSource: string;
  mute: boolean;
  videoPosition: "FULL_SCREEN" | "FIT";
  onEnd: () => void;
  onPlay: () => void;
}) => {
  return (
    <Suspense fallback={<div />}>
      <VideoPanel
        ref={refId}
        key={key}
        videoSrc={videoSource}
        onPlay={onPlay}
        onVideoEnded={onEnd}
        mute={mute}
        youTube={null}
        videoPosition={videoPosition}
      />
    </Suspense>
  );
};

const YOUTUBE = ({
  refId,
  key,
  videoSource,
  mute,
  videoPosition,
  onEnd,
  onPlay,
}: {
  refId: any;
  key: string;
  videoSource: string;
  mute: boolean;
  videoPosition: "FULL_SCREEN" | "FIT";
  onEnd: () => void;
  onPlay: () => void;
}) => {
  return (
    <YouTube
      ref={refId}
      key={key}
      // ``
      videoId={videoSource}
      opts={{
        height: "100%",
        width: "100%",
        playerVars: {
          // fs: 0,
          rel: 0,
          autoplay: 1,
          loop: 0,
          playsinline: 1,
          start: 0,
          mute: mute ? 1 : 0,
        },
      }}
      id={videoSource}
      onReady={onPlay}
      onEnd={onEnd}
      className="iframe"
      width="100%"
      height="100%"
    />
  );
};

export const Button = ({
  isOutline,
  text,
  onClick,
  target,
  isDisabled,
}: {
  isOutline: boolean;
  isDisabled?: boolean;
  text: any;
  target?: string;
  onClick?: () => void;
}) => {
  return (
    <a
      className={`pitch-card-button ${isOutline && !isDisabled ? "outline-button" : ""} ${
        isDisabled ? "disabled" : ""
      }`}
      onClick={!isDisabled ? onClick : undefined}
      target={target}
    >
      {text}
    </a>
  );
};

export const VideoContainer = ({ children, isCurrent }) => {
  return (
    <div style={{ borderRadius: "12px" }} className={`pitch-video-container ${isCurrent ? "current" : ""}`}>
      {children}
    </div>
  );
};

export const CardTitle = ({ children, style }) => {
  return (
    <div className="pitch-card-title" style={style}>
      {children}
    </div>
  );
};
export const Buttons = ({ children }) => {
  return <div className="pitch-card-actions">{children}</div>;
};

export const Container = ({ children }) => {
  return <div className="pitch-card">{children}</div>;
};

export const Video = ({ children, style }) => {
  return (
    <div className="pitch-video" style={style}>
      {children}
    </div>
  );
};

/**
 * @author Sarah Nouh
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-20-09 5:00
 * @description Implementation of loader.
 * @filename loader.tsx
 */
import React from "react";
import { Spinner } from "../user-widgets/spinner";

const LOADER_GIF = require("../../../static/Pre-loader-with-bckg.gif");
const SPLASH_LOADER_GIF = require("../../../static/Time_1_8.gif");
//const LOGO = require("../../../static/rv_logo_01.png");
const LOGO = require("../../../static/rv-logo-on-black.png");

/**
 * The props of the loader component.
 *
 * @interface
 */
interface LoaderProps {
  /**
   * Indicates whether the loader should be active or not.
   *
   * @type boolean
   */
  isActive: boolean;

  /**
   *
   *
   * @type {boolen}
   * @memberof LoaderProps
   */
  splash?: boolean;
}

/**
 *  A React component that renders the loader.
 *
 * @export
 * @class Loader
 * @extends {React.Component<LoaderProps>}
 */
export class Loader extends React.Component<LoaderProps> {
  /**
   * Constructor.
   *
   * @param {LoaderProps} props The props of the component.
   */
  constructor(props: LoaderProps) {
    super(props);
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render() {
    return this.props.isActive ? (
      this.props.splash && this.props.splash === true ? (
        <div className="loader-container">
          <div id="breathing-image">
            <img src={LOGO} />
          </div>
        </div>
      ) : (
        <div className="loader-container spinner">
          <Spinner color="#4cb4e7" size={80} />
        </div>
      )
    ) : null;
  }
}

import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { FormFieldContainer, FormFieldInput } from "../../../react-components/modals/modal-form-elements";
import { SetProfileStateAction, SetProfileStatePayload, ProfileModuleState } from "../redux/profile/types";
import { setProfileState } from "../redux/profile/actions";
import { numberWithCommas } from "../../../utilities/number-with-commas";
import { CompanySizeRanges } from "../../../constants/company-info";
import { GRAPHQL_TYPE_ENTITY } from "@radivision/graphql";

interface MapStateProps {
  noOfEmployees: string;
  isCompany: boolean;
}

interface MapDispatchProps {
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { noOfEmployees, setProfileState, isCompany } = props;

  if (!isCompany) return null;

  return (
    <FormFieldContainer>
      <label htmlFor="noOfEmployees">
        <span>Company Size: *</span>
      </label>

      <FormFieldInput>
        <select
          value={noOfEmployees ? noOfEmployees : ""}
          onChange={(e) => {
            const noOfEmployees = e.target.value;
            setProfileState({ noOfEmployees });
          }}
        >
          <option disabled value="">
            Select number of employees
          </option>
          {CompanySizeRanges.map((range) => (
            <option key={`range-${range}`} value={range}>
              {range.replace(/(\d+)/g, (num) => numberWithCommas(num))} Employees
            </option>
          ))}
        </select>
      </FormFieldInput>
    </FormFieldContainer>
  );
};

const mapState = (state: AppState & ProfileModuleState) => {
  return {
    noOfEmployees: state.profile.noOfEmployees,
    isCompany: state.profile.type === GRAPHQL_TYPE_ENTITY,
  };
};

const mapDispatch = {
  setProfileState: setProfileState,
};

export const CompanySize = connect(mapState, mapDispatch)(Component);

/**
 * @author Muhammad Omran
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2020-10-21
 * @description Implementation of the GraphQL query to retrieve a person.
 * @filename person-query.tsx
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL query to retrieve a person with an id.
 *
 * @type {GraphQLTaggedNode}
 */
export const PERSON_QUERY: GraphQLTaggedNode = graphql`
  query personQuery($id: ID!) {
    person(id: $id) {
      hasErrors
      person {
        __typename
        description
        emailAddresses
        fullName
        accountType {
          kind
          subKind
          description
        }
        hasPublicPage
        isEditable
        isClaimable
        landingPageUrl
        revision
        id
        greeting
        myList
        defaultLocation {
          id
          label
        }
        locations {
          id
          label
        }
        previews {
          count
          edges {
            cursor
            preview {
              link
              kind
              mediaAsset {
                id
                files {
                  id
                  name
                  path
                }
                topLevelMediaType {
                  kind
                  label
                }
              }
            }
          }
        }
        entities {
          __typename
          name
          id
          revision
          hasPublicPage
          description
          tagLine
          websiteUrl
          landingPageUrl
          isEditable
          previews {
            count
            edges {
              cursor
              preview {
                link
                mediaAsset {
                  id
                  files {
                    id
                    name
                    path
                  }
                  topLevelMediaType {
                    kind
                    label
                  }
                }
                kind
              }
            }
          }
        }
        experience {
          count
          edges {
            cursor
            position {
              id
              isCurrent
              isDeleted
              isFounder
              title
              entity {
                __typename
                name
                id
                description
                websiteUrl
                hasPublicPage
                isEditable
                landingPageUrl
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                      kind
                    }
                  }
                }
              }
            }
          }
        }
        inspirations {
          id
          inspirer {
            ... on Entity {
              __typename
              id
              name
              landingPageUrl
              description
              websiteUrl
              hasPublicPage
              isEditable
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    mediaAsset {
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                    kind
                  }
                }
              }
            }
          }
        }
        investmentsBy {
          id
          entity {
            ... on Entity {
              __typename
              id
              name
              description
              websiteUrl
              landingPageUrl
              hasPublicPage
              isEditable
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    mediaAsset {
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                    kind
                  }
                }
              }
            }
          }
          investee {
            ... on Entity {
              __typename
              name
              id
              landingPageUrl
              description
              websiteUrl
              hasPublicPage
              isEditable
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    mediaAsset {
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                    kind
                  }
                }
              }
            }
          }
        }
        follows {
          ... on Entity {
            __typename
            name
            id
            landingPageUrl
            previews {
              count
              edges {
                cursor
                preview {
                  link
                  kind
                  mediaAsset {
                    files {
                      id
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                }
              }
            }
          }
          ... on Person {
            __typename
            description
            fullName
            hasPublicPage
            id
            greeting
            landingPageUrl
            previews {
              count
              edges {
                cursor
                preview {
                  link
                  kind
                  mediaAsset {
                    id
                    files {
                      id
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                }
              }
            }
          }
        }
        mediaAbout {
          count
          edges {
            cursor
            story {
              __typename
              ... on RadivisionPost {
                id
                link
                previews {
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
                landingPageUrl
                revision
              }
              ... on OnlineCourseStory {
                id
                link
                cost
                publisher
                publishedDate
                timeRequiredInSeconds
                description
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      mediaAsset {
                        id
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                      kind
                    }
                  }
                }
                subTitle
                title
                revision
              }
              ... on RadivisionPost {
                id
                content
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                      kind
                    }
                  }
                }
                revision
                title
              }
              ... on ArticleStory {
                description
                id
                link
                publisher
                publishedDate
                description
                timeToReadInSeconds
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      mediaAsset {
                        id
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                      kind
                    }
                  }
                }
                revision
                subTitle
                title
              }
              ... on BookStory {
                id
                link
                cost
                description
                publisher
                publishedDate
                timeToReadInSeconds
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                      kind
                    }
                  }
                }
                subTitle
                title
                revision
              }
              ... on NewsletterStory {
                id
                description
                link
                cost
                publisher
                publishedDate
                timeToReadInSeconds
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                      kind
                    }
                  }
                }
                subTitle
                title
                revision
              }
              ... on PodcastStory {
                id
                link
                cost
                description
                durationInSeconds
                publisher
                publishedDate
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                      kind
                    }
                  }
                }
                subTitle
                title
                revision
              }
              ... on OriginalContentStory {
                id
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                      kind
                    }
                  }
                }
                subTitle
                title
                revision
              }
            }
          }
        }
        responsibility
        websiteUrl
        northStar {
          __typename
          description
          id
          questions {
            __typename
            ... on TextQuestion {
              content
              id
              response {
                content
                id
              }
            }
          }
        }
        socialMediaCredentials {
          credential
          platform {
            kind
          }
        }
        questionnaires {
          count
          edges {
            cursor
            node {
              questions {
                __typename
                ... on MultipleChoiceQuestion {
                  id
                  title
                  choices {
                    acceptsText
                    id
                  }
                  response {
                    choices {
                      id
                      title
                    }
                  }
                }
                ... on TextQuestion {
                  id
                  content
                  response {
                    content
                    id
                  }
                  title
                }
              }
            }
          }
        }
        websiteUrl
        socialMediaProfiles {
          id
          link
          platform {
            id
            kind
          }
        }
      }
    }
  }
`;

/**
 * @Author: Nassar Sara
 * @Date:   2018-05-30T10:49:05+02:00
 * @Email:  sara@radivision.com
 * @Project: Radivision
 * @Filename: dynamic-list-order-expression.ts
 * @Last modified by:   Nassar Sara
 * @Last modified time: 2018-05-30T13:39:50+02:00
 * @Copyright: Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

/**
 * The __typeID of the Dynamic List Order Expression GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_DYNAMIC_LIST_ORDER_EXPRESSION: string = "DynamicListOrderExpression";

/**
 * An order expression to order those entities, people, and stories included in the dynamic list
 *
 * @export
 * @interface DynamicListOrderExpression
 */
export interface DynamicListOrderExpression {
  /**
   * The name of the attribute which is the order key
   *
   * @type {string}
   * @memberof DynamicListOrder
   */
  attribute?: string;

  /**
   * A flag which is true if the attribute values are in descending order
   *
   * @type {boolean}
   * @memberof DynamicListOrder
   */
  descending?: boolean;

  /**
   * The typename of the dynamic list order.
   *
   * @type {string}
   */
  __typename?: string;
}

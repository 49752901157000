import { FullScreenVideoReducer } from "./reducer";
import { FullScreenVideoModuleState } from "./types";

export { FullScreenVideoModuleState };

export const fullScreenVideoModule = {
  id: "fullScreenVideo",
  reducerMap: {
    fullScreenVideo: FullScreenVideoReducer,
  },
};

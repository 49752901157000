/**
 * @author William A. Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-30
 * @description Implementation of the CognitoIdpAuthenticator react component
 * @filename cognito-idp-authenticator.tsx
 */
import React from "react";
import { Router } from "../../utilities/router";

/**
 * The props associated with the CognitoIdpAuthenticator component.
 *
 * @interface
 */
export interface CognitoIdpAuthenticatorProps {
  /**
   * The Cognito aws.cognito.signin.user.admin token information.
   *
   * @type {string}
   */
  tokens: {
    /**
     * The AWS identity pool access token.
     *
     * @type {string}
     */
    access_Token: string;

    /**
     * The date adnd time when the token expires.
     *
     * @type {string}
     */
    expires_in: string;

    /**
     * The AWS identity pool identity token.
     *
     * @type {string}
     */
    id_token: string;

    /**
     * The AWS identity pool .
     *
     * @type {string}
     */
    token_type: string;
  };
}

/**
 *  A React component that executes the process to complete the Cognito IDP login.
 *
 * @extends {React.Component<CognitoIdpAuthenticatorProps>}
 */
export class CognitoIdpAuthenticator extends React.Component<
  CognitoIdpAuthenticatorProps
> {
  /**
   * Constructor
   *
   * @param {CognitoIdpAuthenticatorProps} props The props.
   */
  constructor(props: CognitoIdpAuthenticatorProps) {
    super(props);
  }

  /**
   * Invoked by the React framework immediately after a component is mounted.
   */
  componentDidMount() {
    return import("../../authentication/cognito-client").then((module) => {
      return module.CognitoClient.loginWithIdentityPoolIdTokens(
        this.props.tokens
      )
        .then(
          (): Promise<any> => {
            Router.to("", []);
            return Promise.resolve();
          }
        )
        .catch(
          (e: any): Promise<any> => {
            Router.to("", []);
            return Promise.resolve();
          }
        );
    });
  }

  /**
   * Returns the React node containing the rendered content.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    return <div />;
  }
}

import React from "react";
import { Types } from "@radivision/common";
import { Badge as GraphQlBadge } from "@radivision/graphql/lib/ts/graphql/badge";
import { PreviewKind } from "@radivision/graphql/lib/ts/graphql/preview-type";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import { CarouselItem } from "../../component-configuration/carousel-item";
import { RelayProps } from "../../relay/relay-props";
import { getCarouselItem, extractBadge } from "../../utilities/general";
import { ImageHelper } from "../../utilities/image-helper";
import { RdvCarousel } from "../lists/rdv-carousel";
import { ListCollection } from "@radivision/graphql/lib/ts/graphql/list-collection";
import { DynamicList } from "@radivision/graphql/lib/ts/graphql/dynamic-list";
import { FixedList } from "@radivision/graphql/lib/ts/graphql/fixed-list";
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import { Show } from "../../component-configuration/show";
import { Preview } from "../../utilities/preview";
import isEmpty from "lodash.isempty";

import { FetchOriginalShowsAction, FetchOriginalShowsPayload } from "../../redux/cache/types";
import { AppState } from "../../redux";

interface OriginalShowsProps extends RelayProps {
  listCollection: ListCollection;
  json: any;
}

class OriginalShows extends React.Component<OriginalShowsProps> {
  constructor(props: OriginalShowsProps) {
    super(props);
    this.renderShow = this.renderShow.bind(this);
  }

  renderShow(): React.ReactNode {
    const windowSize = window.innerWidth;
    let originalShows: CarouselItem[] = [];
    let trailerUrl;
    if (this.props.listCollection.lists !== undefined) {
      this.props.listCollection.lists.map((list: DynamicList | FixedList, index: number) => {
        const badges: any = list.badges
          ? list.badges.map((award: GraphQlBadge) => {
              const MEDIA_ASSERT = extractBadge(award, PreviewKind.BADGE_LIGHT);
              if (Types.isObject(MEDIA_ASSERT)) {
                return {
                  link: award.link,
                  imgUrl: ImageHelper.fetchOptimizedImageUrl({
                    imageType: "MEDIA_ASSET",
                    mediaAsset: {
                      files: MEDIA_ASSERT.files,
                      type: TopLevelMediaKind.IMAGE,
                    },
                    desiredDimensions: {
                      containerWidthRatio: 1,
                      numberOfItems: 4,
                    },
                    revision: award.revision,
                  }),
                };
              }
            })
          : [];

        const items: CarouselItem[] = list.items
          ? list.items.edges.map((edge: any, index: number) => {
              return getCarouselItem(
                edge,
                list,
                index,
                {
                  containerWidthRatio: 1.4,
                  numberOfItems: 5,
                },
                PreviewKind.CAROUSEL,
                true,
              );
            })
          : [];

        if (list.previews !== undefined && list.previews !== null) {
          trailerUrl = Preview.getUrlFromPreview(list.previews, PreviewKind.EXCERPT);
        }

        originalShows.push({
          landingPageUrl: list.landingPageUrl,
          id: list.id,
          title: list.title,
          description: list.description,
          label: list.label,
          type: "OriginalContentShow",
          previewImageUrl: ImageHelper.fetchOptimizedImageUrl({
            imageType: "PREVIEW",
            preview: {
              content: list.previews,
              previewKind: PreviewKind.PORTRAIT,
              topLevelMedia: TopLevelMediaKind.IMAGE,
            },
            desiredDimensions: {
              containerWidthRatio: 1.5,
              numberOfItems: 6,
            },
            revision: list.revision,
          }),
          details: {
            badges,
            items,
            id: list.id,
            title: list.title,
            description: list.description,
            revision: list.revision,
            label: list.label,
            trailer: trailerUrl,
            moreLikeThis: list.moreLikeThis || "qQXPd4GmRlGeKbNxpioVpA",
            backgroundImage: ImageHelper.fetchOptimizedImageUrl({
              imageType: "PREVIEW",
              preview: {
                content: list.previews,
                previewKind: PreviewKind.BACKGROUND,
                topLevelMedia: TopLevelMediaKind.IMAGE,
              },
              desiredDimensions: {
                containerWidthRatio: windowSize > 1920 ? 1.8 : 1,
                numberOfItems: 1,
              },
              revision: list.revision,
            }),
            landingPageUrl: list.landingPageUrl,
            logo: ImageHelper.fetchOptimizedImageUrl({
              imageType: "PREVIEW",
              preview: {
                content: list.previews,
                previewKind: PreviewKind.HERO_LOGO,
                topLevelMedia: TopLevelMediaKind.IMAGE,
              },
              desiredDimensions: {
                containerWidthRatio: windowSize > 1920 ? 1.8 : 1.2,
                numberOfItems: 4,
              },
              revision: list.revision,
            }),
          } as Show,
        });
      });
    }

    const RADIVISION_ORIGINALS: Show = {
      id: this.props.listCollection.lists[this.props.listCollection.lists.length - 1].id,
      title: "Radivision Originals",
      description: "",
      revision: this.props.listCollection.revision,
      items: originalShows,
    };

    return (
      <RdvCarousel
        json={this.props.json}
        list={this.props.listCollection}
        key={`show-${this.props.listCollection.id}`}
        show={RADIVISION_ORIGINALS}
        itemPreviewType={PreviewKind.PORTRAIT}
        hasDetails={true}
        hideItemTitle={true}
        numberOfItems={6}
        pageInfo={this.props?.listCollection?.lists[this.props.listCollection?.lists?.length - 1]?.items?.pageInfo}
      />
    );
  }

  render(): React.ReactNode {
    return (
      <div
        id={
          this.props.listCollection.title
            ? this.props.listCollection.title.replace(/\s/g, "-").toLowerCase()
            : this.props.listCollection.name
            ? this.props.listCollection.name.replace(/\s/g, "-").toLowerCase()
            : this.props.listCollection.subTitle
            ? this.props.listCollection.subTitle.replace(/\s/g, "-").toLowerCase()
            : this.props.listCollection.id
        }
        className="the-shows"
      >
        {this.renderShow()}
      </div>
    );
  }
}

interface MapStateProps {
  data: any;
}

interface MapDispatchProps {
  fetchOriginalShows: (payload: FetchOriginalShowsPayload) => FetchOriginalShowsAction;
}

type Props = OriginalShowsProps & MapStateProps & MapDispatchProps;

export const _OriginalShowsContainer = (props: Props) => {
  const { data, fetchOriginalShows } = props;
  const listCollectionId: string = props.listCollection?.id;

  React.useEffect(() => {
    if (listCollectionId && !data) {
      const variables = { listCollectionId };
      fetchOriginalShows({ variables });
    }
  }, [listCollectionId, data, fetchOriginalShows]);

  if (isEmpty(data?.listCollection?.listCollection)) {
    return <div className="the-shows"></div>;
  }

  return (
    <div className="the-shows">
      <OriginalShows {...props} listCollection={data?.listCollection?.listCollection} />
    </div>
  );
};

const mapDispatch = {
  fetchOriginalShows: Actions.fetchOriginalShows,
};

const mapState = (state: AppState, props: OriginalShowsProps) => ({
  data: props.listCollection?.id && state.cache.store[props.listCollection?.id],
});

export const OriginalShowsContainer = connect(mapState, mapDispatch)(_OriginalShowsContainer);

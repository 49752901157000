import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { EntityInfo, ProfileModuleState } from "../redux/profile/types";
import { Router } from "../../../utilities/router";
import { getProfileInfo } from "../../../utilities/get-profile-info";

interface MapStateProps {
  existingCompany: EntityInfo;
}

export type Props = MapStateProps;

export const Component = (props: Props) => {
  const { existingCompany } = props;

  const { profileUrl } = getProfileInfo(existingCompany);

  const visitProfile = React.useCallback(() => {
    Router.to(profileUrl);
  }, [profileUrl]);

  if (!profileUrl) {
    return null;
  }

  return (
    <div className="error-message">
      The company that you are trying to add already exists on Radivision.
      <br />
      <a onClick={visitProfile} href={profileUrl}>
        Click here to view and claim profile.
      </a>
    </div>
  );
};

const mapState = (state: AppState & ProfileModuleState) => {
  const websiteUrl = state.profile.websiteUrl;
  const existingCompany = state.profile.existingUrls[websiteUrl];
  return {
    existingCompany,
  };
};

const mapDispatch = {};

export const CheckExistingWebsiteStatus = connect(mapState, mapDispatch)(Component);

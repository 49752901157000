import { Reducer } from "redux";
import { AuthenticationState, AuthenticationAction } from "./types";
import { SET_AUTH_STATE, AUTH_SIGNIN } from "./constants";

export const initialState: AuthenticationState = {
  step: AUTH_SIGNIN,
  name: "",
  password: "",
  email: "",
  verificationCode: "",
  accountType: null,
  resendAt: null,
  verifyAttributes: null,
  signupAccountTypes: [],
  signupRedirectionUrl: null,
};

export const AuthenticationReducer: Reducer<AuthenticationState, AuthenticationAction> = (
  state: AuthenticationState = initialState,
  action: AuthenticationAction,
) => {
  switch (action.type) {
    case SET_AUTH_STATE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

/**
 * @author William Alexander Livesley
 * @date   2018-03-22
 * @description Declaration of the Tag interface.
 * @filename tag.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Connection } from "./connection";
import { Note } from "./note";

/**
 * The __typeID of the GraphQL Tag object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_TAG: string = "Tag";

/**
 * A tag.
 *
 * @template D
 * @interface
 */
export interface Tag<D extends Date | string = string> {
  /**
   * The date and time when the node was created.
   *
   * @template D
   * @type {D}
   */
  created?: D;

  /**
   * A description of the tag.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The ID of the node.
   *
   * @type {string}
   */
  id?: string;

  /**
   * A flag which is true if the node is marked as deleted.
   *
   * @type {boolean}
   */
  isDeleted?: boolean;

  /**
   * A flag which, if true, means that the tag identifies a GraphQLType.
   *
   * @type {boolean}
   * @memberof Tag
   */
  isGraphQlType?: boolean;

  /**
   * The date and time when the node was last modified.
   *
   * @template D
   * @type {D}
   */
  lastModified?: D;

  /**
   * The revision of the node.
   *
   * @type {string}
   */
  revision?: string;

  /**
   * A label associated with the tag.
   *
   * @type {string}
   */
  label?: string;

  /**
   * The typename of the node.
   *
   * @type {string}
   */
  __typename?: string;
}

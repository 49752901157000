/**
 * @author Ahmed serag
 * @date   2018-04-19
 * @description Declaration of the InvestmentInEntity interface.
 * @filename investment-in-entity.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Currency } from "./currency";
import { Entity } from "./entity";
import { FundingRoundType } from "./funding-round-type";
import { Node } from "./node";
import { Person } from "./person";

/**
 * The __typeID of the Investment in Entity type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_INVESTMENT_IN_ENTITY: string = "InvestmentInEntity";

/**
 * An investment made in an entity
 *
 * @export
 * @interface InvestmentInEntity
 * @extends {Node<D>}
 * @template D
 */
export interface InvestmentInEntity<D extends Date | string = string>
  extends Node<D> {
  /**
   * The amount of the investment
   *
   * @type {number}
   * @memberof InvestmentInEntity
   */
  amount?: string;

  /**
   * The currency unit of the investment,
   *
   * @template D
   * @type {string}
   * @memberof InvestmentInEntity
   */
  currency?: Currency<D>;

  /**
   * The entity by whom the investment was made
   *
   * @template D
   * @type {Entity<D>}
   * @memberof InvestmentByEntity
   */
  entity?: Entity<D>;

  /**
   * The funding round when the investment was made
   *
   * @template D
   * @type {FundingRoundType<D>}
   * @memberof InvestmentByPerson
   */
  fundingRound?: FundingRoundType<D>;

  /**
   * The timestamp when the investment was made
   *
   * @template D
   * @type {D}
   * @memberof InvestmentInEntity
   */
  investmentDate?: D;

  /**
   * The person or collection of people who made the investment on behalf of the entity
   *
   * @template D
   * @type {Array<Person<D>>}
   * @memberof InvestmentByEntity
   */
  people?: Person[];
}

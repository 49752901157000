import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { FormFieldContainer, FormFieldInput } from "../../../react-components/modals/modal-form-elements";
import { SetProfileStateAction, SetProfileStatePayload, ProfileModuleState } from "../redux/profile/types";
import { setProfileState } from "../redux/profile/actions";
import { CompanyIndustries } from "../../../constants/company-info";
import { GRAPHQL_TYPE_ENTITY } from "@radivision/graphql";

interface MapStateProps {
  industry: string;
  isCompany: boolean;
}

interface MapDispatchProps {
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { industry, setProfileState, isCompany } = props;

  if (!isCompany) return null;

  return (
    <FormFieldContainer>
      <label htmlFor="industry">
        <span>Industry: *</span>
      </label>
      <FormFieldInput>
        <select value={industry ? industry : ""} onChange={(e) => setProfileState({ industry: e.target.value })}>
          <option disabled value={""}>
            Select company industry
          </option>
          {CompanyIndustries.map((_industry) => {
            return (
              <option key={`industry-${_industry.title}`} value={_industry.title}>
                {_industry.title}
              </option>
            );
          })}
        </select>
      </FormFieldInput>
    </FormFieldContainer>
  );
};

const mapState = (state: AppState & ProfileModuleState) => {
  return {
    industry: state.profile.industry,
    isCompany: state.profile.type === GRAPHQL_TYPE_ENTITY,
  };
};

const mapDispatch = {
  setProfileState: setProfileState,
};

export const CompanyIndustry = connect(mapState, mapDispatch)(Component);

/**
 * @author Ahmed Serag
 * @date   2018-04-19
 * @description Declaration of the EntityProduct interface.
 * @filename entity-product.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Connection } from "./connection";
import { Preview } from "./preview";

/**
 * The __typeID of the Entity Product GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_ENTITY_PRODUCT: string = "EntityProduct";

/**
 * A product offered by an entity
 *
 * @export
 * @interface EntityProduct
 */
export interface EntityProduct<D extends Date | string = string> {
  /**
   * A description of the product
   *
   * @type {string}
   * @memberof EntityProduct
   */
  description?: string;

  /**
   * The ID of the product
   *
   * @type {string}
   * @memberof EntityProduct
   */
  id?: string;

  /**
   * A URL for more details concerning the product
   *
   * @type {string}
   * @memberof EntityProduct
   */
  link?: string;

  /**
   * The name of the product
   *
   * @type {string}
   * @memberof EntityProduct
   */
  name?: string;

  /**
   * A collection of media assets used as previews for the product.
   *
   * @template D
   * @type {Connection<D, Preview>}
   */
  previews?: Connection<D, Preview>;
  
  /**
   * The typename of the entity product.
   *
   * @type {string}
   */
  __typename?: string;
}

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CreateUpdateDeactivateRequestInput = {
    readonly clientMutationId: string;
    readonly id?: string | null;
    readonly description?: string | null;
    readonly previews?: ReadonlyArray<CreatePreviewInput> | null;
    readonly force?: boolean | null;
    readonly revision?: string | null;
    readonly reasons?: ReadonlyArray<string | null> | null;
    readonly isDeleted?: boolean | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type CreatePreviewInput = {
    readonly kind?: ReadonlyArray<string> | null;
    readonly link?: string | null;
    readonly mediaAsset?: string | null;
};
export type createDeactivateAccountRequestMutationVariables = {
    readonly input: CreateUpdateDeactivateRequestInput;
};
export type createDeactivateAccountRequestMutationResponse = {
    readonly DeactivateRequest: {
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly hasErrors: boolean | null;
        readonly userRequest: ({
            readonly id?: string;
            readonly description?: string | null;
            readonly status?: string | null;
            readonly reasons?: ReadonlyArray<string | null> | null;
        } & ({
            readonly id: string;
            readonly description: string | null;
            readonly status: string | null;
            readonly reasons: ReadonlyArray<string | null> | null;
        } | {
            /*This will never be '% other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        })) | null;
    } | null;
};
export type createDeactivateAccountRequestMutation = {
    readonly response: createDeactivateAccountRequestMutationResponse;
    readonly variables: createDeactivateAccountRequestMutationVariables;
};



/*
mutation createDeactivateAccountRequestMutation(
  $input: CreateUpdateDeactivateRequestInput!
) {
  DeactivateRequest(input: $input) {
    errors {
      id
      location
    }
    hasErrors
    userRequest {
      __typename
      ... on DeactivateRequest {
        id
        description
        status
        reasons
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateUpdateDeactivateRequestInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reasons",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "createDeactivateAccountRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "DeactivateRequest",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUpdateUserRequestPayload",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userRequest",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "type": "DeactivateRequest",
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createDeactivateAccountRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "DeactivateRequest",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUpdateUserRequestPayload",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userRequest",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "DeactivateRequest",
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "createDeactivateAccountRequestMutation",
    "id": null,
    "text": "mutation createDeactivateAccountRequestMutation(\n  $input: CreateUpdateDeactivateRequestInput!\n) {\n  DeactivateRequest(input: $input) {\n    errors {\n      id\n      location\n    }\n    hasErrors\n    userRequest {\n      __typename\n      ... on DeactivateRequest {\n        id\n        description\n        status\n        reasons\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '087ad7131821bc325df852e2db3dc007';
export default node;

import { takeLatest, call, put, select, all, fork } from "redux-saga/effects";
import { getUserIdentity } from "../account/utils";
import $ from "jquery";
import { NAVIGATE_TO } from "./constants";
import { NavigateToAction } from "./types";
import { Router } from "../../utilities/router";
import { fetchHtmlPageWorker } from "../cache/saga";
import { AppState } from "..";
import { fetchInProgress, fetchHtmlPage } from "../cache/actions";
import isEmpty from "lodash.isempty";

export function* navigateToWorker(action: NavigateToAction) {
  const { alias, queryParameters, location, hash } = action.payload;

  yield put(fetchInProgress({ key: "navigation", status: true }));
  try {
    const currentAlias = yield Router.getAlias();
    const { userIdentityId } = getUserIdentity();
    const [pageAlias, ...rest] = alias.replace(/^\//, "").split("/");

    const qparams = rest.length > 1 ? [`item_id=${rest[1]}`] : [];
    const cacheKey = `${isEmpty(pageAlias) ? "home" : pageAlias}-${qparams.join()}`;
    const htmlPage = yield select((state: AppState) => state.cache.store[cacheKey]);
    const noHtmlPageChacheExits = isEmpty(htmlPage);
    const force = currentAlias !== alias;
    const variables = {
      userIdentityId,
      queryParameters: qparams,
      alias: pageAlias,
    };
    if (noHtmlPageChacheExits) {
      const payload = {
        variables,
        cacheKey,
        force,
      };
      yield call(fetchHtmlPageWorker, fetchHtmlPage(payload));
    }
    yield Router.to(alias, queryParameters, location, hash);
    yield put(fetchInProgress({ key: "navigation", status: false }));
    if (!isEmpty(hash)) {
      const elem = document.getElementById(hash);
      if (elem) {
        window.scrollTo({ top: $(`#${elem}`).offset().top - 50 });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  } catch (error) {}
}
export function* watchNavigateTo() {
  yield takeLatest(NAVIGATE_TO, navigateToWorker);
}

const sagas = [watchNavigateTo];
export default function* root() {
  yield all(sagas.map((saga) => fork(saga)));
}

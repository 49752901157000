import * as React from "react";
import { SVG } from "../../../component-configuration/svgs";

import { useCarouselContext } from "./context";

export const NextButton = (): JSX.Element => {
  const { displayMode, state, next } = useCarouselContext();

  const isCarouselDisplayMode: boolean = !displayMode || displayMode === "Carousel";
  if (!isCarouselDisplayMode) return null;

  return (
    <div
      className="rdv-btn rdv-carousel-next"
      onClick={() => {
        if (!state.loading) next();
      }}
    >
      {SVG.chevronRight}
    </div>
  );
};

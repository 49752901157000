import { GRAPHQL_TYPE_PERSON, GRAPHQL_TYPE_ENTITY, GRAPHQL_TYPE_POSTER, Person } from "@radivision/graphql";
import { CarouselItem } from "../../../component-configuration/carousel-item";
import { AppState } from "../../../redux";
import isEmpty from "lodash.isempty";
import {Entity} from "aws-sdk/clients/costexplorer";

export const isItemSaveable = (_state: AppState, item: CarouselItem) =>
  !(item.type === "OriginalContentShow" || item.type === GRAPHQL_TYPE_POSTER);

export const isSaveItemLoading = (state: AppState, item: CarouselItem): boolean => {
  if (!item) {
    return false;
  }
  const { saveItemLoading } = state.account;
  return saveItemLoading.includes(item.id);
};

export const isPersonOrEntity = (_state: AppState, item: CarouselItem | Person | Entity): boolean => {
  if (!item) {
    return false;
  }
  const isPerson = item["__typename"] === "Person" || item["type"] === "Person";
  const isEntity = item["__typename"] === "Entity" || item["type"] === "Entity";
  return isPerson || isEntity;
};

export const isItemSaved = (state: AppState, item: CarouselItem) => {
  if (!item) {
    return false;
  }
  const { user, savedItems } = state.account;

  const isFollowed =
    isPersonOrEntity(state, item) &&
    user &&
    !isEmpty(user.follows) &&
    user.follows.find((f) => f !== null && f.id === item.id)
      ? true
      : false;

  const isSaved =
    !isPersonOrEntity(state, item) &&
    user &&
    user.myList &&
    savedItems &&
    savedItems.items &&
    savedItems.items.edges &&
    savedItems.items.edges.length &&
    savedItems.items.edges.find((r) => r.listItem.item.id === item.id)
      ? true
      : false;

  return isSaved || isFollowed;
};

// false && (<REACT_USER_CONTEXT.Consumer>
//   {(value: {
//     user: GraphQlPerson;
//     userSavedItems: GraphQlFixedList;
//     updateUser: Function;
//     updateSavedItemsList: Function;
//   }) => {
//     const itemId: string =
//       item.landingPageUrl &&
//       (item.landingPageUrl.includes("/person/") ||
//         item.landingPageUrl.includes("/company/"))
//         ? item.landingPageUrl.substring(
//             item.landingPageUrl.lastIndexOf("/") + 1
//           )
//         : item.id;
//     const savedState: boolean =
//       value.user &&
//       (item.type === GRAPHQL_TYPE_PERSON ||
//         item.type === GRAPHQL_TYPE_ENTITY ||
//         (item.landingPageUrl &&
//           (item.landingPageUrl.includes("/person/") ||
//             item.landingPageUrl.includes(
//               "/company/"
//             )))) &&
//       value.user.follows !== undefined &&
//       value.user.follows !== null &&
//       value.user.follows.find(
//         (f) => f !== null && f.id === itemId
//       )
//         ? true
//         : value.user &&
//           value.user.myList &&
//           value.userSavedItems &&
//           value.userSavedItems.items &&
//           value.userSavedItems.items.edges &&
//           value.userSavedItems.items.edges.length &&
//           value.userSavedItems.items.edges.find(
//             (r) => r.listItem.item.id === item.id
//           )
//         ? true
//         : false;
//     return (
//       <span
//         className={`save-item ${
//           savedState ||
//           this.state.saveItemLoading.includes(item.id)
//             ? "saved"
//             : ""
//         }`}
//         data-toggle={`${!value.user ? "modal" : ""}`}
//         data-target={
//           !value.user ? `#${LOGIN_MODAL_ID}` : ""
//         }
//         onClick={() => {
//           if (!value.user) {
//             return;
//           }

//           // if item is in loading state doent do any thing
//           if (
//             this.state.saveItemLoading.includes(item.id)
//           ) {
//             return;
//           }

//           // if item is entity or a person >> then call to action is follow
//           this.setState(
//             (prevState: HeroCarouselState) => ({
//               saveItemLoading: prevState.saveItemLoading.concat(
//                 item.id
//               ),
//             })
//           );
//           if (
//             item.type === GRAPHQL_TYPE_PERSON ||
//             item.type === GRAPHQL_TYPE_ENTITY ||
//             (item.landingPageUrl &&
//               (item.landingPageUrl.includes("/person/") ||
//                 item.landingPageUrl.includes(
//                   "/company/"
//                 )))
//           ) {
//             if (savedState) {
//               this.handleUnFollow(
//                 value.user,
//                 value.updateUser,
//                 itemId
//               );
//             } else {
//               this.handleFollow(
//                 value.user,
//                 value.updateUser,
//                 itemId
//               );
//             }
//           } else {
//             if (savedState) {
//               this.handleUnSaveResource(
//                 value.user,
//                 value.userSavedItems,
//                 value.updateSavedItemsList,
//                 item.id
//               );
//             } else {
//               this.handleSaveResource(
//                 value.user,
//                 value.userSavedItems,
//                 value.updateUser,
//                 value.updateSavedItemsList,
//                 item.id
//               );
//             }
//           }
//         }}
//       >
//         {this.state.saveItemLoading.includes(item.id)
//           ? SVG.spinner
//           : savedState
//           ? SVG.checkmark
//           : SVG.plusAlt}
//       </span>
//     );
//   }}
// </REACT_USER_CONTEXT.Consumer>

import {
  CheckAccountExistsAction,
  CheckAccountExistsPayload,
  ConfirmResetPasswordAction,
  ConfirmResetPasswordPayload,
  ResendVerificationCodeAction,
  ResendVerificationCodePayload,
  ResetPasswordAction,
  ResetPasswordPayload,
  SetAuthStateAction,
  SetAuthStatePayload,
  SignInWithEmailAndPasswordAction,
  SignInWithEmailAndPasswordPayload,
  SignUpWithEmailAndPasswordAction,
  SignUpWithEmailAndPasswordPayload,
  VerifyAccountAction,
  VerifyAccountPayload,
} from "./types";
import {
  CHECK_ACCOUNT_EXISTS,
  CONFIRM_RESET_PASSWORD,
  RESEND_ATTRIBUTES_VERIFICATION_CODE,
  RESEND_VERIFICATION_CODE,
  RESET_PASSWORD,
  SET_AUTH_STATE,
  SIGNIN_VERIFY_ACCOUNT,
  SIGNIN_WITH_EMAIL_AND_PASSWORD,
  SIGNUP_VERIFY_ACCOUNT,
  SIGNUP_WITH_EMAIL_AND_PASSWORD,
} from "./constants";

export const setAuthState = (payload: SetAuthStatePayload): SetAuthStateAction => ({
  type: SET_AUTH_STATE,
  payload,
});

export const signInWithEmailAndPassword = (
  payload: SignInWithEmailAndPasswordPayload,
): SignInWithEmailAndPasswordAction => ({
  type: SIGNIN_WITH_EMAIL_AND_PASSWORD,
  payload,
});

export const signUpWithEmailAndPassword = (
  payload: SignUpWithEmailAndPasswordPayload,
): SignUpWithEmailAndPasswordAction => ({
  type: SIGNUP_WITH_EMAIL_AND_PASSWORD,
  payload,
});

export const resetPassword = (payload: ResetPasswordPayload): ResetPasswordAction => ({
  type: RESET_PASSWORD,
  payload,
});

export const signinVerifyAccount = (payload: VerifyAccountPayload): VerifyAccountAction => ({
  type: SIGNIN_VERIFY_ACCOUNT,
  payload,
});
export const signupVerifyAccount = (payload: VerifyAccountPayload): VerifyAccountAction => ({
  type: SIGNUP_VERIFY_ACCOUNT,
  payload,
});

export const confirmResetPassword = (payload: ConfirmResetPasswordPayload): ConfirmResetPasswordAction => ({
  type: CONFIRM_RESET_PASSWORD,
  payload,
});

export const resendAccountVerificationCode = (
  payload: ResendVerificationCodePayload,
): ResendVerificationCodeAction => ({
  type: RESEND_VERIFICATION_CODE,
  payload,
});

export const resendUserAttributesVerificationCode = (
  payload: ResendVerificationCodePayload,
): ResendVerificationCodeAction => ({
  type: RESEND_ATTRIBUTES_VERIFICATION_CODE,
  payload,
});

export const checkAccountExists = (payload: CheckAccountExistsPayload): CheckAccountExistsAction => ({
  type: CHECK_ACCOUNT_EXISTS,
  payload,
});


import * as constants from "./constants";
import { SetLocalStoragePayload, SetLocalStorageAction, GetLocalStoragePayload, GetLocalStorageAction } from "./types";

export const setItem = (
  payload: SetLocalStoragePayload
): SetLocalStorageAction => ({
  type: constants.SET_LOCAL_STORAGE,
  payload,
});

export const getItem = (
  payload: GetLocalStoragePayload
): GetLocalStorageAction => ({
  type: constants.GET_LOCAL_STORAGE,
  payload,
});

/**
 * @author W. Alex Livesley
 * @copyright Copyright 2018 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2018-02-04
 * @description Implementation of the Iso8601 class.
 * @filename iso-8601.ts
 */

/**
 * A collection of utility functions concerning type determination.
 */
export class Iso8601 {
  /**
   * A regular expression used to validate an ISO-8601 conformant date.
   *
   * @constant
   * @static
   * @type {RegExp}
   */
  public static readonly REGEX_ISO_8601_DATE: RegExp = /^(\d{4})\D?(0[1-9]|1[0-2])\D?([12]\d|0[1-9]|3[01])$/i;

  /**
   * A regular expression used to validate an ISO-8601 conformant date time.
   *
   * @constant
   * @static
   * @type {RegExp}
   */
  public static readonly REGEX_ISO_8601_DATE_TIME: RegExp = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)$/i;

  /**
   * A regular expression used to validate an ISO-8601 conformant period/duration.
   *
   * @constant
   * @static
   * @type {RegExp}
   */
  public static readonly REGEX_ISO_8601_PERIOD: RegExp = /^([+-]?)P(?:(\d*)Y)?(?:(\d*)M)?(?:(\d*)D)?T?(?:(\d*)H)?(?:(\d*)M)?(?:([\d.]*)S)?$/i;

  /**
   * A regular expression used to validate an ISO-8601 conformant time.
   *
   * @constant
   * @static
   * @type {RegExp}
   */
  public static readonly REGEX_ISO_8601_TIME: RegExp = /^([01]\d|2[0-3])\D?([0-5]\d)\D?([0-5]\d)?\D?(\d{3})?$/i;

  /**
   * Returns a period in milliseconds as an ISO 8601 formatted string.
   *
   * @static
   * @param {number} milliseconds A number of milliseconds.
   *
   * @return {string} A string as an ISO 8601 formatted period.
   */
  public static getMillisecondsAsIso8601Period(
    milliseconds: number = 0
  ): string {
    const MS: number = Math.abs(milliseconds);
    const YEARS: number = Math.floor(MS / 31556952000);
    const MONTHS: number = Math.floor((MS - YEARS * 31556952000) / 2592000000);
    const DAYS: number = Math.floor(
      (MS - YEARS * 31556952000 - MONTHS * 2592000000) / 86400000
    );
    const HOURS: number = Math.floor(
      (MS - YEARS * 31556952000 - MONTHS * 2592000000 - DAYS * 86400000) /
        3600000
    );
    const MINUTES: number = Math.floor(
      (MS -
        YEARS * 31556952000 -
        MONTHS * 2592000000 -
        DAYS * 86400000 -
        HOURS * 3600000) /
        60000
    );
    const SECONDS: number =
      (MS -
        YEARS * 31556952000 -
        MONTHS * 2592000000 -
        DAYS * 86400000 -
        HOURS * 3600000 -
        MINUTES * 60000) /
      1000;

    return `${milliseconds < 0 ? "-" : ""}P${YEARS === 0 ? "" : YEARS + "Y"}${
      MONTHS === 0 ? "" : MONTHS + "M"
    }${milliseconds === 0 ? "0D" : ""}${DAYS === 0 ? "" : DAYS + "D"}${
      HOURS > 0 || MINUTES > 0 || SECONDS > 0 ? "T" : ""
    }${HOURS === 0 ? "" : HOURS + "H"}${MINUTES === 0 ? "" : MINUTES + "M"}${
      SECONDS === 0 ? "" : SECONDS + "S"
    }`;
  }

  /**
   * Returns the number of milliseconds in an ISO 8601 formatted string.
   *
   * @static
   * @param {string} period An ISO 8601 formatted string.
   *
   * @return {number} The number of milliseconds in an ISO 8601 formatted string.
   */
  public static getIso8601PeriodAsMilliseconds(period: string): number {
    const PRD: RegExpExecArray | null = Iso8601.REGEX_ISO_8601_PERIOD.exec(
      period
    );

    let noOfMilliseconds = -1;

    if (PRD !== null) {
      noOfMilliseconds =
        (PRD[1] === "-" ? -1 : 1) *
        ((PRD[2] === undefined ? 0 : 31556952000 * parseInt(PRD[2])) +
          (PRD[3] === undefined ? 0 : parseInt(PRD[3]) * 2592000000) +
          (PRD[4] === undefined ? 0 : parseInt(PRD[4]) * 86400000) +
          (PRD[5] === undefined ? 0 : parseInt(PRD[5]) * 3600000) +
          (PRD[6] === undefined ? 0 : parseInt(PRD[6]) * 60000) +
          (PRD[7] === undefined ? 0 : parseFloat(PRD[7]) * 1000));
    }
    return noOfMilliseconds;
  }

  /**
   * Returns a flag which is true if a given value is a valid serialized ISO-8601 date with optional range
   * limits.
   *
   * @static
   * @param {string} value The value which may contain a serialized ISO-8601 date.
   *
   * @param {{from:string, fromInclusive:boolean, to:string, toInclusive:boolean}} range The optional range.
   *
   * @return {boolean} A flag which is true if a given value is a valid serialized ISO-8601 date within the
   * range limits, if specified.
   */
  public static isDate(
    value: string,
    range: {
      from?: string;
      fromInclusive?: boolean;
      to?: string;
      toInclusive?: boolean;
    } | null = null
  ): boolean {
    let from: Date;
    let isValid: boolean;
    let to: Date;
    let valueDate: Date;

    isValid = Iso8601.REGEX_ISO_8601_DATE.test(value);
    if (isValid === true && range !== undefined && range !== null) {
      valueDate = new Date(value);
      if (range.from !== undefined && range.from !== null) {
        from = new Date(range.from);
        isValid =
          (range.fromInclusive === true && valueDate > from) ||
          (range.fromInclusive !== true && valueDate >= from);
      }
      if (isValid === true && range.to !== undefined && range.to !== null) {
        to = new Date(range.to);
        isValid =
          (range.toInclusive === true && valueDate < to) ||
          (range.toInclusive !== true && valueDate <= to);
      }
    }
    return isValid === undefined ? false : isValid;
  }

  /**
   * Returns a flag which is true if a given value is a valid serialized ISO-8601 date-time with optional range
   * limits.
   *
   * @static
   * @param {string} value The value which may contain a serialized ISO-8601 date-time.
   *
   * @param {{from:string, fromInclusive:boolean, to:string, toInclusive:boolean}} range The optional range.
   *
   * @return {boolean} A flag which is true if a given value is a valid serialized ISO-8601 date-time within
   * the range limits, if specified.
   */
  public static isDateTime(
    value: string,
    range: {
      from?: string;
      fromInclusive?: boolean;
      to?: string;
      toInclusive?: boolean;
    } | null = null
  ): boolean {
    let from: Date;
    let isValid: boolean;
    let to: Date;
    let valueDate: Date;

    isValid = Iso8601.REGEX_ISO_8601_DATE_TIME.test(value);
    if (isValid === true && range !== null) {
      valueDate = new Date(value);
      if (range.from !== undefined && range.from !== null) {
        from = new Date(range.from);
        isValid =
          (range.fromInclusive === true && valueDate > from) ||
          (range.fromInclusive !== true && valueDate >= from);
      }
      if (isValid === true && range.to !== undefined && range.to !== null) {
        to = new Date(range.to);
        isValid =
          (range.toInclusive === true && valueDate < to) ||
          (range.toInclusive !== true && valueDate <= to);
      }
    }
    return isValid === undefined ? false : isValid;
  }

  /**
   * Returns a flag which is true if a given value is a valid serialized ISO-8601 time with optional range
   * limits.
   *
   * @static
   * @param {string} value The value which may contain a serialized ISO-8601 time.
   *
   * @param {{from:string, fromInclusive:boolean, to:string, toInclusive:boolean}} range The optional range.
   *
   * @return {boolean} A flag which is true if a given value is a valid serialized ISO-8601 date-time within
   * the range limits, if specified.
   */
  public static isTime(
    value: string,
    range: {
      from?: string;
      fromInclusive?: boolean;
      to?: string;
      toInclusive?: boolean;
    } | null = null
  ): boolean {
    let from: Date;
    let isValid: boolean;
    let to: Date;
    let valueDate: Date;

    isValid = Iso8601.REGEX_ISO_8601_TIME.test(value);
    if (isValid === true && range !== null) {
      valueDate = new Date(value);
      if (range.from !== undefined && range.from !== null) {
        from = new Date(range.from);
        isValid =
          (range.fromInclusive === true && valueDate > from) ||
          (range.fromInclusive !== true && valueDate >= from);
      }
      if (isValid === true && range.to !== undefined && range.to !== null) {
        to = new Date(range.to);
        isValid =
          (range.toInclusive === true && valueDate < to) ||
          (range.toInclusive !== true && valueDate <= to);
      }
    }
    return isValid === undefined ? false : isValid;
  }
}

/**
 * @author Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @description Implementation of the Component react component and declaration of its related interfaces.
 * @Date: 2018-03-19 02:23:06
 * @filename dynamic-component.tsx
 */
import { Component as GraphQlComponent } from "@radivision/graphql/lib/ts/graphql/component";
import { ComponentPropertyValue } from "@radivision/graphql/lib/ts/graphql/component-property-value";
import React from "react";
import { StructureUtilities } from "../../utilities/structure-utilities";
import { MAIN_COMPONENTS } from "../components-map";
import { ENVIRONMENT } from "../../relay/relay-environment";

export interface DynamicComponentProps {
  component: GraphQlComponent;
  properties?: ComponentPropertyValue[];
}

interface DynamicComponentState {
  componentsMap: {
    [index: string]: any;
  };
  shouldLoad: boolean;
  isLoaded: boolean;
}

class DynamicComponent extends React.Component<DynamicComponentProps, DynamicComponentState> {
  constructor(props: DynamicComponentProps) {
    super(props);

    this.state = {
      componentsMap: MAIN_COMPONENTS,
      shouldLoad: false,
      isLoaded: false,
    };
  }

  componentDidUpdate() {
    if (this.state.shouldLoad && !this.state.isLoaded) {
      import("../components").then((math) => {
        this.setState({
          shouldLoad: false,
          isLoaded: true,
          componentsMap: {
            ...this.state.componentsMap,
            ...math.COMPONENTS,
          },
        });
      });
    }
  }

  render(): React.ReactElement<any> {
    if (this.state.componentsMap[this.props.component.key]) {
      const CONTROLTAGNAME = this.state.componentsMap[this.props.component.key].component;
      return (
        <CONTROLTAGNAME
          {...StructureUtilities.getComponentUtilities(this.props.component.properties, this.props.properties)}
          {...this.state.componentsMap[this.props.component.key].props}
          relay={{ environment: ENVIRONMENT }}
        />
      );
    } else {
      if (!this.state.shouldLoad && !this.state.isLoaded) this.setState({ shouldLoad: true });
      return <div />;
    }
  }
}

export default DynamicComponent;

/**
 * @author: Adham El Banhawy
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2018-08-16
 * @filename: twitter-request-token-query.ts
 * @description: Implementation of GraphQL query to retrieve a Twitter request token
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL query to retrieve the twitter request token.
 *
 * @type {GraphQLTaggedNode}
 */
export const TWITTER_REQUEST_TOKEN_GRAPHQL_QUERY: GraphQLTaggedNode = graphql`
  query twitterRequestTokenQuery {
    twitter {
      hasErrors
      errors {
        id
        location
      }
      twitter {
        oauthToken
        oauthTokenSecret
        oauthCallbackConfirmed
      }
    }
  }
`;

import Loadable from "react-loadable";
import { Entity } from "@radivision/graphql/lib/ts/graphql/entity";
import OptionsWithoutRender = LoadableExport.OptionsWithoutRender;

interface ComponentProps {
  offer: any;
  entity: Entity;
}

type Props = ComponentProps;

const loadableOptions: OptionsWithoutRender<Props> = {
  loader: () => import("../../../../modules/manage-marketplace").then((module) => module.OfferMoreMenu) as Promise<any>,
  loading: () => null,
};

export const OfferMoreMenu = Loadable(loadableOptions);

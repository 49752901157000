/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2019-02-05
 * @filename: image.ts
 * @description: Declaration of optimized image interface
 */


 /**
 * The __typeID of the GraphQL image object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_INSTAGRAM_IMAGE: string = "Image";

/**
 * The details of the optimized image
 *
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * The URL of the optimized image
     *
     * @type {string}
     * @memberof Image
     */
    url: string;
  }
  
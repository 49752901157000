import { DetailsPanelReducer } from "./reducer";
import { DetailsPanelModuleState } from "./types";
import sagas from "./saga";

export { DetailsPanelModuleState };

export const detailsPanelModule = {
  id: "detailsPanel",
  reducerMap: {
    detailsPanel: DetailsPanelReducer,
  },
  sagas: [sagas],
};

import * as React from "react";
import { connect } from "react-redux";
import {
  DetailsPanelState,
  CloseDetailsPanelAction,
  OpenDetailsPanelPayload,
  OpenDetailsPanelAction,
  SetDetailsPanelAction,
} from "../../redux/details-panel/types";
import {
  StartVideoPlayerAction,
  StopVideoPlayerAction,
  PipPlayerPayload,
  PipPlayerState,
} from "../../redux/youtube-player/types";
import { AppState } from "../../redux";
import * as Actions from "../../redux/actions";
import isEmpty from "lodash.isempty";
import {
  FetchListPayload,
  FetchListAction,
  FetchStoryItemPayload,
  FetchStoryItemAction,
} from "../../redux/cache/types";
import { SVG } from "../../component-configuration/svgs";
import { ItemVideo } from "./item-video";
import { ItemInfo } from "./item-info";
import { Episodes } from "./episodes";
import { PitchVideo } from "./pitch-video";
import { MoreLikeThis } from "./more-like-this";
import { GRAPHQL_TYPE_PITCH_VIDEO_STORY, GRAPHQL_TYPE_POSTER } from "@radivision/graphql";
import { pitchPlanetListId } from "../../constants/content-ids";
import { useDispatch } from "react-redux";
import { closeDetailsPanel, startVideoPlayer, stopVideoPlayer } from "../../redux/actions";

interface MapDispatchProps {
  startVideoPlayer: (payload: PipPlayerPayload) => StartVideoPlayerAction;
  stopVideoPlayer: () => StopVideoPlayerAction;
  closeDetailsPanel: () => CloseDetailsPanelAction;
  fetchList: (payload: FetchListPayload) => FetchListAction;
  fetchStoryItem: (payload: FetchStoryItemPayload) => FetchStoryItemAction;
  setDetailsPanel: (payload: OpenDetailsPanelPayload) => SetDetailsPanelAction;
}

interface MapStateProps {
  isFetchingItem: boolean;
  isFetchingList: boolean;
  scrollAnchorId: string | null;
  moreLikeThis: string | null;
  currentHash: string | null;
  storyItem: any;
  item: any;
  list: any;
}
type Props = DetailsPanelState & PipPlayerState & MapDispatchProps & MapStateProps;

export const DetailsPanelContainer = (props: Props) => {
  const {
    isFetchingItem,
    isFetchingList,
    list,
    item,
    storyItem,
    fetchList,
    fetchStoryItem,
    closeDetailsPanel,
    setDetailsPanel,
    startVideoPlayer,
    stopVideoPlayer,
    currentHash,
  } = props;

  const dispatch = useDispatch();
  const [startMuted, setStartMuted] = React.useState(false);
  const [_title, itemId, listId] = currentHash ? String(currentHash).split("/") : [];
  const isOriginalShow = itemId === listId;
  const isPosterItem = item?.__typename === GRAPHQL_TYPE_POSTER;

  React.useEffect(() => {
    if (isEmpty(currentHash)) return;

    const isPitchPlanet = listId === pitchPlanetListId;
    const listIsNotFetchedYet = !isFetchingList && isEmpty(list?.items) && !isEmpty(listId);
    if (listIsNotFetchedYet) {
      const first = isPitchPlanet ? 12 : undefined;
      const variables = { listId, first };
      fetchList({ variables });
    }

    const itemIsNotFetchedYet = itemId && !isFetchingItem && isEmpty(storyItem);

    if (itemIsNotFetchedYet) {
      if (!isOriginalShow && !isPosterItem) {
        fetchStoryItem({ id: itemId });
      }
    }

    if (!isOriginalShow && storyItem) {
      setDetailsPanel({ item: storyItem });
    }

    const openDetailsPanelByHash = isEmpty(item) && itemId;
    if (openDetailsPanelByHash) {
      setStartMuted(true);
    }
  }, [
    itemId,
    listId,
    setStartMuted,
    list,
    item,
    storyItem,
    currentHash,
    fetchStoryItem,
    fetchList,
    setDetailsPanel,
    isFetchingList,
    isFetchingItem,
    isOriginalShow,
    isPosterItem,
  ]);

  const canShowOriginalList = isOriginalShow && !isEmpty(list);
  const canShowStoryItem = !isEmpty(item);
  if (!(canShowStoryItem || canShowOriginalList)) {
    return null;
  }

  const isPitchVideo = item?.__typename === GRAPHQL_TYPE_PITCH_VIDEO_STORY;

  function onClosePip() {
    closeDetailsPanel();
    if (item?.externalId || item?.trailer?.externalId || item?.originalClip?.externalId) {
      dispatch(
        startVideoPlayer(
          item?.externalId
            ? item?.externalId
            : item?.trailer?.externalId
            ? item?.trailer?.externalId
            : item?.originalClip?.externalId,
        ),
      );
    }
  }

  return (
    <Container onClose={onClosePip} closeDetailsPanel={closeDetailsPanel}>
      {isPitchVideo ? (
        <PitchVideo />
      ) : (
        <>
          <ItemVideo startMuted={startMuted} />
          <ItemInfo />
          <Episodes />
        </>
      )}
      <MoreLikeThis />
    </Container>
  );
};

const Container = ({
  onClose,
  children,
  closeDetailsPanel,
}: React.PropsWithChildren<{ onClose: () => void; closeDetailsPanel }>) => {
  return (
    <div className="visible-panel details-panel">
      <div className="details-panel-backdrop" onClick={onClose} />
      <div className="details-panel-wrapper">
        <i onClick={closeDetailsPanel}>{SVG.crosshairsThin}</i>
        <div className="details-panel-container">{children}</div>
      </div>
    </div>
  );
};

const mapState = (state: AppState) => {
  const { item: _item, scrollAnchorId, moreLikeThis } = state.detailsPanel;
  const { currentHash } = state.app;
  const [_title, itemId, listId] = currentHash ? String(currentHash).split("/") : [];
  const list = listId ? state.cache.store[listId] : null;
  const storyItem = itemId ? state.cache.store[itemId] : null;
  const isFetchingItem = itemId ? state.cache.inProgress[itemId] : false;
  const isFetchingList = listId ? state.cache.inProgress[listId] : false;
  const isOriginalShow = itemId === listId;
  const getItemFromList = !isOriginalShow && list && !_item && itemId;
  const item = getItemFromList ? list?.items?.edges?.find((edge) => edge?.listItem?.item?.id === itemId) : _item;
  return {
    isFetchingList,
    isFetchingItem,
    currentHash,
    storyItem,
    list: list?.list?.list,
    item,
    scrollAnchorId,
    moreLikeThis,
  };
};
const mapDispatch = {
  closeDetailsPanel: Actions.closeDetailsPanel,
  fetchList: Actions.fetchList,
  fetchStoryItem: Actions.fetchStoryItem,
  setDetailsPanel: Actions.setDetailsPanel,
  startVideoPlayer: startVideoPlayer,
  stopVideoPlayer: stopVideoPlayer,
};

export default connect(mapState, mapDispatch)(DetailsPanelContainer);

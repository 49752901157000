import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import { PitchCard } from "./pitch-card";
import LazyLoad from "react-lazyload";
import { PitchPlanetFilters } from "./filters";
import { structureQuerySearch } from "../../../utilities/search";
import { getCleanUuid } from "../../../utilities/general";
import { Loader } from "../../page/loader";
import debounce from "lodash.debounce";
import { SearchFacet } from "@radivision/graphql/lib/ts/graphql/search-hit";
import { Router } from "../../../utilities/router";
import { OpenDetailsPanelPayload, OpenDetailsPanelAction } from "../../../redux/details-panel/types";
import { randomize } from "../../../utilities/randomize";
import { PseudoRandom } from "../../../utilities/pseudo-random";
import isEmpty from "lodash.isempty";
import { getPTDate } from "../../../utilities/get-pt-date";
import { SVG } from "../../../component-configuration/svgs";

interface ComponentProps {
  list: FileList;
}

interface MapDispatchProps {
  openDetailsPanel: (payload: OpenDetailsPanelPayload) => OpenDetailsPanelAction;
}
interface MapStateProps {
  filters: { [s: string]: string[] };
}

interface State {
  pitchVideoItems: any;
  isLoading?: boolean;
  facets?: SearchFacet[];
  page: number;
}
export type Props = ComponentProps & MapDispatchProps & MapStateProps;

const PAGE_SIZE = 10;
class Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      pitchVideoItems: undefined,
      isLoading: true,
      page: 1,
    };
    this.loadPitchItemsCore = this.loadPitchItemsCore.bind(this);
  }

  componentDidMount() {
    this.loadPitchItemsCore().then(() => {
      const ACTIVE_SLIDE_ID = Router.getParameterByName("ctx");
      if (ACTIVE_SLIDE_ID) {
        setTimeout(() => {
          const episode = this.state.pitchVideoItems?.findIndex((i) => i.id === ACTIVE_SLIDE_ID);
          if (episode >= 0) {
            const LIST = {
              id: getCleanUuid(),
              items: {
                edges: this.state.pitchVideoItems.map((item) => {
                  return {
                    listItem: {
                      item: { ...item },
                    },
                  };
                }),
              },
            };
            const PAYLOAD = { show: LIST, episode, type: "PitchGrid" };

            this.props.openDetailsPanel(PAYLOAD);
          }
        }, 350);
      }
    });
  }

  loadPitchItemsCore(filters?: string, pagination?: string): Promise<any> {
    const QUERY = "(and (phrase field='kind' 'PITCH_VIDEO'))";
    return structureQuerySearch(QUERY, filters, undefined, pagination).then((pitchPlanetList) => {
      const day = parseInt(Router.getParameterByName("day"));
      const currentDay = getPTDate().getDate();
      const seed = day || currentDay;
      const generator = PseudoRandom((seed + 1) * 12356);
      const items = randomize(pitchPlanetList.items, () => generator());

      const STATE: State = this.state;
      STATE.pitchVideoItems = items;
      STATE.page = 1;
      STATE.isLoading = false;
      if (!filters) {
        STATE.facets = pitchPlanetList.facets;
      }
      this.setState(STATE);
      return Promise.resolve();
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps?.filters !== this.props?.filters) {
      const loadPitchItems = debounce(
        (filters?: string, pagination?: string) => this.loadPitchItemsCore(filters, pagination),
        300,
      );
      const KEYS = Object.keys(this.props.filters);
      let filterQuery: string = KEYS && KEYS.length ? "(and " : undefined;
      KEYS.forEach((key: string) => {
        const FILTER_ITEMS = this.props.filters[key];
        if (FILTER_ITEMS && FILTER_ITEMS.length) {
          filterQuery = `${filterQuery} (or ${this.props.filters[key]
            .map((value) => {
              return ` ${key}: '${value}'`;
            })
            .join("")})`;
        }
      });

      this.setState({ isLoading: true });
      loadPitchItems(filterQuery && filterQuery !== "(and " ? `${filterQuery})` : undefined);
    }
  }

  render() {
    const items: any[] = this.state.pitchVideoItems;
    const ERROR_MESSAGE = "No results found matching selected criteria.";
    return (
      <LazyLoad once={true}>
        <div id={`pitch-grid-${getCleanUuid()}`} className="pitch-grid-container">
          <PitchPlanetFilters facets={this.state.facets} />
          {this.state.isLoading && (
            <div className="pitch-grid-loading-container">
              <Loader isActive={this.state.isLoading} />
            </div>
          )}
          {isEmpty(items) && !this.state.isLoading ? (
            <h4 className="pitch-no-results"> {ERROR_MESSAGE} </h4>
          ) : (
            <h4 className="panel-title">Featured Startups</h4>
          )}
          <Container isLoading={this.state.isLoading}>
            {items && items.length
              ? items.map((item) => item && <PitchCard key={`pitch-card-${item?.id}`} list={items} item={item} />)
              : null}
          </Container>
        </div>
      </LazyLoad>
    );
  }
}

const Container = ({ children, isLoading }) => {
  return (
    <div className="pitch-grid" style={{ opacity: isLoading ? 0.3 : 1 }}>
      {children}
    </div>
  );
};

const mapState = (state: AppState) => ({
  user: state.account.user,
  filters: state.account.pitchPlanetFilter,
});

const mapDispatch = {
  createPostSet: Actions.createPostSet,
  openDetailsPanel: Actions.openDetailsPanel,
};

export const PitchGrid = connect(mapState, mapDispatch)(Component);

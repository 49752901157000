/**
 * @Author: Adham El Banhawy
 * @Copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-08-01
 * @Filename: twitter-authenticator.ts
 * @Description: Twitter authentication methods
 */

import { fetchQuery } from "react-relay";
import { TWITTER_REQUEST_TOKEN_GRAPHQL_QUERY } from "../relay/queries/twitter-request-token-query";
import { ENVIRONMENT } from "../relay/relay-environment";

/**
 *
 *
 * @export
 * @class TwitterAuthenticator
 */
export class TwitterAuthenticator {
  /**
   * A function that redirects user to Twitter login page using request oauth token fetched
   * from back-end
   *
   * @returns {Window} a window redirect to Twitter authorization page.
   */
  static authenticateTwitterUser(): Window | void {
    // fetch twitter user oauth token from app sync
    let promise: Promise<any> = fetchQuery(
      ENVIRONMENT,
      TWITTER_REQUEST_TOKEN_GRAPHQL_QUERY,
      {}
    );
    // attempt to redirect user to twitter to sign in.
    promise = promise.then((response: any) => {
      if (!response.twitter.hasErrors) {
        const url =
          `https://api.twitter.com/oauth/authenticate?` +
          `oauth_token=${response.twitter.twitter.oauthToken}&force_login=false`;
        // A popup window to hold Twitter's authorization page
        return TwitterAuthenticator.openPopup(url, "_self");
      }
    });
  }
 
  /**
   * A function that opens up a popup authentication window
   *
   * @returns {Window} A new window that holds an authentication page
   */
  static openPopup(url: string, target: string): Window {
    return window.open(url, target);
  }
}

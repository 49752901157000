import * as constants from "./constants";
import { OpenDetailsPanelAction, OpenDetailsPanelPayload, CloseDetailsPanelAction } from "./types";

export const setDetailsPanel = (payload: OpenDetailsPanelPayload): OpenDetailsPanelAction => ({
  payload,
  type: constants.SET_DETAILS_PANEL,
});

export const openDetailsPanel = (payload: OpenDetailsPanelPayload): OpenDetailsPanelAction => ({
  payload,
  type: constants.OPEN_DETAILS_PANEL,
});

export const closeDetailsPanel = (): CloseDetailsPanelAction => ({
  type: constants.CLOSE_DETAILS_PANEL,
});

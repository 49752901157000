/**
 * @author Ahmed Samer
 * @date   2020-01-06
 * @description Declaration of the PreviewType interface and PreviewKind enumeration.
 * @filename preview-type.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the Preview Type type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_UGC_META_TYPE: string = "UGCMetaKind";

/**
 * The properties of a use case for a preview for a person, entity, story list or list collection.
 *
 * @enum
 */
export enum UGCMetaDataKind {
  /**
   *
   */
  FULL_NAME = "full_name",
  /**
   *
   */
  COMPANY_NAME = "company_name",
  /**
   *
   */
  RESPONSIBILITY = "title",
  /**
   *
   */
  url = "company_url",
  /**
   *
   */
  TERM_AGREEMENT = "term_agreement"
}

/**
 * The properties of a use case for a preview for a person, entity, story list or list collection.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface UGCMetaDataType<D extends Date | string = string>
  extends Node<D> {
  /**
   * A description of the preview type.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The kind of the preview type.
   *
   * @type {PreviewKind}
   */
  kind?: UGCMetaDataKind;

  /**
   * A label of the preview type.
   *
   * @type {string}
   */
  label?: string;
}

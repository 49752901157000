import * as React from "react";
import { ProfilePictureName } from "../../../react-components/page-framework/profile-picture-name";
import { connect } from "react-redux";
import isEmpty from "lodash.isempty";
import { Entity } from "@radivision/graphql/lib/ts/graphql/entity";
import { Checkbox } from "../../../react-components/page-framework/checkbox";
import { EVENT_INVESTMENT_AMOUNT } from "../redux/events/constants";
import { SetEventStatePayload, SetEventStateAction, EventsModuleState } from "../redux/events/types";
import { setEventState } from "../redux/events/actions";
import { AppState } from "../../../redux";
import { RdvButton } from "../../../react-components/page-framework/rdv-button";
import { ConnectInvestorButton } from "./connect-investor-button";
import { getProfileInfo } from "../../../utilities/get-profile-info";
import { Router } from "../../../utilities/router";
import { InvestButton } from "./invest-button";

interface ComponentProps {
  entity: Entity;
}
interface MapDispatchProps {
  setEventState: (payload: SetEventStatePayload) => SetEventStateAction;
}

interface MapStateProps {
  investAmount: string;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const CompanyInfoComponent = (props: Props) => {
  const { entity } = props;

  const modalId = "company-intro";
  const showFounder =
    entity?.people &&
    entity?.people?.edges &&
    entity?.people?.edges[0] &&
    !isEmpty(entity?.people?.edges[0].person?.fullName?.trim());

  const { profileUrl } = getProfileInfo(entity);
  const goto = React.useCallback(
    (e) => {
      e.preventDefault();
      if (profileUrl) Router.navigateTo(profileUrl);
    },
    [profileUrl],
  );

  const investmentsBy = Array.isArray(entity?.investmentsBy) && entity?.investmentsBy[0];
  const link = investmentsBy?.link;
  return (
    <div className="event-company-info">
      <div className="section title">
        <sup>Presenting</sup>
        <h2>
          <a href={profileUrl} onClick={goto}>
            {entity?.name}
          </a>
        </h2>
        <div className="actions">
          <ConnectInvestorButton
            entity={entity}
            link={link}
            buttonText={"Connect"}
            message={"We sent your contact information to the pitching company to arrange an introduction"}
          />
          <InvestButton entity={entity} event={event} />
        </div>
      </div>
      <div className="section founders">
        {showFounder && <h4>Founders</h4>}
        {showFounder
          ? entity?.people?.edges?.map((entityLeader: { role?: string; person: any }) => {
              if (isEmpty(entityLeader?.person?.fullName)) return null;
              return (
                <ProfilePictureName
                  subject={entityLeader?.person}
                  key={`person-${entityLeader?.person?.id}`}
                  subtitle={entityLeader.person?.responsibility}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

const mapDispatch = {
  setEventState: setEventState,
};

const mapState = (state: AppState & EventsModuleState) => ({
  investAmount: state.events.investAmount,
});

export const CompanyInfo = connect(mapState, mapDispatch)(CompanyInfoComponent);

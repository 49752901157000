import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { CheckExistingCompanyUrlAction, CheckExistingCompanyUrlPayload } from "../../../redux/create-company/types";
import { FormFieldContainer, FormFieldInput } from "../../../react-components/modals/modal-form-elements";
import { SVG } from "../../../component-configuration/svgs";
import { LabelToolTip } from "../../../react-components/page-framework/label-tool-tip";
import { CheckExistingWebsiteStatus } from "./existing-url-status";
import { ProfileModuleState } from "../redux/profile";
import { setProfileState, checkExistingCompanyUrl } from "../redux/profile/actions";
import { SetProfileStateAction, SetProfileStatePayload } from "../redux/profile/types";

interface ComponentProps {
  isRequired?: boolean;
}

interface MapStateProps {
  isChecking: boolean;
  name: string;
  websiteUrl: string;
}

interface MapDispatchProps {
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
  checkExistingCompanyUrl: (payload: CheckExistingCompanyUrlPayload) => CheckExistingCompanyUrlAction;
}

export type Props = MapDispatchProps & MapStateProps & ComponentProps;

export const WebsiteUrlComponent = (props: Props) => {
  const { websiteUrl, setProfileState, isChecking, checkExistingCompanyUrl, isRequired } = props;

  const websitetooltipText = "This is a link to your external website";
  return (
    <FormFieldContainer>
      <label htmlFor="deckUrl">
        <span>Website: {isRequired ? "*" : ""}</span>
        <LabelToolTip text={websitetooltipText} />
      </label>
      <p className="field-help-text">{websitetooltipText}</p>
      <FormFieldInput withIcon={true}>
        {isChecking ? <span>{SVG.spinner}</span> : <span>{SVG.link}</span>}
        <input
          type="text"
          name="website"
          value={websiteUrl}
          onChange={(e) => setProfileState({ websiteUrl: e.target.value })}
          onBlur={() => isRequired && checkExistingCompanyUrl({ website: websiteUrl })}
        />
      </FormFieldInput>
      <CheckExistingWebsiteStatus />
    </FormFieldContainer>
  );
};

const mapState = (state: AppState & ProfileModuleState) => {
  const { websiteUrl, name } = state.profile;
  const key = `check-${websiteUrl}`;
  const isChecking = state.cache.inProgress[key];
  return {
    isChecking,
    name,
    websiteUrl,
  };
};

const mapDispatch = {
  setProfileState: setProfileState,
  checkExistingCompanyUrl: checkExistingCompanyUrl,
};

export const WebsiteUrl = connect(mapState, mapDispatch)(WebsiteUrlComponent);

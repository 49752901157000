/**
 * @Author: Nassar Sara
 * @Date:   2018-03-27T10:52:12+02:00
 * @Email:  sara@radivision.com
 * @Project: Radivision
 * @Filename: list.ts
 * @Last modified by:   Nassar Sara
 * @Last modified time: 2018-06-01T03:05:11+02:00
 * @Copyright: Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { ArticleStory } from "./article-story";
import { BookStory } from "./book-story";
import { Badge } from "./badge";
import { Connection } from "./connection";
import { DynamicList } from "./dynamic-list";
import { Entity } from "./entity";
import { FixedList } from "./fixed-list";
import { MediaAsset } from "./media-asset";
import { NewsletterStory } from "./newsletter-story";
import { Node } from "./node";
import { OnlineCourseStory } from "./online-course-story";
import { Person } from "./person";
import { PodcastStory } from "./podcast-story";
import { Preview } from "./preview";
import { Tag } from "./tag";
import { Questionnaire, Question } from "./questionnaire";
import { SearchHit } from "./search-hit";
import { PopulateKind } from "./queries/order-input";
import { PitchVideoStory } from "./pitch-video-story";

/**
 * The __typeID of the  List GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_LIST: string = "List";

export const GRAPHQL_TYPE_LIST_ITEM: string = "ListItem";

/**
 * An item in a list
 *
 * @export
 * @interface ListItem
 */
export interface ListItem {
  /**
   * The index of the item in the collection.
   *
   * @type {number}
   */
  index?: number;

  /**
   * The list item
   *
   * @type {ListItemType}
   * @memberof ListItem
   */
  item: ListItemType;

  /**
   * A list associated with the item
   *
   * @type {ListType}
   * @memberof ListItem
   */
  list?: ListType;

  /**
   * A question associated with the item.
   *
   * @type {string}
   * @memberof ListItem
   */
  question?: string;

  /**
   * the typeID of List Item in GraphQl
   *
   * @type {string}
   * @memberof ListItem
   */
  __typename?: string;
}

/**
 * The types of items which may be in a list.
 *
 * @template D
 * type {ListItemType<D>}
 */
export type ListItemType<D extends Date | string = string> =
  | ArticleStory<D>
  | SearchHit<D>
  | OnlineCourseStory<D>
  | BookStory<D>
  | MediaAsset<D>
  | NewsletterStory<D>
  | PodcastStory<D>
  | PitchVideoStory<D>
  | Entity<D>
  | Person<D>
  | Tag<D>;

/**
 * The types of lists.
 *
 * @template D, L
 */
export type ListType<
  D extends Date | string = string,
  L extends ListItemType<D> = ListItemType<D>
> = FixedList<D, L> | DynamicList<D, L>;

/**
 * The set of API object kind types
 *
 * @export
 * @enum {number}
 */
export enum ListItemKind {
  ARTICLE_STORY = "ARTICLE_STORY",
  BOOK_STORY = "BOOK_STORY",
  CLOUD_SEARCH_HIT = "CLOUD_SEARCH_HIT",
  ENTITY = "ENTITY",
  HTML_DOCUMENT_TEMPLATE = "HTML_DOCUMENT_TEMPLATE",
  LIST = "LIST",
  LIST_COLLECTION = "LIST_COLLECTION",
  MEDIA_ASSET = "MEDIA_ASSET",
  NEWSLETTER_STORY = "NEWSLETTER_STORY",
  NOTE = "NOTE",
  ONLINE_COURSE_STORY = "ONLINE_COURSE_STORY",
  ORIGINAL_CONTENT_STORY = "ORIGINAL_CONTENT_STORY",
  PITCH_VIDEO_STORY = "PITCH_VIDEO_STORY",
  PERSON = "PERSON",
  PODCAST = "PODCAST",
  TAG = "TAG"
}

/**
 * Information pertaining to a page of edges in a connection.
 *
 * @export
 * @extends {Node<D>}
 * @interface List
 * @template D, L
 */
export interface List<D extends Date | string = string> extends Node<D> {
  /**
   * The collection of badges associated with the list
   *
   * @type {Badge[]}
   * @memberof List
   */
  badges?: Badge[];

  /**
   * A description of the List. Metadata
   *
   * @type {string}
   */
  description?: string;

  /**
   * A page in the collection of organizations, people, and stories for the list.
   *
   * @template D, L
   * @return {Connection<D, L>} The connection between a list and that paginated set of story items which concern the
   * story
   */
  items?: Connection<D>;

  /**
   * The kinds of items in the list.
   *
   * @type {ListItemKind[]}
   * @memberof List
   */
  itemsTypes?: ListItemKind[];

  /**
   * A label for the list. Metadata.
   *
   * @type {string}
   */
  label?: string;

  /**
   * The name of the list. Metadata
   *
   * @type {string}
   */
  name?: string;

  /**
   * A collection of media assets used as previews for the list.
   *
   * @template D
   * @type {Connection<D, Preview>}
   * @memberof List
   */
  previews?: Connection<D, Preview>;

  /**
   * The date when the list was published
   *
   * @template D
   * @type {D}
   * @memberof DynamicList
   */
  publishedDate?: D;

  /**
   * The questionnaire associated with the list.
   *
   * @type {Questionnaire}
   * @memberof List
   */
  questionnaire?: Questionnaire;

  /**
   * The sub-title of the list.
   *
   * @type  {string}
   */
  subTitle?: string;

  /**
   * The title of the list.
   *
   * @type  {string}
   */
  title?: string;

  /**
   *
   *
   * @type {string}
   * @memberof List
   */
  landingPageUrl?: string;


   /**
   *
   *
   * @type {string}
   * @memberof List
   */
  moreLikeThis?: string;

  /**
   *
   *
   * @type {PopulateKind}
   * @memberof List
   */
  orderKind?: PopulateKind
}

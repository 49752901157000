/**
 * @author William Alexander Livesley
 * @date   2018-04-22
 * @description Declaration of the PodcastStory interface.
 * @filename podcast-story.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Story } from "./story";

/**
 * The __typeID of the Video Podcast Story type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_PODCAST_STORY: string = "PodcastStory";

/**
 * A story concerning a podcast.
 *
 * @extends {Story}
 * @template D
 * @interface
 */
export interface PodcastStory<D extends Date | string = string> extends Story<D> {

  /**
   * A non-normative description of the cost of the podcast
   *
   * @type {string}
   * @memberof PodcastStory
   */
  cost?: string;
  
  /**
   * The duration of the source video in seconds.
   *
   * @type {number}
   */
  durationInSeconds?: number;

  /**
   * The URL of the video-podcast.
   *
   * @type {string}
   */
  link?: string;
}

import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { Entity } from "@radivision/graphql";
import { Loader } from "../../../react-components/page/loader";
import { PitchVideoPanel } from "../../../react-components/inner-pages/profile-details-tab/investment/pitch-video-panel";
import { fetchStoryItem } from "../../../redux/actions";
import { FetchStoryItemPayload, FetchStoryItemAction } from "../../../redux/cache/types";
import {Opportunities} from "../../../react-components/inner-pages/profile-details-tab/investment/opportunities";

interface ComponentProps {
  entity: Entity & { pitchOriginalMedia: any };
}

interface MapDispatchProps {
  fetchStoryItem: (payload: FetchStoryItemPayload) => FetchStoryItemAction;
}

interface MapStateProps {
  item: any;
  isFetching: boolean;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { entity, fetchStoryItem, isFetching, item } = props;

  React.useEffect(() => {
    const id = entity?.pitchOriginalMedia?.id;
    if (id && !item && !isFetching) {
      fetchStoryItem({ id });
    }
  }, [entity, fetchStoryItem, isFetching, item]);
  return (
    <div className="investment">
      <PitchVideoPanel entity={entity} item={item} />
      <Opportunities entity={entity} />
    </div>
  );
};

const mapState = (state: AppState, props: ComponentProps) => ({
  item: state.cache.store[props.entity?.pitchOriginalMedia?.id],
  isFetching: state.cache.inProgress[props.entity?.pitchOriginalVideo?.id],
});

const mapDispatch = {
  fetchStoryItem: fetchStoryItem,
};

export const Investment = connect(mapState, mapDispatch)(Component);

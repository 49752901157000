import { Range } from "@radivision/graphql";

export function getNumberFromRange(range: Range): string {
  let num: string;

  if (range?.singleValue !== undefined && range?.singleValue !== null) {
    num = `${range.singleValue}`;
  } else {
    if (
      range.minimum !== null &&
      range.minimum !== undefined &&
      range.maximum !== null &&
      range.maximum !== undefined
    ) {
      num = `${range.minimum} - ${range.maximum}`;
    } else if (range.minimum !== null && range.minimum !== undefined) {
      num = range.isGreaterThan ? `${range.minimum}+` : `${range.minimum}`;
    } else if (range.maximum !== null && range.maximum !== undefined) {
      num = `< ${range.maximum}`;
    }
  }
  return num;
}

interface NumRange {
  __typename?: "Range";
  maximum?: number;
  minimum?: number;
  isGreaterThan?: boolean;
  isLessThan?: boolean;
  singleValue?: number;
}

export function getRangeFromNumber(number: string): NumRange {
  let range: NumRange = {
    minimum: undefined,
    maximum: undefined,
  };
  let safeNum: string[] = [];
  if (number !== null && number !== undefined && number !== "") {
    if (number.includes(">")) {
      safeNum = number.substr(number.indexOf(">") + 1).match(/(\d+)/);
      if (safeNum && safeNum.length) {
        range.minimum = parseInt(safeNum[0], 10);
        range.isGreaterThan = true;
      }
    } else if (number.includes("+")) {
      safeNum = number.replace("+", "").match(/(\d+)/);
      if (safeNum && safeNum.length) {
        range.minimum = parseInt(safeNum[0], 10);
        range.isGreaterThan = true;
      }
    } else if (number.includes("<")) {
      safeNum = number.substr(number.indexOf("<") + 1).match(/(\d+)/);
      if (safeNum && safeNum.length) {
        range.maximum = parseInt(safeNum[0], 10);
        range.isLessThan = true;
      }
    } else if (number.includes("~") || number.includes("-")) {
      let rangeArr: string[];
      if (number.includes("~")) {
        rangeArr = number.split("~");
      } else {
        rangeArr = number.split("-");
      }
      if (rangeArr.length && rangeArr.length >= 1) {
        safeNum = rangeArr[0].match(/(\d+)/);
        const safeNum2: string[] = rangeArr[1].match(/(\d+)/);
        if (safeNum && safeNum.length && safeNum2 && safeNum2.length) {
          const num1: number = parseInt(safeNum[0], 10);
          const num2: number = parseInt(safeNum2[0], 10);
          range.minimum = num1 > num2 ? num2 : num1;
          range.maximum = num1 > num2 ? num1 : num2;
        }
      }
    } else {
      safeNum = number.match(/(\d+)/);
      if (safeNum && safeNum.length) {
        range.minimum = parseInt(safeNum[0], 10);
      } else {
        range = undefined;
      }
    }
  }
  return range;
}

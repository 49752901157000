export const SET_LIST = "SET_LIST";
export const FETCH_QUERY = "FETCH_QUERY";
export const FETCH_HTML_PAGE = "FETCH_HTML_PAGE";
export const FETCH_IN_PROGRESS = "FETCH_IN_PROGRESS";
export const CACHE_DATA = "CACHE_DATA";
export const PREFETCH_HTML_PAGES = "PREFETCH_HTML_PAGES";
export const FETCH_LIST = "FETCH_LIST";
export const FETCH_STORY_ITEM = "FETCH_STORY_ITEM";
export const FETCH_ORIGINAL_SHOWS = "FETCH_ORIGINAL_SHOWS";
export const FETCH_ENTITY = "FETCH_ENTITY";
export const FETCH_PERSON = "FETCH_PERSON";
export const FETCH_LIST_COLLECTION = "FETCH_LIST_COLLECTION";
export const FETCH_LIST_LOAD_MORE = "FETCH_LIST_LOAD_MORE";
export const FETCH_LIST_ITEM = "FETCH_LIST_ITEM";
export const SET_LOADING_STATUS = "SET_LOADING_STATUS";
export const FETCH_MEDIA_ASSETS_LIST = "FETCH_MEDIA_ASSETS_LIST";

export const FETCH_ERROR = "FETCH_ERROR";
export const TTL = 10 * 60 * 1000;

/**
 * @author William Alexander Livesley
 * @date   2018-08-20
 * @description Declaration of the Component interface.
 * @filename comment.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";
import { ComponentProperty } from "./component-property";

/**
 * The __typeID of the GraphQL Component type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_COMPONENT: string = "Component";

/**
 * A poster - an poster displayed on the site, often to advertise site features or shows.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface Component<D extends Date | string = string> extends Node<D> {
 
  /**
   * A description of the component. This is metadata.
   *
   * @type {string}
   * @memberof Component
   */
  description?: string;

  /**
   * The name (key) of the component which identifies the component in a component framework.
   *
   * @type {string}
   * @memberof Component
   */
  key?: string;

  /**
   * A label for the component.
   *
   * @type {string}
   * @memberof Component
   */
  label?: string;

  /**
   * The public properties of the component.
   *
   * @type {ComponentProperty[]}
   * @memberof Component
   */
  properties?: ComponentProperty[];
}

import * as React from "react";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import { SetAuthStatePayload, SetAuthStateAction } from "../../../redux/authentication/types";
import { AUTH_CONFIRM_RESET_PASSWORD, AUTH_RESET_VERIFICATION } from "../../../redux/authentication/constants";
import { FormFieldContainer, Submit, ModalFooter } from "../modal-form-elements";
import { TOAST_ALERT } from "../../../redux/toast/constants";
import { SetToastMessagePayload, SetToastMessageAction } from "../../../redux/toast/types";

interface MapDispatchProps {
  setAuthState: (paylaod: SetAuthStatePayload) => SetAuthStateAction;
  setToastMessage: (payload: SetToastMessagePayload) => SetToastMessageAction;
}

interface MapStateProps {
  step: number;
  verificationCode: string;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { step, verificationCode, setAuthState, setToastMessage } = props;
  const shouldDisplay = step === AUTH_RESET_VERIFICATION;

  const onSubmit = React.useCallback(() => {
    if (verificationCode.length !== 6) {
      setToastMessage({ msg: "Please enter the 6 characters verification code.", type: TOAST_ALERT });
      return;
    }
    setAuthState({ step: AUTH_CONFIRM_RESET_PASSWORD });
  }, [setAuthState, verificationCode, setToastMessage]);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Container>
      <div className="subtitle">Please check your email for the code to verify your password, and enter the code</div>
      <FormFieldContainer>
        <OtpInput
          value={verificationCode}
          onChange={(otp: string) => setAuthState({ verificationCode: otp })}
          numInputs={6}
          className="otp-input"
          isDisabled={false}
        />
      </FormFieldContainer>
      <ModalFooter>
        <Submit onClick={onSubmit} disabled={false} isSubmitting={false} title={"Continue"} />
      </ModalFooter>
    </Container>
  );
};

const Container = ({ children }) => {
  return <div className="reset-verification-code verification-code-form">{children}</div>;
};

const mapState = (state: AppState) => ({
  step: state.authentication.step,
  verificationCode: state.authentication.verificationCode,
});

const mapDispatch = {
  setAuthState: Actions.setAuthState,
  setToastMessage: Actions.setToastMessage,
};

export const ResetVerificationCode = connect(mapState, mapDispatch)(Component);

import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../redux";

interface MapStateProps {
  inProgress: boolean;
}

type Props = MapStateProps;

export const Component = (props: Props) => {
  const { inProgress } = props;
  return (
    <div className={`page-loading-progress`}>
      <div className={`loadingBar ${inProgress ? "show" : ""}`} />
    </div>
  );
};

const mapState = (state: AppState) => ({
  inProgress: state.cache.inProgress["navigation"],
});

export const HtmlPageLoading = connect(mapState)(Component);

/**
* @author: Ahmed Samer
* @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
* @date: 2018-12-26
* @filename: twitter-user.ts
* @description: Declaration of the twitter user payload interface
*/

/**
 * The __typeID of the GraphQL twitter user object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_TWITTER_USER: string = "TwitterUser";

/**
 *
 *
 * @export
 * @interface TwitterUser
 */
export interface TwitterUser {
    /**
     * The cognito identityId of the registered twitter user
     */
    id: string;

    /**
     * The user Id of the registered twitter user
     */
    twitterId: string;
    
    /**
     * The cognito openId token of the registered twitter user
     */
    openIdToken: string;
}
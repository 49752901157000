import { SetToastMessagePayload, SetToastMessageAction, DismissToastMessageAction } from "./types";
import { SET_TOAST_MESSAGE, DISMISS_TOAST_MESSAGE } from "./constants";

export const setToastMessage = (payload: SetToastMessagePayload): SetToastMessageAction => ({
  type: SET_TOAST_MESSAGE,
  payload,
});

export const dismissToastMessage = (payload: never): DismissToastMessageAction => ({
  type: DISMISS_TOAST_MESSAGE,
  payload,
});

import * as React from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { NextArrow, PrevArrow } from "./elements";
import { Card } from "./small-card";
import isEmpty from "lodash.isempty";
import { Router } from "../../../utilities/router";
import { AppState } from "../../../redux";

interface MapStateProps {
  lastSeenPitchId: string | null;
}

interface ComponentProps {
  items: any[];
  showBtn: boolean;
  setCurrentSlideIndex: (n: number) => void;
  currentSlideIndex: number;
}

export type Props = ComponentProps & MapStateProps;

export const Component = (props: Props) => {
  const { items, showBtn, setCurrentSlideIndex, currentSlideIndex, lastSeenPitchId } = props;
  const sliderRef = React.useRef(null);

  const settings = {
    className: "small-pitch-panel",
    beforeChange: (_i: number, index: number) => {
      setCurrentSlideIndex(index);
    },
    slidesToShow: 1,
    lazyLoad: true,
    slidesToScroll: 1,
    speed: 250,
    touchThreshold: 20,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const ACTIVE_SLIDE = Router.getParameterByName("slide") || lastSeenPitchId;

  React.useEffect(() => {
    if (items && !isEmpty(ACTIVE_SLIDE)) {
      const index = items.findIndex((item: any) => {
        return ACTIVE_SLIDE === item?.listItem?.item?.id;
      });
      if (index >= 0 && sliderRef?.current) {
        setCurrentSlideIndex(index);
        sliderRef.current?.slickGoTo(index);
      }
    }
  }, [ACTIVE_SLIDE, items, setCurrentSlideIndex]);

  const slickNext = () => {
    if (typeof sliderRef.current?.slickNext === "function") {
      sliderRef.current?.slickNext();
    }
  };

  return (
    <Slider {...settings} ref={sliderRef}>
      {items &&
        items.map((edge: any, index) => (
          <Card
            key={`pictch-card-${edge?.listItem?.item?.id}`}
            slickNext={slickNext}
            item={edge?.listItem?.item}
            currentSlideIndex={currentSlideIndex}
            slideIndex={index}
            showBtn={showBtn}
          />
        ))}
    </Slider>
  );
};

const mapState = (state: AppState) => ({
  lastSeenPitchId: state.app.lastSeenPitchId,
});

export const SmallCarousel = connect(mapState)(Component);

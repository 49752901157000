import { all, fork } from "redux-saga/effects";
import { watchSetPageScroll } from "./workers/set-page-scroll";
import { watchSetDevice } from "./workers/set-device";

export const sagas = [watchSetDevice, watchSetPageScroll];

export default function* root() {
  yield all(sagas.map((saga) => fork(saga)));
}



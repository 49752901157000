/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type RefreshLinkedInCredentialsInput = {
    readonly clientMutationId: string;
    readonly id: string;
};
export type refreshLinkedInCredentialsMutationVariables = {
    readonly input: RefreshLinkedInCredentialsInput;
};
export type refreshLinkedInCredentialsMutationResponse = {
    readonly refreshLinkedInCredentials: {
        readonly clientMutationId: string | null;
        readonly credentials: {
            readonly id: string;
            readonly expires: unknown | null;
            readonly linkedInUserId: string | null;
            readonly openIdToken: string | null;
        } | null;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly created: unknown | null;
            readonly id: string;
            readonly location: string | null;
            readonly type: string | null;
        } | null> | null;
    } | null;
};
export type refreshLinkedInCredentialsMutation = {
    readonly response: refreshLinkedInCredentialsMutationResponse;
    readonly variables: refreshLinkedInCredentialsMutationVariables;
};



/*
mutation refreshLinkedInCredentialsMutation(
  $input: RefreshLinkedInCredentialsInput!
) {
  refreshLinkedInCredentials(input: $input) {
    clientMutationId
    credentials {
      id
      expires
      linkedInUserId
      openIdToken
    }
    hasErrors
    errors {
      created
      id
      location
      type
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RefreshLinkedInCredentialsInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "refreshLinkedInCredentials",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RefreshLinkedInCredentialsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "credentials",
        "storageKey": null,
        "args": null,
        "concreteType": "LinkedInUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "expires",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "linkedInUserId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "openIdToken",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "refreshLinkedInCredentialsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "refreshLinkedInCredentialsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "refreshLinkedInCredentialsMutation",
    "id": null,
    "text": "mutation refreshLinkedInCredentialsMutation(\n  $input: RefreshLinkedInCredentialsInput!\n) {\n  refreshLinkedInCredentials(input: $input) {\n    clientMutationId\n    credentials {\n      id\n      expires\n      linkedInUserId\n      openIdToken\n    }\n    hasErrors\n    errors {\n      created\n      id\n      location\n      type\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '8aa7f2671e7cd80775c43749947b7892';
export default node;

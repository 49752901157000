/**
 * @Author: Nassar Sara
 * @Date:   2018-03-29T11:30:42+02:00
 * @Email:  sara@radivision.com
 * @Project: Radivision
 * @Filename: original-content-story.ts
 * @Last modified by:   Nassar Sara
 * @Last modified time: 2018-05-30T14:20:19+02:00
 * @Copyright: Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { MediaAsset } from "./media-asset";
import { Story } from "./story";

/**
 * The __typeID of the Original Content Story type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY: string =
  "OriginalContentStory";

/**
 * A story referencing an original content story
 *
 * @export
 * @extends {Story<D>}
 * @interface OriginalContentStory
 * @template D
 */
export interface OriginalContentStory<D extends Date | string = string>
  extends Story<D> {
  /**
   * The duration of the video for the original content story in seconds
   *
   * @type {number}
   * @memberof OriginalContentStory
   */
  durationInSeconds?: number;

  /**
   * The serialized embed code for the media item.
   *
   * @type {string}
   */
  externalId?: string;

  /**
   * The original content story media item.
   *
   * @type {MediaAsset}
   */
  mediaItem?: MediaAsset;

  /**
   * The link for the media item.
   *
   * @type {string}
   */
  link?: string;
}

import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import {
  SetAuthStatePayload,
  SetAuthStateAction,
  VerifyAccountAction,
  VerifyAccountPayload,
} from "../../../redux/authentication/types";
import { AUTH_SIGNUP_VERIFY_ACCOUNT } from "../../../redux/authentication/constants";
import { VerifyAccount } from "./verify-account";

interface MapDispatchProps {
  signupVerifyAccount: (payload: VerifyAccountPayload) => VerifyAccountAction;
}

interface MapStateProps {
  step: number;
  email: string;
  verificationCode: string;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { step, email, verificationCode, signupVerifyAccount } = props;

  const shouldDisplay = step === AUTH_SIGNUP_VERIFY_ACCOUNT;

  const onSubmit = React.useCallback(() => {
    signupVerifyAccount({ email, verificationCode });
  }, [email, signupVerifyAccount, verificationCode]);

  if (!shouldDisplay) {
    return null;
  }

  return <VerifyAccount onSubmit={onSubmit} />;
};

const mapState = (state: AppState) => ({
  step: state.authentication.step,
  email: state.authentication.email,
  verificationCode: state.authentication.verificationCode,
});

const mapDispatch = {
  setToastMessage: Actions.setToastMessage,
  signInWithEmailAndPassword: Actions.signInWithEmailAndPassword,
  signupVerifyAccount: Actions.signupVerifyAccount,
};

export const SignUpVerifyAccount = connect(mapState, mapDispatch)(Component);

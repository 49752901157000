import React from "react";
import { connect } from "react-redux";
import $ from "jquery";
// import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { Router } from "../../../utilities/router";
import { SetEventStatePayload, SetEventStateAction } from "../redux/events/types";
import { setEventState } from "../redux/events/actions";
import { RdvButton } from "../../../react-components/page-framework/rdv-button";
import { MONTHS_NAMES } from "../../../constants/months-names";
import { TopLevelMediaKind, PreviewKind } from "@radivision/graphql";
import { OptimizedImageUrls } from "../../../component-configuration/optimized-image";
import { ImageHelper } from "../../../utilities/image-helper";
import { getEventInfo } from "../utils/get-event-info";
import { RSVPButton } from "../rsvp-button";

interface ComponentProps {
  event: any;
  itemId: string;
}

interface MapDispatchProps {
  setEventState: (payload: SetEventStatePayload) => SetEventStateAction;
}

export type Props = ComponentProps & MapDispatchProps;

const Component = (props: Props) => {
  const { event } = props;
  const previewUrl: OptimizedImageUrls = event?.previews
    ? ImageHelper.fetchOptimizedImageUrl({
        imageType: "PREVIEW",
        preview: {
          content: event?.previews,
          previewKind: PreviewKind.CAROUSEL,
          topLevelMedia: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: { containerWidthRatio: 3 / 4, numberOfItems: 3 },
        isSquareImage: false,
        revision: event?.revision,
      })
    : null;
  const Item = React.useCallback(
    ({ event }: { event: any }) => {
      if (!event) return null;
      const { dateLine, url } = getEventInfo(event);
      const goto = (e: React.SyntheticEvent) => {
        e.preventDefault();
        Router.navigateTo(url);
      };
      return (
        <div className="event-card">
          <a
            className="event-card-image"
            href={url}
            onClick={goto}
            style={{ backgroundImage: `url('${previewUrl?.requestedResolutionUrl}')` }}
          ></a>
          <div className="event-card-body">
            <h4 className="date">
              <a href={url} onClick={goto}>
                {dateLine}
              </a>
            </h4>
            <p className="title">{event.name}</p>
            <div className="rsvp">
              <RSVPButton event={event} />
            </div>
          </div>
        </div>
      );
    },
    [previewUrl],
  );

  return (
    <div role="button" tabIndex={0} className="card-container">
      <Item event={event} />
    </div>
  );
};

const mapState = () => ({});

const mapDispatch = {
  setEventState: setEventState,
};

export const Card = connect(mapState, mapDispatch)(Component);

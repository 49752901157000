.upload-profile-image-button {
    width: 100%;
    position:relative;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px dashed #888;
    input {
      cursor: pointer;
      position: absolute;
      height: 40px;
      width: 200px;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index:1;
      opacity: 0;
      &:hover ~ button {
        color: white;
        background-color: #4cb4e7;
        cursor: pointer;
      }
    }
    button {
      position: absolute;
      cursor: pointer;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      border: 1px solid #4cb4e7;
      font-size: 14px;
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 20px;
      padding: 4px 15px 4px 15px;
      pointer-events: none;
      color: #4cb4e7;
      white-space: nowrap;
      width: 200px;
    }
}

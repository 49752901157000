/**
 * @author Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-11-06
 * @description Implementation of User utilities to be used in the app.
 * @filename user.ts
 */
import {
  AddToPersonInput,
  CreateUpdatePreviewInput,
  Person as GraphqlPerson,
  PreviewKind,
  SocialMediaPlatformKind,
  UpdatePersonInput,
} from "@radivision/graphql";
import { CreateUpdateSocialMediaCredentialInput } from "@radivision/graphql/lib/ts/graphql/mutations/create-update-social-media-credential-input";
import { Environment } from "relay-runtime";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../relay/relay-environment";
import { getClientMutationId } from "./general";
import { LocalStorageKeys } from "./local-storage-keys";
import { commitMutation } from "./relay";
import { storageFactory } from "../utilities/local-storage-factory";

const localStore = storageFactory(() => localStorage);

/**
 * Returns the logged-in user id.
 *
 * @returns {GraphqlPerson} The logged-in user id.
 */
export function getLoggedInUser(): string {
  const USER: string = localStore.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_USER_ID);
  let personId: string;

  if (USER !== undefined && USER !== null) {
    personId = USER;
  }
  return personId;
}

/**
 * Manages user interactions with back-end.
 */
export class User {
  /**
   * A Regular expression used to validate the number of numbers in the password.
   *
   * @type {RegExp}
   */
  static PASSWORD_NUMBERS: RegExp = /([0-9])/g;

  /**
   * A Regular expression used to validate the number of lower-case characters in the password.
   *
   * @type {RegExp}
   */
  static PASSWORD_LOWERCASE_LETTERS: RegExp = /[a-z]/g;

  /**
   * A Regular expression used to validate the number of special characters in the password.
   *
   * @type {RegExp}
   */
  static PASSWORD_SPECIAL: RegExp = /([!@#$%^&*])/g;

  /**
   * A Regular expression used to validate the number of upper-case characters in the password.
   *
   * @type {RegExp}
   */
  static PASSWORD_UPPERCASE_LETTERS: RegExp = /([A-Z])/g;

  /**
   * Returns a promise to add to a person in the repository.
   *
   * @param {AddToPersonInput} personInput The update to add to the person.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<*>} The promise to add to a person in the repository.
   */
  static async addToPerson(personInput: AddToPersonInput, environment: Environment = RELAY_ENVIRONMENT): Promise<any> {
    return import("../relay/mutations-person").then((mutations) => {
      const config = {
        mutation: mutations.ADD_TO_PERSON_GRAPHQL_MUTATION,
        variables: {
          input: personInput,
        },
      };

      return commitMutation(environment, config);
    });
  }

  /**
   * Returns a promise to update the user's information in the repository.
   *
   * @param {UpdatePersonInput} personInput The update to the person.
   *
   * @returns {Promise<>>*>} The promise to update the user's information in the repository.
   */
  static async updatePerson(
    personInput: UpdatePersonInput,
    environment: Environment = RELAY_ENVIRONMENT,
  ): Promise<any> {
    return import("../relay/mutations-person").then((mutations) => {
      const config = {
        mutation: mutations.UPDATE_PERSON_GRAPHQL_MUTATION,
        variables: {
          input: personInput,
        },
      };
      return commitMutation(environment, config);
    });
  }

  /**
   * Returns a flag which is true if a given string is a valid password.
   *
   * @param {string} password The password to be validated.
   *
   * @returns {boolean} A flag which is true if a given string is a valid password.
   */
  static validatePassword(password: string = ""): boolean {
    let lowercase: number;
    let numbers: number;
    let result: RegExpMatchArray;
    let special: number;
    let uppercase: number;

    result = password.match(User.PASSWORD_LOWERCASE_LETTERS);
    lowercase = result === null ? 0 : result.length;
    result = password.match(User.PASSWORD_NUMBERS);
    numbers = result === null ? 0 : result.length;
    result = password.match(User.PASSWORD_UPPERCASE_LETTERS);
    uppercase = result === null ? 0 : result.length;
    result = password.match(User.PASSWORD_SPECIAL);
    special = result === null ? 0 : result.length;
    return (
      password.length === 0 ||
      (lowercase > 0 && uppercase > 0 && numbers > 0 && special > 0 && lowercase + uppercase + numbers + special > 7)
    );
  }

  /**
   * Function used to handle user previews changes
   * person can change his profile image or his profile background
   *
   * @static
   * @param {string} [profileImgMediaAssetId]
   * @param {string} [backGroundMediaAssetId]
   * @param {*} [personPreviews]
   * @returns {CreateUpdatePreviewInput[]}
   * @memberof User
   */
  static handleEditPersonPreview(
    profileImgMediaAssetId?: string,
    backGroundMediaAssetId?: string,
    personPreviews?: any,
    requestPreviewKind?: PreviewKind,
  ): CreateUpdatePreviewInput[] {
    const DEFAULT_PREVIEW: PreviewKind = requestPreviewKind ? requestPreviewKind : PreviewKind.HEADSHOT;

    let previews: CreateUpdatePreviewInput[] = personPreviews
      ? personPreviews.edges.map((previewEdge: any) => {
          return {
            kind: previewEdge.preview.kind,
            link: previewEdge.preview.link,
            mediaAsset: previewEdge.preview.mediaAsset ? previewEdge.preview.mediaAsset.id : undefined,
          };
        })
      : [];

    if (profileImgMediaAssetId !== null && profileImgMediaAssetId !== undefined) {
      // ... check if HeadShot is already on his previews just update its value
      // ... check if background is already on his previews just update its value
      const profileImgIndex: number = previews.findIndex((value: CreateUpdatePreviewInput): boolean => {
        return value.kind === DEFAULT_PREVIEW;
      });

      if (profileImgIndex === -1) {
        previews.push({
          kind: DEFAULT_PREVIEW,
          mediaAsset: profileImgMediaAssetId,
        });
      } else {
        // ... update
        previews[profileImgIndex] = {
          kind: DEFAULT_PREVIEW,
          mediaAsset: profileImgMediaAssetId,
        };
      }

      previews = [
        ...previews,
        {
          kind: PreviewKind.SQUARE,
          mediaAsset: profileImgMediaAssetId,
        },
      ];
    }

    if (backGroundMediaAssetId !== null && backGroundMediaAssetId !== undefined) {
      // ... check if background is already on his previews just update its value
      const backGroundIndex: number = previews.findIndex((value: CreateUpdatePreviewInput): boolean => {
        return value.kind === PreviewKind.BACKGROUND;
      });

      if (backGroundIndex === -1) {
        previews.push({
          kind: PreviewKind.BACKGROUND,
          mediaAsset: backGroundMediaAssetId,
        });
      } else {
        // ... update
        previews[backGroundIndex] = {
          kind: PreviewKind.BACKGROUND,
          mediaAsset: backGroundMediaAssetId,
        };
      }
    }

    return previews;
  }

  /**
   *
   * @static
   * @param {string} personId
   * @param {string} credentials
   * @param {SocialMediaPlatformKind} kind
   * @returns {Promise<any>}
   * @memberof User
   */
  static updateSocialMediaCredential(
    personId: string,
    credentials: string,
    kind: SocialMediaPlatformKind,
  ): Promise<any> {
    const personUpdateInput: UpdatePersonInput = {
      clientMutationId: getClientMutationId(),
      force: true,
      id: personId,
    };
    let socialMediaCredentialInput: CreateUpdateSocialMediaCredentialInput;
    let promise: Promise<any>;

    if (credentials !== undefined && credentials !== null && credentials.length > 0) {
      socialMediaCredentialInput = {
        credentials,
        platform: kind,
      };
    }
    // validate provided inputs before execute actual update mutation
    if (socialMediaCredentialInput === null || socialMediaCredentialInput === undefined) {
      promise = Promise.reject("[PBD38HBsiUOGTMh7jt0obQ] invalid social media credentials provided");
    }
    // assign created social media credentials to update person input
    personUpdateInput.socialMediaCredentials = [socialMediaCredentialInput];
    // attempt to invoke update user graph ql mutation.
    promise = User.updatePerson(personUpdateInput);

    promise = promise.then((mutationResults: any) => {
      return mutationResults.updatePerson.hasErrors === false
        ? Promise.resolve(mutationResults.updatePerson.person)
        : Promise.reject(mutationResults.updatePerson.errors);
    });

    return promise;
  }

  /**
   *
   *
   * @returns {boolean}
   * @memberof User
   */
  static isContentManager(): boolean {
    return true;
  }
}

/**
 * @Author: Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-03-29 01:56:09
 * @description Implementation of the Inspiration type.
 * @filename Inspiration.ts
 */
import { Entity } from "./entity";
import { Person } from "./person";

/**
 * The __typeID of the Inspiration type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_INSPIRATION: string = "Inspiration";

/**
 * Items which can be identified as an inspiration.
 */
export type Inspirer<D extends string | Date = string> = Entity<D> | Person<D>;

/**
 * A description of an inspiration.
 *
 * @export
 * @interface Inspiration
 * @template D
 */
export interface Inspiration<D extends string | Date = string> {
  /**
   * The date and time when the inspiration was created as a Date object or an ISO-8601 formatted UTC date string.
   *
   * @template D
   * @type {D}
   */
  created?: D;

  /**
   * The ID of the inspiration item
   *
   * @memberof Inspiration
   * @type {string}
   */
  id?: string;

  /**
   * The person or entity that inspires
   *
   * @memberof Inspiration
   * @template D
   * @type {Inspirer<D>}
   */
  inspirer?: Inspirer<D>;

  /**
   * A description of why the inspiree is insiprational
   *
   * @memberof Inspiration
   * @type {string}
   */
  why?: string;

  /**
   * The typename of the inspiration.
   *
   * @type {string}
   */
  __typename?: string;
}

import * as React from "react";
import Loadable from "react-loadable";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { AUTH_CHOOSE_ACCOUNT_TYPE } from "../../../redux/authentication/constants";
import { Loader } from "../../page/loader";

interface MapStateProps {
  step: number;
}

export type Props = MapStateProps;

export const Component = (props: Props) => {
  const { step } = props;
  const isChooseAccountStep = step === AUTH_CHOOSE_ACCOUNT_TYPE;
  if (!isChooseAccountStep) return null;
  const DynamicChooseAccountType = Loadable({
    loader: () => import("../../../modules/manage-profile").then((module) => module.ChooseAccountType) as Promise<any>,
    loading: () => <Loader isActive={true} />,
  });
  return (
    <div className="dynamic-choose-account-type">
      <DynamicChooseAccountType />
    </div>
  );
};

const mapState = (state: AppState) => ({
  step: state.authentication.step,
});

const mapDispatch = {};

export const ChooseAccountType = connect(mapState, mapDispatch)(Component);

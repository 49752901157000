.marketplace-container {
  width: 100%;
  margin: 0 auto;
  // display: flex;
  // justify-content: space-evenly;
  // align-items: center;
  .action-btn {
    background-color: white;
    color: black;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    border-radius: 20px;
    border: none;
    padding: 10px 40px;
    margin-top: 20px;
  }
  .break-line {
    margin-top: 4rem;
  }
  .card {
    display: flex;
    // justify-content: center;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    background-color: rgb(40, 40, 40);
    width: 100%;
    height: auto;
    border-radius: 25px;
    padding-bottom: 10px;
    color: rgb(223, 217, 217);
    position: relative;
    &:hover {
      .admin-buttons {
        display: flex;
        width: auto;
        position: absolute;
        top: 1px;
        right: 0px;
        margin-bottom: 2px;
        border-radius: 50%;
        border: 2px;
        &.delete {
          top: 2.25rem;
        }
      }
    }
    .card-content {
      padding: 10px 20px 20px 20px;
      flex-grow: 1;
      display: flex;
      position: relative;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;
      .description {
        font-size: 16px;
        color: #aaa;
        font-family: Muli;
        margin: 10px 0px;
        width: 100%;
        text-align: center;
        flex-grow: 1;
      }
      .code-btn {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        .action-btn {
        }
        .code {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          width: 100%;
          text-align: center;
          margin-right: 15px;
        }
        .copy {
          margin-left: 6px;
          svg {
            fill: white;
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
  .section-header {
    margin-bottom: 2rem;
    font-size: 20px;
    font-family: MuliBold;
  }
  .card-image {
    align-self: flex-start;
    width: 100%;
    height: 0px;
    padding-top: 45%;
    position: relative;
    background-position: center center;
    background-size: cover;
  }
  .del {
    top: 7px;
  }
  .admin-buttons {
    display: none;
    background-color: transparent;
    border-color: transparent;
  }
  .edit {
    background-color: transparent;
  }
  .delete {
    background-color: transparent;
  }
  img {
    display: block;
    margin-bottom: 1rem;
    max-width: 180px;
    max-height: 120px;
    width: auto;
    height: auto;
  }

  .marketplace-row {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 70%;
    flex: 1;
    justify-content: center;
  }
  @media screen and (min-width: 570px) {
    .card {
      height: auto;
    }
    .marketplace-row {
      grid-gap: 1.5rem;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (min-width: 800px) {
    .card {
      height: auto;
    }
    .marketplace-row {
      grid-gap: 2rem;
      grid-template-columns: repeat(3, 1fr);
    }
    .description {
      font-size: 18px;
      margin: 20px 0px;
    }
    .action-btn {
      margin: 20px 0px;
    }
  }
}


/**
 * @author Ahmed Samer
 * @copyright Copyright 2021 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2021-10-30
 * @description Implementation of the GraphQL query to retrieve the search.
 * @filename pitch-planet-query.tsx
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL query to retrieve the current user.
 *
 * @type {GraphQLTaggedNode}
 */
export const PITCH_PLANET_QUERY: GraphQLTaggedNode = graphql`
  query pitchPlanetQuery(
    $query: String!
    $cursor: String
    $facets: [SearchFacetInput!]
    $filterQuery: String
    $order: String
  ) {
    pitchPlanet(query: $query, cursor: $cursor, facets: $facets, filterQuery: $filterQuery, order: $order) {
      hasErrors
      errors {
        id
        location
      }
      facets {
        buckets {
          count
          value
        }
        name
      }
      items {
        __typename
        id
        description
        title
        isPromoted
        trailer {
          externalId
          description
          durationInSeconds
          link
        }
        originalClip {
          externalId
          durationInSeconds
          description
          link
        }
        revision
        created
        label
        isDeleted
        isNew
        peopleEntities {
          entity {
            __typename
            landingPageUrl
            id
            name
            websiteUrl
            fundingRound
            industry
            people {
              count
              edges {
                role
                person {
                  __typename
                  id
                  fullName
                  landingPageUrl
                  previews {
                    edges {
                      preview {
                        link
                        kind
                        mediaAsset {
                          files {
                            name
                            path
                          }
                          topLevelMediaType {
                            kind
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            activities {
              kind
              label
            }
            previews {
              edges {
                preview {
                  link
                  kind
                  mediaAsset {
                    files {
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                    }
                  }
                }
              }
            }
          }
          person {
            __typename
            landingPageUrl
            id
            fullName
            responsibility
            description
            previews {
              edges {
                preview {
                  link
                  kind
                  mediaAsset {
                    files {
                      name
                      path
                    }
                    topLevelMediaType {
                      kind
                    }
                  }
                }
              }
            }
          }
        }
        previews {
          count
          edges {
            cursor
            preview {
              link
              kind
              mediaAsset {
                id
                files {
                  id
                  name
                  path
                }
                topLevelMediaType {
                  kind
                  label
                }
              }
            }
          }
        }
        title
      }
    }
  }
`;

import { debounce, put } from "redux-saga/effects";
import { setAppState } from "../actions";
import { SetAppStateAction } from "../types";
import { SET_PAGE_SCROLL } from "../constants";

function* setPageScrollWorker(action: SetAppStateAction) {
  try {
    yield put(setAppState(action.payload));
  } catch (errors) {}
}

export function* watchSetPageScroll() {
  yield debounce(300, SET_PAGE_SCROLL, setPageScrollWorker);
}

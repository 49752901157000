/**
 * @author William Alexander Livesley
 * @date   2018-03-20
 * @description Declaration of the HtmlDocumentSlot interface.
 * @filename html-document-slot.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { ComponentWithData } from "./component-with-data";
import { Node } from "./node";

/**
 * The __typeID of the HTML Document Slot type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_HTML_DOCUMENT_SLOT: string = "HtmlDocumentSlot";

/**
 * An HTML document slot.
 *
 * @extends {Node}
 * @interface
 * @template D
 */
export interface HtmlDocumentSlot<D extends Date | string = string>
  extends Node<D> {
  /**
   * The component which manages the HTML document slot
   *
   * @type {ComponentWithData}
   */
  component?: ComponentWithData;

  /**
   * A description of the HTML document slot. Metadata
   *
   * @type {string}
   */
  description?: string;

  /**
   * A label for the HTML document slot. Metadata
   *
   * @type {string}
   */
  label?: string;
}

/**
 * @author William A. Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-11-24
 * @description Implementation of Preview class.
 * @filename preview.ts
 */
import { Preview as GraphQlPreview} from "@radivision/graphql/lib/ts/graphql/preview";
import { MediaAssetFile } from "./media-asset-file";
import { Connection } from "@radivision/graphql/lib/ts/graphql/connection";
import { PreviewKind } from "@radivision/graphql/lib/ts/graphql/preview-type";
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";

/**
 * A class containing utilities for preview manipulation.
 */
export class Preview {
  /**
   * Returns the URL of the image or video acting as a preview  - where a collection of types can be specified.
   *
   * @param {Connection<string, GraphQlPreview>} previews The collection of previews.
   *
   * @param {(GraphQlPreviewKind|Array<GraphQlPreviewKind>)} previewKind The collection of preview kinds in priority order.
   *
   * @returns {string} The URL of the image or video acting as a preview.
   */
  static getUrlFromPreview(
    previews: Connection<string, GraphQlPreview>,
    previewKind: any
  ): string {
    let edge: any;
    let url: string;

    if (
      previews !== null &&
      previews !== undefined &&
      previews.edges &&
      previews.edges.length > 0
    ) {
      if (Array.isArray(previewKind) === true) {
        previewKind.find((gpk: PreviewKind): boolean => {
          edge = previews.edges.find(
            (edge: { cursor: string; preview: GraphQlPreview }): boolean => {
              return edge.preview.kind === gpk;
            }
          );
          return edge !== undefined;
        });
      } else {
        edge = previews.edges.find(
          (edge: { cursor: string; preview: GraphQlPreview }): boolean => {
            return edge.preview.kind === previewKind;
          }
        );
      }
      if (edge !== undefined) {
        if (edge.preview.link === undefined || edge.preview.link === null) {
          if (
            edge.preview.mediaAsset.topLevelMediaType &&
            edge.preview.mediaAsset.topLevelMediaType.kind ===
              TopLevelMediaKind.IMAGE
          ) {
            url = MediaAssetFile.getImageUrlFromMediaAssetFile(
              edge.preview.mediaAsset.files[0]
            );
          } else {
            url = MediaAssetFile.getVideoUrlFromMediaAssetFiles(
              edge.preview.mediaAsset.files
            );
          }
        } else {
          url = edge.preview.link;
        }
      }
    }
    return url;
  }

  /**
   * Returns the URL of the image or video acting as a preview of a given type - where a collection of types can be specified.
   *
   * @param {Connection<string, GraphQlPreview>} previews The collection of previews.
   *
   * @param {(GraphQlPreviewKind|Array<GraphQlPreviewKind>)} previewKind The collection of preview kinds in priority order.
   *
   * @returns {string} The URL of the image or video acting as a preview.
   */
  static getPlaceholderUrlFromPreview(previews: any, previewKind: any): string {
    let edge: { cursor: string; preview: GraphQlPreview };
    let url: string;

    if (
      previews !== null &&
      previews !== undefined &&
      previews.edges &&
      previews.edges.length > 0
    ) {
      if (Array.isArray(previewKind) === true) {
        previewKind.find((gpk: PreviewKind): boolean => {
          edge = previews.edges.find(
            (edge: { cursor: string; preview: GraphQlPreview }): boolean => {
              return edge.preview.kind === gpk;
            }
          );
          return edge !== undefined;
        });
      } else {
        edge = previews.edges.find(
          (edge: { cursor: string; preview: GraphQlPreview }): boolean => {
            return edge.preview.kind === previewKind;
          }
        );
      }
      if (edge !== undefined && edge !== null) {
        if (edge.preview.link === undefined) {
          if (
            edge.preview.mediaAsset.topLevelMediaType &&
            edge.preview.mediaAsset.topLevelMediaType.kind ===
             TopLevelMediaKind.IMAGE
          ) {
            url = MediaAssetFile.getImageUrlFromMediaAssetFile(
              edge.preview.mediaAsset.files[0],
              10
            );
          } else {
            url = MediaAssetFile.getVideoUrlFromMediaAssetFiles(
              edge.preview.mediaAsset.files
            );
          }
        } else {
          url = edge.preview.link;
        }
      }
    }
    return url;
  }
}

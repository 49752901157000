import { graphql, GraphQLTaggedNode } from "react-relay";

export const FETCH_ENTITY_QUERY: GraphQLTaggedNode = graphql`
  query fetchEntityQuery($id: ID!) {
    entity(id: $id) {
      hasErrors
      entity {
        __typename
        description
        founded
        id
        isEditable
        industry
        marketplace {
          count
          edges {
            cursor
            story {
              ...marketplaceEntry @relay(mask: false)
            }
          }
        }
        activities {
          kind
          label
        }
        isClaimable
        pitchOriginalMedia {
          id
        }
        investmentsBy {
          amount
          link
          deckUrl
          investmentDate
        }
        opportunities {
          productAndServices {
            description
            link
            email
          }
          careers {
            description
            link
            email
          }
          collaboration {
            description
            link
            email
          }
        }
        fundingRound
        isFundraising
        isFeedBaseEntity
        landingPageUrl
        locations {
          id
          label
        }
        headquarters {
          location {
            label
          }
        }
        feed(kind: PROFILE) {
          feed {
            key
            stories {
              __typename
              ... on RadivisionPost {
                id
                link
                title
              }
              ... on ArticleStory {
                id
                link
                kind
                publisher
                publishedDate
                timeToReadInSeconds
                previews {
                  edges {
                    preview {
                      link
                      kind
                    }
                  }
                }
                description
                title
              }
            }
          }
        }
        mediaAbout {
          count
          edges {
            cursor
            story {
              __typename
              ... on RadivisionPost {
                id
                link
                previews {
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        id
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
                landingPageUrl
                revision
              }
              ... on OnlineCourseStory {
                id
                publishedDate
                timeRequiredInSeconds
                publisher
                link
                previews {
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        id
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
                revision
              }
              ... on RadivisionPost {
                id
                content
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        id
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                title
                revision
              }
              ... on ArticleStory {
                description
                id
                link
                publisher
                publishedDate
                timeToReadInSeconds
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        id
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                revision
                subTitle
                title
              }
              ... on BookStory {
                id
                link
                publisher
                publishedDate
                timeToReadInSeconds
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
                revision
              }
              ... on NewsletterStory {
                id
                description
                link
                publisher
                publishedDate
                timeToReadInSeconds
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                revision
              }
              ... on PodcastStory {
                id
                link
                publisher
                publishedDate
                durationInSeconds
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                description
                revision
              }
              ... on OriginalContentStory {
                id
                durationInSeconds
                previews {
                  count
                  edges {
                    cursor
                    preview {
                      link
                      kind
                      mediaAsset {
                        files {
                          id
                          name
                          path
                        }
                        topLevelMediaType {
                          kind
                          label
                        }
                      }
                    }
                  }
                }
                subTitle
                title
                revision
              }
            }
          }
        }
        name
        hasPortfolio
        portfolioMarketCap
        portfolioEntities {
          id
        }
        portfolioPeople {
          id
        }
        noOfPortfolioCompanies {
          maximum
          minimum
          isGreaterThan
          isLessThan
        }
        portfolio {
          count
          pageInfo {
            hasNextPage
          }
          edges {
            edges {
              __typename
              id
              name
              landingPageUrl
              previews(kinds: [SQUARE, LOGO]) {
                edges {
                  preview {
                    link
                    mediaAsset {
                      id
                      files {
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                      }
                    }
                    kind
                  }
                }
              }
              tags {
                label
              }
            }
          }
        }
        noOfEmployees {
          maximum
          minimum
          isGreaterThan
          isLessThan
        }
        people(first: 24) {
          count
          pageInfo {
            hasNextPage
          }
          edges {
            role
            person {
              id
              __typename
              fullName
              responsibility
              description
              landingPageUrl
              previews {
                count
                edges {
                  cursor
                  preview {
                    link
                    kind
                    mediaAsset {
                      id
                      files {
                        id
                        name
                        path
                      }
                      topLevelMediaType {
                        kind
                        label
                      }
                    }
                  }
                }
              }
            }
          }
        }
        previews {
          count
          edges {
            cursor
            preview {
              link
              kind
              mediaAsset {
                id
                files {
                  id
                  name
                  path
                }
                topLevelMediaType {
                  kind
                  label
                }
              }
            }
          }
        }
        northStar {
          __typename
          description
          id
          questions {
            __typename
            ... on TextQuestion {
              content
              id
              response {
                content
                id
              }
            }
          }
        }
        tagLine
        socialMediaCredentials {
          credential
          platform {
            kind
          }
        }
        socialMediaProfiles {
          id
          link
          platform {
            id
            kind
          }
        }
        revision
        websiteUrl
      }
    }
  }
`;

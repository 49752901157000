import {
  UpdatePersonInput,
  RemoveFromPersonInput,
  RemoveFromEntityInput,
  DeleteEntityAttributeInput,
  DeletePersonAttributeInput,
  AddToPersonInput,
} from "@radivision/graphql";
import { commitMutation } from "../../../../utilities/relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";
import { UpdateEntityInput } from "../../../../__generated__/updateEntityMutation.graphql";

export const updateEntity = async (input: UpdateEntityInput): Promise<any> => {
  const MUTATIONS = await import("../../../../relay/mutations-entity");
  const config = { mutation: MUTATIONS.UPDATE_ENTITY_GRAPHQL_MUTATION, variables: { input } };
  return commitMutation(ENVIRONMENT, config).then((response: any) => {
    return response.updateEntity.hasErrors === true
      ? Promise.reject(response.updateEntity.errors)
      : Promise.resolve(response.updateEntity.entity);
  });
};

export const updatePerson = async (input: UpdatePersonInput) => {
  const MUTATIONS = await import("../../../../relay/mutations-person");
  const config = { mutation: MUTATIONS.UPDATE_PERSON_GRAPHQL_MUTATION, variables: { input } };
  return commitMutation(ENVIRONMENT, config).then((response) => {
    return response.updatePerson.hasErrors === true
      ? Promise.reject(response.updatePerson.erros)
      : Promise.resolve(response.updatePerson.person);
  });
};

export const removeFromPerson = async (input: RemoveFromPersonInput) => {
  const MUTATIONS = await import("../../../../relay/mutations-person");
  return commitMutation(ENVIRONMENT, {
    mutation: MUTATIONS.REMOVE_FROM_PERSON_GRAPHQL_MUTATION,
    variables: {
      input,
    },
  }).then((response) => {
    return response.removeFromPerson.hasErrors === true
      ? Promise.reject(response.removeFromPerson)
      : Promise.resolve(response.removeFromPerson.person);
  });
};

export const removeFromEntity = async (input: RemoveFromEntityInput) => {
  const MUTATIONS = await import("../../../../relay/mutations-entity");
  return commitMutation(ENVIRONMENT, {
    mutation: MUTATIONS.REMOVE_FROM_ENTITY_GRAPHQL_MUTATION,
    variables: {
      input: input,
    },
  }).then((response) => {
    return response.removeFromEntity.hasErrors === true
      ? Promise.reject(response.removeFromEntity)
      : Promise.resolve(response.removeFromEntity.entity);
  });
};

export const deleteFromEntity = async (input: DeleteEntityAttributeInput) => {
  const MUTATIONS = await import("../../../../relay/mutations-entity");
  return commitMutation(ENVIRONMENT, {
    mutation: MUTATIONS.DELETE_FROM_ENTITY_GRAPHQL_MUTATION,
    variables: {
      input,
    },
  }).then((response) => {
    return response.deleteFromEntity.hasErrors === true
      ? Promise.reject(response.deleteFromEntity)
      : Promise.resolve(response.deleteFromEntity.entity);
  });
};

export const deleteFromPerson = async (input: DeletePersonAttributeInput) => {
  const MUTATIONS = await import("../../../../relay/mutations-person");
  return commitMutation(ENVIRONMENT, {
    mutation: MUTATIONS.DELETE_FROM_PERSON_GRAPHQL_MUTATION,
    variables: {
      input,
    },
  }).then((response) => {
    return response.deleteFromPerson.hasErrors === true
      ? Promise.reject(response.deleteFromPerson)
      : Promise.resolve(response.deleteFromPerson.person);
  });
};

export const addToPerson = async (input: AddToPersonInput) => {
  const MUTATIONS = await import("../../../../relay/mutations-person");
  return commitMutation(ENVIRONMENT, {
    mutation: MUTATIONS.ADD_TO_PERSON_GRAPHQL_MUTATION,
    variables: {
      input,
    },
  }).then((response) => {
    return response.addToPerson.hasErrors === true
      ? Promise.reject(response.addToPerson)
      : Promise.resolve(response.addToPerson.person);
  });
};

/**
 * @author Ahmed serag
 * @date   2018-04-19
 * @description Declaration of the InvestmentByEntity interface.
 * @filename investment-by-entity.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Currency } from "./currency";
import { Node } from "./node";
import { Entity } from "./entity";
import { FundingRoundType } from "./funding-round-type";
import { Person } from "./person";

/**
 * The __typeID of the Investment by Entity type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_INVESTMENT_BY_ENTITY: string = "InvestmentByEntity";

/**
 *  An investment made by an entity
 *
 * @export
 * @interface InvestmentByEntity
 * @extends {Node<D>}
 * @template D
 */
export interface InvestmentByEntity<D extends Date | string = string>
  extends Node<D> {
  /**
   * The amount of the investment
   *
   * @type {number}
   * @memberof InvestmentByEntity
   */
  amount?: string;

  /**
   * The currency unit of the investment,
   *
   * @template D
   * @type {string}
   * @memberof InvestmentByEntity
   */
  currency?: Currency<D>;

  /**
   * The funding round when the investment was made
   *
   * @template D
   * @type {FundingRoundType<D>}
   * @memberof InvestmentByPerson
   */
  fundingRound?: FundingRoundType<D>;

  /**
   * The entity in whom the investment was made
   *
   * @template D
   * @type {Entity<D>}
   * @memberof InvestmentByEntity
   */
  investee?: Entity<D>;

  /**
   * The timestamp when the investment was made
   *
   * @template D
   * @type {D}
   * @memberof InvestmentByEntity
   */
  investmentDate?: D;

  /**
   * The person or collection of people who made the investment on behalf of the entity
   *
   * @template D
   * @type {Array<Person<D>>}
   * @memberof InvestmentByEntity
   */
  people?: Person[];
}

/**
 * @author: Adham El Banhawy
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2018-08-16
 * @filename: twitter-authenticator.tsx
 * @description: Implementation of the TwitterAuthenticator react component that completes the
 * twitter login flow
 */
import React from "react";
import { fetchQuery } from "react-relay";
import { TWITTER_ACCESS_TOKEN_GRAPHQL_QUERY } from "../../relay/queries/twitter-access-token-query";
import { ENVIRONMENT } from "../../relay/relay-environment";
import { gaEvent } from "../../utilities/general";

/**
 * The props associated with the LinkedInAuthenticator component.
 *
 * @interface
 */
interface TwitterAuthenticatorProps {
  /**
   * the access token retrieved from url twitter redirect
   *
   * @type {string}
   */
  oauthToken: string;

  /**
   * The oauth verifier retrieved from url twitter redirect
   *
   * @type {string}
   */
  oauthVerifier: string;
}

/**
 *  A React component that executes the process to complete a LinkedIn login.
 *
 * @extends {React.Component<TwitterAuthenticatorProps>}
 */
export class TwitterAuthenticator extends React.Component<
  TwitterAuthenticatorProps
> {
  /**
   * Constructor
   *
   * @param {TwitterAuthenticatorProps} props The props.
   */
  constructor(props: TwitterAuthenticatorProps) {
    super(props);
  }

  /**
   * Invoked by the React framework immediately after a component is mounted.
   */
  componentDidMount(): void {
    let twitterUserToken: { [index: string]: string };

    fetchQuery(ENVIRONMENT, TWITTER_ACCESS_TOKEN_GRAPHQL_QUERY, this.props)
      .then((queryResults: any) => {
        gaEvent("Registration ", "Click", "Register New Twitter Profile", true);

        if (queryResults.twitterUser.hasErrors === true) {
          throw new Error(
            "Could not retrieve Twitter access Token [JdySvzNSToSRbh7m0ml2UQ]"
          );
        }

        twitterUserToken = queryResults["twitterUser"].twitterUser;
        // A function to map the openId token with AWS credentials
        return import("../../authentication/cognito-client").then((module) => {
          return module.CognitoClient.getCognitoIdentityCredentialsForDeveloperIdentity(
            "twitter",
            {
              developerId: twitterUserToken.twitterUserId,
              identityId: twitterUserToken.id,
              openIdToken: twitterUserToken.openIdToken,
            }
          ).then((data: any) => {
            window.location.href = window.location.origin;
          });
        });
      })
      .catch((error: any) => {
        console.error(error);
        window.location.href = window.location.origin;
      });
  }

  /**
   * Returns the React node containing the rendered content.
   *
   * @returns {React.ReactNode} The rendered node.
   */
  render(): React.ReactNode {
    return <div />;
  }
}

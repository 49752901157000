/**
 * @author: Hadeer Elbanna
 * @copyright Copyright 2019 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-12-31 02:15:00
 * @description Implementation of the Ticker Tape component .
 * @filename ticker-tape.tsx
 */
import $ from "jquery";
import React from "react";
import LazyLoad from "react-lazyload";
import ProgressiveImage from "react-progressive-image";
import { fetchQuery } from "react-relay";
import Ticker from "react-ticker";
import { ENTITY_QUERY_TICKER_FEED } from "../../relay/queries/entity-query";
import { ENVIRONMENT } from "../../relay/relay-environment";
import { SVG } from "../../component-configuration/svgs";

/**
 *
 *
 */
const WING_TICKER = require("../../../static/ticker-logo-dark.jpg");

/**
 *  The props of the TickerTape component.
 *
 * @export
 * @interface
 */
interface TickerTapeProps {
  /**
   *
   *
   */
  entity: { id: string };
}

/**
 * The state of the Ticker Tape component.
 *
 * @export
 * @interface
 */
interface TickerTapeState {
  tickerItem: {
    /**
     *
     *
     * @type {string}
     */
    id: string;
    /**
     *
     *
     * @type {string}
     */
    title: string;
    /**
     *
     *
     * @type {string}
     */
    link: string;
  }[];

  /**
   *
   *
   * @type {boolean}
   * @memberof TickerTapeState
   */
  move: boolean;
  /**
   *
   *
   * @type {string}
   * @memberof TickerTapeState
   */
  landingPageUrl: string;
}

/**
 *  A React component that renders the Ticker Tape.
 *
 * @export
 * @class Ticker Tape
 * @extends {React.Component<TickerTapeProps, TickerTapeState>}
 */
export class TickerTape extends React.Component<
  TickerTapeProps,
  TickerTapeState
> {
  /**
   * Constructor
   *
   * @param {TickerTapeProps} props The component props.
   */
  constructor(props: TickerTapeProps) {
    super(props);

    this.state = {
      tickerItem: [],
      move: true,
      landingPageUrl: "",
    };
    this.closeTickerTape = this.closeTickerTape.bind(this);
    this.onWindowScroll = this.onWindowScroll.bind(this);
  }

  /**
   * Invoked by the React framework immediately before a component is mounted initially.
   */
  componentWillMount() {
    return fetchQuery(ENVIRONMENT, ENTITY_QUERY_TICKER_FEED, {
      id: this.props.entity.id,
    }).then((queryResults: any) => {
      const TODAY_FEED = queryResults.entity.entity.feed.feed[0];
      const LANDING_PAGE = queryResults.entity.entity.landingPageUrl;
      if (TODAY_FEED && TODAY_FEED.stories && TODAY_FEED.stories.length > 0) {
        this.setState({
          tickerItem: TODAY_FEED.stories.map((item) => {
            return {
              id: item.id,
              link: item.link,
              title: item.title,
            };
          }),
          landingPageUrl: LANDING_PAGE,
        });
      }
    });
  }

  onScroll(){
    $(".navbar").addClass("addTopMargin");
    fired = true;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll.bind(this));
  }
  /**
   * Invoked by the React framework immediately after a component is mounted.
   */
  componentDidMount() {
    $(".navbar").addClass("addTopMargin");
    let fired = false;
    window.addEventListener(
      "scroll",
      this.onScroll.bind(this),
      true
    );
  }

  /**
   * A function invoked by the event framework when the window scrolls.
   */
  onWindowScroll(): void {
    $(".navbar").addClass("addTopMargin");
  }

  /**
   * function to hide ticker tap
   */
  closeTickerTape() {
    $(".ticker-tape").hide();
    $(".navbar").removeClass("addTopMargin");
    window.removeEventListener("scroll", this.onWindowScroll);
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    const windowSize = window.innerWidth;
    return (
      <div>
        {this.state.tickerItem && this.state.tickerItem.length > 0 ? (
          <div className="ticker-tape fixed-top" id="ticker_tap">
            <a href={this.state.landingPageUrl}>
              <LazyLoad once={true}>
                <ProgressiveImage src={WING_TICKER} placeholder={WING_TICKER}>
                  {(src: any, LOADING: any, srcSetData: any) => (
                    <img
                      loading="lazy"
                      alt="ticker-tape-logo"
                      className="ticker-tape_image"
                      src={src}
                    />
                  )}
                </ProgressiveImage>
              </LazyLoad>
            </a>
            <Ticker
              direction="toLeft"
              offset="50%"
              move={this.state.move}
              speed={windowSize >= 1024 ? 12 : 8}
            >
              {(index) => (
                <h1
                  onMouseEnter={() => {
                    this.setState({ move: false });
                  }}
                  onMouseLeave={() => {
                    this.setState({ move: true });
                  }}
                >
                  {this.state.tickerItem && this.state.tickerItem.length > 0 ? (
                    this.state.tickerItem.map((item, index) => {
                      return (
                        <a href={item.link} target="_blank" key={index}>
                          {item.title}
                        </a>
                      );
                    })
                  ) : (
                    <a />
                  )}
                </h1>
              )}
            </Ticker>
            <div
              className="close-ticker-tape"
              onClick={() => this.closeTickerTape()}
            >
              {SVG.eyeClosed}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

/**
 * @author William Alexander Livesley
 * @date   2018-08-20
 * @description Declaration of the Poster interface.
 * @filename poster.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Comment } from "./comment";
import { Connection } from "./connection";
import { Node } from "./node";
import { Preview } from "./preview";
import { StoryRating } from "./story-rating";

/**
 * The __typeID of the GraphQL Poster type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_POSTER: string = "Poster";

/**
 * A poster - an poster displayed on the site, often to advertise site features or shows.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface Poster<D extends Date | string = string> extends Node<D> {
  /**
   * A description of the actions associated with the poster.
   *
   * @type {Array<string>}
   */
  actionDescription?: string;

  /**
   * A title for the actions associated with the poster.
   *
   * @type {Array<string>}
   */
  actionTitle?: string;

  /**
   * A page in the collection of comments for a given poster with given properties and in a given order.
   *
   * @type {Connection<D, StoryReview<D>>}
   */
  comments?: Connection<D, Comment<D>>;

  /**
   * A description of the poster. This is metadata.
   *
   * @type {Array<string>}
   */
  description?: string;

  /**
   * Activity status of the story.
   *
   * @type {boolean}
   */
  isActive?: boolean;

  /**
   * Predefined value by content management.
   *
   * @type {boolean}
   */
  isNew?: boolean;

  /**
   * A label for the poster.
   *
   * @type {string}
   */
  label?: string;

  /**
   *
   * The permalink of the poster in the form of a relative path adn query parameters.
   *
   * @type {string}
   * @memberof Poster
   */
  permalink?:string ;


  /**
   * The media assets used as previews for the poster.
   * 
   * @template D
   * @type {Connection<D, Preview>}
   */
  previews?: Connection<D, Preview>;

  /**
   * The date when the poster was published as a ISO-8601 formatted UTC date string
   *
   * @type {string}
   */
  publishedDate?: string;


  

  /**
   * The rating information for the story
   *
   * @type {StoryRating}
   */
  rating?: StoryRating;

  /**
   * The sub-title of the poster.
   *
   * @type {string}
   */
  subTitle?: string;

  /**
   * The title of the poster.
   *
   * @type {string}
   */
  title?: string;
}

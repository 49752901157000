/**
 * @author William Alexander Livesley
 * @date   2018-04-08
 * @description Declaration of the MediaAsset interface.
 * @filename media-asset.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Connection } from "./connection";
import { MediaAssetFile } from "./media-asset-file";
import { MediaAssetState } from "./media-asset-state";
import { Node } from "./node";
import { Preview } from "./preview";
import { TopLevelMediaType } from "./top-level-media-type";

/**
 * The __typeID of the GraphQL Media Asset object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_MEDIA_ASSET: string = "MediaAsset";

/**
 * A media asset; a video, audio file, text file or image in different formats to be delivered on different platforms.
 *
 * @template D
 * @interface
 */
export interface MediaAsset<D extends Date | string = string> extends Node<D> {
  /**
   * The originator of the media asset.
   *
   * @type {string}
   */
  attribution?: string;

  /**
   * A caption associated with the media asset.
   *
   * @type {string}
   */
  caption?: string;

  /**
   * A description of the media asset. Metadata.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The duration of the media asset in milliseconds. This is only shown for streaming media.
   *
   * @type {number}
   */
  durationInMilliseconds?: number;

  /**
   * The collection of media asset files associated with the media asset.
   *
   * @type {Array<MediaAssetFile>}
   */
  files?: MediaAssetFile[];

  /**
   * A label associated with media asset.
   *
   * @type {string}
   */
  label?: string;

  /**
   * The landing page associated with media asset.
   *
   * @type {string}
   */
  landingPageUrl?: string;

  /**
   * The collection of previews for with the media asset.
   *
   * @type {Connection<string, Preview>}
   */
  previews?: Connection<string, Preview>;

  /**
   * The state of the media asset.
   *
   * @type {MediaAssetState}
   */
  state?: MediaAssetState;

  /**
   * A title for the media assets.
   *
   * @type {string}
   */
  title?: string;

  /**
   * The top level media type of the media asset.
   *
   * @type {TopLevelMediaType}
   */
  topLevelMediaType?: TopLevelMediaType;
}

export const aliasCache = {
  "pitchplanet-": {
    urlRouteByAlias: {
      hasErrors: false,
      errors: null,
      node: {
        id: "f15aXXzOsGEqVJ8YSmlDXsw",
        panels: [
          {
            component: {
              id: "J0GwzkKsdkcs5vt7tw9Ij",
              key: "LoggedInNavbar",
              properties: null,
            },
            properties: null,
          },
          {
            component: {
              id: "EQbzUHM0RQafgw7sX73nxw",
              key: "PitchPanel",
              properties: null,
            },
            properties: [
              {
                name: "json",
                article: null,
                book: null,
                boolean: null,
                dynamicList: null,
                entity: null,
                fixedList: null,
                id: null,
                int: null,
                json: '{"sourceId":"mFrJCxiHQESBl-vkUjMH2g","viewMode":"GRID","showBtn":true }',
                listCollection: null,
                newsletter: null,
                number: null,
                onlineCourse: null,
                originalContent: null,
                person: null,
                podcast: null,
                poster: null,
                string: null,
              },
            ],
          },
          {
            component: {
              id: "WnQrbnTITU2VwR0hR3qZ8A",
              key: "Footer",
              properties: null,
            },
            properties: null,
          },
        ],
      },
    },
  },
};

import * as React from "react";
import { CREATE_COMPANY_MODAL_ID } from "../redux/create-company/constants";
import { RdvButton } from "../../../react-components/page-framework/rdv-button";
import { SVG } from "../../../component-configuration/svgs";
import $ from "jquery";

export const CreateCompanyButton = () => {
  return (
    <div id="add-post-button">
      <button
        className={`add-new-post-button add-company-button`}
        onClick={() => {
          $(`#${CREATE_COMPANY_MODAL_ID}`).modal("show");
        }}
      >
        <span>{SVG.plusAlt}</span>
        <div className="button-text">
          <span>Create</span>
          <span>Company Page</span>
        </div>
      </button>
    </div>
  );
};

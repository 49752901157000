/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type twitterRequestTokenQueryVariables = {};
export type twitterRequestTokenQueryResponse = {
    readonly twitter: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly twitter: {
            readonly oauthToken: string | null;
            readonly oauthTokenSecret: string | null;
            readonly oauthCallbackConfirmed: string | null;
        } | null;
    } | null;
};
export type twitterRequestTokenQuery = {
    readonly response: twitterRequestTokenQueryResponse;
    readonly variables: twitterRequestTokenQueryVariables;
};



/*
query twitterRequestTokenQuery {
  twitter {
    hasErrors
    errors {
      id
      location
    }
    twitter {
      oauthToken
      oauthTokenSecret
      oauthCallbackConfirmed
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "twitter",
    "storageKey": null,
    "args": null,
    "concreteType": "TwitterPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "twitter",
        "storageKey": null,
        "args": null,
        "concreteType": "Twitter",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "oauthToken",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "oauthTokenSecret",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "oauthCallbackConfirmed",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "twitterRequestTokenQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "twitterRequestTokenQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "twitterRequestTokenQuery",
    "id": null,
    "text": "query twitterRequestTokenQuery {\n  twitter {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    twitter {\n      oauthToken\n      oauthTokenSecret\n      oauthCallbackConfirmed\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'e99c0d502b66c17240c094c39cf8384d';
export default node;

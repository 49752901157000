import * as React from "react";
import ProgressiveImage from "react-progressive-image";
import { useCarouselContext } from "./context";
import { Router } from "../../../utilities/router";

interface Props {
  view: "PRE-ITEM" | "POST-ITEM";
}

export const ShowDetails = (props: Props): JSX.Element => {
  const { view } = props;

  const goToSheconomics = React.useCallback(
    (title) => (e) => {
      e.preventDefault();
      if (title === "The Sheconomics") {
        Router.navigateTo("sheconomics");
      }
    },
    [],
  );

  const {
    show,
    listDetailsView,
    campaignPreview,
    displaySubtitleFlag,
    json,
    displayListDescriptionFlag,
  } = useCarouselContext();
  if (listDetailsView !== view) return null;

  const alignedRight = listDetailsView === "POST-ITEM" ? "align-right" : "";
  const isMaster = json?.campaign === "MASTERS";
  const titleWords = show?.title?.trim().split(" ");
  const [w1, w2, ...wrest] = Array.isArray(titleWords) ? titleWords : [];
  const title1 = `${w1} ${w2}`;
  const title2 = Array.isArray(wrest) ? wrest.join(" ") : wrest;
  return (
    <div className={`show-details ${alignedRight}`}>
      {show && !isMaster && show.logo ? (
        <ProgressiveImage
          src={
            show.logo.requestedResolutionUrl !== undefined && show.logo.requestedResolutionUrl !== null
              ? show.logo.requestedResolutionUrl
              : show.logo.screenResolutionUrl
          }
          placeholder={show.logo.requestedResolutionUrl}
        >
          {(src: any, _LOADING: any, _srcSetData: any) => (
            <img
              loading="lazy"
              alt={`${show.title ? show.title : ""} logo`}
              className={`logo-img ${show.title === "The Sheconomics" ? "she" : ""} `}
              src={src}
              onClick={show.title === "The Sheconomics" ? goToSheconomics(show.title) : null}
              // onClick={this.handleShowLandingPage}
            />
          )}
        </ProgressiveImage>
      ) : show && show.title ? (
        <div>
          <h3 className="panel-title">
            {isMaster && (
              <ProgressiveImage
                src={show.logo?.requestedResolutionUrl || show.logo?.screenResolutionUrl}
                placeholder={show.logo?.requestedResolutionUrl}
              >
                {(src: any) => (
                  <a
                    className="masters-logo"
                    onClick={() => (window.location.href = show.landingPageUrl)}
                    style={{ backgroundImage: `url('${src}')` }}
                  />
                )}
              </ProgressiveImage>
            )}
            {campaignPreview ? (
              <ProgressiveImage
                src={
                  campaignPreview.requestedResolutionUrl !== undefined &&
                  campaignPreview.requestedResolutionUrl !== null
                    ? campaignPreview.requestedResolutionUrl
                    : campaignPreview.screenResolutionUrl
                }
                placeholder={campaignPreview.requestedResolutionUrl}
              >
                {(src: any, LOADING: any, srcSetData: any) => <img loading="lazy" className="compain-logo" src={src} />}
              </ProgressiveImage>
            ) : null}
            {isMaster ? (
              <div className="masters-title">
                <span className="spotlight">{title1}</span>
                <span>{title2}</span>
              </div>
            ) : (
              <div>{show.title}</div>
            )}
          </h3>
        </div>
      ) : (
        <span className="title-placeholder loading-gradient" />
      )}
      {show && show.description && (displaySubtitleFlag || displayListDescriptionFlag) ? (
        <h6 className="subtitle">{show.subTitle || show.description} </h6>
      ) : displaySubtitleFlag || displayListDescriptionFlag ? (
        <span className="description-placeholder">
          <span className="loading-gradient" />
          <span className="loading-gradient" />
        </span>
      ) : null}
      <div className="awards">
        {show && show.badges !== undefined && show.badges !== null ? (
          show.badges.map((badge, index) => {
            return badge !== null && badge !== undefined ? (
              <a href={badge.link} key={index} target="_blank">
                <ProgressiveImage
                  src={
                    badge.imgUrl.requestedResolutionUrl !== undefined && badge.imgUrl.requestedResolutionUrl !== null
                      ? badge.imgUrl.requestedResolutionUrl
                      : badge.imgUrl.screenResolutionUrl
                  }
                  placeholder={badge.imgUrl.placeHolderUrl}
                  key={`badge-${index.toString()}`}
                >
                  {(src: any, _LOADING: any, _srcSetData: any) => (
                    <img loading="lazy" alt={`Badge ${index}`} className="award" key={index} src={src} />
                  )}
                </ProgressiveImage>
              </a>
            ) : (
              undefined
            );
          })
        ) : show && !show.title ? (
          <div className="badges-placeholder">
            <span className="loading-gradient" />
            <span className="loading-gradient" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

import { Reducer } from "redux";
import produce from "immer";
import {
  AccountAction,
  AccountState,
  AddSaveItemLoadingPayload,
  RemoveFromSavedItemsPayload,
  RemoveFromUserRequestsPayload,
  RemoveSaveItemLoadingPayload,
  UpdateSavedItemsPayload,
  UpdateUserPayload,
  UpdateUserRequestsPayload,
  UpdateUserMyListSuccessPayload,
  SetSettingsMenuPayload,
  DeactivateAccountFulfilledPayload,
  SetAccountIsLoadingPayload,
  SetProfileActiveTabPayload,
} from "./types";
import * as constants from "./constants";
import isEmpty from "lodash.isempty";

export const initialState: AccountState = {
  user: null,
  savedItems: null,
  saveItemLoading: [],
  currentSettingsMenu: null,
  profileActiveTab: null,
  pitchPlanetFilter: {},
  isLoading: false,
};

export const AccountReducer: Reducer<AccountState, AccountAction> = (
  state: AccountState = initialState,
  action: AccountAction,
) => {
  switch (action.type) {
    case constants.UPDATE_USER: {
      return {
        ...state,
        user: (action.payload as UpdateUserPayload).user,
      };
    }
    case constants.UPDATE_USER_REVISION: {
      return {
        ...state,
        user: {
          ...state.user,
          revision: action.payload.revision,
        },
      };
    }
    case constants.SET_PITCH_PLANET_FILTER: {
      const { key, value } = action.payload;
      const filter = state.pitchPlanetFilter[key] || [];
      const updatedFilter = filter.includes(value) ? filter.filter((f) => f !== value) : [...filter, value];
      return {
        ...state,
        pitchPlanetFilter: {
          ...state.pitchPlanetFilter,
          [key]: updatedFilter,
        },
      };
    }
    case constants.UPDATE_SAVED_ITEMS: {
      return {
        ...state,
        savedItems: (action.payload as UpdateSavedItemsPayload).savedItems,
      };
    }
    case constants.REMOVE_FROM_SAVED_ITEMS: {
      const resourceId = (action.payload as RemoveFromSavedItemsPayload).resourceId;
      if (!state.savedItems) return state;
      return produce(state, (draft: AccountState) => {
        draft.savedItems.items.edges = state.savedItems?.items.edges.filter(
          (edge) => edge.listItem.item.id !== resourceId,
        );
      });
    }
    case constants.REMOVE_FROM_USER_REQUESTS: {
      const requestId = (action.payload as RemoveFromUserRequestsPayload).id;
      if (!state.user) return state;
      return produce(state, (draft: AccountState) => {
        draft.user.requests = state.user?.requests.filter((request) => request.id !== requestId);
      });
    }
    case constants.UPDATE_USER_REQUESTS: {
      const { request } = action.payload as UpdateUserRequestsPayload;
      if (!state.user) return state;
      const updateExistingRequest =
        Array.isArray(state.user.requests) && state.user.requests.find((r: any) => r.id === request.id);
      if (updateExistingRequest) {
        return produce(state, (draft: AccountState) => {
          const updatedRequests = state.user?.requests.map((req: any) => (req.id !== request.id ? req : request));
          draft.user.requests = updatedRequests;
        });
      } else {
        return produce(state, (draft: AccountState) => {
          draft.user.requests = Array.isArray(state.user.requests) ? [...state.user.requests, request] : [request];
        });
      }
    }
    case constants.DEACTIVATE_ACCOUNT_FULFILLED: {
      const { userRequest } = action.payload as DeactivateAccountFulfilledPayload;
      if (!state.user) return state;
      const updateExistingRequest =
        Array.isArray(state.user.requests) && state.user.requests.find((r: any) => r.id === userRequest.id);
      if (updateExistingRequest) {
        return produce(state, (draft: AccountState) => {
          const updatedRequests = state.user?.requests.map((req: any) =>
            req.id !== userRequest.id ? req : userRequest,
          );
          draft.user.requests = updatedRequests;
        });
      } else {
        return produce(state, (draft: AccountState) => {
          draft.user.requests = Array.isArray(state.user.requests)
            ? [...state.user.requests, userRequest]
            : [userRequest];
        });
      }
    }
    case constants.UPDATE_USER_MY_LIST_SUCCESS: {
      const listId = (action.payload as UpdateUserMyListSuccessPayload).listId;
      return {
        ...state,
        user: {
          ...state.user,
          myList: listId,
        },
      };
    }
    case constants.ADD_SAVE_ITEM_LOADING: {
      const itemId = (action.payload as AddSaveItemLoadingPayload).itemId;
      return {
        ...state,
        saveItemLoading: [...state.saveItemLoading, itemId].filter(onlyUniq),
      };
    }
    case constants.REMOVE_SAVE_ITEM_LOADING: {
      const itemId = (action.payload as RemoveSaveItemLoadingPayload).itemId;
      return {
        ...state,
        saveItemLoading: state.saveItemLoading.filter((id) => id !== itemId),
      };
    }
    case constants.SET_SETTINGS_MENU: {
      return {
        ...state,
        currentSettingsMenu: (action.payload as SetSettingsMenuPayload).menu,
      };
    }
    case constants.SET_ACCOUNT_IS_LOADING: {
      const isLoading = (action.payload as SetAccountIsLoadingPayload).status;
      return {
        ...state,
        isLoading,
      };
    }
    case constants.SET_PROFILE_ACTIVE_TAB: {
      const profileActiveTab = (action.payload as SetProfileActiveTabPayload).activeTab;
      return {
        ...state,
        profileActiveTab,
      };
    }
    default:
      return state;
  }
};

const onlyUniq = (value: string, index: number, self: any[]) => {
  return self.indexOf(value) === index;
};

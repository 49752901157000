import * as React from "react";

export const eyeClosedIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
    <g>
      <title>Layer 1</title>
      <path
        stroke="null"
        id="svg_1"
        d="m22.92029,10.88916c0.28355,-0.5671 0.09452,-1.22873 -0.47259,-1.51228s-1.22873,-0.09452 -1.51228,0.47259c-1.79583,3.21359 -5.19846,5.19846 -8.88464,5.19846s-7.08881,-1.98487 -8.88464,-5.19846c-0.28355,-0.5671 -1.03969,-0.75614 -1.51228,-0.47259c-0.66162,0.28355 -0.85066,0.94517 -0.5671,1.51228c0.75614,1.41776 1.89035,2.64649 3.11907,3.68618l-0.75614,0.75614c-0.47259,0.47259 -0.47259,1.13421 0,1.6068c0.18903,0.18903 0.47259,0.37807 0.75614,0.37807s0.5671,-0.09452 0.75614,-0.37807l1.13421,-1.13421c1.41776,0.75614 3.02456,1.22873 4.72587,1.41776l0,1.22873c0,0.66162 0.47259,1.13421 1.13421,1.13421s1.13421,-0.47259 1.13421,-1.13421l0,-1.22873c1.70131,-0.18903 3.30811,-0.66162 4.72587,-1.41776l1.13421,1.13421c0.18903,0.18903 0.47259,0.37807 0.75614,0.37807s0.5671,-0.09452 0.75614,-0.37807c0.47259,-0.47259 0.47259,-1.13421 0,-1.6068l-0.75614,-0.75614c1.32324,-1.03969 2.36294,-2.26842 3.21359,-3.68618z"
      />
    </g>
  </svg>
);

export const eyeOpenedIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
    <g>
      <path
        transform="rotate(-180 12.0049 8.07632)"
        stroke="transparent"
        fill="currentColor"
        d="m22.92029,4.54979c0.28355,-0.5671 0.09452,-1.22873 -0.47259,-1.51228s-1.22873,-0.09452 -1.51228,0.47259c-1.79583,3.21359 -5.19846,5.19846 -8.88464,5.19846s-7.08881,-1.98487 -8.88464,-5.19846c-0.28355,-0.5671 -1.03969,-0.75614 -1.51228,-0.47259c-0.66162,0.28355 -0.85066,0.94517 -0.5671,1.51228c0.75614,1.41776 1.89035,2.64649 3.11907,3.68618l-0.75614,0.75614c-0.47259,0.47259 -0.47259,1.13421 0,1.6068c0.18903,0.18903 0.47259,0.37807 0.75614,0.37807s0.5671,-0.09452 0.75614,-0.37807l1.13421,-1.13421c1.41776,0.75614 3.02456,1.22873 4.72587,1.41776l0,1.22873c0,0.66162 0.47259,1.13421 1.13421,1.13421s1.13421,-0.47259 1.13421,-1.13421l0,-1.22873c1.70131,-0.18903 3.30811,-0.66162 4.72587,-1.41776l1.13421,1.13421c0.18903,0.18903 0.47259,0.37807 0.75614,0.37807s0.5671,-0.09452 0.75614,-0.37807c0.47259,-0.47259 0.47259,-1.13421 0,-1.6068l-0.75614,-0.75614c1.32324,-1.03969 2.36294,-2.26842 3.21359,-3.68618z"
      />
      <ellipse
        strokeWidth="2"
        fill="transparent"
        stroke="currentColor"
        ry="2.50696"
        rx="2.50696"
        cy="12.41783"
        cx="12.13928"
      />
      <path
        stroke="transparent"
        fill="currentColor"
        d="m22.92029,13.16849c0.28355,-0.5671 0.09452,-1.22872 -0.47259,-1.51228s-1.22872,-0.09451 -1.51227,0.47259c-1.79583,3.21359 -5.19846,5.19846 -8.88464,5.19846s-7.08881,-1.98487 -8.88464,-5.19846c-0.28355,-0.5671 -1.03969,-0.75614 -1.51228,-0.47259c-0.66162,0.28356 -0.85065,0.94518 -0.5671,1.51228c0.75614,1.41776 1.89035,2.64649 3.11907,3.68618l1.89035,1.22873c1.41776,0.75614 3.02456,1.22873 4.72587,1.41776l2.26842,0c1.70131,-0.18903 3.30811,-0.66162 4.72587,-1.41776l1.89035,-1.22873c1.32324,-1.03969 2.36294,-2.26842 3.21359,-3.68618z"
      />
    </g>
  </svg>
);

export const closeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor">
    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
  </svg>
);


/**
 * @author Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-07-25
 * @description Implementation of user context to store user data in the react Context.
 * @filename user-context.ts
 */
import { FixedList } from "@radivision/graphql/lib/ts/graphql/fixed-list";
import { Person } from "@radivision/graphql/lib/ts/graphql/person";
import React from "react";

/**
 * The context holding logged-in user data.
 *
 * @const
 * @type {React.Context<{Person, function(user:Person):void}>}
 * user: the logged-in user object
 * updateUser: function to update logged-in user object.
 */
export const REACT_USER_CONTEXT: React.Context<{
  user: Person;
  userSavedItems: FixedList;
  updateUser: (user: Person) => any;
  updateSavedItemsList: (list: FixedList) => any;
}> = React.createContext({
  user: null,
  userSavedItems: null,
  // tslint:disable-next-line: no-empty
  updateUser: (user: Person) => {},
  updateSavedItemsList: (list: FixedList) => {}
});

/**
 * @author W. Alex Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2018-03-30
 * @description A class containing the error returned from a request to a GraphQL server where the request contains 
 * schema validation errors.
 * @filename schema-validation-error.ts
 */
import { Error } from "./error";

/**
 * The error returned from a request to a GraphQL server where the request contains schema validation errors.
 *
 * @extends {Error}
 */
export class SchemaValidationError<V> extends Error {
  /**
   * The type ID of the error.
   *
   * @type {string}
   */
  public static readonly TYPE_ID: string = "DsKP2v816kKZt9ImkLtIFg";

  /**
   * A parametric constructor.
   *
   * @param {string} location The location where the error was thrown.
   *
   * @param {YUP.ValidationError} _validation The details of the validation errors.
   *
   * @param {string} id The ID of the error.
   */
  public constructor(
    location:string,
    private _validation: V,
    id: string = null
  ) {
    super(location, id, 400, "Bad Request");
  }

  /**
   * Returns the details of the validation errors.
   * 
   * @template V
   * @type {V}
   */
  public validation(): V {
    return this._validation;
  }

  /**
   * The type of the error - a general classification of the error.
   *
   * @type {string}
   */
  public get type(): string {
    return SchemaValidationError.TYPE_ID;
  }
}

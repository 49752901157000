/**
 * @author William Alexander Livesley
 * @date   2018-03-16
 * @description Declaration of the Edge interface.
 * @filename edge.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the Edge GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_EDGE: string = "Edge";

/**
 * An edge for a components connection including the component.
 *
 * @interface
 * @template D, N
 */
export interface Edge<
  D extends Date | string = string,
  N extends Node<D> = Node<D>
> {
  /**
   * The cursor uniquely identifying the edge in the connection.
   *
   * @type {string}
   */
  cursor?: string;

  /**
   * The node in the edge.
   *
   * @template N
   * @type {N}
   */
  node?: N;

  /**
   * The typename of the edge.
   *
   * @type {string}
   */
  __typename?: string;
}

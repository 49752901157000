import { takeEvery, put, call } from "redux-saga/effects";
import { preparePhotoFile } from "../../../../../utilities/image-uploader";
import { SetProfilePhotoAction } from "../types";
import { setProfileState } from "../actions";
import { SET_PROFILE_PHOTO } from "../constants";
import { PreviewKind } from "@radivision/graphql";

function* setProfilePhotoWorker(action: SetProfilePhotoAction) {
  try {
    const { file, type, previewUrl } = action.payload;
    const key = type === PreviewKind.BACKGROUND ? "profileBackground" : "profileImage";
    if (previewUrl) {
      yield put(setProfileState({ [`${key}Url`]: previewUrl, [key]: null }));
    }
    if (file) {
      const payload = yield call(preparePhotoFile, file);
      const backgroundAssetId = PreviewKind.BACKGROUND ? { backgroundAssetId: null } : {};
      yield put(setProfileState({ [key]: payload.file, [`${key}Url`]: null, ...backgroundAssetId }));
    }
  } catch (errors) {}
}

export function* watchSetProfilePhoto() {
  yield takeEvery(SET_PROFILE_PHOTO, setProfilePhotoWorker);
}

import { graphql } from "react-relay";

export const LIST_ITEM_ENTITY_QUERY = graphql`
  query listItemEntityQuery($id: ID!) {
    entity(id: $id) {
      hasErrors
      entity {
        __typename
        description
        landingPageUrl
        id
        name
        previews {
          count
          edges {
            cursor
            preview {
              link
              kind
              mediaAsset {
                id
                files {
                  id
                  name
                  path
                }
                topLevelMediaType {
                  kind
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;

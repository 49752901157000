/**
 * @author Ahmed Serag
 * @date   2018-09-24
 * @description Declaration of the SupportIssue interface.
 * @filename support-issue.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";
import { Person } from "./person";
import { SupportIssueType } from "./support-issue-type";

/**
 * The __typeID of the GraphQL SupportIssue type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_SUPPORT_ISSUE: string = "SupportIssue";

/**
 * A support issue
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface SupportIssue<D extends Date | string = string> extends Node<D> {
  
  /**
   * The browser and/or device associated with the support issue.
   *
   * @type {string}
   * @memberof SupportIssue
   */
  browserAndDeviceType?: string;

  /**
   * A description of the support issue.
   *
   * @type {string}
   * @memberof SupportIssue
   */
  description?: string;

  /**
   * The email address of the originator of the support issue.
   *
   * @type {String}
   * @memberof SupportIssue
   */
  email?: String;

  /**
   * The timestamp when the support issue was created as an ISO-8601 formatted UTC date string.
   *
   * @type {string}
   * @memberof SupportIssue
   */
  lastReportAttempt?: string;

  /**
   * The name of the originator of the support issue.
   *
   * @type {string}
   * @memberof SupportIssue
   */
  name?: string;

  /**
   * The originator of the support issue.
   *
   * @type {Person}
   * @memberof SupportIssue
   */
  person?: Person;

  /**
   * The type of the support issue
   *
   * @type {SupportIssueType}
   * @memberof SupportIssue
   */
  type?: SupportIssueType;
}

import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import { SVG } from "../../../component-configuration/svgs";
import { SetAuthStatePayload, SetAuthStateAction } from "../../../redux/authentication/types";
import { FormFieldContainer, FormFieldInput } from "../modal-form-elements";
import { eyeClosedIcon, eyeOpenedIcon } from "../icons";

interface ComponentProps {
  setIsValid: (b: boolean) => void;
}

interface MapDispatchProps {
  setAuthState: (paylaod: SetAuthStatePayload) => SetAuthStateAction;
}

interface MapStateProps {
  password: string;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

const atLeastEight = (password: string) => password.length >= 8;
const oneLower = (password: string) => /[a-z]/.test(password);
const oneUpper = (password: string) => /[A-Z]/.test(password);
const oneSpecial = (password: string) => /[^a-zA-Z\d\s]/.test(password);
const oneNumber = (password: string) => /[0-9]/.test(password);

const validations = [
  { title: "At least 8 characters", fun: atLeastEight },
  { title: "One lower case character", fun: oneLower },
  { title: "One upper case character", fun: oneUpper },
  { title: "One number", fun: oneNumber },
  { title: "One special character", fun: oneSpecial },
];

export const Component = (props: Props) => {
  const { password, setAuthState, setIsValid } = props;

  const [hiddenPassword, setHiddenPassword] = React.useState(true);

  const hidePassword = React.useCallback(() => {
    setHiddenPassword(!hiddenPassword);
  }, [hiddenPassword]);

  React.useEffect(() => {
    const passed = validations.every(({ fun }) => fun(password));
    setIsValid(passed);
  }, [password, setIsValid]);

  return (
    <Container>
      <FormFieldContainer>
        <FormFieldInput withIcon rightAligned>
          <span onClick={hidePassword}>{hiddenPassword ? eyeClosedIcon : eyeOpenedIcon}</span>
          <input
            type={hiddenPassword ? "password" : "text"}
            autoFocus={true}
            className="form-control"
            placeholder="Password"
            required={true}
            disabled={false}
            value={password}
            onChange={(e) => setAuthState({ password: e.target.value })}
          />
        </FormFieldInput>
      </FormFieldContainer>
      <div className="validations-rules">
        <ul>
          {validations.map(({ title, fun }) => {
            const valid = fun(password);
            return (
              <li key={title} className={valid ? "valid-rule" : ""}>
                <span className="icon">{valid ? SVG.checkmark : null}</span>
                <span className="rule-name">{title}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </Container>
  );
};

const Container = ({ children }) => {
  return <div className="validated-password-input">{children}</div>;
};

const mapState = (state: AppState) => ({
  step: state.authentication.step,
  email: state.authentication.email,
  password: state.authentication.password,
  verificationCode: state.authentication.verificationCode,
  inProgress: state.cache.inProgress["reset-password"],
});

const mapDispatch = {
  setAuthState: Actions.setAuthState,
  confirmResetPassword: Actions.confirmResetPassword,
};

export const Password = connect(mapState, mapDispatch)(Component);

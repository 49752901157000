import * as React from "react";
import { AboutProfile } from "../../../react-components/inner-pages/profile-details-tab/about/about-profile";
import { Entity } from "@radivision/graphql/lib/ts/graphql/entity";

interface ComponentProps {
  entity: Entity;
}

export type Props = ComponentProps;

export const About = (props: Props) => {
  const { entity } = props;
  return (
    <div className="about-section">
      <AboutProfile subject={entity} />
    </div>
  );
};

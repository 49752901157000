import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { Modal } from "../../../react-components/modals/modal";
import { ProfileModuleState } from "../redux/profile";
import { PageInfo } from "./page-info";
import { About } from "./about";
import { Links } from "./links";
import { ChangeBanner } from "./change-banner";
import {
  EDIT_PROFILE_PAGE_INFO,
  EDIT_PROFILE_ABOUT,
  EDIT_PROFILE_SOCIAL_LINKS,
  EDIT_PROFILE_OPPORTUNITIES,
} from "../redux/profile/constants";
import { SetProfileStatePayload, SetProfileStateAction } from "../redux/profile/types";
import { setProfileState } from "../redux/profile/actions";
import { Opportunities } from "./opportunities";
import { GRAPHQL_TYPE_ENTITY } from "@radivision/graphql";
import { HorizontalScroller } from "../../../react-components/page-framework/horizontal-scroller";
import "./tabs.scss";

interface MapDispatchProps {
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}
interface MapStateProps {
  screen: string;
  isCompany: boolean;
  currentHash: string | null;
  profileActiveTab: string | null;
  type: string | null;
}

export type Props = MapDispatchProps & MapStateProps;

export const EDIT_PROFILE_MODAL_ID = "edit-profile-modal";

export const Component = (props: Props) => {
  const { screen, currentHash, isCompany, setProfileState, profileActiveTab, type } = props;

  React.useEffect(() => {
    if (profileActiveTab === "About" && !screen) {
      setProfileState({ screen: EDIT_PROFILE_ABOUT });
    } else if (profileActiveTab === "Opportunities" && !screen) {
      setProfileState({ screen: EDIT_PROFILE_OPPORTUNITIES });
    }
  }, [profileActiveTab, setProfileState, screen]);

  const TABS = [
    { id: EDIT_PROFILE_PAGE_INFO, title: "Page Info" },
    { id: EDIT_PROFILE_ABOUT, title: "About" },
    { id: EDIT_PROFILE_SOCIAL_LINKS, title: "Links" },
    ...(isCompany ? [{ id: EDIT_PROFILE_OPPORTUNITIES, title: "Opportunities" }] : []),
  ];

  const currentTab = screen ? screen : EDIT_PROFILE_PAGE_INFO;
  return (
    <Modal id={EDIT_PROFILE_MODAL_ID} title={"Edit Profile"} className={`screen-${screen} form-modal`}>
      <div className={`content`} style={{ position: "relative", width: "100%" }}>
        {type ? (
          <HorizontalScroller rootClassName={"edit-profile-tabs"}>
            {TABS.map(({ id, title }) => (
              <div
                onClick={() => {
                  setProfileState({ screen: id });
                }}
                role="button"
                style={{}}
                tabIndex={0}
                className={`card ${id === currentTab ? "selected" : ""}`}
                key={id}
              >
                <span style={{ whiteSpace: "nowrap" }}>{title}</span>
              </div>
            ))}
          </HorizontalScroller>
        ) : null}
        <PageInfo />
        <About />
        <Links />
        <Opportunities />
        <ChangeBanner />
      </div>
    </Modal>
  );
};

const mapState = (state: AppState & ProfileModuleState) => ({
  screen: state.profile.screen,
  isCompany: state.profile.type === GRAPHQL_TYPE_ENTITY,
  currentHash: state.app.currentHash,
  profileActiveTab: state.account.profileActiveTab,
  type: state.profile.type,
});

const mapDispatch = {
  setProfileState: setProfileState,
};

export const EditProfileModal = connect(mapState, mapDispatch)(Component);

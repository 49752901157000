/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-01-21
 * @description Implementation of image handler utilities to be used in the app.
 * @filename imageHelper.ts
 */
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import { ENVIRONMENT } from "../relay/relay-environment";
import { MediaAsset, MEDIA_TYPE_TO_EXTENSION } from "./media-asset";
import { S3Helper } from "./s3";
import { getCleanUuid } from "./general";

/**
 *
 * @export
 * @interface CreateMediaAssetFileInput
 */
export interface MediaAssetFileFunctionInput {
  /**
   *
   *
   * @type {string}
   * @memberof CreateMediaAssetFileInput
   */
  attribution: string;
  /**
   *
   *
   * @type {string}
   * @memberof CreateMediaAssetFileInput
   */
  description: string;
  /**
   *
   *
   * @type {string}
   * @memberof CreateMediaAssetFileInput
   */
  fileName: string;
  /**
   *
   *
   * @type {string}
   * @memberof CreateMediaAssetFileInput
   */
  title: string;

  /**
   *
   *
   * @type {number}
   * @memberof MediaAssetFileFunctionInput
   */
  fileSize: number;

  /**
   *
   *
   * @type {string}
   * @memberof MediaAssetFileFunctionInput
   */
  format: string;

  /**
   * The top level media type of the media asset
   *
   * @type {TopLevelMediaKind}
   * @memberof MediaAssetFileFunctionInput
   */
  topLevelMediaType?: TopLevelMediaKind;
}

/**
 *
 *
 * @export
 * @class ImageUnloader
 */
export class ImageUpload {
  /**
   * Function to upload image binary content to s3 bucket
   *
   * @static
   * @param {{ fileBinaryContent: Blob;
   *     fileSize: number;
   *     fileName: string;
   *     mimeType: string;}} staticFileInformation
   * @returns Returns a promise containing a upload results
   * @memberof imageHelper
   */
  static uploadImageToS3(staticFileInformation: {
    fileBinaryContent: Blob;
    fileSize: number;
    fileName: string;
    mimeType: string;
  }): Promise<SendData> {
    let promise: Promise<SendData>;

    // attempt to validate image
    // size      : we validate size to prevent uploading corrupted files
    // mime-type : we validate mime-type to prevent uploading
    //             binary data without magic headers
    if (staticFileInformation.fileBinaryContent.size <= 0 || staticFileInformation.mimeType.length < 1) {
      promise = Promise.reject(new Error("[Y6fFw9NGaUmoyEotoGfBJQ] invalid image provided [mime-type/size]"));
    } else {
      // create a promise to handle s3 upload providing needed file data
      // file Binary content
      // generated file name
      // file mime-type
      promise = S3Helper.uploadToS3(staticFileInformation.fileBinaryContent, staticFileInformation.fileName, {
        ContentType: staticFileInformation.mimeType,
      });
    }
    return promise;
  }

  /**
   *
   * @static
   * @param {MediaAssetFileFunctionInput} inputParameters
   * @returns {Promise<any>}
   * @memberof ImageHelper
   */
  static createMediaAssetFile(inputParameters: MediaAssetFileFunctionInput): Promise<any> {
    let mediaAsset: MediaAsset;
    mediaAsset = new MediaAsset();

    return mediaAsset.createMediaAssetFromFile(ENVIRONMENT, inputParameters);
  }
}

export function dataUrlToBlob(file: PhotoFile) {
  const arr = file.dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export interface PhotoFile {
  dataUrl: string;
  fileSize: number;
  fileName: string;
  mimeType: string;
}

export function prepareUploadMediaPayload(file: PhotoFile) {
  const blob = dataUrlToBlob(file);
  const uploadMediaPayload = {
    fileBinaryContent: blob,
    fileSize: file.fileSize,
    fileName: file.fileName,
    mimeType: file.mimeType,
  };
  return uploadMediaPayload;
}

export function prepareMediaAssetPayload(file: PhotoFile) {
  const mediaAssetPayload: MediaAssetFileFunctionInput = {
    attribution: "TODO",
    description: "The media image",
    fileName: file.fileName,
    title: "Media image for story",
    fileSize: file.fileSize,
    format: file.mimeType,
  };
  return mediaAssetPayload;
}

export interface FilePayload {
  file: {
    dataUrl: string;
    fileName: string;
    mimeType: string;
    fileSize: number;
  };
}

export const preparePhotoFile = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject({ error: "No File is selected" });
    }
    const reader = new FileReader();
    reader.onloadend = (event) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => {
        const mimeType = "image/jpeg";
        const dataUrl = resize(img);
        const fileName = `${getCleanUuid()}.${MEDIA_TYPE_TO_EXTENSION[mimeType]}`;
        resolve({
          file: {
            dataUrl,
            fileName,
            mimeType,
            fileSize: atob(dataUrl.substr(23)).length,
          },
        });
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  });
};

function resize(img) {
  const canvas = document.createElement("canvas");
  const MAX_WIDTH = 800;
  const MAX_HEIGHT = 800;
  let width = img.naturalWidth;
  let height = img.naturalHeight;

  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width;
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }
  }
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height);
  const quality = 0.75;
  const mimeType = "image/jpeg";
  const dataUrl = ctx.canvas.toDataURL(mimeType, quality);
  return dataUrl;
}

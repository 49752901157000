/**
 * @author Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-12-19
 * @description Implementation of the GraphQL query to retrieve the list of mediaAssets.
 * @filename backgrounds-list-query.tsx
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL query to retrieve the current user.
 *
 * @type {GraphQLTaggedNode}
 */
export const MEDIA_ASSETS_LIST_QUERY: GraphQLTaggedNode = graphql`
  query mediaAssetsListQuery($id: ID!) {
    list(id: $id) {
      hasErrors
      list {
        ... on List {
          name
          label
          description
          title
          subTitle
          itemsTypes {
            __typename
          }
          items {
            count
            edges {
              listItem {
                item {
                  __typename
                  ... on MediaAsset {
                    created
                    description
                    durationInMilliseconds
                    files {
                      id
                      name
                      path
                    }
                    id
                    label
                    revision
                    topLevelMediaType {
                      kind
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

import React from "react";
import { connect } from "react-redux";
import { CarouselItem } from "../../component-configuration/carousel-item";
import { HomeGenericJson } from "../../component-configuration/home-generic-json";
import { getCleanUuid } from "../../utilities/general";
import { RdvCarousel } from "../lists/rdv-carousel";
import { FixedList } from "@radivision/graphql/lib/ts/graphql/fixed-list";
import { DynamicList } from "@radivision/graphql/lib/ts/graphql/dynamic-list";
import { Person } from "@radivision/graphql/lib/ts/graphql/person";
import { PreviewKind } from "@radivision/graphql/lib/ts/graphql/preview-type";
import { Show } from "../../component-configuration/show";
import { getRatioAndNumberOfItems, getCarouselProps } from "../lists/rdv-carousel/selectors";
import { FetchListPayload, FetchListAction } from "../../redux/cache/types";
import { useOnScreen } from "../../utilities/use-on-screen";
import { AppState } from "../../redux";
import * as Actions from "../../redux/actions";
import isEmpty from "lodash.isempty";

const LOAD = require("../../../static/placeholder.jpg");

interface CarouselPanelProps {
  fixedList?: { id: string };
  forceNumberOfItems?: number;
  json: HomeGenericJson;
  list?: FixedList | DynamicList;
  user?: Person;
}

interface MapStateProps {
  data: any;
}

interface MapDispatchProps {
  fetchList: (payload: FetchListPayload) => FetchListAction;
}

type Props = CarouselPanelProps & MapStateProps & MapDispatchProps;

const CarouselPanel = (props: Props) => {
  const { data, fetchList, forceNumberOfItems, json } = props;

  const list = data?.list?.list;
  const listId = getListId(props);

  const [skeleton, setSkeleton] = React.useState(null);
  const [carouselProps, setCarouselProps] = React.useState(null);

  React.useEffect(() => {
    const variables = { listId, count: 5 };
    if (!list) {
      fetchList({ variables });
    }
  }, [listId, list, fetchList]);

  React.useEffect(() => {
    if (!skeleton) {
      const { numberOfItems } = getRatioAndNumberOfItems({
        json,
        forceNumberOfItems,
      });

      const items: CarouselItem[] = [];

      // ... Staticky add 6 carousel items with only image
      for (let i = 0; i < numberOfItems; i++) {
        items.push({
          previewImageUrl: { originalUrl: LOAD, requestedResolutionUrl: LOAD },
          landingPageUrl: "",
          title: "",
        });
      }

      const tmpShow: Show = {
        items,
        id: getCleanUuid(),
        title: "",
        description: "",
        revision: "",
        landingPageUrl: "",
      };

      const skeletonCarousel = (
        <RdvCarousel
          key={`show-${getCleanUuid()}`}
          show={tmpShow}
          listDetailsView={json && json.listDetailsView ? json.listDetailsView : null}
          itemPreviewType={json && json.itemPreviewType ? json.itemPreviewType : PreviewKind.CAROUSEL}
          json={json}
          hideTitle={json && json.hideListTitle}
          displayListDescriptionFlag={json && (json.displayListDescriptionFlag || json.displaySubtitleFlag)}
          numberOfItems={numberOfItems}
          pageInfo={null}
        />
      );
      setSkeleton(skeletonCarousel);
    }
  }, [list, skeleton, forceNumberOfItems, json]);

  React.useEffect(() => {
    // Router.scrollTo();
    if (list) {
      const gotProps = getCarouselProps({
        show: list,
        json,
        forceNumberOfItems,
      });
      setCarouselProps(gotProps);
    }
  }, [list, listId, forceNumberOfItems, json]);

  return (
    <div
      id={
        list?.title
          ? list?.title.replace(/\s/g, "-").toLowerCase()
          : list?.name
          ? list?.name.replace(/\s/g, "-").toLowerCase()
          : list?.subTitle
          ? list?.subTitle.replace(/\s/g, "-").toLowerCase()
          : list?.id
      }
      className={`the-shows carousel-panel${json && json.displayMode === "GRID" ? " grid-carousel" : ""}`}
      data-tags={list && list.tags && list.tags.length ? list.tags.map((t: any) => t.label).join(",") : ""}
    >
      {list && carouselProps ? <RdvCarousel key={`show-${listId}`} {...carouselProps} json={json} /> : skeleton}
    </div>
  );
};

const getListId = (props: CarouselPanelProps) => props.list?.id || props.fixedList?.id || props.json?.sourceId;

const mapState = (state: AppState, props: CarouselPanelProps) => {
  const listId: string = getListId(props);
  const data = state.cache.store[listId];
  return {
    data,
  };
};

const mapDispatch = {
  fetchList: Actions.fetchList,
};

export const CarouselPanelContainer = connect(mapState, mapDispatch)(CarouselPanel);

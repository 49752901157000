import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import { SetAuthStatePayload, SetAuthStateAction } from "../../../redux/authentication/types";
import { AUTH_SIGNIN } from "../../../redux/authentication/constants";

interface ComponentProps {}

interface MapDispatchProps {
  setAuthState: (paylaod: SetAuthStatePayload) => SetAuthStateAction;
}

interface MapStateProps {}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { setAuthState } = props;

  return (
    <Container>
      Already have an account? <a onClick={() => setAuthState({ step: AUTH_SIGNIN })}>Log in</a>
    </Container>
  );
};

const Container = ({ children }) => {
  return <div className="dont-have-an-account">{children}</div>;
};

const mapState = (state: AppState) => ({});

const mapDispatch = {
  setAuthState: Actions.setAuthState,
};

export const AlreadyHaveAnAccount = connect(mapState, mapDispatch)(Component);

/**
 * @author Ahmed Samer
 * @copyright Copyright 2021 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2021-6-22
 * @description Implementation of the GraphQL mutation to create new payment.
 * @filename charge-payment-method.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to charge payment.
 *
 * @type {GraphQLTaggedNode}
 */
export const CHARGE_PAYMENT_METHOD_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation chargePaymentMethodMutation($input: CreatePaymentInput!) {
    chargePaymentMethod(input: $input) {
      hasErrors
      errors {
        id
        location
      }
      transaction {
        id
        created
        amount
        currency
        invoice
        status
      }
    }
  }
`;

/**
 * @Author: Nassar Sara
 * @Date:   2018-03-27T10:49:05+02:00
 * @Email:  sara@radivision.com
 * @Project: Radivision
 * @Filename: dynamic-list.ts
 * @Copyright: Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { List, ListItemType } from "./list";
import { TagsClause } from "./tags-clause";

/**
 * The __typeID of the Dynamic List GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_DYNAMIC_LIST: string = "DynamicList";

/**
 * The __typeID of the Filter expression GraphQL type.
 */
export const GRAPHQL_TYPE_FILTER_EXPRESSION = "FilterExpression";

/**
 * The __typeID of the Order expression GraphQL type.
 */
export const GRAPHQL_TYPE_ORDER_EXPRESSION = "OrderExpression";

/**
 * An expression to filter those entities, people and stories included in the dynamic list.
 *
 * @export
 * @interface FilterExpression
 */
export interface FilterExpression {
  /**
   * The expression to identify items in the list.
   *
   * @type {string}
   * @memberof FilterExpression
   */
  expression?: string;

  /**
   * A clause identifying tags used to select list items.
   *
   * @type {TagsClause}
   * @memberof FilterExpression
   */
  tags?: TagsClause;

  __typename: string;
}

/**
 * An order expression to order those entities, people, and stories included in the dynamic list.
 *
 * @export
 * @interface OrderExpression
 */
export interface OrderExpression {

  /**
   * An edge for a list items connection including the entities, person or story.
   *
   * @type {string}
   * @memberof OrderExpression
   */
  attribute?: string;

  /**
   * A flag which is true if the attribute values are in descending order.
   *
   * @type {boolean}
   * @memberof OrderExpression
   */
  descending?: boolean;

  __typename: string;
}

/**
 * A set of entities, people and stories selected manually. This may be ordered or
 * unordered
 *
 * @template D, L
 * @extends {List<D, L>}
 * @interface
 */
export interface DynamicList<
  D extends Date | string = string,
  L extends ListItemType<D> = ListItemType<D>
> extends List<D> {

  /**
   * The expressions for the default order of those stories included in the dynamic list.
   *
   * @type {Array<OrderExpression>}
   * @memberof DynamicList
   */
  defaultOrder?: OrderExpression[];

  /**
   * The expression which limits the list items included in the dynamic list.
   *
   * @type {FilterExpression}
   * @memberof DynamicList
   */
  filter?: FilterExpression;
}

import { EventsReducer } from "./reducer";
import { EventsModuleState } from "./types";
import sagas from "./saga";

export { EventsModuleState };

export const eventsModule = {
  id: "events",
  reducerMap: {
    events: EventsReducer,
  },
  sagas: [sagas],
};

import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { FullName } from "../profile/full-name";
import { Responsibility } from "../profile/responsibility";
import { ProfileModuleState } from "../redux/profile";
import { ModalFooter, Submit, Cancel } from "../../../react-components/modals/modal-form-elements";
import { EDIT_PROFILE_PAGE_INFO } from "../redux/profile/constants";
import { TagLine } from "../profile/tagline";
import { WebsiteUrl } from "../profile/website-url";
import { submitProfile } from "../redux/profile/actions";
import { SubmitProfileAction } from "../redux/profile/types";
import { Name } from "../profile/name";
import { ProfileImages } from "../profile/profile-images";
import {GRAPHQL_TYPE_ENTITY} from "@radivision/graphql";

interface ComponentProps {}

interface MapDispatchProps {
  submitProfile: () => SubmitProfileAction;
}

interface MapStateProps {
  screen: string;
  type: string;
  inProgress: boolean;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { screen, type, submitProfile, inProgress } = props;
  const shouldDisplay = screen === EDIT_PROFILE_PAGE_INFO;
  const isCompany = type === GRAPHQL_TYPE_ENTITY;

  const onSubmit = React.useCallback(() => {
    submitProfile();
  }, [submitProfile]);

  const isValid = true;

  if (!shouldDisplay) return null;

  return (
    <Container>
      <div className="form-body">
        <ProfileImages />
        <FullName />
        <Name />
        <Responsibility />
        <WebsiteUrl isRequired={isCompany}/>
        <TagLine />
      </div>
      <ModalFooter>
        <Cancel />
        <Submit
          onClick={onSubmit}
          disabled={false}
          className={isValid ? `` : "disabled"}
          isSubmitting={inProgress}
          title="Save"
        />
      </ModalFooter>
    </Container>
  );
};

export const Container = ({ children }: React.PropsWithChildren<any>) => {
  return <div className="edit-profile-page-info edit-profile-form">{children}</div>;
};

const mapState = (state: AppState & ProfileModuleState) => ({
  screen: state.profile.screen,
  inProgress: state.cache.inProgress["submit-profile"],
  type: state.profile.type,
});

const mapDispatch = {
  submitProfile: submitProfile,
};

export const PageInfo = connect(mapState, mapDispatch)(Component);

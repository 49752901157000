/**
 * @author William Alexander Livesley
 * @date   2018-03-30
 * @description Declaration of the MediaAssetFile interface.
 * @filename media-asset-file.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { MediaAssetFileStatus } from './media-asset-file-status';
import { MediaType } from './media-type';
import { NetworkLocation } from './network-location';
import { Node } from './node';

/**
 * The __typeID of the GraphQL Media Asset File object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_MEDIA_ASSET_FILE: string = "MediaAssetFile";

/**
 * A media asset file, a video, audio file or image with a particular format, size and dimensions.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface MediaAssetFile<D extends Date | string = string> extends Node<D> {
  /**
   * The location where copies of the media asset files are stored.
   *
   * @template D
   * @type {Array<NetworkLocation<D>>}
   */
  copies?: NetworkLocation<D>[];

  /**
   * The format of the media asset file.
   *
   * @template D
   * @type {MediaType<D>}
   */
  format?: MediaType<D>;

  /**
   * The height of the media asset file in pxiels.
   * 
   * @type {number}
   */
  heightInPixels?:number;

  /**
   * The name of the media asset file.
   *
   * @type {string}
   */
  name?: string;

  /**
   * The path to access the media asset file in the network location.
   *
   * @type {string}
   */
  path?: string;

  /**
   * The status of the media asset file.
   *
   * @template D
   * @type {MediaAssetFileStatus<D>}
   */
  status?: MediaAssetFileStatus<D>;

  /**
   * The size, in bytes, of the media asset file.
   *
   * @type {number}
   */
  sizeInBytes?: number;

  /**
   * The width of the media asset file in pxiels.
   * 
   * @type {number}
   */
  widthInPixels?:number;
}

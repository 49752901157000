import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import { CompanyIndustries, CompanyInvestmentStages, CompanyTypes } from "../../../constants/company-info";
import { SetPitchPlanetFilterPayload, SetPitchPlanetFilterAction } from "../../../redux/account/types";
import { Checkbox } from "../../page-framework/checkbox";
import { SVG } from "../../../component-configuration/svgs";
import { SearchFacet } from "@radivision/graphql/lib/ts/graphql/search-hit";

interface ComponentProps {
  facets?: SearchFacet[];
}

interface MapDispatchProps {
  setPitchPlanetFilter: (payload: SetPitchPlanetFilterPayload) => SetPitchPlanetFilterAction;
}
interface MapStateProps {
  filters: { [s: string]: string[] };
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { filters, setPitchPlanetFilter, facets } = props;
  const menus = {
    industry: CompanyIndustries,
    stage: CompanyInvestmentStages,
    activity: CompanyTypes,
  };

  const checkIt = (key, value) => () => setPitchPlanetFilter({ key, value });
  return (
    <div className="pitch-planet-filters">
      {facets?.map(({ name, buckets }) => {
        const filterName = name;
        const menu = menus[filterName];
        const filter = filters[filterName] || [];
        return (
          <div className="filter-tab" key={`filter-tab-${filterName}`}>
            <a className="filter-name">
              <span>{filterName === "activity" ? "Company Type" : filterName}</span> {SVG.caretRight}
            </a>
            <ul className="sm-scroller">
              {menu &&
                menu.map((item: any, index: number) => {
                  const itemKey = item.title;
                  const bucket = buckets.find((bucket) => bucket.value === item.title);
                  const count = bucket?.count;
                  if (filterName !== "activity" && !count) {
                    return null;
                  }
                  return item && item.title ? (
                    <li key={`filter-${filterName}-${index}`}>
                      <Checkbox
                        checked={filter.includes(itemKey)}
                        onChange={checkIt(filterName, itemKey)}
                        label={
                          <div>
                            <span>{String(item.title)}</span>
                            {filterName === "activity" && <span>{count ? (count > 99 ? "99+" : count) : 0}</span>}
                          </div>
                        }
                      />
                    </li>
                  ) : null;
                })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

const mapState = (state: AppState) => ({
  filters: state.account.pitchPlanetFilter,
});

const mapDispatch = {
  setPitchPlanetFilter: Actions.setPitchPlanetFilter,
};

export const PitchPlanetFilters = connect(mapState, mapDispatch)(Component);

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type PreviewKind = "A_ROLE" | "BACKGROUND" | "BADGE_DARK" | "BADGE_LIGHT" | "B_ROLE" | "CAROUSEL" | "DETAIL_BACKGROUND" | "EXCERPT" | "HEADSHOT" | "HERO" | "HERO_LOGO" | "LOGO" | "PORTRAIT" | "SQUARE" | "%future added value";
export type TopLevelMediaKind = "APPLICATION" | "AUDIO" | "DOCUMENT" | "IMAGE" | "TEXT" | "VIDEO" | "%future added value";
export type listItemPersonQueryVariables = {
    readonly id: string;
};
export type listItemPersonQueryResponse = {
    readonly person: {
        readonly hasErrors: boolean | null;
        readonly person: {
            readonly __typename: string;
            readonly description: string | null;
            readonly fullName: string | null;
            readonly landingPageUrl: string | null;
            readonly id: string;
            readonly previews: {
                readonly count: number | null;
                readonly edges: ReadonlyArray<{
                    readonly cursor: string | null;
                    readonly preview: {
                        readonly link: string | null;
                        readonly kind: PreviewKind | null;
                        readonly mediaAsset: {
                            readonly id: string;
                            readonly files: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string | null;
                                readonly path: string | null;
                            }> | null;
                            readonly topLevelMediaType: {
                                readonly kind: TopLevelMediaKind | null;
                                readonly label: string | null;
                            } | null;
                        } | null;
                    } | null;
                }> | null;
            } | null;
            readonly responsibility: string | null;
        } | null;
    } | null;
};
export type listItemPersonQuery = {
    readonly response: listItemPersonQueryResponse;
    readonly variables: listItemPersonQueryVariables;
};



/*
query listItemPersonQuery(
  $id: ID!
) {
  person(id: $id) {
    hasErrors
    person {
      __typename
      description
      fullName
      landingPageUrl
      id
      previews {
        count
        edges {
          cursor
          preview {
            link
            kind
            mediaAsset {
              id
              files {
                id
                name
                path
              }
              topLevelMediaType {
                kind
                label
                id
              }
            }
          }
        }
      }
      responsibility
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasErrors",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "landingPageUrl",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "files",
  "storageKey": null,
  "args": null,
  "concreteType": "MediaAssetFile",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "path",
      "args": null,
      "storageKey": null
    }
  ]
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "responsibility",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "listItemPersonQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "person",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PersonPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "person",
            "storageKey": null,
            "args": null,
            "concreteType": "Person",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "previews",
                "storageKey": null,
                "args": null,
                "concreteType": "PreviewsConnection",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewEdge",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "preview",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Preview",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "mediaAsset",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MediaAsset",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v12/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "topLevelMediaType",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TopLevelMediaType",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  (v13/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v14/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "listItemPersonQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "person",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PersonPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "person",
            "storageKey": null,
            "args": null,
            "concreteType": "Person",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "previews",
                "storageKey": null,
                "args": null,
                "concreteType": "PreviewsConnection",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PreviewEdge",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "preview",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Preview",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "mediaAsset",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MediaAsset",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v12/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "topLevelMediaType",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TopLevelMediaType",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  (v13/*: any*/),
                                  (v7/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v14/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "listItemPersonQuery",
    "id": null,
    "text": "query listItemPersonQuery(\n  $id: ID!\n) {\n  person(id: $id) {\n    hasErrors\n    person {\n      __typename\n      description\n      fullName\n      landingPageUrl\n      id\n      previews {\n        count\n        edges {\n          cursor\n          preview {\n            link\n            kind\n            mediaAsset {\n              id\n              files {\n                id\n                name\n                path\n              }\n              topLevelMediaType {\n                kind\n                label\n                id\n              }\n            }\n          }\n        }\n      }\n      responsibility\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '267879630506fc3ba837790370bc2dc1';
export default node;

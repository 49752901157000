/**
 * @Author: Nassar Sara
 * @Date:   2018-05-02T19:56:49+02:00
 * @Email:  sara@radivision.com
 * @Project: Radivision
 * @Filename: questionnaire.ts
 * @Copyright: Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Connection } from "./connection";
import { Entity } from "./entity";
import { MultipleChoiceQuestion } from "./multiple-choice-question";
import { MultipleChoiceQuestionResponse } from "./multiple-choice-question-response";
import { Node } from "./node";
import { Preview } from "./preview";
import { Person } from "./person";
import { TextQuestion } from "./text-question";
import { TextQuestionResponse } from "./text-question-response";

/**
 * The __typeID of the Questionnaire type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_QUESTIONNAIRE: string = "Questionnaire";

/**
 * The different types of questions.
 */
export type QuestionResponder = Person | Entity;

/**
 * The different types of questions.
 */
export type QuestionResponse = MultipleChoiceQuestionResponse | TextQuestionResponse;

/**
 * The different types of questions.
 */
export type Question = MultipleChoiceQuestion | TextQuestion

/**
 * A questionnaire.
 *
 * @template D
 * @interface
 */
export interface Questionnaire<D extends Date | string = string>
  extends Node<D> {
  /**
   * A description the questionnaire
   *
   * @type {string}
   * @memberof Questionnaire
   */
  description?: string;

  /**
   * A collection of media assets used as previews. Only one media asset can act as a given
   * type of preview
   *
   * @type Connection<D, Preview>
   */
  previews?: Connection<D, Preview>;

  /**
   * The collection of questions associated with the questionnaire.
   *
   * @type {Array<QuestionnaireQuestion>}
   * @memberof Questionnaire
   */
  questions?: Question[];

  /**
   * The sub-title of the questionnaire.
   *
   * @type {string}
   * @memberof Questionnaire
   */
  subTitle?: string;

  /**
   * The title of the questionnaire
   *
   * @type {string}
   * @memberof Questionnaire
   */
  title?: string;
}

import { takeEvery, put, call, all, fork } from "redux-saga/effects";
import * as Api from "./api";
import * as Actions from "../actions";
import $ from "jquery";
import { CREATE_NOTIFICATION_REQUEST } from "./constants";
import { CreateNotificationRequestAction } from "./types";
import { notifyMeModalId } from "../../react-components/panels/pitch-panel/notify-me-modal";
import { TOAST_SUCCESS, TOAST_FAIL } from "../toast/constants";
import { storageFactory } from "../../utilities/local-storage-factory";
import { OPPORTUNITIES_NOTIFY_ME_MODAL } from "../../react-components/inner-pages/profile-details-tab/investment/pitch-video-panel";
const localStore = storageFactory(() => localStorage);

function* createNotificationRequestWorker(action: CreateNotificationRequestAction) {
  const { email, meta, notificationKind } = action.payload;
  try {
    yield put(Actions.creatingNotification({ status: true }));
    yield call(Api.createRadivisionNotification, notificationKind, email, meta);
    localStore.setItem(`notification-${notificationKind}-${meta?.entity}`, email);
    yield put(Actions.getNotificationSubscriptionStatus({ notificationKind, entityId: meta?.entity }));
    yield put(Actions.creatingNotification({ status: false }));
    yield put(Actions.setToastMessage({ msg: `You will be notified on "${email}". Thank you!`, type: TOAST_SUCCESS }));
    $(`#${notifyMeModalId}`).modal("hide");
    $(`#${OPPORTUNITIES_NOTIFY_ME_MODAL}`).modal("hide");
  } catch (error) {
    yield put(Actions.setToastMessage({ msg: `Subscribing to notification Failed!`, type: TOAST_FAIL }));
  }
}

function* watchCreateNotificationRequest() {
  yield takeEvery(CREATE_NOTIFICATION_REQUEST, createNotificationRequestWorker);
}

const sagas = [watchCreateNotificationRequest];
export default function* root() {
  yield all(sagas.map((saga) => fork(saga)));
}

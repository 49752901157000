import { graphql, GraphQLTaggedNode } from "react-relay";

export const EVENT_QUERY: GraphQLTaggedNode = graphql`
  query eventQuery($id: ID!) {
    event(id: $id) {
      hasErrors
      errors {
        id
        location
      }
      event {
        ...event @relay(mask: false)
      }
    }
  }
`;

/**
 * @author Ahmed Serag
 * @date   2018-04-05
 * @description Declaration of ComponentWithData interface.
 * @filename component-with-data.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { ApiObject } from "./api-object";
import { Component } from "./component";

/**
 * The __typeID of the Component with Data GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_COMPONENT_WITH_DATA: string = "ComponentWithData";

/**
 *  A Javascript component, normally a React component, which can be hosted on a webpage
 * including the underlying configuration data.
 *
 * @interface ComponentWithData
 */
export interface ComponentWithData {
  /**
   * The typename of the node.
   *
   * @type {string}
   */
  __typename?: string;

  /**
   * The API object containing the data for of the component. This is optional where
   * information is derived from the list item.
   *
   * @type {ApiObject}
   * @memberof ComponentWithData
   */
  apiObject?: ApiObject<any>;

  /**
   * The ID of the API object containing the data for of the component. This is optional
   * where information is derived from the list item.
   * Allows dynamic loading without passing details of the API object in a fragment.
   *
   * @type {string}
   * @memberof ComponentWithData
   */
  apiObjectId?: string;

  /**
   * The component
   *
   * @type {Component}
   * @memberof ComponentWithData
   */
  component?: Component;

  /**
   * the react Component to represent it
   *
   * @type {*}
   * @memberof ComponentWithData
   */
  reactComponent?: any;
}

/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-01-21
 * @description Implementation of image handler utilities to be used in the app.
 * @filename imageHelper.ts
 */
import { PreviewKind } from "@radivision/graphql/lib/ts/graphql/preview-type";
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import { OptimizedImageUrls } from "../component-configuration/optimized-image";
import { OptimizedImageInput } from "../component-configuration/optimized-image-input";
import { DEFAULTS } from "./defaults";
import { PagePixelUtility } from "./page-pixel-utility";
/**
 * collection of allowed image width across different screen sizes
 */
const MAXIMUM_ALLOWED_WIDTHS: number[] = [1600, 1392, 1200, 640];
/**
 * number representing placeholder image width
 */
const PLACEHOLDER_WIDTH: number = 10;

/**
 *
 * Utility class responsible for OptimizedImage calls
 * @export
 * @class ImageHelper
 */
export class ImageHelper {
  /**
   * Function used to map remote image to use edge lambda function responsible
   * for handling remote image
   * @static
   * @param {string} imageUrl
   * @param {{ width?: number; height?: number }} [desiredDimensions]
   * @returns String containing the modified image link
   * @memberof ImageHelper
   */
  static handleRemoteImage(imageUrl: string): string {
    let remoteUrl: string = process.env.URL_REMOTE_MEDIA_IMAGE;
    let encodedLink: string;

    if (imageUrl === null || imageUrl === undefined) {
      throw new Error("invalid url provided");
    }

    if (remoteUrl === null || remoteUrl === undefined) {
      throw new Error("invalid environment variable for remote image url");
    }
    // request parameters
    // i = image url
    // h = desired height
    // w = desired width
    // attempt to remove any query string presented in url before actual call
    // ... btoa only encode chars within Latin words
    encodedLink = window.btoa(unescape(encodeURIComponent(imageUrl))); // encode a string
    remoteUrl = `${remoteUrl}?i=${encodedLink}`;

    return remoteUrl;
  }

  /**
   * Function used to map media asset id to edge lambda function url
   * with desired image format
   * @param {any[]} files media assets files
   * @param {string} url  environment edge url
   * @param {string} [requestParameters] desired request parameters
   *
   * @returns {string} Returns a mapped url to image handle edge lambda function
   * @memberof ImageHelper
   */
  static handleMediaAssetFile(type: TopLevelMediaKind, files?: any[], mediaAssetPath?: string): string {
    let baseUrl: string;
    let parsedUrl: string;

    if (type === "IMAGE") {
      baseUrl = process.env.URL_MEDIA_IMAGE;
    } else {
      baseUrl = `${process.env.URL_MEDIA_VIDEO}`;
    }

    if (mediaAssetPath !== undefined && mediaAssetPath !== null) {
      parsedUrl = `${baseUrl}/${mediaAssetPath}`;
    } else {
      parsedUrl = `${baseUrl}/${files[0].path}/${files[0].name}`;
    }

    return parsedUrl;
  }

  /**
   * return default img link based on the preview type and item type.
   *
   * @param {PreviewKind} previewType
   * @param {string} type
   * @returns {string} url of the returned item.
   */
  static handleDefaultUrl(previewType: PreviewKind, mediaType: TopLevelMediaKind, type?: string): string {
    // env variable name should be something like DEFAULT_type_previewType
    let defaultPreview: string;
    let url: string;

    if (type !== undefined && type !== null) {
      defaultPreview = `DEFAULT_${type}_${previewType}`;
    } else {
      defaultPreview = `DEFAULT_${previewType}`;
    }
    if (process.env.URL_MEDIA_IMAGE !== undefined && process.env.URL_MEDIA_IMAGE !== null) {
      url = `${process.env.URL_MEDIA_IMAGE}/${DEFAULTS[defaultPreview]}`;
    } else {
      url = `${DEFAULTS[`URL_MEDIA_${mediaType}`]}/${DEFAULTS[defaultPreview]}`;
    }
    return url;
  }

  /**
   * Returns the URL of the image or video acting as a preview.
   *
   * @param {GraphQlConnection} previews
   *
   * @param {PreviewKind} previewType
   *
   * @returns {string} Returns A string contains a valid url for current previc
   */
  static handleUrlFromPreviews(
    previews: any,
    previewType: PreviewKind[] | PreviewKind,
    topLevelMedia?: TopLevelMediaKind,
    def: boolean = true,
    type: string = undefined,
  ): string {
    let url: string;

    let providedTypes: PreviewKind[];

    if (Array.isArray(previewType)) {
      providedTypes = previewType;
    } else {
      providedTypes = [previewType];
    }

    if (previews !== undefined && previews !== null) {
      for (const EDGE of previews.edges) {
        if (EDGE !== null && EDGE !== undefined) {
          const PREVIEW = EDGE.preview;

          // check if current preview is remote image
          if (PREVIEW && PREVIEW.link !== undefined && PREVIEW.link !== null && providedTypes.includes(PREVIEW.kind)) {
            url = ImageHelper.handleRemoteImage(PREVIEW.link);
          } else if (
            PREVIEW &&
            PREVIEW.mediaAsset &&
            PREVIEW.mediaAsset.files !== undefined &&
            PREVIEW.mediaAsset.files !== null &&
            PREVIEW.mediaAsset.files.length > 0 &&
            PREVIEW.mediaAsset.topLevelMediaType !== undefined &&
            PREVIEW.mediaAsset.topLevelMediaType !== null &&
            PREVIEW.mediaAsset.topLevelMediaType.kind === TopLevelMediaKind.VIDEO
          ) {
            if (PREVIEW.kind !== undefined && PREVIEW.kind !== null && providedTypes.includes(PREVIEW.kind)) {
              url = ImageHelper.handleMediaAssetFile(TopLevelMediaKind.VIDEO, PREVIEW.mediaAsset.files);
            }
          } else if (
            PREVIEW &&
            PREVIEW.mediaAsset &&
            PREVIEW.mediaAsset.files !== undefined &&
            PREVIEW.mediaAsset.files !== null &&
            PREVIEW.mediaAsset.files.length > 0
          ) {
            if (PREVIEW.kind !== undefined && PREVIEW.kind !== null && providedTypes.includes(PREVIEW.kind)) {
              url = ImageHelper.handleMediaAssetFile(TopLevelMediaKind.IMAGE, PREVIEW.mediaAsset.files);
            }
          }
        }
      }
    }
    if ((url === undefined || url === null) && def) {
      url = ImageHelper.handleDefaultUrl(providedTypes[0], topLevelMedia, type);
    }

    return url;
  }

  /**
   * Function used to handle parsing requested dimension to request parameters
   * to request image url
   * @static
   * @param {{
   *     width?: number;
   *     height?: number;
   *   }} imageDimensions
   * @returns {string}
   * @memberof ImageHelper
   */
  static handelRequestParameters(imageDimensions: { width?: number; height?: number }): string {
    let requestParameters: string = "";

    if (imageDimensions !== undefined && imageDimensions !== null) {
      if (imageDimensions.width !== undefined && imageDimensions.width !== null && imageDimensions.width > 0) {
        requestParameters = `${requestParameters}w=${imageDimensions.width}`;
      }

      if (imageDimensions.height !== undefined && imageDimensions.height !== null && imageDimensions.height > 0) {
        requestParameters = `${requestParameters}&h=${imageDimensions.height}`;
      }
    }

    return requestParameters;
  }

  /**
   * Function responsible for handling different image urls
   * version for various resolutions of same image url
   * @static
   * @param {string} originalUrl
   * @param {("REMOTE" | "PREVIEW" | "MEDIA_ASSET" | "DEFAULT")} imageType
   * @param {{ containerWidthRatio: number; numberOfItems: number }} requestParameters
   * @returns {OptimizedImageUrls}
   * @memberof ImageHelper
   */
  static handleVariousImageResolution(originalUrl: string, option: OptimizedImageInput): OptimizedImageUrls {
    const optimizedImageUrls: OptimizedImageUrls = {};

    const imageType: string = originalUrl.indexOf(process.env.URL_REMOTE_MEDIA_IMAGE) >= 0 ? "REMOTE" : "IMAGES";

    const widthQueryParameters: string = imageType === "REMOTE" ? "&w=" : "?wip=";
    const heightQueryParameters: string = imageType === "REMOTE" ? "&h=" : "&hip=";
    const revisionQueryParameters: string = "&r=";
    const screenDimension: number = ImageHelper.getQuantizedWidthOfPageInPixels();
    let requestWidth: number;

    // validate first on provided image url
    if (originalUrl !== undefined && originalUrl !== null) {
      // assign original image url
      optimizedImageUrls.originalUrl = originalUrl;
      // assign place holder resolution typically square (10x10)
      optimizedImageUrls.placeHolderUrl = `${originalUrl}${widthQueryParameters}${PLACEHOLDER_WIDTH}`;

      if (screenDimension !== undefined && screenDimension !== null) {
        // assign screen size url
        optimizedImageUrls.screenResolutionUrl = `${originalUrl}${widthQueryParameters}${screenDimension}`;

        if (option.isSquareImage) {
          optimizedImageUrls.screenResolutionUrl = `${optimizedImageUrls.screenResolutionUrl}${heightQueryParameters}${screenDimension}`;
        }
      }

      // validate if desired dimensions is request addition to our standers
      if (option.desiredDimensions !== null && option.desiredDimensions !== undefined) {
        // validate if provided container width just calculate image section
        // area based on container not screen width
        requestWidth = ImageHelper.calculateContainerDimension(
          option.desiredDimensions.containerWidthRatio,
          option.desiredDimensions.numberOfItems,
        );
        //
        optimizedImageUrls.requestedResolutionUrl = `${optimizedImageUrls.originalUrl}${widthQueryParameters}${requestWidth}`;
        // if desired image is a square image just assign height parameters
        if (option.isSquareImage) {
          optimizedImageUrls.requestedResolutionUrl = `${optimizedImageUrls.requestedResolutionUrl}${heightQueryParameters}${requestWidth}`;
        }
      }

      if (option.revision) {
        optimizedImageUrls.requestedResolutionUrl = `${optimizedImageUrls.requestedResolutionUrl}${revisionQueryParameters}${option.revision}`;
        optimizedImageUrls.placeHolderUrl = `${optimizedImageUrls.placeHolderUrl}${revisionQueryParameters}${option.revision}`;
      }
    }
    return optimizedImageUrls;
  }

  /**
   * Returns the quantized width of page - constrained to the closest size in the collection of sizes.
   *
   * @returns {number} The quantized width of the page.
   */
  static getQuantizedWidthOfPageInPixels(): number {
    let width: number = PagePixelUtility.getWidthOfPageInPixels();

    width = MAXIMUM_ALLOWED_WIDTHS.find((wdth: number, index: number): boolean => {
      return (
        (index === 0 && width > wdth) ||
        index === MAXIMUM_ALLOWED_WIDTHS.length - 1 ||
        (width > MAXIMUM_ALLOWED_WIDTHS[index + 1] && width <= wdth)
      );
    });
    return width;
  }

  /**
   *
   * Function responsible for delivering 4 optimized urls
   * {placeholder,original image url,screen resolution image , requested dimensions of image }
   * @static
   * @param {OptimizedImageInput} option
   * @returns {OptimizedImageUrls}
   * @memberof ImageHelper
   */
  static fetchOptimizedImageUrl(option: OptimizedImageInput): OptimizedImageUrls {
    // initialize string to store optimized image url
    let optimizedImageUrl: string;
    // initialize optimized image urls
    let optimizedImageUrls: OptimizedImageUrls = {};

    // handle multiple image type cases
    switch (option.imageType) {
      // in case of remote image invoke remote image handler
      case "REMOTE":
        optimizedImageUrl = ImageHelper.handleRemoteImage(option.remote.url);
        break;
      // in case of media asset file invoke handler for media asset file
      case "MEDIA_ASSET":
        optimizedImageUrl = ImageHelper.handleMediaAssetFile(
          option.mediaAsset.type,
          option.mediaAsset.files,
          option.mediaAsset.mediaAssetPath,
        );
        break;
      // in case of media previews invoke handler component previews
      case "PREVIEW":
        optimizedImageUrl = ImageHelper.handleUrlFromPreviews(
          option.preview.content,
          option.preview.previewKind,
          option.preview.topLevelMedia,
          option.preview.isDefaultPreview,
          option.preview.type,
        );
        break;

      case "DEFAULT":
        optimizedImageUrl = ImageHelper.handleDefaultUrl(
          option.default.previewType,
          option.default.mediaType,
          option.default.type,
        );

      default:
    }

    // if image url is acquired or not included a undefined sub-string
    if (
      optimizedImageUrl !== undefined &&
      optimizedImageUrl !== null &&
      optimizedImageUrl.indexOf("undefined") === -1
    ) {
      // handle various image image resolution
      // placeHolder       : 10 px image
      // screen resolution : image url with screen width and height parameters
      // request resolution: image url with desired width and height parameters
      if (option.mediaAsset && option.mediaAsset.type === TopLevelMediaKind.VIDEO) {
        optimizedImageUrls = {
          requestedResolutionUrl: optimizedImageUrl,
          originalUrl: optimizedImageUrl,
        };
      } else {
        optimizedImageUrls = ImageHelper.handleVariousImageResolution(optimizedImageUrl, option);
      }
    } else {
      //
      optimizedImageUrls = undefined;
    }

    return optimizedImageUrls;
  }

  /**
   *
   * Function responsible for delivering the Preview mediaAssetId
   * {placeholder,original image url,screen resolution image , requested dimensions of image }
   * @static
   *
   * @param {GraphQlConnection} previews
   * @param {PreviewKind} previewType
   *
   * @returns {string}
   * @memberof ImageHelper
   */
  static getPreviewMediaAssetId(previews: any, previewType: PreviewKind): string {
    let previewMediaAssetId: string;

    const providedTypes: PreviewKind[] = [previewType];

    if (previews !== undefined && previews !== null) {
      for (const EDGE of previews.edges) {
        if (EDGE !== null && EDGE !== undefined) {
          const preview = EDGE.preview;

          // check if current preview is remote image
          if (preview && preview.link !== undefined && preview.link !== null) {
            previewMediaAssetId = undefined;
          } else if (
            preview &&
            preview.mediaAsset &&
            preview.mediaAsset.files !== undefined &&
            preview.mediaAsset.files !== null &&
            preview.mediaAsset.files.length > 0
          ) {
            if (preview.kind !== undefined && preview.kind !== null && providedTypes.includes(preview.kind)) {
              previewMediaAssetId = preview.mediaAsset.id;
            }
          }
        }
      }
    }

    return previewMediaAssetId;
  }

  /**
   *
   * function to check if set of previews has a specific PreviewKind or not
   * @static
   *
   * @param {GraphQlConnection} previews
   * @param {PreviewKind} previewType
   *
   * @returns {boolean}
   * @memberof ImageHelper
   */
  static hasPreviewKind(previews: any, previewType: PreviewKind): boolean {
    let hasPreviewKind: boolean;

    const providedTypes: PreviewKind[] = [previewType];

    if (previews !== undefined && previews !== null) {
      for (const EDGE of previews.edges) {
        if (EDGE !== null && EDGE !== undefined) {
          const preview = EDGE.preview;
          // check if current preview is remote image
          if (preview && preview.link !== undefined && preview.link !== null) {
            hasPreviewKind = false;
          } else if (
            preview &&
            preview.mediaAsset &&
            preview.mediaAsset.files !== undefined &&
            preview.mediaAsset.files !== null &&
            preview.mediaAsset.files.length > 0
          ) {
            if (preview.kind !== undefined && preview.kind !== null && providedTypes.includes(preview.kind)) {
              hasPreviewKind = true;
            }
          }
        }
      }
    }

    return hasPreviewKind;
  }

  /**
   *
   *
   * @static
   * @param {number} containerWidthRatio
   * @param {number} numberOfItems
   * @returns {number}
   * @memberof ImageHelper
   */
  static calculateContainerDimension(containerWidthRatio: number, numberOfItems: number): number {
    const screenDimension: number = ImageHelper.getQuantizedWidthOfPageInPixels();
    return Math.ceil((containerWidthRatio * screenDimension) / numberOfItems);
  }
}

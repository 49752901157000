/**
 * @author: Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-03-26 02:40:36
 * @description Implementation of the Navbar React component .
 * @filename nav-bar.tsx
 */
import { GRAPHQL_TYPE_PERSON, Person as GraphqlPerson } from "@radivision/graphql/lib/ts/graphql/person";
import { PreviewKind } from "@radivision/graphql/lib/ts/graphql/preview-type";
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import LazyLoad from "react-lazyload";
import ProgressiveImage from "react-progressive-image";
import { ImageHelper } from "../../utilities/image-helper";
// import { ACCOUNT_CONTROL_MODAL_ID } from "../../constants/general-constants";
import { Router } from "../../utilities/router";
// import { AccountControl } from "../modals/register-and-login/account-control";
// import { CreateAccountModal } from "../modals/register-and-login/create-account-modal";
// import { SignInModal } from "../modals/register-and-login/sign-in-modal";
import { SearchComponent } from "./search-component";
import { REACT_USER_CONTEXT } from "../../contexts/react-user-context";
// import { UGCModal } from "../modals/ugc-modal";
import { SVG } from "../../component-configuration/svgs";
import { snowplowAnalytics } from "../../utilities/general";
import { AUTHENTICATION_MODAL_ID, AUTH_SIGNIN, AUTH_SIGNUP } from "../../redux/authentication/constants";
import { setAuthState } from "../../redux/actions";
import { store, AppState } from "../../redux";
import { CreateCompanyButton } from "../modals/create-company";
import { getProfileInfo } from "../../utilities/get-profile-info";
import CS from "../../../static/ComingSoon.png";

const isEqual = require("lodash.isequal");
const LOGO = require("../../../static/rv-logo-on-black.png");
const DEFAULT_IMAGE = require("../../../static/no-image-icon.png");

export function toggleNavMenu() {
  $(".navbar-collapse").toggleClass("expanded");
  $(".navbar-toggler").toggleClass("is-active");
  $(".navbar-toggler").focus();
}
/**
 *  The props of the Navbar component.
 *
 * @export
 * @interface
 */
interface NavbarProps {
  /**
   * A flag which, if set to true, sets the navbar to use transparent background.
   *
   * @type {boolean}
   */
  transparent: boolean;
  pageYOffset: number;
  device: string;
}

/**
 * The state of the Navbar component.
 *
 * @export
 * @interface
 */
interface NavbarState {
  /**
   * save the active displayed page form current page url
   *
   * @type {string}
   */
  activePage: string;

  /**
   * A flag which is true if
   *
   * @type {number}
   */
  fireRedirect: boolean;

  /**
   * A flag which is true if the hamburger drop-down is being displayed.
   *
   * @type {boolean}
   *
   */
  hamburgerDropDown: boolean;

  /**
   * The height of the navbar in pixels.
   *
   * @type {number}
   */
  heightInPixels: number;

  /**
   * The Sign in or Join modal
   *
   * @type {React.ReactNode}
   */
  modalController: React.ReactNode;

  /**
   * The value of the search expression.
   *
   * @type {string}
   */
  searchValue: string;

  /**
   * A flag which is true if
   *
   * @type {boolean}
   */
  showJoin: boolean;

  /**
   * A flag which is true if
   *
   * @type {boolean}
   */
  showsDropDown: boolean;

  /**
   * A flag which is true if the sign-in is displayed.
   *
   * @type {boolean}
   */
  showSignIn: boolean;

  /**
   * The URL of the tag-line.
   *
   * @type {string}
   */
  taglineUrl: string;

  /**
   * A flag which is true if the nvabar is in search mode.
   *
   * @ype {boolean}
   *
   */
  toggleSearch: boolean;

  /**
   * A flag which, if set to true, sets the navbar to use transparent background.
   *
   * @type {boolean}
   */
  transparent: boolean;
}

/**
 *  A React component that renders the Navbar.
 *
 * @export
 * @class Navbar
 * @extends {React.Component<NavbarProps, NavbarState>}
 */

class Component extends React.Component<NavbarProps, NavbarState> {
  /**
   * The navbar element.
   *
   * @type {HTMLElement}
   */
  navbar: HTMLElement;

  /**
   * boolean which is true uf we have to update component
   *
   * @type {boolean}
   * @memberof Navbar
   */
  forceUpdateForModal: boolean;

  /**
   * save the value of window scrollTop
   *
   * @type {number}
   */
  windowScrollValue: number;

  /**
   * Constructor
   *
   * @param {NavbarProps} props The component props.
   */
  constructor(props: NavbarProps) {
    super(props);
    this.windowScrollValue = 0;
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchValue = this.handleSearchValue.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.forceUpdateForModal = false;

    this.state = {
      activePage: "",
      fireRedirect: false,
      hamburgerDropDown: false,
      heightInPixels: 1,
      // modalController: SignInModal || CreateAccountModal,
      searchValue: "",
      showJoin: false,
      showsDropDown: false,
      showSignIn: false,
      taglineUrl: `${process.env.URL_MEDIA_IMAGE}/0a/us/0aUSTIfTQZu456apuXFTbw.svg`,
      toggleSearch: false,
      transparent: props.transparent,
    };
  }

  onScroll() {
    if (this.props.transparent && this.navbar !== undefined && this.navbar !== null) {
      if (this.props.pageYOffset > 100 && this.state.transparent) {
        this.setState({
          transparent: false,
        });
      }
      if (this.props.pageYOffset <= 100 && !this.state.transparent) {
        this.setState({
          transparent: true,
        });
      }
    }
  }
  /**
   * Invoked by the React framework immediately after a component is mounted.
   */
  componentDidMount() {
    Router.getAlias().then((alias: string) => {
      this.setState({ activePage: alias });
    });

    $(".collapsable").on("click", (e) => {
      e.preventDefault();
      const expanded: string = e.currentTarget.getAttribute("data-expanded");
      const target: string = e.currentTarget.getAttribute("href");
      if (expanded === "true") {
        $(target).animate({ height: "0px" }, 300, () => {
          $(target).css("display", "none");
        });
        e.currentTarget.setAttribute("data-expanded", "false");
      } else {
        $(target).css("display", "block");
        $(target).css("height", "auto");
        const height: number = $(target).height();
        $(target).css("height", "0");
        $(target).animate({ height }, 300);
        e.currentTarget.setAttribute("data-expanded", "true");
      }
    });
  }

  /**
   * invoked when component changed
   */
  componentDidUpdate(prevProps, prevState: NavbarState) {
    if (prevState.hamburgerDropDown !== this.state.hamburgerDropDown) {
      if (this.state.hamburgerDropDown) {
        this.windowScrollValue = document.documentElement.scrollTop;
        $("body").removeClass("allow-scroll");
        document.body.style.marginTop = `-${this.windowScrollValue}px`;
      } else {
        document.body.style.marginTop = "0";
        $("body").addClass("allow-scroll");
        window.scrollTo(0, this.windowScrollValue);
      }
    }
    if (prevProps.device !== this.props.device) {
      this.onResize();
    }
    this.onScroll();
  }

  /**
   * Invoked just before the component is mounted.
   */
  componentWillUnmount() {
    $("body").addClass("allow-scroll");
    window.scrollTo(0, this.windowScrollValue);
  }

  /**
   *
   * @param nextProps
   * @param nextState
   */
  shouldComponentUpdate(nextProps: NavbarProps, nextState: NavbarState): boolean {
    const shouldUpdate: boolean =
      !isEqual(nextState, this.state) || !isEqual(nextProps, this.props) || this.forceUpdateForModal;
    this.forceUpdateForModal = false;

    return shouldUpdate;
  }

  /**
   * Invoked when the search is selected.
   *
   * @param {React.MouseEvent} e The event which triggered the function.
   */
  handleSearch(e: any) {
    e.preventDefault();
    if (!(this.state.searchValue.length > 0) && e.detail > 0) {
      this.setState({ toggleSearch: !this.state.toggleSearch });
      document.getElementById("search-input-nav").focus();
    } else {
      this.setState({ fireRedirect: true });
    }
  }

  /**
   * Invoked when the search operation is selected.
   *
   * @param {React.ChangeEvent} e The event which triggered the function.
   */
  handleSearchValue(e: React.ChangeEvent) {
    this.setState({
      fireRedirect: false,
      searchValue: (e.target as any)["value"],
    });
  }

  /**
   * A function invoked on nav item click)
   *
   * @type {function(event: React.MouseEvent<HTMLElement>, index: number, alias: string, queryParameters?: string[], location?: string))}
   */
  handleClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    alias: string,
    queryParameters?: string[],
    location?: string,
  ) => {
    event.preventDefault();
    this.setState({ hamburgerDropDown: false });
    Router.navigateTo(alias, queryParameters, location);
    this.setState({
      hamburgerDropDown: false,
    });
    snowplowAnalytics().then((r) => {
      r.page({
        path: "/" + alias + (location ? "#" + location : ""),
        url: window.location.protocol + "//" + window.location.host + "/" + alias + (location ? "#" + location : ""),
      });
    });
  };

  /**
   * Invoked when the window is resized.
   */
  onResize(): void {
    if (this.navbar !== undefined && this.navbar !== null) {
      this.setState({
        heightInPixels: this.navbar.clientHeight,
      });
    }
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    const hideNavigation = this.props?.json?.hideNavigation;
    return (
      <REACT_USER_CONTEXT.Consumer>
        {(value: { user: GraphqlPerson; updateUser: Function }) => (
          <div id="main-navbar" className={this.props.transparent && this.state.transparent ? "transparent" : ""}>
            <nav
              className={`${value.user ? "logged-in " : ""}main-navbar navbar navbar-expand-md navbar-dark fixed-top 
                ${this.props.transparent && this.state.transparent ? "transparent" : ""}`}
              id="top-nav"
              ref={(navbar) => {
                this.navbar = navbar;
              }}
            >
              <a
                className="navbar-brand nav-logo"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  Router.navigateTo("/");
                }}
              >
                <img src={LOGO} alt="Radivision logo" className="logo d-inline-block" onLoad={this.onResize} />
              </a>
              <div
                className={`collapse navbar-collapse${this.state.hamburgerDropDown ? " expanded" : ""}`}
                id="navbarSupportedContent"
              >
                <div
                  className="navbar-collapse-backdrop d-block d-lg-none"
                  onClick={() => this.setState({ hamburgerDropDown: false })}
                />
                <ul className="navbar-nav ml-auto">
                  {/* <li className="nav-item">
                    <a
                      className={`nav-link${this.state.activePage === "home" ? " active" : ""}`}
                      onClick={(event: React.MouseEvent<HTMLElement>) =>
                        this.handleClick(event, -1, "home", undefined, undefined)
                      }
                    >
                      <span>Home</span>
                    </a>
                  </li> */}
                  {!hideNavigation && (
                    <>
                      <li className="nav-item">
                        <a
                          className={`nav-link${this.state.activePage === "home" ? " active" : ""}`}
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            Router.navigateTo("/");
                          }}
                        >
                          <span>Watch</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link${this.state.activePage === "learn" ? " active" : ""}`}
                          href="/learn"
                          onClick={(event: React.MouseEvent<HTMLElement>) =>
                            this.handleClick(event, -1, "learn", undefined)
                          }
                        >
                          <span>Learn</span>
                        </a>
                      </li>
                      <li className="nav-item dropdown moreDropdownMenu">
                        <a
                          className="nav-link dropdown-toggle more-dropdown"
                          href="#"
                          id="moreDropdownMenu"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span className="d-none d-lg-block">Connect</span>
                          <span className="d-block d-lg-none">
                            Connect<i>{SVG.caretRight}</i>
                          </span>
                        </a>

                        <div className="dropdown-menu moreDropdownMenu" aria-labelledby="moreDropdownMenu">
                          {/* <a
                            className="dropdown-item"
                            href="/pitchplanet"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              this.handleClick(event, -1, "pitchplanet", undefined);
                            }}
                          >
                            Pitch Planet
                          </a> */}
                          <a
                            className="dropdown-item"
                            href="/sheconomics"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              this.handleClick(event, -1, "sheconomics", undefined);
                            }}
                          >
                            SHEconomics
                          </a>
                          <a
                            className="dropdown-item"
                            href="/black-is-the-new-green"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              this.handleClick(event, -1, "black-is-the-new-green", undefined);
                            }}
                          >
                            Black is the New Green
                          </a>
                          <a
                            className="dropdown-item"
                            href="/ecosystem"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              this.handleClick(event, -1, "ecosystem", undefined);
                            }}
                          >
                            Startup Ecosystem
                          </a>

                          <a
                            className="dropdown-item soon"
                            // href="/black-is-the-new-green"
                            // onClick={(event: React.MouseEvent<HTMLElement>) => {
                            //   this.handleClick(event, -1, "black-is-the-new-green", undefined);
                            // }}
                          >
                            The Experts
                            <img src={CS} width="80" height="45" />
                          </a>
                          <a
                            className="dropdown-item soon"
                            // href="/black-is-the-new-green"
                            // onClick={(event: React.MouseEvent<HTMLElement>) => {
                            //   this.handleClick(event, -1, "black-is-the-new-green", undefined);
                            // }}
                          >
                            Equity IQ
                            <img src={CS} width="80" height="45" />
                          </a>

                          {/* <a
                            className="dropdown-item"
                            href="/radical-visionaries"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              this.handleClick(event, -1, "radical-visionaries", undefined);
                            }}
                          >
                            Radical Visionaries
                          </a> */}

                          {/* <a
                            className="dropdown-item"
                            href="/covidpreneurs"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              this.handleClick(event, -1, "covidpreneurs", undefined);
                            }}
                          >
                            COVIDpreneurs
                          </a> */}
                        </div>
                      </li>
                      <li className="nav-item">
                        <a
                          href="/pitchplanet"
                          className={`nav-link ${this.state.activePage === "pitchplanet" ? "active" : ""}`}
                          onClick={(event: React.MouseEvent<HTMLElement>) =>
                            this.handleClick(event, -1, "pitchplanet", undefined)
                          }
                        >
                          <span>Invest</span>
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a
                          href="/buy"
                          className={`nav-link ${this.state.activePage === "buy" ? "active" : ""}`}
                          onClick={(event: React.MouseEvent<HTMLElement>) =>
                            this.handleClick(event, -1, "buy", undefined)
                          }
                        >
                          <span>Buy</span>
                        </a>
                      </li> */}
                      {/* <li className="nav-item">
                    <a
                      href="/ecosystem"
                      className={`nav-link${this.state.activePage === "ecosystem" ? " active" : ""}`}
                      onClick={(event: React.MouseEvent<HTMLElement>) =>
                        this.handleClick(event, -1, "ecosystem", undefined)
                      }
                    >
                      <span>Ecosystem</span>
                    </a>
                  </li> */}

                      {/* <li className="nav-item d-lg-none">
                        <a
                          className={`nav-link ${this.state.activePage === "about" ? "active" : ""}`}
                          href="/about"
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            this.handleClick(event, -1, "about", undefined);
                          }}
                        >
                          <span>About</span>
                        </a>
                      </li> */}
                      {value.user ? (
                        <li className="nav-item dropdown d-block d-sm-none">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="userDropDownMenuMobile"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            My Profile<i>{SVG.caretRight}</i>
                          </a>
                          <div className="dropdown-menu" aria-labelledby="userDropDownMenuMobile">
                            <a
                              className={`dropdown-item${
                                value.user !== null &&
                                value.user !== undefined &&
                                window.location.href === value.user.landingPageUrl
                                  ? " active"
                                  : ""
                              }`}
                              onClick={() => {
                                const { profileUrl } = getProfileInfo(value.user);
                                Router.navigateTo(profileUrl);
                              }}
                            >
                              My Profile
                            </a>
                            {/* <a
                          className="dropdown-item companyProfiles collapsable"
                          href="#companyProfiles"
                          data-expanded="false"
                        >
                          My Companies
                        </a>
                        <div
                          className="collapse multi-collapse"
                          id="companyProfiles"
                        >
                          <div className="card card-body">
                            {value.user.entities === null ||
                            value.user.entities === undefined
                              ? null
                              : value.user.entities.map((entity, index) => {
                                  return (
                                    <a
                                      className="dropdown-item"
                                      href={entity.landingPageUrl}
                                      key={index}
                                    >
                                      {entity.name}
                                    </a>
                                  );
                                })}
                          </div>
                        </div> */}
                            <a
                              className={`dropdown-item setting${this.state.activePage === "saved" ? " active" : ""}`}
                              href="/saved"
                              onClick={() => Router.to("saved")}
                            >
                              My List
                            </a>
                            <a
                              className={`dropdown-item setting${
                                this.state.activePage === "following" ? " active" : ""
                              }`}
                              href="/following"
                              onClick={() => Router.to("following")}
                            >
                              Following
                            </a>
                            <a
                              className={`dropdown-item setting${
                                this.state.activePage === "settings" ? " active" : ""
                              }`}
                              href="/account"
                              onClick={() => Router.to("account")}
                            >
                              Settings
                            </a>
                            <a
                              className="dropdown-item signout"
                              onClick={() => {
                                import("../../authentication/cognito-client").then((module) => {
                                  return module.CognitoClient.signOut(true);
                                });
                              }}
                            >
                              Sign out
                            </a>
                            {/* <CreateCompanyButton /> */}
                          </div>
                        </li>
                      ) : null}
                      <li className="nav-item dropdown moreDropdownMenu">
                        <a
                          className="nav-link dropdown-toggle more-dropdown"
                          href="#"
                          id="moreDropdownMenu"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span className="d-none d-lg-block">&amp; More</span>
                          <span className="d-block d-lg-none">
                            &amp; More<i>{SVG.caretRight}</i>
                          </span>
                        </a>

                        <div className="dropdown-menu moreDropdownMenu" aria-labelledby="moreDropdownMenu">
                          <a
                            className="dropdown-item"
                            href="/get-started"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              this.handleClick(event, -1, "get-started", undefined);
                            }}
                          >
                            Get Started
                          </a>
                          <a
                            className="dropdown-item"
                            href="/about"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              this.handleClick(event, -1, "about", undefined);
                            }}
                          >
                            About
                          </a>
                          <a
                            className="dropdown-item"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              event.preventDefault();
                              const blogURL = "https://blog.radivision.com/";
                              window.open(blogURL, "_blank");
                            }}
                          >
                            Media &amp; Blog
                          </a>
                          <a
                            className="dropdown-item"
                            href="/partners"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              this.handleClick(event, -1, "partners", undefined);
                            }}
                          >
                            Partners
                          </a>
                        </div>
                      </li>
                    </>
                  )}
                  {/* user login or join on mobile */}
                  {!value.user ? (
                    <div className="xs-user-login d-block d-sm-none">
                      <div className="join-btn">
                        <a
                          href="#"
                          className="join"
                          data-toggle="modal"
                          data-target={`#${AUTHENTICATION_MODAL_ID}`}
                          onClick={(e) => {
                            e.preventDefault();
                            store.dispatch(setAuthState({ step: AUTH_SIGNUP }));
                            // toggleNavMenu();
                          }}
                        >
                          Join
                        </a>
                      </div>
                      <div className="login-btn">
                        Already have an account?{" "}
                        <a
                          className="login"
                          data-toggle="modal"
                          data-target={`#${AUTHENTICATION_MODAL_ID}`}
                          onClick={() => {
                            // toggleNavMenu();
                            store.dispatch(setAuthState({ step: AUTH_SIGNIN }));
                          }}
                          href="#"
                        >
                          Log in
                        </a>
                      </div>
                    </div>
                  ) : null}
                </ul>
              </div>
              <div className="flex-fill" />
              <div className="nav-right-menu d-flex align-items-center">
                <button
                  aria-label="Search"
                  className="btn search-btn"
                  onClick={() => {
                    this.setState({ toggleSearch: !this.state.toggleSearch }, () => {
                      if (this.state.toggleSearch) {
                        const NAV = document.getElementById("search-input-nav");
                        const TICKER = document.getElementById("ticker_tap");
                        if (NAV) {
                          NAV.focus();
                        }
                        if (TICKER) {
                          $(".navbar").addClass("addTopMargin");
                        }
                      }
                    });
                  }}
                  type="button"
                >
                  {SVG.searchAlt}
                  <span style={{ display: "none" }}>Search</span>
                </button>
                <form
                  className="form-inline search-block"
                  onSubmit={(event) => {
                    // needed to prevent enter key press from submitting form
                    event.preventDefault();
                  }}
                >
                  <div className={this.state.toggleSearch ? "search-form search-form-shown" : " search-form"}>
                    <SearchComponent
                      placeholder="Search Radivision"
                      toggleSearch={this.handleSearch}
                      searchComponentOpened={this.state.toggleSearch}
                      id="search-input-nav"
                    />
                  </div>
                  <div
                    className="search-overley"
                    onClick={() =>
                      this.setState({ toggleSearch: !this.state.toggleSearch }, () => {
                        const TICKER = document.getElementById("ticker_tap");
                        if (TICKER) {
                          $(".navbar").addClass("addTopMargin");
                        }
                      })
                    }
                  />
                </form>
                {value.user ? (
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown picture-dropdown  d-md-block ">
                      <a
                        className="nav-link dropdown-toggle profile-picture"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-label="User Menu"
                      >
                        {value.user && value.user.previews ? (
                          <LazyLoad once={true}>
                            <ProgressiveImage
                              src={
                                ImageHelper.fetchOptimizedImageUrl({
                                  imageType: "PREVIEW",
                                  preview: {
                                    content: value.user.previews,
                                    previewKind: PreviewKind.HEADSHOT,
                                    topLevelMedia: TopLevelMediaKind.IMAGE,
                                    isDefaultPreview: true,
                                    type: GRAPHQL_TYPE_PERSON,
                                  },
                                  desiredDimensions: {
                                    containerWidthRatio: 3 / 12,
                                    numberOfItems: 4,
                                  },
                                  revision: value.user.revision,
                                }).requestedResolutionUrl
                              }
                              placeholder={
                                ImageHelper.fetchOptimizedImageUrl({
                                  imageType: "PREVIEW",
                                  preview: {
                                    content: value.user.previews,
                                    previewKind: PreviewKind.HEADSHOT,
                                    topLevelMedia: TopLevelMediaKind.IMAGE,
                                    isDefaultPreview: true,
                                    type: GRAPHQL_TYPE_PERSON,
                                  },
                                  desiredDimensions: {
                                    containerWidthRatio: 3 / 12,
                                    numberOfItems: 4,
                                  },
                                  revision: value.user.revision,
                                }).placeHolderUrl
                              }
                            >
                              {(src: any, LOADING: any, srcSetData: any) => <img loading="lazy" src={src} />}
                            </ProgressiveImage>
                          </LazyLoad>
                        ) : (
                          <LazyLoad once={true}>
                            <ProgressiveImage src={DEFAULT_IMAGE} placeholder={DEFAULT_IMAGE}>
                              {(src: any, LOADING: any, srcSetData: any) => <img loading="lazy" src={src} />}
                            </ProgressiveImage>
                          </LazyLoad>
                        )}
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a
                          aria-label="My Profile"
                          className={`dropdown-item${
                            value.user !== null &&
                            value.user !== undefined &&
                            window.location.href === value.user.landingPageUrl
                              ? " active"
                              : ""
                          }`}
                          onClick={() => {
                            const { profileUrl } = getProfileInfo(value.user);
                            Router.navigateTo(profileUrl);
                          }}
                          href="#"
                        >
                          My Profile
                        </a>
                        <a
                          className={`dropdown-item${
                            value.user !== null &&
                            value.user !== undefined &&
                            window.location.href === value.user.landingPageUrl
                              ? " active"
                              : ""
                          }`}
                          onClick={() => {
                            // window.location.href =
                            //   value.user !== null && value.user !== undefined
                            //     ? `${value.user.landingPageUrl}#MycompanyProfiles`
                            //     : null;
                            Router.navigateTo(
                              `/person/${value?.user?.fullName}/${value?.user?.id}`,
                              undefined,
                              undefined,
                              "About",
                            );
                          }}
                          href="#"
                        >
                          My Companies
                        </a>
                        {Array.isArray(value?.user?.entities) &&
                          value.user?.entities?.map((entity, index) => {
                            if (index > 7) {
                              return null;
                            }
                            return (
                              <a
                                key={`user-company-${entity.id}`}
                                className={`dropdown-item entity-item`}
                                href={entity.landingPageUrl}
                                onClick={(e) => {
                                  e.preventDefault();
                                  const { profileUrl } = getProfileInfo(entity);

                                  Router.navigateTo(profileUrl);
                                  window.location.hash = "Posts";
                                }}
                              >
                                {entity.name}
                              </a>
                            );
                          })}
                        <a
                          className={`dropdown-item setting${this.state.activePage === "saved" ? " active" : ""}`}
                          onClick={() => Router.to("saved")}
                        >
                          My List
                        </a>
                        <a
                          className={`dropdown-item setting${this.state.activePage === "following" ? " active" : ""}`}
                          onClick={() => Router.to("following")}
                        >
                          Following
                        </a>
                        <a
                          className={`dropdown-item setting${this.state.activePage === "settings" ? " active" : ""}`}
                          onClick={() => Router.to("account")}
                        >
                          Settings
                        </a>
                        <a
                          className="dropdown-item signout"
                          onClick={() => {
                            import("../../authentication/cognito-client").then((module) => {
                              return module.CognitoClient.signOut(true);
                            });
                          }}
                        >
                          Sign out
                        </a>
                        {/* <CreateCompanyButton /> */}
                      </div>
                    </li>
                  </ul>
                ) : (
                  <div className="navbar-browse">
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <a
                          className="nav-link semibold sign-in desktop"
                          data-toggle="modal"
                          data-target={`#${AUTHENTICATION_MODAL_ID}`}
                          onClick={() => {
                            store.dispatch(setAuthState({ step: AUTH_SIGNIN }));
                          }}
                          href="#"
                        >
                          Login
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link semibold join"
                          data-toggle="modal"
                          data-backdrop="static"
                          data-keyboard="false"
                          data-target={`#${AUTHENTICATION_MODAL_ID}`}
                          onClick={() => {
                            store.dispatch(setAuthState({ step: AUTH_SIGNUP }));
                            // toggleNavMenu();
                          }}
                          href="#"
                        >
                          Join
                        </a>
                      </li>
                    </ul>
                    {/* {hideNavigation ? (
                      <h5
                        className="browse"
                        onClick={(e) => {
                          e?.preventDefault();
                          Router.navigateTo("tour");
                        }}
                      >
                        Browse for now
                      </h5>
                    ) : (
                      <></>
                    )} */}
                  </div>
                )}
              </div>
              <button
                className={`hamburger hamburger--collapse navbar-toggler${
                  this.state.hamburgerDropDown ? " is-active" : ""
                } ${hideNavigation ? "hide" : ""}`}
                type="button"
                aria-label="Toggle navigation"
                onClick={() =>
                  this.setState({
                    hamburgerDropDown: !this.state.hamburgerDropDown,
                  })
                }
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </nav>
            {!this.props.transparent || window.innerWidth < 576 ? (
              <div className="nav-placeholder" style={{ height: this.state.heightInPixels }}>
                &nbsp;
              </div>
            ) : null}
          </div>
        )}
      </REACT_USER_CONTEXT.Consumer>
    );
  }
}
// {value.user ? null : ( // <UGCModal user={value.user} />
//   <AccountControl activeModal={this.state.modalController} modalId={ACCOUNT_CONTROL_MODAL_ID} />
// )}

const mapState = (state: AppState) => ({
  pageYOffset: state.app.pageYOffset,
  device: state.app.device,
});

export const Navbar = connect(mapState)(Component);

import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { connect } from "react-redux";
import { LeftArrow, RightArrow } from "./arrows";
import { Card } from "./card";
import { EVENTS } from "./events";
import { RsvpSuccessModal } from "./rsvp-modal";
import { FetchListPayload, FetchListAction } from "../../../redux/cache/types";
import { EventsListId } from "../../../constants/content-ids";
import { AppState } from "../../../redux";
import { fetchList } from "../../../redux/actions";
import "./carousel.scss";
import { HorizontalScroller } from "../../../react-components/page-framework/horizontal-scroller";
import isEmpty from "lodash.isempty";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

interface ComponentProps {}

interface MapDispatchProps {
  fetchList: (payload: FetchListPayload) => FetchListAction;
}
interface MapStateProps {
  list: any;
  inProgress: boolean;
}

type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const EventsCarouselComponent = (props: Props) => {
  const { fetchList, list, inProgress } = props;

  const items = list?.list?.list?.items?.edges?.map((edge) => edge?.listItem?.item);

  React.useEffect(() => {
    if (!list && !inProgress) {
      fetchList({ variables: { listId: EventsListId } });
    }
  }, [fetchList, inProgress, list]);

  return (
    <>
      <div className="events-panel">
        <h4 className="panel-title">Upcoming Events</h4>
        <HorizontalScroller rootClassName={"events-carousel-container"}>
          {items
            ?.filter((e) => !isEmpty(e.start))
            .sort((a: any, b: any) => new Date(a.start).getTime() - new Date(b.start).getTime())
            .map((event, index) => (
              <Card event={event} key={`event-${index}`} itemId={event?.id} />
            ))}
        </HorizontalScroller>
      </div>
      <RsvpSuccessModal />
    </>
  );
};
const mapState = (state: AppState) => ({
  list: state.cache.store[EventsListId],
  inProgress: state.cache.inProgress[EventsListId],
});

const mapDispatch = {
  fetchList: fetchList,
};

export const EventsCarousel = connect(mapState, mapDispatch)(EventsCarouselComponent);

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2019-01-18
 * @filename: facebook-post-kind.ts
 * @description: Declaration of facebook post kind payload enum
 */

/**
 *
 * The details of a facebook post kind
 * @export
 * @enum TweetMediaKind
 */
export enum FacebookPostKind {
  /**
   * The post contains a link to another post
   */
  LINK,
  /**
   * The post contains a business offer
   */
  OFFER,
  /**
   * The post contains an image
   */
  PHOTO,
  /**
   * The post contains a status update
   */
  STATUS,
  /**
   * The post contains a video
   */
  VIDEO
}

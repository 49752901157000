/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type SearchFacetInput = {
    readonly buckets?: ReadonlyArray<string> | null;
    readonly field: string;
    readonly size?: number | null;
    readonly sort?: string | null;
};
export type searchQueryVariables = {
    readonly query: string;
    readonly cursor?: string | null;
    readonly facets?: ReadonlyArray<SearchFacetInput> | null;
    readonly filterQuery?: string | null;
    readonly order?: string | null;
};
export type searchQueryResponse = {
    readonly search: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly connection: {
            readonly count: number | null;
            readonly facets: ReadonlyArray<{
                readonly name: string | null;
                readonly buckets: ReadonlyArray<{
                    readonly count: number | null;
                    readonly value: string | null;
                }> | null;
            }> | null;
            readonly edges: ReadonlyArray<{
                readonly cursor: string;
                readonly node: {
                    readonly __typename: string;
                    readonly activities: ReadonlyArray<string> | null;
                    readonly area: string | null;
                    readonly authors: ReadonlyArray<string> | null;
                    readonly categories: ReadonlyArray<string> | null;
                    readonly country: string | null;
                    readonly countryCode: string | null;
                    readonly durationInSeconds: number | null;
                    readonly fundingRound: number | null;
                    readonly id: string;
                    readonly investments: ReadonlyArray<string> | null;
                    readonly isPublic: boolean | null;
                    readonly kind: string | null;
                    readonly label: string | null;
                    readonly linkUrl: string | null;
                    readonly location: string | null;
                    readonly name: string | null;
                    readonly previewUrl: string | null;
                    readonly landingPageUrl: string | null;
                    readonly published: unknown | null;
                    readonly publisher: string | null;
                    readonly responsibility: string | null;
                    readonly settlement: string | null;
                    readonly subTitle: string | null;
                    readonly tagLine: string | null;
                    readonly tags: ReadonlyArray<string> | null;
                    readonly title: string | null;
                };
            }> | null;
        } | null;
    } | null;
};
export type searchQuery = {
    readonly response: searchQueryResponse;
    readonly variables: searchQueryVariables;
};



/*
query searchQuery(
  $query: String!
  $cursor: String
  $facets: [SearchFacetInput!]
  $filterQuery: String
  $order: String
) {
  search(query: $query, cursor: $cursor, facets: $facets, filterQuery: $filterQuery, order: $order) {
    hasErrors
    errors {
      id
      location
    }
    connection {
      count
      facets {
        name
        buckets {
          count
          value
        }
      }
      edges {
        cursor
        node {
          __typename
          activities
          area
          authors
          categories
          country
          countryCode
          durationInSeconds
          fundingRound
          id
          investments
          isPublic
          kind
          label
          linkUrl
          location
          name
          previewUrl
          landingPageUrl
          published
          publisher
          responsibility
          settlement
          subTitle
          tagLine
          tags
          title
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "facets",
    "type": "[SearchFacetInput!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filterQuery",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "order",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "location",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "search",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "cursor",
        "variableName": "cursor"
      },
      {
        "kind": "Variable",
        "name": "facets",
        "variableName": "facets"
      },
      {
        "kind": "Variable",
        "name": "filterQuery",
        "variableName": "filterQuery"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      },
      {
        "kind": "Variable",
        "name": "query",
        "variableName": "query"
      }
    ],
    "concreteType": "SearchPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "connection",
        "storageKey": null,
        "args": null,
        "concreteType": "SearchHitsConnection",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "facets",
            "storageKey": null,
            "args": null,
            "concreteType": "SearchFacet",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "buckets",
                "storageKey": null,
                "args": null,
                "concreteType": "SearchFacetBucket",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "value",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "SearchHitEdge",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "SearchHit",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "activities",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "area",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "authors",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "categories",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "country",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "countryCode",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "durationInSeconds",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fundingRound",
                    "args": null,
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "investments",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isPublic",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "kind",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "label",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "linkUrl",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "previewUrl",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "landingPageUrl",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "published",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "publisher",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "responsibility",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "settlement",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "subTitle",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "tagLine",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "tags",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "searchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "searchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "searchQuery",
    "id": null,
    "text": "query searchQuery(\n  $query: String!\n  $cursor: String\n  $facets: [SearchFacetInput!]\n  $filterQuery: String\n  $order: String\n) {\n  search(query: $query, cursor: $cursor, facets: $facets, filterQuery: $filterQuery, order: $order) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    connection {\n      count\n      facets {\n        name\n        buckets {\n          count\n          value\n        }\n      }\n      edges {\n        cursor\n        node {\n          __typename\n          activities\n          area\n          authors\n          categories\n          country\n          countryCode\n          durationInSeconds\n          fundingRound\n          id\n          investments\n          isPublic\n          kind\n          label\n          linkUrl\n          location\n          name\n          previewUrl\n          landingPageUrl\n          published\n          publisher\n          responsibility\n          settlement\n          subTitle\n          tagLine\n          tags\n          title\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '1f24119410ef686aeb9ec2e8d1436ba1';
export default node;

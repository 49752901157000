/**
 * @author Ahmed Samer
 * @date   2019-02-20
 * @description Declaration of the social media credentials  interface declaring the properties of GraphQL type.
 * @filename social-media-credential.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import { Node } from "./node";
import { SocialMediaPlatform } from "./social-media-platform";

/**
 * The __typeID of the Social Media credential type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_SOCIAL_MEDIA_CREDENTIAL: string = "SocialMediaCredential";


/**
 *  A social media credential
 *
 * @extends {Node}
 * @interface
 * @template D
 */
export interface SocialMediaCredential<D extends Date | string = string>
  extends Node<D> {
    /**
     * Public credential information
     * 
     * @type {string}
     * @memberof SocialMediaCredential
     */
    credential: string;
    /**
     * The social media platform associated with the social media credential
     *
     * @type {SocialMediaPlatform!}
     * @memberof SocialMediaCredential
     */
    platform: SocialMediaPlatform;
  }

import { graphql } from "react-relay";

export const ORIGINAL_SHOWS_QUERY = graphql`
  query originalShowsQuery($listCollectionId: ID!) {
    listCollection(id: $listCollectionId) {
      errors {
        created
        id
        location
        type
      }
      hasErrors
      listCollection {
        description
        id
        label
        revision
        lists {
          ... on FixedList {
            __typename
            landingPageUrl
            description
            id
            moreLikeThis
            badges {
              imageLink
              link
              previews {
                count
                ...previewsConnection_edges @relay(mask: false)
              }
            }
            items {
              pageInfo {
                hasNextPage
              }
              edges {
                cursor
                listItem {
                  item {
                    __typename
                    ... on OriginalContentStory {
                      ...originalContentStoryItem @relay(mask: false)
                    }
                    ... on Poster {
                      ...poster_item @relay(mask: false)
                    }
                  }
                  question
                }
              }
            }
            label
            name
            previews {
              count
              ...previewsConnection_edges @relay(mask: false)
            }
            subTitle
            title
          }
        }
        name
        subTitle
        title
      }
    }
  }
`;

import { graphql, GraphQLTaggedNode } from "react-relay";

export const REMOVE_FROM_ENTITY_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation removeFromEntityMutation($input: RemoveFromEntityInput!) {
    removeFromEntity(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      entity {
        ...entity_fullProfile @relay(mask: false)
      }
    }
  }
`;

/**
 * @Author: Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-12-13
 * @description Implementation of the ClauseOperator class and declaration of the SearchHit.
 * @filename search-hit.ts
 */
/**
 *
 * The __typeID of the CloudSearchHitItem GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_SEARCH_HIT: string = "SearchHit";

/**
 * An enumeration of the kinds of cloud search hit.
 *
 * @enum
 */
export enum HitItemKind {
  /**
   * A story referencing an article, a written work published in printed or electronic form.
   */
  ARTICLE = "ARTICLE",

  /**
   * A story referencing a book, a long-form written work published in printed or electronic form.
   */
  BOOK = "BOOK",

  /**
   * A company or other formal entity such as a partnership.
   */
  ENTITY = "ENTITY",

  /**
   * A story referencing a newsletter, a bulletin issued periodically to the members of a society, business, or organization.
   */
  NEWSLETTER = "NEWSLETTER",

  /**
   * A story referencing an online course, a course of instruction delivered through the internet.
   */
  ONLINE_COURSE = "ONLINE_COURSE",

  /**
   * A story referencing an episode of original video content.
   */
  ORIGINAL_CONTENT = "ORIGINAL_CONTENT",

  /**
   * A physical person.
   */
  PERSON = "PERSON",

  /**
   * A story referencing an externally hosted audio or video.
   */
  PODCAST = "PODCAST",

  /**
   * A fixed-list referencing .
   */
  FIXED_LIST = "FIXED_LIST",
  /**
   * A fixed-list referencing .
   */
  DYNAMIC_LIST = "DYNAMIC_LIST",
  /**
   * A fixed-list referencing .
   */
  LIST_COLLECTION = "LIST_COLLECTION"
}

/**
 * Information pertaining to a search facet
 * returned in the response to a search request.
 *
 * @export
 * @interface SearchFacet
 */
export interface SearchFacet {
  /**
   * The statistics pertaining to the facet
   *
   * @type {SearchFacetBucket[]}
   * @memberof SearchFacet
   */
  buckets?: SearchFacetBucket[];

  /**
   * The name of the facet.
   *
   * @type {string}
   * @memberof SearchFacet
   */
  name?: string;
}

/**
 * Information pertaining to a bucket in the facet
 * returned in the response to a search request.
 *
 * @export
 * @interface SearchFacetBucket
 */
export interface SearchFacetBucket {
  /**
   * The number of hits for the facet bucket with the given value.
   *
   * @type {number}
   * @memberof SearchFacetBucket
   */
  count?: number;

  /**
   * The value of the facet bucket
   *
   * @type {string}
   * @memberof SearchFacetBucket
   */
  value?: string;
}

/**
 * A result returned from an AWS cloud search operation
 *
 * @export
 * @interface CloudSearchHit
 */
export interface SearchHit<D extends Date | string = string> {
  /**
   * The type of entrepreneurial activities performed by the (entity) hit item.
   *
   * @type {string[]}
   * @memberof SearchHit
   */
  activities?: string[];

  /**
   * The name of an area, for example a state or county, associated with the cloud search hit.
   *
   * @type {string}
   */
  area?: string;

  /**
   * The authors of the cloud search hit.
   *
   * @type {Array<string>}
   */
  authors?: string[];

  /**
   * The type of entrepreneurial activities performed by the (person) hit item.
   *
   * @type {string[]}
   * @memberof SearchHit
   */
  categories?: string[];

  /**
   * The name of the country associated with the cloud search hit.
   *
   * @type {string}
   */
  country?: string;

  /**
   * The ISO 3166-1 country code associated with the cloud search hit.
   *
   * @type {string}
   */
  countryCode?: string;

  /**
   * The duration of the cloud search hit in seconds.
   *
   * @type {string}
   */
  durationInSeconds?: number;

  /**
   * The unique ID of the cloud search hit.
   *
   * @type {string}
   */
  id: string;

  /**
   * A flag which is true if the  cloud search hit is public.
   *
   * @type {boolean}
   */
  isPublic?: boolean;

  /**
   * The kind of cloud search hit.
   *
   * @type {CloudSearchHitKind}
   */
  kind?: HitItemKind;

  /**
   * A label of the cloud search hit.
   *
   * @type {CloudSearchHitKind}
   */
  label?: string;

  /**
   * A URL which is a link for details of the cloud search hit.
   *
   * @type {string}
   */
  linkUrl?: string;

  /**
   * The geographical location (as latitude and longitude) of the cloud search hit.
   *
   * @type {string}
   */
  location?: string;

  /**
   * The full name of the hit.
   *
   * @type {string}
   */
  name?: string;

  /**
   * A URL which to a preview image for the cloud search hit.
   *
   * @type {string}
   */
  previewUrl?: string;

  /**
   * The date and time, as an ISO-8601 formatted UTC date string, when the cloud search hit was published.
   *
   * @type {string}
   */
  published?: D;

  /**
   * The name of the publisher of the cloud search hit.
   *
   * @type {string}
   */
  publisher?: string;

  /**
   * The current responsibility of the cloud search hit.
   *
   * @type {string}
   */
  responsibility?: string;

  /**
   * The name of a settlement, for example a city or town, associated with the cloud search hit.
   *
   * @type {string}
   */
  settlement?: string;

  /**
   * The sub-title of the cloud search hit.
   *
   * @type {string}
   */
  subTitle?: string;

  /**
   * The tag line of the cloud search hit.
   *
   * @type {string}
   */
  tagLine?: string;

  /**
   * The collection of tags associated with the cloud search hit.
   *
   * @type {Array<string>}
   */
  tags?: string[];

  /**
   * The title of the cloud search hit.
   *
   * @type {string}
   */
  title?: string;

  /**
   * The landing page url of the cloud search hit.
   *
   * @type {string}
   */
  landingPageUrl?: string;

  /**
   * The GraphQL typename for cloud search hit.
   *
   * @type {string}
   */
  __typename: string;
}

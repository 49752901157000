/**
 * @author Ahmed Serag
 * @date   2018-03-28
 * @description Declaration of the GeographicalLocation interface.
 * @filename geographical-location.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the GraphQL Geographical Location object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_GEOGRAPHICAL_LOCATION: string =
  "GeographicalLocation";

/**
 *  A geographical location
 *
 * @template D
 * @interface
 */
export interface GeographicalLocation<D extends Date | string = string>
  extends Node<D> {
  
  /**
   * The name of an area, for example a state or country, associated with the location.
   *
   * @type {string}
   * @memberof GeographicalLocation
   */
  area?: string;

  /**
   * The name of a settlement, for example a city or town, associated with the location.
   *
   * @type {string}
   * @memberof GeographicalLocation
   */
  settlement?: string;
  
  /**
   * The name of the country associated with the location.
   *
   * @type {string}
   * @memberof GeographicalLocation
   */
  country?: string;
  
  /**
   * The ISO 3166-1 country code associated with the location
   *
   * @type {string}
   * @memberof GeographicalLocation
   */
  countryCode?: string;

  /**
   * A label describing the location
   *
   * @type {string}
   * @memberof GeographicalLocation
   */
  label?: string;

  /**
   * The open location code for the location.
   * https://en.wikipedia.org/wiki/Open_Location_Code
   *
   * @type {string}
   * @memberof GeographicalLocation
   */
  openLocationCode?: string;
}

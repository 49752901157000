/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-09-03
 * @description Implementation of the GraphQL query to retrieve a show.
 * @filename list-query.ts
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL query to retrieve list of type Fixed or Dynamic List.
 *
 * @type {GraphQLTaggedNode}
 */
export const LIST_QUERY: GraphQLTaggedNode = graphql`
  query listQuery($listId: ID!, $after: String, $first: Int) {
    list(id: $listId) {
      errors {
        created
        id
        location
        type
      }
      hasErrors
      list {
        __typename
        ... on DynamicList {
          description
          id
          label
          name
          items {
            edges {
              cursor
              listItem {
                item {
                  __typename
                  ... on SearchHit {
                    ...searchHit_item @relay(mask: false)
                  }
                }
              }
            }
          }
          previews {
            ...previewsConnection_edges @relay(mask: false)
          }
          subTitle
          title
          tags {
            id
            label
          }
        }
        ... on FixedList {
          description
          id
          landingPageUrl
          moreLikeThis
          badges {
            imageLink
            link
            previews {
              ...previewsConnection_edges @relay(mask: false)
            }
          }
          items(after: $after, first: $first) {
            pageInfo {
              hasNextPage
            }
            edges {
              cursor
              listItem {
                list {
                  ... on FixedList {
                    previews {
                      count
                      ...previewsConnection_edges @relay(mask: false)
                    }
                    name
                    id
                    label
                    description
                    landingPageUrl
                    revision
                    subTitle
                    title
                    tags {
                      id
                      label
                    }
                    badges {
                      imageLink
                      link
                      previews {
                        count
                        ...previewsConnection_edges @relay(mask: false)
                      }
                    }
                  }
                }
                item {
                  __typename
                  ... on Event {
                    ...eventItem @relay(mask: false)
                  }
                  ... on PitchVideoStory {
                    ...pitchVideoStoryItem @relay(mask: false)
                  }
                  ... on OriginalContentStory {
                    ...originalContentStoryItem @relay(mask: false)
                  }
                  ... on PodcastStory {
                    ...podcastStoryItem @relay(mask: false)
                  }
                  ... on Poster {
                    ...poster_item @relay(mask: false)
                  }
                  ... on Entity {
                    ...entityItem_item @relay(mask: false)
                  }
                  ... on Person {
                    ...personItem_item @relay(mask: false)
                  }
                  ... on ArticleStory {
                    ...articleStory_item @relay(mask: false)
                  }
                  ... on BookStory {
                    ...bookStory_item @relay(mask: false)
                  }
                  ... on NewsletterStory {
                    ...newsletterStory_item @relay(mask: false)
                  }
                  ... on OnlineCourseStory {
                    ...onlineCourseStory_item @relay(mask: false)
                  }
                }
                question
              }
            }
          }
          label
          name
          previews {
            ...previewsConnection_edges @relay(mask: false)
          }
          # questionnaire {
          #   id
          #   questions {
          #     __typename
          #     ... on MultipleChoiceQuestion {
          #       id
          #       title
          #       choices {
          #         acceptsText
          #         person {
          #           __typename
          #           landingPageUrl
          #           id
          #           fullName
          #           previews {
          #             edges {
          #               preview {
          #                 link
          #                 kind
          #                 mediaAsset {
          #                   files {
          #                     name
          #                     path
          #                   }
          #                   topLevelMediaType {
          #                     kind
          #                   }
          #                 }
          #               }
          #             }
          #           }
          #           revision
          #         }
          #       }
          #     }
          #   }
          # }
          subTitle
          title
          tags {
            id
            label
          }
          revision
        }
      }
    }
  }
`;
                // list {
                //   ... on FixedList {
                //     ...fixedList @relay(mask: false)
                //   }
                // }

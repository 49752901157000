/**
 * @author: Adham El Banhawy
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2018-08-21
 * @filename: twitter-access-token-query.ts
 * @description: Implementation of GraphQL query to retrieve twitter access token
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL query to retrieve the twitter access token.
 *
 * @type {GraphQLTaggedNode}
 */
export const TWITTER_ACCESS_TOKEN_GRAPHQL_QUERY: GraphQLTaggedNode = graphql`
  query twitterAccessTokenQuery($oauthToken: String!, $oauthVerifier: String!) {
    twitterUser(oauthToken: $oauthToken, oauthVerifier: $oauthVerifier) {
      hasErrors
      errors {
        created
        id
        location
        type
      }
      twitterUser {
        id
        twitterUserId
        openIdToken
      }
    }
  }
`;

/**
 * @author W. Alex Livesley
 * @copyright Copyright 2018 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2018-02-04
 * @description Implementation of the Types class.
 * @filename types.ts
 */
import { Iso8601 } from "./iso-8601";

/**
 * An enumeration of the names of types returned by the typeof function.
 *
 * @type {String}
 */
export type Type =
  | "boolean"
  | "function"
  | "number"
  | "object"
  | "string"
  | "symbol";

/**
 * A collection of utility functions concerning type determination.
 */
export class Types {
  /**
   * The name of the boolean type.
   *
   * @static
   * @type {Type}
   */
  public static readonly BOOLEAN: Type = "boolean";

  /**
   * The name of the function type.
   *
   * @static
   * @type {Type}
   */
  public static readonly FUNCTION: Type = "function";

  /**
   * The name of the number type.
   *
   * @static
   * @type {Type}
   */
  public static readonly NUMBER: Type = "number";

  /**
   * The name of the object type.
   *
   * @static
   * @type {Type}
   */
  public static readonly OBJECT: Type = "object";

  /**
   * The name of the string type.
   *
   * @static
   * @type {Type}
   */
  public static readonly STRING: Type = "string";

  /**
   * The name of the symbol type.
   *
   * @static
   * @type {Type}
   */
  public static readonly SYMBOL: Type = "symbol";

  /**
   * Returns a flag which is true if a given value is a boolean.
   *
   * @static
   * @param {*} value The given value.
   *
   * @return {boolean} A flag which is true if a given value is a boolean.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isBoolean(value: any): boolean {
    return typeof value === Types.BOOLEAN;
  }

  /**
   * Returns a flag which is true if a given value is an object without any direct properties.
   *
   * @static
   * @param {*} value The given value.
   *
   * @return {boolean} A flag which is true if a given value is an object without properties.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isObjectWithoutOwnProperties(value: any): boolean {
    return Types.isObject(value) === true && Object.keys(value).length === 0;
  }

  /**
   * Returns a flag which is true if a given value is an object with direct properties.
   *
   * @static
   * @param {*} value The given value.
   *
   * @return {boolean} A flag which is true if a given value is an object with properties.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isObjectWithOwnProperties(value: any): boolean {
    return Types.isObject(value) === true && Object.keys(value).length > 0;
  }

  /**
   * Returns a flag which is true if a given value is a function.
   *
   * @static
   * @param {*} value The given value.
   *
   * @return {boolean} A flag which is true if a given value is a function.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isFunction(value: any): boolean {
    return typeof value === Types.FUNCTION;
  }

  /**
   * Returns a flag which is true if a given value is a number.
   *
   * @static
   * @param {*} value The given value.
   *
   * @return {boolean} A flag which is true if a given value is a number.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isNumber(value: any): boolean {
    return typeof value === Types.NUMBER;
  }

  /**
   * Returns a flag which is true if a given value is an object.
   *
   * @static
   * @param {*} value The given value.
   *
   * @return {boolean} A flag which is true if a given value is an object.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isObject(value: any): boolean {
    return (
      value !== null &&
      typeof value === Types.OBJECT &&
      Array.isArray(value) === false
    );
  }

  /**
   * Returns a flag which is true if a given value is a date/time serialized con formant to ISO-8601.
   *
   * @static
   * @param {*} value The given value.
   *
   * @return {boolean} A flag which is true if a given value is a date/time serialized con formant to ISO-8601.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isIso8601SerializedDateTime(value: any): boolean {
    return (
      Types.isString(value) === true &&
      Iso8601.REGEX_ISO_8601_DATE_TIME.test(value) === true
    );
  }

  /**
   * Returns a flag which is true if a given value is a string.
   *
   * @static
   * @param {*} value The given value.
   *
   * @return {boolean} A flag which is true if a given value is a string.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isString(value: any): boolean {
    return typeof value === Types.STRING;
  }

  /**
   * Returns a flag which is true if a given value is a symbol.
   *
   * @static
   * @param {*} value The given value.
   *
   * @return {boolean} A flag which is true if a given value is a symbol.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isSymbol(value: any): boolean {
    return typeof value === Types.SYMBOL;
  }

  /**
   * Returns the boolean equivalent of a given value.
   *
   * @static
   * @param {*} value The value to be converted.
   *
   * @return {boolean} The boolean equivalent of a value.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static toBoolean(value: any): boolean | undefined {
    return value
      ? ["1", "true", true].indexOf(value.toString()) !== -1
      : undefined;
  }

  /**
   * Returns the Number equivalent of a value.
   *
   * @static
   * @param {*} value
   * @returns {(number | undefined)}
   * @memberof Types
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static toNumber(value: any): number | undefined {
    return value === undefined ||
      value === null ||
      value.length < 1 ||
      Number.isNaN(Number(value))
      ? undefined
      : Number(value);
  }

  /**
   * Returns the String equivalent of a value if the string isn't empty.
   *
   * @static
   * @param {string} value
   * @returns {(string | undefined)}
   * @memberof Types
   */
  public static toString(value: string): string | undefined {
    return value === undefined || value === null || value.length < 1
      ? undefined
      : value;
  }

  /**
   * check if the provided value isn't empty.
   *
   * @static
   * @param {*} value
   * @returns {(Array<any> | undefined)}
   * @memberof Types
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isEmpty(value: any): any | undefined {
    return value === undefined || value === null || value.length === 0;
  }
}

/**
 * @author William Alexander Livesley
 * @date   2018-04-22
 * @description Declaration of the OnlineCourseStory interface.
 * @filename online-course-story.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Story } from "./story";

/**
 * The __typeID of the Online Course Story type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_ONLINE_COURSE_STORY: string = "OnlineCourseStory";

/**
 * A story concerning an online-course.
 *
 * @extends {Story}
 * @template D
 * @interface
 */
export interface OnlineCourseStory<D extends Date | string = string>
  extends Story<D> {
  /**
   * A non-normative description of the cost of the course
   *
   * @type {string}
   */
  cost?: string;

  /**
   * The URL of the online-course.
   *
   * @type {string}
   */
  link?: string;

  /**
   * The estimated time to execute the course in seconds.
   *
   * @type {number}
   */
  timeRequiredInSeconds?: number;
}

.upload-profile-images {
  position: relative;
  padding-bottom: 40px;
  .upload-background {
    width: 100%;
    height: 140px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #313131;
    cursor: pointer;
    position: absolute;
    input {
      position: absolute;
      visibility: hidden;
      top: 0px;
      width: 100%;
      height: 140px;
      opacity: 0;
    }
    button {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 14px;
      background-color: rgba(0,0,0,0.2);
      border-radius: 15px;
      border: 0px;
      padding: 3px 10px;
      color: #eee;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &:hover {
        color: black;
        background-color: white;
      }
      .camera {
        display: none;
        margin: 0px 5px;
        svg {
          fill: white;
          width: 14px;
          height: 14px;
          filter: drop-shadow(0px 0px 2px rgb(0 0 0 / 0.5));
        }
      }
    }
  }
  .upload-image {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #313131;
    border: 2px solid #171717;
    width: 140px;
    height: 140px;
    border-radius: 70px;
    z-index: 1;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 50%);
    cursor: pointer;
    .icon {
      position: absolute;
      top: 0px;
      width: 140px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        transform: scale(2);
      }
    }
    .camera {
      position: absolute;
      top: 70px;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.5;
      pointer-events: none;
      svg {
        fill: white;
        filter: drop-shadow(0px 0px 2px rgb(0 0 0 / 1));
      }
    }
    input {
      width: 140px;
      height: 210px;
      opacity: 0;
      &:hover ~ button {
        color: white;
        background-color: #4cb4e7;
      }
      &:hover {
        .camera {
          opacity: 1;
        }
      }
    }
    button {
      border: 1px solid #4cb4e7;
      transform: translateY(-50px);
      font-size: 14px;
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 20px;
      padding: 4px 15px 4px 15px;
      pointer-events: none;
      color: #4cb4e7;
      white-space: nowrap;
      width: 140px;
    }
  }
}

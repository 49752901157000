import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { ProfileModuleState } from "../redux/profile";
import { EDIT_PROFILE_SELECT_BANNER, EDIT_PROFILE_PAGE_INFO } from "../redux/profile/constants";
import { UploadImageButton } from "../profile/upload-image-button";
import { ModalFooter } from "../../../react-components/modals/modal-form-elements";
import { Or } from "../profile/or-line-break";
import { PreviewKind } from "@radivision/graphql";
import { BannersList } from "../profile/banners-list";
import { setProfileState } from "../redux/profile/actions";
import { SetProfileStateAction, SetProfileStatePayload } from "../redux/profile/types";
import { Cancel } from "../../../react-components/modals/modal-form-elements";

interface ComponentProps {}

interface MapDispatchProps {
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}

interface MapStateProps {
  screen: string;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { screen, setProfileState } = props;
  const shouldDisplay = screen === EDIT_PROFILE_SELECT_BANNER;

  const onCancel = React.useCallback(() => {
    setProfileState({ screen: EDIT_PROFILE_PAGE_INFO });
  }, [setProfileState]);

  if (!shouldDisplay) return null;

  return (
    <Container>
      <div className="form-body">
        <UploadImageButton previewKind={PreviewKind.BACKGROUND} />
        <Or title={"or choose a banner"} />
        <BannersList />
      </div>
      <ModalFooter>
        <Cancel onCancel={onCancel} />
      </ModalFooter>
    </Container>
  );
};

export const Container = ({ children }: React.PropsWithChildren<any>) => {
  return <div className="edit-profile-change-banner edit-profile-form">{children}</div>;
};

const mapState = (state: AppState & ProfileModuleState) => ({
  screen: state.profile.screen,
});

const mapDispatch = {
  setProfileState: setProfileState,
};

export const ChangeBanner = connect(mapState, mapDispatch)(Component);

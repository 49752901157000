/**
 * @author: Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-03-29 01:56:09
 * @description Declaratin of the QuestionnaireQuestion interfaces.
 * @filename questionnaire-questions.ts
 */

/**
 * The __typeID of the Questionnaire Question type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_QUESTIONNAIRE_QUESTION: string = "Question";

/**
 * The question used inside a questionnaire.
 *
 * @export
 * @interface QuestionnaireQuestion
 */
export interface QuestionnaireQuestion {
  /**
   * The ID of the question.
   *
   * @type {string}
   */
  id?: string;

  /**
   * The content of the question.
   *
   * @type {string}
   */
  question?: string;

  /**
   * The typename of the node.
   *
   * @type {string}
   */
  __typename?: string;
}

/**
 * @author William Alexander Livesley
 * @date   2018-04-14
 * @description Declaration of the BookStory interface.
 * @filename book-story.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Story } from "./story";

/**
 * The __typeID of the API Book Story GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_BOOK_STORY: string = "BookStory";

/**
 * A story concerning a book.
 *
 * @extends {Story}
 * @template D
 * @interface
 */
export interface BookStory<D extends Date | string = string> extends Story<D> {

  /**
   * A non-normative description of the cost of the book
   *
   * @type {string}
   * @memberof BookStory
   */
  cost?: string;

  /**
   * The URL of the book.
   *
   * @type {string}
   */
  link?: string;

  /**
   * The time to read the book in seconds.
   *
   * @type {number}
   */
  timeToReadInSeconds?: number;
}

/**
 * @author William Alexander Livesley
 * @date   2018-03-22
 * @description Declaration of the Note interface.
 * @filename note.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Change } from "./change";
import { Tag } from "./tag";

/**
 * The __typeID of the Note type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_NOTE: string = "Note";

/**
 * A media asset, a video, audio file, text file or image.
 *
 * @template D
 * @interface
 */
export interface Note<D extends Date | string = string> {
  /**
   * The content of the note
   *
   * @type {string}
   */
  content?: string;

  /**
   * The change made to the referent note
   *
   * @type {Change}
   */
  change?: Change;

  /**
   * The date and time when the note was created.
   *
   * @template D
   * @type {D}
   */
  created?: D;

  /**
   * The ID of the note.
   *
   * @type {string}
   */
  id?: string;

  /**
   * A flag which is true if the note is marked as deleted.
   *
   * @type {boolean}
   */
  isDeleted?: boolean;

  /**
   * A label associated with the note
   *
   * @type {string}
   */
  label?: string;

  /**
   * The person who created the note or whose actions caused the note to be created
   *
   * @type {Person}
   */
  originator?: any;

  /**
   * The revision of the note.
   *
   * @type {string}
   */
  revision?: string;

  /**
   * The tags associated with the note.
   *
   * @type {Array<Tag>}
   */
  tags?: Tag[];

  /**
   * The typename of the note.
   *
   * @type {string}
   */
  __typename?: string;
}

/**
 * @author William A. Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-11-12
 * @description Implementation of MediaAsset class.
 * @filename media-asset.ts
 */
import { CreateMediaAssetFileInput } from "@radivision/graphql/lib/ts/graphql/mutations/create-media-asset-file-input";
import { CreateMediaAssetInput } from "@radivision/graphql/lib/ts/graphql/mutations/create-media-asset-input";
import { MediaAsset as GraphQlMediaAsset } from "@radivision/graphql/lib/ts/graphql/media-asset";
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import { commitMutation as _commitMutation } from "react-relay";
import { Environment, MutationConfig, OperationDefaults, PayloadError } from "relay-runtime";
import { CREATE_MEDIA_ASSET_GRAPHQL_MUTATION } from "../relay/mutations/create-media-asset";
import { CREATE_MEDIA_ASSET_FILE_GRAPHQL_MUTATION } from "../relay/mutations/create-media-asset-file";
import { getClientMutationId as _getClientMutationId } from "./general";
import { MediaAssetFileFunctionInput } from "./image-uploader";
import { MediaAssetFile } from "./media-asset-file";

/**
 * A regular expression to extract the media type from a file URL.
 *
 * @type {RegExp}
 */
const REXEXP_IMAGE_EXTENSION: RegExp = /image\/([a-z]+)/i;

/**
 * A map of media types to file extensions.
 */
export const MEDIA_TYPE_TO_EXTENSION: {
  [index: string]: string;
} = {
  "image/jpeg": "jpg",
  "image/jpg": "jpg",
  "image/png": "png",
  "image/svg": "svg",
};

/**
 * A map of media types to file extensions.
 */
export const DOC_TYPE_TO_EXTENSION: {
  [index: string]: string;
} = {
  "image/jpeg": "jpg",
  "image/jpg": "jpg",
  "image/png": "png",
  pdf: "pdf",
};

/**
 * A class containing utilities for media asset manipulation
 */
export class MediaAsset {
  /**
   * Returns the relevant video URL from a given media asset
   *
   * @param {GraphQlMediaAsset} mediaAsset The media asset.
   *
   * @returns {string} The URL of the video link to a media asset file.
   */
  static getVideoUrlFromMediaAsset(mediaAsset: GraphQlMediaAsset = null): string {
    return MediaAssetFile.getVideoUrlFromMediaAssetFiles(mediaAsset.files);
  }
  /**
   * Returns a promise to commit a given mutation.
   *
   * @param {Environment} environment The relay environment.
   *
   * @returns {Promise<any>} The promise to commit a given mutation.
   */
  commitMutation(environment: Environment, config: MutationConfig<OperationDefaults>): Promise<any> {
    return new Promise((resolve: (response: any) => void, reject: (e: any) => void): void => {
      config.onCompleted = (response: any, errors: PayloadError[] = null): void => {
        if (errors !== undefined && errors !== null) {
          reject(errors);
        } else {
          resolve(response);
        }
      };
      config.onError = (e: any = null): void => {
        reject(e);
      };
      _commitMutation(environment, config);
    });
  }

  /**
   * Returns a promise to create a media asset from a file with a given URI.
   *
   * @param {Environment} environment The relay environment.
   *
   * @param {string} fileUri The URI of the file.
   *
   * @param {{attribution:string, description:string, title:string}} options The options associated with the media asset.
   *
   * @returns {Promise<string>} The promise to create a media asset from a file with a given URI. The promise contains the
   */
  createMediaAssetFromFile(environment: Environment, options: MediaAssetFileFunctionInput = null): Promise<string> {
    const mediaAssetInput: CreateMediaAssetInput = {
      clientMutationId: _getClientMutationId(),
      topLevelMediaType: options.topLevelMediaType ? options.topLevelMediaType : TopLevelMediaKind.IMAGE,
    };
    const mediaAssetFileInput: CreateMediaAssetFileInput = {
      clientMutationId: _getClientMutationId(),
      format: options.format,
      name: options.fileName,
      sizeInBytes: undefined,
    };

    // Creates a new media asset
    if (options !== null) {
      if (options.description !== undefined) {
        mediaAssetInput.description = options.description;
      }
      if (options.title !== undefined) {
        mediaAssetInput.label = options.title;
      }
    }

    return this.commitMutation(environment, {
      mutation: CREATE_MEDIA_ASSET_FILE_GRAPHQL_MUTATION,
      variables: {
        input: mediaAssetFileInput,
      },
    })
      .then(
        (result: {
          createMediaAssetFile: {
            mediaAssetFile: any;
            errors: any;
            hasErrors: boolean;
          };
        }): Promise<any> => {
          //
          return result.createMediaAssetFile.hasErrors
            ? Promise.reject(result.createMediaAssetFile)
            : Promise.resolve(result.createMediaAssetFile.mediaAssetFile.id);
        },
      )
      .then((mediaAssetFileId: string) => {
        mediaAssetInput.files = [mediaAssetFileId];
        return this.commitMutation(environment, {
          mutation: CREATE_MEDIA_ASSET_GRAPHQL_MUTATION,
          variables: {
            input: mediaAssetInput,
          },
        }).then(
          (result: {
            createMediaAsset: {
              mediaAsset: any;
              errors: any;
              hasErrors: boolean;
            };
          }): Promise<any> => {
            //
            return result.createMediaAsset.hasErrors
              ? Promise.reject(result.createMediaAsset)
              : Promise.resolve(result.createMediaAsset.mediaAsset.id);
          },
        );
      });
  }
}

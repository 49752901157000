import { PLAY_IN_MINI_PLAYER, PLAY_FULL_SCREEN_VIDEO, SET_PLAYING_FULL_SCREEN, CLOSE_FULL_SCREEN_VIDEO, CLOSE_MINI_PLAYER } from "./constants";
import {
  PlayFullScreenVideoAction,
  PlayFullScreenVideoPayload,
  CloseFullScreenVideoAction,
  SetPlayingFullScreenPayload,
  SetPlayingFullScreenAction,
  PlayInMiniPlayerAction,
  PlayInMiniPlayerPayload,
  CloseMiniPlayerPayload,
  CloseMiniPlayerAction,
} from "./types";

export const playFullScreenVideo = (payload: PlayFullScreenVideoPayload): PlayFullScreenVideoAction => ({
  type: PLAY_FULL_SCREEN_VIDEO,
  payload,
});

export const setPlayingFullScreen = (payload: SetPlayingFullScreenPayload): SetPlayingFullScreenAction => ({
  type: SET_PLAYING_FULL_SCREEN,
  payload,
});

export const closeFullScreenVideo = (): CloseFullScreenVideoAction => ({
  type: CLOSE_FULL_SCREEN_VIDEO,
});

export const playInMiniPlayer = (payload: PlayInMiniPlayerPayload): PlayInMiniPlayerAction => ({
  type: PLAY_IN_MINI_PLAYER,
  payload,
});

export const closeMiniPlayer = (payload: never): CloseMiniPlayerAction => ({
  type: CLOSE_MINI_PLAYER,
  payload,
});

export const SET_EVENTS_STATE = "SET_EVENTS_STATE";
export const CREATE_RSVP_REQUEST = "CREATE_RSVP_REQUEST";
export const FETCH_EVENT = "FETCH_EVENT";
export const CREATE_INVEST_AMOUNT_REQUEST = "CREATE_INVEST_AMOUNT_REQUEST";

export const EVENT_INVESTMENT_AMOUNT = [
  {
    amount: "$10,000",
    title: "$10,000",
  },
  {
    amount: "$25,000",
    title: "$25,000",
  },
  {
    amount: "$50,000",
    title: "$50,000",
  },
  {
    amount: "$100,000",
    title: "$100,000",
  },
];

export const EVENT_LIVE = "live";
export const EVENT_DRAFT = "draft";

import * as React from "react";
import { Modal } from "../../../react-components/modals/modal";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { EventsModuleState } from "../redux/events";
import { INVESTOR } from "../../../redux/authentication/account-types";

interface ComponentProps {}
interface MapDispatchProps {}
interface MapStateProps {
  event: any;
  user: Person;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

const Component = (props: Props) => {
  const { event, user } = props;

  const isInvestor = user?.accountType?.kind === INVESTOR.kind;
  return (
    <Modal id={`rsvp-success-modal`} title={"RSVP Successful"} className={"success-modal"}>
      <div className="content" style={{ position: "relative" }}>
        <div className="modal-success-inner">
          <h3>
            {isInvestor
              ? `You have registered to attend the event successfully, an email will be sent to you with the needed details.`
              : `Thank you for your interest in this event. We will notify you when it is available for your viewing. If you are an accredited investor and you want to watch the event live, please sign up to rsvp.`}
          </h3>
        </div>
      </div>
    </Modal>
  );
};

const mapState = (state: AppState & EventsModuleState) => ({
  user: state.account.user,
  event: state.events.event,
});

const mapDispatch = {};

export const RsvpSuccessModal = connect(mapState, mapDispatch)(Component);

/**
 * @author William A. Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-11-12
 * @description Implementation of the GraphQL mutation to create a new media asset file.
 * @filename create-media-asset-file
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL mutation to create a new media asset file.
 *
 * @type {GraphQLTaggedNode}
 */
export const CREATE_MEDIA_ASSET_FILE_GRAPHQL_MUTATION: GraphQLTaggedNode = graphql`
  mutation createMediaAssetFileMutation($input: CreateMediaAssetFileInput!) {
    createMediaAssetFile(input: $input) {
      clientMutationId
      hasErrors
      errors {
        id
        location
      }
      mediaAssetFile {
        id
        name
      }
    }
  }
`;

import * as React from "react";
import { DynamicModuleLoader } from "redux-dynamic-modules";
import { eventsModule } from "./redux/events";
import { EventDetails as ConnectedEventDetails, ComponentProps as EventDetailsProps } from "./event-details";
import { EventsCarousel as ConnectedEventsCarousel } from "./events-carousel";

export const EventPanel = (props: EventDetailsProps) => {
  return (
    <DynamicModuleLoader modules={[eventsModule]}>
      <ConnectedEventDetails {...props} />
    </DynamicModuleLoader>
  );
};

export const EventsCarousel = () => {
  return (
    <DynamicModuleLoader modules={[eventsModule]}>
      <ConnectedEventsCarousel />
    </DynamicModuleLoader>
  );
};

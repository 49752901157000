/**
 * @author: Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-04-21
 * @description Implementation of StoryReview interface.
 * @filename story-review.ts
 */
import { Node } from "./node";
import { Person } from "./person";

/**
 * The __typeID of the Story Review type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_STORY_REVIEW: string = "StoryReview";

/**
 * A user submitted review of a story.
 *
 * @interface StoryReview
 * @extends {Node<D>}
 * @template D
 */
export interface StoryReview<D extends Date | string = string> extends Node<D> {
  /**
   * The originator of the review
   *
   * @template D
   * @type {Person<D>}
   * @memberof StoryReview
   */
  attribution?: Person<D>;

  /**
   * The content of the story review.
   *
   * @type {string}
   * @memberof StoryReview
   */
  content?: string;

  /**
   *  The number of people who thought that the review was helpful.
   *
   * @type {number}
   * @memberof StoryReview
   */
  helpful?: number;

  /**
   * The number of people who thought that the review was not helpful.
   *
   * @type {number}
   * @memberof StoryReview
   */
  notHelpful?: number;

  /**
   * The rating that the reviewer gave to the story.
   *
   * @type {number}
   * @memberof StoryReview
   */
  rating?: number;
}

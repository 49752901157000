/**
 * @author William Alexander Livesley
 * @date   2018-04-22
 * @description Declaration of the NewsletterStory interface.
 * @filename newsletter-story.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Story } from "./story";

/**
 * The __typeID of the Newsletter Story type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_NEWSLETTER_STORY: string = "NewsletterStory";

/**
 * A story concerning a newsletter.
 *
 * @extends {Story}
 * @template D
 * @interface
 */
export interface NewsletterStory<D extends Date | string = string> extends Story<D> {

  /**
   * A non-normative description of the cost of the newsletter
   *
   * @type {string}
   * @memberof NewsletterStory
   */
  cost?: string;

  /**
   * The URL of the newsletter.
   *
   * @type {string}
   */
  link?: string;

  /**
   * The time to read the newsletter in seconds.
   *
   * @type {number}
   */
  timeToReadInSeconds?: number;

  /**
   * The next publication date of the newsletter.
   *
   * @type {string}
   */
  nextPublicationDate?:string;

  /**
   * A non-normative description of the conditions of publication
   *
   * @type {string}
   * @memberof NewsletterStory
   */
  publication?: string;
}

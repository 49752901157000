export * from "./app/actions";
export * from "./trackers/actions";
export * from "./details-panel/actions";
export * from "./youtube-player/actions";
export * from "./local-storage/actions";
export * from "./account/actions";
export * from "./authentication/actions";
export * from "./crawler/actions";
export * from "./cache/actions";
export * from "./notification/actions";
export * from "./full-screen-video/actions";
export * from "./toast/actions";
export * from "./router/actions";

export const trace = (type: string, payload: any) => ({ type, payload });

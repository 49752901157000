import {
  FetchHtmlPagePayload,
  FetchHtmlPageAction,
  CacheDataAction,
  CacheDataPayload,
  FetchInProgressAction,
  FetchInProgressPayload,
  PrefetchHtmlPagesAction,
  FetchListPayload,
  FetchListAction,
  FetchOriginalShowsPayload,
  FetchOriginalShowsAction,
  FetchStoryItemPayload,
  FetchStoryItemAction,
  FetchEntityPayload,
  FetchEntityAction,
  FetchErrorPayload,
  FetchErrorAction,
  FetchListCollectionPayload,
  FetchListCollectionAction,
  FetchListItemAction,
  FetchListItemPayload,
  SetLoadingStatusAction,
  SetLoadingStatusPayload,
  FetchMediaAssetsListPayload,
  FetchMediaAssetsListAction,
  FetchPersonPayload,
  FetchPersonAction,
  FetchListLoadMorePayload,
  FetchListLoadMoreAction,
} from "./types";
import * as constants from "./constants";

export const fetchHtmlPage = (payload: FetchHtmlPagePayload): FetchHtmlPageAction => {
  return {
    type: constants.FETCH_HTML_PAGE,
    payload,
  };
};

export const fetchList = (payload: FetchListPayload): FetchListAction => {
  return {
    type: constants.FETCH_LIST,
    payload,
  };
};

export const fetchListItem = (payload: FetchListItemPayload): FetchListItemAction => {
  return {
    type: constants.FETCH_LIST_ITEM,
    payload,
  };
};

export const fetchListCollection = (payload: FetchListCollectionPayload): FetchListCollectionAction => {
  return {
    type: constants.FETCH_LIST_COLLECTION,
    payload,
  };
};

export const fetchOriginalShows = (payload: FetchOriginalShowsPayload): FetchOriginalShowsAction => {
  return {
    type: constants.FETCH_ORIGINAL_SHOWS,
    payload,
  };
};

export const cacheData = (payload: CacheDataPayload): CacheDataAction => ({
  type: constants.CACHE_DATA,
  payload,
});

export const setLoadingStatus = (payload: SetLoadingStatusPayload): SetLoadingStatusAction => ({
  type: constants.SET_LOADING_STATUS,
  payload,
});

export const fetchInProgress = (payload: FetchInProgressPayload): FetchInProgressAction => ({
  type: constants.FETCH_IN_PROGRESS,
  payload,
});

export const prefetchHtmlPages = (): PrefetchHtmlPagesAction => ({
  type: constants.PREFETCH_HTML_PAGES,
});

export const fetchStoryItem = (payload: FetchStoryItemPayload): FetchStoryItemAction => ({
  type: constants.FETCH_STORY_ITEM,
  payload,
});

export const fetchEntity = (payload: FetchEntityPayload): FetchEntityAction => ({
  type: constants.FETCH_ENTITY,
  payload,
});

export const fetchPerson = (payload: FetchPersonPayload): FetchPersonAction => ({
  type: constants.FETCH_PERSON,
  payload,
});

export const fetchError = (payload: FetchErrorPayload): FetchErrorAction => ({
  type: constants.FETCH_ERROR,
  payload,
});

export const fetchMediaAssetsList = (payload: FetchMediaAssetsListPayload): FetchMediaAssetsListAction => ({
  type: constants.FETCH_MEDIA_ASSETS_LIST,
  payload,
});

export const fetchListLoadMore = (payload: FetchListLoadMorePayload): FetchListLoadMoreAction => ({
  type: constants.FETCH_LIST_LOAD_MORE,
  payload,
});

/**
 * @author William Alexander Livesley
 * @date   2018-05-28
 * @description Declaration of the HtmlDocumentHeaderType interface.
 * @filename html-document-header-type.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the HTML Document Header GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_HTML_DOCUMENT_HEADER_TYPE: string =
  "HtmlDocumentHeaderType";

/**
 * An enumeration of the different kinds of headers for an HTML document.
 */
export enum HtmlDocumentHeaderTypeKind {
  /**
   * The base URL and base target for all relative URLs in a page.
   */
  BASE = "BASE",

  /**
   * The relationship between the HTML document and an external resource.
   */
  LINK = "LINK",

  /**
   * Metadata about the HTML document.
   */
  META = "META",

  /**
   * The title of a document.
   */
  TITLE = "TITLE"
}

/**
 * A type of HTML document header.
 *
 * @extends {Node}
 * @interface
 * @template D
 */
export interface HtmlDocumentHeaderType<D extends Date | string = string>
  extends Node<D> {
  /**
   * A description of the HTML document header type. Metadata.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The kind of the HTML document header type. Metadata.
   *
   * @type {HtmlDocumentHeaderTypeKind}
   */
  kind?: HtmlDocumentHeaderTypeKind;

  /**
   * A label of the HTML document header type. Metadata.
   *
   * @type {string}
   */
  label?: string;
}

/**
 * @author Adham El Banaway
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-08-03
 * @description Implementation of the LinkedInAuthenticator react component
 * @filename linked-in-authenticator.tsx
 */
import React from "react";
import { fetchQuery } from "react-relay";
import { LINKED_USER_GRAPHQL_QUERY } from "../../relay/queries/linked-in-authentication-query";
import { ENVIRONMENT } from "../../relay/relay-environment";
import { gaEvent } from "../../utilities/general";

/**
 * The props associated with the LinkedInAuthenticator component.
 *
 * @interface
 */
interface LinkedInAuthenticatorProps {
  /**
   * the access code retrieved from url linkedin redirect
   */
  code: string;
}

/**
 *  A React component that executes the process to complete a LinkedIn login.
 *
 * @extends {React.Component<LinkedInAuthenticatorProps>}
 */
export class LinkedInAuthenticator extends React.Component<
  LinkedInAuthenticatorProps
> {
  /**
   * Constructor
   *
   * @param {LinkedInAuthenticatorProps} props The props.
   */
  constructor(props: LinkedInAuthenticatorProps) {
    super(props);
  }

  /**
   * Invoked by the React framework immediately after a component is mounted.
   */
  componentDidMount(): void {
    fetchQuery(
      ENVIRONMENT,
      // Get the linkedin user open-id token using fetchQuery and LINKED_USER_GRAPHQL_QUERY
      LINKED_USER_GRAPHQL_QUERY,
      { linkedInCode: this.props.code }
    )
      .then((queryResults: any) => {
        let linkedInUserToken: { [index: string]: string };
        let promise: Promise<any>;
        gaEvent(
          "Registration ",
          "Click",
          "Register New LinkedIn Profile",
          true
        );

        // if query face any error in lambda function
        if (queryResults["linkedInUser"].hasErrors === true) {
          promise = Promise.reject(
            new Error(
              "[Cu9fYK1SSQOn3O2qQPtAOg] could not retrieve openId Token"
            )
          );
        } else {
          linkedInUserToken = queryResults["linkedInUser"].linkedInUser;
          promise = import("../../authentication/cognito-client").then(
            (module) => {
              return module.CognitoClient.getCognitoIdentityCredentialsForDeveloperIdentity(
                "linkedin",
                {
                  developerId: linkedInUserToken.linkedInUserId,
                  identityId: linkedInUserToken.id,
                  openIdToken: linkedInUserToken.openIdToken,
                }
              );
            }
          );
        }
        // extracted provided credentials
        // attempt to get cognito credentials with provided openId token & identity id
        return promise;
      })
      .then(() => {
        window.location.href = window.location.origin;
      })
      .catch((err) => {
        // TODO it should redirect to error page (failure to login with linkedIn)
        window.location.href = window.location.origin;
      });
  }

  /**
   * Returns the React node containing the rendered content.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    return <div />;
  }
}

import { graphql, GraphQLTaggedNode } from "react-relay";

export const CREATE_CHANGE_EMAIL_REQUEST_MUTATION: GraphQLTaggedNode = graphql`
  mutation createChangeEmailRequestMutation($input: CreateUpdateChangeEmailRequestInput!) {
    ChangeEmailRequest(input: $input) {
      errors {
        id
        location
      }
      hasErrors
      userRequest {
        ... on DeactivateRequest {
          id
          status
        }
      }
    }
  }
`;

/**
 * @author Ahmed serag
 * @date   2018-09-24
 * @description Declaration of the TextQuestionResponse interface.
 * @filename text-question.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Connection } from "./connection";
import { Preview } from "./preview";
import { QuestionResponder } from "./questionnaire";

/**
 * The __typeID of the text Question response GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_TEXT_QUESTION_RESPONSE: string = "TextQuestionResponse";

/**
 * The response to a text question
 *
 * @export
 * @interface TextQuestionResponse
 */
export interface TextQuestionResponse {

  /**
   * The content of the question.
   *
   * @type {string}
   * @memberof TextQuestionResponse
   */
  content?: string;

  /**
   * The timestamp when the response to the text question was 
   * created as an ISO-8601 formatted UTC date string.
   *
   * @type {string}
   * @memberof TextQuestionResponse
   */
  created?: string;

  /**
   * The ID of the question.
   *
   * @type {string}
   * @memberof TextQuestionResponse
   */
  id?: string;
  
   /**
   * The person or entity making the response.
   *
   * @type {QuestionResponder}
   * @memberof TextQuestionResponse
   */
  responder?: QuestionResponder;

  /**
   * The typename of the node.
   *
   * @type {string}
   */
  __typename?: string;
}
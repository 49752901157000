import * as React from "react";
import { connect } from "react-redux";
import Truncate from "react-truncate";
import { Loader } from "../../page/loader";
import { PitchVideoStory } from "@radivision/graphql/lib/ts/graphql/pitch-video-story";
import { SVG } from "../../../component-configuration/svgs";
import { OpenDetailsPanelPayload, OpenDetailsPanelAction } from "../../../redux/details-panel/types";
import isEmpty from "lodash.isempty";
import * as Actions from "../../../redux/actions";
import { getPreviewImageUrl } from "./selectors";
import { useOnScreen } from "../../../utilities/use-on-screen";
import { pitchPlanetListId } from "../../../constants/content-ids";
import { AppState } from "../../../redux";
import { ProfilePictureName } from "../../page-framework/profile-picture-name";
import { Router } from "../../../utilities/router";
import { getProfileInfo } from "../../../utilities/get-profile-info";
import { SetProfileActiveTabPayload, SetProfileActiveTabAction } from "../../../redux/account/types";
import { extractYoutubeId } from "../../../utilities/extract-youtube-id";

const VideoPanel = React.lazy(() => import("../../page-framework/video-panel"));

interface ComponentProps {
  item: PitchVideoStory;
  list: PitchVideoStory[];
}
interface MapStateProps {
  device: string;
}
interface MapDispatchProps {
  openDetailsPanel: (payload: OpenDetailsPanelPayload) => OpenDetailsPanelAction;
  setProfileActiveTab: (payload: SetProfileActiveTabPayload) => SetProfileActiveTabAction;
}

export type Props = MapStateProps & MapDispatchProps & ComponentProps;

export const Component = (props: Props) => {
  const { item, openDetailsPanel, setProfileActiveTab, device } = props;

  const thisRef = React.useRef(null);
  const isOnScreen = useOnScreen(thisRef);

  const eitherPitch = item?.trailer || item?.originalClip;
  const youtubeId =  eitherPitch?.externalId || extractYoutubeId(item.link);
  const previewImageUrl = getPreviewImageUrl(item);
  const backgroundImage = `url('${previewImageUrl?.requestedResolutionUrl}')`;
  const hasYoutubeVideo = !isEmpty(youtubeId);

  const referral = item && item.peopleEntities && item.peopleEntities[1] && item.peopleEntities[1].entity;
  const { profileUrl: referralUrl } = getProfileInfo(referral);

  const openDetailsPanelOnClick = React.useCallback(
    (e) => {
      e.preventDefault();
      const _list = { id: pitchPlanetListId, title: "PitchPlanet" };
      openDetailsPanel({ item, list: _list });
    },
    [item, openDetailsPanel],
  );

  const goTo = React.useCallback(
    (url: string) => (e: React.SyntheticEvent) => {
      e.preventDefault();
      setProfileActiveTab({ activeTab: "Opportunities" });
      Router.navigateTo(url, null, null, "Opportunities");
    },
    [setProfileActiveTab],
  );

  const shouldPlayYoutubePreview = hasYoutubeVideo;
  const company = item?.peopleEntities && item.peopleEntities[0] && item?.peopleEntities[0].entity;
  const companyName = company?.name;

  const companyStage = company?.fundingRound;
  const companyType = company?.activities && company.activities[0] && company.activities[0].kind;
  const companyIndustry = company?.industry;
  const { profileUrl } = getProfileInfo(company);

  const isSmallScreen = device === "mobile";

  const PitchTags = React.useCallback(() => {
    return (
      <ul className="pitch-tags">
        {companyStage && (
          <li>
            {" "}
            <b>Stage:</b> {companyStage}{" "}
          </li>
        )}
        {companyType && (
          <li>
            {" "}
            <b>Type:</b> {companyType?.toLowerCase()?.replace("_", " ")}{" "}
          </li>
        )}
        {companyIndustry && (
          <li>
            {" "}
            <b>Industry:</b> {companyIndustry}{" "}
          </li>
        )}
      </ul>
    );
  }, [companyStage, companyIndustry, companyType]);

  const PopOver = React.useCallback(() => {
    if (isSmallScreen) return null;
    return (
      <>
        <PreviewContainer onClick={openDetailsPanelOnClick}>
          <Video onClick={openDetailsPanelOnClick} style={{ backgroundImage }}>
            {shouldPlayYoutubePreview && isOnScreen && (
              <React.Suspense fallback={<Loader isActive={true}></Loader>}>
                <VideoPanel
                  youTube={youtubeId}
                  sendAnalyticEvent={true}
                  onReady={() => {}}
                  onVideoEnded={() => {}}
                  mute={true}
                  showControls={false}
                  json={{}}
                  videoPosition={"FIT"}
                  disableSeek={true}
                />
              </React.Suspense>
            )}
          </Video>
        </PreviewContainer>
        <CardText style={{}}>
          <a href={`${profileUrl}#Opportunities`} onClick={goTo(profileUrl)}>
            {companyName}
          </a>
          <div className="referral">
            {referral && (
              <>
                <div className="referral-name">
                  <p className="nominated-by">Nominated By</p>
                  <a
                    href={referralUrl}
                    className="nominated-by"
                    onClick={(e: any) => {
                      e.preventDefault();
                      Router.to(referralUrl);
                    }}
                  >
                    {referral?.name}
                  </a>
                </div>
                <ProfilePictureName subject={referral} />
              </>
            )}
          </div>
        </CardText>
        <CardText style={{}}>
          <PitchTags />
        </CardText>
        <div className="item-card-actions">
          <span onClick={openDetailsPanelOnClick}> {SVG.chevronDown} </span>
        </div>
      </>
    );
  }, [
    companyName,
    backgroundImage,
    isOnScreen,
    shouldPlayYoutubePreview,
    youtubeId,
    openDetailsPanelOnClick,
    isSmallScreen,
    PitchTags,
    profileUrl,
    goTo,
    referral,
    referralUrl,
  ]);

  if (item.__typename !== "PitchVideoStory") {
    return null;
  }

  return (
    <div className={`item-card-container`}>
      <Container>
        <div className="popover" ref={thisRef}>
          <PopOver />
        </div>
        <PreviewContainer onClick={openDetailsPanelOnClick}>
          <div className="item-preview" onClick={openDetailsPanelOnClick} style={{ backgroundImage }} />
        </PreviewContainer>
        <CardText style={{}}>
          <ProfilePictureName subject={company} />
          <div className="referral">
            {referral && (
              <>
                <div className="referral-name">
                  <p className="nominated-by">Nominated By</p>
                  <a
                    href={referralUrl}
                    className="nominated-by"
                    onClick={(e: any) => {
                      e.preventDefault();
                      Router.to(referralUrl);
                    }}
                  >
                    {referral?.name}
                  </a>
                </div>
              </>
            )}
          </div>
        </CardText>
        {false && (
          <CardText>
            <Truncate lines={1} ellipsis={<span className="ellipsis">...</span>}>
              {item?.description}
            </Truncate>
            <div className="read-more">Read more</div>
          </CardText>
        )}
        <PitchTags />
      </Container>
    </div>
  );
};

export const CardText = ({ children, style }: React.PropsWithChildren<{ style?: any }>) => {
  return (
    <div className={`item-card-text`} style={style}>
      {children}
    </div>
  );
};

export const PopOverContainer = ({ children }) => {
  return <div className="popover">{children}</div>;
};
export const PreviewContainer = ({ children, onClick }) => {
  return (
    <div className="item-preview-container" onClick={onClick}>
      {children}
    </div>
  );
};

export const CardTitle = ({ children, style }) => {
  return (
    <div className="item-card-title" style={style}>
      {children}
    </div>
  );
};
export const Buttons = ({ children }) => {
  return <div className="item-card-actions">{children}</div>;
};

export const Container = ({ children }) => {
  return <div className={`item-card`}>{children}</div>;
};

export const Video = ({ children, ...props }) => {
  return (
    <div className="item-video" {...props}>
      {children}
    </div>
  );
};

const mapState = (state: AppState) => ({
  device: state.app.device,
});

const mapDispatch = {
  openDetailsPanel: Actions.openDetailsPanel,
  setProfileActiveTab: Actions.setProfileActiveTab,
};

export const PitchCard = connect(mapState, mapDispatch)(Component);

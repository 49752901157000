/**
 * @author Ahmed Serag
 * @date   2018-03-28 02:49:26
 * @description Declaration of the social media platform interface.
 * @filename social-media-platform.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the Social Media Platform type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_SOCIAL_MEDIA_PLATFORM: string = "SocialMediaPlatform";

/**
 * An enumeration of the set of social media platform kinds
 */
export enum SocialMediaPlatformKind {
  /**
   * Angel List
   */
  ANGEL_LIST = "ANGEL_LIST",

  /**
   * Facebook
   */
  FACEBOOK = "FACEBOOK",

  /**
   * Instagram
   */
  INSTAGRAM = "INSTAGRAM",

  /**
   * LinedIn
   */
  LINKED_IN = "LINKED_IN",

  /**
   * Medium
   */
  MEDIUM = "MEDIUM",

  /**
   * Pinterest
   */
  PINTEREST = "PINTEREST",

  /**
   * Reddit.
   */
  REDDIT = "REDDIT",

  /**
   * SnapChat
   */
  SNAPCHAT = "SNAPCHAT",

  /**
   * Tumblr
   */
  TUMBLR = "TUMBLR",

  /**
   * Twitter
   */
  TWITTER = "TWITTER",

  /**
   * Weibo
   */
  WEIBO = "WEIBO",

  /**
   * WhatsApp
   */
  WHATS_APP = "WHATS_APP",

  /**
   * YouTube
   */
  YouTube = "YouTube"
}

/**
 *  A social media platform
 *
 * @extends {Node}
 * @interface
 * @template D
 */
export interface SocialMediaPlatform<D extends Date | string = string>
  extends Node<D> {
  /**
   *  The social media platform kind
   *
   * @type {SocialMediaPlatformKind}
   */
  kind?: SocialMediaPlatformKind;

  /**
   * The social media platform label
   *
   * @type {string}
   * @memberof SocialMediaPlatform
   */
  label?: string;
}

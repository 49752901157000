import {
  ONBOARDING_INVESTOR,
  SET_PROFILE_STATE,
  ONBOARDING_FOUNDER,
  ONBOARDING_ENTHUSIAST,
  UPDATE_ACCOUNT_TYPE,
  EDIT_PROFILE,
  SET_PROFILE_PHOTO,
  SET_PROFILE_SOCIAL_LINK,
  CHECK_EXISTING_COMAPNY_URL,
  CHECK_EXISTING_COMAPNY_URL_FULFILLED,
  SUBMIT_PROFILE,
  SET_PROFILE_OPPORTUNITY,
  DELETE_COMPANY,
  REMOVE_COMPANY,
  ADD_COMPANY,
} from "./constants";
import {
  SetProfileStatePayload,
  SetProfileStateAction,
  OnBoardingInvestorAction,
  OnBoardingFounderAction,
  OnBoardingEnthusiastAction,
  UpdateAccountTypePayload,
  UpdateAccountTypeAction,
  EditProfilePayload,
  EditProfileAction,
  SetProfilePhotoPayload,
  SetProfilePhotoAction,
  SetProfileSocialLinkPayload,
  SetProfileSocialLinkAction,
  CheckExistingCompanyUrlPayload,
  CheckExistingCompanyUrlAction,
  CheckExistingCompanyUrlFulfilledPayload,
  CheckExistingCompanyUrlFulfilledAction,
  SubmitProfileAction,
  SetProfileOpportunityAction,
  SetProfileOpportunityPayload,
  DeleteCompanyPayload,
  DeleteCompanyAction,
  RemoveCompanyAction,
  RemoveCompanyPayload,
  AddCompanyPayload,
  AddCompanyAction,
} from "./types";

export const onBoardingInvestor = (): OnBoardingInvestorAction => ({
  type: ONBOARDING_INVESTOR,
});

export const onBoardingFounder = (): OnBoardingFounderAction => ({
  type: ONBOARDING_FOUNDER,
});
export const onBoardingEnthusiast = (): OnBoardingEnthusiastAction => ({
  type: ONBOARDING_ENTHUSIAST,
});

export const setProfileState = (payload: SetProfileStatePayload): SetProfileStateAction => ({
  type: SET_PROFILE_STATE,
  payload,
});

export const updateAccountType = (payload: UpdateAccountTypePayload): UpdateAccountTypeAction => ({
  type: UPDATE_ACCOUNT_TYPE,
  payload,
});

export const editProfile = (payload: EditProfilePayload): EditProfileAction => ({
  type: EDIT_PROFILE,
  payload,
});

export const setProfilePhoto = (payload: SetProfilePhotoPayload): SetProfilePhotoAction => ({
  type: SET_PROFILE_PHOTO,
  payload,
});

export const setProfileSocialLink = (payload: SetProfileSocialLinkPayload): SetProfileSocialLinkAction => ({
  type: SET_PROFILE_SOCIAL_LINK,
  payload,
});

export const checkExistingCompanyUrl = (payload: CheckExistingCompanyUrlPayload): CheckExistingCompanyUrlAction => ({
  type: CHECK_EXISTING_COMAPNY_URL,
  payload,
});

export const checkExistingCompanyUrlFulfilled = (
  payload: CheckExistingCompanyUrlFulfilledPayload,
): CheckExistingCompanyUrlFulfilledAction => ({
  type: CHECK_EXISTING_COMAPNY_URL_FULFILLED,
  payload,
});

export const setProfileOpportunity = (payload: SetProfileOpportunityPayload): SetProfileOpportunityAction => ({
  type: SET_PROFILE_OPPORTUNITY,
  payload,
});

export const submitProfile = (): SubmitProfileAction => ({
  type: SUBMIT_PROFILE,
});

export const deleteCompany = (payload: DeleteCompanyPayload): DeleteCompanyAction => ({
  type: DELETE_COMPANY,
  payload,
});

export const addCompany = (payload: AddCompanyPayload): AddCompanyAction => ({
  type: ADD_COMPANY,
  payload,
});

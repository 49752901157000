/**
 * @Author: Ahmed Samer
 * @Date:   2021-07-06 T11:30:42+02:00
 * @Email:  sara@radivision.com
 * @Project: Radivision
 * @Filename: pitch-video-story.ts
 * @Copyright: Copyright 2021 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Story } from "./story";

/**
 * The __typeID of the pitch video Story type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_PITCH_VIDEO_STORY: string = "PitchVideoStory";

/**
 * A story referencing an pitch video story
 *
 * @export
 * @extends {Story<D>}
 * @interface PitchVideoStory
 * @template D
 */
export interface PitchVideoStory<D extends Date | string = string>
  extends Story<D> {
  /**
   * The pitch video story media item.
   *
   * @type {pitchVideoDetails}
   */
  trailer?: PitchVideoDetails;

  /**
   *
   *
   * @type {pitchVideoDetails}
   * @memberof PitchVideoStory
   */
  mediaItem?: PitchVideoDetails;

  /**
   * The link for the media item.
   *
   * @type {string}
   */
  link?: string;

  /**
   * promotions status of the story.
   *
   * @type {boolean}
   */
  isPromoted?: boolean;
}

/**
 *
 *
 * @interface pitchVideoDetails
 */
export interface PitchVideoDetails {
  /**
   * The duration of the video for the pitch video story in seconds
   *
   * @type {number}
   * @memberof pitchVideoDetails
   */
  durationInSeconds?: number;

  /**
   * The serialized embed code for the media item.
   *
   * @type {string}
   * @memberof pitchVideoDetails
   */
  externalId?: string;

  /**
   * The link for the media item.
   *
   * @type {string}
   */
  link?: string;

  /**
   * A description of the story.
   *
   * @type
   */
  description?: string;
}

import * as React from "react";
import { SVG } from "../../../component-configuration/svgs";

import { useCarouselContext } from "./context";

export const PrevButton = (): JSX.Element => {
  const { displayMode, prev } = useCarouselContext();

  const isCarouselDisplayMode: boolean = !displayMode || displayMode === "Carousel";
  if (!isCarouselDisplayMode) return null;

  return (
    <div className="rdv-btn rdv-carousel-prev hidden" onClick={prev}>
      {SVG.chevronLeft}
    </div>
  );
};

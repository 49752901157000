import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { FormFieldInput, FormFieldContainer } from "../../../react-components/modals/modal-form-elements";
import {
  SocialMediaProfiles,
  ProfileModuleState,
  SetProfileSocialLinkAction,
  SetProfileSocialLinkPayload,
} from "../redux/profile/types";
import { SVG } from "../../../component-configuration/svgs";
import { socialPlatforms } from "../../../constants/social-platforms";
import { setProfileSocialLink } from "../redux/profile/actions";

interface ComponentProps {
  platform: string;
}

interface MapStateProps {
  socialMediaProfiles: SocialMediaProfiles;
}
interface MapDispatchProps {
  setProfileSocialLink: (payload: SetProfileSocialLinkPayload) => SetProfileSocialLinkAction;
}

export type Props = ComponentProps & MapStateProps & MapDispatchProps;

export const Component = (props: Props) => {
  const { setProfileSocialLink, socialMediaProfiles, platform } = props;
  const item = socialPlatforms[platform];
  const value = socialMediaProfiles && socialMediaProfiles[platform];

  const onChange = React.useCallback(
    (input) => {
      const value = input?.length ? input : undefined;
      setProfileSocialLink({
        [platform]: value,
      });
    },
    [setProfileSocialLink, platform],
  );

  return (
    <Container>
      <FormFieldContainer>
        <label htmlFor={`social-link-${platform}`}>
          <span>{item.title}</span>
        </label>
        <FormFieldInput withIcon={true} withReset={true} rightAligned={false}>
          <span>{item.icon}</span>
          <span
            className="remove"
            onClick={() => {
              onChange("");
            }}
          >
            <i
              style={{
                margin: "auto",
              }}
            >
              {SVG.crosshairsThin}
            </i>
          </span>
          <input
            type="text"
            className={`form-control`}
            id={`social-link-${platform}`}
            placeholder={item.domain}
            value={value || ""}
            onChange={(e) => onChange(e.target.value)}
            disabled={false}
          />
        </FormFieldInput>
      </FormFieldContainer>
    </Container>
  );
};

export const Container = ({ children }) => {
  return <div className="social-link-input">{children}</div>;
};

const mapState = (state: AppState & ProfileModuleState) => ({
  socialMediaProfiles: state.profile.socialMediaProfiles,
});

const mapDispatch = {
  setProfileSocialLink: setProfileSocialLink,
};

export const SocialLinkInput = connect(mapState, mapDispatch)(Component);

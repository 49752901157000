/**
 * @author Ahmed Serag
 * @date   2018-04-19
 * @description Declaration of the HtmlDocumentTemplate interface.
 * @filename html-document-template.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";
import { HtmlDocumentHeader } from "./html-document-header";
import { HtmlDocumentTemplateSlot } from "./html-document-template-slot";

/**
 * The __typeID of the HTML Document Template type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_HTML_DOCUMENT_TEMPLATE: string = "HtmlDocumentTemplate";

/**
 *
 *
 * @export
 * @interface HtmlDocumentTemplate
 * @extends {Node<D>}
 * @template D
 */
export interface HtmlDocumentTemplate<D extends Date | string = string>
  extends Node<D> {
  /**
   *  An alias, where documents produced by the template use the template automatically
   *
   * @type {string}
   * @memberof HtmlDocumentTemplate
   */
  alias?: string;

  /**
   * A description of the HTML document template. Metadata
   *
   * @type {string}
   * @memberof HtmlDocumentTemplate
   */
  description?: string;

  /**
   * The collection of headers for the HTML document template
   *
   * @template D
   * @type {Array<HtmlDocumentHeader>}
   * @memberof HtmlDocumentTemplate
   */
  headers?: HtmlDocumentHeader<D>[];

  /**
   * A label of the HTML document template. Metadata
   *
   * @type {string}
   * @memberof HtmlDocumentTemplate
   */
  label?: string;

  /**
   * The collection of HTML document template slots associated with the HTML document template.
   *
   * @template D
   * @type {Array<HtmlDocumentTemplateSlot<D>>}
   */
  slots?: HtmlDocumentTemplateSlot<D>[];
}

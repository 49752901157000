/**
 * @author William Alexander Livesley
 * @date   2018-04-20
 * @description Declaration of the FrequencyKind enum and the Frequency interface declaring the properties
 * of different kinds of frequencies with which a story is published.
 * @filename frequency.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the Frequency GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_FREQUENCY: string = "Frequency";

/**
 * An enumeration of the different kinds of frequencies with which a story is published.
 */
export enum FrequencyKind {
  /**
   * The story is published every other month.
   */
  BI_MONTHLY = "BI_MONTHLY",

  /**
   * The story is published every other week.
   */
  BI_WEEKLY = "BI_WEEKLY",

  /**
   * The story is published every day.
   */
  DAILY = "DAILY",

  /**
   * The story is published once a month.
   */
  MONTHLY = "MONTHLY",

  /**
   * The story is published once a week.
   */
  WEEKLY = "WEEKLY"
}

/**
 * The properties of a frequency with which a story is published.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface Frequency<D extends Date | string = string> extends Node<D> {
  /**
   * A description of the frequency.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The kind of the frequency.
   *
   * @type {FrequencyKind}
   */
  kind?: FrequencyKind;

  /**
   * A label of the moderation state.
   *
   * @type {string}
   */
  label?: string;
}

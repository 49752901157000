/**
 * @author: Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-04-26
 * @description Implementation of the subscription and declaration of its related interfaces.
 * @filename subscription.ts
 */
import { Node } from "./node";

/**
 * The __typeID of the Subscription type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_SUBSCRIPTION: string = "Subscription";

/**
 * A subscription to a Radivision publication.
 *
 * @export
 * @interface Subscription
 * @extends {Node<D>}
 * @template D
 */
export interface Subscription<D extends Date | string = string>
  extends Node<D> {
  /**
   * A description of the subscription.
   *
   * @type {string}
   * @memberof Subscription
   */
  description?: string;

  /**
   * A label for the subscription.
   *
   * @type {string}
   * @memberof Subscription
   */
  label?: string;
}

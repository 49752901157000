import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import { SetAuthStatePayload, SetAuthStateAction, ResetPasswordAction, ResetPasswordPayload } from "../../../redux/authentication/types";
import { AUTH_RESET_PASSWORD } from "../../../redux/authentication/constants";
import { FormFieldContainer, FormFieldInput, Submit, ModalFooter } from "../modal-form-elements";

interface MapDispatchProps {
  setAuthState: (paylaod: SetAuthStatePayload) => SetAuthStateAction;
  resetPassword: (payload: ResetPasswordPayload) => ResetPasswordAction;
}

interface MapStateProps {
  step: number;
  email: string;
  inProgress: boolean;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { step, email, setAuthState, inProgress, resetPassword } = props;
  const shouldDisplay = step === AUTH_RESET_PASSWORD;

  const onSubmit = React.useCallback(() => {
    resetPassword({ email });
  }, [email, resetPassword]);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Container>
      <div className="subtitle">Please enter the Email associated with your account.</div>
      <FormFieldContainer>
        <FormFieldInput>
          <input
            type="email"
            className="form-control"
            placeholder="Email Address"
            required={true}
            disabled={false}
            value={email}
            onChange={(e) => setAuthState({ email: e.target.value })}
          />
        </FormFieldInput>
      </FormFieldContainer>
      <ModalFooter>
        <Submit onClick={onSubmit} disabled={false} isSubmitting={inProgress} title={"Reset password"} />
      </ModalFooter>
    </Container>
  );
};

const Container = ({ children }) => {
  return <div className="reset-password-form">{children}</div>;
};

const mapState = (state: AppState) => ({
  step: state.authentication.step,
  email: state.authentication.email,
  inProgress: state.cache.inProgress["reset-password"],
});

const mapDispatch = {
  setAuthState: Actions.setAuthState,
  resetPassword: Actions.resetPassword,
};

export const ResetPassword = connect(mapState, mapDispatch)(Component);

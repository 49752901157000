import * as React from "react";
import { connect } from "react-redux";
import { setAppState } from "../../../redux/actions";
import { AppState } from "../../../redux";
import { SetAppStateAction, SetAppStatePayload } from "../../../redux/app/types";


interface ComponentProps {

  title: string;

}
interface MapStateProps {
  muted: boolean;
}
interface MapDispatchProps {
  setAppState: (payload: SetAppStatePayload) => SetAppStateAction;
}

type Props = ComponentProps & MapStateProps & MapDispatchProps;

export const HeaderComponent = (props: Props) => {

  return (
    <Container>
      <h3 className="pitch-panel-title"> {props?.title}</h3>
    </Container>
  );
};
const mapState = (state: AppState) => ({
  muted: state.app.muted,
});

const mapDispatch = {
  setAppState: setAppState;
}

export const Container = ({ children }) => {
  return <div className="pitch-carousel-header"
  >{children}</div>;
};

export const Header = connect(mapState, mapDispatch)(HeaderComponent);

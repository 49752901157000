/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-0q-3
 * @description Implementation of the re-sign in modal react component
 * @filename re-sign-in-modal.tsx
 */
import React from "react";
import { RdvButton } from "../page-framework/rdv-button";

/**
 * The props of the ActionModal component.
 *
 * @interface
 */
export interface ActionModalProps {
  /**
   *
   *
   * @type {boolean}
   * @memberof ActionModalProps
   */
  allowCancelButton?: boolean;
  /**
   *
   * @type {string}
   * @memberof ActionModalProps
   */
  modalMessageBody?: string;
  /**
   *
   * @type {Function}
   * @memberof ActionModalProps
   */
  buttonAction?: Function;
  /**
   *
   *
   * @type {string}
   * @memberof ActionModalProps
   */
  buttonText?: string;
  /**
   *
   *
   * @type {string}
   * @memberof ActionModalProps
   */
  modalTitle?: string;
}

/**
 * The state of the ActionModal component
 *
 * @interface
 */
interface ActionModalState {
  /**
   *
   *
   * @type {string}
   * @memberof ActionModalState
   */
  buttonText: string;
}

/**
 * A React component that uploads files to an S3 bucket
 */
export class ActionModal extends React.Component<ActionModalProps, ActionModalState> {
  /**
   * Constructor.
   *
   * @param {ActionModalProps} props The props of the component.
   */
  constructor(props: ActionModalProps) {
    super(props);
    this.state = {
      buttonText: this.props.buttonText !== undefined && this.props.buttonText !== null ? this.props.buttonText : "ok",
    };
    this.submitModalAction = this.submitModalAction.bind(this);
  }

  /**
   *
   *
   * @param {*} event
   * @memberof ActionModal
   */
  submitModalAction(): void {
    if (this.props.buttonAction !== undefined && this.props.buttonAction !== null) {
      this.props.buttonAction();
    } else {
      import("../../authentication/cognito-client").then((module) => {
        return module.CognitoClient.signOut(true);
      });
    }
  }
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    return (
      <div>
        <h5>{this.props.modalMessageBody}</h5>
        {this.props.allowCancelButton ? (
          <RdvButton isOutline={true} extraClassName="outline-light" isCancel={true} text="Cancel" />
        ) : null}

        <RdvButton extraClassName="float-right" text={this.state.buttonText} onClick={this.submitModalAction} />
      </div>
    );
  }
}

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type SupportIssueKind = "API" | "OTHER" | "WEBSITE_BEHAVIOR" | "WEBSITE_CONTENT" | "%future added value";
export type CreateSupportIssueInput = {
    readonly browserAndDeviceType?: string | null;
    readonly clientMutationId: string;
    readonly description: string;
    readonly email?: string | null;
    readonly kind?: SupportIssueKind | null;
    readonly name?: string | null;
    readonly tags?: ReadonlyArray<string> | null;
};
export type createSupportIssueMutationVariables = {
    readonly input: CreateSupportIssueInput;
};
export type createSupportIssueMutationResponse = {
    readonly createSupportIssue: {
        readonly clientMutationId: string;
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly supportIssue: {
            readonly id: string;
            readonly name: string | null;
        } | null;
    } | null;
};
export type createSupportIssueMutation = {
    readonly response: createSupportIssueMutationResponse;
    readonly variables: createSupportIssueMutationVariables;
};



/*
mutation createSupportIssueMutation(
  $input: CreateSupportIssueInput!
) {
  createSupportIssue(input: $input) {
    clientMutationId
    hasErrors
    errors {
      id
      location
    }
    supportIssue {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSupportIssueInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createSupportIssue",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeleteSupportIssuePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "supportIssue",
        "storageKey": null,
        "args": null,
        "concreteType": "SupportIssue",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "createSupportIssueMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "createSupportIssueMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "createSupportIssueMutation",
    "id": null,
    "text": "mutation createSupportIssueMutation(\n  $input: CreateSupportIssueInput!\n) {\n  createSupportIssue(input: $input) {\n    clientMutationId\n    hasErrors\n    errors {\n      id\n      location\n    }\n    supportIssue {\n      id\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '59b5467cbec243490602f981dbc44394';
export default node;

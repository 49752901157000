import { Reducer } from "redux";
import { SetAppStatePayload } from "./types";
import { SET_APP_STATE } from "./constants";
import { AppAction } from "./types";

const initialState: SetAppStatePayload = {
  pageYOffset: 0,
  device: null,
  navbarIsOpen: false,
  muted: true,
};

export const AppReducer: Reducer<SetAppStatePayload, AppAction> = (
  state: SetAppStatePayload = initialState,
  action: AppAction,
) => {
  switch (action.type) {
    case SET_APP_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

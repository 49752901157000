import * as React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import * as Actions from "../../redux/actions";
import { AppState } from "../../redux";
import { RdvButton } from "../../../react-components/page-framework/rdv-button";
import { setEventState, createRSVPRequest } from "../redux/events/actions";
import { SetEventStateAction } from "../redux/events/types";
import { setToastMessage } from "../../../redux/actions";
import { TOAST_SUCCESS } from "../../../redux/toast/constants";
import { getEventInfo } from "../utils/get-event-info";
import { SetToastMessageAction } from "../../../redux/toast/types";
import { RSVPButton } from "../rsvp-button";

interface ComponentProps {
  event: any;
}

interface MapDispatchProps {
  setEventState: (payload: SetEventStateAction) => SetEventStateAction;
  setToastMessage: (payload: SetToastMessageAction) => SetToastMessageAction;
}
interface MapStateProps {}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { event, setToastMessage } = props;
  const domain = window.location.host;
  const { url, dateLine, isLiveEvent } = getEventInfo(event);
  const eventUrl = `https://${domain}${url}`;
  return (
    <div className="event-info">
      <div className="info-body">
        <div className="date desktop-event-video-title">{dateLine}</div>
        <h1 className="title desktop-event-video-title">{event?.name}</h1>
        <p className="description">{event?.summary}</p>
      </div>
      <div className="actions">
        {!isLiveEvent && <RSVPButton event={event} />}
        <button
          className="share"
          alt="share event"
          onClick={() => {
            navigator.clipboard.writeText(eventUrl);
            setToastMessage({ msg: "Event link was copied to clipboard!", type: TOAST_SUCCESS });
          }}
        >
          Share
        </button>
      </div>
    </div>
  );
};

const mapState = (state: AppState) => ({});

const mapDispatch = {
  setEventState: setEventState,
  setToastMessage: setToastMessage,
};

export const EventInfo = connect(mapState, mapDispatch)(Component);

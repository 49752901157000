/**
 * @author Ahmed serag
 * @date   2018-09-24
 * @description Declaration of the MultipleChoiceQuestionResponse interface.
 * @filename multiple-choice-question-response.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { MultipleChoiceQuestionChoice } from "./multiple-choice-question-choice";
import { QuestionResponder } from "./questionnaire";

/**
 * The __typeID of the Multiple Choice Question GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_MULTIPLE_CHOICE_RESPONSE: string = "MultipleChoiceQuestionResponse";


/**
 * A multiple choice question.
 *
 * @export
 * @interface MultipleChoiceQuestionResponse
 */
export interface MultipleChoiceQuestionResponse {
  
  /**
   * The choices made for the multiple-choice question
   *
   * @type {Array<MultipleChoiceQuestionChoice>}
   * @memberof MultipleChoiceQuestionResponse
   */
  choices?: MultipleChoiceQuestionChoice[];

  /**
   * The content of the response to the multiple-choice question.
   *
   * @type {string}
   * @memberof MultipleChoiceQuestionResponse
   */
  content?: string;

  /**
   * The timestamp when the response to the multiple-choice question was 
   * created as an ISO-8601 formatted UTC date string.
   *
   * @type {string}
   * @memberof MultipleChoiceQuestionResponse
   */
  created?: string;

  /**
   * The ID of the response to the multiple-choice question.
   *
   * @type {string}
   * @memberof MultipleChoiceQuestionResponse
   */
  id?: string;

  /**
   * A flag which indicates that the response to the multiple-choice question is marked for deletion.
   *
   * @type {boolean}
   * @memberof MultipleChoiceQuestionResponse
   */
  isDeleted?: boolean;

  /**
   * The timestamp when the response to the multiple-choice question was last modified as an ISO-8601
   * formatted UTC date string.
   *
   * @type {string}
   * @memberof MultipleChoiceQuestionResponse
   */
  lastModified?: string;
  
  /**
   * The person or entity making the response.
   *
   * @type {QuestionResponder}
   * @memberof MultipleChoiceQuestionResponse
   */
  responder?: QuestionResponder;

  /**
   * The typename of the node.
   *
   * @type {string}
   */
  __typename?: string;
}
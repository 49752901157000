/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2019-01-18
 * @filename: tweet.ts
 * @description: Declaration of Twitter tweet payload interface
 */

import { TweetMediaItem } from "./tweet-media-item";
import { TwitterHashTag } from "./twitter-hash-tag";

/**
 * The __typeID of the GraphQL Tweet object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_TWEET: string = "Tweet";

/**
 *
 * The details of a Twitter tweet
 * @export
 * @interface Tweet
 */
export interface Tweet {
  /**
   * The screen name of the author of the tweet
   * @type {string}
   * @memberof Tweet
   */
  author: string;

  /**
   * The content of the tweet
   * @type {String}
   * @memberof Tweet
   */
  content: string;

  /**
   * The collection of hashtags
   * @type {[TwitterHashTag]}
   * @memberof Tweet
   */
  hashtags: TwitterHashTag[];

  /**
   *  The display name of the author of the tweet
   *
   * @type {string}
   * @memberof Tweet
   */
  name: string;

  /**
   * Media associated with the tweet
   * @type {[TweetMediaItem]}
   * @memberof Tweet
   */
  media: TweetMediaItem[];

  /**
   * The URL of the profile of the author of the tweet
   *
   * @type {string}
   * @memberof Tweet
   */
  profileLink: string;

  /**
   * Media associated with the tweet
   * @type {number}
   * @memberof Tweet
   */
  retweetsCount?: number;

  /**
   * Media associated with the tweet
   * @type {number}
   * @memberof Tweet
   */

  favouritesCount?: number;

  /**
   * A flag which is true if the user is verified
   *
   * @type {boolean}
   * @memberof Tweet
   */
  verified: boolean;

  /**
   * The typename of the tweet.
   *
   * @type {string}
   */
  __typename?: string;
}

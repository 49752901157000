import {AppState} from "../../redux";
import {HtmlPageProps} from "./html-page";
import { aliasCache } from "../../redux/cache/alias-cache";

export const getMainPage = (state: AppState, props: HtmlPageProps) => {
  const { cacheKey } = props;
  if(!cacheKey) return null;
  const mainPage = state.cache.store[cacheKey] || aliasCache[cacheKey];
  return mainPage;
}

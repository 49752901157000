/**
 * @author William Alexander Livesley
 * @date   2018-03-22
 * @description Declaration of the HtmlDocumentLinkHeaderRelType interface.
 * @filename html-document-link-header-rel-type.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the HTML Document Header Link Rel GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_HTML_DOCUMENT_HEADER_LINK_REL_TYPE: string =
  "HtmlDocumentLinkHeaderRelType";

/**
 * An enumeration of the legal values of the HTTP link header rel attribute.
 */
export enum HtmlDocumentLinkHeaderRelTypeKind {
  /**
   * A link to an alternate version of the page (printed version for example).
   */
  ALTERNATE = "ALTERNATE",

  /**
   * A link to the author of the document.
   */
  AUTHOR = "AUTHOR",

  /**
   * A link to a help document.
   */
  HELP = "HELP",

  /**
   * A link to a license for the document.
   */
  LICENSE = "LICENSE",

  /**
   * A link to a resource that can be used to search through the current document and its related pages.
   */
  SEARCH = "SEARCH",

  /**
   * A link is to a CSS or other type of stylesheet.
   */
  STYLESHEET = "STYLESHEET"
}

/**
 * A legal value of the rel attribute of the link HTML header; the type of the relationship.
 *
 * @extends {Node}
 * @interface
 * @template D
 */
export interface HtmlDocumentLinkHeaderRelType<
  D extends Date | string = string
> extends Node<D> {
  /**
   * A description of the value of the rel attribute of the link HTML header; the type of the relationship. Metadata.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The kind of the value of the rel attribute of the link HTML header; the type of the relationship. Metadata.
   *
   * @type {HtmlDocumentLinkHeaderRelTypeKind}
   */
  kind?: HtmlDocumentLinkHeaderRelTypeKind;

  /**
   * A label of the value of the rel attribute of the link HTML header; the type of the relationship. Metadata.
   *
   * @type {string}
   */
  label?: string;
}

import { PipPlayerReducer } from "./reducer";
import { PipPlayerModuleState } from "./types";
// import sagas from "./saga";

export { PipPlayerModuleState };

export const pipPlayerModule = {
  id: "pipPlayer",
  reducerMap: {
    pipPlayer: PipPlayerReducer,
  },
  // sagas: [sagas],
};

import { GaEventPayload, GaEventAction } from "./types";
import * as constants from "./constants";

export const gaEvent = (payload: GaEventPayload): GaEventAction => ({
  type: constants.GA_EVENT,
  payload,
});

export const snowplowIdentify = (
  payload: SnowPlowIdentifyPayload
): SnowPlowIdentifyAction => ({
  type: constants.SNOWPLOW_IDENTIFY,
  payload,
});

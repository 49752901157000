/**
 * @author Ahmed Samer
 * @date   2019-12-12
 * @description Declaration of the UrlRoute interface.
 * @filename user-request.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { UserRequest } from "./user-request";
import { Person } from "./person";
import { Entity } from "./entity";
/**
 * the GraphQl Type.
 */
export const GRAPHQL_TYPE_CLAIM_REQUEST = "ClaimRequest";

/**
 *
 *
 * @export
 * @interface UrlRoute
 * @extends {Node}
 */
export interface ClaimRequest<D extends Date | string = string> extends UserRequest<D> {

  /**
   * A description of the actions associated with the poster.
   *
   * @type {string}
   * @memberof PosterModel
   */
  item?: Person | Entity;

  /**
   * A description of the actions associated with the poster.
   *
   * @type {string}
   * @memberof PosterModel
   */
  personId?: string;

  /**
   * A title for the actions associated with the poster.
   *
   * @type {string}
   * @memberof PosterModel
   */
  personEmail?: string;
}


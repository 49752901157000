import * as React from "react";

export const Or = ({ title }: { title?: string }) => (
  <div className="or-line-break">
    <div className="br" />
    <span>
      <em>{title ? title : "OR"}</em>
    </span>
    <div className="br" />
  </div>
);

import { AppReducer } from "./reducer";
import { AppModuleState } from "./types";
import sagas from "./saga";

export { AppModuleState };

export const appModule = {
  id: "app",
  reducerMap: {
    app: AppReducer,
  },
  sagas: [sagas],
};

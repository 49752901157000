/**
 * @author William Alexander Livesley
 * @date   2018-04-14
 * @description Declaration of the Person interface.
 * @filename person.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Category } from "./category";
import { ComponentWithData } from "./component-with-data";
import { Connection } from "./connection";
import { DynamicList } from "./dynamic-list";
import { Entity } from "./entity";
import { Follows } from "./follows";
import { GeographicalLocation } from "./geographical-location";
import { Inspiration } from "./inspiration";
import { InvestmentByPerson } from "./investment-by-person";
import { ListType } from "./list";
import { MediaAsset } from "./media-asset";
import { Node } from "./node";
import { Note } from "./note";
import { Position } from "./position";
import { Preview } from "./preview";
import { Questionnaire } from "./questionnaire";
import { SocialMediaPost } from "./social-media-post";
import { SocialMediaProfile } from "./social-media-profile";
import { StoryType } from "./story-type";
import { Subscription } from "./subscription";
import { Badge } from "./badge";
import { ContentModerationRole } from "./content-moderation-role";
import { SocialMediaCredential } from "./social-media-credential";
import { ListCollection } from "./list-collection";
import { Investment } from "./investment";
import { UserRequestType } from "./user-request-type";
import { Feed } from "./feed";
import { Notification } from "./notification";
import { Payment } from "./payment";

/**
 * The __typeID of the GraphQL Person type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_PERSON: string = "Person";

/**
 * A person.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface Person<D extends Date | string = string> extends Node<D> {
  /**
   * The entrepreneurial activities performed by a person
   *
   * @type {Array<Category>}
   * @memberof Person
   */
  activities?: Category[];

  /**
   * A flag which, if true, allows content submitted by a person to be auto-approved. If false then submitted content
   * must be approved as a result of the moderation process
   *
   * @type {boolean}
   * @memberof Person
   */
  autoApproveContent?: boolean;

  /**
   * The collection of badges associated with the person.
   *
   * @type {Badge[]}
   * @memberof Person
   */
  badges?: Badge[];

  /**
   * A role applied to the content moderation process
   *
   * @type {ContentModerationRole}
   * @memberof Person
   */
  contentModerationRole?: ContentModerationRole;

  /**
   * The content of the default view for the content management system.
   *
   * @type {ListCollection}
   * @memberof Person
   */
  defaultContentManagementView?: ListCollection;

  /**
   * The default geographical location of the person
   *
   * @type {GeographicalLocation}
   * @memberof Entity
   */
  defaultLocation?: GeographicalLocation;

  /**
   * A description the person (CEO & Founder)
   *
   * @type {string}
   * @memberof Person
   */
  description?: string;

  /**
   * The details for person story. This is only returned when the person is part of a list.
   *
   * @type {Array<ComponentWithData>}
   */
  details?: ComponentWithData[];

  /**
   * Array of e-mail addresses associated with the person.
   *
   * @type {Array<string>}
   * @memberof Person
   */
  emailAddresses?: string[];

  /**
   * The collection of entities associated with the person
   *
   * @type {Array<Entity>}
   * @memberof Person
   */
  entities?: Entity[];

  /**
   * A position
   *
   * @template D
   * @type {Connection<D,Position>}
   * @memberof Person
   */
  experience?: Connection<D, Position<D>>;

  /**
   * The collection of feed person
   *
   * @type {Array<Follows>}
   * @memberof Person
   */
  feed?: Feed;

  /**
   * The collection of people and entities that the person follows
   *
   * @type {Array<Follows>}
   * @memberof Person
   */
  follows?: Follows[];

  /**
   * The positions where the person founded an entity
   *
   * @template D
   * @type {Array<Position>}
   * @memberof Person
   */
  founded?: Position<D>[];

  /**
   * A fragment to access the person page.
   *
   * @type {string}
   * @memberof Person
   */
  fragment?: string;

  /**
   * The full name of the person (Peter James Smith)
   *
   * @type {string}
   * @memberof Person
   */
  fullName?: string;

  /**
   * The nickname or name usually used for a person (Pete)
   *
   * @type {string}
   * @memberof Person
   */
  greeting?: string;

  /**
   * A flag which is true if the person has a public page on the site
   *
   * @type {boolean}
   * @memberof Person
   */
  hasPublicPage?: boolean;

  /**
   * The collection of media assets which can be used as backgrounds for the header of
   * person pages
   *
   * @template D
   * @type {Array<MediaAsset<D>>}
   * @memberof Person
   */
  headerBackgrounds?: MediaAsset<D>[];

  /**
   * The collection of inspirations for the person
   *
   * @type {Array<Inspiration>}
   * @memberof Person
   */
  inspirations?: Inspiration[];

  /**
   * The collection of investments made by the person
   *
   * @type {Array<InvestmentByPerson>}
   * @memberof Person
   */
  investmentsBy?: (InvestmentByPerson | Investment)[];

  /**
   * Set to true if the details of the person may be claimed.
   */
  isClaimable?: boolean;

  /**
   * Set to true if the details of the person may be edited.
   */
  isEditable?: boolean;

  /**
   * Set to true if the person is a spectator, not an active participant.
   *
   * @type {Boolean}
   * @memberof Person
   */
  isSpectator?: Boolean;

  /**
   * The list with which the person is associated. This is only populated when the person is included as part of a list
   * with this information configured.
   *
   * @template D
   * @type {ListType<D, *>}
   * @memberof Person
   */
  list?: ListType<D, any>;

  /**
   * The current geographical location of the person
   *
   * @type {Array<GeographicalLocation>}
   * @memberof Person
   */
  locations?: GeographicalLocation[];

  /**
   *The collection of requests submitted by the person
   *
   * @type {UserRequest[]}
   * @memberof Person
   */
  requests?: UserRequestType[];

  /**
   * A page in the collection of media items about the person
   *
   * @template D
   * @type {DynamicList<D>[]}
   * @memberof Person
   */
  mediaAbout?: Connection<D, StoryType<D>>;

  /**CreateUpdateSocialMediaProfileInput
   * People and entities which are related to the person as a list of people and a list of entities
   *
   * @template D
   * @type {DynamicList<D>[]}
   * @memberof Person
   */
  moreLikeThis?: DynamicList<D>[];

  /**
   * A statement of personal belief - what makes the person tick.
   *
   * @type {Questionnaire}
   * @memberof Person
   */
  northStar?: Questionnaire;

  /**
   * The list with which the person is associated. This is only populated when the person is included as part of a list
   * with this information configured.
   *
   * @template D
   * @type {ListType<D, *>}
   * @memberof Person
   */
  myList?: String;

  /**
   * A page in the collection of notes made by the person.
   *
   * @memberof Person
   * @template D
   * @type {Connection<D, Note<D>>}
   */
  notesBy?: Connection<D, Note<D>>;

  /**
   * Posts made by a person.
   *
   * @template D
   * @type {Connection<D, SocialMediaPost<D>>}
   * @memberof Person
   */
  posts?: Connection<D, SocialMediaPost<D>>;

  /**
   * A collection of media assets used as previews. Only one media asset can act as a given type of preview
   *
   * @template D
   * @type Connection<D, Preview>
   */
  previews?: Connection<D, Preview>;

  /**
   * The collection of questionnaires to which the person may have responded
   *
   * @type {Connection<D, Questionnaire<D>>}
   * @memberof Person
   */
  questionnaires?: Connection<D, Questionnaire<D>>;

  /**
   * The self-declared responsibility of the person
   *
   * @type {string}
   */
  responsibility?: string;

  /**
   * The social media profiles of the person
   *
   * @type {Array<SocialMediaProfile>}
   */
  socialMediaProfiles?: SocialMediaProfile[];

  /**
   * The social media credentials of the person
   *
   * @type {SocialMediaCredential[]}
   * @memberof Person
   */
  socialMediaCredentials?: SocialMediaCredential[];

  /**
   * The subscriptions of the person to Radivision publications
   *
   * @type {Array<Subscription>}
   * @memberof Person
   */
  subscriptions?: Subscription[];

  /**
   * The URL of the person's website
   *
   * @type {string}
   * @memberof Entity
   */
  websiteUrl?: string;

  /**
   * The landing page url of the person.
   *
   * @type {string}
   * @memberof Story
   */
  landingPageUrl?: string;

  /**
   * The collection of notification
   *
   * @template D
   * @memberof Person
   */
  notifications?: Connection<D, Notification<D>>;

  /**
   * The collection of user payment information
   *
   * @template D
   * @memberof Person
   */
  payments?: Payment[];
}

export enum AccountType {
  ENTITY = "ENTITY",
  FOUNDER = "FOUNDER",
  INVESTOR = "INVESTOR",
  ENTREPRENEURIAL = "ENTREPRENEURIAL",
}

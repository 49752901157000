/**
 * @author Ahmed Samer
 * @date    2020-05-10
 * @filename  mutation-controller
 * @LastModifiedBy Ahmed Samer
 * @Copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { commitMutation } from "./utilities/relay";
import { CREATE_SUPPORT_ISSUE_GRAPHQL_MUTATION } from "./relay/mutations/create-support-issue";
import { UpdateLinkedInUserInput } from "@radivision/graphql/lib/ts/graphql/mutations/update-linked-in-user-input";
import { ENVIRONMENT } from "./relay/relay-environment";
import { GraphQLTaggedNode } from "relay-runtime";
import { getNewUniqueId } from "@radivision/common";
import { CreateSupportIssueInput } from "@radivision/graphql/lib/ts/graphql/mutations/create-support-issue-input";
import { UPDATE_LINKEDIN_USER_GRAPHQL_MUTATION } from "./relay/mutations/refresh-linked-in-credentials";
import { UpdateTwitterUserInput } from "@radivision/graphql/lib/ts/graphql/mutations/update-twitter-user-input";
import { REFRESH_TWITTER_USER_GRAPHQL_MUTATION } from "./relay/mutations/refresh-twitter-credentials";
import { CHARGE_PAYMENT_METHOD_GRAPHQL_MUTATION } from "./relay/mutations/charge-payment-method";
import { SUBSCRIBE_PAYMENT_METHOD_GRAPHQL_MUTATION } from "./relay/mutations/subscribe-price-plan";
import { storageFactory } from "./utilities/local-storage-factory";
const localStore = storageFactory(() => localStorage);

/**
 *
 *
 * @export
 * @class MutationController
 */
export class MutationController {
  /**
   *
   *
   * @static
   * @param {CreateSupportIssueInput} input
   * @returns {Promise<any>}
   * @memberof MutationController
   */
  public static createSupportIssue(input: CreateSupportIssueInput): Promise<any> {
    if (input) {
      input.clientMutationId = MutationController.getClientMutationId();
    }

    return MutationController.commitMutationAction(CREATE_SUPPORT_ISSUE_GRAPHQL_MUTATION, input).then(
      (mutationResults: any) => {
        return mutationResults.createSupportIssue.hasErrors === false
          ? Promise.resolve(mutationResults.createSupportIssue.supportIssue)
          : Promise.reject(mutationResults.createSupportIssue.errors);
      },
    );
  }

  /**
   *
   *
   * @static
   * @param {*} input
   * @returns
   * @memberof MutationController
   */
  public static chargePayment(input: any) {
    if (input) {
      input.clientMutationId = MutationController.getClientMutationId();
    }

    return MutationController.commitMutationAction(CHARGE_PAYMENT_METHOD_GRAPHQL_MUTATION, input).then(
      (mutationResults: any) => {
        return mutationResults.chargePaymentMethod.hasErrors === false
          ? Promise.resolve(mutationResults.chargePaymentMethod.transaction)
          : Promise.reject(mutationResults.chargePaymentMethod.errors);
      },
    );
  }

  /**
   *
   *
   * @static
   * @param {*} input
   * @returns
   * @memberof MutationController
   */
  public static subscribePaymentPlan(input: any) {
    if (input) {
      // input.clientMutationId = MutationController.getClientMutationId();
    }

    return MutationController.commitMutationAction(SUBSCRIBE_PAYMENT_METHOD_GRAPHQL_MUTATION, input).then(
      (mutationResults: any) => {
        return mutationResults.subscribePricePlan.hasErrors === false
          ? Promise.resolve(mutationResults.subscribePricePlan.transaction)
          : Promise.reject(mutationResults.subscribePricePlan.errors);
      },
    );
  }

  /**
   *
   *
   * @static
   * @param {CreateSupportIssueInput} input
   * @returns {Promise<any>}
   * @memberof MutationController
   */
  public static refreshLinkedInToken(input: UpdateLinkedInUserInput): Promise<any> {
    if (input) {
      input.clientMutationId = MutationController.getClientMutationId();
    }

    return MutationController.commitMutationAction(UPDATE_LINKEDIN_USER_GRAPHQL_MUTATION, input).then(
      (mutationResults: any) => {
        return mutationResults.updateTwitterUser.hasErrors === false
          ? Promise.resolve(mutationResults.updateTwitterUser.edge)
          : Promise.reject(mutationResults.updateTwitterUser.errors);
      },
    );
  }

  /**
   *
   *
   * @static
   * @param {CreateSupportIssueInput} input
   * @returns {Promise<any>}
   * @memberof MutationController
   */
  public static refreshTwitterInToken(input: UpdateTwitterUserInput): Promise<any> {
    if (input) {
      input.clientMutationId = MutationController.getClientMutationId();
    }

    return MutationController.commitMutationAction(REFRESH_TWITTER_USER_GRAPHQL_MUTATION, input).then(
      (mutationResults: any) => {
        //   "[refreshLinkedInToken] mutation results : ",
        //   mutationResults
        // );

        return mutationResults.updateTwitterUser.hasErrors === false
          ? Promise.resolve(mutationResults.updateTwitterUser.edge)
          : Promise.reject(mutationResults.updateTwitterUser.errors);
      },
    );
  }

  /**
   *
   *
   * @static
   * @memberof MutationController
   */
  private static commitMutationAction(mutation: GraphQLTaggedNode, input: {}): Promise<any> {
    let config: { mutation: GraphQLTaggedNode; variables: {} };

    let promise: Promise<any> = Promise.resolve();

    promise = promise.then(() => {
      if (!mutation) {
        throw new Error("Invalid mutation to process");
      }

      if (!input) {
        throw new Error("Invalid input object, exiting mutation action");
      }

      // attempt to initialize config paramter
      config = {
        mutation,
        variables: {
          input,
        },
      };

      return commitMutation(ENVIRONMENT, config);
    });

    // promise = promise.then((mutationResults: any) => {
    //

    //   return mutationResults.createEntity.hasErrors === false
    //     ? Promise.resolve(mutationResults.createEntity.entity)
    //     : Promise.reject(mutationResults.createEntity.errors);
    // });

    return promise;
  }

  /**
   * Check if there is a stored mutationId and generate new one if not.
   */
  private static getClientMutationId(): string {
    let clientMutationId: string = localStore.getItem("clientMutationId");

    if (!clientMutationId) {
      clientMutationId = getNewUniqueId();
      localStore.setItem("clientMutationId", clientMutationId);
    }
    return clientMutationId;
  }
}

// export default MutationController;

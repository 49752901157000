import * as React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Header } from "./header";
import { FetchListAction, FetchListPayload, CacheDataAction, CacheDataPayload } from "../../../redux/cache/types";
import { useWindowSize } from "../../../utilities/use-window-size";
import { PreviewKind, TopLevelMediaKind } from "@radivision/graphql";
import { AppState } from "../../../redux";
import * as Actions from "../../../redux/actions";
import backgroundImage from "./background.jpg";
import smallBackgroundImage from "./background-sm.jpg";
import { SmallCarousel } from "./small-carousel";
import { LargeCarousel } from "./large-carousel";
import { PitchGrid } from "../../lists/pitch-grid";
import { NotifyMeModal } from "./notify-me-modal";
import { Router } from "../../../utilities/router";
import { Loader } from "../../page/loader";
import { randomize } from "../../../utilities/randomize";
import { PseudoRandom } from "../../../utilities/pseudo-random";
import { rotate } from "../../../utilities/rotate";
import isEmpty from "lodash.isempty";
import { getPTDate } from "../../../utilities/get-pt-date";
import { Card as LargeCard } from "./card";
import { Card as SmallCard } from "./small-card";
import { ImageHelper } from "../../../utilities/image-helper";

interface ComponentProps {
  json: { sourceId: string; showBtn?: boolean; viewMode?: "GRID" | "CAROUSEL" };
}

interface MapStateProps {
  data: any;
  listId: string;
  showBtn?: boolean;
  isFetching: boolean;
  viewMode?: "GRID" | "CAROUSEL";
}

interface MapDispatchProps {
  fetchList: (payload: FetchListPayload) => FetchListAction;
  cacheData: (payload: CacheDataPayload) => CacheDataAction;
}

export type Props = ComponentProps & MapStateProps & MapDispatchProps;

export const Component = (props: Props) => {
  const { data, isFetching, fetchList, listId, showBtn, viewMode, cacheData } = props;
  const list = data?.list?.list;
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState<number>(0);
  const [retries, setRetries] = React.useState<number>(0);
  const [items, setItems] = React.useState(null);
  const windowSize = useWindowSize();
  const screenWidth = windowSize?.width;
  const isSmallScreen = !isNaN(screenWidth) && screenWidth <= 520;
  const GRID_VIEW = viewMode === "GRID";

  const isPrerender = /HeadlessChrome/i.test(navigator.userAgent);
  const hasHydratedData = !isEmpty(window.pitchplanet);

  const [hydrationData, setHydrationData] = React.useState(null);

  React.useEffect(() => {
    const shouldUseHydratedData = hasHydratedData && !list;
    if (shouldUseHydratedData) {
      const key = listId;
      const hydratedList = window.pitchplanet;
      cacheData({ key, data: hydratedList });
    }
  }, [hydrationData, cacheData, listId, hasHydratedData, list]);

  React.useEffect(() => {
    if (isPrerender && !hasHydratedData && !isEmpty(data)) {
      const listJson = JSON.stringify(data);
      setHydrationData(listJson);
    }
  }, [hasHydratedData, data, isPrerender]);

  React.useEffect(() => {
    const shouldFetchData = !hasHydratedData && !isEmpty(listId) && isEmpty(list) && !isFetching && retries < 3;
    if (shouldFetchData) {
      setRetries(retries + 1);
      fetchList({ variables: { listId } });
    }
  }, [listId, isFetching, fetchList, list, retries, setRetries, hasHydratedData]);

  React.useEffect(() => {
    if (list?.items?.edges && items === null) {
      const day = parseInt(Router.getParameterByName("day"));
      const currentDay = getPTDate().getDate();
      const seed = day || currentDay;
      const [first, ...rest] = rotate([...list?.items?.edges], seed);
      const generator = PseudoRandom((seed + 1) * 12356);
      const randomRest = randomize(rest, () => generator());
      const _items = [first, ...randomRest];
      setItems(_items);
    }
  }, [list, setItems, items]);

  if (!screenWidth) {
    return null;
  }

  return (
    <>
      {!hasHydratedData && isPrerender && hydrationData ? (
        <Helmet>
          <script id={"PitchPlanetListData"}>{`window.pitchplanet=${hydrationData}`}</script>
        </Helmet>
      ) : null}
      <Container isSmallScreen={isSmallScreen} isPitchPlanet={GRID_VIEW}>
        <Header />
        {items && !isPrerender ? (
          isSmallScreen ? (
            <SmallCarousel
              items={items}
              showBtn={showBtn}
              setCurrentSlideIndex={setCurrentSlideIndex}
              currentSlideIndex={currentSlideIndex}
              playMode={"YOUTUBE"}
            />
          ) : (
            <LargeCarousel
              items={items}
              showBtn={showBtn}
              setCurrentSlideIndex={setCurrentSlideIndex}
              currentSlideIndex={currentSlideIndex}
              playMode={"YOUTUBE"}
            />
          )
        ) : (
          <SkeletonWithImages items={items} isPrerender={isPrerender} isSmallScreen={isSmallScreen} />
        )}
      </Container>
      {GRID_VIEW && !isPrerender && <PitchGrid list={list} />}
    </>
  );
};

export const xSkeleton = (props) => {
  const { items, isSmallScreen, isPrerender } = props;
  const slides = items ? [items[items.length - 1], items[0], items[1]] : [];
  const Card = isSmallScreen ? SmallCard : LargeCard;
  return (
    <div className={`pitch-carousel-skeleton ${isPrerender ? "isPrerender" : ""}`}>
      {slides &&
        slides.map((edge: any, index) => {
          if (index > 2) return null;
          return (
            <div key={`pictch-card-${edge?.listItem?.item?.id}`}>
              <Card
                slickNext={() => {}}
                item={edge?.listItem?.item}
                currentSlideIndex={3}
                slideIndex={index}
                showBtn={false}
              />
            </div>
          );
        })}
    </div>
  );
};

export const SkeletonWithImages = (props) => {
  const { items, isPrerender, isSmallScreen } = props;
  const imageUrls = React.useCallback(
    (index) => {
      const item = items && items[index] ? items[index].listItem.item : null;
      return item?.previews
        ? ImageHelper.fetchOptimizedImageUrl({
            imageType: "PREVIEW",
            preview: {
              content: item.previews,
              previewKind: PreviewKind.CAROUSEL,
              topLevelMedia: TopLevelMediaKind.IMAGE,
            },
            desiredDimensions: { containerWidthRatio: 3 / 4, numberOfItems: 1 },
            isSquareImage: false,
            revision: item.revision,
          })
        : {};
    },
    [items],
  );

  return (
    <div className="pitch-carousel-skeleton">
      <div>{isPrerender && <img src={imageUrls(items?.length - 1).requestedResolutionUrl} />}</div>
      <div>
        <Loader isActive={true} />
        {isPrerender && <img src={imageUrls(0).requestedResolutionUrl} />}
      </div>
      <div>{isPrerender && <img src={imageUrls(1).requestedResolutionUrl} />}</div>
    </div>
  );
};

export const Skeleton = (props) => {
  return (
    <div className="pitch-carousel-skeleton">
      <div></div>
      <div>
        <Loader isActive={true} />
      </div>
      <div></div>
    </div>
  );
};
export const Container = ({ children, isSmallScreen, isPitchPlanet }) => {
  return (
    <>
      <div
        id="pitch_planet"
        className={`${isSmallScreen ? "small-pitch-carousel" : "pitch-carousel"} ${
          isPitchPlanet ? "is-pitch-planet" : "is-standalone"
        }`}
        style={{ backgroundImage: `url('${isSmallScreen ? smallBackgroundImage : backgroundImage}')` }}
      >
        <div className="top-shadow" />
        <div className="bottom-shadow" />
        {children}
      </div>
      <NotifyMeModal />
    </>
  );
};

const mapState = (state: AppState, props: ComponentProps) => {
  const listId = props.json.sourceId;
  const data = state.cache.store[listId];
  const list = data && data.list?.list;
  const showBtn = props?.json?.showBtn;
  const viewMode = props?.json?.viewMode;

  return {
    data,
    showBtn,
    viewMode,
    listId: props.json.sourceId,
    isFetching: state.cache.inProgress[listId],
  };
};

const mapDispatch = {
  fetchList: Actions.fetchList,
  cacheData: Actions.cacheData,
};

export const PitchPanel = connect(mapState, mapDispatch)(Component);

import React, { Component } from "react";

interface Props {
  active?: boolean;
  children: any;
  className?: string;
  onHide: (n: any) => void;
  onIntersect: (n: any) => void;
  onShow: (m: any) => void;
  options?: {
    root: any;
    rootMargin: string;
    threshold: number | number[];
  };
}

interface ComponentState {}

export default class IntersectionVisible extends Component<Props, ComponentState> {
  observer: any;
  node: any;
  constructor(props: Props) {
    super(props);
    this.observer = null;
  }

  static defaultProps = {
    active: true,
    className: "intersection-visible-wrapper",
    onHide: () => null,
    onShow: () => null,
    onIntersect: () => null,
  };

  handleObserverUpdate = (entries: any) => {
    const { onIntersect, onShow, onHide } = this.props;
    const { intersectionRect } = entries[0];
    const { top, left, bottom, right } = intersectionRect;

    if ([top, bottom, left, right].some(Boolean) && onShow) {
      onShow(entries);
    } else if (onHide) {
      onHide(entries);
    }

    onIntersect(entries);
  };

  /**
   * Starts the observer
   */
  startObserving() {
    this.observer.observe(this.node);
  }

  /**
   * Stops the observer
   */
  stopObserving() {
    this.observer.unobserve(this.node);
  }

  /**
   * Start the observer when the component is mounted
   */
  componentDidMount() {
    const { options } = this.props;
    this.observer = new IntersectionObserver(this.handleObserverUpdate, options);

    if (this.props.active) {
      this.startObserving();
    }
  }

  /**
   * Update observer state on prop changes
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.active && !prevProps.active) {
      this.startObserving();
    }

    if (!this.props.active && prevProps.active) {
      this.stopObserving();
    }
  }

  /**
   * Stop the observer on unmounting
   */
  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { className } = this.props;
    return (
      <div className={className} ref={(node) => (this.node = node)}>
        {this.props.children}
      </div>
    );
  }
}

import * as React from "react";
import logo from "../../../../static/pitch_planet_on_black.png";
import { connect } from "react-redux";
import { SVG } from "../../../component-configuration/svgs";
import { Router } from "../../../utilities/router";
import {setAppState} from "../../../redux/actions";
import {AppState} from "../../../redux";
import {SetAppStateAction, SetAppStatePayload} from "../../../redux/app/types";


interface ComponentProps {}
interface MapStateProps {
  muted: boolean;
}
interface MapDispatchProps {
  setAppState: (payload: SetAppStatePayload) => SetAppStateAction;
}

type Props = ComponentProps & MapStateProps & MapDispatchProps;

export const HeaderComponent = (props: Props) => {
  const { muted, setAppState } = props;
  const isPitchPlanet = /pitchplanet/i.test(window.location.href);
  const viewAll = React.useCallback((e) => {
    e.preventDefault();
    Router.navigateTo("/pitchplanet", undefined);
  }, []);

  return (
    <Container>
      <h3 className="pitch-panel-title">
        <span>
          <img src={logo} alt="Pitch Planet" />
        </span>{" "}
        {/* <div className="audio-controls" onClick={() => setAppState({ muted: !muted })}>
          {muted ? SVG.muted : SVG.unmuted}
        </div> */}
      </h3>
      {/* {isPitchPlanet ? (
        <p>
          Learn how you can invest in the latest startups. Watch pitch videos, check out profiles, and connect directly
          to the founders for investment opportunities, partnerships, careers, and more.
        </p>
      ) : (
        <p>Discover innovative startups with potential investment and other opportunities.</p>
      )} */}
      <p>Enjoy meeting the real-life superheroes curing cancer, battling climate change, delivering solutions to many problems, and aiming to create great prosperity for themselves, their families, and their investors along the way. Watch 99-second trailers and stay for longer full episodes of companies you'd like to learn more about. Be the Shark and click to connect to potential investment or other opportunities. </p>
      <div className="view-all">
        <a href="/pitchplanet" onClick={viewAll}>
          View All
          {SVG.chevronRight}
        </a>
      </div>
    </Container>
  );
};
const mapState = (state: AppState) => ({
  muted: state.app.muted,
});

const mapDispatch = {
  setAppState: setAppState;
}

export const Container = ({ children }) => {
  return <div className="pitch-carousel-header">{children}</div>;
};

export const Header = connect(mapState,mapDispatch)(HeaderComponent);

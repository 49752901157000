import * as React from "react";
import { Show } from "../../../component-configuration/show";
import { PreviewKind } from "@radivision/graphql/lib/ts/graphql/preview-type";
import { FixedList as GraphQlFixedList } from "@radivision/graphql/lib/ts/graphql/fixed-list";
import { Person as GraphQlPerson } from "@radivision/graphql/lib/ts/graphql/person";
import { PageInfo } from "@radivision/graphql/lib/ts/graphql/page-info";
import { RdvCarouselState } from "./index";
import { OptimizedImageUrls } from "../../../component-configuration/optimized-image";
import { CarouselItem } from "../../../component-configuration/carousel-item";
import {HomeGenericJson} from "../../../component-configuration/home-generic-json";

export interface RdvCarouselContextInterface {
  listDetailsView?: "PRE-ITEM" | "POST-ITEM";
  hideTitle?: boolean;
  displayMode?: "Carousel" | "GridView";
  campaignPreview?: OptimizedImageUrls;
  displaySubtitleFlag?: boolean;
  displayListDescriptionFlag?: boolean;
  hideItemTitle?: boolean;
  itemPreviewType?: PreviewKind;
  hasDetails?: boolean;
  state: RdvCarouselState;
  show: Show;
  viewAllOnClick: () => void;
  retrieveOriginalIndex: (i: CarouselItem) => number;
  prev: () => void;
  next: () => void;
  isCarouselDisplayMode: boolean;
  prevBtn: JQuery<HTMLElement>;
  nextBtn: JQuery<HTMLElement>;
  loadDefaultImage: (s: any, n: any) => void;
  youtubeHoverVideoOpt: any;
  openDetailsPanel: (index: number) => void;
  mouseLeave: () => void;
  mouseEnter: (n: number) => void;
  onYouTubeVideoEnds: () => void;
  playItemFullVideo: (n: number) => void;
  handleSaveResource: (
    user: GraphQlPerson,
    savedItemsList: GraphQlFixedList,
    updateUser: Function,
    updateSavedItemsList: Function,
    resourceId: string,
  ) => Promise<any>;
  handleUnSaveResource: (
    user: GraphQlPerson,
    savedItemsList: GraphQlFixedList,
    updateSavedItemsList: Function,
    resourceId: string,
  ) => Promise<any>;
  handleUnFollow: (user: GraphQlPerson, updateUser: Function, whoId: string) => void;
  handleFollow: (user: GraphQlPerson, updateUser: Function, whoId: string) => void;
	setState: (f: (prevState: RdvCarouselState) => any) => void;
  pageInfo: PageInfo;
  showItemTypeIcon ?: boolean;
  json: HomeGenericJson;
}

export const RdvCarouselContext: React.Context<RdvCarouselContextInterface | undefined> = React.createContext(
  undefined,
);
export const useCarouselContext = () => {
  const ctx = React.useContext(RdvCarouselContext);
  if (!ctx) {
    throw new Error("useAppContext must be inside a Provider with a value");
  }
  return ctx;
};

import { createStore } from "redux-dynamic-modules";
import { getSagaExtension } from "redux-dynamic-modules-saga";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import { appModule, AppModuleState } from "./app";
import { routerModule } from "./router";
import { accountModule, AccountModuleState } from "./account";
import { authenticationModule, AuthenticationModuleState } from "./authentication";
import { cacheModule, CacheModuleState } from "./cache";
import { detailsPanelModule, DetailsPanelModuleState } from "./details-panel";
import { fullScreenVideoModule, FullScreenVideoModuleState } from "./full-screen-video";
import { toastModule, ToastModuleState } from "./toast";
import { crawlerModule, CrawlerModuleState } from "./crawler";
import { notificationModule, NotificationModuleState } from "./notification";
import { pipPlayerModule, PipPlayerModuleState } from "./youtube-player";

const store = createStore(
  {
    initialState: {},
    enhancers: [],
    extensions: [getSagaExtension()],
    advancedComposeEnhancers: composeWithDevTools({
      maxAge: 500,
    }),
  },
  appModule,
  routerModule,
  cacheModule,
  accountModule,
  detailsPanelModule,
  fullScreenVideoModule,
  toastModule,
  authenticationModule,
  crawlerModule,
  notificationModule,
  pipPlayerModule,
);

export type AppState = AccountModuleState &
  DetailsPanelModuleState &
  AppModuleState &
  CacheModuleState &
  ToastModuleState &
  AuthenticationModuleState &
  FullScreenVideoModuleState &
  NotificationModuleState &
  CrawlerModuleState &
  PipPlayerModuleState;

export { store };

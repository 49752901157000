/**
 * @author Ahmed Serag
 * @date   2018-03-08
 * @description Declaration of the ComponentPropertyValue interface.
 * @filename component-property-value.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import { Person } from "./person";
import { ArticleStory } from "./article-story";
import { BookStory } from "./book-story";
import { DynamicList } from "./dynamic-list";
import { Entity } from "./entity";
import { FixedList } from "./fixed-list";
import { ListType } from "./list";
import { NewsletterStory } from "./newsletter-story";
import { OnlineCourseStory } from "./online-course-story";
import { OriginalContentStory } from "./original-content-story";
import { PodcastStory } from "./podcast-story";
import { Poster } from "./poster";
import { ListCollection } from "./list-collection";

/**
 * the GraphQl Type.
 */
export const GRAPHQL_TYPE_COMPONENT_PROPERTY_VALUE = "ComponentPropertyValue";

/**
 * A value of a component property.
 *
 * @export
 * @interface ComponentPropertyValue
 */
export interface ComponentPropertyValue {
  
  /**
   * An article.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  article?: ArticleStory;

  /**
   * A book.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  book?: BookStory;

  /**
   * A boolean value.
   *
   * @type {boolean}
   * @memberof ComponentPropertyValue
   */
  boolean?: boolean;

  /**
   * A dynamicList
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  dynamicList?: DynamicList;

  /**
   * an Entity
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  entity?: Entity;

  /**
   * A FixedList
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  fixedList?: FixedList;

  /**
   * The ID of the component property.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  id?: string;

  /**
   * An integer value.
   *
   * @type {number}
   * @memberof ComponentPropertyValue
   */
  int?: number;

  /**
   * A serialized JSON value.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  json?: any;

  /**
   * A list.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  list?: ListType;

  /**
   * A list Collection.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  listCollection?: ListCollection;

  /**
   * a NewsLetter.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  newsletter?: NewsletterStory;

  /**
   * The name of the property. Corresponds to the name of the props attribute.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  name?: string;

  /**
   * A real number value.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  number?: number;

  /**
   *  An  Online Course.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  onlineCourse?: OnlineCourseStory;

  /**
   * an OriginalContent.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  originalContent?: OriginalContentStory;

  /**
   * a Person.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  person?: Person;

  /**
   * a podcast.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  podcast?: PodcastStory;

  /**
   * a poster.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  poster?: Poster;

  /**
   * a Query parameter value(not populated in the API)
   *
   * @type {*}
   * @memberof ComponentPropertyValue
   */
  queryParameters?: any;

  /**
   * A string value.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  string?: string;

  /**
   * A logged in user details(not populated in the API).
   *
   * @type {Person}
   * @memberof ComponentPropertyValue
   */
  user?: Person;

  /**
   * the graphql type.
   *
   * @type {string}
   * @memberof ComponentPropertyValue
   */
  __typename?: string;
}
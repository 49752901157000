/**
 * @Author: Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-03-29 01:38:15
 * @description Implementation of the OnlineCourseStoryState React component and declaration of its related interfaces.
 * @filename category.ts
 */
import { Node } from "./node";

/**
 * The __typeID of the GraphQL Category type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_CATEGORY: string = "Category";

/**
 * A key for a type of entrepreneurial activity performed by a person
 *
 * @export
 * @enum {string}
 */
export enum CategoryKind {
  /**
   * A person who provides capital for a business start-up, usually in exchange for
   * convertible debt or ownership equity
   */
  ANGEL = "ANGEL",

  /**
   * A person has founded and remains actively involved in entrepreneurial activities for a
   * startup organization
   */
  ENTREPRENEUR = "ENTREPRENEUR",

  /**
   * A person with an interest in entrepreneurship but no active involvement
   */
  FAN = "FAN",

  /**
   * A person who works for an insitutional investment organization
   */
  INSTITUTIONAL_INVESTOR = "INSTITUTIONAL_INVESTOR",

  /**
   * A person who advises organizations on investment opportunities and processes in order
   * to optimize the benefits of investments to that organization
   */
  INVESTMENT_ADVISOR = "INVESTMENT_ADVISOR",

  /**
   * A person who has been involved in entrepreneurial activities for more than one startup
   * organization
   */
  SERIAL_ENTREPRENEUR = "SERIAL_ENTREPRENEUR"
}

/**
 * An entrepreneurial activity performed by a person
 *
 * @export
 * @extends {Node<D>}
 * @interface Category
 * @template D
 */
export interface Category<D extends Date | string = string> extends Node<D> {
  /**
   * A description of the category
   *
   * @type {string}
   * @memberof Category
   */
  description?: string;

  /**
   *  The category key
   *
   * @type {CategoryKind}
   * @memberof Category
   */
  kind?: CategoryKind;

  /**
   * The category label
   */
  label?: string;
}

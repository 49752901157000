/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2020-05-10
 * @description Implementation of general utilities to be used in the app.
 * @filename page-pixel-utility.ts
 */

export class PagePixelUtility {
  /**
   * Returns the width of the page in pixels.
   *
   * @returns {number} The width of the page in pixels.
   */
  static getWidthOfPageInPixels(): number {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  /**
   * Returns the width of the page in pixels.
   *
   * @returns {number} The width of the page in pixels.
   */
  static getHeightOfPageInPixels(): number {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );
  }
}

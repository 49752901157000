import { graphql, GraphQLTaggedNode } from "react-relay";

export const CREATE_RSVP_REQUEST_MUTATION: GraphQLTaggedNode = graphql`
  mutation createRsvpRequestMutation($input: CreateUpdatePitchRequestInput!) {
    createRSVPRequest(input: $input) {
      errors {
        id
        location
      }
      hasErrors
      userRequest {
        ... on RSVPRequest {
          id
          __typename
          status
          item {
            ... on Event {
              ...event @relay(mask: false)
            }
          }
        }
      }
    }
  }
`;

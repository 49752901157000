/**
 * @author Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-12-17
 * @description Implementation of search utilities to be used in the app.
 * @filename search.ts
 */
import { fetchQuery } from "react-relay";
import { SEARCH_QUERY } from "../relay/queries/search-query";
import { ENVIRONMENT } from "../relay/relay-environment";
import { HitItemKind } from "@radivision/graphql/lib/ts/graphql/search-hit";
import { GRAPHQL_TYPE_ARTICLE_STORY } from "@radivision/graphql/lib/ts/graphql/article-story";
import { GRAPHQL_TYPE_BOOK_STORY } from "@radivision/graphql/lib/ts/graphql/book-story";
import { GRAPHQL_TYPE_ENTITY } from "@radivision/graphql/lib/ts/graphql/entity";
import { GRAPHQL_TYPE_NEWSLETTER_STORY } from "@radivision/graphql/lib/ts/graphql/newsletter-story";
import { GRAPHQL_TYPE_ONLINE_COURSE_STORY } from "@radivision/graphql/lib/ts/graphql/online-course-story";
import { GRAPHQL_TYPE_PERSON } from "@radivision/graphql/lib/ts/graphql/person";
import { GRAPHQL_TYPE_PODCAST_STORY } from "@radivision/graphql/lib/ts/graphql/podcast-story";
import { GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY } from "@radivision/graphql/lib/ts/graphql/original-content-story";
import { GRAPHQL_TYPE_FIXED_LIST } from "@radivision/graphql/lib/ts/graphql/fixed-list";
import { GRAPHQL_TYPE_DYNAMIC_LIST } from "@radivision/graphql/lib/ts/graphql/dynamic-list";
import { GRAPHQL_TYPE_LIST_COLLECTION } from "@radivision/graphql/lib/ts/graphql/list-collection";
import { func } from "prop-types";
import { PITCH_PLANET_QUERY } from "../relay/queries/pitch-planet-query";

const PREFIX = ["al", "el", "al-", "el-"];
/**
 * mapper for filters to it's kinds.
 */
export const FILTERS: { [index: string]: any } = {
  shows: [HitItemKind.ORIGINAL_CONTENT],
  people: [HitItemKind.PERSON],
  companies: [HitItemKind.ENTITY],
  resources: [
    HitItemKind.ARTICLE,
    HitItemKind.BOOK,
    HitItemKind.NEWSLETTER,
    HitItemKind.ONLINE_COURSE,
    HitItemKind.PODCAST,
  ],
  articles: [HitItemKind.ARTICLE],
  books: [HitItemKind.BOOK],
  newsletters: [HitItemKind.NEWSLETTER],
  courses: [HitItemKind.ONLINE_COURSE],
  podcasts: [HitItemKind.PODCAST],
};

/**
 * suggester types.
 */
export const SUGGESTER_KIND = {
  /**
   * companies suggester name
   */
  company: "entity_name_suggester",
  /**
   * people suggester name
   */
  person: "person_name_suggester",
  /**
   * resources suggester name
   */
  resource: "title_suggester",
};

/**
 * Order Kinds.
 */
export const ODRED_KIND = {
  AtoZ: "name asc",
  latest: "published desc",
  oldest: "published asc",
};

/**
 * return array of suggestions for a specific text.
 *
 * @export
 * @param {string} searchText
 * @param {string} filter
 * @returns {Promise<any>}
 */
export function getSuggestions(searchText: string, filter?: string): Promise<any> {
  let suggester: string;
  let promise: Promise<any> = Promise.resolve();

  if (searchText !== null && searchText !== undefined && searchText.length > 0) {
    if (filter !== undefined && filter !== null) {
      suggester = getSuggesterNameFromFilter(filter);
    } else {
      suggester = "name_suggester";
    }
    promise = fetch(`${process.env.SUGGESTIONS_ENDPOINT}?suggester=${suggester}&q=${searchText}`).then(
      (result: Response) => {
        return result.json().then((result: { suggest: { suggestions: {} } }) => {
          return Promise.resolve(result.suggest.suggestions);
        });
      },
    );
  }
  return promise;
}

/**
 * extract suggester Name from filters.
 *
 * @param {string} filter
 * @returns {string}
 */
function getSuggesterNameFromFilter(filter: string): string {
  let suggesterName: string;

  if (filter.toLowerCase().indexOf("navigate") >= 0 || filter.toLowerCase().indexOf("resource") >= 0) {
    suggesterName = "title_suggester";
  } else if (
    filter.toLowerCase().indexOf("entit") >= 0 ||
    filter.toLowerCase().indexOf("compan") >= 0 ||
    filter.toLowerCase().indexOf("start") >= 0 ||
    filter.toLowerCase().indexOf("organization") >= 0
  ) {
    suggesterName = "entity_name_suggester";
  } else if (filter.toLowerCase().indexOf("person") >= 0 || filter.toLowerCase().indexOf("people") >= 0) {
    suggesterName = "person_name_suggester";
  } else {
    suggesterName = "name_suggester";
  }
  return suggesterName;
}

/**
 * search for specific text with specific filters.
 *
 * @export
 * @param {string} searchText
 * @returns {Promise<any>}
 */
export function search(
  searchText: string,
  filters?: { field: string; values: string[] }[],
  operator: "and" | "or" = "or",
  order?: string,
  cursor?: string,
): Promise<any> {
  let promise: Promise<any> = Promise.resolve();

  if (searchText !== undefined && searchText !== null && searchText.length > 0) {
    promise = fetchQuery(ENVIRONMENT, SEARCH_QUERY, {
      order,
      cursor,
      query: getSearchQuery(searchText.trim()),
      filterQuery: filters ? getFilterQuery(filters, operator) : "(not (prefix field=kind 'MARKET_ENTRY_STORY'))",
      facets: [
        {
          field: "kind",
        },
      ],
    }).then((result) => {
      if (
        result.search !== null &&
        result.search !== undefined &&
        result.search.hasErrors === false &&
        result.search.connection !== null &&
        result.search.connection !== undefined
      ) {
        return Promise.resolve(result.search.connection);
      }
      return Promise.resolve();
    });
  }

  return promise;
}

/**
 * extracting search query out of searchText
 *
 * @export
 * @param {string} searchText a text to be searched for
 * @returns {string} Structured Search query
 */
export function getSearchQuery(searchText: string): string {
  let searchQuery: string = "";
  let searchTerms: string[] = searchText.split(" ");

  if (searchTerms.length > 1) {
    // filter all prefix to be removed from search terms
    searchTerms = searchTerms.filter((item) => {
      return PREFIX.indexOf(item.toLowerCase()) < 0;
    });
    searchQuery = `(or (term '${searchTerms[0]}')`;
    for (const TERM of searchTerms) {
      if (searchTerms.indexOf(TERM) < searchTerms.length - 1) {
        searchQuery = `${searchQuery} (term '${TERM}')`;
      } else {
        searchQuery = `${searchQuery} (prefix '${TERM}') (term '${TERM}')`;
      }
    }
    searchQuery = `${searchQuery})`;
  } else if (searchTerms.length === 1) {
    searchQuery = `(or (term '${searchTerms[0]}') (prefix '${searchTerms[0]}'))`;
  }

  return searchQuery;
}

/**
 * generate filter query based on field and values for it
 *
 * @export
 * @param {{field: string, values: string[]}[]} filters
 * @param {('and' | 'or')} operator
 */
export function getFilterQuery(filters: { field: string; values: string[] }[], operator?: "and" | "or") {
  let filterQuery: string = "";

  if (filters.length > 1) {
    if (operator === "and") {
      filterQuery = "(and ";
    } else {
      filterQuery = "(or ";
    }
  }

  for (const filter of filters) {
    let f: string;
    if (filter.values.length > 1) {
      f = `(or (term field='${filter.field}' '${filter.values[0]}')`;
      for (const VALUE of filter.values) {
        f = `${f} (term field='${filter.field}' '${VALUE}')`;
      }
      f = `${f})`;
    } else if (filter.values.length === 1) {
      f = `(term field='${filter.field}' '${filter.values[0]}')`;
    }
    filterQuery = `${filterQuery}${f}`;
  }

  if (filters.length > 1) {
    filterQuery = `${filterQuery})`;
  }

  return filterQuery;
}
/**
 * map the type coming from search kinds to search result types.
 *
 * @export
 * @param {string} type
 * @returns {string}
 */
export function mapKindToSearch(type: string): string {
  let result: string;

  switch (type) {
    case HitItemKind.ARTICLE:
      result = "articles";
      break;
    case HitItemKind.BOOK:
      result = "books";
      break;
    case HitItemKind.ENTITY:
      result = "companies";
      break;
    case HitItemKind.NEWSLETTER:
      result = "newsletters";
      break;
    case HitItemKind.ONLINE_COURSE:
      result = "courses";
      break;
    case HitItemKind.ORIGINAL_CONTENT:
      result = "shows";
      break;
    case HitItemKind.PERSON:
      result = "people";
      break;
    case HitItemKind.PODCAST:
      result = "podcasts";
      break;
    default:
  }
  return result;
}
/**
 * map the type coming from search to search result types.
 *
 * @export
 * @param {string} type
 * @returns {string}
 */
export function mapTypeToSearch(type: string): string {
  let result: string = "";

  switch (type) {
    case GRAPHQL_TYPE_ARTICLE_STORY:
      result = "article";
      break;
    case GRAPHQL_TYPE_BOOK_STORY:
      result = "book";
      break;
    case GRAPHQL_TYPE_ENTITY:
      result = "entity";
      break;
    case GRAPHQL_TYPE_NEWSLETTER_STORY:
      result = "newsletter";
      break;
    case GRAPHQL_TYPE_ONLINE_COURSE_STORY:
      result = "online_course";
      break;
    case GRAPHQL_TYPE_PERSON:
      result = "person";
      break;
    case GRAPHQL_TYPE_PODCAST_STORY:
      result = "podcast";
      break;
    case GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY:
      result = "original_content";
      break;
    case GRAPHQL_TYPE_FIXED_LIST:
      result = "fixed_list";
      break;
    case GRAPHQL_TYPE_DYNAMIC_LIST:
      result = "dynamic_list";
      break;
    case GRAPHQL_TYPE_LIST_COLLECTION:
      result = "list_collection";
      break;
    default:
  }
  return result;
}

/**
 *
 *
 * @export
 * @param {string} query
 * @param {string} [fq]
 * @param {string} [order]
 */
export function structureQuerySearch(query: string, fq?: string, order?: string, cursor?: string) {
  let promise: Promise<any> = Promise.resolve();

  promise = promise.then(() => {
    if (!query) {
      throw new Error("Invalid Query");
    }

    return fetchQuery(ENVIRONMENT, PITCH_PLANET_QUERY, {
      order,
      cursor,
      query,
      filterQuery: fq,
    });
  });

  promise = promise.then((results: any) => {
    if (results.hasErrors) {
      throw new Error(results.errors);
    }

    return Promise.resolve(results?.pitchPlanet);
  });

  promise = promise.catch((err) => {
    return Promise.resolve();
  });

  return promise;
}

import * as React from "react";
import { useCarouselContext } from "./context";
import isEmpty from "lodash.isempty";

export const ShowLogo = () => {
  const { displayListLogo, listDetailsView, show } = useCarouselContext();

  const logoUrl =
    show && show.logo && !isEmpty(show.logo.requestedResolutionUrl)
      ? show?.logo?.requestedResolutionUrl
      : show?.logo?.screenResolutionUrl;

  // if (!listDetailsView) return null;
  // if (!displayListLogo) return null;
  // if (isEmpty(logoUrl)) return null;

  return (
    <Container>
      <img
        src={logoUrl}
        style={{
          width: "45vw",
          alignItems: "flex-start",
        }}
      />
    </Container>
  );
};

const Container = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className="logo">{children}</div>;
};

export default ShowLogo;

/**
 * @author Ahmed Serag
 * @date   2018-04-09
 * @description Declaration of the UrlRoute interface.
 * @filename url-route.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * the GraphQl Type.
 */
export const GRAPHQL_TYPE_URL_ROUTE = "UrlRoute";

/**
 * 
 *
 * @export
 * @interface UrlRoute
 * @extends {Node}
 */
export interface UrlRoute extends Node {

  /**
   * An alias, contained in the fragement of the request, used as the key for the URL-route.
   *
   * @type {string}
   * @memberof UrlRoute
   */
  alias: string;

  /**
   * A description of the URL route.
   *
   * @type {string}
   * @memberof UrlRoute
   */
  description?: string;

  /**
   * A label for the URL route.
   *
   * @type {string}
   * @memberof UrlRoute
   */
  label?: string;

  /**
   *
   *
   * @type {any[]}
   * @memberof UrlRoute
   */
  rules?: any[]
}
import { Reducer } from "redux";
import { EventsState, EventsActions } from "./types";
import { SET_EVENTS_STATE } from "./constants";

export const initialState: EventsState = {
  lastSeenId: null,
  event: null,
  investAmount: null,
};

export const EventsReducer: Reducer<EventsState, EventsActions> = (
  state: EventsState = initialState,
  action: EventsActions,
) => {
  switch (action.type) {
    case SET_EVENTS_STATE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

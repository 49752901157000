/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2019-02-05
 * @filename: image-edge.ts
 * @description: Declaration of image edge interface
 */

import { Image } from "./image";

/**
 * The __typeID of the GraphQL image format object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_INSTAGRAM_IMAGE_EDGE: string = "ImageEdge";

/**
 * An edge for an images connection including the image
 *
 * @export
 * @interface ImageEdge
 */
export interface ImageEdge {
  /**
   * The cursor uniquely identifying the edge in the connection
   *
   * @type {string!}
   * @memberof ImageEdge
   */
  cursor: string;
  /**
   * The edge in the connection
   *
   * @type {Image!}
   * @memberof ImageEdge
   */
  node: Image;
}

import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import { AppState } from "../../redux";
import { Toaster } from "./toaster";
import { DismissToastMessageAction, TOAST_TYPES } from "../../redux/toast/types";
import { TOAST_SUCCESS, TOAST_FAIL, TOAST_ALERT } from "../../redux/toast/constants";
import isEmpty from "lodash.isempty";

interface MapDispatchProps {
  dismissToastMessage: () => DismissToastMessageAction;
}
interface MapStateProps {
  msg: null | string;
  header?: string;
  autoHide?: boolean;
  type?: TOAST_TYPES;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { msg, header, autoHide, dismissToastMessage } = props;
  if (isEmpty(msg)) {
    return null;
  }
  return (
    <Toaster
      message={msg}
      type={
        props.type === null
          ? TOAST_SUCCESS
          : props.type === TOAST_FAIL
          ? "danger"
          : props.type === TOAST_ALERT
          ? "alert"
          : "success"
      }
      header={header}
      autoHide={autoHide}
      onClose={() => dismissToastMessage()}
    />
  );
};

const mapState = (state: AppState) => ({
  msg: state.toast.msg,
  autoHide: state.toast.autoHide,
  type: state.toast.type,
  header: state.toast.header,
});

const mapDispatch = {
  dismissToastMessage: Actions.dismissToastMessage,
};

export const ToastMessage = connect(mapState, mapDispatch)(Component);

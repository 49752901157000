/**
 * @author Ahmed Serag
 * @date   2018-03-28 02:49:26
 * @description Declaration of the SocialMediaPost interface.
 * @filename social-media-post.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Post } from "./post";
import { SocialMediaPlatform } from "./social-media-platform";

/**
 * The __typeID of the Social Media Post type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_SOCIAL_MEDIA_POST: string = "SocialMediaPost";

/**
 *  A post to a social media account
 *
 * @extends {Node}
 * @interface
 * @template D
 */
export interface SocialMediaPost<
  D extends Date | string = string
> {
  /**
   * The timestamp when the social media post was created
   *
   * @template D
   * @type {D}
   */
  created?: D;

  /**
   * The string representation of the unique identifier for this post
   * @type {string}
   */
  id?: string;

  /**
   * The timestamp when the social media post was last modified
   *
   * @template D
   * @type {string}
   */
  lastModified?: D;

  /**
   *The social media platform on which the post was made
   *
   * @type {SocialMediaPlatform}
   */
  platform?: SocialMediaPlatform;
  /**
   * A URL link (permalink) to the original post
   *
   * @type {string}
   * @memberof SocialMediaPost
   */
  link: string  
  /**
   *  The details of the social media post
   *
   * @type {Post}
   * @memberof SocialMediaPost
   */
  post: Post
  
  /**
   * The typename of the social media post.
   *
   * @type {string}
   */
  __typename?: string;
}

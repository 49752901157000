import * as React from "react";
import "./style.scss";
import { OfferMoreMenu } from "./offer-more-menu";
import { ImageHelper } from "../../../../utilities/image-helper";
import { PreviewKind, TopLevelMediaKind, Entity } from "@radivision/graphql";
import { OptimizedImageUrls } from "../../../../component-configuration/optimized-image";
import { SVG } from "../../../../component-configuration/svgs";
import { setToastMessage } from "../../../../redux/actions";
import { TOAST_SUCCESS } from "../../../../redux/toast/constants";
import { store } from "../../../../redux";
import { ProfilePictureName } from "../../../page-framework/profile-picture-name";
import "./offer-item.scss";

interface ComponentProps {
  offer: any;
  entity: Entity;
}

type Props = ComponentProps;
export const OfferItem = (props: Props) => {
  const { entity, offer } = props;
  const company = offer?.peopleEntities && offer?.peopleEntities[0]?.entity;

  const copyToClipboard = React.useCallback(() => {
    navigator.clipboard.writeText(offer?.promoCode);
    store.dispatch(setToastMessage({ msg: "offer code is copied!", type: TOAST_SUCCESS }));
  }, [offer]);

  if (!offer) return null;

  const imageUrls: OptimizedImageUrls = offer?.previews
    ? ImageHelper.fetchOptimizedImageUrl({
        imageType: "PREVIEW",
        preview: {
          content: offer?.previews,
          previewKind: PreviewKind.BACKGROUND,
          topLevelMedia: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: { containerWidthRatio: 3 / 4, numberOfItems: 3 },
        isSquareImage: false,
        revision: offer?.revision,
      })
    : null;

  return (
    <div className="card offer-card" id={offer?.id}>

      <div className="card-image" style={{ backgroundImage: `url('${imageUrls?.requestedResolutionUrl}')` }}></div>
    {!entity && company ? <ProfilePictureName subject={company} /> : entity ? null : <div className="profile-picture-placeholder" />}
      <div className="card-content">
        <OfferMoreMenu key={offer?.id} offer={offer} entity={entity} />
        <div className="description">
          <span>{offer?.description}</span>
        </div>
        <div className="code-btn">
          {offer?.promoCode ? (
            <div className="code" onClick={copyToClipboard}>
              <div>Use Code: {offer?.promoCode}</div>
              <div className="copy">{SVG.copy}</div>
            </div>
          ) : null}
          <a href={offer?.link} target="_blank" rel="noopener noreferrer" className="action-btn">
            Connect
          </a>
        </div>
      </div>
    </div>
  );
};

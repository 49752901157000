/**
 * @author William Alexander Livesley
 * @date   2018-04-08
 * @description Declaration of the NetworkAddress interface.
 * @filename network-address.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

/**
 * The __typeID of the GraphQL Network Address object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_NETWORK_ADDRESS: string = "NetworkAddress";

/**
 * An IPV4 or IPV6 network address.
 *
 * @interface
 */
export interface NetworkAddress {
  /**
   * The IPV4 or IPV6 network address.
   *
   * @type {string}
   */
  address?: string;
}

import { Reducer } from "redux";
import { PipPlayerState, PipPlayerAction } from "./types";
import * as constants from "./constants";

const initialState: PipPlayerState = {
  videoPlayer: {
    video: "",
    visible: false,
  },
};

export const PipPlayerReducer: Reducer<PipPlayerState, PipPlayerAction> = (
  state: PipPlayerState = initialState,
  action: PipPlayerAction,
) => {
  switch (action.type) {
    case constants.START_VIDEO_PLAYER:
      return {
        ...state,
        videoPlayer: {
          ...state.videoPlayer,
          video: action?.payload,
          visible: true,
        },
      };
    case constants.STOP_VIDEO_PLAYER:
      return {
        ...state,
        videoPlayer: { ...state.videoPlayer, video: "", visible: false },
      };
    default:
      return state;
  }
};

import * as React from "react";
import { SVG } from "../../../component-configuration/svgs";
import debounce from "lodash.debounce";
import "./horizontal-scroller.scss";

interface ComponentProps {
  rootClassName: string;
  extraClassName?: string;
}

export type Props = React.PropsWithChildren<ComponentProps>;

export const HorizontalScroller = (props: Props) => {
  const { children, rootClassName, extraClassName } = props;
  const tabBarId = `${rootClassName}-tab-bar`;
  const [element, setElement] = React.useState({ scrollLeft: 0, scrollWidth: 0, offsetWidth: 0, isScrollable: false });
  const { scrollLeft, scrollWidth, offsetWidth, isScrollable } = element;

  const onScroll = React.useCallback(() => {
    const element = document.getElementById(tabBarId);
    const isScrollable = element.scrollWidth > element.offsetWidth;
    const scrollWidth = element.scrollWidth;
    const scrollLeft = element.scrollLeft;
    const offsetWidth = element.offsetWidth;
    setElement({ scrollWidth, scrollLeft, offsetWidth, isScrollable });
  }, [tabBarId]);

  const scrollToRight = React.useCallback(() => {
    const element = document.getElementById(tabBarId);
    if ("scrollTo" in element) {
      element.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    }
  }, [tabBarId]);

  const scrollToLeft = React.useCallback(() => {
    const element = document.getElementById(tabBarId);
    if ("scrollTo" in element) {
      element.scrollTo({
        left: element.scrollLeft + offsetWidth,
        behavior: "smooth",
      });
    }
  }, [offsetWidth, tabBarId]);

  React.useEffect(() => {
    setTimeout(onScroll, 2000);
  }, [onScroll]);

  React.useEffect(() => {
    const element = document.getElementById(tabBarId);
    element?.addEventListener("scroll", debounce(onScroll, 200), true);
    onScroll();
    return () => element?.removeEventListener("scroll", onScroll);
  }, [onScroll, tabBarId]);

  const hasMoreLeft = scrollLeft + offsetWidth < scrollWidth;

  return (
    <div id={rootClassName} className="horizontal-scroller-container">
      {scrollLeft > 50 ? (
        <span className="tab-scroll-btn tab-prev" onClick={scrollToRight}>
          {SVG.chevronLeft}
        </span>
      ) : null}
      <ul className={`${extraClassName || ""} horizontal-scroller`} id={tabBarId}>
        {children}
      </ul>
      {isScrollable && hasMoreLeft ? (
        <span className="tab-scroll-btn tab-next" onClick={scrollToLeft}>
          {SVG.chevronRight}
        </span>
      ) : null}
    </div>
  );
};

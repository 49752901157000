/**
 * @author William Alexander Livesley
 * @date   2018-04-08
 * @description Declaration of the NetworkLocation interface.
 * @filename network-location.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { NetworkLocationAddress } from "./network-location-address";
import { Node } from "./node";

/**
 * The __typeID of the GraphQL Network Location object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_NETWORK_LOCATION: string = "NetworkLocation";

/**
 * A network location.
 *
 * @template D
 * @interface
 */
export interface NetworkLocation<D extends Date | string = string> extends Node<D> {
  /**
   * The addresses of the network location when accessed from given network zones.
   *
   * @type {Array<NetworkLocationAddress>}
   */
  addresses?: NetworkLocationAddress[];

  /**
   * A description of the media asset. Metadata.
   *
   * @type {string}
   */
  description?: string;

  /**
   * A label of the network location. Metadata.
   *
   * @type {string}
   */
  label?: string;

  /**
   * A flag which, if true, identifies that the network location supports paths. If not true then files at the network
   * location are accessed from the root path.
   *
   * @type {boolean}
   */
  supportsPath: boolean;
}

/**
 * @author William Alexander Livesley
 * @date   2018-03-20
 * @description Declaration of the ApiObject interface.
 * @filename api-object.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { ApiObjectKind } from "./api-object-kind";
import { ArticleStory } from "./article-story";
import { BookStory } from "./book-story";
import { DynamicList } from "./dynamic-list";
import { Entity } from "./entity";
import { FixedList } from "./fixed-list";
//import { HtmlDocument } from "./html-document";
//import { HtmlDocumentTemplate } from "./html-document-template";
//import { HtmlDocumentAliasRoute } from "./html-document-alias-route";
//import { ListCollection } from "./list-collection";
import { ListItemType } from "./list";
import { MediaAssetState } from "./media-asset-state";
import { MediaAsset } from "./media-asset";
import { MediaAssetFile } from "./media-asset-file";
import { MediaAssetFileStatus } from "./media-asset-file-status";
import { NewsletterStory } from "./newsletter-story";
import { NetworkLocation } from "./network-location";
import { NetworkZone } from "./network-zone";
import { OnlineCourseStory } from "./online-course-story";
import { OriginalContentStory } from "./original-content-story";
import { Person } from "./person";
import { Note } from "./note";
import { Questionnaire } from "./questionnaire";
import { Tag } from "./tag";
import { PodcastStory } from "./podcast-story";

/**
 * The __typeID of the API Object GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_API_OBJECT: string = "ApiObject";

/**
 * The different values returned in the API object.
 *
 * @template D, N
 * @type {(ArticleStory|OnlineCourseStory|BookStory|NewsletterStory)}
 */
export type ApiObjectType<
  D extends Date | string = string,
  N extends ListItemType<D> = ListItemType<D>
> =
  | ArticleStory<D>
  | BookStory<D>
  | NewsletterStory<D>
  | DynamicList<D, N>
  | Entity<D>
  | FixedList<D, N>
  //| HtmlDocument<D>
  //| HtmlDocumentAliasRoute<D>
  //| HtmlDocumentTemplate<D>
  // | ListCollection<D, L>
  | MediaAsset<D>
  | MediaAssetState<D>
  | MediaAssetFile<D>
  | MediaAssetFileStatus<D>
  // | Mutation
  | NetworkLocation<D>
  | NetworkZone<D>
  | Note<D>
  | OnlineCourseStory<D>
  | OriginalContentStory<D>
  | Questionnaire<D>
  | Person<D>
  | Tag<D>
  | PodcastStory<D>;

/**
 * Metadata describing the API object (organization, list, list collection, person, story)
 *
 * @template R, N
 * @interface
 */
export interface ApiObject<
  D extends Date | string = string,
  T extends ListItemType<D> = ListItemType<D>
> {
  /**
   * Metadata describing the kind of the API object
   *
   * @type {ApiObjectKind}
   */
  kind?: ApiObjectKind;

  /**
   * The ID of the API object.
   *
   * @type {string}
   */
  id?: string;

  /**
   * The value of the API object
   *
   * @template D, T
   * @type {ApiObjectType}
   */
  value?: ApiObjectType<D, T>;
}

import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { LiveVideoPanel } from "../../../react-components/panels/live-panel";
import { EventInfo } from "./event-info";
import { Companies } from "./companies";
import { CompanyInfo } from "./company-info";
import { About } from "./about";
import { Investment } from "./investment";
import { fetchEntity, fetchList } from "../../../redux/actions";
import { FetchListAction, FetchListPayload } from "../../../redux/cache/types";
import { RsvpSuccessModal } from "../events-carousel/rsvp-modal";
import { EventsListId } from "../../../constants/content-ids";
import { getEventInfo } from "../utils/get-event-info";
import { fetchEvent } from "../redux/events/actions";
import { FetchEventPayload, FetchEventAction } from "../redux/events/types";
import isEmpty from "lodash.isempty";
import { INVESTOR } from "../../../redux/authentication/account-types";
import { Person } from "@radivision/graphql/lib/ts/graphql/person";
import "./event-details.scss";
import { EventBanner } from "./event-banner";
import { ConnectInvestorModal } from "./connect-investor-modal";

export interface ComponentProps {
  event: string;
}
interface MapDispatchProps {
  fetchList: (payload: FetchListPayload) => FetchListAction;
  fetchEvent: (payload: FetchEventPayload) => FetchEventAction;
}
interface MapStateProps {
  user: (Person & { accountType: string | null }) | null;
  list: any;
  fetchListInProgress: boolean;
  fetchEventInProgress: boolean;
  story: any;
}

export type Props = MapDispatchProps & MapStateProps & ComponentProps;

export const Component = (props: Props) => {
  const { list, story, fetchEventInProgress, event: eventId, fetchEvent, user } = props;
  const [selectedCompany, setCompany] = React.useState(null);

  const events = list?.list?.list?.items?.edges?.map((edge: any) => edge?.listItem?.item);
  const item = events?.find((event: any) => event?.id === eventId);
  const event = story || item;

  React.useEffect(
    function _fetchevent() {
      const shouldFetchEvent = eventId && !story && !fetchEventInProgress;
      if (shouldFetchEvent) {
        fetchEvent({ id: eventId });
      }
    },
    [fetchEvent, story, fetchEventInProgress, eventId],
  );

  React.useEffect(() => {
    if (story && story.entities?.length && isEmpty(selectedCompany)) {
      setCompany(story?.entities[0]);
    }
  }, [selectedCompany, story]);

  const { dateLine } = getEventInfo(event);
  const entity = selectedCompany;
  const userIsAnInvestor = user && user.accountType?.kind === INVESTOR.kind;
  const rsvpEventsIds = user?.requests
    ? user?.requests?.map((request) =>
        request.__typename === "RSVPRequest" && request.item?.id ? request.item.id : null,
      )
    : [];
  const isAttending = event && rsvpEventsIds?.includes(event?.id);
  const eventIsLive = event?.status === "live" && !isEmpty(event?.mediaExternalId);
  const showEventVideo = userIsAnInvestor && isAttending && eventIsLive;
  return (
    <Container>
      <div className="event-info-video">
        <EventInfo event={event} />
        {showEventVideo ? (
          <LiveVideoPanel listId={null} json={{ youtubeId: event?.mediaExternalId }} />
        ) : (
          <EventBanner event={event} />
        )}
        <div className="event-info mobile-event-video-title">
          <div className="info-body">
            <div className="date">{dateLine}</div>
            <h1 className="title">{event?.name}</h1>
          </div>
        </div>
      </div>
      <Companies event={event} selectedCompany={selectedCompany} setCompany={setCompany} />
      <CompanyInfo entity={entity} />
      <div className="sections-row">
        <About entity={entity} />
        <Investment entity={entity} />
      </div>
      <RsvpSuccessModal />
      <ConnectInvestorModal
        message={"We sent your contact information to the pitching company to arrange an introduction"}
      />
    </Container>
  );
};

const Container = ({ children }) => {
  return <div className="event-details">{children}</div>;
};
const mapState = (state: AppState, props: ComponentProps) => {
  const eventId = props.event;
  return {
    user: state.account.user,
    list: state.cache.store[EventsListId],
    story: state.cache.store[eventId],
    fetchListInProgress: state.cache.inProgress[EventsListId],
    fetchEventInProgress: state.cache.inProgress[eventId],
  };
};

const mapDispatch = {
  fetchEntity: fetchEntity,
  fetchList: fetchList,
  fetchEvent: fetchEvent,
};

export const EventDetails = connect(mapState, mapDispatch)(Component);

/**
 * @author Ahmed Serag
 * @date   2018-04-19
 * @description Declaration of the Environment interface.
 * @filename environment.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { ApiObjectKind } from "./api-object-kind";
import { ConnectionPayload } from "./connection-payload";
import { NetworkZone } from "./network-zone";

/**
 * The __typeID of the Environment GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_ENVIRONMENT: string = "Environment";

/**
 * The environment of the site. This encapsulates informtaion which is unlikely to be changed
 *
 * @export
 * @interface Environment
 * @template D
 */
export interface Environment<D extends Date | string = string> {
  /**
   * A page in the collection of API object kinds with given properties and in a given order
   *
   * @template D
   * @type {ConnectionPayload<D, ApiObjectKind<D>>}
   * @memberof Environment
   */
  apiObjectKinds?: ConnectionPayload<D, ApiObjectKind<D>>;

  /**
   * The timestamp when the environment was last modified
   *
   * @template D
   * @type {D}
   * @memberof Environment
   */
  lastModified?: D;

  /**
   * A page in the collection of network location zones with given properties and in a given order
   *
   * @template D
   * @type {ConnectionPayload<D, NetworkZone<D>>}
   * @memberof Environment
   */
  zones?: ConnectionPayload<D, NetworkZone<D>>;
}

/**
 * @author W. Alex Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2018-03-30
 * @description A class acting as the error returned from a request to a GraphQL server where the user is not 
 * authorized to perform the request.
 * @filename authorization-error.ts
 */
import { AuthorizationPolicy } from "../authorization/authorization-policy";
import { Error } from "./error";

/**
 * The error returned from a request to a GraphQL server where the user is not authorized to perform the request.
 *
 * @extends {Error}
 */
export class AuthorizationError extends Error {
  /**
   * The type ID of the error.
   *
   * @type {string}
   */
  public static readonly TYPE_ID: string = "IuHRdWQmwUebJg0DdfXbgQ";

  /**
   * A parametric constructor.
   *
   * @param {string} location The location where the error was thrown.
   *
   * @param {Object<string, *>} _identity The identity supplied to the GraphQL resolver.
   *
   * @param {AuthorizationPolicy} _policy The authorization policy.
   *
   * @param {Object<string, *>} _requestArguments The GraphQL request arguments.
   *
   * @param {string} id The ID of the error.
   */
  public constructor(
    location: string,
    private _identity: {},
    private _policy: AuthorizationPolicy,
    private _requestArguments: {},
    id: string = null
  ) {
    super(location, id, 401, "Unauthorized");
  }

  /**
   * Returns the authorization policy.
   *
   * @type {AuthorizationPolicy}
   */
  public policy(): AuthorizationPolicy {
    return this._policy;
  }

  /**
   * Returns the identity supplied to the GraphQL resolver.
   *
   * @type {Object<string, *>}
   */
  public identity(): {} {
    return this._identity;
  }

  /**
   * Returns the GraphQL request arguments.
   *
   * @type {Object<string, *>}
   */
  public requestArguments(): {} {
    return this._requestArguments;
  }

  /**
   * The type of the error - a general classification of the error.
   *
   * @type {string}
   */
  public get type(): string {
    return AuthorizationError.TYPE_ID;
  }
}

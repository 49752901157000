/**
 * @Author: Nassar Sara
 * @Date:   2018-05-30T10:49:05+02:00
 * @Email:  sara@radivision.com
 * @Project: Radivision
 * @Filename: dynamic-list-selection-expression.ts
 * @Last modified by:   Nassar Sara
 * @Last modified time: 2018-05-30T13:39:50+02:00
 * @Copyright: Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { TagsClause } from "./tags-clause";

/**
 * The __typeID of the Dynamic List Order GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_DYNAMIC_LIST_SELECTION_EXPRESSION: string =
  "DynamicListSelectionExpression";

/**
 * Expressions to select those entities, people and stories included in the dynamic list
 *
 * @export
 * @interface DynamicListSelectionExpression
 */
export interface DynamicListSelectionExpression {
  /**
   * An expression that identify items in the list.
   *
   * @type {string}
   */
  expression?: string;

  /**
   * A clause identifying tags used to select list items.
   *
   * @type {TagsClause}
   */
  tags?: TagsClause;

  /**
   * The typename of the dynamic list selection expression.
   *
   * @type {string}
   */
  __typename?: string;
}

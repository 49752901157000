#edit-profile-tabs {
  &.horizontal-scroller-container {
    margin-bottom: 20px;
    .horizontal-scroller {
      margin:0px;
      padding: 0px;
    }
    .tab-prev {
      background: linear-gradient(90deg, rgba(22, 22, 22, 1) 0%, rgba(22, 22, 22, 1) 80%, rgba(22, 22, 22, 0) 100%);
      color: white;
    }
    .tab-next {
      color: white;
      background: linear-gradient(90deg, rgba(22, 22, 22, 0) 0%, rgba(22, 22, 22, 1) 20%, rgba(22, 22, 22, 1) 100%);
    }
  }
  .card {
    border-radius: 20px;
    padding: 5px 10px;
    display: inline-flex;
    align-items: center;
    margin: 0 5px;
    padding: 1px 10px;
    font-size: 16px;
    flex-shrink: 0;
    height: 30px;
    color: #888;
    border: 1px solid #313131;
    background-color: #171717;
    user-select: none;
    &.selected {
      color: #ddd;
      background-color: #313131;
    }
  }
}

/**
 * @author Ostap Demkovych
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2020-11-7 11:00:01
 * @description SnowPlow JS Tracker integration.
 * @filename analytics-snowplow.ts
 */
import Analytics, { AnalyticsInstance } from "analytics";
import snowplowPlugin from "@analytics/snowplow";

class AnalyticsSnowplowContainer {
  private analyticsInstance: AnalyticsInstance;
  private eventsStash: any[] = [];
  private initialized: boolean = false;

  init(): AnalyticsSnowplowContainer {
    if (this.initialized) return this;

    this.analyticsInstance = Analytics({
      app: "radivision",
      plugins: [
        snowplowPlugin({
          name: "snowplow",
          scriptSrc: process.env.SNOWPLOW_JS_TRACKER_URL,
          collectorUrl: process.env.SNOWPLOW_COLLECTOR_URL,
          trackerSettings: {
            appId: process.env.SNOWPLOW_APP_ID,
            cookieName: "_rdvp_",
            forceSecureTracker: true,
            contexts: {
              webPage: true,
              performanceTiming: true,
              clientHints: {
                includeHighEntropy: true,
              },
              gaCookies: true,
              geolocation: false,
              optimizelyXSummary: true,
            },
          },
        }),
      ],
    });

    this.analyticsInstance.on("initialize:snowplow", ({ instance }) => {
      instance.plugins.snowplow.enableActivityTracking(10, 10);
      instance.plugins.snowplow.enableLinkClickTracking();
      this.initialized = true;
    });

    return this;
  }

  private stash(eventFunction: Function, ...args: any[]): boolean {
    if (this.initialized) {
      this.unstash();
      return false;
    }
    this.eventsStash.push({ f: eventFunction.bind(this), a: args, i: eventFunction === this.identify });
    setTimeout(this.unstash.bind(this), 500);
    return true;
  }

  private unstash(): void {
    if (!!this.eventsStash && this.eventsStash.length > 0) {
      //run identifications
      for (let i: number = 0; i < this.eventsStash.length; i++) {
        let e: any = this.eventsStash[i];
        if (!!e.i) {
          setTimeout(e.f, 0, ...e.a);
        }
      }
      //run all other
      for (let i: number = 0; i < this.eventsStash.length; i++) {
        let e: any = this.eventsStash.pop();
        if (!e.i) {
          setTimeout(e.f, 0, ...e.a);
        }
      }
    }
  }

  identify(userId: string, userName: string, userEmail: string): AnalyticsSnowplowContainer {
    if (this.stash(this.identify, userId, userName, userEmail)) return this;

    this.analyticsInstance.identify(userId, { name: userName, email: userEmail });
    return this;
  }

  page(opts) {
    if (this.stash(this.page, opts)) return this;

    this.analyticsInstance.page(opts);
  }

  trackPlayVideo(opts: SnowPlowStructuredBaseEvent) {
    if (this.stash(this.trackPlayVideo, opts)) return this;

    if (!opts.url) {
      opts.url = opts.property;
    }
    window.snowplow("setCustomUrl", opts.url);
    this.analyticsInstance.track("play_video", opts);
  }

  trackClick(opts: SnowPlowStructuredBaseEvent) {
    if (this.stash(this.trackClick, opts)) return this;

    this.analyticsInstance.track("link_click", opts);
  }
}

type SnowPlowStructuredBaseEvent = {
  category: string;
  label?: string;
  property?: string;
  value?: number;
  url?: string;
};

export const analyticsSnowplow = new AnalyticsSnowplowContainer();

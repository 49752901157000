/**
 * @author William A. Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-08-04
 * @description Implementation of the GraphQL query to retrieve the current user.
 * @filename user-graphql-query.tsx
 */
import { graphql, GraphQLTaggedNode } from "react-relay";

/**
 * The GraphQL query to retrieve the current user.
 *
 * @type {GraphQLTaggedNode}
 */
export const USER_GRAPHQL_QUERY: GraphQLTaggedNode = graphql`
  query userGraphqlQuery($alias: String!, $userIdentityId: ID, $queryParameters: [String!]) {
    urlRouteByAlias(alias: $alias, userIdentityId: $userIdentityId, queryParameters: $queryParameters) {
      hasErrors
      errors {
        created
        id
        location
        type
      }
      node {
        user {
          ...person_fullProfile @relay(mask: false)
        }
      }
    }
  }
`;

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CreatePaymentInput = {
    readonly clientMutationId: string;
    readonly amount: string;
    readonly currency: string;
    readonly email: string;
    readonly payment_method?: string | null;
};
export type chargePaymentMethodMutationVariables = {
    readonly input: CreatePaymentInput;
};
export type chargePaymentMethodMutationResponse = {
    readonly chargePaymentMethod: {
        readonly hasErrors: boolean | null;
        readonly errors: ReadonlyArray<{
            readonly id: string;
            readonly location: string | null;
        } | null> | null;
        readonly transaction: {
            readonly id: string;
            readonly created: unknown | null;
            readonly amount: string | null;
            readonly currency: string | null;
            readonly invoice: string | null;
            readonly status: string | null;
        } | null;
    } | null;
};
export type chargePaymentMethodMutation = {
    readonly response: chargePaymentMethodMutationResponse;
    readonly variables: chargePaymentMethodMutationVariables;
};



/*
mutation chargePaymentMethodMutation(
  $input: CreatePaymentInput!
) {
  chargePaymentMethod(input: $input) {
    hasErrors
    errors {
      id
      location
    }
    transaction {
      id
      created
      amount
      currency
      invoice
      status
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreatePaymentInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "chargePaymentMethod",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PaymentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasErrors",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "transaction",
        "storageKey": null,
        "args": null,
        "concreteType": "Payment",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "amount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "currency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "invoice",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "chargePaymentMethodMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "chargePaymentMethodMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "chargePaymentMethodMutation",
    "id": null,
    "text": "mutation chargePaymentMethodMutation(\n  $input: CreatePaymentInput!\n) {\n  chargePaymentMethod(input: $input) {\n    hasErrors\n    errors {\n      id\n      location\n    }\n    transaction {\n      id\n      created\n      amount\n      currency\n      invoice\n      status\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'a30ab0bf710936f31da536cf5b37e771';
export default node;

import { graphql } from "react-relay";

export const htmlPageQuery = graphql`
  query htmlPageQuery($alias: String!, $userIdentityId: ID, $queryParameters: [String!]) {
    urlRouteByAlias(alias: $alias, userIdentityId: $userIdentityId, queryParameters: $queryParameters) {
      hasErrors
      errors {
        created
        id
        location
        type
      }
      node {
        id
        panels {
          component {
            id
            key
            properties {
              kind
              isRequired
              isQueryParameterName
              name
            }
          }
          properties {
            name
            article {
              id
            }
            book {
              id
            }
            boolean
            dynamicList {
              id
            }
            entity {
              id
            }
            fixedList {
              id
            }
            id
            int
            json
            listCollection {
              id
            }
            name
            newsletter {
              id
            }
            number
            onlineCourse {
              id
            }
            originalContent {
              id
            }
            person {
              id
            }
            podcast {
              id
            }
            poster {
              id
            }
            string
          }
        }
      }
    }
  }
`;

export const HTML_PAGE_QUERY = `
  query htmlPageQuery($alias: String!, $userIdentityId: ID, $queryParameters: [String!]) {
    urlRouteByAlias(alias: $alias, userIdentityId: $userIdentityId, queryParameters: $queryParameters) {
      hasErrors
      errors {
        created
        id
        location
        type
      }
      node {
        id
        panels {
          component {
            id
            key
            properties {
              kind
              isRequired
              isQueryParameterName
              name
            }
          }
          properties {
            name
            article {
              id
            }
            book {
              id
            }
            boolean
            dynamicList {
              id
            }
            entity {
              id
            }
            fixedList {
              id
            }
            id
            int
            json
            listCollection {
              id
            }
            name
            newsletter {
              id
            }
            number
            onlineCourse {
              id
            }
            originalContent {
              id
            }
            person {
              id
            }
            podcast {
              id
            }
            poster {
              id
            }
            string
          }
        }
    }
  }
`;

/**
 * @author Ahmed serag
 * @date   2019-03-31
 * @description Declaration of the EntityActivityKind enum.
 * @filename entity-activity.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

/**
 * An enumeration of the kinds of entrepreneurial activities performed by an entity.
 *
 * @export
 * @enum {number}
 */
export enum EntityActivityKind {
  /**
 * Educational Corporation is a corporation formed not for profit but for an educational purpose, 
 * particularly the establishment and maintenance of a school, college, or university
 */
EDUCATIONAL = "EDUCATIONAL",
/**
 * A government or state agency, sometimes an appointed commission, 
 * is a permanent or semi-permanent organization in the machinery 
 * of government that is responsible for the oversight and administration 
 * of specific functions, such as an administration
 */
GOVERNMENT_AGENCY = "GOVERNMENT_AGENCY",
/**
 * A nonprofit organization, also known as a non-business entity, not-for-profit organization,
 * or nonprofit institution, is a legal entity organized and operated for a collective, public 
 * or social benefit, in contrast with an entity that operates as a business aiming to generate 
 * a profit for its owners
 */
NON_PROFIT = "NON_PROFIT",
/**
 * A partnership is an arrangement where parties, known as business partners, agree to cooperate
 *  to advance their mutual interests. The partners in a partnership may be individuals,
 *  businesses, interest-based organizations, schools, governments or combinations.
 */
PARTNERSHIP = "PARTNERSHIP",
/**
 * A privately held company, private company, or close corporation is a corporation not owned by the government, 
 * non-governmental organizations and by a relatively small number of shareholders or company members, 
 * which does not offer or trade its company stock
 * 
 */
PRIVATELY_HELD = "PRIVATELY_HELD",
/**
 * Public organization means a not-for-profit economic development organization or other not-for-profit organization 
 * including one that sponsors or supports sporting events.
 */
PUBLIC_COMPANY = "PUBLIC_COMPANY",
/**
 * A self-employed person does not work for a specific employer who pays them a consistent salary 
 * or wage. Self-employed individuals, or independent contractors, earn income by contracting with 
 * a trade or business directly
 * 
 */
SELF_EMPLOYED = "SELF_EMPLOYED",
/**
 * elf-employed means running your own business, but that business can be set up in 
 * a variety of ways. These business types are owned by self-employed business 
 * owners: Sole proprietorship businesses have only one owner
 */
SELF_OWNED = "SELF_OWNED"
}
import { AnyAction } from "redux";
import { Person } from "@radivision/graphql/lib/ts/graphql/person";
import * as constants from "./constants";
import { FixedList } from "@radivision/graphql/lib/ts/graphql/fixed-list";

export interface UpdateUserPayload {
  user: Person;
}

export interface UpdateUserAction {
  type: typeof constants.UPDATE_USER;
  payload: UpdateUserPayload;
}

export interface UpdateUserRevisionPayload {
  revision: string;
}

export interface UpdateUserRevisionAction {
  type: typeof constants.UPDATE_USER_REVISION;
  payload: UpdateUserRevisionPayload;
}

export interface UpdateSavedItemsPayload {
  savedItems: FixedList;
}

export interface UpdateSavedItemsAction {
  type: typeof constants.UPDATE_SAVED_ITEMS;
  payload: UpdateSavedItemsPayload;
}

export interface FetchSavedItemsPayload {
  listId: string;
}

export interface FetchSavedItemsAction {
  type: typeof constants.FETCH_SAVED_ITEMS;
  payload: FetchSavedItemsPayload;
}

export interface FetchCurrentUserAction {
  type: typeof constants.FETCH_CURRENT_USER;
}

export interface FetchCurrentUserRevisionPayload {
  id: string;
}

export interface FetchCurrentUserRevisionAction {
  type: typeof constants.FETCH_CURRENT_USER_REVISION;
  payload: FetchCurrentUserRevisionPayload;
}

export interface FetchingCurrentUserAction {
  type: typeof constants.FETCHING_CURRENT_USER;
  payload: FetchingCurrentUserPayload;
}
export interface FetchingCurrentUserPayload {
  status: boolean;
}

export interface FollowResourcePayload {
  resourceId: string;
}

export interface FollowResourceAction {
  type: typeof constants.FOLLOW_RESOURCE;
  payload: FollowResourcePayload;
}

export interface UnfollowResourcePayload {
  resourceId: string;
}

export interface UnfollowResourceAction {
  type: typeof constants.UNFOLLOW_RESOURCE;
  payload: UnfollowResourcePayload;
}

export interface SaveResourcePayload {
  resourceId: string;
}

export interface SaveResourceAction {
  type: typeof constants.SAVE_RESOURCE;
  payload: SaveResourcePayload;
}

export interface UnsaveResourcePayload {
  resourceId: string;
}

export interface UnsaveResourceAction {
  type: typeof constants.UNSAVE_RESOURCE;
  payload: UnsaveResourcePayload;
}

export interface AddSaveItemLoadingPayload {
  itemId: string;
}

export interface AddSaveItemLoadingAction {
  type: typeof constants.ADD_SAVE_ITEM_LOADING;
  payload: AddSaveItemLoadingPayload;
}

export interface RemoveSaveItemLoadingPayload {
  itemId: string;
}

export interface RemoveSaveItemLoadingAction {
  type: typeof constants.REMOVE_SAVE_ITEM_LOADING;
  payload: AddSaveItemLoadingPayload;
}

export interface UpdateUserMyListPayload {
  user: Person;
  savedItems: FixedList;
}

export interface UpdateUserMyListAction {
  type: typeof constants.UPDATE_USER_MY_LIST;
  payload: UpdateUserMyListPayload;
}

export interface UpdateUserMyListSuccessPayload {
  listId: string | null;
}

export interface UpdateUserMyListSuccessAction {
  type: typeof constants.UPDATE_USER_MY_LIST_SUCCESS;
  payload: UpdateUserMyListSuccessPayload;
}

export interface RemoveFromSavedItemsPayload {
  resourceId: string;
}

export interface RemoveFromSavedItemsAction {
  type: typeof constants.REMOVE_FROM_SAVED_ITEMS;
  payload: RemoveFromSavedItemsPayload;
}

export interface RemoveFromUserRequestsPayload {
  id: string;
}

export interface RemoveFromUserRequestsAction {
  type: typeof constants.REMOVE_FROM_USER_REQUESTS;
  payload: RemoveFromUserRequestsPayload;
}
export interface UpdateUserRequestsPayload {
  request: any;
}

export interface UpdateUserRequestsAction {
  type: typeof constants.UPDATE_USER_REQUESTS;
  payload: UpdateUserRequestsPayload;
}

export interface DeactivateAccountRequestPayload {
  reasons: string[];
  description: string;
}

export interface DeactivateAccountRequestAction {
  type: typeof constants.DEACTIVATE_ACCOUNT_REQUEST;
  payload: DeactivateAccountRequestPayload;
}

export interface DeactivateRequest {
  id: string;
  description: string;
  reasons: string[];
  status: string;
}

export interface DeactivateAccountFulfilledPayload {
  userRequest: DeactivateRequest;
}

export interface DeactivateAccountFulfilledAction {
  type: typeof constants.DEACTIVATE_ACCOUNT_FULFILLED;
  payload: DeactivateAccountFulfilledPayload;
}

export interface ChangeEmailRequestPayload {
  email: string;
  currentEmail: string;
}

export interface ChangeEmailRequestAction {
  type: typeof constants.CHANGE_EMAIL_REQUEST;
  payload: ChangeEmailRequestPayload;
}

export interface ChangeEmailRequest {
  id: string;
  email: string;
  status: string;
}

export interface ChangeEmailFulfilledPayload {
  userRequest: ChangeEmailRequest;
}

export interface ChangeEmailFulfilledAction {
  type: typeof constants.CHANGE_EMAIL_FULFILLED;
  payload: ChangeEmailFulfilledPayload;
}

export interface VerifyChangeEmailPayload {
  verificationCode: string;
  email: string;

}
export interface VerifyChangeEmailAction {
  type: typeof constants.VERIFY_CHANGE_EMAIL;
  payload: VerifyChangeEmailPayload;
}

export interface CancelSubscriptionPayload {
  subscriptionId: string;
}

export interface CancelSubscriptionAction {
  type: typeof constants.CANCEL_SUBSCRIPTION;
  payload: CancelSubscriptionPayload;
}

export interface AccountState {
  user: Person | null;
  savedItems: FixedList | null;
  saveItemLoading: string[];
  currentSettingsMenu: string | null;
  profileActiveTab: string | null;
  pitchPlanetFilter: { [s: string]: string[] };
  isLoading: boolean;
}

export interface AccountModuleState {
  account: AccountState;
}

export interface SetPitchPlanetFilterPayload {
  key: string;
  value: string;
}
export interface SetPitchPlanetFilterAction {
  type: typeof constants.SET_PITCH_PLANET_FILTER;
  payload: SetPitchPlanetFilterPayload;
}

export interface SetSettingsMenuPayload {
  menu: string | null;
}
export interface SetSettingsMenuAction {
  type: typeof constants.SET_SETTINGS_MENU;
  payload: SetSettingsMenuPayload;
}
export interface SetProfileActiveTabPayload {
  activeTab: string | null;
}
export interface SetProfileActiveTabAction {
  type: typeof constants.SET_PROFILE_ACTIVE_TAB;
  payload: SetProfileActiveTabPayload;
}

export interface SetAccountIsLoadingPayload {
  status: boolean;
}

export interface SetAccountIsLoadingAction {
  type: typeof constants.SET_ACCOUNT_IS_LOADING;
  payload: SetAccountIsLoadingPayload;
}

export const SettingsMenuItems = [constants.SETTING_SIGN_IN_AND_SECURITY, constants.SETTING_BILLING_AND_PAYMENTS];

export type AccountAction =
  | AddSaveItemLoadingAction
  | FetchCurrentUserAction
  | FetchCurrentUserRevisionAction
  | FetchSavedItemsAction
  | FollowResourceAction
  | RemoveFromSavedItemsAction
  | RemoveFromUserRequestsAction
  | RemoveSaveItemLoadingAction
  | SaveResourceAction
  | SetPitchPlanetFilterAction
  | SetProfileActiveTabAction
  | SetSettingsMenuAction
  | UnfollowResourceAction
  | UnsaveResourceAction
  | UpdateSavedItemsAction
  | UpdateUserAction
  | UpdateUserMyListAction
  | UpdateUserRequestsAction
  | UpdateUserRevisionAction
  | VerifyChangeEmailAction
  | AnyAction;

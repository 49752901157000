/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2019-11-20
 * @description 
 * @filename media-format-kind.ts
 */
import { Node } from "./node";

/**
 * The __typeID of the GraphQL Media Asset State object.
 *
 * @type {string}
 */
// export const GRAPHQL_TYPE_MEDIA_FORMAT_KIND: string = "MediaFormatKind";

/**
 * An enumeration of the kinds of the states of media assets.
 */
export enum MediaFormatKind {
  /**
   *
   */
  GIF = "image/gif",

  /**
   *
   */
  HTML = "text/html",

  /**
   *
   */
  JPEG = "image/jpeg",

  /**
   *
   */
  AUDIO_MPEG = "audio/mpeg",

  /**
   *
   */
  VIDEO_MPEG = "video/mpeg",

  /**
   *
   */
  MP4 = "video/mp4",

  /**
   *
   */
  PLAIN = "text/plain",

  /**
   *
   */
  PNG = "image/png",

  /**
   *
   */
  OCTET_STREAM = "application/octet-stream",

  /**
   *
   */
  AUDIO_OGG = "audio/ogg",

  /**
   *
   */
  VIDEO_OGG = "video/ogg",

  /**
   *
   */
  SVG = "image/svg+xml",

  /**
   *
   */
  WEBP = "image/webp"
}

/**
 * The properties of a media asset state.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface MediaFormat<D extends Date | string = string> extends Node<D> {
  /**
   * A description of the media asset state.
   *
   * @type {string}
   */
  description?: string;

  /**
   * The kind of a media asset state.
   *
   * @type {MediaAssetStateKind}
   */
  kind?: MediaFormatKind;

  /**
   * A label of the media asset state.
   *
   * @type {string}
   */
  label?: string;
}

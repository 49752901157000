import * as React from "react";
import { useCarouselContext } from "./context";
import { Title } from "./elements";

export const ShowTitle = () => {
  const { listDetailsView, hideTitle, show } = useCarouselContext();
  if (hideTitle) return null;
  if(listDetailsView) return null;
  if (!show) return null;
  return (
    <Container>
      <Title>{show && show.title}</Title>
    </Container>
  );
};

const Container = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <h6> {children} </h6>
  );
};

export default ShowTitle;

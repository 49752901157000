/**
 * @author Ahmed Serag
 * @date   2018-04-19
 * @description Declaration of the HtmlDocumentAliasRoute interface.
 * @filename html-document-alias-route.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the HTML Document Alias Route type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_HTML_DOCUMENT_ALIAS_ROUTE: string = "HtmlDocumentAliasRoute";

/**
 * A route, a set of rules which map an HTML document alias to a given HTML document.
 *
 * @export
 * @interface HtmlDocumentAliasRoute
 * @extends {Node<D>}
 * @template D
 */
export interface HtmlDocumentAliasRoute<D extends Date | string = string>
  extends Node<D> {
  /**
   * An alias used as the key for the HTML document alias route
   *
   * @type {string}
   * @memberof HtmlDocumentAliasRoute
   */
  alias?: string;

  /**
   * A description of the HTML document alias route
   *
   * @type {string}
   * @memberof HtmlDocumentAliasRoute
   */
  description?: string;

  /**
   * A label of the HTML document alias route
   *
   * @type {string}
   * @memberof HtmlDocumentAliasRoute
   */
  label?: string;
}

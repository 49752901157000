/**
 * @author Ahmed Serag
 * @date   2018-04-19
 * @description Declaration of the HtmlDocumentTemplateSlot interface.
 * @filename html-document-template-slot.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { ApiObjectKind } from "./api-object-kind";
import { Component } from "./component";
import { Node } from "./node";

/**
 * The __typeID of the HTML Document Template Slot type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_HTML_DOCUMENT_TEMPLATE_SLOT: string = "HtmlDocumentTemplateSlot";

/**
 * An HTML document template slot.
 *
 * @extends {Node}
 * @interface
 * @template D
 */
export interface HtmlDocumentTemplateSlot<D extends Date | string = string>
  extends Node<D> {
  /**
   * The kind of the API object to be associated with the component in the HTML template
   * document slot
   *
   * @template D
   * @type {ApiObject<D>}
   */
  apiObjectKind?: ApiObjectKind<D>;

  /**
   * The component which would manage the HTML document slot
   *
   * @template D
   * @type {Component<D>}
   */
  component?: Component<D>;

  /**
   * A description of the HTML document slot. Metadata
   *
   * @type {string}
   */
  description?: string;

  /**
   * A label for the HTML document slot. Metadata
   *
   * @type {string}
   */
  label?: string;
}

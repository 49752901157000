import * as React from "react";
import { getPreviewImageUrl } from "./selectors";
import { connect } from "react-redux";
import { DEFAULTS } from "../../utilities/defaults";
import { Router } from "../../utilities/router";
import { CloseDetailsPanelAction } from "../../redux/details-panel/types";
import * as Actions from "../../redux/actions";
import { PitchVideoStory } from "@radivision/graphql/lib/ts/graphql/pitch-video-story";
import { SetProfileActiveTabPayload, SetProfileActiveTabAction } from "../../redux/account/types";
import { getProfileInfo } from "../../utilities/get-profile-info";
import isEmpty from "lodash.isempty";

interface ComponentProps {
  item: PitchVideoStory;
}

interface MapDispatchProps {
  closeDetailsPanel: () => CloseDetailsPanelAction;
  setProfileActiveTab: (payload: SetProfileActiveTabPayload) => SetProfileActiveTabAction;
}

const DEFAULT_Person_SQUARE = `${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_Person_SQUARE"]}?wip=300`;
const DEFAULT_Entity_SQUARE = `${process.env.URL_MEDIA_IMAGE}/${DEFAULTS["DEFAULT_Entity_SQUARE"]}?wip=300`;

type Props = ComponentProps & MapDispatchProps;
export const Component = (props: Props) => {
  const { item, closeDetailsPanel, setProfileActiveTab } = props;
  const founder = item?.peopleEntities && item.peopleEntities[0] && item?.peopleEntities[0].person;
  const company = item?.peopleEntities && item.peopleEntities[0] && item?.peopleEntities[0].entity;
  const owner = company?.people && company?.people?.edges && company?.people?.edges[0]?.person;
  const PERSON = owner || founder;
  const nominatedBy = item?.peopleEntities && item.peopleEntities[1] && item?.peopleEntities[1].entity;

  const founderPicture = getPreviewImageUrl(PERSON);
  const companyPicture = getPreviewImageUrl(company);
  const nominatedByPicture = getPreviewImageUrl(nominatedBy);

  const goTo = React.useCallback(
    (profileUrl) => (e: any) => {
      e.preventDefault();
      if (isEmpty(profileUrl)) return;
      closeDetailsPanel();
      setProfileActiveTab({ activeTab: null });

      if (profileUrl) {
        Router.navigateTo(profileUrl);
      }
    },
    [closeDetailsPanel, setProfileActiveTab],
  );

  const { profileUrl: companyUrl } = getProfileInfo(company);
  const { profileUrl: personUrl } = getProfileInfo(PERSON);
  const { profileUrl: nominatedByUrl } = getProfileInfo(nominatedBy);

  return (
    <Container>
      <div className="company-founder people-entity-section">
        {PERSON && (
          <>
            <h4>Company and Founder</h4>
            <div className="avatars-names">
              <div className="avatars">
                <a
                  href={company?.landingPageUrl}
                  onClick={goTo(companyUrl)}
                  className="large-avatar company-avatar"
                  style={{
                    backgroundImage: `url('${companyPicture?.requestedResolutionUrl || DEFAULT_Entity_SQUARE}')`,
                  }}
                ></a>
              </div>
              <div className="names">
                <h2>
                  <a href={company?.landingPageUrl} onClick={goTo(companyUrl)}>
                    {company?.name}
                  </a>
                </h2>
              </div>
            </div>
            <div className="avatars-names">
              <div className="avatars">
                <a
                  href={PERSON?.landingPageUrl}
                  onClick={goTo(personUrl)}
                  className="large-avatar founder-avatar"
                  style={{
                    backgroundImage: `url('${founderPicture?.requestedResolutionUrl || DEFAULT_Person_SQUARE}')`,
                  }}
                ></a>
              </div>
              <div className="names">
                <h2>
                  <a href={PERSON?.landingPageUrl} onClick={goTo(personUrl)}>
                    {PERSON?.fullName}
                  </a>
                  <h6>CEO</h6>
                </h2>
              </div>
            </div>
          </>
        )}
      </div>
      {nominatedBy && (
        <div className="nominated-by people-entity-section">
          <h4>Nominated By</h4>
          <div className="avatars-names">
            <div className="avatars">
              <a
                href={nominatedBy?.landingPageUrl}
                onClick={goTo(nominatedByUrl)}
                className="large-avatar"
                style={{
                  backgroundImage: `url('${nominatedByPicture?.requestedResolutionUrl || DEFAULT_Entity_SQUARE}')`,
                }}
              ></a>
            </div>
            <div className="names">
              <h2>
                <a href={nominatedBy?.landingPageUrl} onClick={goTo(nominatedByUrl)}>
                  {nominatedBy?.name}
                </a>
              </h2>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

const Container = ({ children }) => {
  return <div className="pitch-people-entity details-panel-section">{children}</div>;
};

const mapState = (state: AppState) => ({});

const mapDispatch = {
  closeDetailsPanel: Actions.closeDetailsPanel,
  setProfileActiveTab: Actions.setProfileActiveTab,
};

export const PitchPeopleEntity = connect(mapState, mapDispatch)(Component);

/**
 * @author: Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date: 2019-02-05
 * @filename: image-payload.ts
 * @description: Declaration of response to a mutation to create an image interface
 */

import { ImageEdge } from "./image-edge";

/**
 * The __typeID of the GraphQL image payload object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_INSTAGRAM_IMAGE_PAYLOAD: string = "ImagePayload";

/**
 * The response to a mutation to create an image optimized to a given size and format
 *
 * @export
 * @interface ImagePayload
 */
export interface ImagePayload {
  /**
   * An ID for client-side management of the mutation - associates the mutation with its response
   *
   * @type {string}
   * @memberof ImagePayload
   */
  clientMutationId: string;
  /**
   * An edge containing the newly added HTML document
   *
   * @type {ImageEdge}
   * @memberof ImagePayload
   */
  edge: ImageEdge;
  /**
   *
   *# /One or more errors associated with the mutation
   * @type {[Error!]}
   * @memberof ImagePayload
   */
  errors: [Error];
  /**
   *  A flag which is true if the response contains errors
   *
   * @type {boolean}
   * @memberof ImagePayload
   */
  hasErrors: boolean;
}

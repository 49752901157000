import { ProfileReducer } from "./reducer";
import { ProfileModuleState } from "./types";
import sagas from "./saga";

export { ProfileModuleState };

export const profileModule = {
  id: "profile",
  reducerMap: {
    profile: ProfileReducer,
  },
  sagas: [sagas],
};

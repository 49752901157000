import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import {
  SetAuthStatePayload,
  SetAuthStateAction,
  CheckAccountExistsAction,
  CheckAccountExistsPayload,
} from "../../../redux/authentication/types";
import { AUTH_SIGNUP_PASSWORD, AUTH_SIGNUP } from "../../../redux/authentication/constants";
import { FormFieldContainer, FormFieldInput, Submit } from "../modal-form-elements";
import { ProvidersSignin } from "./providers-signin";
import { AlreadyHaveAnAccount } from "./already-have-an-account";
import { isValidEmail } from "../../../utilities/is-valid-email";
import { TOAST_ALERT } from "../../../redux/toast/constants";
import isEmpty from "lodash.isempty";
import { SetToastMessageAction, SetToastMessagePayload } from "../../../redux/toast/types";

interface ComponentProps {}

interface MapDispatchProps {
  setAuthState: (paylaod: SetAuthStatePayload) => SetAuthStateAction;
  setToastMessage: (payload: SetToastMessagePayload) => SetToastMessageAction;
  checkAccountExists: (payload: CheckAccountExistsPayload) => CheckAccountExistsAction;
}

interface MapStateProps {
  step: number;
  email: string;
  inProgress: boolean;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { step, email, setAuthState, setToastMessage, checkAccountExists, inProgress } = props;
  const shouldDisplay = step === AUTH_SIGNUP;

  const onSubmit = React.useCallback(() => {
    if (!isValidEmail(email)) {
      setToastMessage({ msg: "Please enter a valid email address", type: TOAST_ALERT });
      return;
    }
    checkAccountExists({ email });
  }, [email, setToastMessage, checkAccountExists]);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Container>
      <div className="subtitle">
        Radivision&lsquo;s interactive digital TV and social network unlocks the fascinating world of entrepreneurship
        through original video series, a connected global community and more.
      </div>
      <FormFieldContainer>
        <FormFieldInput>
          <input
            type="email"
            className="form-control"
            placeholder="Email Address"
            required={true}
            disabled={false}
            value={email}
            onChange={(e) => setAuthState({ email: e.target.value })}
          />
        </FormFieldInput>
      </FormFieldContainer>
      <Submit
        onClick={onSubmit}
        disabled={isEmpty(email)}
        className={!isValidEmail(email) ? "disabled" : ""}
        isSubmitting={inProgress}
        title={"Join"}
      />
      <Or />
      <ProvidersSignin step={step} />
      <AlreadyHaveAnAccount />
    </Container>
  );
};

export const Or = () => (
  <div className="or-line-break">
    <div className="br" />
    <span>
      <em>OR</em>
    </span>
    <div className="br" />
  </div>
);

const Container = ({ children }) => {
  return <div className="signin-form">{children}</div>;
};

const mapState = (state: AppState) => ({
  step: state.authentication.step,
  email: state.authentication.email,
  password: state.authentication.password,
  inProgress: state.cache.inProgress["check-account-exists"],
});

const mapDispatch = {
  setAuthState: Actions.setAuthState,
  setToastMessage: Actions.setToastMessage,
  checkAccountExists: Actions.checkAccountExists,
};

export const SignUp = connect(mapState, mapDispatch)(Component);

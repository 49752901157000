/**
 * @author W. Alex Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @date 2018-08-12
 * @description A class containing the error returned from a request to a GraphQL server where the request is for an 
 * unknown resource.
 * @filename unknown-resource-error.ts
 */
import { Error } from "./error";

/**
 * A type defining the information identifying an unknown resource.
 *
 * @type {{type:string, id:string}}
 */
export type UnknownResource = { type: string; id: string };

/**
 * The error returned from a request to a GraphQL server where the request is for an unknown resource.
 *
 * @extends {Error}
 */
export class UnknownResourceError extends Error {
  /**
   * The type ID of the error.
   *
   * @type {string}
   */
  public static readonly TYPE_ID: string = "NJc0Pl7hRK2JxcmIVzuD4Q";

  /**
   * A parametric constructor.
   *
   * @param {string} location The location where the error was thrown.
   *
   * @param {UnknownResource} _unknown The unknown resource.
   *
   * @param {string} id The ID of the error.
   */
  public constructor(
    location: string,
    private _unknown: UnknownResource,
    id: string = null
  ) {
    super(location, id, 400, "Bad Request");
  }

  /**
   * Returns the details of the error.
   *
   * @type {Array<UnknownNode>}
   */
  public unknown(): UnknownResource {
    return this._unknown;
  }

  /**
   * The type of the error - a general classification of the error.
   *
   * @type {string}
   */
  public get type(): string {
    return UnknownResourceError.TYPE_ID;
  }
}

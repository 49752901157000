/**
 * @author Ahmed Samer
 * @date   2020-07-02
 * @description Declaration of the AddToPersonInput interface.
 * @filename delete-entity-attr-input.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { AddToNodeInput } from "./add-to-node-input";

/**
 * The input to the mutation to add edge entities to an existing person.
 * Duplicates are ignored
 */
export interface DeleteEntityAttributeInput extends AddToNodeInput {
  /**
   *
   *
   * @type {DeletePersonAttributeKind[]}
   * @memberof DeleteEntityAttributeInput
   */
  attributes: DeleteEntityAttributeKind[];
}

/**
 *
 *
 * @export
 * @enum {number}
 */
export enum DeleteEntityAttributeKind {
  /**
   * .
   */
  DESCRIPTION = "DESCRIPTION",

  /**
   * .
   */
  PEOPLE = "PEOPLE",

  /**
   * .
   */
  LOCATIONS = "LOCATIONS",

  /**
   * .
   */
  PREVIEWS = "PREVIEWS",

  /**
   * .
   */
  STORIES = "STORIES",

  /**
   *
   */
  SOCIAL_MEDIA = "SOCIAL_MEDIA",

  /**
   *
   */
  WEBSITE = "WEBSITE",

  /**
   *
   */
  FOUNDED = "FOUNDED",

  /**
   *
   */
  NUMBER_OF_EMPLOYEES = "NUMBER_OF_EMPLOYEES",

  /**
   *
   */
  NUMBER_OF_PORTFOLIO_ENTITIES = "NUMBER_OF_PORTFOLIO_ENTITIES",
  /**
   *
   */

  PORTFOLIO_MARKET_CAP = "PORTFOLIO_MARKET_CAP",

   /**
   * 
   * 
   */
    PITCH_ORIGINAL_VIDEO = "PITCH_ORIGINAL_VIDEO",
}

/**
* @author: Ahmed Samer
* @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
* @date: 2019-01-18
* @filename: twitter-hash-tag.ts
* @description: Declaration of Twitter hash tag payload interface
*/


/**
 * The __typeID of the GraphQL Tweet object.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_TWITTER_HASHTAG: string = "TwitterHashTag";

/**
 *
 * A Twitter hashtag, minus the leading ‘#’ character
 * @export
 * @interface TweetMediaItem
 */
export interface TwitterHashTag {
    /**
     *The indices identifying the location of the hashtag in the text
     *
     * @type {[number]}
     * @memberof TwitterHashTag
     */
    indices: number [];

    /**
     * string representing The tag
     *
     * @type {string}
     * @memberof TwitterHashTag
     */
    text: string;
}
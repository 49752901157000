import * as React from "react";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import { Modal } from "../../modals/modal";
import { ResendCodeLink } from "../../modals/authentication/resend-code-link";
import { VerifyChangeEmailPayload, VerifyChangeEmailAction } from "../../../redux/account/types";
import { verifyChangeEmail } from "../../../redux/account/actions";
import { FormFieldContainer } from "../../modals/modal-form-elements";
import { Spinner } from "../../user-widgets/spinner";
import { AppState } from "../../../redux";
import * as Actions from "../../../redux/actions";
import { SetAuthStateAction, SetAuthStatePayload } from "../../../redux/authentication/types";

interface ComponentProps {
  email: string;
}

interface ComponentState {
  verificationCode: string;
}

interface MapStateProps {
  onProgress?: boolean;
}

interface MapDispatchProps {
  verifyChangeEmail: (payload: VerifyChangeEmailPayload) => VerifyChangeEmailAction;
  setAuthState: (payload: SetAuthStatePayload) => SetAuthStateAction;
}

export const CHANGE_EMAIL_VERIFICATION_MODAL_ID = `change-email-modal`;

type Props = ComponentProps & MapDispatchProps & MapStateProps;

class Component extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      verificationCode: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  onSubmit() {
    const { verificationCode } = this.state;
    const { email } = this.props;
    this.props.verifyChangeEmail({ email, verificationCode });
  }
  render() {
    const { verificationCode } = this.state;
    const { inProgress } = this.props;
    const title = "Account verification";
    this.props.setAuthState({ email: this.props.email, verifyAttributes: true });
    const isDisabled = this.state.verificationCode.length !== 6;
    return (
      <Modal id={CHANGE_EMAIL_VERIFICATION_MODAL_ID} title={title} className="form-modal">
        <div className="content">
          <h4>Your account requires verification. Please check your email and enter the verification code.</h4>
          <div className="verification-code-form">
            <FormFieldContainer>
              <OtpInput
                value={verificationCode}
                onChange={(verificationCode: string) => this.setState({ verificationCode })}
                numInputs={6}
                shouldAutoFocus={true}
                className="otp-input"
                isDisabled={false}
              />
            </FormFieldContainer>
            <ResendCodeLink />
          </div>

          <div className="modal-footer">
            <div className="is-highlight">&nbsp;</div>
            <div className="is-highlight">
              <button
                type="button"
                className={`btn btn-radivision text-center ${isDisabled ? "disabled" : ""}`}
                onClick={this.onSubmit}
                disabled={false}
              >
                {inProgress ? <Spinner /> : "Continue"}
                <div className="hoverOverlay" />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapState = (state: AppState) => ({
  inProgress: state.cache.inProgress["verify-change-email"],
});
const mapDispatch = {
  verifyChangeEmail,
  setAuthState: Actions.setAuthState,
};
export const ChangeEmailVerificationModal = connect(mapState, mapDispatch)(Component);

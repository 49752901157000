import {
  SignInWithEmailAndPasswordPayload,
  ConfirmResetPasswordPayload,
  SignUpWithEmailAndPasswordPayload,
  VerifyAccountPayload,
  ResendVerificationCodePayload,
  UpdateAccountTypePayload,
} from "./types";

const UsernameExistsException = {
  code: "UsernameExistsException",
  message: "An account with the given email already exists.",
  name: "UsernameExistsException",
};

export const signInWithEmailAndPassword = (payload: SignInWithEmailAndPasswordPayload): Promise<any> => {
  const { email, password } = payload;
  return import("../../authentication/cognito-client").then((module) => {
    return module.CognitoClient.loginWithEmailAndPassword(email, password).then((res) => {
      location.reload();
      return Promise.resolve();
    });
  });
};

export const updateAccountType = (payload: UpdateAccountTypePayload): Promise<any> => {
  const { accountType } = payload;
  return Promise.resolve(accountType);
};

export const signUpWithEmailAndPassword = (payload: SignUpWithEmailAndPasswordPayload): Promise<any> => {
  // return Promise.resolve();
  let promise: Promise<any> = import("../../authentication/cognito-client");
  const { email, password, name } = payload;
  promise = promise.then((module) => {
    return module.CognitoClient.createAccountUsingEmailAndPassword(email, password, name).catch((err) => {
      const code = err.code;
      switch (code) {
        case "UsernameExistsException": {
          return module.CognitoClient.loginWithEmailAndPassword(email, "****")
            .then((results) => {
              // reject with UsernameExistsException
              return Promise.reject(err);
            })
            .catch((err_) => {
              const code = err_.code;
              if (code === "UserNotConfirmedException") {
                return Promise.reject(err_);
              }
              return Promise.reject(err);
            });
          break;
        }
      }
    });
  });
  return promise;
};

export const verifyAccount = (payload: VerifyAccountPayload): Promise<any> => {
  const { email, verificationCode } = payload;
  // return Promise.resolve();
  return import("../../authentication/cognito-client").then((module) => {
    return module.CognitoClient.verifyAccountCreationFromVerificationCode(email, verificationCode);
  });
};

export const verifyAttributes = (payload: VerifyAccountPayload): Promise<any> => {
  const { email, verificationCode } = payload;
  // return Promise.resolve();
  return import("../../authentication/cognito-client").then((module) => {
    return module.CognitoClient.verifyUserAttributes("email", verificationCode);
  });
};

export const resetPassword = (email: string): Promise<any> => {
  return import("../../authentication/cognito-client").then((module) => {
    return module.CognitoClient.forgotAccountPassword(email);
  });
};

export const confirmResetPassword = (payload: ConfirmResetPasswordPayload): Promise<any> => {
  const { email, verificationCode, password } = payload;
  return import("../../authentication/cognito-client").then((module) => {
    return module.CognitoClient.confirmForgotPassword(email, verificationCode, password);
  });
};

export const resendAccountVerificationCode = (payload: ResendVerificationCodePayload): Promise<any> => {
  const { email } = payload;
  return import("../../authentication/cognito-client").then((module) => {
    return module.CognitoClient.resendAccountConfirmationCode(email);
  });
};

export const resendUserAttributesAccountVerificationCode = (): Promise<any> => {
  return import("../../authentication/cognito-client").then((module) => {
    return module.CognitoClient.getUserAttributeVerificationCode("email");
  });
};

export const checkAccountExists = (email: string): Promise<any> => {
  return import("../../authentication/cognito-client").then((module) => {
    return module.CognitoClient.loginWithEmailAndPassword(email, "****")
      .then(() => {
        return Promise.reject(UsernameExistsException);
      })
      .catch((error) => {
        if (error.code === "UserNotFoundException") {
          return Promise.resolve();
        }
        if (error.code === "UserNotConfirmedException") {
          return Promise.reject(error);
        }
        return Promise.reject(UsernameExistsException);
      });
  });
};

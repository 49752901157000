/**
 * @author Ahmed Serag
 * @date   2018-09-24
 * @description Declaration of the type of support issue.
 * @filename support-issue-type.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";

/**
 * The __typeID of the Support issue GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_SUPPORT_ISSUE_TYPE: string = "SupportIssueType";

/**
 * An enumeration of the kinds of support issues.
 */
export enum SupportIssueKind {
  /**
   * The API.
   */
  API = "API",

  /**
   * Other type.
   */
  OTHER = "OTHER",

  /**
   * The behavior of the website.
   */
  WEBSITE_BEHAVIOR = "WEBSITE_BEHAVIOR",

  /**
   * The content in the website.
   */
  WEBSITE_CONTENT = "WEBSITE_CONTENT",

}

/**
 * the type of support issue.
 *
 * @extends {Node}
 * @template D
 * @interface
 */
export interface SupportIssueType<D extends Date | string = string> extends Node<D> {
    /**
     * A description of the top level media type.
     *
     * @type {string}
     */
    description?: string;
  
    /**
     * The kind of the top level media type.
     *
     * @type {SupportIssueKind}
     */
    kind?: SupportIssueKind;
  
    /**
     * A label of the top level media type.
     *
     * @type {string}
     */
    label?: string;
  }
  
/**
 * @author Ahmed Serag
 * @date   2018-03-28 02:49:26
 * @description Declaration of the SocialMediaProfile interface.
 * @filename social-media-profile.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Node } from "./node";
import { SocialMediaPlatform } from "./social-media-platform";

/**
 * The __typeID of the Social Media Profile type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_SOCIAL_MEDIA_PROFILE: string = "SocialMediaProfile";

/**
 *  A link to a profile on a social media platform
 *
 * @extends {Node}
 * @interface
 * @template D
 */
export interface SocialMediaProfile<D extends Date | string = string>
  extends Node<D> {
  /**
   * The URL of the social media profile
   *
   * @type {string}
   */
  link?: string;

  /**
   * The social media profile
   *
   * @type {SocialMediaPlatform}
   */
  platform?: SocialMediaPlatform;
}

/**
 * @author Ahmed serag
 * @date   2018-09-24
 * @description Declaration of the MultipleChoiceQuestion interface.
 * @filename multiple-choice-question.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { MultipleChoiceQuestionChoice } from "./multiple-choice-question-choice";
import { Connection } from "./connection";
import { Preview } from "./preview";
import { QuestionResponse } from "./questionnaire";
import { MultipleChoiceQuestionResponse } from "./multiple-choice-question-response";

/**
 * The __typeID of the Multiple Choice Question GraphQL type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_MULTIPLE_CHOICE_QUESTION: string = "MultipleChoiceQuestion";


/**
 * A multiple choice question.
 *
 * @export
 * @interface MultipleChoiceQuestion
 */
export interface MultipleChoiceQuestion {

  /**
   * The collection of choices in the multiple-choice question.
   *
   * @type {MultipleChoiceQuestionChoice}
   * @memberof MultipleChoiceQuestion
   */
  choices?: MultipleChoiceQuestionChoice[];
   
  /**
   * The ID of the question.
   *
   * @type {string}
   * @memberof MultipleChoiceQuestion
   */
  id?: string;
  
  /**
   * The maximum number of choices in the response. If not specified this is assumed to be 1.
   *
   * @type {number}
   * @memberof MultipleChoiceQuestion
   */
  maximumNumberOfChoices?: number;

  /**
   * The minimum number of choices in the response. If not specified this is assumed to be 1.
   *
   * @type {number}
   * @memberof MultipleChoiceQuestion
   */
  minimumNumberOfChoices?: number;

  /**
   * A collection of media assets used as previews for the question.
   *
   * @type {Connection<string, Preview>}
   * @memberof MultipleChoiceQuestion
   */
  previews?: Connection<string, Preview>;

  /**
   * The response to the question by the person or entity if returned within the context of a person or entity.
   *
   * @type {MultipleChoiceQuestionResponse}
   * @memberof MultipleChoiceQuestion
   */
  response?: MultipleChoiceQuestionResponse;

  /**
   * A collection of responses to the question.
   *
   * @type {Connection<string,QuestionResponse>}
   * @memberof MultipleChoiceQuestion
   */
  responses?: Connection<string,QuestionResponse>;

  /**
   * The sub-title of the question.
   *
   * @type {string}
   * @memberof MultipleChoiceQuestion
   */
  subTitle?: string;

  /**
   * The title of the question.
   *
   * @type {string}
   * @memberof MultipleChoiceQuestion
   */
  title?: string;

  /**
   * The typename of the node.
   *
   * @type {string}
   */
  __typename?: string;
}
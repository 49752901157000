/**
 * @author: Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-03-29 01:56:09
 * @description Declaratin of the QuestionnaireQuestion interfaces.
 * @filename questionnaire-questions.ts
 */
import { Questionnaire } from "./questionnaire";
import { QuestionnaireQuestion } from "./questionnaire-question";

/**
 * The __typeID of the Questionnaire Question Response type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_QUESTIONNAIRE_QUESTION_RESPONSE: string =
  "QuestionnaireQuestionResponse";

/**
 * The response to a questionnaire question.
 *
 * @export
 * @interface QuestionnaireQuestionResponse
 */
export interface QuestionnaireQuestionResponse<
  D extends Date | string = string
> {
  /**
   * The date and time when the response was created.
   *
   * @template D
   * @type {D}
   */
  created?: D;

  /**
   * The ID of the questionnaire question response.
   *
   * @type {string}
   */
  id?: string;

  /**
   * A flag which is true if the question is marked as deleted.
   *
   * @type {boolean}
   */
  isDeleted?: boolean;

  /**
   * The date and time when the question was last modified.
   *
   * @template D
   * @type {D}
   */
  lastModified?: D;

  /**
   * The question for which this is a response.
   *
   * @type {QuestionnaireQuestion}
   */
  question?: QuestionnaireQuestion;

  /**
   * The questionnaire in which this is a response.
   *
   * @type {QuestionnaireQuestion}
   */
  questionnaire?: Questionnaire<D>;

  /**
   * The content of the response to the question.
   *
   * @type {string}
   */
  response?: string;

  /**
   * The typename of the node.
   *
   * @type {string}
   */
  __typename?: string;
}

import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { Person, PreviewKind, TopLevelMediaKind, GRAPHQL_TYPE_ENTITY, GRAPHQL_TYPE_EVENT } from "@radivision/graphql";
import { ImageHelper } from "../../../utilities/image-helper";
import { RSVPButton } from "../rsvp-button";

interface ComponentProps {
  event: any;
}

interface MapDispatchProps {}
interface MapStateProps {
  user: (Person & { accountType: string | null }) | null;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const EventBannerComponent = (props: Props) => {
  const { user, event } = props;

  const rsvpEventsIds = user?.requests?.map((request) =>
    request.__typename === "RSVPRequest" && request.item?.id ? request.item.id : null,
  );
  const isAttending = event && rsvpEventsIds?.includes(event?.id);

  const backgroundUrl =
    event &&
    ImageHelper.fetchOptimizedImageUrl({
      imageType: "PREVIEW",
      preview: {
        content: event?.previews,
        previewKind: PreviewKind.CAROUSEL,
        topLevelMedia: TopLevelMediaKind.IMAGE,
        isDefaultPreview: false,
      },
      desiredDimensions: {
        containerWidthRatio: 3 / 12,
        numberOfItems: 1,
      },
      revision: event?.revision,
    }).requestedResolutionUrl;
  const msg = !user ? (
    <div>If you are an accredited investor and you want to watch the event live, please sign up to RSVP.</div>
  ) : isAttending ? (
    <div>
      Thank you for your interest in "{event.name}" We will notify you when the event is available for your viewing.
    </div>
  ) : (
    <div>To watch the event, please RSVP to receive notification when the event is available for your viewing</div>
  );

  return (
    <div className="event-banner">
      <div className="event-poster" style={{ backgroundImage: `url('${backgroundUrl}')` }}></div>
      <div className="shade" />
      <div className="event-banner-container">
        <h1>{msg}</h1>
        {isAttending ? null : <RSVPButton event={event} />}
      </div>
    </div>
  );
};

const mapState = (state: AppState) => ({
  user: state.account.user,
});

const mapDispatch = {};

export const EventBanner = connect(mapState, mapDispatch)(EventBannerComponent);

import { Reducer } from "redux";
import { ProfileState, ProfileAction } from "./types";
import {
  SET_PROFILE_STATE,
  EDIT_PROFILE_PAGE_INFO,
  SET_PROFILE_SOCIAL_LINK,
  SET_PROFILE_OPPORTUNITY,
  EDIT_PROFILE,
} from "./constants";

export const initialState: ProfileState = {
  id: null,
  screen: EDIT_PROFILE_PAGE_INFO,
  type: null,
  revision: null,
  name: null,
  fullName: null,
  tagLine: null,
  description: null,
  responsibility: null,
  founded: null,
  location: null,
  websiteUrl: null,
  noOfEmployees: null,
  industry: null,
  activities: [],
  profileImage: null,
  profileImageUrl: {},
  profileBackground: null,
  profileBackgroundUrl: {},
  backgroundAssetId: null,
  existingUrls: {},
  opportunities: {},
  isVerified: false,
  socialMediaProfiles: null,
  accountType: null,
};

export const ProfileReducer: Reducer<ProfileState, ProfileAction> = (
  state: ProfileState = initialState,
  action: ProfileAction,
) => {
  switch (action.type) {
    case SET_PROFILE_STATE: {
      return { ...state, ...action.payload };
    }
    case SET_PROFILE_SOCIAL_LINK: {
      const socialMediaProfiles = { ...state.socialMediaProfiles, ...action.payload };
      Object.keys(action.payload).forEach((k) => {
        if (action.payload[k] === undefined) {
          delete socialMediaProfiles[k];
        }
      });
      return { ...state, socialMediaProfiles };
    }
    case SET_PROFILE_OPPORTUNITY: {
      const { name, key, value } = action.payload;
      const opportunities = { ...state.opportunities, [name]: { ...state.opportunities[name], [key]: value } };
      return { ...state, opportunities };
    }
    case EDIT_PROFILE: {
      const { screen } = action.payload;
      if (!screen) return state;
      return { ...state, screen };
    }
    default:
      return state;
  }
};

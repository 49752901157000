import { fetchQuery } from "react-relay";
import { ENVIRONMENT } from "../../relay/relay-environment";
import { LIST_QUERY } from "../../relay/queries/list-query";
import { FixedList, UpdatePersonInput, Person } from "@radivision/graphql";
import { FixedList as FixedListMutations } from "../../utilities/mutation-operation/fixed-list";
import { commitMutation } from "../../utilities/relay";
import { getClientMutationId } from "../../utilities/general";
import isEmpty from "lodash.isempty";
import { USER_GRAPHQL_QUERY } from "../../relay/queries/user-graphql-query";
import { USER_REVISION_QUERY } from "../../relay/queries/user-revision-query";
import { DeactivateAccountRequestPayload, ChangeEmailRequestPayload } from "./types";
import { CREATE_DEACTIVATE_ACCOUNT_REQUEST_MUTATION } from "../../relay/mutations/create-deactivate-account-request";
import { CREATE_CHANGE_EMAIL_REQUEST_MUTATION } from "../../relay/mutations/create-change-email-request";

export const fetchSavedItems = (listId: string): any => {
  return fetchQuery(ENVIRONMENT, LIST_QUERY, { listId });
};

export const updateSavedItems = (resourceId: string, savedItems: FixedList) => {
  if (isEmpty(savedItems)) {
    return Promise.reject("updateSavedItems: cannot update, savedItems is null.");
  }

  const itemExists = savedItems.items.edges.find((item) => item.id === resourceId);

  if (itemExists) {
    return Promise.reject("addSavedItems: Item is already in saved list.");
  }

  const items = savedItems.items.edges
    .map((item) => ({ itemId: item.listItem.item.id }))
    .concat({ itemId: resourceId });

  const fixedListInput = {
    items,
    id: savedItems.id,
    name: savedItems.name,
    revision: savedItems.revision,
  };

  return FixedListMutations.updateFixedList(fixedListInput);
};

export const addSavedItems = (resourceId: string, user: Person) => {
  const items = [{ itemId: resourceId }];

  const fixedListInput = {
    items,
    name: `${user.fullName} saved list`,
    title: `${user.fullName} saved list`,
  };

  return FixedListMutations.addFixedList(fixedListInput);
};

export const updatePerson = async (user: Person, savedItems: FixedList) => {
  const personInput: UpdatePersonInput = {
    clientMutationId: getClientMutationId(),
    id: user.id,
    revision: user.revision,
    myList: savedItems.id,
  };

  const MUTATIONS = await import("../../relay/mutations-person");
  return commitMutation(ENVIRONMENT, {
    mutation: MUTATIONS.UPDATE_PERSON_GRAPHQL_MUTATION,
    variables: {
      input: personInput,
    },
  });
};

export const removeFromUserSavedItemList = async (resourceId: string, savedItems: FixedList) => {
  const fixedListInput = {
    clientMutationId: getClientMutationId(),
    id: savedItems.id,
    items: [resourceId],
    revision: savedItems.revision,
  };
  const mutations = await import("../../relay/mutations-fixedlist");
  return commitMutation(ENVIRONMENT, {
    mutation: mutations.REMOVE_FROM_FIXED_LIST_GRAPHQL_MUTATION,
    variables: {
      input: fixedListInput,
    },
  });
};

export const followResource = async (resourceId: string, user: Person) => {
  const personInput = {
    id: user.id,
    clientMutationId: getClientMutationId(),
    follows: [resourceId],
    revision: user.revision,
  };

  const MUTATIONS = await import("../../relay/mutations-person");
  return commitMutation(ENVIRONMENT, {
    mutation: MUTATIONS.ADD_TO_PERSON_GRAPHQL_MUTATION,
    variables: {
      input: personInput,
    },
  });
};

export const unfollowResource = async (resourceId: string, user: Person) => {
  const personInput = {
    id: user.id,
    clientMutationId: getClientMutationId(),
    follows: [resourceId],
    revision: user.revision,
  };
  const MUTATIONS = await import("../../relay/mutations-person");
  return commitMutation(ENVIRONMENT, {
    mutation: MUTATIONS.REMOVE_FROM_PERSON_GRAPHQL_MUTATION,
    variables: {
      input: personInput,
    },
  });
};

export const deactivateAccountRequest = (payload: DeactivateAccountRequestPayload) => {
  const description = payload.description;
  const reasons = payload.reasons;
  const deactivateRequestInput = {
    clientMutationId: getClientMutationId(),
    description,
    reasons,
  };
  const config = {
    mutation: CREATE_DEACTIVATE_ACCOUNT_REQUEST_MUTATION,
    variables: {
      input: deactivateRequestInput,
    },
  };

  let promise = commitMutation(ENVIRONMENT, config);

  promise = promise.then((mutationResults: any) => {
    return mutationResults.DeactivateRequest.hasErrors === false
      ? Promise.resolve(mutationResults.DeactivateRequest.userRequest)
      : Promise.reject(mutationResults.DeactivateRequest.errors);
  });

  return promise;
};

export const changeEmailRequest = (payload: ChangeEmailRequestPayload) => {
  const email = payload.email;
  const changeEmailRequestInput = {
    clientMutationId: getClientMutationId(),
    email,
  };
  const config = {
    mutation: CREATE_CHANGE_EMAIL_REQUEST_MUTATION,
    variables: {
      input: changeEmailRequestInput,
    },
  };

  let promise = commitMutation(ENVIRONMENT, config);

  promise = promise.then((mutationResults: any) => {
    return mutationResults?.ChangeEmailRequest?.hasErrors === false
      ? Promise.resolve(mutationResults?.ChangeEmailRequest?.userRequest)
      : Promise.reject(mutationResults?.ChangeEmailRequest?.errors);
  });

  return promise;
};
export const fetchCurrentUser = () => {
  const variables = {
    userIdentityId: null,
    queryParameters: [],
    alias: "home",
  };
  return fetchQuery(ENVIRONMENT, USER_GRAPHQL_QUERY, variables, { force: true });
};

export const fetchCurrentUserRevision = (id: string) => {
  const variables = { id };
  return fetchQuery(ENVIRONMENT, USER_REVISION_QUERY, variables, { force: true });
};

/**
 * @author: Ahmed Samer
 * @date:   2018-06-09
 * @Description Declaration of a class to execute fetch commands to an aws4 protected endpoint.
 * @filename: api-client.ts
 * @copyright: Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { Variables } from "relay-runtime";

/**
 * GraphQL query functions.
 */
export class ApiClient {
  /**
   * Returns a promise to execute a given GraphQL query with a given set of variables.
   *
   * @param {Object<string, *>} query The GraphQL query.
   *
   * @returns {Promise<string>} The JSON response from the GraphQL query.
   * @static
   */
  static fetch(query: { query: string; [index: string]: any }): Promise<any> {
    const VARIABLES: { [index: string]: any } = {};

    Object.keys(query).forEach((key: string) => {
      if (key?.toLowerCase() !== "query") {
        VARIABLES[key] = query[key];
      }
    });
    return ApiClient.post(query.query, VARIABLES);
  }

  /**
   * Returns a promise to execute a given GraphQL query with a given set of variables.
   *
   * @param {string} query The GraphQL query.
   *
   * @param {Variables} variables A collection of variables as serialized JSON.
   *
   * @static
   * @memberof ApiClient
   */
  static post(query: string, variables: Variables): Promise<any> {
    // .. attempt to fetch user credentials
    return import("./cognito-client").then((module) => {
      return module.CognitoClient.getCognitoIdentityCredentials().then((credentials: any) => {
        if (!credentials) {
          return Promise.reject(new Error("Invalid credentials"));
        }
        let requestResponse: Response;
        let requestOptions;

        const BODY: string = JSON.stringify({ query, variables });
        const REQUEST: RequestInit = {
          body: BODY,

          method: "POST",
          mode: "cors",
        };

        // If there are query parameters or headers that need to be
        // sent with the request you can add them here
        const url: URL = new URL(process.env.AWS_API_GATEWAY_ENDPOINT);

        requestOptions = {
          body: REQUEST.body,
          headers: REQUEST.headers,
          host: url.hostname,
          method: REQUEST.method,
          path: `${url.pathname}`,
          service: "execute-api",
          region: process.env.AWS_REGION,
        };

        const CREDENTIALS = {
          accessKeyId: credentials && credentials.AccessKeyId ? credentials.AccessKeyId : process.env.BACKUP_USER_KEY,
          secretAccessKey:
            credentials && credentials.SecretKey ? credentials.SecretKey : process.env.BACKUP_USER_SECRET,
          sessionToken: credentials && credentials.SessionToken ? credentials.SessionToken : undefined,
        };

        module.CognitoClient.signRequest(requestOptions, CREDENTIALS);

        return fetch(`${url.href}`, REQUEST)
          .then((response: Response) => {
            requestResponse = response;
            return response.json();
          })
          .then((responseBody) => {
            if (requestResponse.ok) {
              return Promise.resolve(responseBody);
            }
            return Promise.reject(responseBody);
          });
      });
    });
  }

  /**
   *
   *
   * @static
   * @param {*} req
   * @memberof ApiClient
   */
  public static signNetworkRequest(req: any): Promise<any> {
    return import("./cognito-client").then((module) => {
      return module.CognitoClient.getCognitoIdentityCredentials()
        .then((credentials: any) => {
          if (!credentials) {
            return Promise.reject(new Error("Invalid credentials"));
          }
          const url: URL = new URL(process.env.AWS_API_GATEWAY_ENDPOINT);
          let requestOptions = {
            body: req.getBody(),
            headers: {
              "Content-Type": "application/json",
            },
            host: url.hostname,
            method: "POST",
            path: `${url.pathname}`,
            service: "execute-api",
            region: process.env.AWS_REGION,
          };

          const CREDENTIALS = {
            accessKeyId: credentials && credentials.AccessKeyId ? credentials.AccessKeyId : process.env.BACKUP_USER_KEY,
            secretAccessKey:
              credentials && credentials.SecretKey ? credentials.SecretKey : process.env.BACKUP_USER_SECRET,
            sessionToken: credentials && credentials.SessionToken ? credentials.SessionToken : undefined,
          };

          module.CognitoClient.signRequest(requestOptions, CREDENTIALS);

          return Promise.resolve(requestOptions);
        })
        .catch((err) => {
          console.error("SignRequest: Error ", { err });
          return Promise.reject(err);
        });
    });
  }

  /**
   *
   *
   * @static
   * @param {string} url
   * @memberof ApiClient
   */
  static get(url: string) {
    return import("./cognito-client").then((module) => {
      return module.CognitoClient.getCognitoIdentityCredentials().then((credentials: any) => {
        let requestResponse: Response;
        const REQUEST: RequestInit = {
          method: "GET",
          mode: "cors",
        };
        let requestOptions;
        // If there are query parameters or headers that need to be
        // sent with the request you can add them here
        const URL_COMPONENT: URL = new URL(url);

        requestOptions = {
          body: REQUEST.body,
          headers: REQUEST.headers,
          host: URL_COMPONENT.hostname,
          method: REQUEST.method,
          path: URL_COMPONENT.pathname,
          service: "execute-api",
          region: process.env.AWS_REGION,
        };
        module.CognitoClient.signRequest(requestOptions, {
          accessKeyId: credentials.AccessKeyId,
          secretAccessKey: credentials.SecretKey,
          sessionToken: credentials.SessionToken,
        });
        return fetch(url, REQUEST)
          .then((response: Response) => {
            requestResponse = response;
            return response.json();
          })
          .then((responseBody) => {
            if (requestResponse.ok) {
              return Promise.resolve(responseBody);
            }
            return Promise.reject(responseBody);
          });
      });
    });
  }

  /**
   *
   *
   * @static
   * @param {*} body
   * @returns {Promise<any>}
   * @memberof ApiClient
   */
  static mqttPublish(body: any): Promise<any> {
    // .. attempt to fetch user credentials
    return import("./cognito-client").then((module) => {
      return module.CognitoClient.getCognitoIdentityCredentials().then((credentials: any) => {
        if (!credentials) {
          return Promise.reject(new Error("Invalid credentials"));
        }
        let requestResponse: Response;
        let requestOptions;

        const REQUEST: RequestInit = {
          body,
          headers: {
            "Content-Type": "application/json",
          },
          method: "PUT",
        };

        // If there are query parameters or headers that need to be
        // sent with the request you can add them here
        const url: URL = new URL(process.env.LIVE_COMMENTS_ENDPOINT);

        requestOptions = {
          body: REQUEST.body,
          headers: REQUEST.headers,
          host: url.hostname,
          method: REQUEST.method,
          path: `${url.pathname}`,
          service: "execute-api",
          region: process.env.AWS_REGION,
        };

        module.CognitoClient.signRequest(requestOptions, {
          accessKeyId: credentials.AccessKeyId,
          secretAccessKey: credentials.SecretKey,
          sessionToken: credentials.SessionToken,
        });

        return fetch(`${url}`, REQUEST)
          .then((response: Response) => {
            requestResponse = response;

            return response.body ? response.json() : Promise.resolve(response);
          })
          .then((responseBody) => {
            if (requestResponse.ok) {
              return Promise.resolve(responseBody);
            }
            return Promise.reject(responseBody);
          });
      });
    });
  }
}

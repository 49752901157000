/**
 * @author Ahmed Serag
 * @date   2019-04-08
 * @description Declaration of the HtmlDocumentSlot interface.
 * @filename html-document-panel.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */
import { ComponentPropertyValue } from "./component-property-value";
import { Component } from "./component";

/**
 * The __typeID of the HTML Document Slot type.
 *
 * @type {string}
 */
export const GRAPHQL_TYPE_HTML_DOCUMENT_PANEL: string = "HtmlDocumentPanel";

/**
 * An HTML document slot.
 *
 * @extends {Node}
 * @interface
 * @template D
 */
export interface HtmlDocumentPanel {

  /**
   * The Component which manages the HTML document slot
   *
   * @type {Component}
   */
  component?: Component;

  /**
   * The ID of the HTML document panel.
   *
   * @type {string}
   * @memberof HtmlDocumentPanel
   */
  id?: string;

  /**
   * A label for the HTML document slot. Metadata
   *
   * @type {string}
   */
  label?: string;

  /**
   * The property values mapped to their names, usually the name of the props attribute.
   *
   * @type {ComponentPropertyValue}
   * @memberof HtmlDocumentPanel
   */
  properties?: ComponentPropertyValue[];

  /**
   * 
   *
   * @type {string}
   * @memberof HtmlDocumentPanel
   */
  __typename?: string;
}

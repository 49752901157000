import { graphql, GraphQLTaggedNode } from "react-relay";

export const CREATE_RADIVISION_NOTIFICATION_MUTATION: GraphQLTaggedNode = graphql`
  mutation createRadivisionNotificationMutation($input: CreateNotificationInput!) {
    createRadivisionNotification(input: $input) {
      errors {
        id
        location
      }
      hasErrors
      notification {
        id
        created
        status
        title
      }
    }
  }
`;

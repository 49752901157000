import { Reducer } from "redux";
import produce from "immer";
import { GET_NOTIFICATION_SUBSCRIPTION_STATUS, CREATING_NOTIFICATION } from "./constants";
import { NotificationAction, NotificationState } from "./types";
import { storageFactory } from "../../utilities/local-storage-factory";
const localStore = storageFactory(() => localStorage);

const initialState: NotificationState = {
  status: {},
  isLoading: false,
};

export const NotificationReducer: Reducer<NotificationState, NotificationAction> = (
  state: NotificationState = initialState,
  action: NotificationAction,
) => {
  switch (action.type) {
    case GET_NOTIFICATION_SUBSCRIPTION_STATUS: {
      const { notificationKind, entityId } = action.payload;
      const status = localStore.getItem(`notification-${notificationKind}-${entityId}`);
      return produce(state, (draft) => {
        draft.status = { ...draft.status, [`${notificationKind}-${entityId}`]: status };
      });
    }
    case CREATING_NOTIFICATION: {
      const status = action.payload.status;
      return produce(state, (draft) => {
        draft.isLoading = status;
      });
    }
    default:
      return state;
  }
};

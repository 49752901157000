/**
 * @author Muhammad Omran
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2019-02-19 15:13
 * @description Implementation of Radivision Carousel
 * @filename rdv-carousel.tsx
 */

import { PreviewKind } from "@radivision/graphql/lib/ts/graphql/preview-type";
import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import { Person as GraphQlPerson, GRAPHQL_TYPE_PITCH_VIDEO_STORY } from "@radivision/graphql";
import $ from "jquery";
import React, { Suspense } from "react";
import { connect } from "react-redux";
import { CarouselItem } from "../../../component-configuration/carousel-item";
import { OptimizedImageUrls } from "../../../component-configuration/optimized-image";
import { Analytics } from "../../../utilities/analytics";
import {
  facebookPixelTraceEvent,
  snowplowAnalytics,
  gaEvent,
  getCarouselItem,
  getCleanHtmlId,
} from "../../../utilities/general";
import { ImageHelper } from "../../../utilities/image-helper";
import { Router } from "../../../utilities/router";
import { RdvButton } from "../../page-framework/rdv-button";
import { ArraysHelper } from "../../../utilities/arrays";
import { PageInfo } from "@radivision/graphql/lib/ts/graphql/page-info";
import { ListType } from "@radivision/graphql/lib/ts/graphql/list";
import { Show } from "../../../component-configuration/show";
import { RdvCarouselContextInterface, RdvCarouselContext } from "./context";
// import { AccountControl } from "../../modals/register-and-login/account-control";
// import { SignInModal } from "../../modals/register-and-login/sign-in-modal";
import { getNewUniqueId } from "@radivision/common";
import { CarouselHeader } from "./carousel-header";
import { ShowDetails } from "./show-details";
import { SlideItem } from "./slide-item";
import { NextButton } from "./next-button";
import { PrevButton } from "./prev-button";
import * as Selectors from "./selectors";
import * as Actions from "../../../redux/actions";
import { OpenDetailsPanelPayload, OpenDetailsPanelAction } from "../../../redux/details-panel/types";
import { AppState } from "../../../redux";
import { SetPlayingFullScreenAction, SetPlayingFullScreenPayload } from "../../../redux/full-screen-video/types";
import { FixedList } from "@radivision/graphql";
import { CONTENT_REQUIRING_PAYWALL, FeaturedCarouselId } from "../../../constants/content-ids";
import { PaywallOverlay } from "../../details-panel/paywall-overlay";
import { ScrollingCarousel } from "../scrolling-carousel";
import { useWindowSize } from "../../../utilities/use-window-size";
import { MobileCarousel } from "../mixed-carousel/mobile-carousel";
import { LoadMore } from "./load-more";
import { extractYoutubeId } from "../../../utilities/extract-youtube-id";

const VideoPanel = React.lazy(() => import("../../page-framework/video-panel"));
const isEqual = require("lodash.isequal");

// const COMPAIN_LOGO = require("../../../static/SHE_191001.png");
const LOAD = require("../../../../static/placeholder.jpg");
const LOGIN_MODAL_ID: string = `login-${getNewUniqueId()}`;

export interface RdvCarouselProps {
  /**
   * flag to decide to display the list description or not
   *
   * @type {boolean}
   */
  displayListDescriptionFlag?: boolean;

  /**
   * the display mode of the carousel
   *
   * @type {"Carousel" | "GridView"}
   * @memberof RdvCarouselProps
   */
  displayMode?: "Carousel" | "GridView";

  /**
   * flag upon which to decide to display the subtitle of the list or not
   *
   * @type {boolean}
   */
  displaySubtitleFlag?: boolean;

  /**
   * a flag which is true if the carousel has details
   *
   * @type {boolean}
   */
  hasDetails?: boolean;

  /**
   * flag to hide item description if true
   *
   * @type {boolean}
   */
  hideItemDescription?: boolean;

  /**
   * flag to hide carousel item title
   *
   * @type {boolean}
   * @memberof RdvCarouselProps
   */
  hideItemTitle?: boolean;

  /**
   *
   *
   * @type {boolean}
   * @memberof RdvCarouselProps
   */
  hideItemSubtitle?: boolean;

  /**
   * to hide carousel title
   *
   * @type {boolean}
   * @memberof RdvCarouselProps
   */
  hideTitle?: boolean;

  /**
   * the ratio of the requested carousel
   *    # PreviewKind.SQUARE     :     for square carousel
   *    # PreviewKind.CAROUSEL   :     for video carousel
   *    # PreviewKind.PORTRAIT   :     for portrait carousel
   *
   * @type {PreviewKind}
   */
  itemPreviewType?: PreviewKind;

  /**
   * if carousel doesn't have a title at the top and have a detailed title/logo, description & awards on the side
   *  "PRE-ITEM" >> before the carousel (left side)
   *  "POST-ITEM" >> after the carousel (right side)
   *
   * @type {"PRE-ITEM" | "POST-ITEM"}
   * @memberof RdvCarouselProps
   */
  listDetailsView?: "PRE-ITEM" | "POST-ITEM";

  /**
   * details overlay more like this section carousel list id
   *
   * @type {string}
   * @memberof RdvCarouselProps
   */
  moreLikeThis?: string;

  /**
   * the text of more button under the carousel
   *
   * @type {string}
   */
  navButtonText?: string;

  /**
   * the Url of more button under the carousel
   *
   * @type {string}
   */
  navButtonUrl?: string;

  /**
   * The total number of items in the carousel.
   *
   * @type {number}
   */
  numberOfItems?: number;

  /**
   * pagination paramter supplied for list query
   *
   * @type {PageInfo}
   * @memberof RdvCarouselProps
   */
  pageInfo: PageInfo;

  /**
   * the Show to display
   *
   * @type {Show}
   * @memberof RdvCarouselProps
   */
  show: Show;
  list: FixedList | { lists: FixedList[] };

  /**
   *
   *
   * @type {OptimizedImageUrls}
   * @memberof RdvCarouselProps
   */
  campaignPreview?: OptimizedImageUrls;
  descriptionHyperLinkText?: string;
  descriptionHyperLink?: string;
  json: HomeGenericJson;
}

/**
 * The state of the wide carousel component.
 *
 * @interface
 */
export interface RdvCarouselState {
  /**
   * the current displayed carousel items
   *
   * @type {CarouselItem[]}
   */
  currentCarouselItems: CarouselItem[];

  /**
   * The item index of which the details panel is showing.
   *
   * @type {number}
   */
  currentItemDetails: number;

  /**
   * indicates the index of current slide first APPEARED item
   *
   * @type {number}
   * @memberof RdvCarouselState
   */
  currentSlideFirstItemIndex: number;

  /**
   * current in view Details panel show
   *
   * @type {Show}
   * @memberof RdvCarouselState
   */
  detailsOverlayShow: Show;

  /**
   * to tell if carousel is proparly first rendered to the screen
   *
   * @type {boolean}
   * @memberof RdvCarouselState
   */
  firstRender: boolean;

  /**
   * to play the youtube video on item MouseEnter > number represents the item index
   *
   * @type {number}
   */
  hoverPlay: number;

  /**
   * Number of items to show in single frame, pick your favorite
   * this will only shown if window size allows to display that number
   *
   * @type {number}
   */
  itemsToShow: number;

  /**
   * The Show
   *
   * @type {Show}
   * @memberof RdvCarouselState
   */
  show: Show;

  /**
   * A flag which is true if the panel is displayed.
   *
   * @type {boolean}
   */
  showPanel: boolean;

  /**
   * this id of the Youtube Video upon which slide is clicked
   *
   * @type {string}
   */
  videoId: string;

  /**
   *
   *
   * @type {boolean}
   * @memberof RdvCarouselState
   */
  paginate: boolean;

  /**
   * onItem hover card, on press on Play button to play the item video on fullscreen
   *
   * @type {boolean}
   * @memberof  RdvCarouselState
   */
  playItemFullVideo: boolean;
  currentEpisodeIndex: number;

  /**
   *
   *
   * @type {boolean}
   * @memberof RdvCarouselState
   */
  loading: boolean;

  /**
   * save call to action loading state array of loading item ids
   *
   * @type {string[]}
   * @memberof RdvCarouselState
   */
  saveItemLoading: string[];
}

interface MapStateProps {
  user: GraphQlPerson;
  detailsPanelIsOpened: boolean;
}

interface MapDispatchProps {
  openDetailsPanel: (p: OpenDetailsPanelPayload) => OpenDetailsPanelAction;
  setPlayingFullScreen: (p: SetPlayingFullScreenPayload) => SetPlayingFullScreenAction;
}

export type Props = RdvCarouselProps & MapDispatchProps & MapStateProps;

/**
 *  A React component that renders the wide carousel.
 *
 * @export
 * @class Carousel
 * @extends {React.Component<RdvCarouselProps>}
 */
export class Carousel extends React.Component<Props, RdvCarouselState> {
  /**
   * the HTML Id of the carousel for DOM manipulation
   *
   * @type {string}
   */
  carouselID: string;

  // elements
  sliderFrame: JQuery<HTMLElement>;
  sliderMask: JQuery<HTMLElement>;
  sliderContainer: JQuery<HTMLElement>;
  prevBtn: JQuery<HTMLElement>;
  nextBtn: JQuery<HTMLElement>;

  youtubeHoverVideoOpt: any;

  /**
   * Constructor.
   *
   * @param {RdvCarouselProps} props The props of the component.
   */
  constructor(props: Props) {
    super(props);
    this.carouselID = `rdv-car-${this.props.show && this.props.show.id ? this.props.show.id : ""}-${getCleanHtmlId()}`;
    this.videoPanelRef = React.createRef();

    this.state = {
      show: this.props.show,
      currentCarouselItems: [],
      currentItemDetails: props.show && props.show.items && props.show.items.length > 0 ? 0 : null,
      currentSlideFirstItemIndex: 0,
      detailsOverlayShow: props.show,
      firstRender: true,
      hoverPlay: -1,
      itemsToShow: 0,
      showPanel: false,
      videoId: "",
      paginate: props.pageInfo && props.pageInfo.hasNextPage ? props.pageInfo.hasNextPage : false,
      loading: false,
      playItemFullVideo: false,
      currentEpisodeIndex: 0,
      saveItemLoading: [],
    };

    this.init = this.init.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
    this.playItemFullVideo = this.playItemFullVideo.bind(this);
    this.closeFullScreenVideo = this.closeFullScreenVideo.bind(this);
    this.nextOne = this.nextOne.bind(this);
    this.nextCB = this.nextCB.bind(this);
    this.prevCB = this.prevCB.bind(this);
    this.updateCarousel = this.updateCarousel.bind(this);
    this.openDetailsPanel = this.openDetailsPanel.bind(this);
    this.addRemoveLoadingItems = this.addRemoveLoadingItems.bind(this);
    this.onYouTubeVideoEnds = this.onYouTubeVideoEnds.bind(this);
    this.viewAllOnClick = this.viewAllOnClick.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.retrieveOriginalIndex = this.retrieveOriginalIndex.bind(this);
  }

  /**
   * A function triggered to handle images  that are not found
   *
   * @type {function()}
   */
  loadDefaultImage(targetImage: any, itemType: any) {
    targetImage.onerror = null;
    targetImage.src = ImageHelper.fetchOptimizedImageUrl({
      imageType: "DEFAULT",
      default: {
        previewType: PreviewKind.CAROUSEL,
        mediaType: TopLevelMediaKind.IMAGE,
        type: itemType,
      },
      desiredDimensions: { containerWidthRatio: 1, numberOfItems: 6 },
      isSquareImage: true,
      revision: undefined,
    }).requestedResolutionUrl;
  }

  detectMob() {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  /**
   * Invoked by the React framework after the component is mounted
   *
   * @type {function()}
   */

  // onScroll() {
  //   if (this.props.show && this.props.show.id && this.state.paginate) {
  //     const carouselContainer: HTMLElement = document
  //       .getElementById(this.props.show.id)
  //       .querySelector(".rdv-carousel-container");
  //     if (window.innerHeight + window.scrollY >= carouselContainer.offsetHeight - 250) {
  //       this.loadItems();
  //     }
  //   }
  // }

  componentWillUnmount() {
    //window.removeEventListener("scroll", this.onScroll.bind(this));
    window.removeEventListener("resize", this.init);
  }

  componentDidMount() {
    // detect browser device > if it's mobile add class to html document body
    this.init();
    window.addEventListener("resize", this.init);
    // add the draggable event listener to the carousel
    // tslint:disable-next-line: no-this-assignment
    const self = this;
    const isCarouselDisplayMode: boolean = !this.props.displayMode || this.props.displayMode === "Carousel";
    if (this.detectMob() && isCarouselDisplayMode) {
      this.sliderMask.on("touchstart", function(event) {
        event.stopImmediatePropagation();
        const xClick = event.originalEvent.touches[0].pageX;
        $(this).one("touchmove", (event) => {
          const xMove = event.originalEvent.touches[0].pageX;
          if (Math.floor(xClick - xMove) > 10) {
            self.nextBtn.trigger("click");
          } else if (Math.floor(xClick - xMove) < -10) {
            self.prevBtn.trigger("click");
          }
        });
        $(this).on("touchend", () => {
          $(this).off("touchmove");
        });
      });
    }

    // if (this.props.displayMode === "GridView") {
    // window.XddEventListener("scroll", this.onScroll.bind(this));
    // }
  }

  /**
   *
   *
   * @memberof RdvCarousel
   */
  componentDidUpdate(nextProps: Props) {
    if (!isEqual(nextProps.show, this.props.show)) {
      this.setState(
        {
          show: this.props.show,
          detailsOverlayShow: this.props.show,
          currentItemDetails: 0,
        },
        () => {
          this.init();
        },
      );
    }
    if (this.state.currentCarouselItems.length && this.state.firstRender) {
      this.setState({ firstRender: false });
    }
    if (!this.props.detailsPanelIsOpened && nextProps.detailsPanelIsOpened) {
      this.setState({ playItemFullVideo: false });
    }
  }

  /**
   *
   *
   * @memberof RdvCarousel
   */
  init() {
    // elements
    this.sliderFrame = $(`#${this.carouselID}`);
    this.sliderMask = $(`#${this.carouselID}-msk.rdv-carousel-mask`);
    this.sliderContainer = this.sliderFrame.find(".rdv-carousel-container");
    if (!this.props.displayMode || this.props.displayMode === "Carousel") {
      this.prevBtn = this.sliderFrame.find(".rdv-carousel-prev");
      this.nextBtn = this.sliderFrame.find(".rdv-carousel-next");
    }

    // remove any cloned items on re-init
    this.sliderFrame.find(".rdv-slide.slide-i").remove();

    // sizes
    const windowWidth: number = $("body").prop("clientWidth");
    let newShowCount = this.props.numberOfItems;
    if (windowWidth <= 480) {
      newShowCount = this.props.numberOfItems > 5 ? 3 : 2;
    } else if (windowWidth <= 550) {
      newShowCount = this.props.numberOfItems > 5 ? 4 : 3;
      // newShowCount = this.props.listDetailsView ? newShowCount - 1 : newShowCount;
    } else if (windowWidth <= 768) {
      newShowCount = this.props.numberOfItems > 5 ? 4 : 3;
      // newShowCount = this.props.listDetailsView ? newShowCount - 1 : newShowCount;
    } else if (windowWidth <= 1100) {
      newShowCount = this.props.numberOfItems > 5 ? 5 : 4;
      // newShowCount = this.props.listDetailsView ? newShowCount - 1 : newShowCount;
    } else if (windowWidth <= 1500) {
      newShowCount = this.props.numberOfItems > 5 ? 6 : 5;
      // newShowCount = this.props.listDetailsView ? newShowCount - 1 : newShowCount;
    } else {
      newShowCount = this.props.numberOfItems > 5 ? 7 : 6;
      // newShowCount = this.props.listDetailsView ? newShowCount - 2 : newShowCount;
    }
    if (windowWidth >= 768) {
      newShowCount =
        this.props.itemPreviewType === PreviewKind.CIRCLE || this.props.itemPreviewType === PreviewKind.SQUARE
          ? newShowCount + 1
          : newShowCount;
      if (this.props.displayMode === "GridView") {
        if (newShowCount > 6 && newShowCount < 8) {
          newShowCount = 6;
        }
      }
    }

    // set current displayed carousel items
    const isCarouselDisplayMode: boolean = !this.props.displayMode || this.props.displayMode === "Carousel";
    const currentCarouselItems: CarouselItem[] = isCarouselDisplayMode
      ? []
      : this.state.show && this.state.show.items && this.state.show.items.length
      ? this.state.show.items
      : [];
    let slideIndex: number = 0;
    while (
      isCarouselDisplayMode &&
      this.state.show &&
      this.state.show.items &&
      this.state.show.items.length &&
      slideIndex <= this.state.show.items.length &&
      currentCarouselItems.length <= newShowCount * 2 + 1
    ) {
      // if slide is filled with all show items and the carousel has only one slide >> break out and doen't recycle
      if (slideIndex === this.state.show.items.length && this.state.show.items.length <= newShowCount) {
        break;
      }

      // if slide id filled with all show items >> recycle from the begining untill filled with next slide items
      if (slideIndex === this.state.show.items.length) {
        slideIndex = 0;
      }
      currentCarouselItems.push(this.state.show.items[slideIndex]);
      slideIndex++;
    }

    this.setState(
      {
        currentCarouselItems,
        itemsToShow: newShowCount,
        paginate: false,
        // this.props.pageInfo && this.props.pageInfo.hasNextPage
        //   ? this.props.pageInfo.hasNextPage
        //   : this.state.paginate,
        loading: false,
      },
      () => {
        if (isCarouselDisplayMode) {
          this.sliderFrame
            .find(".rdv-btn")
            .height(this.sliderFrame.find(".rdv-slide:first-child .rdv-slide-img").height());
        }
      },
    );
  }

  /**
   * to retrieve original index of the array since carouselItemList is sliced every update
   *
   * @param currentItem the item from the new updated carousel array
   * @returns {number} which is the original index at carouselItemList
   */
  retrieveOriginalIndex(currentItem: CarouselItem): number {
    let originalIndex = -1;
    this.state.show.items.filter((e, i) => {
      if (e.id === currentItem.id) {
        originalIndex = i;
      }
    });
    return originalIndex;
  }

  /**
   * when cursor is hovering over a slide item
   *
   * @param i index of the slide item that the mouse is hover on
   */
  mouseEnter(i: number) {
    let videoId =
      this.state.currentCarouselItems[i].youTubeVideo || extractYoutubeId(this.state.currentCarouselItems[i].link);

    if (videoId === "" && this.state.currentCarouselItems[i].youTubeVideo) {
      videoId = this.state.currentCarouselItems[i].youTubeVideo;
    }
    if (videoId !== "") {
      this.youtubeHoverVideoOpt = {
        playerVars: {
          autoplay: 1,
          controls: 0,
          mute: 1,
          rel: 0,
        },
      };
      this.setState({ videoId, hoverPlay: i });
    }
  }

  /**
   * when cursor was hovering over a slide item and exits
   */
  mouseLeave() {
    this.setState({ hoverPlay: -1 });
  }

  /**
   * to play the item full video in full screen
   *
   * @param i item index
   */
  playItemFullVideo(i: number) {
    const item = this.state.currentCarouselItems[i];
    const currentEpisodeIndex = this.props.list?.items?.edges.findIndex(
      (edge: any) => edge?.listItem?.item?.id === item.id,
    );

    let videoId =
      this.state.currentCarouselItems[i].youTubeVideo || extractYoutubeId(this.state.currentCarouselItems[i].link);
    if (videoId === "" && this.state.currentCarouselItems[i].youTubeVideo) {
      videoId = this.state.currentCarouselItems[i].youTubeVideo;
    }
    const isPitchVideoStory = item.type === GRAPHQL_TYPE_PITCH_VIDEO_STORY;
    if (isPitchVideoStory) {
      videoId = item?.originalClip?.externalId || item?.trailer?.externalId;
    }
    if (videoId !== "") {
      this.props.setPlayingFullScreen({ status: true });
      this.setState({ videoId, playItemFullVideo: true, currentEpisodeIndex });

      const item: any = this.state.currentCarouselItems[i];
      snowplowAnalytics()
        .then((r) => {
          r.trackPlayVideo({
            category: item.id ? item.id : null,
            label: item.title ? item.title : "Carousel Youtube Video Played",
            property: videoId,
          });
        })
        .catch((r) => console.debug("SnowPlow not initialized."));
    }
  }

  closeFullScreenVideo() {
    this.setState({ playItemFullVideo: false });
    this.props.setPlayingFullScreen({ status: false });
  }

  /**
   * fires when next button is clicked
   */
  next() {
    // disable when carousel is already in animation
    if (this.sliderContainer.hasClass("animating")) {
      return;
    }

    // disable when total items is less than items to show
    if (this.state.show && this.state.show.items && this.state.show.items.length > this.state.itemsToShow) {
      this.sliderContainer.addClass("animating");
      this.sliderContainer.addClass("animating-left");
      setTimeout(this.nextCB, 700);
    }
  }

  nextOne() {
    if (this.prevBtn.hasClass("hidden")) {
      this.prevBtn.removeClass("hidden");
    }

    // the new slide to be displayed first item index should increase by the number of shown items per slide
    let newSlideFirstItemIndex: number = this.state.currentSlideFirstItemIndex + 1;
    // check if the new slide item index exceeds the length of total items >> then re calculate
    newSlideFirstItemIndex =
      newSlideFirstItemIndex >= this.state.show.items.length
        ? newSlideFirstItemIndex - this.state.show.items.length
        : newSlideFirstItemIndex;

    const updatedCarousel: CarouselItem[] = this.updateCarousel(newSlideFirstItemIndex);
    this.setState({ currentCarouselItems: updatedCarousel }, () => {
      this.sliderContainer.removeClass("animating");
      this.sliderContainer.removeClass("animating-left");
      this.sliderContainer.addClass("silent");
    });
  }

  /**
   * Callback function when animation is finished after 0.7s after next button is clicked
   */
  nextCB() {
    if (this.prevBtn.hasClass("hidden")) {
      this.prevBtn.removeClass("hidden");
    }

    // the new slide to be displayed first item index should increase by the number of shown items per slide
    let newSlideFirstItemIndex: number = this.state.currentSlideFirstItemIndex + this.state.itemsToShow;
    // check if the new slide item index exceeds the length of total items >> then re calculate
    newSlideFirstItemIndex =
      newSlideFirstItemIndex >= this.state.show.items.length
        ? newSlideFirstItemIndex - this.state.show.items.length
        : newSlideFirstItemIndex;

    const updatedCarousel: CarouselItem[] = this.updateCarousel(newSlideFirstItemIndex);
    this.setState({ currentCarouselItems: updatedCarousel }, () => {
      this.sliderContainer.removeClass("animating");
      this.sliderContainer.removeClass("animating-left");
      this.sliderContainer.addClass("silent");
    });
  }

  /**
   * fires when prev button is clicked
   */
  prev() {
    // disable when carousel is already in animation
    if (this.sliderContainer.hasClass("animating")) {
      return;
    }

    // disable when total items is less than items to show & when no next button first called
    if (this.state.show && this.state.show.items && this.state.show.items.length > this.state.itemsToShow) {
      this.sliderContainer.addClass("animating");
      this.sliderContainer.addClass("animating-right");
      setTimeout(this.prevCB, 700);
    }
  }

  /**
   * Callback function when animation is finished after 0.7s after prev button is clicked
   */
  prevCB() {
    // the new slide to be displayed first item index should decreased by the number of shown items per slide
    let newSlideFirstItemIndex: number = this.state.currentSlideFirstItemIndex - this.state.itemsToShow;
    // check if the new slide item index is below Zero >> then re calculate
    newSlideFirstItemIndex =
      newSlideFirstItemIndex < 0 ? this.state.show.items.length + newSlideFirstItemIndex : newSlideFirstItemIndex;

    const updatedCarousel: CarouselItem[] = this.updateCarousel(newSlideFirstItemIndex);
    this.setState({ currentCarouselItems: updatedCarousel }, () => {
      this.sliderContainer.removeClass("animating");
      this.sliderContainer.removeClass("animating-right");
      this.sliderContainer.addClass("silent");
    });
  }

  /**
   *
   *
   * @param {("ADD" | "REMOVE")} type
   * @memberof RdvCarousel
   */
  addRemoveLoadingItems(type: "ADD" | "REMOVE", itemToReplace: CarouselItem[]): void {
    let listItems: CarouselItem[] =
      this.state.show && this.state.show.items && this.state.show.items.length ? this.state.show.items : [];
    const loadingArray: CarouselItem[] = [];
    const loadingArrayLength: number =
      this.props.displayMode === "GridView" ? this.state.itemsToShow * 2 + 2 : this.state.itemsToShow;
    for (let i = 0; i < loadingArrayLength; i++) {
      loadingArray.push({
        id: `loading-item-${i}`,
        previewImageUrl: { originalUrl: LOAD, requestedResolutionUrl: LOAD },
        landingPageUrl: "",
        title: "",
      });
    }

    switch (type) {
      case "ADD":
        listItems = ArraysHelper.unionBy(listItems, loadingArray, "id") as CarouselItem[];
        break;
      case "REMOVE":
        listItems = listItems.filter((item: CarouselItem) => {
          return !item.id || item.id.indexOf("loading-item-") === -1;
        });
        break;
      default:
    }

    if (itemToReplace) {
      listItems = ArraysHelper.unionBy(listItems, itemToReplace, "id") as CarouselItem[];
    }

    const updatedShow: Show = {
      ...this.state.show,
      items: listItems,
    };

    if (this.props.displayMode === "GridView") {
      this.setState({ currentCarouselItems: listItems });
    }

    this.setState({
      show: updatedShow,
    });
  }

  /**
   * update the current displayed carousel items
   *
   * @param {number} newSlideItemIndex the new slide to display first item index
   *
   * @returns {CarouselItem[]} the new displayed items in the carousel
   */
  updateCarousel(newSlideItemIndex): CarouselItem[] {
    let newSlideFirstItemIndex: number = newSlideItemIndex;

    // assign prev slide items
    let prevSlide = this.state.show.items.slice(
      newSlideFirstItemIndex - this.state.itemsToShow - 1,
      newSlideFirstItemIndex,
    );
    if (newSlideFirstItemIndex <= this.state.itemsToShow) {
      prevSlide = this.state.show.items.slice(0, newSlideFirstItemIndex);
    }
    // if the current index is from the first slide >> bring items from the end
    if (prevSlide.length === 0) {
      prevSlide = this.state.show.items.slice(this.state.show.items.length - this.state.itemsToShow - 1);
    } else if (prevSlide.length < this.state.itemsToShow + 1) {
      const endChunkStartIndex: number = this.state.show.items.length - (this.state.itemsToShow + 1 - prevSlide.length);
      prevSlide.unshift(...this.state.show.items.slice(endChunkStartIndex, this.state.show.items.length));
    }

    // assign the current displayed items
    const currentDisplayed = this.state.show.items.slice(
      newSlideFirstItemIndex,
      newSlideFirstItemIndex + this.state.itemsToShow,
    );
    // if current slide doesn't fill the carousel bring items from the begining
    if (currentDisplayed.length < this.state.itemsToShow) {
      const fillSlide: number = this.state.itemsToShow - currentDisplayed.length;
      currentDisplayed.push(...this.state.show.items.slice(0, fillSlide));
    }

    // the next slide index
    let nextSlideFirstItemIndex: number = newSlideFirstItemIndex + this.state.itemsToShow;
    nextSlideFirstItemIndex =
      nextSlideFirstItemIndex >= this.state.show.items.length
        ? nextSlideFirstItemIndex - this.state.show.items.length
        : nextSlideFirstItemIndex;

    // assign the next slide items array
    let nextSlide = this.state.show.items.slice(
      nextSlideFirstItemIndex,
      nextSlideFirstItemIndex + this.state.itemsToShow + 1,
    );
    // if we're closing to the end of the carousel bring items from the begining
    if (nextSlide.length === 0) {
      nextSlide = this.state.show.items.slice(0, this.state.itemsToShow + 1);
    } else if (nextSlide.length < this.state.itemsToShow + 1) {
      const endChunkStartIndex: number = this.state.itemsToShow + 1 - nextSlide.length;
      nextSlide.push(...this.state.show.items.slice(0, endChunkStartIndex));
    }

    this.setState({ currentSlideFirstItemIndex: newSlideFirstItemIndex });
    return [...prevSlide, ...currentDisplayed, ...nextSlide];
  }

  /**
   * A function to open up the details panel.
   *
   * @type {(panelId: string, index: number) => void}
   */
  openDetailsPanel(itemId: string) {
    const { moreLikeThis: _moreLikeThis, list } = this.props;
    const isListCollecion = this.props.list.lists;

    let index: number;

    if (isListCollecion) {
      index = isListCollecion?.findIndex((list) => list?.id === itemId);
    } else {
      index = this.props.list?.items.edges.findIndex((edge) => edge?.listItem?.item?.id === itemId);
    }

    const isOriginalShow = isListCollecion && index !== -1;

    const isPoster = isListCollecion && index === -1;
    const comingSoonList = isListCollecion && isListCollecion[isListCollecion?.length - 1];
    const posterEdge = isPoster && comingSoonList?.items?.edges.find((edge) => edge.listItem?.item?.id === itemId);
    const posterItem = posterEdge?.listItem?.item;
    const otherItem =
      list?.items && list?.items?.edges && list?.items?.edges[index] ? list?.items?.edges[index].listItem?.item : null;

    const originalShowItem = isListCollecion && list.lists[index];
    const item = isListCollecion ? (isOriginalShow ? originalShowItem : posterItem) : otherItem;
    const moreLikeThis = _moreLikeThis || item?.moreLikeThis || "qQXPd4GmRlGeKbNxpioVpA";

    const scrollAnchorId = this.carouselID;

    const detailsPanelList = isOriginalShow ? originalShowItem : posterItem ? comingSoonList : list;
    const payload = { item, list: detailsPanelList, moreLikeThis, scrollAnchorId };

    this.props.openDetailsPanel(payload);
  }

  /**
   * on youtube video has ended..
   */
  onYouTubeVideoEnds() {
    this.nextOne();
    this.setState({ hoverPlay: -1 /*hideButtons: false*/ });
  }

  viewAllOnClick() {
    Analytics.userHistory({
      item: this.state.show.id,
      description: this.state.show.title,
    }).then((_requestId: string): void => {});

    gaEvent("View List", "Click", this.props.show.title ? this.props.show.title : "WXiJWxYwkWG68gQkRGUzw", true);
    facebookPixelTraceEvent(event, {
      category: this.state.show.id,
      title: this.state.show.title,
    });

    Router.to(`browse/${this.props.show.id}`);
  }

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render() {
    const carouselContainerClassName = `rdv-carousel-container items-${this.state.itemsToShow}${
      this.props.displayMode && this.props.displayMode === "GridView" ? " carouesl-grid" : ""
    }`;

    const classNoArrows =
      this.state.show && this.state.show.items && this.state.show.items.length <= this.state.itemsToShow
        ? " no-arrows "
        : "";

    const rdvCarouselClassName = `${classNoArrows} ${
      this.state.showPanel
        ? `has-panel rdv-carousel slider-${this.props.show.id}`
        : `rdv-carousel slider-${this.props.show.id}`
    }`;

    const contextValue: RdvCarouselContextInterface = {
      listDetailsView: this.props.listDetailsView,
      hideTitle: this.props.hideTitle,
      displayMode: this.props.displayMode,
      state: this.state,
      setState: this.setState.bind(this),
      show: this.props.show,
      viewAllOnClick: this.viewAllOnClick,
      retrieveOriginalIndex: this.retrieveOriginalIndex,
      prev: this.prev,
      next: this.next,
      isCarouselDisplayMode: !this.props.displayMode || this.props.displayMode === "Carousel",
      campaignPreview: this.props.campaignPreview,
      displaySubtitleFlag: this.props.displaySubtitleFlag,
      displayListDescriptionFlag: this.props.displayListDescriptionFlag,
      itemPreviewType: this.props.itemPreviewType,
      hideItemTitle: this.props.hideItemTitle,
      hasDetails: this.props.hasDetails,
      prevBtn: this.prevBtn,
      nextBtn: this.nextBtn,
      loadDefaultImage: this.loadDefaultImage,
      youtubeHoverVideoOpt: this.youtubeHoverVideoOpt,
      openDetailsPanel: this.openDetailsPanel,
      mouseEnter: this.mouseEnter,
      mouseLeave: this.mouseLeave,
      onYouTubeVideoEnds: this.onYouTubeVideoEnds,
      playItemFullVideo: this.playItemFullVideo,
      pageInfo: this.props.pageInfo,
      showItemTypeIcon: this.props?.json?.showItemTypeIcon,
      json: this.props.json,
    };

    const mastersClass = this.props.json?.campaign === "MASTERS" ? "masters" : "";
    const shouldShowPaywall = CONTENT_REQUIRING_PAYWALL.includes(this.props.list?.id);
    const isVertical = this.carouselID.includes("Z0GQWuEnoMcQcSHpw") || this.carouselID.includes("fYX8FrSMyru");
    const PortraitMode = this.props?.json?.itemPreviewType === PreviewKind.PORTRAIT;

    return (
      <RdvCarouselContext.Provider value={contextValue}>
        <div className={`rdv-carousel-section ${mastersClass}`} id={this.props.show.id}>
          <CarouselHeader />
          <div className="rdv-show">
            <ShowDetails view="PRE-ITEM" />
            <div className="rdv-show-content">
              <div
                id={`${this.carouselID}-msk`}
                className={`rdv-carousel-mask ${isVertical ? "vertical-carousel" : ""}`}
              >
                <div className={rdvCarouselClassName} id={`${this.carouselID}`}>
                  <PrevButton />
                  <NextButton />
                  <div className={carouselContainerClassName}>
                    {this.state.currentCarouselItems.map((item, index) => (
                      <SlideItem
                        item={item}
                        key={`item-${item.title}-${index.toString()}`}
                        index={index}
                        isVertical={isVertical}
                        PortraitMode={PortraitMode}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.props.displayMode === "GridView" ? <LoadMore listId={this.props.list?.id} /> : null}

          {this.state.playItemFullVideo && !this.props.detailsPanelIsOpened ? (
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <VideoPanel
                  ref={this.videoPanelRef}
                  youTube={!/http/i.test(this.state.videoId) ? this.state.videoId : null}
                  videoPosition="FULL_SCREEN"
                  onVideoEnded={this.closeFullScreenVideo}
                  hasBackBtn={true}
                />
              </Suspense>
              {shouldShowPaywall && (
                <PaywallOverlay
                  delayInSeconds={this.state.currentEpisodeIndex > 0 ? 45 : 90}
                  videoPanelRef={this.videoPanelRef}
                  skip={this.closeFullScreenVideo}
                />
              )}
            </>
          ) : null}

          {this.props.navButtonUrl ? (
            <RdvButton
              isOutline={true}
              extraClassName="outline-light view-more-btn"
              onClick={() => {
                const REG = this.props.navButtonUrl.split("#:");

                Router.to(REG[0], undefined, REG[1]);
              }}
              // ${this.state.videoHeightDiff + 0}px
              style={{ marginTop: `2px` }}
              text={this.props.navButtonText}
            />
          ) : null}
        </div>
      </RdvCarouselContext.Provider>
    );
  }
}
const mapState = (state: AppState) => ({
  user: state.account.user,
  detailsPanelIsOpened: state.detailsPanel.item !== null,
});

const mapDispatch = {
  openDetailsPanel: Actions.openDetailsPanel,
  setPlayingFullScreen: Actions.setPlayingFullScreen,
};
export const ConnectedCarousel = connect(mapState, mapDispatch)(Carousel);
export const RdvCarousel = (props: RdvCarouselProps) => {
  const isFeaturedCarousel = props.show?.id === FeaturedCarouselId;
  const { width: screenWidth } = useWindowSize();
  const isSmallScreen = screenWidth <= 560;
  const isGridView = props.json?.displayMode === "GRID";
  if (isFeaturedCarousel && !isSmallScreen && !isGridView) {
    return <ScrollingCarousel {...props} />;
  }
  return <ConnectedCarousel {...props} />;
};
